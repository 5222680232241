import React from "react";
import theme from "@ui/basalt/theme";
import translate from "../../utils/translate";
import SpoqaHansTypography from "@SpoqaHansTypography";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import { IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";

const useStyles = makeStyles({
  menu: {
    borderRadius: 8,
    border: `1px solid ${theme.gray_1}`,
    ...theme.shadow_2,
    zIndex: 3,
  },

  menu_list: {
    paddingTop: 7,
    paddingBottom: 7,
    zIndex: 40,
  },

  menu_item: {
    height: 40,
    paddingLeft: 11,
    paddingRight: 11,
    "&:hover": {
      backgroundColor: theme.indigo_0,
    },
  },

  menu_item_text: {
    ...theme.font14,
    color: theme.gray_9_title,
    lineHeight: "20px",
  },

  menu_button: {
    width: 36,
    height: 36,
    borderRadius: 8,
    border: `1px solid ${theme.gray_2}`,
    backgroundColor: theme.white,
  },

  icon_vert: {
    color: theme.gray_7_text,
  },
});

type Props = {
  anchorEl?: HTMLElement | null;
  setAnchorEl?: (el: HTMLElement | null) => void;
  onClickDelete: () => void;
  onDownloadAllContract: () => void;
  setHover?: (h: boolean) => void;
  setBackdrop?: (b: boolean) => void;
  setEditDescription?: () => void;
};

const NDACardItemMenu = (props: Props) => {
  const {
    anchorEl,
    setAnchorEl = () => {},
    setBackdrop = () => {},
    setHover = () => {},
    setEditDescription = () => {},
    onClickDelete,
    onDownloadAllContract,
  } = props;
  const classes = useStyles();

  const openMenu = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setHover(true);
    setAnchorEl(evt.currentTarget as HTMLElement);
  };

  const closeMenu = (evt: React.MouseEvent, reason: string) => {
    evt.stopPropagation();
    setAnchorEl(null);
    if (reason == "backdropClick") {
      setHover(false);
      setBackdrop(true);
    }
  };

  const renderMoreMenuButton = () => {
    return (
      <IconButton
        onClick={openMenu}
        aria-controls="simple-nda-menu"
        aria-haspopup="true"
        className={classes.menu_button}
      >
        <MoreVertRoundedIcon className={classes.icon_vert} />
      </IconButton>
    );
  };

  const renderMenuItem = ({
    event,
    text,
    isDeleteText,
  }: {
    event: (evt: React.MouseEvent) => void;
    text: string;
    isDeleteText?: boolean;
  }) => {
    return (
      <MenuItem onClick={event} className={classes.menu_item}>
        <SpoqaHansTypography
          className={classes.menu_item_text}
          style={isDeleteText ? { color: theme.system_red } : {}}
        >
          {text}
        </SpoqaHansTypography>
      </MenuItem>
    );
  };

  const addDescription = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setAnchorEl(null);
    setEditDescription();
  };

  const contractDownload = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setAnchorEl(null);
    onDownloadAllContract();
  };

  const deleteFile = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    setHover(false);
    setAnchorEl(null);
    onClickDelete();
  };

  return (
    <React.Fragment>
      {renderMoreMenuButton()}
      <Menu
        id="simple-nda-menu"
        elevation={0}
        keepMounted
        anchorEl={anchorEl}
        getContentAnchorEl={undefined}
        MenuListProps={{ className: classes.menu_list }}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          className: classes.menu,
        }}
        TransitionProps={{
          onExited: () => {
            setHover(false);
          },
        }}
      >
        {renderMenuItem({
          event: addDescription,
          text: translate.nda_item.add_description,
        })}
        {renderMenuItem({
          event: contractDownload,
          text: translate.nda_item.download_history,
        })}
        {renderMenuItem({
          event: deleteFile,
          text: translate.nda_item.delete,
          isDeleteText: true,
        })}
      </Menu>
    </React.Fragment>
  );
};

export default NDACardItemMenu;
