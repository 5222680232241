import { makeStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";

const styles = makeStyles((MuiTheme: Theme) => ({
  container: {
    position: "relative",
    borderRadius: 8,
    borderWidth: 1,
    border: `1px solid ${theme.gray_2}`,
    aspectRatio: "1/1",
    overflow: "hidden",
    ...theme.shadow_1,
  },
  hover_style: {
    border: `2px solid ${theme.indigo_2}`,
    margin: "-1px",
    boxShadow: "0",
  },
  content: {
    height: "100%",
  },
  image_content: {
    overflow: "hidden",
    "& img": {
      width: "100%",
    },
  },
  data_content: {
    boxSizing: "border-box",
    padding: 16,
    borderTop: `1px solid ${theme.gray_2}`,
    borderBottom: `1px solid ${theme.gray_2}`,
    [MuiTheme.breakpoints.down(theme.breakMedium)]: {
      padding: "8px 12px",
    },
    [MuiTheme.breakpoints.down(theme.breakSmall)]: {
      padding: "8px 8px 0 8px",
      borderBottom: "0",
    },
  },
  file_name: {
    color: theme.black,
    ...theme.font16,
    width: "100%",
    maxWidth: 300,
    [MuiTheme.breakpoints.down(theme.breakMedium)]: {
      ...theme.font14,
    },
    [MuiTheme.breakpoints.down(theme.breakSmall)]: {
      letterSpacing: "-0.025em",
      marginRight: "-4px",
    },
  },
  file_date: {
    color: theme.gray_6,
    ...theme.font12,
    [MuiTheme.breakpoints.down(theme.breakSmall)]: {
      display: "none",
    },
  },
  info_content: {
    padding: "14px 16px",
    color: theme.gray_6,
    [MuiTheme.breakpoints.down(theme.breakMedium)]: {
      padding: "6px 12px",
      "& svg": {
        width: 18,
        height: 18,
      },
    },
    [MuiTheme.breakpoints.down(theme.breakSmall)]: {
      padding: "0 8px 8px 8px",
    },
  },

  profile_content: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  profile_avatar: {
    width: 20,
    height: 20,
    marginRight: 8,
    [MuiTheme.breakpoints.down(theme.breakMedium)]: {
      width: 18,
      height: 18,
    },
  },
  profile_name: {
    ...theme.font14,
    marginRight: 4,
  },
  profile_email: {
    ...theme.font12,
    display: "table-cell",
    verticalAlign: "middle",
    height: 20,
  },
}));

export default styles;
