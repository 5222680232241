import React from "react";
import {
  LinearProgress,
  Grid,
  makeStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { theme } from "@theme";
import LoadingWrap from "./LoadingWrap";
import clsx from "clsx";

type LinearLoadingWrapProps = {
  loading: boolean;
  loadingComponent?: JSX.Element;
  children?: any;
  wrapClass?: string;
  progressClass?: string;
};

const BorderLinearProgress = withStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    colorPrimary: {
      backgroundColor: theme.indigo_1,
    },
    bar: {
      backgroundColor: theme.indigo_6,
    },
  })
)(LinearProgress);

const useStyle = makeStyles(() => ({
  root: {
    width: "100%",
  },
  progress: {
    width: "100%",
  },
}));

export function LinearLoadingWrap({
  loading,
  loadingComponent,
  children,
  wrapClass,
  progressClass,
}: LinearLoadingWrapProps) {
  const LinearLoadingView = () => {
    const classes = useStyle();
    return (
      <Grid
        container
        alignItems="center"
        className={clsx(classes.root, wrapClass)}
      >
        <BorderLinearProgress
          className={clsx(classes.progress, progressClass)}
        />
      </Grid>
    );
  };

  return (
    <LoadingWrap
      loading={loading}
      loadingComponent={
        loadingComponent ? loadingComponent : <LinearLoadingView />
      }
    >
      {children}
    </LoadingWrap>
  );
}

export default LinearLoadingWrap;
