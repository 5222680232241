import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";
import {
  UserLicenseMapObjectSchema,
  UserLicenseMapObjectCore,
  LicenseRoleTypes,
  LicenseRoleTypesValues,
} from "./user_license_map";
import {
  UserObject,
  validateUserObjectWithLicenseRole,
  UserObjectWithLicenseRoleSchema,
  UserObjectSchema,
  extractUserObject,
} from "./user";

export enum LicensePlan {
  FREE = "LicensePlan::free",
  STANDARD = "LicensePlan::standard",
}

export const getPlanLevel = (plan: LicensePlan) => {
  switch (plan) {
    case LicensePlan.STANDARD:
      return 1;
    case LicensePlan.FREE:
    default:
      return 0;
  }
};

export enum LicenseType {
  PERSONAL = "LicenseType::personal",
  TRIAL = "LicenseType::trial",
  INSTITUTION = "LicenseType::institution",
}

export const getTypeLevel = (Type: LicenseType) => {
  switch (Type) {
    case LicenseType.INSTITUTION:
      return 2;
    case LicenseType.PERSONAL:
      return 1;
    case LicenseType.TRIAL:
    default:
      return 0;
  }
};

export interface LicensePureObjectCoreWOEtc {
  readonly id: string;
  readonly plan: LicensePlan;
  readonly type: LicenseType;
  readonly seats: number;
  readonly start: string;
  readonly end: string;
  readonly inviteCode: string;
}

export type LicensePureObjectCore = LicensePureObjectCoreWOEtc & {
  etc: string;
};

export const LicensePureObjectCoreWOEtcSchema = withAccessTime()
  .addField("id", T.string())
  .addField(
    "plan",
    T.string().withEnum([LicensePlan.FREE, LicensePlan.STANDARD])
  )
  .addField(
    "type",
    T.string().withEnum([
      LicenseType.TRIAL,
      LicenseType.PERSONAL,
      LicenseType.INSTITUTION,
    ])
  )
  .addField("seats", T.number())
  .addField("start", T.string())
  .addField("end", T.string())
  .addField("inviteCode", T.string());

export const LicensePureObjectSchema =
  LicensePureObjectCoreWOEtcSchema.clone().addField("etc", T.string());

export interface LicenseExtraInfo {
  institution_name: string;
  description: string;
}

const addExtraDescription = (from: T): T => {
  return from
    .addField("institution_name", T.string())
    .addField("description", T.string());
};

export const LicenseExtraInfoSchema = addExtraDescription(T.object());
export const LicensePureObjectWDescriptionSchema = addExtraDescription(
  LicensePureObjectCoreWOEtcSchema.clone()
);
export const extractLicenseExtraDescription =
  T.mkObjectExtractor<LicenseExtraInfo>(LicenseExtraInfoSchema);
export const validteLicenseExtraDescription = T.mkValidator<LicenseExtraInfo>(
  LicenseExtraInfoSchema
);

export type LicensePureObject = LicensePureObjectCore & AccessTime;
export type LicensePureObjectWDescription = Omit<LicensePureObject, "etc"> &
  LicenseExtraInfo;

export type LicenseDBObjectCore = {
  readonly Users: Array<
    UserObject & { User_License_Map: UserLicenseMapObjectCore }
  >;
} & LicensePureObjectCore;

export type RefinedLicenseObjectCore = LicensePureObjectWDescription & {
  readonly Users: Array<UserObject & { role: LicenseRoleTypes }>;
};

export type LicenseDBObject = LicenseDBObjectCore & AccessTime;
export type LicenseObject = RefinedLicenseObjectCore & AccessTime;

export const refineLicenseObject = (rdbLicense: LicenseDBObject) => {
  const Users = rdbLicense.Users.map((user) => {
    const userObject = extractUserObject(user);
    const role = user.User_License_Map.role;
    const userObjWithRole = validateUserObjectWithLicenseRole({
      ...userObject,
      role,
    });
    return userObjWithRole;
  });
  const extraInfo = validteLicenseExtraDescription(JSON.parse(rdbLicense.etc));
  const ret: LicenseObject = {
    ...rdbLicense,
    ...extraInfo,
    Users,
  };
  return ret;
};

export const LicenseDBObjectSchema = LicensePureObjectSchema.clone().addField(
  "Users",
  T.array(
    UserObjectSchema.clone().addField(
      "User_License_Map",
      UserLicenseMapObjectSchema.clone()
    )
  )
);

export const LicenseObjectSchema =
  LicensePureObjectWDescriptionSchema.clone().addField(
    "Users",
    T.array(UserObjectWithLicenseRoleSchema.clone())
  );

export const extractLicensePureObject = T.mkObjectExtractor<LicensePureObject>(
  LicensePureObjectSchema
);
export const validateLicenseObject =
  T.mkValidator<LicenseObject>(LicenseObjectSchema);
export const extractLicenseDBObject = T.mkObjectExtractor<LicenseDBObject>(
  LicenseDBObjectSchema
);

export type UserDBObjectWithLicenseCore = UserObject & {
  readonly Licenses: Array<
    LicensePureObject & { User_License_Map: UserLicenseMapObjectCore }
  >;
};
export type UserDBObjectWithLicense = UserDBObjectWithLicenseCore & AccessTime;
export type RefinedUserWithLicense = UserObject & {
  readonly Licenses: Array<
    LicensePureObjectWDescription & { role: LicenseRoleTypes }
  >;
};
export const UserDBObjectWithLicenseSchema = UserObjectSchema.clone().addField(
  "Licenses",
  T.array(
    LicensePureObjectSchema.clone().addField(
      "User_License_Map",
      UserLicenseMapObjectSchema.clone()
    )
  )
);
export const RefinedUserWithLicenseSchema = UserObjectSchema.clone().addField(
  "Licenses",
  T.array(
    LicensePureObjectWDescriptionSchema.clone().addField(
      "role",
      T.string().withEnum(LicenseRoleTypesValues)
    )
  )
);

export const extractUserObjectWithLicense =
  T.mkObjectExtractor<UserDBObjectWithLicense>(UserDBObjectWithLicenseSchema);
export const refineUserWithLicense = (
  rdbUser: UserDBObjectWithLicense
): RefinedUserWithLicense => {
  const Licenses = rdbUser.Licenses.map((lics) => {
    const role = lics.User_License_Map.role;
    const extraInfo = extractLicenseExtraDescription(JSON.parse(lics.etc));
    const licenseWithRole: LicensePureObjectWDescription & {
      role: LicenseRoleTypes;
    } = {
      ...lics,
      ...extraInfo,
      role,
    };
    return licenseWithRole;
  });
  const userObject = extractUserObject(rdbUser);
  return {
    ...userObject,
    Licenses,
  };
};
