export default {
  ko: {
    landing_service_intro: {
      banner: {
        header: "문서 관리에 필요한\n모든 서비스,\nbaSalt에 있습니다",
        button: "지금 시작하기",
      },
      row_1: {
        title_1: "업로드 즉시 이중",
        title_2: "되어 블록체인까지",
        title_highlight: "암호화",
        text: "업로드와 동시에 이중 암호화되어 블록체인에 안전하게\n보관됩니다. 또한, 업로드 한 시점에 대한 타임스탬프가\n적힌 블록체인 인증서를 제공합니다.",
      },

      row_2: {
        title: "워크스페이스 룰 설정을 통한\n팀 내부적 문서 보안 관리",
        text: "워크스페이스 내의 룰 설정을 통하여 팀원에게 자동으로 문서 보안\n에 관한 NDA 서명을 받을 수 있습니다. 또한, 세분화된 권한 설정을\n통해 문서의 팀원 접근 제어를 손쉽게 할 수 있습니다.",
      },

      row_3: {
        title: "공유 옵션 설정을 통한\n외부 공유 보안 강화",
        text: "다운로드, 프린트, NDA를 통한 비밀유지협약 등 다양한\n공유 옵션을 설정하여 외부 사용자의 파일 접근에 대한\n보안을 손쉽게 한층 더 강화할 수 있습니다.",
      },

      row_4: {
        title: "공유 링크에 대한\n인사이트 리포트 제공",
        text: "공유 링크를 통해 접속한 방문자의 정보 및 페이지 별\n체류 시간, 가장 관심 있게 본 페이지 등이 포함된\n인사이트 리포트를 제공합니다.",
      },

      row_5: {
        title: "문서에 관한 모든 기록\n자동 수집 기능",
        text: "문서의 열람, 삭제, 공유, 다운로드 등 모든 활동에 대해\n자동으로 수집하고 이를 직관적인 UI로 제공하며\nPDF 추출을 통해 별도의 문서로 보관하실 수 있습니다.",
      },

      row_6: {
        title: "OCR을 이용한 검색 및\n태그 기능 제공",
        text: "문서에 대한 OCR 검색이 가능하여, 어떤 문서 형식이든 손쉽게\n검색할 수 있습니다. 또한 문서에 태그를 달아검색 및 문서 관리를\n용이하게 할 수 있습니다.",
      },

      start_free_header:
        "이 모든 것을 무료로!\n지금 baSalt Docs에서 혜택 챙겨가세요!",
      start_free_button: "무료체험 시작하기",
      person_small_text: "Harry, CSO of Start-up",
      person_title:
        "팀 내부적 관리와 소통 측면에\n있어서 업무 과정을 바로\n확인할 수 있어요",
      person_text:
        "“ 바솔트를 사용하여 조직 전체 현안에 빠르게 대처하고 있습니다. 공유하는 파일들에 대해 언제 액세스 하였는지 등의 정보를 타임라인을 볼 수 있고, 권한 세분화 등의 기능을 이용하여 문서 체계를 쉽게 구축하였습니다. “",
      solution_button: "솔루션 확인하기",
      solution_text_large: "같은 고민을 하고 계신가요?",
      solution_text_small: "baSalt로 어떻게 해결하였는지 확인해보세요!",
      try_button: "무료로 다양한 기능 이용해보기",
      guide_button: "서비스 이용 가이드 확인하기",
      moving_text: "그 외에도 더 많은 기능을 제공합니다!",
    },
  },

  en: {
    landing_service_intro: {
      banner: {
        header: "Basalt Docs has everything\nyou need for file management",
        button: "Free Trial Now!",
      },

      row_1: {
        title_1: "Double-encrypted",
        title_2: "and immediately block-chained",
        title_highlight: "after upload",
        text: "It will be stored safely within the block chain through double encryption immediately after uploading. Also, block chain verification with the time stamp regarding the point of upload will be provided.",
      },

      row_2: {
        title:
          "By setting workspaces, you can manage the security of documents with your team",
        text: "By setting the workspace rules, you will be able to automatically receive NDA signature regarding document security from your teammates. Also, the availability of your teammates to the documents can be easily managed through detailed authority settings.",
      },

      row_3: {
        title:
          "Enhanced security for external sharing by setting the sharing options",
        text: "You can easily enhance the security regarding external users’ file access through various sharing options such as downloading, printing, or confidentiality agreement through NDA",
      },

      row_4: {
        title: "An insight report on the link for sharing",
        text: "An inside report will be provided the sharing link that includes the users’ information, most interested page, how long they visited each page, etc.",
      },

      row_5: {
        title: "Keep a record of all\ndocument activities",
        text: "We collect all activities regarding the document such as browsing, deleting, sharing, downloading, and provide them with intuitive UI, also enabling you to store them as separate document by PDF extraction.",
      },

      row_6: {
        title: "OCR-based search and tagging",
        text: "OCR search for the documents is possible, so that you can easily search them regardless of the format. Also, searching and managing the documents can be done easily through tags.",
      },

      start_free_header: "Free for All!\nTake advantage of baSalt Docs!",
      start_free_button: "Free Trial Now",
      person_small_text: "Harry, CSO of Start-up",
      person_title:
        "I can check the process of work regarding team management and communication.",
      person_text:
        "“ We are quickly responding to the issues throughout our organization with baSalt. Information such as time of access for the shared files can be seen through the timeline, and we have set a convenient document system through detailed authorization. “",
      solution_button: "Free Trial Now!",
      solution_text_large: "Do you have same concerns?",
      solution_text_small: "Check out how baSalt Docs has helped solve them!",
      try_button: "Use various functions for free",
      guide_button: "Check the service access manual",
      moving_text: "And we provide so much more!",
    },
  },
};
