import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";

export type IPFSObject = {
  cid: string;
  extension: string;
};

export enum IPFSStateStatus {
  INIT = "IPFS::INIT",
}

export enum IPFSActionKind {
  TRY_UPLOAD_GUEST_FILE = "IPFSAction::TRY_UPLOAD_GUEST_FILE",
  TRY_UPLOAD_IMAGE = "IPFSAction::TRY_UPLOAD",
  TRY_RESET = "IPFSAction::TRY_RESET",
}

export type IPFSState = {
  readonly status: IPFSStateStatus.INIT;
  readonly cids: IPFSObject[];
};

export type IPFSAction =
  | {
      readonly kind: IPFSActionKind.TRY_UPLOAD_IMAGE;
      readonly files: File[];
    }
  | {
      readonly kind: IPFSActionKind.TRY_UPLOAD_GUEST_FILE;
      readonly files: File[];
    }
  | {
      readonly kind: IPFSActionKind.TRY_RESET;
    };

export type IPFSError = never;

const smid = "IPFS_STATE_MACHINE3";
export type IPFSStateMachineType = StateMachine3<
  IPFSStateStatus,
  IPFSState,
  IPFSActionKind,
  IPFSAction,
  IPFSError
>;
export const ipfsStateMachine: IPFSStateMachineType = new StateMachine3<
  IPFSStateStatus,
  IPFSState,
  IPFSActionKind,
  IPFSAction,
  IPFSError
>(smid, { status: IPFSStateStatus.INIT, cids: [] }, [
  transition(
    IPFSStateStatus.INIT,
    IPFSStateStatus.INIT,
    IPFSActionKind.TRY_RESET
  ),
  transition(
    IPFSStateStatus.INIT,
    IPFSStateStatus.INIT,
    IPFSActionKind.TRY_UPLOAD_IMAGE
  ),
  transition(
    IPFSStateStatus.INIT,
    IPFSStateStatus.INIT,
    IPFSActionKind.TRY_UPLOAD_GUEST_FILE
  ),
]);

/* */
export type DispatchIPFSAction = Dispatch<
  StateMachineAction<
    IPFSStateStatus,
    IPFSState,
    IPFSActionKind,
    IPFSAction,
    IPFSError
  >
>;
export default mkReducer<
  IPFSStateStatus,
  IPFSState,
  IPFSActionKind,
  IPFSAction,
  IPFSError
>(ipfsStateMachine);
export const doIpfsAction = (
  dispatch: DispatchIPFSAction,
  nextAction: IPFSAction,
  onResolve: () => void = () => {},
  onReject: (err: IPFSError | InternalError) => void = () => {}
) => {
  dispatch(ipfsStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doIpfsActionAsync = (
  dispatch: DispatchIPFSAction,
  nextAction: IPFSAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(ipfsStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetIpfs = (dispatch: DispatchIPFSAction) => {
  dispatch(ipfsStateMachine.newResetAction());
};
