import React from "react";
import { Grid } from "@material-ui/core";
import { theme } from "@theme";
import SpoqaHansTypography from "../typo/SpoqaHansTypography";
import FolderRoundedIcon from "@material-ui/icons/FolderRounded";
import CustomButton from "../buttons/CustomButton";
import SearchIcon from "@material-ui/icons/Search";

type SearchWordsButtonProps = {
  value: string;
  type?: string;
  folder?: boolean;
  onClick: () => void;
};

type SearchWordsButtonState = {
  hover: boolean;
};

class SearchWordsButton extends React.PureComponent<
  SearchWordsButtonProps,
  SearchWordsButtonState
> {
  static defaultProps = {
    value: "",
    onClick: () => {},
  };
  constructor(props: SearchWordsButtonProps) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  render() {
    const backgroundColor =
      this.state.hover === true ? `${theme.gray_1}60` : theme.white;
    return (
      <Grid
        id="search-input"
        onClick={(evt) => {
          evt.preventDefault();
          this.props.onClick();
        }}
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
        alignItems="center"
        container
        item
        xs={12}
        style={{
          backgroundColor,
          width: "100%",
          height: 48,
          cursor: "pointer",
          padding: "12px 8px",
        }}
      >
        <Grid container item xs={7} id="search-input">
          {this.props.folder && (
            <FolderRoundedIcon
              id="search-input"
              style={{ fontSize: 18, marginRight: 8 }}
            />
          )}
          <SpoqaHansTypography
            id="search-input"
            style={{ ...theme.font14, color: theme.black }}
            noWrap
          >
            {this.props.value}
          </SpoqaHansTypography>
        </Grid>
        <Grid container item xs={5} justifyContent="flex-end" id="search-input">
          {this.props.type !== undefined && (
            <CustomButton
              buttonType="secondary"
              size="small"
              onClick={(evt) => {
                evt.stopPropagation();
                this.props.onClick();
              }}
              customId="search-input"
              id="search-input"
              style={{
                height: 32,
                paddingRight: 8,
              }}
              endAdornment={<SearchIcon id="search-input" />}
            >
              {this.props.type}
            </CustomButton>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default SearchWordsButton;
