import { doAPIGetRequest } from "./api";

import { IGetAuthNotes } from "@basalt-commons/api/request/note";
import {
  OGetAuthNotes,
  validateOGetAuthNotes,
} from "@basalt-commons/api/response/note";

/**
 * GOONO 서버에 인증 상황을 받아오는 API function
 * @param goonoToken string
 * @param args IGetAuthNotes
 * @returns Promise<OGetAuthNotes>
 */
const getAuthNote = async (
  goonoToken: string,
  args?: IGetAuthNotes
): Promise<OGetAuthNotes> => {
  const ret = await doAPIGetRequest("auth/notes", goonoToken, args);
  return validateOGetAuthNotes(ret);
};

export default {
  getAuthNote,
};
