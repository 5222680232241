import T from "@redwit-commons/utils/typecheck";
/**
 * OWNER: Only one in workspace, have full auth
 * ADMIN: Can not edit workspace, admin authorization
 * MEMBER: Can not edit workspace, role authorization, make team
 * GUEST: No authorization
 */
export enum WorkspaceRoleType {
  OWNER = "Workspace::Owner",
  ADMIN = "Workspace::Admin",
  MEMBER = "Workspace::Member",
  GUEST = "Workspace::Guest",
}

export const getRoleType = (role?: WorkspaceRoleType) => {
  switch (role) {
    case WorkspaceRoleType.OWNER:
      return "Owner";
    case WorkspaceRoleType.ADMIN:
      return "Admin";
    case WorkspaceRoleType.MEMBER:
      return "Member";
    default:
      return "Guest";
  }
};

export const getWorkspaceRoleLevel = (role?: WorkspaceRoleType) => {
  switch (role) {
    case WorkspaceRoleType.OWNER:
      return 3;
    case WorkspaceRoleType.ADMIN:
      return 2;
    case WorkspaceRoleType.MEMBER:
      return 1;
    case WorkspaceRoleType.GUEST:
      return 0;
    default:
      return -1;
  }
};

export const WorkspaceRoleTypeValues = [
  WorkspaceRoleType.OWNER,
  WorkspaceRoleType.ADMIN,
  WorkspaceRoleType.MEMBER,
  WorkspaceRoleType.GUEST,
];

export const UserWorkspaceMapObjectSchema = T.object()
  .addField("UserId", T.string())
  .addField("WorkspaceId", T.string())
  .addField("roleType", T.string().withEnum(WorkspaceRoleTypeValues));

export interface UserWorkspaceMapObjectCore {
  readonly UserId: string;
  readonly WorkspaceId: string;
  readonly roleType: WorkspaceRoleType;
}
