import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";
import { AuthObject, AuthObjectSchema } from "./auth";
import { addTag, CommentObject, TagObject } from "./tag";
import { extractUserObject, UserObject, UserObjectSchema } from "./user";
import { ProjectPureObject, ProjectPureObjectSchema } from "./project";
import { addTXInfo, TXInfo } from "./tx_info";
import {
  DocumentObject,
  DocumentObjectSchema,
} from "../../global-api/object/document";

export const addAuth = (from: T, required: boolean) => {
  return from.addField("Auth", AuthObjectSchema, required);
};

// tslint:disable-next-line: interface-name
export interface NoteObjectCore {
  readonly id: string;
  /**
   * @deprecated 나중에는 완성되어도 이 URL 안줄겁니다. 아래 cid도 대체
   */
  readonly url?: string;
  readonly cid: string;
  readonly extension: string;
  /**
   * @deprecated 나중에는 완성되어도 이 URL 안줄겁니다. 아래 cid도 대체
   */
  readonly thumbUrl?: string;
  readonly thumbCid?: string;
  readonly thumbExtension?: string;
  /**
   * @deprecated 나중에는 완성되어도 이 URL 안줄겁니다. 이건 아예 안 사용되는 것 같아서 CID 안만듭니다.
   */
  readonly previewUrl?: string;
  readonly originalCid: string;
  readonly originalExtension: string;
  /**
   * @deprecated 나중에는 완성되어도 이 URL 안줄겁니다. 이건 아예 안 사용되는 것 같아서 CID 안만듭니다.
   */
  readonly flatUrl?: string;
  readonly timestamp: string;
  readonly writtenAt: string;
  readonly hash?: string;
  /**
   * @deprecated 이제 isFile 을 안주고 extension 을 줄 겁니다. 당분간은 계속 들어옵니다.
   */
  readonly isFile?: boolean;
  readonly ProjectId: string; // Relation
  readonly UserId: string; // Relation

  // globalSevice에 필요한 필드
  readonly file_name?: string;
  readonly merged?: boolean;
}

export type NoteMetaObject = NoteObjectCore & AccessTime;

export type NoteTagObject = {
  Tags: TagObject[];
  Comments: CommentObject[];
  Auth?: AuthObject;
} & NoteMetaObject;

export type NoteDBObject = {
  User: UserObject;
} & NoteTagObject;

export type NoteObjectWithDocuments = {
  Documents: DocumentObject[];
} & NoteObject;

export type NoteObject = {
  User: UserObject;
} & NoteTagObject;

export type AuthNoteObject = Omit<NoteObject, "Auth"> & {
  Auth: AuthObject;
};

export type AuthNoteObjectWithProject = {
  Project: ProjectPureObject;
} & AuthNoteObject;

export const refineNoteObject = (rdbNote: NoteDBObject) => {
  const user = rdbNote.User;
  const userObject = extractUserObject(user);
  const ret: NoteObject = {
    ...rdbNote,
    User: userObject,
  };
  return ret;
};

export const refineNoteObjectWithDocuments = (
  rdbNote: NoteObjectWithDocuments
) => {
  const user = rdbNote.User;
  const userObject = extractUserObject(user);
  const ret: NoteObjectWithDocuments = {
    ...rdbNote,
    User: userObject,
  };
  return ret;
};

export const NoteMetaObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("url", T.string(), false)
  .addField("cid", T.string())
  .addField("extension", T.string())
  .addField("thumbUrl", T.string(), false)
  .addField("thumbCid", T.string(), false)
  .addField("thumbExtension", T.string(), false)
  .addField("previewUrl", T.string(), false)
  .addField("originalCid", T.string())
  .addField("originalExtension", T.string())
  .addField("flatUrl", T.string(), false)
  .addField("timestamp", T.string())
  .addField("writtenAt", T.string())
  .addField("hash", T.string(), false)
  .addField("isFile", T.boolean(), false)
  .addField("file_name", T.string(), false)
  .addField("merged", T.boolean(), false)
  .addField("ProjectId", T.string())
  .addField("UserId", T.string());

export const NoteEssentialSchema = T.object()
  .addField("id", T.string())
  .addField("originalCid", T.string())
  .addField("originalExtension", T.string())
  .addField("hash", T.string())
  .addField("hashAlg", T.string())
  .addField("ProjectId", T.string())
  .addField("UserId", T.string())
  .addField("CipherId", T.string(), false); // not implemented on this branch

export const NoteEssentialWithTXSchema = addTXInfo(NoteEssentialSchema.clone());

export type NoteEssential = {
  readonly id: string;
  readonly originalCid: string;
  readonly originalExtension: string;
  readonly hash: string;
  readonly hashAlg: string;
  readonly ProjectId: string;
  readonly UserId: string;
  readonly CipherId?: string; // not implemented on this branch
};

/**
 * 성공한 note essential 을 불러올 때 필요
 */
export type NoteEssentialWithTX = NoteEssential & TXInfo;

export const extractNoteEssentialWithTX =
  T.mkObjectExtractor<NoteEssentialWithTX>(NoteEssentialWithTXSchema);
const extractNoteEssentialHelper =
  T.mkObjectExtractor<NoteEssential>(NoteEssentialSchema);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const extractNoteEssential = (input: any): NoteEssential => {
  if (typeof input === "object") {
    if (input.hashAlg === undefined) {
      input.hashAlg = "sha256";
    }
  }
  return extractNoteEssentialHelper(input);
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const NoteTagObjectSchema = addAuth(
  addTag(NoteMetaObjectSchema.clone()),
  false
);

export const NoteDBObjectSchema = NoteTagObjectSchema.clone().addField(
  "User",
  UserObjectSchema.clone()
);

export const NoteObjectWithDocumentsSchema =
  NoteDBObjectSchema.clone().addField(
    "Documents",
    T.array(DocumentObjectSchema.clone())
  );

export const NoteObjectSchema = NoteTagObjectSchema.clone().addField(
  "User",
  UserObjectSchema.clone()
);

export const AuthNoteObjectSchema = addAuth(NoteTagObjectSchema.clone(), true);

export const AuthNoteObjectWithProjectSchema =
  AuthNoteObjectSchema.clone().addField(
    "Project",
    ProjectPureObjectSchema.clone()
  );

export const extractNoteTagObject =
  T.mkObjectExtractor<NoteTagObject>(NoteTagObjectSchema);
export const extractNoteObject =
  T.mkObjectExtractor<NoteObject>(NoteObjectSchema);
export const validateNoteObject = T.mkValidator<NoteObject>(NoteObjectSchema);
export const extractNoteDBObject =
  T.mkObjectExtractor<NoteDBObject>(NoteDBObjectSchema);
export const extractNoteObjectWithDocuments =
  T.mkObjectExtractor<NoteObjectWithDocuments>(NoteObjectWithDocumentsSchema);
export const extractNoteMetaObject =
  T.mkObjectExtractor<NoteMetaObject>(NoteMetaObjectSchema);
export const extractNoteMayHaveAuthObject = T.mkObjectExtractor<
  NoteObject | AuthNoteObject
>(NoteObjectSchema);
export const extractAuthNoteObject =
  T.mkObjectExtractor<AuthNoteObject>(AuthNoteObjectSchema);
export const validateAuthNoteObject =
  T.mkValidator<AuthNoteObject>(AuthNoteObjectSchema);
export const extractAuthNoteObjectWithProject =
  T.mkObjectExtractor<AuthNoteObjectWithProject>(
    AuthNoteObjectWithProjectSchema
  );
export const validateAuthNoteObjectWithProject =
  T.mkValidator<AuthNoteObjectWithProject>(AuthNoteObjectWithProjectSchema);
