export default {
  ko: {
    workspace_upgrade: {
      header:
        "나에게 적합한 <highlight>Plan<highlight>으로\n지금 바로 baSalt로 문서 관리를 시작하세요",
      title_1: "결제 플랜",
      start: "무료 체험 시작하기",
      purchase: "구매하기",
      enquire: "업그레이드 문의하기",
      month: "월",
      person: "1인",
      title_2: "제공 기능 사항",
      unlimited: "무제한",
      link: "10회 / 월",
      link_with_nda: "5회 / 월",
      plan: {
        team: {
          header: "Team",
          title: "최고 수준의 문서 관리와 보안을 위한 팀 전용 Plan",
          text: {
            item_1: "파일 업로드 무제한",
            item_2: "블록체인 인증서 제공",
            item_3: "워크스페이스 팀원 추가 가능",
            item_4: "외부 링크 생성 무제한",
            item_5: "NDA 첨부 무제한",
            item_6: "링크 인사이트 및 그래프 제공",
            item_7: "방문자 목록 제공",
          },
        },
        individual: {
          header: "Individual",
          title: "안전하게 문서를 외부 공유하고 싶은 개인 사용자 전용 Plan",
          text: {
            item_1: "파일 업로드 무제한",
            item_2: "워크스페이스 팀원 추가 불가능",
            item_3: "외부 링크 생성 10회/월 가능",
            item_4: "NDA 첨부 5회/월 가능",
            item_5: "링크 인사이트 및 그래프 제공",
          },
        },
        enterprise: {
          header: "Enterprise",
          title: "큰 규모의 팀 도입이 필요하신가요?",
          text: "팀에 최적화된 플랜으로 지금 바로 시작해보세요!",
        },
      },
      tables: {
        table_1: {
          row_1: "파일 업로드",
          row_2: "블록체인 인증서 제공",
          row_3: "워크스페이스 팀원 초대",
          row_4: "폴더 공유",
        },
        table_2: {
          title: "공유 링크 생성 관련",
          row_1: "링크 생성",
          row_2: "링크 NDA 첨부",
          row_3: "워크스페이스 서명 등록",
          row_4: "링크 메일 발송 ",
        },
        table_3: {
          title: "문서 내부 트래킹 관련",
          row_1: "워크스페이스 타임라인 제공",
          row_2: "타임라인 PDF 다운로드",
        },
        table_4: {
          title: "공유 링크 트래킹 관련",
          row_1: "페이지 별 요약 리포트 제공",
          row_2: "요약 리포트 PDF 다운로드",
          row_3: "방문자 목록 제공",
          row_4: "방문자 목록 Excel 다운로드",
        },
      },
    },
  },

  en: {
    workspace_upgrade: {
      header:
        "Managing documents with baSalt Docs with the right <highlight>plan<highlight> for you",
      title_1: "Payment plan",
      start: "Start for free",
      purchase: "Purchase",
      enquire: "Inquire about upgrading",
      month: "month",
      person: "person",
      title_2: "Features provided",
      unlimited: "Unlimited",
      link: "10 times / month",
      link_with_nda: "5 times / month",
      plan: {
        team: {
          header: "Team",
          title:
            "The team only plans for top-level document management and security",
          text: {
            item_1: "Unlimited file upload",
            item_2: "Provide a blockchain certificate",
            item_3: "Can add work space team members",
            item_4: "Unlimited creation of external links",
            item_5: "Unlimited NDA attachment",
            item_6: "Provide link insights and graphs",
            item_7: "Providing a list of visitors",
          },
        },
        individual: {
          header: "Individual",
          title:
            "Share documents safely with others, plan exclusively for Individual users",
          text: {
            item_1: "Unlimited file upload",
            item_2: "Can't add work space team members",
            item_3: "You can create an external link 10 times/month",
            item_4: "NDA can be attached 5 times/month",
            item_5: "Provide link insights and graphs",
          },
        },
        enterprise: {
          header: "Enterprise",
          title: "Introducing an Enterprising Plan",
          text: "Take advantage of an enterprise-optimized plan today",
        },
      },
      tables: {
        table_1: {
          row_1: "File Upload",
          row_2: "Provide blockchain certificate",
          row_3: "Invite Workspace Team Members",
          row_4: "Folder Sharing",
        },
        table_2: {
          title: "About creating shared links",
          row_1: "Create Link",
          row_2: "Attach link NDA",
          row_3: "Register Workspace Signature",
          row_4: "Send link email",
        },
        table_3: {
          title: "Document internal tracking",
          row_1: "Workspace timeline provided",
          row_2: "Timeline PDF Download",
        },
        table_4: {
          title: "Shared link tracking",
          row_1: "Provides summary reports for each page",
          row_2: "Download summary report PDF",
          row_3: "Provide visitor list",
          row_4: "Download Visitor List Excel",
        },
      },
    },
  },
};
