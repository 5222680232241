import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import Services from "@basalt-react-commons/services";
import {
  CommentStateMachineType,
  commentStateMachine,
  CommentState,
  CommentAction,
  CommentActionKind,
  CommentStateStatus,
} from "../../store/reducers/comment";
import { mkErr, InternalErrorKind } from "@redwit-commons/utils/exception2";
import { TokenStateStatus } from "../../store/reducers/token";
import { ChatObject } from "@basalt-commons/global-api/object/chat";
import { ICreateChat, IEditChat } from "@basalt-commons/api/request/chat";
import moment from "moment";
import { ProjectStateStatus } from "../../store/reducers/project";
import { ProjectAuthType } from "@basalt-commons/api/object/user_project_map";
import { WorkspaceStateStatus } from "../../store/reducers/workspace";
import { getProjectAuthLevel } from "@basalt-commons/global-api/object/user_project_map";

const { GlobalChatService } = Services;

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    reduxState: state.comment,
    project: state.project,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type CommentContainerProps = PropsFromRedux & {
  stateMachine: CommentStateMachineType;
};

const alignCommentWithCreateTime = (comments: ChatObject[]): ChatObject[] => {
  const sort = comments.sort((a, b) => {
    let aDate = moment(a.createdAt).toDate().getTime();
    let bDate = moment(b.createdAt).toDate().getTime();
    return aDate > bDate ? -1 : 1;
  });
  return sort;
};

const alignPinCommentWithCreateTime = (
  comments: ChatObject[]
): ChatObject[] => {
  const sort = comments.sort((a, b) => {
    let aDate = moment(a.createdAt).toDate().getTime();
    let bDate = moment(b.createdAt).toDate().getTime();
    return aDate <= bDate ? -1 : 1;
  });
  return sort;
};

class CommentContainer extends ReduxStateComponent3<CommentContainerProps> {
  static defaultProps = {
    stateMachine: commentStateMachine,
  };
  constructor(props: CommentContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: CommentState,
    action: CommentAction
  ): Promise<CommentState> {
    const { token, workspace } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "CommentContainer",
        msg: "user not loggin-in",
      });
    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "CommentContainer",
        msg: "workspace status invalid",
      });
    if (workspace.state.selectAuthWorkspace === undefined)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "CommentContainer",
        msg: "selectAuthWorkspace is undefined",
      });

    const userId = token.state.id;
    const userToken = token.state.token;
    switch (action.kind) {
      case CommentActionKind.TRY_GET_COMMENT: {
        if (workspace.state.selectAuthWorkspace.name !== action.workspaceName)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: CommentActionKind.TRY_GET_COMMENT,
            msg: "selectAuthWorkspace is old",
          });
        const workspaceId = workspace.state.selectAuthWorkspace.id;
        const ret = await this.guardAwait(() =>
          GlobalChatService.getChats(userToken, action.projectId, action.noteId)
        );
        const pinComments = ret.filter(
          (chat) =>
            chat.pinnedUserId !== undefined && chat.pinnedUserId.length > 1
        );
        const unpinedComments = ret.filter(
          (chat) =>
            chat.pinnedUserId === undefined || chat.pinnedUserId.length < 1
        );
        const alignPinComments = alignPinCommentWithCreateTime(pinComments);
        const alignUnpinComments = alignCommentWithCreateTime(unpinedComments);
        const comments = [...alignPinComments, ...alignUnpinComments];
        return {
          status: CommentStateStatus.SUCCESS,
          workspaceId,
          noteId: action.noteId,
          projectId: action.projectId,
          comments,
        };
      }
      case CommentActionKind.TRY_CREATE_COMMENT: {
        if (prevState.status !== CommentStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: CommentActionKind.TRY_CREATE_COMMENT,
            msg: "prevState is invalid",
          });

        // Check user auth. Only Write and above can perform this action
        const targetProject =
          this.props.project.state.status === ProjectStateStatus.SUCCESS
            ? this.props.project.state.projects.find(
                (pj) => pj.id === prevState.projectId
              )
            : undefined; // target project 확인하기
        const userAuth = targetProject?.Users.find(
          (u) => u.id === userId
        )?.authType;
        if (
          getProjectAuthLevel(userAuth) <
          getProjectAuthLevel(ProjectAuthType.WRITE)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: CommentActionKind.TRY_CREATE_COMMENT,
            msg: "Only `Write` and above can upload notes",
          });

        const chatArgs: ICreateChat = {
          pageNum: action.pageNum,
          text: action.text,
          mentionIds: action.mentionIds === undefined ? [] : action.mentionIds,
          isPinned: action.isPinned,
        };

        const ret = await this.guardAwait(() =>
          GlobalChatService.createChat(
            userToken,
            prevState.workspaceId,
            prevState.projectId,
            prevState.noteId,
            chatArgs
          )
        );

        const newComments = [ret, ...prevState.comments];
        const pinComments = newComments.filter(
          (chat) =>
            chat.pinnedUserId !== undefined && chat.pinnedUserId.length > 1
        );
        const unpinedComments = newComments.filter(
          (chat) =>
            chat.pinnedUserId === undefined || chat.pinnedUserId.length < 1
        );
        const alignPinComments = alignPinCommentWithCreateTime(pinComments);
        const alignUnpinComments = alignCommentWithCreateTime(unpinedComments);
        const comments = [...alignPinComments, ...alignUnpinComments];
        return { ...prevState, comments };
      }
      case CommentActionKind.TRY_EDIT_COMMENT: {
        if (prevState.status !== CommentStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: CommentActionKind.TRY_EDIT_COMMENT,
            msg: "prevState is invalid",
          });

        // Check user auth. Only Write and above can perform this action
        const targetProject =
          this.props.project.state.status === ProjectStateStatus.SUCCESS
            ? this.props.project.state.projects.find(
                (pj) => pj.id === prevState.projectId
              )
            : undefined; // target project 확인하기
        const userAuth = targetProject?.Users.find(
          (u) => u.id === userId
        )?.authType;
        if (
          getProjectAuthLevel(userAuth) <
          getProjectAuthLevel(ProjectAuthType.WRITE)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: CommentActionKind.TRY_EDIT_COMMENT,
            msg: "Only `Write` and above can upload notes",
          });

        const targetCommentId = action.commentId;
        const targetComment = prevState.comments.find(
          (c) => c.id === targetCommentId
        );
        if (targetComment === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: CommentActionKind.TRY_EDIT_COMMENT,
            msg: "id is invalid",
          });

        const workspaceId =
          this.props.project.state.status === ProjectStateStatus.SUCCESS
            ? this.props.project.state.selectProject?.WorkspaceId
            : undefined;
        if (workspaceId === undefined) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "CommentActionKind.TRY_EDIT_COMMENT",
            msg: "WorkspaceId is undefined",
          });
        }
        const chatArgs: IEditChat = {
          ...targetComment,
          pageNum: action.pageNum,
          text: action.text,
        };
        const ret = await this.guardAwait(() =>
          GlobalChatService.updateChat(
            userToken,
            prevState.workspaceId,
            prevState.projectId,
            prevState.noteId,
            targetCommentId,
            chatArgs
          )
        );
        const newComments = prevState.comments.map((c) =>
          c.id === targetCommentId ? ret : c
        );
        return { ...prevState, comments: newComments };
      }
      case CommentActionKind.TRY_PIN_COMMENT: {
        if (prevState.status !== CommentStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: CommentActionKind.TRY_EDIT_COMMENT,
            msg: "prevState is invalid",
          });
        const prevComments = prevState.comments;
        const { commentId } = action;
        const targetComment = prevComments.find((c) => c.id === commentId);
        if (targetComment === undefined) {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: CommentActionKind.TRY_EDIT_COMMENT,
            msg: "comment가 없음",
          });
        }

        const workspaceId =
          this.props.project.state.status === ProjectStateStatus.SUCCESS
            ? this.props.project.state.selectProject?.WorkspaceId
            : undefined;
        if (workspaceId === undefined) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "CommentActionKind.TRY_PIN_COMMENT",
            msg: "WorkspaceId is undefined",
          });
        }

        const editComment: IEditChat = {
          ...targetComment,
          mentionIds: [],
          pinnedUserId:
            targetComment.pinnedUserId === undefined ||
            targetComment.pinnedUserId.length < 1
              ? userId
              : "",
        };

        await this.guardAwait(() =>
          GlobalChatService.updateChat(
            userToken,
            prevState.workspaceId,
            prevState.projectId,
            prevState.noteId,
            action.commentId,
            editComment
          )
        );
        const ret = await this.guardAwait(() =>
          GlobalChatService.getChats(
            userToken,
            prevState.projectId,
            prevState.noteId
          )
        );
        const pinComments = ret.filter(
          (chat) =>
            chat.pinnedUserId !== undefined && chat.pinnedUserId.length > 1
        );
        const unpinedComments = ret.filter(
          (chat) =>
            chat.pinnedUserId === undefined || chat.pinnedUserId.length < 1
        );
        const alignPinComments = alignPinCommentWithCreateTime(pinComments);
        const alignUnpinComments = alignCommentWithCreateTime(unpinedComments);
        const comments = [...alignPinComments, ...alignUnpinComments];
        return { ...prevState, comments };
      }
      case CommentActionKind.TRY_REMOVE_COMMENT: {
        if (prevState.status !== CommentStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: CommentActionKind.TRY_REMOVE_COMMENT,
            msg: "prevState is invalid",
          });

        // Check user auth. Only Write and above can perform this action
        const targetProject =
          this.props.project.state.status === ProjectStateStatus.SUCCESS
            ? this.props.project.state.projects.find(
                (pj) => pj.id === prevState.projectId
              )
            : undefined; // target project 확인하기
        const userAuth = targetProject?.Users.find(
          (u) => u.id === userId
        )?.authType;
        if (
          targetProject === undefined ||
          getProjectAuthLevel(userAuth) <
            getProjectAuthLevel(ProjectAuthType.WRITE)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: CommentActionKind.TRY_REMOVE_COMMENT,
            msg: "Only `Write` and above can upload notes",
          });

        const targetCommentId = action.commentId;
        const noteId = prevState.noteId;
        const targetComment = prevState.comments.find(
          (c) => c.id === targetCommentId
        );
        if (targetComment === undefined || targetComment.UserId !== userId) {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: CommentActionKind.TRY_REMOVE_COMMENT,
            msg: "not comments owner",
          });
        }
        await this.guardAwait(() =>
          GlobalChatService.deleteChat(
            userToken,
            targetProject.id,
            noteId,
            targetCommentId
          )
        );
        const newComments = prevState.comments.filter(
          (c) => c.id !== targetCommentId
        );
        return { ...prevState, comments: newComments };
      }
    }
  }
}

export default connector(CommentContainer);
