import { ProjectObject, ProjectObjectSchema } from "../../api/object/project";
import {
  GoonoArrayResponse,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "../../api/response/common";
import T from "@redwit-commons/utils/typecheck";
import {
  TokenLogWithUserObject,
  TokenLogWithUserObjectSchema,
} from "../object/token_log";
/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum JoinProjectErrorType {
  NOT_FOUND_TOKEN = "JoinProjectError::NOT_FOUND_TOKEN",
  NOT_FOUND_WORKSPACE = "JoinProjectError::NOT_FOUND_WORKSPACE",
  NOT_FOUND_PROJECT = "JoinProjectError::NOT_FOUND_PROJECT",
  ALREADY_JOIN = "JoinProjectError::ALREADY_JOIN",
  NOT_FOUND_PLAN = "JoinProjectError::NOT_FOUND_PLAN",
  INVALID_TOKEN = "JoinProjectError::INVALID_TOKEN",
  INVALID_SHARE_AUTH = "JoinProjectError::INVALID_SHARE_AUTH",
  REJECT_INDIVIDUAL_PLAN = "JoinProjectError::REJECT_INDIVIDUAL_PLAN",
}
export type OSetDefaultNDA = GoonoResponse<ProjectObject>;
export type OUpdateOwner = GoonoResponse<ProjectObject>;
export type OUpdatePublic = GoonoResponse<ProjectObject>;
export type OGetProjectShareEmailList =
  GoonoArrayResponse<TokenLogWithUserObject>;
export type OUpdateProjectAuthSharedEmail =
  GoonoResponse<TokenLogWithUserObject>;
export type ODeleteProjectSharedEmail = GoonoResponse<string>;

export const OSetDefaultNDASchema = mkResponseSchema(ProjectObjectSchema);
export const OUpdateOwnerSchema = mkResponseSchema(ProjectObjectSchema);
export const OUpdatePublicSchema = mkResponseSchema(ProjectObjectSchema);
export const OGetProjectShareEmailListSchema = mkArrayResultsSchema(
  TokenLogWithUserObjectSchema
);
export const OUpdateProjectAuthSharedEmailSchema = mkResponseSchema(
  TokenLogWithUserObjectSchema
);
export const ODeleteProjectSharedEmailSchema = mkResponseSchema(T.string());

export const validateOSetDefaultNDA =
  T.mkValidator<OSetDefaultNDA>(OSetDefaultNDASchema);
export const validateOUpdateOwner =
  T.mkValidator<OUpdateOwner>(OUpdateOwnerSchema);
export const validateOUpdatePublic =
  T.mkValidator<OUpdatePublic>(OUpdatePublicSchema);
export const validateOGetProjectShareEmailList =
  T.mkValidator<OGetProjectShareEmailList>(OGetProjectShareEmailListSchema);
export const validateOUpdateProjectAuthSharedEmail =
  T.mkValidator<OUpdateProjectAuthSharedEmail>(
    OUpdateProjectAuthSharedEmailSchema
  );
export const validateODeleteProjectSharedEmail =
  T.mkValidator<ODeleteProjectSharedEmail>(ODeleteProjectSharedEmailSchema);
