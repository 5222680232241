import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";

export interface TaskObjectCoreWithoutJSON {
  readonly id: string;
  readonly type: TaskType | string;
  readonly status: TaskStatusType | string;
  readonly UserId: string; // Relation
}

export type TaskObjectCore = {
  json: string;
} & TaskObjectCoreWithoutJSON;

export type TaskObject = TaskObjectCore & AccessTime;
export type TaskObjectWithOutJSON = TaskObjectCoreWithoutJSON & AccessTime;

export const TaskObjectWithoutJSONSchema = withAccessTime()
  .addField("id", T.string())
  .addField("type", T.string())
  .addField("status", T.string())
  .addField("UserId", T.string());

export const TaskObjectSchema = TaskObjectWithoutJSONSchema.clone().addField(
  "json",
  T.string()
);

export enum TaskType {
  TEST = "Task::TEST",
  SEND_EMAIL = "Task::SEND_EMAIL",
  MERGE_NOTES = "Task::MERGE_NOTES",
  DOCUMENT = "Task::DOCUMENT",
  RULE_CONTRACT = "Task::RULE_CONTRACT",
  NDA_CONTRACT = "Task::NDA_CONTRACT",
}

export enum TaskStatusType {
  INITIALIZED = "Task::INITIALIZED",
  COMPLETED = "Task::COMPLETED",
  CANCELLED = "Task::CANCELLED",
}
export const extractTaskObject =
  T.mkObjectExtractor<TaskObject>(TaskObjectSchema);
export const extractTaskObjectWithoutJSON =
  T.mkObjectExtractor<TaskObjectWithOutJSON>(TaskObjectWithoutJSONSchema);
