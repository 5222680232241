import React from "react";
import { Grid, WithStyles, withStyles, ButtonBase } from "@material-ui/core";
import SpoqaHansTypography from "@SpoqaHansTypography";
import { theme } from "@theme";
import moment from "moment-timezone";
import { ContractObject } from "@basalt-commons/global-api/object/nda_contract";
import { DocumentObject } from "@basalt-commons/global-api/object/document";
import styles from "./styles/NDAItem";
import { RouteComponentProps, withRouter } from "react-router-dom";
import translate from "../../../utils/translate";

type Props = {
  workspace_name: string;
};

type NDAItemProps = {
  contract: ContractObject;
  doc: DocumentObject | undefined;
  time_zone: string;
  format: string;
} & WithStyles<typeof styles> &
  RouteComponentProps<Props>;

type NDAItemState = {
  hover: boolean;
};

class NDASignatureItem extends React.PureComponent<NDAItemProps, NDAItemState> {
  constructor(props: NDAItemProps) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  renderFileName() {
    const { contract, doc, classes } = this.props;
    return (
      <Grid
        container
        item
        xs={6}
        alignItems="center"
        className={classes.nda_sign_history_grid_file}
      >
        <SpoqaHansTypography noWrap className={classes.nda_sign_history_file}>
          {doc?.file_name}
        </SpoqaHansTypography>

        {this.state.hover && (
          <ButtonBase
            className={classes.preview_button}
            onClick={() => {
              this.props.history.push(
                `/${this.props.match.params.workspace_name}/preview/contract/${contract.id}`
              );
            }}
          >
            <SpoqaHansTypography
              noWrap
              fontWeight="medium"
              className={classes.preview_button_text}
            >
              {translate.nda_item.preview}
            </SpoqaHansTypography>
          </ButtonBase>
        )}
      </Grid>
    );
  }

  renderSignRequester() {
    const { doc, classes } = this.props;
    return (
      <Grid
        container
        item
        xs={2}
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        className={classes.nda_sign_history_grid}
      >
        <SpoqaHansTypography
          fontWeight="medium"
          className={classes.nda_sign_history_name}
          noWrap
        >
          {doc?.nda_contractor_name}
        </SpoqaHansTypography>
        <SpoqaHansTypography
          fontWeight="medium"
          className={classes.nda_sign_history_inst}
          noWrap
        >
          {doc?.nda_contractor_department}
        </SpoqaHansTypography>
      </Grid>
    );
  }

  renderSigner() {
    const { contract, classes } = this.props;
    return (
      <Grid container item xs={2} className={classes.nda_sign_history_grid}>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <SpoqaHansTypography
            fontWeight="medium"
            className={classes.nda_sign_history_name}
            noWrap
          >
            {contract.client_name}
          </SpoqaHansTypography>
          <SpoqaHansTypography
            fontWeight="medium"
            className={classes.nda_sign_history_inst}
            noWrap
          >
            {contract.client_department}
          </SpoqaHansTypography>
        </Grid>
      </Grid>
    );
  }

  renderSignDate() {
    const { contract, time_zone, format, classes } = this.props;
    return (
      <Grid
        container
        item
        xs={2}
        alignItems="center"
        className={classes.nda_sign_history_grid}
      >
        <SpoqaHansTypography className={classes.nda_sign_history_date} noWrap>
          {moment(contract.createdAt).tz(time_zone).format(format)}
        </SpoqaHansTypography>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        item
        className={classes.nda_sign_history_item}
        style={{
          backgroundColor: this.state.hover ? theme.gray_1 : theme.gray_0,
        }}
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
      >
        {this.renderFileName()}
        {this.renderSignRequester()}
        {this.renderSigner()}
        {this.renderSignDate()}
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(NDASignatureItem));
