import { doAPIGetRequest, doAPIPostRequest } from "./api";
import {
  OGetWorkspaceInfo,
  validateOGetWorkspaceInfo,
  OCreatePlan,
  validateOCreatePlan,
  OUpdatePlan,
  validateOUpdatePlan,
} from "@basalt-commons/global-api/response/admin";
import {
  ICreatePlan,
  IUpdatePlan,
} from "@basalt-commons/global-api/request/admin";

const getWorkspaceInfoList = async (
  token: string
): Promise<OGetWorkspaceInfo> => {
  const obj = await doAPIGetRequest(`basalt/admin/workspaces`, token);
  const ret = validateOGetWorkspaceInfo(obj);
  return ret;
};
const createPlan = async (
  token: string,
  args: ICreatePlan
): Promise<OCreatePlan> => {
  const obj = await doAPIPostRequest(`/basalt/admin/create/plan`, token, args);
  const ret = validateOCreatePlan(obj);
  return ret;
};
const updatePlan = async (
  token: string,
  args: IUpdatePlan
): Promise<OUpdatePlan> => {
  const obj = await doAPIPostRequest(`/basalt/admin/update/plan`, token, args);
  const ret = validateOUpdatePlan(obj);
  return ret;
};

export default {
  getWorkspaceInfoList,
  createPlan,
  updatePlan,
};
