import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  NDAAction,
  NDAActionKind,
  NDAState,
  ndaStateMachine,
  NDAStateMachineType,
  NDAStateStatus,
} from "../../store/reducers/nda";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { TokenStateStatus } from "../../store/reducers/token";
import Services from "@basalt-react-commons/services";
import { WorkspaceStateStatus } from "../../store/reducers/workspace";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";
import moment from "moment";
import { saveAs } from "file-saver";

const { NDAService, IPFSService, NDAContractService, TaskService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.nda,
    token: state.token,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type NDAContainerProps = PropsFromRedux & { stateMachine: NDAStateMachineType };

class NDAContainer extends ReduxStateComponent3<NDAContainerProps> {
  static defaultProps = { stateMachine: ndaStateMachine };
  constructor(props: NDAContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: NDAState,
    action: NDAAction
  ): Promise<NDAState> {
    const getToken = () => {
      const { token } = this.props;
      if (token.state.status !== TokenStateStatus.SUCCESS)
        throw mkErr({
          kind: InternalErrorKind.Fatal,
          loc: "LogContainer::onAction",
          msg: "not goono login",
        });
      return token.state.token;
    };
    const getWorkspaceId = () => {
      const { workspace } = this.props;
      if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
        throw mkErr({
          kind: InternalErrorKind.Fatal,
          loc: "LogContainer::onAction",
          msg: "no selectAuthWorkspace",
        });
      return workspace.state.selectAuthWorkspace.id;
    };

    switch (action.kind) {
      case NDAActionKind.TRY_CREATE: {
        if (prevState.status !== NDAStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NDAActionKind.TRY_CREATE,
            msg: "invalid prevState",
          });
        const { file, customNDAInfo, description } = action;
        const userToken = getToken();
        const workspaceId = getWorkspaceId();
        // only allow pdf
        const ipfs_cid = await this.guardAwait(() =>
          IPFSService.uploadFileIPFS(userToken, file, "pdf")
        );
        await this.guardAwait(() =>
          NDAService.createNDA(userToken, workspaceId, {
            cid: ipfs_cid,
            extension: "pdf",
            file_name: file.name,
            isCustom: customNDAInfo !== undefined,
            pdfInfo: customNDAInfo,
            description,
          })
        );
        const ret = await this.guardAwait(() =>
          NDAService.getNDAList(userToken, workspaceId)
        );
        return { ...prevState, ndas: ret };
      }
      case NDAActionKind.TRY_UPDATE_NDA: {
        if (prevState.status !== NDAStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NDAActionKind.TRY_UPDATE_NDA,
            msg: "invalid prevState",
          });
        const { nda_id, description } = action;
        const userToken = getToken();
        const workspaceId = getWorkspaceId();
        await this.guardAwait(() =>
          NDAService.updateNDA(userToken, workspaceId, nda_id, { description })
        );
        const ret = (
          await this.guardAwait(() =>
            NDAService.getNDAList(userToken, workspaceId)
          )
        ).sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
        return { ...prevState, ndas: ret };
      }
      case NDAActionKind.TRY_GET_CONTRACT: {
        const ret = await this.guardAwait(() =>
          NDAContractService.getNdaContract(action.contractId)
        );
        return {
          status: NDAStateStatus.SUCCESS,
          contract: ret.response,
          ndas:
            prevState.status === NDAStateStatus.SUCCESS ? prevState.ndas : [],
        };
      }
      case NDAActionKind.TRY_GET_CONTRACT_LIST: {
        if (prevState.status !== NDAStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NDAActionKind.TRY_GET_CONTRACT_LIST,
            msg: "invalid prevState",
          });
        const userToken = getToken();
        const workspaceId = getWorkspaceId();
        const ret = await this.guardAwait(() =>
          NDAContractService.getNdaContractList(userToken, workspaceId)
        );

        return { ...prevState, workspaceWithNdaContract: ret.response };
      }
      case NDAActionKind.TRY_SHARE_CONTRACT: {
        if (prevState.status !== NDAStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NDAActionKind.TRY_SHARE_CONTRACT,
            msg: "invalid prevState",
          });
        const { contractId, emails } = action;
        const userToken = getToken();
        const workspaceId = getWorkspaceId();
        const message =
          action.message.trim().length > 0 ? action.message : undefined;
        await this.guardAwait(() =>
          NDAContractService.shareNDAContract(
            userToken,
            workspaceId,
            contractId,
            { emails: emails, message: message }
          )
        );

        return prevState;
      }
      case NDAActionKind.TRY_DOWNLOAD_CONTRACT: {
        if (prevState.status !== NDAStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NDAActionKind.TRY_DOWNLOAD_CONTRACT,
            msg: "invalid prevState",
          });
        const { contract } = action;
        const userToken = getToken();
        const ret = await this.guardAwait(() =>
          NDAContractService.checkDownloadableNdaContract(
            userToken,
            contract.id
          )
        );
        if (ret.response.available) {
          window.open(getIPFSUrl(contract.cid), "_blank");
          saveAs(getIPFSUrl(contract.cid), contract.file_name);
        } else {
          if (ret.response.message !== undefined) {
            throw mkErr({
              kind: InternalErrorKind.Abort,
              loc: NDAActionKind.TRY_DOWNLOAD_CONTRACT,
              msg: ret.response.message,
            });
          }
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NDAActionKind.TRY_DOWNLOAD_CONTRACT,
            msg: "download unavailable",
          });
        }

        return prevState;
      }
      case NDAActionKind.TRY_GET_NDAS: {
        const userToken = getToken();
        const workspaceId = getWorkspaceId();
        const ret = await this.guardAwait(() =>
          NDAService.getNDAList(userToken, workspaceId)
        );
        const sort = ret.sort((a, b) => {
          let aDate = moment(a.createdAt).toDate().getTime();
          let bDate = moment(b.createdAt).toDate().getTime();
          return aDate > bDate ? -1 : 1;
        });
        if (prevState.status === NDAStateStatus.INIT) {
          return {
            status: NDAStateStatus.SUCCESS,
            ndas: sort,
          };
        }
        return {
          ...prevState,
          ndas: sort,
        };
      }
      case NDAActionKind.TRY_DELETE_NDA: {
        if (prevState.status !== NDAStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NDAActionKind.TRY_DELETE_NDA,
            msg: "invalid prevState",
          });
        const { nda_id } = action;
        const userToken = getToken();
        const workspaceId = getWorkspaceId();
        const retId = await this.guardAwait(() =>
          NDAService.deleteNDA(userToken, workspaceId, nda_id)
        );
        const prevNdas = prevState.ndas;
        const newNdas = prevNdas.filter((nda) => nda.id !== retId);
        return { ...prevState, ndas: newNdas };
      }
      case NDAActionKind.TRY_DOWNLOAD_ALL_CONTRACT: {
        if (prevState.status !== NDAStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NDAActionKind.TRY_DOWNLOAD_ALL_CONTRACT,
            msg: "Invalid prev state",
          });
        const userToken = getToken();
        const { workspaceId, NdaId } = action;
        // ZIP 파일 입니다.
        const contractsArchivedZip = await this.guardAwait(() =>
          NDAContractService.getNDAContractAllPDF(userToken, workspaceId, {
            NdaId,
          })
        );

        // 시간이 걸리는 작업이므로 Task처리를 필요로 한다.
        const taskResult = await this.guardAwait(() =>
          TaskService.waitforTaskComplete(
            contractsArchivedZip.response.id,
            userToken
          )
        );
        if (taskResult) {
          const zip = await this.guardAwait(() =>
            IPFSService.getIPFSZipUrl(
              userToken,
              contractsArchivedZip.pending_tasks.pdf,
              contractsArchivedZip.response.UserId
            )
          );
          const filePath = IPFSService.getIPFSUrl(zip) + ".zip";
          return { ...prevState, filePath };
        } else {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NDAActionKind.TRY_DOWNLOAD_ALL_CONTRACT,
            msg: "pdf get failed",
          });
        }
      }
    }
  }
}

export default connector(NDAContainer);
