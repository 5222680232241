interface mixPanelParmas {
  eventName: string;
  object?: any;
}

export const mixPanelTrackIdentify = async (
  user_name: string,
  user_property: any
): Promise<void> => {
  try {
    const mixPanel = await getMixPanel();
    mixPanel.identify(user_name);
    mixPanel.people.set(user_property);
  } catch (err) {
    console.log(err);
  }
};

export const mixPanelTrackEvent = async (
  params: mixPanelParmas
): Promise<void> => {
  try {
    const mixPanel = await getMixPanel();
    mixPanel.track(params.eventName, params.object);
  } catch (err) {
    console.log(err);
  }
};

const getMixPanel = () => {
  if (typeof window == "undefined") {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.mixpanel) {
    return console.log("gtm event called but, not exist");
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.mixpanel || [];
};
