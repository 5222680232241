import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  DocumentAction,
  DocumentActionKind,
  DocumentState,
  documentStateMachine,
  DocumentStateMachineType,
  DocumentStateStatus,
} from "../../store/reducers/document";
import Services from "@basalt-react-commons/services";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { TokenStateStatus } from "../../store/reducers/token";
import { WorkspaceStateStatus } from "../../store/reducers/workspace";
import { ProjectStateStatus } from "../../store/reducers/project";
import { DocumentStatus } from "@basalt-commons/global-api/object/document";
import moment from "moment-timezone";

const { DocumentService, NDAContractService, IPFSService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.document,
    token: state.token,
    workspace: state.workspace,
    project: state.project,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type DocumentContainerProps = PropsFromRedux & {
  stateMachine: DocumentStateMachineType;
};

class DocumentContainer extends ReduxStateComponent3<DocumentContainerProps> {
  static defaultProps = { stateMachine: documentStateMachine };
  constructor(props: DocumentContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: DocumentState,
    action: DocumentAction
  ): Promise<DocumentState> {
    const { token, workspace, project } = this.props;
    switch (action.kind) {
      case DocumentActionKind.TRY_GET_INFO: {
        const { id } = action;
        const ret = await this.guardAwait(() =>
          DocumentService.getDocumentShareInfo(id)
        );

        return {
          ...prevState,
          decoded_info: ret.response,
          token: ret.response.documentToken,
        };
      }
      case DocumentActionKind.TRY_RESET: {
        return { status: DocumentStateStatus.INIT, need_sign: false };
      }
      case DocumentActionKind.TRY_CREATE_CONTRACT: {
        const userToken =
          token.state.status === TokenStateStatus.SUCCESS
            ? token.state.token
            : undefined;
        if (userToken === undefined) {
          // When the user is not logged in
          await this.guardAwait(() =>
            NDAContractService.createGuestNDAContract({ ...action })
          );
          return prevState;
        } else {
          await this.guardAwait(() =>
            NDAContractService.createNDAContract(userToken, { ...action })
          );
          return { ...prevState, need_sign: false };
        }
      }
      case DocumentActionKind.TRY_SHARE: {
        if (token.state.status !== TokenStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_SHARE,
            msg: "not login",
          });
        }

        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_SHARE,
            msg: "workspace status invalid",
          });

        if (project.state.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_SHARE,
            msg: "project status invalid",
          });

        if (workspace.state.selectAuthWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_SHARE,
            msg: "selectAuthWorkspace is undefined",
          });

        if (project.state.selectProject === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_SHARE,
            msg: "selectProject is undefined",
          });

        const userToken = token.state.token;
        const workspaceId = workspace.state.selectAuthWorkspace.id;
        const projectId = project.state.selectProject.id;
        const documentId = action.DocumentId;
        const message =
          action.message.trim().length > 0 ? action.message : undefined;

        await this.guardAwait(() =>
          DocumentService.shareDocument(
            userToken,
            workspaceId,
            projectId,
            documentId,
            { emails: action.emails, message: message }
          )
        );
        return { ...prevState };
      }
      case DocumentActionKind.TRY_UPDATE_STATUS: {
        if (token.state.status !== TokenStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_UPDATE_STATUS,
            msg: "not login",
          });
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_UPDATE_STATUS,
            msg: "workspace status invalid",
          });

        if (project.state.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_UPDATE_STATUS,
            msg: "project status invalid",
          });

        if (workspace.state.selectAuthWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_UPDATE_STATUS,
            msg: "selectAuthWorkspace is undefined",
          });

        const userToken = token.state.token;
        const time_zone = token.state.time_zone;

        const workspace_id = workspace.state.selectAuthWorkspace.id;
        const { doc } = action;
        const project_id = doc.ProjectId;
        const time_zone_ =
          time_zone === undefined ? moment.tz.guess() : time_zone;
        if (
          doc.status === DocumentStatus.INACTIVE &&
          moment().tz(time_zone_).isAfter(moment(doc.expires_in).tz(time_zone_))
        ) {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: DocumentActionKind.TRY_UPDATE_STATUS,
            msg: "link is expired",
          });
        }
        const status =
          doc.status === DocumentStatus.ACTIVE
            ? DocumentStatus.INACTIVE
            : DocumentStatus.ACTIVE;
        const ret = await this.guardAwait(() =>
          DocumentService.updateDocumentStatus(
            userToken,
            workspace_id,
            project_id,
            doc.id,
            { status }
          )
        );
        return { ...prevState, decoded_info: ret.response };
      }
      case DocumentActionKind.TRY_CHECK_CONTRACT: {
        if (token.state.status !== TokenStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: DocumentActionKind.TRY_CHECK_CONTRACT,
            msg: "not login",
          });
        const userToken = token.state.token;
        const ret = await this.guardAwait(() =>
          NDAContractService.checkSignDocument(
            userToken,
            action.NdaId,
            action.DocumentId
          )
        );
        return {
          ...prevState,
          need_sign: ret.response.need_sign,
        };
      }
      case DocumentActionKind.TRY_DOWNLOAD_FILE: {
        const originFile = await this.guardAwait(() =>
          DocumentService.getOriginNote(action.args)
        );
        const filePath = IPFSService.getIPFSUrl(
          originFile.response.originalCid
        );
        return { ...prevState, filePath };
      }
    }
  }
}

export default connector(DocumentContainer);
