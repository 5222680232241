import { theme, buttons } from "@theme";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((MuiTheme) => ({
  base: { textTransform: "none" },
  xs: {
    ...theme.button_xs,
    [MuiTheme.breakpoints.only("xs")]: { padding: "9px 8px" },
  },
  xs_text: { ...theme.button_xs_text },
  small: { ...theme.button_small },
  small_text: { ...theme.button_small_text },
  default: { ...theme.button_default },
  default_text: {
    ...theme.spoqahans_bold,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "visible",
  },
  large: { ...theme.button_default, height: 24 },
  large_text: { ...theme.button_default_text, height: 24 },

  adornment: {
    marginRight: 6,
    height: 24,
    [MuiTheme.breakpoints.only("xs")]: {
      marginRight: 4,
      height: 16,
      alignSelf: "self-start",
    },
  },
  adornment_end: {
    marginLeft: 4,
    height: 24,
    [MuiTheme.breakpoints.only("xs")]: { height: 16, alignSelf: "self-start" },
  },

  primary_button: {
    ...buttons.primary,
    [MuiTheme.breakpoints.only("xs")]: { borderRadius: 6 },
    "&:hover": {
      backgroundColor: theme.indigo_8,
    },

    "&:disabled": {
      backgroundColor: theme.gray_1,
      borderColor: theme.gray_1,
      color: theme.gray_4,
    },

    "&:active": {
      backgroundColor: theme.indigo_9,
    },
  },
  secondary_button: {
    ...buttons.secondary,
    [MuiTheme.breakpoints.only("xs")]: { borderRadius: 6 },
    "&:hover": {
      backgroundColor: theme.indigo_2,
    },

    "&:disabled": {
      backgroundColor: theme.gray_1,
      color: theme.gray_4,
    },

    "&:active": {
      backgroundColor: theme.indigo_3,
      color: theme.indigo_9,
    },
  },
  default_button: {
    ...buttons.default,
    [MuiTheme.breakpoints.only("xs")]: { borderRadius: 6 },
    "&:hover": {
      backgroundColor: theme.gray_1,
      borderColor: theme.indigo_3,
    },

    "&:disabled": {
      backgroundColor: theme.white,
      borderColor: theme.gray_1,
      color: theme.gray_4,
    },

    "&:active": {
      backgroundColor: theme.gray_2,
      borderColor: theme.indigo_3,
    },
  },
  default_outlined_button: {
    ...buttons.default_outlined,
    [MuiTheme.breakpoints.only("xs")]: { borderRadius: 6 },
    "&:hover": {
      backgroundColor: theme.gray_0,
    },
    "&:disabled": {
      backgroundColor: theme.white,
      borderColor: theme.gray_2,
      color: theme.gray_2,
    },
    "&:active": {
      backgroundColor: theme.gray_2,
    },
  },
  white_button: {
    ...buttons.default_outlined,
    [MuiTheme.breakpoints.only("xs")]: { borderRadius: 6 },
    "&:hover": {
      backgroundColor: theme.gray_1,
      borderColor: theme.gray_3,
    },
    "&:disabled": {
      backgroundColor: theme.white,
      borderColor: theme.gray_1,
      color: theme.gray_4,
    },
    "&:active": {
      backgroundColor: theme.gray_2,
      borderColor: theme.gray_4,
    },
  },
  danger_button: {
    ...buttons.danger,
    [MuiTheme.breakpoints.only("xs")]: { borderRadius: 6 },
    "&:hover": {
      backgroundColor: theme.red_0,
    },
    "&:disabled": {
      backgroundColor: theme.white,
      borderColor: theme.gray_2,
      color: theme.gray_2,
    },
    "&:active": {
      backgroundColor: theme.system_red_bg_pressed,
    },
  },
  danger_fill_button: {
    ...buttons.danger_fill,
    [MuiTheme.breakpoints.only("xs")]: { borderRadius: 6 },
    "&:hover": {
      backgroundColor: "#c92a2a",
    },
    "&:disabled": {
      backgroundColor: theme.white,
      borderColor: theme.gray_2,
      color: theme.gray_2,
    },
    "&:active": {
      backgroundColor: "#b22727",
    },
  },
  cyan_button: {
    ...buttons.cyan,
    [MuiTheme.breakpoints.only("xs")]: { borderRadius: 6 },
    "&:hover": {
      backgroundColor: theme.cian_8,
    },

    "&:disabled": {
      backgroundColor: theme.cian_1,
      color: theme.white,
    },

    "&:active": {
      backgroundColor: theme.cian_9,
    },
  },

  cyan_outlined_button: {
    ...buttons.cyan_outlined,
    [MuiTheme.breakpoints.only("xs")]: { borderRadius: 6 },
    "&:hover": {
      backgroundColor: theme.gray_1,
      borderColor: theme.cian_8,
    },

    "&:disabled": {
      backgroundColor: theme.white,
      borderColor: theme.cian_1,
      color: theme.cian_1,
    },

    "&:active": {
      backgroundColor: theme.gray_2,
      borderColor: theme.cian_9,
    },
  },

  gray_button: {
    ...buttons.gray_outlined,
    "&:hover": {
      backgroundColor: theme.gray_1,
      borderColor: theme.gray_3,
    },

    "&:disabled": {
      backgroundColor: theme.white,
      borderColor: theme.gray_1,
      color: theme.gray_5,
    },

    "&:active": {
      backgroundColor: theme.gray_2,
      borderColor: theme.gray_3,
    },
  },
}));

export default useStyles;
