export default {
  ko: {
    search: {
      header_ko: "에 대한 검색 결과",
      header_en: "",
      no_results: "검색 결과가 없습니다.",
      workspace_res_ko1: "내의 총 검색 결과",
      workspace_res_ko2: "개",
      workspace_res_en1: "",
      see_more: "더보기",
      no_results_small: "결과 없음",
      menu_text: "개요",
      other_name: "다른 이름을 검색해주세요",
      all: {
        title: "전체 결과",
        result_count_prefix: "총",
        result_count_postfix: "개",
      },
      hashtags: {
        title: "태그",
        search: "태그 검색",
      },
      ocr: {
        title: "OCR",
        search: "OCR 검색",
      },
      folders: {
        title: "폴더",
        search: "폴더 검색",
      },
      files: {
        title: "파일",
        search: "파일 검색",
      },
    },
  },

  en: {
    search: {
      menu_text: "Overview",
      header_ko: "",
      header_en: "Search results for",
      no_results: "There are no search results.",
      workspace_res_ko1: "",
      workspace_res_ko2: "",
      workspace_res_en1: "search results within",
      see_more: "See more",
      no_results_small: "No results",
      other_name: "Please search another name",
      all: {
        title: "All results",
        result_count_prefix: "",
        result_count_postfix: "found",
      },
      hashtags: {
        title: "Tags",
        search: "Tag search",
      },
      ocr: {
        title: "OCRs",
        search: "OCR search",
      },
      folders: {
        title: "Folders",
        search: "Folder search",
      },
      files: {
        title: "Files",
        search: "File search",
      },
    },
  },
};
