import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";
import { ProjectObject, ProjectObjectSchema } from "./project";

export interface GithubObjectCore {
  readonly id: string;
  readonly repo: string;
  readonly token: string;
  readonly UserId: string;
  readonly ProjectId: string;
}

export type GithubObject = GithubObjectCore & AccessTime;
export const GithubObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("repo", T.string())
  .addField("token", T.string())
  .addField("UserId", T.string())
  .addField("ProjectId", T.string());

export const extractGithubObject =
  T.mkObjectExtractor<GithubObject>(GithubObjectSchema);
export const validateGithubObject =
  T.mkValidator<GithubObject>(GithubObjectSchema);

export type GithubLinkageObject = GithubObject & { Project: ProjectObject };
export const GithubLinkageObjectSchema = GithubObjectSchema.clone().addField(
  "Project",
  ProjectObjectSchema
);
export const validateGithubLinkageObject = T.mkValidator<GithubLinkageObject>(
  GithubLinkageObjectSchema
);
