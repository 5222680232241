import { doAPIPostRequest, doAPIGetRequest } from "./api";
import {
  IGoogleDriveNote,
  IGoogleDriveToken,
} from "@basalt-commons/api/request/google_drive";
import {
  OMessage,
  OGoogleDriveToken,
  validateOMessage,
  validateOGDT,
  OGoogleDriveNote,
  validateOGoogleDriveNote,
} from "@basalt-commons/api/response/google_drive";
import { GDTObject } from "@basalt-commons/api/object/google_drive";
import { getLoggers } from "@redwit-commons/utils/log";
import { InternalErrorKind, isErr } from "@redwit-commons/utils/exception2";

const { WARN } = getLoggers("service/google_drive");

/*
 * Save google drive token of user in server
 */
const saveGDT = async (
  token: string,
  args: IGoogleDriveToken
): Promise<string> => {
  const obj = await doAPIPostRequest(`google_drive/token`, token, args);
  const ret: OMessage = validateOMessage(obj);
  return ret.response.msg;
};

/*
 * Fetch google drive token of user from server
 */
const fetchGDT = async (token: string): Promise<GDTObject | undefined> => {
  try {
    const obj = await doAPIGetRequest(`google_drive/token`, token);
    const ret: OGoogleDriveToken = validateOGDT(obj);
    return ret.response;
  } catch (err) {
    if (isErr(err)) {
      if (err.kind === InternalErrorKind.ResponseCode && err.code === 418) {
        return undefined;
      }
      WARN("구글 드라이브 연동 도중 에러 발생", err);
    }
    throw err;
  }
};

/**
 * Create Google Drive Note
 */
const GDTNote = async (
  token: string,
  args: IGoogleDriveNote
): Promise<OGoogleDriveNote> => {
  const obj = await doAPIPostRequest(`google_drive/note`, token, args);
  const ret: OGoogleDriveNote = validateOGoogleDriveNote(obj);
  return ret;
};

export default {
  saveGDT,
  fetchGDT,
  GDTNote,
};
