import styled, { keyframes } from "styled-components";

export const getRandomColor = (index: number) => {
  const colors = [
    "#FF5733",
    "#FFC300",
    "#16A085",
    "#7D3C98",
    "#34495E",
    "#EC7063",
    "#5DADE2",
    "#EB984E",
    "#76D7C4",
    "#F39C12",
  ];
  return colors[index % colors.length];
};

export const getRandomBackgroundColor = (index: number) => {
  const colors = [
    "#3BC9DB",
    "#B197FC",
    "#8CE99A",
    "#FFD43B",
    "#FFC078",
    "#4DABF7",
    "#FAA2C1",
  ];
  return colors[index % colors.length];
};

let fadeInUp = keyframes`
    from {opacity: 0; -webkit-transform: translate3d(0, 15%, 0); transform: translate3d(0, 15%, 0);}
    to {opacity: 1; -webkit-transform: none; transform: none;}
`;

export const FadeInUp = styled.div`
  animation: ${fadeInUp} 1.5s;
  animation-fill-mode: both;
`;
export const theme = {
  max_screen_width: { maxWidth: 1556 },
  //FontSize
  font6: { fontSize: 6 },
  font8: { fontSize: 8 },
  font10: { fontSize: 10 },
  font12: { fontSize: 12 },
  font14: { fontSize: 14 },
  font16: { fontSize: 16 },
  font18: { fontSize: 18, letterSpacing: -1 },
  font20: { fontSize: 20, letterSpacing: -1 },
  font22: { fontSize: 22 },
  font24: { fontSize: 24 },
  font26: { fontSize: 26 },
  font28: { fontSize: 28 },
  font32: { fontSize: 32, letterSpacing: -1.4 },
  font36: { fontSize: 36 },
  font38: { fontSize: 38 },
  font40: { fontSize: 40 },
  font46: { fontSize: 40 },
  font56: { fontSize: 56, letterSpacing: -0.5 },
  font64: { fontSize: 64 },

  //Color
  black: "#000000",
  white: "#ffffff",
  orange_1: "#ffe8cc",
  orange_2: "#ffd8a8",
  orange_3: "#ffc078",
  orange_light: "#fedebe",
  primary_dark_1: "#1860dc",
  primary_dark_2: "#1450b8",
  primary_blue: "#5c7cfa",
  secondary_purple: "#5c7cfa",
  purple_light: "#bac8ff",
  secondary_light_1: "#bac8ff",
  primary_light_2: "#7ea8f1",
  primary_light_1: "#598fee",
  secondary_light_2: "#ffffff",
  secondary_dark_1: "#7251e1",
  secondary_dark_2: "#7251e1",

  primary_violet: "#845ef7",
  violet_1: "#e5dbff",
  primary_orange: "#f76707",
  font80: { fontSize: 80 },
  font96: { fontSize: 96, letterSpacing: -0.5 },

  gray_0: "#f8f9fa",
  gray_1: "#f1f3f5",
  gray_2: "#e9ecef",
  gray_3: "#dee2e6",
  gray_4: "#ced4da",
  gray_5: "#adb5bd",
  gray_6: "#868e96",
  gray_7_text: "#495057",
  gray_8: "#343a40",
  gray_9_title: "#212529",
  gray_10: "#343546",

  system_red: "#e03131",
  system_yellow: "#fab005",
  system_green: "#2f9e44",
  primary_primary_tag_bg: "#edf3fd",
  primary_primary_bg: "#c8daf9",
  primary_primary_disable: "#c2d6f9",
  system_red_hovered: "#c92a2a",
  system_red_focused: "#b22727",
  system_red_bg: "#fadcdc",
  system_red_bg_pressed: "#ffe3e3",
  system_yellow_bg: "#fff9db",
  system_orange: "#fa5252",

  primary_indigo: "#5c7cfa",
  indigo_0: "#edf2ff",
  indigo_1: "#dbe4ff",
  indigo_2: "#bac8ff",
  indigo_3: "#91a7ff",
  indigo_4: "#748ffc",
  indigo_5: "#5c7cfa",
  indigo_6: "#4c6ef5",
  indigo_7: "#3578ea",
  indigo_8: "#3b5bdb",
  indigo_9: "#4263eb",
  indigo_accent: "#1C4AF5",
  indigo_10: "#364fc7",

  primary_cian: "#3bc9db",
  cian_0: "#e3fafc",
  cian_1: "#c5f6fa",
  cian_2: "#99e9f2",
  cian_3: "#66d9e8",
  cian_4: "#3bc9db",
  cian_5: "#22b8cf",
  cian_6: "#15aabf",
  cian_7: "#1098ad",
  cian_8: "#0c8599",
  cian_9: "#0b7285",

  violet_0: "#f3f0ff",
  orange_0: "#fff4e6",
  yellow_0: "#fff9db",
  green_0: "#ebfbee",
  pink_0: "#fff0f6",
  red_0: "#fff5f5",
  blue_0: "#e7f5ff",

  violet_6: "#7950f2",
  orange_6: "#ff922b",
  yellow_6: "#fab005",
  green_6: "#40c057",
  pink_6: "#e64980",
  red_6: "#fa5252",
  blue_6: "#228be6",

  /* Font Family */
  spoqahans_bold: { fontFamily: "SpoqaHansSans-Bold" },
  spoqahans_regular: { fontFamily: "SpoqaHansSans-Regular" },
  spoqahans_medium: { fontFamily: "SpoqaHansSans-Medium" },
  spoqahans_thin: { fontFamily: "SpoqaHansSans-Thin" },
  spoqahans_light: { fontFamily: "SpoqaHansSans-Light" },

  /* icon */
  icon_40: {
    width: 40,
    height: 40,
    cursor: "pointer",
  },
  icon_36: {
    width: 36,
    height: 36,
    cursor: "pointer",
  },
  icon_32: {
    width: 32,
    height: 32,
    cursor: "pointer",
  },
  icon_24: {
    width: 24,
    height: 24,
    cursor: "pointer",
  },
  icon_20: {
    width: 20,
    height: 20,
    cursor: "pointer",
  },

  /*button sizes*/
  button_xs: {
    height: 32,
    padding: "10px 11px",
  },

  button_xs_text: {
    fontSize: 10,
  },

  button_small: {
    height: 40,
    padding: "8px 20px",
  },

  button_small_text: {
    fontSize: 14,
  },

  button_default: {
    height: 56,
    padding: "19px 24px",
  },

  button_default_text: {
    fontSize: 14,
  },

  button_large: {
    height: 64,
    padding: "22px 36px",
  },

  button_large_text: {
    fontSize: 16,
  },

  /*Shadows*/
  shadow_1: {
    boxShadow: "0 0 10px 0 rgba(173, 181, 189, 0.2)",
  },
  shadow_2: {
    boxShadow: "0 8px 28px 2px rgba(33, 37, 41, 0.12)",
  },
  shadow_3: {
    boxShadow: "0 4px 4px 6px rgba(73, 80, 87, 0.02)",
  },

  //typo 2줄처리
  two_line_typo: {
    // whiteSpace: "pre-wrap", *사용하는곳에서 설정해줘야함
    // WebkitBoxOrient: 'vertical', *사용하는곳에서 설정해줘야함
    maxWidth: "100%",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    overflow: "hidden",
  },

  fixed_title: {
    // position: "sticky", *사용하는곳에서 설정해줘야함
    top: 0,
    backgroundColor: "#fff",
    zIndex: 1,
    paddingBottom: 10,
  },

  //breakpoint
  breakSmall: 600,
  breakMedium: 1024,
};

export const buttons = {
  primary: {
    backgroundColor: theme.indigo_6,
    border: `1px solid ${theme.indigo_6}`,
    borderRadius: 8,
    color: theme.white,
  },

  secondary: {
    backgroundColor: theme.indigo_0,
    border: `1px solid ${theme.indigo_1}`,
    color: theme.indigo_8,
    borderRadius: 8,
  },

  default: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.indigo_2}`,
    borderRadius: 8,
    color: theme.indigo_6,
  },
  default_outlined: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.gray_2}`,
    borderRadius: 8,
    color: theme.gray_7_text,
  },
  white: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.gray_2}`,
    borderRadius: 8,
    color: theme.gray_7_text,
  },
  danger: {
    border: `1px solid ${theme.system_red_bg}`,
    borderRadius: 8,
    color: theme.system_red,
  },
  danger_fill: {
    border: `1px solid ${theme.system_red_bg}`,
    borderRadius: 8,
    background: theme.system_red,
    color: theme.white,
  },
  cyan: {
    backgroundColor: theme.cian_5,
    border: `1px solid ${theme.cian_6}`,
    borderRadius: 8,
    color: theme.white,
  },
  cyan_outlined: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.cian_6}`,
    borderRadius: 8,
    color: theme.cian_6,
  },

  gray_outlined: {
    backgroundColor: theme.white,
    border: `1px solid ${theme.gray_2}`,
    borderRadius: 8,
    color: theme.gray_7_text,
  },

  textbutton: {
    padding: "7px 12px",
  },
};

export const headers = {
  /*Headers*/
  h1: {
    ...theme.font64,
    lineHeight: "84px",
  },

  h2: {
    ...theme.font46,
    lineHeight: "64px",
  },

  h3: {
    ...theme.font36,
    lineHeight: "48px",
  },

  h4: {
    ...theme.font32,
    lineHeight: "42px",
  },

  h5: {
    ...theme.font28,
    lineHeight: "38px",
  },

  h6: {
    ...theme.font24,
    lineHeight: "34px",
  },
};
