import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "../../api/object/access_time";
import {
  ProjectAuthType,
  ProjectAuthTypeValues,
} from "../../api/object/user_project_map";
import { UserObject, UserObjectSchema } from "../../api/object/user";

export enum AlarmType {
  MENTION = "AlarmType::mention",
  INVTIE_PROJECT = "AlarmType::invtieProject",
  NEW_RULE = "AlarmType::newRule",
}

export const AlarmTypeList = [
  AlarmType.MENTION,
  AlarmType.INVTIE_PROJECT,
  AlarmType.NEW_RULE,
];

export type MentionAlarmInfo = {
  readonly ChatId?: string;
  readonly NoteId?: string;
  readonly mention_text?: string;
  readonly file_name?: string;
  readonly mention_id?: string;
};
export type InviteProjectAlarmInfo = {
  readonly ProjectId?: string;
  readonly authType?: ProjectAuthType;
};
export type NewRuleAlarmInfo = {
  readonly RuleId?: string;
};

export type AlarmPureCore = {
  readonly id: string;
  readonly type: AlarmType;
  readonly isCheck: boolean;
  readonly WorkspaceId: string;
  readonly UserId: string;
  readonly folder_name?: string;
} & MentionAlarmInfo &
  InviteProjectAlarmInfo &
  NewRuleAlarmInfo;

export type AlarmPureObject = AlarmPureCore & AccessTime;
export type AlarmObject = {
  readonly User: UserObject;
} & AlarmPureObject;

export const AlarmPureObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("type", T.string().withEnum(AlarmTypeList))
  .addField("isCheck", T.boolean())
  .addField("WorkspaceId", T.string())
  .addField("UserId", T.string())
  .addField("NoteId", T.string(), false)
  .addField("folder_name", T.string(), false)
  .addField("ChatId", T.string(), false)
  .addField("mention_text", T.string(), false)
  .addField("file_name", T.string(), false)
  .addField("mention_id", T.string(), false)
  .addField("ProjectId", T.string(), false)
  .addField("authType", T.string().withEnum(ProjectAuthTypeValues), false)
  .addField("RuleId", T.string(), false);

export const AlarmObjectSchema = AlarmPureObjectSchema.clone().addField(
  "User",
  UserObjectSchema.clone()
);

export const extractAlarmPureObject = T.mkObjectExtractor<AlarmPureObject>(
  AlarmPureObjectSchema
);
export const validateAlarmPureObject = T.mkValidator<AlarmPureObject>(
  AlarmPureObjectSchema
);
export const extractAlarmObject =
  T.mkObjectExtractor<AlarmObject>(AlarmObjectSchema);
export const validateAlarmObject =
  T.mkValidator<AlarmObject>(AlarmObjectSchema);
