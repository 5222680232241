import { doAPIPostRequest, doAPIGetRequest, doAPIDeleteRequest } from "./api";

import { IGithubNote } from "@basalt-commons/api/request/github";
import {
  OGithubNote,
  validateOGithubNote,
  OGithubRepos,
  validateOGithubRepos,
  OGithubStatus,
  validateOGithubStatus,
  ODeleteGithubNote,
  validateODeleteGithubNote,
} from "@basalt-commons/api/response/github";

const githubNote = async (
  goonoToken: string,
  args: IGithubNote
): Promise<OGithubNote> => {
  const ret = await doAPIPostRequest("github/note", goonoToken, args);
  return validateOGithubNote(ret);
};

const getRepos = async (
  goonoToken: string,
  args: { t: string }
): Promise<OGithubRepos> => {
  const ret = await doAPIGetRequest("github/repos", goonoToken, {
    token: args.t,
  });
  return validateOGithubRepos(ret);
};

const githubStatus = async (goonoToken: string): Promise<OGithubStatus> => {
  const ret = await doAPIGetRequest("github/status", goonoToken);
  return validateOGithubStatus(ret);
};

const deleteGithubNote = async (
  goonoToken: string,
  ghId: string
): Promise<ODeleteGithubNote> => {
  const ret = await doAPIDeleteRequest(`github/status/${ghId}`, goonoToken);
  return validateODeleteGithubNote(ret);
};

export default {
  githubNote,
  getRepos,
  githubStatus,
  deleteGithubNote,
};
