import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import {
  CustomNDAInfo,
  NdaObject,
} from "@basalt-commons/global-api/object/nda";
import { WorkspaceWithNdaContract } from "@basalt-commons/global-api/object/workspace";
import { ContractObject } from "@basalt-commons/global-api/object/nda_contract";

export enum NDAStateStatus {
  INIT = "NDA::INIT",
  SUCCESS = "NDA::SUCCESS",
}

export enum NDAActionKind {
  TRY_CREATE = "NDAActionKind::TRY_CREATE",
  TRY_GET_NDAS = "NDAActionKind::TRY_GET_NDAS",
  TRY_DELETE_NDA = "NDAActionKind::TRY_DELETE_NDA",
  TRY_UPDATE_NDA = "NDAActionKind::TRY_UPDATE_NDA",
  TRY_GET_CONTRACT = "NDAActionKind::TRY_GET_CONTRACT",
  TRY_GET_CONTRACT_LIST = "NDAActionKind::TRY_GET_CONTRACT_LIST",
  TRY_SHARE_CONTRACT = "NDAActionKind::TRY_SHARE_CONTRACT",
  TRY_DOWNLOAD_CONTRACT = "NDAActionKind::TRY_DOWNLOAD_CONTRACT",
  TRY_DOWNLOAD_ALL_CONTRACT = "NDAActionKind::TRY_DOWNLOAD_ALL_CONTRACT",
}

export type NDAState =
  | {
      readonly status: NDAStateStatus.INIT;
    }
  | {
      readonly status: NDAStateStatus.SUCCESS;
      readonly ndas: NdaObject[];
      readonly contract?: ContractObject;
      readonly workspaceWithNdaContract?: WorkspaceWithNdaContract;
      readonly filePath?: string;
    };

export type NDAAction =
  | {
      readonly kind: NDAActionKind.TRY_CREATE;
      readonly file: File;
      readonly description?: string;
      readonly customNDAInfo?: CustomNDAInfo; // If present then custom, otherwise it is default.
    }
  | {
      readonly kind: NDAActionKind.TRY_GET_NDAS;
    }
  | {
      readonly kind: NDAActionKind.TRY_DELETE_NDA;
      readonly nda_id: string;
    }
  | {
      readonly kind: NDAActionKind.TRY_UPDATE_NDA;
      readonly description: string;
      readonly nda_id: string;
    }
  | {
      readonly kind: NDAActionKind.TRY_GET_CONTRACT;
      readonly contractId: string;
    }
  | {
      readonly kind: NDAActionKind.TRY_GET_CONTRACT_LIST;
    }
  | {
      readonly kind: NDAActionKind.TRY_SHARE_CONTRACT;
      readonly contractId: string;
      readonly emails: string[];
      readonly message: string;
    }
  | {
      readonly kind: NDAActionKind.TRY_DOWNLOAD_CONTRACT;
      readonly contract: ContractObject;
    }
  | {
      readonly kind: NDAActionKind.TRY_DOWNLOAD_ALL_CONTRACT;
      readonly workspaceId: string;
      readonly NdaId?: string;
    };

export type NDAError = never;

const smid = "NDA_STATE_MACHINE3";
export type NDAStateMachineType = StateMachine3<
  NDAStateStatus,
  NDAState,
  NDAActionKind,
  NDAAction,
  NDAError
>;
export const ndaStateMachine: NDAStateMachineType = new StateMachine3<
  NDAStateStatus,
  NDAState,
  NDAActionKind,
  NDAAction,
  NDAError
>(smid, { status: NDAStateStatus.INIT }, [
  transition(
    NDAStateStatus.INIT,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_GET_NDAS
  ),
  transition(
    NDAStateStatus.SUCCESS,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_GET_NDAS
  ),
  transition(
    NDAStateStatus.SUCCESS,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_CREATE
  ),
  transition(
    NDAStateStatus.SUCCESS,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_DELETE_NDA
  ),
  transition(
    NDAStateStatus.SUCCESS,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_UPDATE_NDA
  ),
  transition(
    NDAStateStatus.INIT,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_GET_CONTRACT
  ),
  transition(
    NDAStateStatus.SUCCESS,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_GET_CONTRACT
  ),
  transition(
    NDAStateStatus.SUCCESS,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_GET_CONTRACT_LIST
  ),
  transition(
    NDAStateStatus.SUCCESS,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_SHARE_CONTRACT
  ),
  transition(
    NDAStateStatus.SUCCESS,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_DOWNLOAD_CONTRACT
  ),
  transition(
    NDAStateStatus.SUCCESS,
    NDAStateStatus.SUCCESS,
    NDAActionKind.TRY_DOWNLOAD_ALL_CONTRACT
  ),
]);

/* */
export type DispatchNDAAction = Dispatch<
  StateMachineAction<
    NDAStateStatus,
    NDAState,
    NDAActionKind,
    NDAAction,
    NDAError
  >
>;
export default mkReducer<
  NDAStateStatus,
  NDAState,
  NDAActionKind,
  NDAAction,
  NDAError
>(ndaStateMachine);
export const doNDAAction = (
  dispatch: DispatchNDAAction,
  nextAction: NDAAction,
  onResolve: () => void = () => {},
  onReject: (err: NDAError | InternalError) => void = () => {}
) => {
  dispatch(ndaStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doNDAActionAsync = (
  dispatch: DispatchNDAAction,
  nextAction: NDAAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(ndaStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetNDA = (dispatch: DispatchNDAAction) => {
  dispatch(ndaStateMachine.newResetAction());
};
