import {
  GoonoArrayResponse,
  GoonoPendingTasks,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "../../api/response/common";
import T from "@redwit-commons/utils/typecheck";
import { DocumentObject, DocumentObjectSchema } from "../object/document";
import { NdaPureObject, NdaPureObjectSchema } from "../object/nda";

export type OCreateDocument = GoonoResponse<DocumentObject> &
  GoonoPendingTasks<{ document_task: string }>;
export const OCreateDocumentSchema = mkResponseSchema(
  DocumentObjectSchema,
  T.object().addField("document_task", T.string())
);
export const validateOCreateDocument = T.mkValidator<OCreateDocument>(
  OCreateDocumentSchema
);

export type OGetDocumentList = GoonoArrayResponse<DocumentObject>;
export const OGetDocumentListSchema =
  mkArrayResultsSchema(DocumentObjectSchema);
export const validateOGetDocumentList = T.mkValidator<OGetDocumentList>(
  OGetDocumentListSchema
);

export type OGetDocumentInfo = GoonoResponse<
  DocumentObject & { Nda?: NdaPureObject; documentToken: string }
>;
export const OGetDocumentInfoSchema = mkResponseSchema(
  DocumentObjectSchema.clone()
    .addField("Nda", NdaPureObjectSchema, false)
    .addField("documentToken", T.string())
);
export const validateOGetDocumentInfo = T.mkValidator<OGetDocumentInfo>(
  OGetDocumentInfoSchema
);

export type OSetDocumentStatus = GoonoResponse<
  DocumentObject & { Nda?: NdaPureObject }
>;
export const OSetDocumentStatusSchema = mkResponseSchema(
  DocumentObjectSchema.clone().addField("Nda", NdaPureObjectSchema, false)
);
export const validateOSetDocumentStatus = T.mkValidator<OSetDocumentStatus>(
  OSetDocumentStatusSchema
);

export type OSendDocument = GoonoResponse<string>;
export const OSendDocumentSchema = mkResponseSchema(T.string());
export const validateOSendDocument =
  T.mkValidator<OSendDocument>(OSendDocumentSchema);
