const ScreenLandingURL = {
  LANDING_MAIN: "/",
  LANDING_SERVICE: "/service",
  LANDING_SOLUTION: "/solution",
  LANDING_PRICING: "/pricing",
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  SIGN_UP_EMAIL_SENT: "/sign-up/email/sent",
  REGISTER_EMAIL: "/sign-up/email",
  REGISTER_SNS: "/sign-up/sns",
  PW_UPDATE_EMAIL: "/pw/verify",
  PW_UPDATE: "/pw/update",
  LANDING_CALLBACK: "/landing/callback",
  PDF_VIEWER: "/pdf/viewer/:document_id",
  CONTACT: "/contact",
  DOCUMENTS: "/agreement/:document_type", // terms | cookies | privacy | marketing | personal_info | third_party | signature
  NDA_CONTRACT_PREVIEW_GUEST: "/preview/contract/:contract_id",
};

export const ScreenURL = {
  ...ScreenLandingURL,
  WORKSPACE_CREATE: "/workspace/create",
  WORKSPACE_INVITE: "/workspace/:workspace_name/invite",
  WORKSPACE_SEARCH: "/workspace/search",
  WORKSPACE_KNOCK: "/workspace/:workspace_name/knock",
  SERVICE_MAIN: "/main",
  WORKSPACE: "/:workspace_name/main",
  WORKSPACE_UPGRADE: "/:workspace_name/upgrade",
  WORKSPACE_SETTINGS: "/:workspace_name/settings",
  RULE_CREATE: "/:workspace_name/rule/create/:nda_id",
  RULE_SIGN: "/:workspace_name/rule/sign/:rule_id",
  TIMELINE: "/:workspace_name/timeline",
  PROJECT: "/:workspace_name/projects",
  FOLDER_INSIDE: "/:workspace_name/projects/:project_id",
  PROJECT_SETTINGS: "/:workspace_name/projects/:project_id/settings",
  PROJECT_CREATE: "/:workspace_name/project/create",
  NOTE_PREVIEW_TAB: "/:workspace_name/projects/:project_id/:note_id/:type",
  LINK_CREATE: "/:workspace_name/projects/:project_id/link/create/:note_id",
  STAMP_CREATE: "/:workspace_name/ndas/stamp/create",
  LINK_DONE:
    "/:workspace_name/projects/:project_id/link/create/done/:document_id",
  SEND_EMAIL: "/:workspace_name/email/:type/:id",
  NDA: "/:workspace_name/ndas",
  NDA_UPLOAD: "/:workspace_name/nda/upload",
  NDA_PREVIEW: "/:workspace_name/nda/:nda_id",
  NDA_CREATE: "/:workspace_name/nda/custom/create",
  NDA_CONTRACT_PREVIEW: "/:workspace_name/preview/contract/:contract_id",
  RULE_CONTRACT_PREVIEW:
    "/:workspace_name/preview/rule/:rule_id/:rule_contract_id",
  ACCOUNT_SETTING: "/:workspace_name/accounts/settings",
  ACCOUNT_SECURITY_PLEDGE: "/:workspace_name/accounts/contracts/signed",
  SEARCH_ALL: "/:workspace_name/search/:q/all",
  SEARCH_WORKSPACE: "/:workspace_name/search/:q",
  PURCHASE: "/:workspace_name/purchase",
};
