import T from "@redwit-commons/utils/typecheck";

export enum LicenseRoleTypes {
  ADMIN = "License::Admin",
  MANAGER = "License::MANAGER",
  ASSIGEND = "License::ASSIGEND",
}

export const getRoleLevel = (role: LicenseRoleTypes) => {
  switch (role) {
    case LicenseRoleTypes.ASSIGEND:
      return 2;
    case LicenseRoleTypes.ADMIN:
      return 3;
    case LicenseRoleTypes.MANAGER:
      return 3;
  }
};

export const LicenseRoleTypesValues = [
  LicenseRoleTypes.ADMIN,
  LicenseRoleTypes.ASSIGEND,
  LicenseRoleTypes.MANAGER,
];

export const UserLicenseMapObjectSchema = T.object()
  .addField("UserId", T.string())
  .addField("LicenseId", T.string())
  .addField("role", T.string().withEnum(LicenseRoleTypesValues));

export interface UserLicenseMapObjectCore {
  readonly UserId: string;
  readonly LicenseId: string;
  readonly role: LicenseRoleTypes;
}
