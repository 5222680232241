export default {
  ko: {
    project_settings: {
      title: "폴더 설정 및 멤버",
      title_md: "폴더 설정",
      default_settings: "기본 설정",
      folder_name: "폴더 이름",
      folder_name_inst: "폴더 이름을 50자 이내로 입력하세요",
      announce_folder_name: (folder_name: string) => {
        return `"${folder_name}" 폴더 설정`;
      },
      info: "정보",
      private_folder: "개인 폴더",
      shared_folder: "공유 폴더",
      public_folder: "공용 폴더",
      creation_date: "생성날짜",
      announce_file_cnt: (file_cnt: number) => {
        return `${file_cnt}개의 파일`;
      },
      verified_files: "이 블록체인에 인증되었습니다",
      project_type: "공개 전환",
      only_owner: "소유자만 수정이 가능합니다",
      only_owner_or_admin: "소유자 및 관리자만 수정할 수 있습니다.",
      make_public: "전체 공개 폴더로 전환",
      folder_owner: "폴더 관리자",
      save_changes: "변경 내용 저장",
      members_title: "멤버",
      person: "명",
      members_subheader: "멤버를 검색하거나 추가할 수 있습니다",
      members_subheader_md:
        "새로운 멤버를 초대하거나 멤버를 검색할 수 있습니다",
      more_members: "10개 항목 더 보기",
      add_members: "멤버 추가",
      delete_folder_title: "폴더 삭제",
      delete_folder_subtitle: "폴더를 삭제할 경우 데이터가 영구 삭제 됩니다",
      remove_folder: "폴더 제거",
      remove_this_folder: "이 폴더 제거",
    },
  },

  en: {
    project_settings: {
      title: "Folder setting / members",
      title_md: "Folder Settings",
      default_settings: "Default Setting",
      folder_name: "Folder name",
      folder_name_inst: "Folder name should be within 50 characters",
      announce_folder_name: (folder_name: string) => {
        return `"${folder_name}" Folder Settings`;
      },
      info: "Information",
      private_folder: "Private Folder",
      shared_folder: "Shared Folder",
      public_folder: "Public Folder",
      creation_date: "Date Created",
      announce_file_cnt: (file_cnt: number) => {
        return `${file_cnt} files`;
      },
      verified_files: "have been authenticated on the blockchain.",
      project_type: "Switch to Public",
      only_owner: "Only the owner can modify it",
      only_owner_or_admin: "Only the owner and administrator can modify it",
      make_public: "Switch to public folder",
      folder_owner: "Folder Administrator",
      save_changes: "Save Changes",
      members_title: "Members",
      person: "",
      members_subheader: "You can search for or add members",
      members_subheader_md: "You can invite new members or search for members",
      more_members: "See 10 more members",
      add_members: "Add members",
      delete_folder_title: "Delete folder",
      delete_folder_subtitle:
        "If you delete it, all data inside will be permanently removed",
      remove_folder: "Remove folder",
      remove_this_folder: "Remove this folder",
    },
  },
};
