export default {
  ko: {
    signed_doc: {
      title: "서명한 문서 확인",
      search: "파일을 검색하세요",
      search_xs: "파일 검색하기",
      download: "전체 다운받기",
      download_tooltip: "현재 보이는 문서들을 모두 다운로드 합니다",
      inside_curr_workspace: "현재 워크스페이스 내부",
      all_workspaces: "전체 워크스페이스",
      no_files: "파일이 없습니다",
      rule_tab: {
        title: "워크스페이스 룰 서명",
        waiting_for_signature: "서명 대기 중",
        completed_signing: "완료된 서명",
        nda_filename: "NDA 파일명",
        workspace_name: "워크 스페이스명",
        signature_requester: "서명 요청자",
        request_date: "요청 시간",
        who_signed: "서명한 사람",
        sign_date: "서명 날짜",
        sign: "서명하기",
        menu: {
          check_nda: "NDA 파일 확인",
          sign: "서명하기",
          view_file: "파일 보기",
        },
      },

      file_tab: {
        title: "파일 열람 동의 서명",
      },
    },
  },

  en: {
    signed_doc: {
      title: "Signed Documents",
      search: "Search the file",
      search_xs: "Search",
      download: "Download all",
      download_tooltip: "Download all visible files",
      inside_curr_workspace: "Inside the current workspace",
      all_workspaces: "All workspaces",
      no_files: "No files were found",
      rule_tab: {
        title: "Signed Workspace Rules",
        waiting_for_signature: "Waiting for Signature",
        completed_signing: "Completed Signing",
        nda_filename: "NDA filename",
        workspace_name: "Workspace Name",
        signature_requester: "Contractor",
        request_date: "Requested Time",
        who_signed: "Who Signed",
        sign_date: "Sign Date",
        sign: "Sign",
        menu: {
          check_nda: "Check NDA file",
          sign: "Sign",
          view_file: "View file",
        },
      },

      file_tab: {
        title: "Signed consents to view files",
      },
    },
  },
};
