export default {
  ko: {
    rule: {
      view_nda: "NDA 보기",
      view: "보기",
      required: "필수",
      no_rule_contracts: "사인 내역이 없습니다.",
      make_required: "필수 사항 해제",
      make_optional: "선택 사항으로 하기",
      signed: "사인했습니다",
      not_signed: "사인하지 않았습니다.",
      all_signed: "모든 멤버가 사인했습니다.",
      number_signed: "명의 멤버가 사인했습니다",
      created_ko: "생성됨",
      created_en: "",
      individual: "개인의",
      name: "이름",
      team: "팀",
      signing_date: "사인 날짜",
      download_all: "모두 다운받기",
      menu: {
        download_all: "전체 사인 다운받기",
        make_required: "필수 사항 적용",
        make_optional: "필수 사항 해제",
        delete: "삭제하기",
        choose_from_existing: "기존 NDA 문서에서 선택",
        upload_new_nda: "새로운 NDA 파일 업로드",
        view_sign_document: "사인 문서 확인하기",
        download_sign_document: "사인 문서 다운받기",
        sign: "사인 하기",
        view_document: "문서 확인하기",
      },
      create: {
        title: "보안 서약 만들기",
        rule_name: "보안 서약 이름",

        rule_name_placeholder: "서약 이름을 50자 이내로 작성해주세요",
        create: "만들기",
        option_settings: {
          title: "보안 서약 옵션",
          required: "모든 멤버에게 필수로 서명받기",
          sub_required:
            "워크스페이스내에 모든 멤버에게 필수로 서명을 받습니다.",
          alert_members: "모든 멤버에게 이메일 알림 주기",
          sub_alert_members:
            "워크스페이스내에 모든 멤버에게 이메일 알림을 보냅니다.",
        },
      },
      sign: {
        header1: "",
        header2: "을 이용하시려면, 옆의 정책에 동의하셔야 합니다",
        subheader:
          "워크스페이스 보안을 위하여 옆의 보안 정책에 동의하셔야 해당 워크스페이스 내에서의 이용이 가능해집니다. 자세한 사항은 워크스페이스 관리자에게 문의해주세요",
        email_subtext:
          "워크스페이스 Member 이신 경우, 워크스페이스 세팅에서도 해당 문서를 확인할 수 있습니다.",
      },
    },
  },

  en: {
    rule: {
      view_nda: "View NDA",
      view: "View",
      no_rule_contracts: "No Sign History.",
      required: "required",
      make_required: "Unclick the required",
      signed: "Signed",
      not_signed: "You didn't sign it",
      all_signed: "All members signed it",
      number_signed: " member(s) signed it",
      created_ko: "",
      created_en: "created ",
      individual: "Individual",
      name: "Name",
      team: "Team",
      signing_date: "Date signed",
      download_all: "Download all",
      create: {
        title: "Create the rule of security pledge",
        rule_name: "Rule Name",
        rule_name_placeholder: "Enter the rule name within 50 characters",
        create: "Create",
        option_settings: {
          title: "Rule Option Settings",
          required: "All members' signatures are required",
          sub_required:
            "Signatures are required from all members in the workspace.",
          alert_members: "Notify all members in workspace by Email",
          sub_alert_members:
            "Emails will be sent to all members in the workspace.",
        },
      },
      sign: {
        header1: "If you want to use",
        header2: ", You have to agree to the policy beside.",
        subheader:
          "For the security of the workspace, you must agree to the security policy next to it to be available within that workspace. For more information, please contact your workspace manager.",
        email_subtext:
          "If you are a workspace member, you can also check the document in the workspace settings.",
      },

      menu: {
        download_all: "Download all signatures",
        make_required: "Make required",
        make_optional: "Make optional",
        delete: "Delete",
        choose_from_existing: "Choose from the existing NDA",
        upload_new_nda: "Upload a new NDA file",
        view_sign_document: "Check the signed document",
        download_sign_document: "Download signed document",
        sign: "Sign",
        view_document: "Check the document",
      },
    },
  },
};
