import { gtmTrackEvent } from "./gtm-raw-api";
import type { CreateLinkParam } from "../marketing-flow/use-create-link-effect";

export const gtmApi = {
  async login(platform: string) {
    await gtmTrackEvent({
      eventName: "login",
      object: {
        platform: platform,
      },
    });
  },
  async signIn(platform: string) {
    await gtmTrackEvent({
      eventName: "sign_in",
      object: {
        paltform: platform,
      },
    });
  },
  async emailVerification() {
    await gtmTrackEvent({
      eventName: "email_verification",
      object: null,
    });
  },
  async inquirySend(category: string | undefined) {
    await gtmTrackEvent({
      eventName: "inquiry_send",
      object: {
        category: category,
      },
    });
  },
  async spaceCreate(isPublic: boolean) {
    await gtmTrackEvent({
      eventName: "create_workspace",
      object: {
        public: isPublic,
      },
    });
  },
  async inviteSend() {
    await gtmTrackEvent({
      eventName: "send_invite",
      object: null,
    });
  },
  async folderCreate(isPublic: boolean) {
    await gtmTrackEvent({
      eventName: "create_folder",
      object: {
        public: isPublic,
      },
    });
  },
  async fileUpload() {
    await gtmTrackEvent({
      eventName: "upload_file",
      object: null,
    });
  },
  async linkCreateTry() {
    await gtmTrackEvent({
      eventName: "try_create_link",
      object: null,
    });
  },
  async linkCreate(param: CreateLinkParam) {
    await gtmTrackEvent({
      eventName: "create_link",
      object: param,
    });
  },
  async ndaUploadTry() {
    await gtmTrackEvent({
      eventName: "try_upload_nda",
      object: null,
    });
  },
  async ndaUpload() {
    await gtmTrackEvent({
      eventName: "upload_nda",
      object: null,
    });
  },
  async signatureUpload() {
    await gtmTrackEvent({
      eventName: "upload_signature",
      object: null,
    });
  },
  async linkSend() {
    await gtmTrackEvent({
      eventName: "send_link",
      object: null,
    });
  },
  async securityUpload() {
    await gtmTrackEvent({
      eventName: "upload_security",
      object: null,
    });
  },
  async beginCheckout(selectCard: "domestic" | "global", value: number) {
    let currrency = selectCard === "domestic" ? "KRW" : "global";
    await gtmTrackEvent({
      eventName: "begin_checkout",
      object: {
        currrency: currrency,
        value: value,
      },
    });
  },
  async purchase(selectCard: "domestic" | "global", value: number) {
    let currrency = selectCard === "domestic" ? "KRW" : "global";
    await gtmTrackEvent({
      eventName: "purchase",
      object: {
        currrency: currrency,
        value: value,
      },
    });
  },
};
