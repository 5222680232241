import { createStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";
import {
  BaseSideBarWidth,
  ServiceHeaderHeightXL,
  ServiceHeaderSidePaddingXL,
} from "../../utils/templates/styles/BaseGrid";

const styles = (MuiTheme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      minWidth: BaseSideBarWidth,
      maxWidth: BaseSideBarWidth,
      height: "100%",
      outline: "none",
      alignContent: "flex-start",
      borderRight: `1px solid ${theme.white}`,
    },
    drawerPaper: { border: "0px", maxWidth: BaseSideBarWidth },
    header: {
      minHeight: 72,
      maxHeight: 72,
      width: 300,
      borderBottom: `1px solid ${theme.gray_2}`,
      [MuiTheme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    headerButton: {
      height: 56,
      width: 160,
      marginLeft: ServiceHeaderSidePaddingXL,
    },
    headerLogo: {
      width: 170,
      height: 24,
      objectFit: "cover",
    },
    workspaceList: {
      width: 72,
      height: "100%",
      backgroundColor: theme.gray_1,
      paddingTop: 10,
    },
    workspaceItem: {
      width: 65,
      height: 65,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    workspaceItemTooltip: { ...theme.font12 },
    workspaceAddGrid: {
      cursor: "pointer",
      width: 45,
      height: 45,
      borderRadius: 10,
      backgroundColor: theme.white,
      ...theme.shadow_1,
    },
    workspaceAddIcon: { ...theme.font32, color: theme.gray_7_text },
    folderListGrid: {
      flex: 1,
      height: "100%",
      backgroundColor: "white",
      borderRight: `1px solid ${theme.gray_2}`,
    },
    folderSideGrid: {
      width: "100%",
      backgroundColor: "white",
      marginBottom: 12,
      marginTop: 20,
      paddingLeft: 16,
      border: "none",
    },
    workspaceUrlGrid: { flex: 1 },
    workspaceName: {
      color: theme.gray_9_title,
      ...theme.font18,
      width: BaseSideBarWidth - ServiceHeaderHeightXL - 24 - 40 - 10,
      marginLeft: 6,
    },
    workspaceUrl: {
      color: theme.gray_6,
      ...theme.font10,
      width: BaseSideBarWidth - ServiceHeaderHeightXL - 24 - 40 - 10,
      marginLeft: 6,
    },
    settingsIconButton: {
      width: 36,
      height: 36,
      backgroundColor: theme.gray_2,
      marginRight: 12,
    },
    settingsIcon: { color: theme.gray_7_text, ...theme.font24 },
    workspaceMenu: { borderRadius: 8 },
    workspaceMenuPaper: {
      borderRadius: 8,
      border: `none`,
      boxShadow: `rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px`,
    },
    menuItem1: { padding: 0, minWidth: 240 },
    menuItemText: { ...theme.font12, userSelect: "none" },
    menuDivider: {
      marginTop: 8,
      marginBottom: 8,
      backgroundColor: theme.gray_1,
    },
    clickableMenuItemText: {
      ...theme.font10,
      userSelect: "none",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    menuItemText2: { ...theme.font12, marginRight: 4, userSelect: "none" },
    menuItemText3: {
      ...theme.font12,
      color: theme.indigo_6,
      userSelect: "none",
    },
    groupAddIcon: {
      ...theme.font20,
      color: theme.gray_7_text,
      marginRight: 8,
    },
    menuItemText4: {
      ...theme.font14,
      color: theme.gray_7_text,
      userSelect: "none",
    },
    menuItemText5: {
      ...theme.font20,
      color: theme.gray_7_text,
      marginRight: 8,
      userSelect: "none",
    },

    copyBtn: {
      backgroundColor: theme.gray_1,
      position: "relative",
      padding: "4px",
      marginLeft: 15,
      transition: "0.25s",
      "&:hover": {
        backgroundColor: theme.indigo_0,
      },
    },

    copyBtnText: {
      color: theme.gray_7_text,
      fontSize: 8,
      fontWeight: "bold",
      textTransform: "initial",
    },

    pinnedGrid: {
      cursor: "pointer",
      width: "100%",
      height: 40,
      paddingLeft: 8,
      backgroundColor: theme.gray_0,
      userSelect: "none",
    },
    starIcon: {
      color: theme.gray_5,
      ...theme.font16,
      marginLeft: 3,
      marginRight: 3,
    },
    folderTitleText: {
      color: theme.gray_5,
      ...theme.font14,
      marginLeft: 10,
    },
    folderGrid: { width: "100%", paddingLeft: 8 },
    folderList: {
      cursor: "pointer",
      width: "100%",
      height: 40,
      paddingLeft: 8,
      position: "relative",
      userSelect: "none",
    },

    signOutWorkspaceButton: {
      paddingLeft: 12,
      display: "block",
      transition: "0.25s",
      "&:hover": {
        backgroundColor: theme.indigo_0,
        borderRadius: 0,
      },
    },

    workspaceCardInnerDiv: {
      borderRadius: 8,
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      alignItems: "center",
    },

    workspaceSubMenuCardInnerDiv: {
      borderRadius: 8,
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      alignItems: "center",
      transition: "0.25s",
      "&:hover": {
        backgroundColor: theme.indigo_0,
        borderRadius: 0,
      },
    },

    workspaceCardTitle: {
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "initial",
      textAlign: "left",
      textOverflow: "ellipsis",
      overflow: "hidden",
      letterSpacing: "normal",
    },
    workspaceCardSubTitle: {
      fontSize: 12,
      color: theme.gray_5,
      textTransform: "initial",
      textAlign: "left",
      textOverflow: "ellipsis",
      overflow: "hidden",
      letterSpacing: "normal",
    },

    arrowDropUpIcon: {
      color: theme.gray_5,
      paddingLeft: 8,
    },

    addNewWorkspaceIcon: {
      borderRadius: 8,
      width: 45,
      height: 45,
      backgroundColor: theme.indigo_5,
      marginLeft: 12,
      marginRight: 8,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });

export default styles;
