import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";

interface Props {
  rippleColor?: string;
}

const useStyles = makeStyles<Theme, Props>((_MuiTheme: Theme) =>
  createStyles({
    button_base: {
      borderRadius: 8,
      padding: "9px 19px",
      width: "max-content",
    },

    text: {
      ...theme.font14,
      ...theme.spoqahans_bold,
      whiteSpace: "nowrap",
      height: "20px",
      "&.Mui-disabled": {
        color: theme.gray_4,
      },
    },

    fill_disabled: {
      "&.Mui-disabled": {
        backgroundColor: theme.gray_1,
        border: `solid 1px ${theme.gray_1}`,
      },
    },
    line_disabled: {
      "&.Mui-disabled": {
        border: `solid 1px ${theme.gray_2}`,
      },
    },

    button_primary: {
      backgroundColor: theme.indigo_6,
      border: `solid 1px ${theme.indigo_6}`,
      "&:hover": {
        backgroundColor: theme.indigo_8,
        borderColor: theme.indigo_8,
      },
    },
    text_primary: {
      color: theme.white,
    },

    button_secondary: {
      backgroundColor: theme.indigo_0,
      border: `solid 1px ${theme.indigo_0}`,
      "&:hover": {
        backgroundColor: theme.indigo_1,
        borderColor: theme.indigo_1,
      },
    },
    text_secondary: {
      color: theme.indigo_6,
    },

    button_line_indigo: {
      backgroundColor: theme.white,
      border: `solid 1px ${theme.indigo_2}`,
      "&:hover": {
        backgroundColor: theme.indigo_0,
      },
    },
    text_line_indigo: {
      color: theme.indigo_6,
      ...theme.spoqahans_regular,
    },

    button_line_gray: {
      backgroundColor: theme.white,
      border: `solid 1px ${theme.gray_2}`,
      "&:hover": {
        backgroundColor: theme.gray_0,
      },
    },
    text_line_gray: {
      color: theme.gray_7_text,
    },

    button_danger_line: {
      backgroundColor: theme.white,
      border: `solid 1px ${theme.system_red_bg}`,
      "&:hover": {
        backgroundColor: theme.red_0,
      },
    },
    text_danger_line: {
      color: theme.system_red,
    },

    button_danger_fill: {
      backgroundColor: theme.system_red,
      border: `solid 1px ${theme.system_red}`,
      "&:hover": {
        backgroundColor: theme.system_red_hovered,
      },
    },
    text_danger_fill: {
      color: theme.white,
    },

    ripple_root_fill: {
      top: "unset",
      left: "unset",
      right: "unset",
      bottom: "unset",
      width: "calc(100% + 2px)",
      height: "calc(100% + 2px)",
      zIndex: 1,
    },
    ripple_root_line: {
      borderRadius: 7,
      zIndex: 1,
    },

    ripple_visible: {
      opacity: 1,
      "& > span": {
        color: (props) => props.rippleColor,
      },
      animationName: "$rippleVisible",
    },
    "@keyframes rippleVisible": {
      "0%": {
        color: (props) => props.rippleColor,
        transform: "scale(0)",
        opacity: 0.3,
      },
      "100%": {
        color: (props) => props.rippleColor,
        transform: "scale(1)",
        opacity: 1,
      },
    },
  })
);

export default useStyles;
