import React from "react";
import { Button, FormLabel, Grid } from "@material-ui/core";
import SpoqaHansTypography from "@SpoqaHansTypography";
import { useStyles } from "./styles/EmptyList";
import translate from "../../../utils/translate";

type EmptyListProps = {
  title: string;
  imgElem: JSX.Element;
  button?: {
    htmlFor: string;
    text: string;
  };
};

export default ({ title, imgElem, button }: EmptyListProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      justifyContent="center"
      className={classes.no_nda_grid}
    >
      <div className={classes.nda_upload_border}>
        {imgElem}
        <SpoqaHansTypography
          fontWeight="bold"
          className={classes.drag_tip_text}
        >
          {title}
        </SpoqaHansTypography>

        {button && (
          <>
            <SpoqaHansTypography
              fontWeight="medium"
              className={classes.drag_or_text}
            >
              {translate.ndas.or}
            </SpoqaHansTypography>

            <Button
              disableRipple
              className={classes.upload_button}
              component={FormLabel}
              htmlFor={button.htmlFor}
            >
              <SpoqaHansTypography
                fontWeight="bold"
                className={classes.upload_button_text}
              >
                {button.text}
              </SpoqaHansTypography>
            </Button>
          </>
        )}
      </div>
    </Grid>
  );
};
