import { doAPIGetRequest } from "./api";
import { OGetTask, validateOGetTask } from "@basalt-commons/api/response/task";
import moment from "moment";
import { TaskStatusType } from "@basalt-commons/api/object/task";
import { delay } from "@redwit-commons/utils/function";

const RETRY_THRES = 3000;
const MAXIMUM_RETRIES = RETRY_THRES;
/**
 *
 * @param taskId string
 * @param token string
 * @returns Promise<OGetTask>
 */
const getTask = async (taskId: string, token: string): Promise<OGetTask> => {
  const ret = await doAPIGetRequest(`task/${taskId}`, token);
  return validateOGetTask(ret);
};
/**
 * task 완료를 확인하는 함수
 * @param taskId
 * @param token
 * @returns Promis<boolean>
 */
const waitforTaskComplete = async (
  taskId: string,
  token: string
): Promise<boolean> => {
  let trial = 0;
  for (;;) {
    if (trial >= MAXIMUM_RETRIES) return false;
    const before = moment();
    const ret = await getTask(taskId, token);
    if (ret.response.status === TaskStatusType.CANCELLED) return false;
    if (ret.response.status === TaskStatusType.COMPLETED) return true;
    const after = moment();
    const diffTime = after.diff(before).valueOf() - RETRY_THRES;
    if (diffTime < 0)
      // 인터넷 속도 확인
      await delay(RETRY_THRES);
    trial += 1;
  }
};

export default {
  getTask,
  waitforTaskComplete,
};
