import { GoonoResponse, mkResponseSchema } from "./common";
import T from "@redwit-commons/utils/typecheck";
import { PaymentDBObject, PaymentDBObjectSchema } from "../object/payment";

export type OIssueVBank = GoonoResponse<PaymentDBObject>;
export type OIssueCard = GoonoResponse<PaymentDBObject>;

export const OIssueVBankSchema = mkResponseSchema(PaymentDBObjectSchema);
export const validateOIssueVBank =
  T.mkValidator<OIssueVBank>(OIssueVBankSchema);
export const OIssueCardSchema = mkResponseSchema(PaymentDBObjectSchema);
export const validateOIssueCard = T.mkValidator<OIssueCard>(OIssueCardSchema);

export type OWebHook = GoonoResponse<PaymentDBObject>;
export type OPaymentCheck = GoonoResponse<PaymentDBObject>;
export const OWebHookSchema = OIssueVBankSchema.clone();
export const OPaymentCheckSchema = mkResponseSchema(PaymentDBObjectSchema);
export const validateOPaymentCheck =
  T.mkValidator<OPaymentCheck>(OPaymentCheckSchema);

export type OGetPendingPayment = GoonoResponse<PaymentDBObject>;
export const OGetPendingPaymentSchema = mkResponseSchema(PaymentDBObjectSchema);
export const validateOGetPendingPayment = T.mkValidator<OGetPendingPayment>(
  OGetPendingPaymentSchema
);
