import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { theme } from "@theme";
import SpoqaHansTypography from "@SpoqaHansTypography";

const useSidebarDropDownMenuViewStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
    width: "100%",
    height: 40,
    paddingLeft: 8,
    position: "relative",
    userSelect: "none",
  },
  icon: {
    color: theme.gray_5,
    paddingLeft: 8,
  },
  menu_name: {
    color: theme.gray_5,
    ...theme.font14,
    marginLeft: 10,
  },
  list_container: {
    width: "100%",
    paddingLeft: 8,
  },
}));

type SidebarDropDownMenuViewProps = {
  menu_name: string;
  listComponent: JSX.Element;
};

const SidebarDropDownMenuView = ({
  menu_name,
  listComponent,
}: SidebarDropDownMenuViewProps) => {
  const [open, setOpen] = useState(true);
  const classes = useSidebarDropDownMenuViewStyles();

  const renderIcon = () => {
    return open ? (
      <ArrowDropDownIcon className={classes.icon} />
    ) : (
      <ArrowDropUpIcon className={classes.icon} />
    );
  };

  const dropDownMenu = () => {
    return (
      <Grid
        onClick={() => {
          setOpen(!open);
        }}
        container
        item
        alignItems="center"
        direction="row"
        className={classes.root}
      >
        {renderIcon()}
        <SpoqaHansTypography fontWeight="medium" className={classes.menu_name}>
          {menu_name}
        </SpoqaHansTypography>
      </Grid>
    );
  };

  const menuItemListView = () => {
    return (
      <Grid
        container
        item
        direction="column"
        justifyContent="flex-start"
        className={classes.list_container}
      >
        {listComponent}
      </Grid>
    );
  };

  return (
    <>
      {dropDownMenu()}
      {open && menuItemListView()}
    </>
  );
};

export default SidebarDropDownMenuView;
