export default {
  ko: {
    service_header: {
      notifications: {
        no_alarm: {
          title: "알림이 없습니다.",
          body: "내가 받은 초대 및 코멘트와 내가 참여하는 워크스페이스 변경사항이 여기에 표시됩니다.",
        },
        tooltip: "알림",
        mention: {
          title: " 님이 언급하셨습니다.",
        },
        new_rule: {
          title: "워크스페이스에 새로운 룰이 추가되었습니다.",
          add: "추가한 사용자",
        },
        invite_folder: {
          title1: " 님이 ",
          title2: " 에 초대하셨습니다.",
          role: "권한",
        },
      },
      tutorial: {
        tooltip: "튜토리얼 보기",
      },
      search: {
        placeholder: "검색어를 입력하세요",
        in_all_workspace: "모든 워크스페이스 내에서",
        in_this_workspace: "지금 워크스페이스 내에서",
        most_searched: "최근 검색어",
        folders: "폴더",
      },
      settings: "계정 설정",
      signature_doc: "서명 문서 확인",
      logout: "로그아웃",
      timezone: "시간대",
      language: "언어",
    },
  },
  en: {
    service_header: {
      notifications: {
        no_alarm: {
          title: "There is no notifications",
          body: "The invitation and comments I reviced and changeds to the workspace are displayed here",
        },
        tooltip: "Notifications",
        mention: {
          title: " mentioned you",
        },
        new_rule: {
          title: "A new rule has beed added to workspace",
          add: "Added by",
        },
        invite_folder: {
          title1: " invited you to the ",
          title2: "",
          role: "Role",
        },
      },
      tutorial: {
        tooltip: "See tutorial",
      },
      search: {
        placeholder: "Enter a search word",
        in_all_workspace: "In all workspaces",
        in_this_workspace: "In this workspace",
        most_searched: "Most Searched",
        folders: "Folders",
      },
      settings: "Account settings",
      signature_doc: "Signature document",
      logout: "Logout",
      timezone: "Timezone",
      language: "Language",
    },
  },
};
