import React, { createContext, useContext } from "react";
import {
  Route,
  Redirect,
  RouteProps,
  SwitchProps,
  Switch,
} from "react-router-dom";
import { ScreenURL } from "./path";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { TokenState, TokenStateStatus } from "../store/reducers/token";
import { marketingFlow } from "@src/services/marketing-tool/marketing-flow";

type userAuthType = {
  user: TokenState;
  isSignedIn: () => boolean;
};

const authContext = createContext({
  user: { status: TokenStateStatus.INIT },
  isSignedIn: () => false,
} as userAuthType);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().

export const ProvideAuth: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const token = useSelector((store: RootState) => store.token);
  const isSignedIn = () => {
    if (token.state.status !== TokenStateStatus.SUCCESS) return false;
    return true;
  };

  return {
    user: token.state,
    isSignedIn,
  };
}
export const PrivateRoute: React.FC<RouteProps> = ({ ...rest }) => {
  let auth = useAuth();
  return auth.isSignedIn() ? (
    <Route {...rest} />
  ) : (
    <Route
      render={({ location }) => (
        <Redirect
          to={{ pathname: ScreenURL.SIGN_IN, state: { from: location } }}
        />
      )}
    />
  );
};

export const LandingRoute: React.FC<RouteProps> = ({ ...rest }) => {
  return <Route {...rest} />;
};

export const RootSwitch: React.FC<SwitchProps> = ({ children }) => {
  let auth = useAuth();
  let name: string | undefined = undefined;
  void marketingFlow.useIdentifyEffect(auth.user);
  const pathname = window.location.pathname;
  const path = pathname.trim().split("/");
  // TODO: 더 좋은 방법으로 변경하기.
  if (auth.isSignedIn() && path.length > 0) {
    if (path.length === 1) {
      name = path[0];
    }
  }
  return (
    <Switch>
      {children}
      <Route
        path="*"
        render={() => {
          // redirect to workspace main
          if (name !== undefined) {
            return <Redirect to={`/${name}/main`} />;
          }
          return <Redirect to={"/"} />;
        }}
      />
    </Switch>
  );
};
