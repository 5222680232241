import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { DocumentObject } from "@basalt-commons/global-api/object/document";
import { NdaPureObject } from "@basalt-commons/global-api/object/nda";
import { IGetNoteOrigin } from "@basalt-commons/global-api/request/note";

export enum DocumentStateStatus {
  INIT = "Document::INIT",
}

export enum DocumentActionKind {
  TRY_GET_INFO = "DocumentAction::TRY_GET_INFO",
  TRY_RESET = "DocumentAction::TRY_RESET",
  TRY_CREATE_CONTRACT = "DocumentAction::TRY_CREATE_CONTRACT",
  TRY_UPDATE_STATUS = "DocumentAction::TRY_UPDATE_STATUS",
  TRY_SHARE = "DocumentAction::TRY_SHARE",
  TRY_CHECK_CONTRACT = "DocumentAction::TRY_CHECK_CONTRACT",
  TRY_DOWNLOAD_FILE = "DocumentAction::TRY_DOWNLOAD_FILE",
}

export type DocumentState = {
  readonly status: DocumentStateStatus.INIT;
  readonly decoded_info?: DocumentObject & { Nda?: NdaPureObject };
  readonly token?: string;
  readonly need_sign: boolean; // 로그인 유저에 대해서 서명 내역이 있는지 확인
  readonly filePath?: string;
};

export type DocumentAction =
  | {
      readonly kind: DocumentActionKind.TRY_GET_INFO;
      readonly id: string;
    }
  | {
      readonly kind: DocumentActionKind.TRY_RESET;
    }
  | {
      readonly kind: DocumentActionKind.TRY_CREATE_CONTRACT;
      readonly DocumentId: string;
      readonly NdaId: string;
      readonly client_name: string;
      readonly client_department: string;
      readonly client_sign_cid: string;
      readonly client_sign_extension: string;
    }
  | {
      readonly kind: DocumentActionKind.TRY_UPDATE_STATUS;
      readonly doc: DocumentObject;
    }
  | {
      readonly kind: DocumentActionKind.TRY_SHARE;
      readonly DocumentId: string;
      readonly emails: string[];
      readonly message: string;
    }
  | {
      readonly kind: DocumentActionKind.TRY_CHECK_CONTRACT;
      readonly DocumentId: string;
      readonly NdaId: string;
    }
  | {
      readonly kind: DocumentActionKind.TRY_DOWNLOAD_FILE;
      readonly args: IGetNoteOrigin;
    };

export type DocumentError = never;

const smid = "DOCUMENT_STATE_MACHINE3";
export type DocumentStateMachineType = StateMachine3<
  DocumentStateStatus,
  DocumentState,
  DocumentActionKind,
  DocumentAction,
  DocumentError
>;
export const documentStateMachine: DocumentStateMachineType = new StateMachine3<
  DocumentStateStatus,
  DocumentState,
  DocumentActionKind,
  DocumentAction,
  DocumentError
>(smid, { status: DocumentStateStatus.INIT, need_sign: false }, [
  transition(
    DocumentStateStatus.INIT,
    DocumentStateStatus.INIT,
    DocumentActionKind.TRY_GET_INFO
  ),
  transition(
    DocumentStateStatus.INIT,
    DocumentStateStatus.INIT,
    DocumentActionKind.TRY_RESET
  ),
  transition(
    DocumentStateStatus.INIT,
    DocumentStateStatus.INIT,
    DocumentActionKind.TRY_CREATE_CONTRACT
  ),
  transition(
    DocumentStateStatus.INIT,
    DocumentStateStatus.INIT,
    DocumentActionKind.TRY_UPDATE_STATUS
  ),
  transition(
    DocumentStateStatus.INIT,
    DocumentStateStatus.INIT,
    DocumentActionKind.TRY_SHARE
  ),
  transition(
    DocumentStateStatus.INIT,
    DocumentStateStatus.INIT,
    DocumentActionKind.TRY_CHECK_CONTRACT
  ),
  transition(
    DocumentStateStatus.INIT,
    DocumentStateStatus.INIT,
    DocumentActionKind.TRY_DOWNLOAD_FILE
  ),
]);

/* */
export type DispatchDocumentAction = Dispatch<
  StateMachineAction<
    DocumentStateStatus,
    DocumentState,
    DocumentActionKind,
    DocumentAction,
    DocumentError
  >
>;
export default mkReducer<
  DocumentStateStatus,
  DocumentState,
  DocumentActionKind,
  DocumentAction,
  DocumentError
>(documentStateMachine);
export const doDocumentAction = (
  dispatch: DispatchDocumentAction,
  nextAction: DocumentAction,
  onResolve: () => void = () => {},
  onReject: (err: DocumentError | InternalError) => void = () => {}
) => {
  dispatch(documentStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doDocumentActionAsync = (
  dispatch: DispatchDocumentAction,
  nextAction: DocumentAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(documentStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetDocument = (dispatch: DispatchDocumentAction) => {
  dispatch(documentStateMachine.newResetAction());
};
