import React from "react";
import { Typography } from "@material-ui/core";

type RobotoTypographyProps = {
  fontWeight: "regular" | "medium" | "bold";
  style: object;
  className: string | undefined;
  id: string | undefined;
  noWrap: boolean;
};

type RobotoTypographyState = {};

class RobotoTypography extends React.PureComponent<
  RobotoTypographyProps,
  RobotoTypographyState
> {
  static defaultProps = {
    style: {},
    fontWeight: "regular",
    id: undefined,
    className: undefined,
    noWrap: false,
  };
  constructor(props: RobotoTypographyProps) {
    super(props);
  }

  fontFamily() {
    const { fontWeight } = this.props;
    switch (fontWeight) {
      case "bold":
        return { fontFamily: "RobotoBold" };
      case "regular":
        return { fontFamily: "RobotoRegular" };
      case "medium":
        return { fontFamily: "RobotoMedium" };
    }
  }

  render() {
    const { style, className, id, noWrap } = this.props;
    const fontFamily = this.fontFamily();
    return (
      <Typography
        noWrap={noWrap}
        id={id}
        style={{ ...fontFamily, ...style }}
        className={className}
      >
        {this.props.children}
      </Typography>
    );
  }
}

export default RobotoTypography;
