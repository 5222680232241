import T from "@redwit-commons/utils/typecheck";
import { AccessTime, addAccessTime } from "@redwit-commons/object/access_time";
export interface PaypleCoreWithoutEtc {
  readonly id: string;
  readonly api_id: string; //거래 ID
  readonly service_oid: string; //주문 번호
  readonly pay_type: string;
  readonly WorkspaceId: string;
}
export type PaypleDBObjectWOEtc = PaypleCoreWithoutEtc & AccessTime;
export type PaypleCore = PaypleCoreWithoutEtc & { etc: string };
export type PaypleDBObject = PaypleCore & AccessTime;
export type PayplePaymentObject = PaypleDBObjectWOEtc & PaypleDescription;

export type PaypleDescription = {
  // 해외 결제 결과 파라미터
  api_date?: string;
  product?: string;
  card_number?: string;
  totalAmount?: string;
  currency?: string;
  firstName?: string;
  lastName?: string;
  address1?: string;
  locality?: string;
  administrativeArea?: string;
  postalCode?: string;
  country?: string;
  email?: string;
  phoneNumber?: string;
  submitTimeUtc?: string;
  description?: string;
  description_en?: string;
  // 국내 결제 결과 파라미터
  PCD_PAY_CARDAUTHNO?: string;
  PCD_PAY_CARDRECEIPT?: string;
};

export const PaypleCoreWithoutEtcSchema = T.object()
  .addField("id", T.string())
  .addField("api_id", T.string())
  .addField("service_oid", T.string())
  .addField("pay_type", T.string())
  .addField("WorkspaceId", T.string());

export const PaypleDBObjectWOEtcSchema = addAccessTime(
  PaypleCoreWithoutEtcSchema.clone()
);
export const PaypleCoreSchema = PaypleCoreWithoutEtcSchema.clone().addField(
  "etc",
  T.string()
);
export const PaypleDBObjectSchema = addAccessTime(PaypleCoreSchema.clone());
export const extractPaypleDBObjectWOEtc =
  T.mkObjectExtractor<PaypleDBObjectWOEtc>(PaypleDBObjectWOEtcSchema);
export const extractPaypleDBObject =
  T.mkObjectExtractor<PaypleDBObject>(PaypleDBObjectSchema);

export const addPaypleDescription = (from: T): T => {
  return from
    .addField("api_date", T.string(), false)
    .addField("product", T.string(), false)
    .addField("card_number", T.string(), false)
    .addField("totalAmount", T.string(), false)
    .addField("currency", T.string(), false)
    .addField("firstName", T.string(), false)
    .addField("lastName", T.string(), false)
    .addField("address1", T.string(), false)
    .addField("locality", T.string(), false)
    .addField("administrativeArea", T.string(), false)
    .addField("postalCode", T.string(), false)
    .addField("country", T.string(), false)
    .addField("email", T.string(), false)
    .addField("phoneNumber", T.string(), false)
    .addField("submitTimeUtc", T.string(), false)
    .addField("description", T.string(), false)
    .addField("description_en", T.string(), false)
    .addField("PCD_PAY_CARDAUTHNO", T.string(), false)
    .addField("PCD_PAY_CARDRECEIPT", T.string(), false);
};

export const PayplePaymentObjectSchema = addPaypleDescription(
  PaypleDBObjectWOEtcSchema.clone()
);

export const validatePaypleDescription = T.mkValidator<PaypleDescription>(
  addPaypleDescription(T.object())
);
export const validatePayplePaymentObject = T.mkValidator<PayplePaymentObject>(
  PayplePaymentObjectSchema
);

export const refinePayplePayment = (rdbPaple: PaypleDBObject) => {
  const etc_string = rdbPaple.etc;
  const PaypleExtraInfo = validatePaypleDescription(JSON.parse(etc_string));
  const PayplePayment = validatePayplePaymentObject({
    ...rdbPaple,
    ...PaypleExtraInfo,
  });
  return PayplePayment;
};

type ResultOfGlobalPurchasePayple = {
  result: string; //응답 코드
  message: string; //응답 메시지
};
type CoreResponseOfGlobalPurchasePayple = {
  resultUrl?: string; //결제결과 반환 URL
  api_id: string; // 결제 요청 고유키
  api_date: string; // 결제 시간(페이플 서버기준: GMT +9)
} & ResultOfGlobalPurchasePayple;
type ExtraResponseOfGlobalPurchasePayple = {
  service_oid: string; //주문번호
  comments: string; //상품명
  pay_type: string; //결제수단
  card_number: string; //카드번호(일부 마스킹 처리)
  totalAmount: string; // 결제 요청 금액
  currency: string; //통화
  firstName: string; //카드소유주 이름
  lastName: string; //카드소유주 성
  address1: string; //도로명
  locality: string; //시/구/군
  administrativeArea: string; //도/시 코드
  postalCode: string; //우편번호
  country: string; //국가 코드
  email: string; //이메일 주소
  phoneNumber: string; //휴대전화 번호
  billing_key: string; // 빌링키
  submitTimeUtc: string; //결제 시간(해외 사용자 기준: GMT)
};
export type PaypleGlobalPaymentResponse = CoreResponseOfGlobalPurchasePayple &
  ExtraResponseOfGlobalPurchasePayple;
export type PaypleGlobalBillingKeyPaymentResponse = {
  info: ExtraResponseOfGlobalPurchasePayple;
} & CoreResponseOfGlobalPurchasePayple;

export const ResultOfGlobalPurchasePaypleSchema = T.object()
  .addField("result", T.string())
  .addField("message", T.string());

export const CoreResponseOfGlobalPurchasePaypleSchema =
  ResultOfGlobalPurchasePaypleSchema.clone()
    .addField("resultUrl", T.string(), false)
    .addField("api_id", T.string())
    .addField("api_date", T.string());

export const ExtraResponseOfGlobalPurchasePaypleSchema = T.object()
  .addField("service_oid", T.string())
  .addField("comments", T.string())
  .addField("pay_type", T.string())
  .addField("card_number", T.string())
  .addField("totalAmount", T.string())
  .addField("currency", T.string())
  .addField("firstName", T.string())
  .addField("lastName", T.string())
  .addField("address1", T.string())
  .addField("locality", T.string())
  .addField("administrativeArea", T.string())
  .addField("postalCode", T.string())
  .addField("country", T.string())
  .addField("email", T.string())
  .addField("phoneNumber", T.string())
  .addField("billing_key", T.string())
  .addField("submitTimeUtc", T.string());

export const PaypleGlobalPaymentResponseSchema =
  ExtraResponseOfGlobalPurchasePaypleSchema.clone()
    .addField("result", T.string())
    .addField("message", T.string())
    .addField("resultUrl", T.string(), false)
    .addField("api_id", T.string())
    .addField("api_date", T.string());

export const PaypleGlobalBillingKeyPaymentResponseSchema =
  CoreResponseOfGlobalPurchasePaypleSchema.clone().addField(
    "info",
    ExtraResponseOfGlobalPurchasePaypleSchema.clone()
  );

export const validatePaypleGlobalBillingKeyPaymentResponse =
  T.mkValidator<PaypleGlobalBillingKeyPaymentResponse>(
    PaypleGlobalBillingKeyPaymentResponseSchema
  );

export const validateResultOfGlobalPurchasePayple =
  T.mkValidator<ResultOfGlobalPurchasePayple>(
    ResultOfGlobalPurchasePaypleSchema
  );

type ResultOfDomesticPurchasePayple = {
  PCD_PAY_RST: string; //요청 결과 success, error, close
  PCD_PAY_CODE: string; //응답코드
  PCD_PAY_MSG: string; //요청 결과 메시지
};
type CoreResponseOfDomesticPurchasePayple = {
  PCD_PAYER_ID: string; // 빌링키
  PCD_PAY_OID: string; //주문번호
  PCD_PAY_TYPE: string; //결제수단(카드: card, 계좌:transfer)
} & ResultOfDomesticPurchasePayple;
type ExtraResponseOfDomesticPurchasePayple = {
  PCD_PAYER_NO?: string; //파트너사에서 사용하는 회원번호
  PCD_PAYER_NAME?: string; //고객 이름
  PCD_PAYER_HP?: string; //고객 휴대전화번호
  PCD_PAYER_EMAIL?: string; //고객 이메일
  PCD_PAY_GOODS: string; //상품명
  PCD_PAY_TOTAL: string; //결제금액
  PCD_PAY_CARDNAME: string; //카드사명
  PCD_PAY_CARDNUM: string; //카드번호
  PCD_PAY_CARDAUTHNO: string; //승인번호
  PCD_PAY_CARDRECEIPT: string; //매출전표 출력 링크
  PCD_PAY_TIME: string; //결제시간
  PCD_RST_URL?: string; //결제(요청)결과 RETURN URL
};

export type PaypleDomesticPaymentResponse = {
  PCD_AUTH_KEY: string; //결제 후 리턴 받은 토큰키
  PCD_PAY_REQKEY: string; // CERT - 결제생성 후 승인을 위한 키
  PCD_PAY_WORK: string; //결제요청방식(CERT)
  PCD_PAY_COFURL: string; //최종 결제요청 URL(CERT)
} & CoreResponseOfDomesticPurchasePayple &
  ExtraResponseOfDomesticPurchasePayple;
export type PaypleCertResponse = CoreResponseOfDomesticPurchasePayple &
  ExtraResponseOfDomesticPurchasePayple;

export const ResultOfDomesticPurchasePaypleSchema = T.object()
  .addField("PCD_PAY_RST", T.string())
  .addField("PCD_PAY_CODE", T.string())
  .addField("PCD_PAY_MSG", T.string());
export const CoreResponseOfDomesticPurchasePaypleSchema =
  ResultOfDomesticPurchasePaypleSchema.clone()
    .addField("PCD_PAYER_ID", T.string())
    .addField("PCD_PAY_OID", T.string())
    .addField("PCD_PAY_TYPE", T.string());
export const addExtraResponseOfDomesticPurchasePayple = (from: T): T => {
  return from
    .addField("PCD_PAYER_NO", T.string(), false)
    .addField("PCD_PAYER_NAME", T.string(), false)
    .addField("PCD_PAYER_HP", T.string(), false)
    .addField("PCD_PAYER_EMAIL", T.string(), false)
    .addField("PCD_PAY_GOODS", T.string())
    .addField("PCD_PAY_TOTAL", T.string())
    .addField("PCD_PAY_CARDNAME", T.string())
    .addField("PCD_PAY_CARDNUM", T.string())
    .addField("PCD_PAY_CARDAUTHNO", T.string())
    .addField("PCD_PAY_CARDRECEIPT", T.string())
    .addField("PCD_PAY_TIME", T.string())
    .addField("PCD_RST_URL", T.string(), false);
};
export const PaypleCertResponseSchema =
  addExtraResponseOfDomesticPurchasePayple(
    CoreResponseOfDomesticPurchasePaypleSchema.clone()
  );
export const PaypleDomesticPaymentResponseSchema =
  PaypleCertResponseSchema.clone()
    .addField("PCD_AUTH_KEY", T.string())
    .addField("PCD_PAY_REQKEY", T.string())
    .addField("PCD_PAY_WORK", T.string())
    .addField("PCD_PAY_COFURL", T.string());

export const validatePaypleCertResponse = T.mkValidator<PaypleCertResponse>(
  PaypleCertResponseSchema
);
export const validateResultOfDomesticPurchasePayple =
  T.mkValidator<ResultOfDomesticPurchasePayple>(
    ResultOfDomesticPurchasePaypleSchema
  );
