import T from "@redwit-commons/utils/typecheck";
import {
  GoonoResponse,
  mkResponseSchema,
  GoonoArrayResponse,
  mkArrayResultsSchema,
} from "./common";
import {
  LicensePureObjectWDescription,
  LicensePureObjectWDescriptionSchema,
  LicenseObject,
  LicenseObjectSchema,
  RefinedUserWithLicense,
  RefinedUserWithLicenseSchema,
} from "../object/license";

export type OGetUserLicense = GoonoArrayResponse<RefinedUserWithLicense>;
export type OCreateLicense = GoonoResponse<LicensePureObjectWDescription>;
export type OUpdateLicense = OCreateLicense;
export type ODeleteLicense = GoonoResponse<{ LicenseId: string }>;
export type OAssignLicense = GoonoResponse<LicenseObject>;
export type OResignLicense = OAssignLicense;
export type OChangeLicenseRole = OAssignLicense;

export const OGetUserLicenseSchema = mkArrayResultsSchema(
  RefinedUserWithLicenseSchema
);
export const OCreateLicenseSchema = mkResponseSchema(
  LicensePureObjectWDescriptionSchema
);
export const OUpdateLicenseSchema = OCreateLicenseSchema.clone();
export const ODeleteLicenseSchema = mkResponseSchema(
  T.object().addField("LicenseId", T.string())
);
export const OAssignLicenseSchema = mkResponseSchema(LicenseObjectSchema);
export const OResignLicenseSchema = OAssignLicenseSchema.clone();
export const OChangeLicenseRoleSchema = OAssignLicenseSchema.clone();

export const validateOGetUserLicense = T.mkValidator<OGetUserLicense>(
  OGetUserLicenseSchema
);
export const validateOCreateLicense =
  T.mkValidator<OCreateLicense>(OCreateLicenseSchema);
export const validateOUpdateLicense =
  T.mkValidator<OUpdateLicense>(OUpdateLicenseSchema);
export const validateODeleteLicense =
  T.mkValidator<ODeleteLicense>(ODeleteLicenseSchema);
export const validateOAssignLicense =
  T.mkValidator<OAssignLicense>(OAssignLicenseSchema);
export const validateOResignLicense =
  T.mkValidator<OResignLicense>(OResignLicenseSchema);
export const validateOChangeLicenseRole = T.mkValidator<OChangeLicenseRole>(
  OChangeLicenseRoleSchema
);
