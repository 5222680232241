import T from "@redwit-commons/utils/typecheck";
import {
  mkResponseSchema,
  GoonoResponse,
  GoonoPendingTasks,
} from "../../api/response/common";
import { TaskObject, TaskObjectSchema } from "../../api/object/task";

export type OGetNoteCertificationPDF = GoonoResponse<TaskObject> &
  GoonoPendingTasks<{ pdf: string }>;
export const OGetNoteCertificationPDFSchema = mkResponseSchema(
  TaskObjectSchema,
  T.object().addField("pdf", T.string())
);
export const validateOGetNoteCertificationPDF =
  T.mkValidator<OGetNoteCertificationPDF>(OGetNoteCertificationPDFSchema);

export type OGetNoteOrigin = GoonoResponse<{
  originalCid: string;
  originalExtension: string;
}>;
export const OGetNoteOriginSchema = mkResponseSchema(
  T.object()
    .addField("originalCid", T.string())
    .addField("originalExtension", T.string())
);
export const validateOGetNoteOrigin =
  T.mkValidator<OGetNoteOrigin>(OGetNoteOriginSchema);

export type OGetNoteOriginList = GoonoResponse<TaskObject> &
  GoonoPendingTasks<{ zip: string }>;
export const OGetNoteOriginListSchema = mkResponseSchema(
  TaskObjectSchema,
  T.object().addField("zip", T.string())
);
export const validateOGetNoteOriginList = T.mkValidator<OGetNoteOriginList>(
  OGetNoteOriginListSchema
);
