export default {
  ko: {
    landing_contact: {
      contact: {
        title: "무엇을 도와드릴까요?",
        sub_title: "영업 시간 기준 24시간 이내로 빠르게 답변 도와드립니다!",
        left_box: {
          title: "혹시 하단의 FAQ가 도움이 되지는\n않으신가요?",
          bold_text: "FAQ 바로 확인하기",
        },
        body: {
          text: {
            title: "문의 내용",
            placeholder: "기업 명 등을 포함한 구체적인 내용을 입력해주세요.",
          },
          category: {
            title: "카테고리",
            placeholder: "카테고리를 선택해주세요.",
            enterprise: "Enterprise 문의",
            feature: "기능 관련",
            promotion: "프로모션",
            partnership: "제휴 문의",
            etc: "기타",
          },
          name: {
            title: "이름",
            placeholder: "이름을 작성해주세요.",
          },
          email: {
            title: "이메일",
            placeholder: "이메일을 작성해주세요.",
          },
          inquire_placeholder:
            "기업 명 등을 포함한 구체적인 내용을 입력해주세요.",
          btn_txt: "약관 동의하고 문의 접수하기",
          policy:
            "* 이메일로 답변을 보내드리기 위해 <highlight>개인정보 수집 및 이용 약관 동의가 필요합니다.<highlight>",
        },
      },
      faq: {
        title: "자주 묻는 질문",
        search_placeholder: "궁금하신 질문의 키워드를 입력해 주세요",
        no_result: "입력하신 키워드에 대한 검색 결과가 없습니다",
      },
    },
  },
  en: {
    landing_contact: {
      contact: {
        title: "Contact Us",
        sub_title: "Within 24 business hours, we will respond to your inquiry!",
        left_box: {
          title: "Could you check the FAQ below?",
          bold_text: "Check the FAQ",
        },
        body: {
          text: {
            title: "Inquiry",
            placeholder:
              "Please fill out the information you want to inquire about",
          },
          category: {
            title: "Category",
            placeholder: "Select your category",
            enterprise: "Enterprise inquiry",
            feature: "About features",
            promotion: "Request a promotion",
            partnership: "Partnership inquiry",
            etc: "ETC",
          },
          name: {
            title: "Name",
            placeholder: "Enter your name",
          },
          email: {
            title: "Email",
            placeholder: "Please enter your email",
          },
          inquire_placeholder:
            "Please fill out the information you want to inquire about",
          btn_txt: "Send an inquiry",
          policy:
            "* To send you an answe via email, <highlight>please agree for the privacy policy.<highlight>",
        },
      },
      faq: {
        title: "FAQ",
        search_placeholder: "Enter a keyword for the question",
        no_result: "There is no results",
      },
    },
  },
};
