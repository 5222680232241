import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import {
  UserLogObject,
  UserLogObjectCore,
} from "@basalt-commons/api/object/user_log";
import { SearchParams } from "@basalt-commons/api/request/common";
import { ActivityType } from "@src/utils/data/LogActionType";

export enum LogStateStatus {
  INIT = "Log::INIT",
}

export enum LogActionKind {
  TRY_CREATE = "LogAction::TRY_CREATE",
  TRY_GET = "LogAction::TRY_GET",
  TRY_GET_MORE_ACTIVITIES = "LogAction::TRY_GET_MORE_ACTIVITIES",
  TRY_SET_ACTIVITY_FILTER = "LogAction::TRY_SET_ACTIVITY_FILTER",
  TRY_GET_NOTE_LOG = "LogAction::TRY_GET_NOTE_LOG",
  TRY_GET_DOC_LOG = "LogAction::TRY_GET_DOC_LOG",
  TRY_CREATE_VIEW_LOG = "LogAction::TRY_CREATE_VIEW_LOG",
}

export type LogState = {
  readonly status: LogStateStatus.INIT;
  readonly logs: UserLogObject[];
  readonly note_logs: UserLogObject[];
  readonly doc_logs: UserLogObject[];
  readonly activity_filter: { type?: ActivityType; userId?: string };
  readonly activity_period_filter: { afterAt?: string; beforeAt?: string };
  readonly no_more_logs?: boolean;
};

export type LogAction =
  | {
      readonly kind: LogActionKind.TRY_CREATE;
      readonly log: Omit<
        UserLogObjectCore,
        "url" | "body" | "query" | "ip_addr"
      >;
    }
  | {
      readonly kind: LogActionKind.TRY_GET;
      readonly search_params: SearchParams & {
        type?: ActivityType;
        userId?: string;
      };
    }
  | {
      readonly kind: LogActionKind.TRY_GET_MORE_ACTIVITIES;
    }
  | {
      readonly kind: LogActionKind.TRY_SET_ACTIVITY_FILTER;
      readonly activity_filter: {
        type?: ActivityType;
        userId?: string;
      };
    }
  | {
      readonly kind: LogActionKind.TRY_GET_NOTE_LOG;
      readonly noteId: string;
      readonly search_params: SearchParams;
    }
  | {
      readonly kind: LogActionKind.TRY_GET_DOC_LOG;
      readonly noteId: string;
      readonly search_params: SearchParams;
    }
  | {
      readonly kind: LogActionKind.TRY_CREATE_VIEW_LOG;
      readonly log: Omit<
        UserLogObjectCore,
        "url" | "body" | "query" | "ip_addr"
      >;
    };

export type LogError = never;

const smid = "LOG_STATE_MACHINE3";
export type LogStateMachineType = StateMachine3<
  LogStateStatus,
  LogState,
  LogActionKind,
  LogAction,
  LogError
>;
export const logStateMachine: LogStateMachineType = new StateMachine3<
  LogStateStatus,
  LogState,
  LogActionKind,
  LogAction,
  LogError
>(
  smid,
  {
    status: LogStateStatus.INIT,
    logs: [],
    note_logs: [],
    doc_logs: [],
    activity_filter: {},
    activity_period_filter: {},
  },
  [
    transition(
      LogStateStatus.INIT,
      LogStateStatus.INIT,
      LogActionKind.TRY_CREATE
    ),
    transition(LogStateStatus.INIT, LogStateStatus.INIT, LogActionKind.TRY_GET),
    transition(
      LogStateStatus.INIT,
      LogStateStatus.INIT,
      LogActionKind.TRY_GET_MORE_ACTIVITIES
    ),
    transition(
      LogStateStatus.INIT,
      LogStateStatus.INIT,
      LogActionKind.TRY_SET_ACTIVITY_FILTER
    ),
    transition(
      LogStateStatus.INIT,
      LogStateStatus.INIT,
      LogActionKind.TRY_GET_NOTE_LOG
    ),
    transition(
      LogStateStatus.INIT,
      LogStateStatus.INIT,
      LogActionKind.TRY_GET_DOC_LOG
    ),
    transition(
      LogStateStatus.INIT,
      LogStateStatus.INIT,
      LogActionKind.TRY_CREATE_VIEW_LOG
    ),
  ]
);

/* */
export type DispatchLogAction = Dispatch<
  StateMachineAction<
    LogStateStatus,
    LogState,
    LogActionKind,
    LogAction,
    LogError
  >
>;
export default mkReducer<
  LogStateStatus,
  LogState,
  LogActionKind,
  LogAction,
  LogError
>(logStateMachine);
export const doLogAction = (
  dispatch: DispatchLogAction,
  nextAction: LogAction,
  onResolve: () => void = () => {},
  onReject: (err: LogError | InternalError) => void = () => {}
) => {
  dispatch(logStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doLogActionAsync = (
  dispatch: DispatchLogAction,
  nextAction: LogAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(logStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetLog = (dispatch: DispatchLogAction) => {
  dispatch(logStateMachine.newResetAction());
};
