import moment from "moment";
import Flex from "@src/components/pure/utils/Flex";
import styles from "./styles/CardItem";
import clsx from "clsx";
import translate from "@src/utils/translate";
import SpoqaHansTypography from "@SpoqaHansTypography";
import React from "react";
import { Avatar, Grid } from "@material-ui/core";
import { getFormat } from "@src/utils/time-format";
import OverFlowTextTooltip from "../utils/OverFlowTextTooltip";

export type CardItemProps = {
  windowWidth: number;
  thumb_url: string;
  file_name: string | undefined;
  uploader_name: string | undefined;
  uploader_profile: string | undefined;
  updated_at: string | undefined;
  onCardClick?: () => void;
  timeZone?: string;
};

type Props = {
  hoverbutton?: JSX.Element;
  cardInfoWithIcon?: JSX.Element;
  selectModeView?: JSX.Element;
  hover?: boolean;
  setHover?: (hover: boolean) => void;
} & CardItemProps;

export const CardItem: React.FC<Props> = ({ ...props }) => {
  const {
    windowWidth,
    thumb_url,
    file_name,
    uploader_name,
    uploader_profile,
    updated_at,
    hover,
    setHover,
    timeZone,
  } = props;
  const classes = styles();

  const ThumbnailView = () => {
    return (
      <Flex item flex="1 1 0" className={classes.image_content}>
        <img src={thumb_url} alt="thumbnail" />
      </Flex>
    );
  };

  const DataContentView = () => {
    return (
      <Flex item className={classes.data_content}>
        <OverFlowTextTooltip
          title={`${file_name}`}
          arrow
          placement="bottom"
          viewOverflow={true}
        >
          <SpoqaHansTypography noWrap className={classes.file_name}>
            {file_name}
          </SpoqaHansTypography>
        </OverFlowTextTooltip>
        <SpoqaHansTypography className={classes.file_date}>
          {translate.folder_item.new_updated(
            moment(updated_at).format(getFormat(timeZone, undefined, true))
          )}
        </SpoqaHansTypography>
      </Flex>
    );
  };

  const UploaderProfileInfo = () => {
    return (
      <Grid container item className={classes.profile_content}>
        <Avatar src={uploader_profile} className={classes.profile_avatar} />
        <SpoqaHansTypography
          noWrap
          fontWeight="bold"
          className={classes.profile_name}
        >
          {uploader_name}
        </SpoqaHansTypography>
      </Grid>
    );
  };

  const CardInfoView = () => {
    return (
      <Flex
        item
        container
        alignItems="center"
        justifyContent="flex-end"
        className={classes.info_content}
      >
        {UploaderProfileInfo()}
        {props.cardInfoWithIcon}
      </Flex>
    );
  };

  const cardClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onCardClick === undefined) return;
    props.onCardClick();
  };

  return (
    <Grid item xs={windowWidth > 1279 ? 3 : windowWidth > 767 ? 4 : 6}>
      <Grid
        onClick={cardClickHandler}
        onMouseEnter={setHover ? () => setHover(true) : undefined}
        onMouseOver={setHover ? () => setHover(true) : undefined}
        onMouseLeave={setHover ? () => setHover(false) : undefined}
        className={clsx(classes.container, hover && classes.hover_style)}
      >
        <Flex container flexDirection="column" className={classes.content}>
          {props.hoverbutton}
          {ThumbnailView()}
          {DataContentView()}
          {CardInfoView()}
          {props.selectModeView}
        </Flex>
      </Grid>
    </Grid>
  );
};

export default CardItem;
