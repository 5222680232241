/* eslint-disable @typescript-eslint/no-explicit-any */

export const LOG_LEVEL_MAP = {
  ALL: -99,
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
  DISABLE: 99,
};
export type LOG_LEVEL_NAME = keyof typeof LOG_LEVEL_MAP;

export const ALL: LOG_LEVEL_NAME = "ALL";
export const DISABLE: LOG_LEVEL_NAME = "DISABLE";
export const DEBUG: LOG_LEVEL_NAME = "DEBUG";
export const INFO: LOG_LEVEL_NAME = "INFO";
export const WARN: LOG_LEVEL_NAME = "WARN";
export const ERROR: LOG_LEVEL_NAME = "ERROR";
type PrintFunction = (message?: any, ...optionalParams: any[]) => void;
type CustomPrintFunction = (
  when: Date,
  type: string,
  level: LOG_LEVEL_NAME,
  message?: any,
  ...optionalParams: any[]
) => void;

const levelMap: { [index: string]: keyof typeof LOG_LEVEL_MAP | undefined } =
  {};
const customPrintMap: { [index: string]: CustomPrintFunction | undefined } = {};

export const defaultLogPrint = (
  when: Date,
  type: string,
  level: LOG_LEVEL_NAME,
  message?: any,
  ...optionalParams: any[]
) => {
  if (LOG_LEVEL_MAP[level] >= LOG_LEVEL_MAP["ERROR"]) {
    console.error(
      `${when.toISOString()}\t[${level}] ${type}:`,
      message,
      ...optionalParams
    );
  } else if (LOG_LEVEL_MAP[level] >= LOG_LEVEL_MAP["WARN"]) {
    console.warn(
      `${when.toISOString()}\t[${level}] ${type}:`,
      message,
      ...optionalParams
    );
  } else if (LOG_LEVEL_MAP[level] >= LOG_LEVEL_MAP["INFO"]) {
    console.info(
      `${when.toISOString()}\t[${level}] ${type}:`,
      message,
      ...optionalParams
    );
  } else if (LOG_LEVEL_MAP[level] >= LOG_LEVEL_MAP["DEBUG"]) {
    console.debug(
      `${when.toISOString()}\t[${level}] ${type}:`,
      message,
      ...optionalParams
    );
  }
};

let customPrintForAll: CustomPrintFunction = defaultLogPrint;

export const configLogLevel = (map: {
  [index: string]: keyof typeof LOG_LEVEL_MAP;
}) => {
  for (const key of Object.keys(map)) {
    levelMap[key] = map[key];
  }
};

export const setCustomPrint = (f: CustomPrintFunction, type?: string) => {
  if (type === undefined) {
    customPrintForAll = f;
  } else {
    customPrintMap[type] = f;
  }
};

export const getCustomPrint = (type?: string) => {
  if (type === undefined) {
    return customPrintForAll;
  } else {
    return customPrintMap[type] || customPrintForAll;
  }
};

let DEFAULT_LOG_LEVEL: LOG_LEVEL_NAME = "INFO";
export const configDefaultLogLevel = (level: LOG_LEVEL_NAME) => {
  DEFAULT_LOG_LEVEL = level;
};
interface GetLoggerParams {
  type?: string;
  level?: LOG_LEVEL_NAME;
}

const getLogger = (params: GetLoggerParams = {}): PrintFunction => {
  const type = params.type !== undefined ? params.type : "Default";
  const level = params.level ? params.level : DEFAULT_LOG_LEVEL;

  const printConsole = (message?: any, ...optionalParams: any[]) => {
    const filter = levelMap[type] || DEFAULT_LOG_LEVEL;

    if (LOG_LEVEL_MAP[level] >= LOG_LEVEL_MAP[filter]) {
      getCustomPrint(type)(new Date(), type, level, message, ...optionalParams);
    }
  };

  return printConsole;
};

export const getLoggers = (
  type = "Default"
): {
  DEBUG: PrintFunction;
  INFO: PrintFunction;
  ERROR: PrintFunction;
  WARN: PrintFunction;
} => {
  return {
    DEBUG: getLogger({ type, level: "DEBUG" }),
    INFO: getLogger({ type, level: "INFO" }),
    ERROR: getLogger({ type, level: "ERROR" }),
    WARN: getLogger({ type, level: "WARN" }),
  };
};

/* eslint-enable @typescript-eslint/no-explicit-any */
