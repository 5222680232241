import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";

type KCCTypographyProps = {
  style: object;
  className: string | undefined;
  id: string | undefined;
  noWrap: boolean;
} & TypographyProps;

type KCCTypographyState = {};

class KCCTypography extends React.PureComponent<
  KCCTypographyProps,
  KCCTypographyState
> {
  static defaultProps = {
    style: {},
    fontWeight: "regular",
    id: undefined,
    className: undefined,
    noWrap: false,
  };
  constructor(props: KCCTypographyProps) {
    super(props);
  }

  render() {
    const { style, className, id, noWrap } = this.props;
    return (
      <Typography
        {...this.props}
        noWrap={noWrap}
        id={id}
        style={{ fontFamily: "KCC", ...style }}
        className={className}
      >
        {this.props.children}
      </Typography>
    );
  }
}

export default KCCTypography;
