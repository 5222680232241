export default {
  ko: {
    sign_up_verified: {
      subheader: "이메일 인증에 성공했습니다!",
      header: "이메일로 회원가입",
      placeholder_pw: "비밀번호를 입력하세요",
      placeholder_name: "이름을 입력해주세요",
      finish: "회원가입 완료하기",
      hint: "계정정보를 모두 입력해야 회원가입이 완료됩니다.",
      terms: {
        agree_all: "전체 약관에 동의합니다.",
        agree_required: "에 동의합니다. (필수)",
        agree_choose: "에 동의합니다. (선택)",
        term_1: "서비스 ",
        term_1_link: "이용약관",
        term_2_link: "개인정보 수집 및 이용 약관",
        term_3_link: "개인정보 제 3자 제공",
        term_4_link: "마케팅 정보 수신 및 활용",
      },
    },
  },
  en: {
    sign_up_verified: {
      header: "Sign up with Email",
      subheader: "Your Email address has been successfully verified!",
      placeholder_pw: "Enter your password",
      placeholder_name: "Enter your name",
      finish: "Sign up",
      hint: "You must enter all account information to complete register",
      terms: {
        agree_all: "I agree to the full terms and conditions.",
        agree_required: "(required)",
        agree_choose: "(optional)",
        term_1: "",
        term_1_link: "Terms and Conditions",
        term_2_link: "Privacy Policy",
        term_3_link: "Disclose personal information to third party ",
        term_4_link:
          "Disclose personal information for promotion and marketing",
      },
    },
  },
};
