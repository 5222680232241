import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";

export interface AuthObjectCore {
  readonly id: string;
  // readonly hash: string;
  readonly blockNum: string;
  readonly txId: string; // Relation
  // readonly certifier?: string;
  // readonly certifying?: string;
  readonly timestamp: string;
  readonly UserId: string; // Relation
  readonly NoteId: string; // Relation
}
/**
 *   static schema_definition = {
    id: {
      type: DataTypes.STRING,
      primaryKey: true
    },
    hash: { type: DataTypes.STRING, allowNull: false },
    blockNum: { type: DataTypes.STRING, allowNull: true },
    txId: { type: DataTypes.STRING, allowNull: true },
    certifier: { type: DataTypes.STRING, allowNull: true },
    certifying: { type: DataTypes.STRING, allowNull: true },
    timestamp: { type: DataTypes.STRING, allowNull: false }
  }
 */
export type AuthObject = AuthObjectCore & AccessTime;

export const AuthObjectSchema = withAccessTime()
  .addField("id", T.string())
  // .addField( "hash", T.string() )
  .addField("blockNum", T.string())
  .addField("txId", T.string())
  // .addField( "certifier", T.string(), false )
  // .addField( "certifying", T.string(), false )
  .addField("timestamp", T.string())
  .addField("UserId", T.string())
  .addField("NoteId", T.string());

export const extractAuthObject =
  T.mkObjectExtractor<AuthObject>(AuthObjectSchema);
