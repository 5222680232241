export default {
  ko: {
    requirements: {
      require_email: "이메일 정보를 요구합니다",
      require_email_info: "이메일 제출 사용자만 열람 가능합니다",
      require_sign: "NDA 파일에 서명을 요구합니다",
      require_sign_info: "NDA 파일 서명 제출한 사용자만 열람 가능합니다",
      not_require_email: "이메일 정보를 요구하지 않습니다",
      not_require_sign: "NDA 파일 서명을 요구하지 않습니다",
    },
    permissions: {
      allow_download: "다운로드를 허용합니다",
      allow_download_info: "사용자가 해당 파일을 다운받을 수 있습니다",
      allow_print: "프린트를 허용합니다",
      allow_print_info: "사용자가 해당 파일을 프린트 할 수 있습니다",
      not_allow_download: "다운로드를 허용하지 않습니다",
      not_allow_print: "프린트를 허용하지 않습니다",
    },
    copy_link_success: "링크가 복사됐습니다",
    download_note: "다운받기",
    blockchain_info: "블록체인 인증 정보",
    create_link: "링크 생성하기",
    preview: "미리보기",
    links: "링크 요약",
    comments: {
      create_comment: "코멘트 작성하기",
      no_comments: "코멘트가 없습니다",
      curr_page_comments: "현재 페이지 코멘트 보기",
      all_page_comments: "모든 페이지 코멘트 보기",
      all_pages: "모든 페이지",
      page: "페이지",
      leave_comment: "남기기",
      premium_only: "프리미엄 유저에게만 제공되는 기능입니다.",
      buy_license: "라이센스 구매하기",
    },
    reset: "새로고침",
    block_no: "블록 넘버",
    hash: "해시",
    timestamp: "타임스탬프",
    creation_date: "생성 날짜",
    auth_date: "인증 날짜",
    history: "기록",
    authentication: "블록체인 인증 진행 중...",
    overview: "개요",
    unknown: "알 수 없는",
    links_tab: {
      user: "방문자님",
      average_time: "평균 체류 시간",
      total_duration: "총 체류 시간",
      view_time: "체류 시간",
      visitor_num: "총 방문자 수",
      all_visitors: "전체",
      time_per_page: "페이지별 체류 시간",

      access_info: "접속자 정보",
      info: "정보",
      hour: "시간",
      min: "분",
      sec: "초",
      visitor: "방문자",
      visitors: "방문자",
      created_by: "작성자",
      most_viewed: "가장 많이 본 페이지",
      most_viewed_list: ["가장", "많이 본", "페이지"],
      least_viewed: "가장 적게 본 페이지",
      least_viewed_list: ["가장", "적게 본", "페이지"],
      most_visited_link: "가장 많이 방문한 링크",
      page_ko: "페이지",
      page_en: "",
      graph: "그래프",
      overview: "링크 전체 요약",
      download_report: "전체 리포트 다운받기",
      download_report_link: "링크 리포트 다운받기",
      all_downloads: "총 다운로드 수",
      no_links: "링크가 없습니다",
      no_data: "아직 데이터가 없습니다",
      download_history: "현재 히스토리 다운로드 하기",
      download_log: "현재 로그 다운로드 하기",
      download_log_xs: "로그 다운로드",
      download_log_xxs: "다운로드",
      download_log_loading: "Loading...",
      download_log_error: "다운로드 사용 불가",
      device: "접속 기기",
      region: "접속 국가",
      visits: "방문횟수",
      downloads: "다운로드 수",
    },
    history_tab: {
      download: "블록체인 인증서 다운로드",
      download_in_short: "인증서 다운로드",
      file_info: "파일 정보",
      name: "이름",
      uploader: "업로더",
      tag: "태그",
    },

    file_links: "파일 링크",
    file_history: "파일 기록",

    report: {
      date: "추출 일자",
      summary: "요약",
      page: "페이지",
    },
  },

  en: {
    requirements: {
      require_email: "This requires e-mail address",
      require_email_info: "Only available for members who submitted e-mail",
      require_sign: "This requires a signature for NDA files",
      require_sign_info:
        "Only available for members who submitted signature for NDA files",
      not_require_email: "This does not require e-mail address",
      not_require_sign: "This does not require an NDA file signature",
    },

    permissions: {
      allow_download: "Allow downloading",
      allow_download_info: "The user can download the files",
      allow_print: "Allow printing",
      allow_print_info: "The owner can print the file",
      not_allow_print: "Do not allow printing",
      not_allow_download: "Do not allow downloading",
    },
    copy_link_success: "The link has been copied",
    download_note: "Download",
    blockchain_info: "Blockchain certification information",
    create_link: "Create link",
    preview: "Preview",
    links: "Links",

    comments: {
      create_comment: "Write a comment",
      no_comments: "No comments yet",
      curr_page_comments: "View comments (current page)",
      all_page_comments: "View comments (for all pages)",
      all_pages: "All Pages",
      page: "page",
      leave_comment: "Done",
      premium_only: "This functionality is provided only to premium users.",
      buy_license: "Buy a license",
    },
    reset: "Reset",
    block_no: "Block Number",
    hash: "Hash",
    timestamp: "Timestamp",
    creation_date: "Created",
    auth_date: "Auth date",
    history: "History",
    authentication: "Blockchain authentication in progress...",
    overview: "Overview",
    unknown: "unkown",
    links_tab: {
      user: "Your",
      average_time: "Average viewed",
      total_duration: "Total Duration",
      view_time: "View Time",
      visitor_num: "Total number of visitors",
      all_visitors: "All",
      time_per_page: "View time by page",
      access_info: "Access Information",
      info: "Information",
      hour: "hour",
      min: "m",
      sec: "s",
      visitor: "Visitor",
      visitors: "VISITORS",
      created_by: "Created by",
      most_viewed: "Most viewed",
      most_viewed_list: ["The", "most", "viewed"],
      least_viewed_list: ["The", "least", "viewd"],
      least_viewed: "Least viewed",
      most_visited_link: "Most visited link",
      page_ko: "",
      page_en: "Page",
      graph: "Graph",
      overview: "Links Overview",
      download_report: "Download full report",
      download_report_link: "Download link report",
      all_downloads: "Total number of downloads",
      download_history: "Download current history",
      download_log: "Download current log",
      download_log_xs: "Download",
      download_log_xxs: "Download",
      download_log_loading: "Loading...",
      download_log_error: "Download Unavailable",
      no_links: "No Links",
      no_data: "No data",
      device: "Device",
      region: "Country",
      visits: "Visits",
      downloads: "Downloads",
    },

    history_tab: {
      download: "Download Blockchain Certificate",
      download_in_short: "Download",
      file_info: "File information",
      name: "Name",
      uploader: "Uploader",
      tag: "Tag",
    },

    file_links: "File links",
    file_history: "File history",

    report: {
      date: "Download Date",
      summary: "Summary",
      page: "Page",
    },
  },
};
