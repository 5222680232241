import { doAPIDeleteRequest, doAPIGetRequest, doAPIPostRequest } from "./api";
import {
  ICreateContent,
  IUpdateContent,
} from "@basalt-commons/api/request/content";

import {
  validateOCreateContent,
  validateOGetAllContentTitle,
  validateOGetContentBody,
  validateOGetPublishContentTitle,
  validateODeleteContent,
  validateOUpdateContent,
} from "@basalt-commons/api/response/content";

const getPublishContentHeaders = async () => {
  const res = await doAPIGetRequest(`contents/titles/publish`);
  const headers = validateOGetPublishContentTitle(res).response.results;
  return headers;
};

const getAllContentHeaders = async (token: string) => {
  const res = await doAPIGetRequest(`contents/titles`, token);
  const headers = validateOGetAllContentTitle(res).response.results;
  return headers;
};

const getContentBody = async (contentId: string) => {
  const res = await doAPIGetRequest(`contents/body/${contentId}`);
  const content = validateOGetContentBody(res).response;
  return content;
};

const createContent = async (token: string, args: ICreateContent) => {
  const res = await doAPIPostRequest(`contents`, token, args);
  const id = validateOCreateContent(res).response;
  return id;
};

const updateContent = async (token: string, args: IUpdateContent) => {
  const res = await doAPIPostRequest("contents/update", token, args);
  const content = validateOUpdateContent(res).response;
  return content;
};

const updateViewContent = async (contentId: string) => {
  const res = await doAPIPostRequest(`contents/view/${contentId}`);
  const id = validateODeleteContent(res).response;
  return id;
};

const deleteContent = async (token: string, contentId: string) => {
  const res = await doAPIDeleteRequest(`contents/${contentId}`, token);
  const id = validateODeleteContent(res).response;
  return id;
};

export default {
  getPublishContentHeaders,
  getAllContentHeaders,
  getContentBody,
  createContent,
  updateContent,
  updateViewContent,
  deleteContent,
};
