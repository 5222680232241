export default {
  ko: {
    stamp_create: {
      create_stamp: "회사 서명 만들기",
      create_stamp_placeholder: "서명 이름을 50자 이내로 작성해주세요",
      create_stamp_success: "만들기",
      update_stamp: "회사 서명 수정하기",
      update_stamp_success: "회사 서명 수정 완료",
      sign_name: "서명 이름",
      sign_description: "서명에 대한 설명",
      tooltip: {
        question: "서명을 등록하면 어디에 쓰나요?",
        titie:
          "등록한 서명을 가지고 NDA에 서명을 하거나 워크스페이스에 초대 받아 워크스페이스 설정에 따라 사인을 하게 되는 경우 사용하실 수 있습니다. 해당 서명은 baSalt 약관에 따라 법적 효력을 가지며, 본인의 서명임에 동의합니다.",
      },
      legally_valid: "해당 서명은 baSalt 약관에 따라 법적 효력을 가집니다.",
      agree: "본인의 서명임에 동의합니다",
      describe_stamp_placeholder: "서명에 대한 설명을 작성해주세요",
    },
  },

  en: {
    stamp_create: {
      create_stamp: "Create company signature",
      create_stamp_placeholder: "Enter the signature name within 50 characters",
      create_stamp_success: "Create",
      update_stamp: "Edit company signature",
      update_stamp_success: "Save Changes",
      sign_name: "Signature Name",
      sign_description: "Description of the Signature",
      tooltip: {
        question: "When I register my signature, where is it used for?",
        titie:
          "You can sign the NDA with the registered signature. After being invited to a workspace, you may be required to input your signature to use it. The signature has legal effect in accordance with the terms and conditions of baSalt.",
      },
      legally_valid:
        "This signature shall be legally valid under the Terms and Conditions of baSalt.",
      agree: "I agree that this is my signature",
      describe_stamp_placeholder:
        "Please write an explanation about the signature",
    },
  },
};
