import T from "@redwit-commons/utils/typecheck";
import {
  mkResponseSchema,
  GoonoResponse,
  GoonoArrayResponse,
  mkArrayResultsSchema,
} from "../../api/response/common";
import {
  PayplePaymentObject,
  PayplePaymentObjectSchema,
} from "../object/payments";
import {
  WorkspaceWithPlan,
  WorkspaceWithPlanSchema,
} from "../object/workspace";
/**
 * 500 에러: PURCHASE_FAIL
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum PaymentErrorType {
  NOT_FOUND_WORKSPACE = "PaymentError::NOT_FOUND_WORKSPACE",
  NOT_FOUND_PLAN = "PaymentError::NOT_FOUND_PLAN",
  NOT_FOUND_CARD = "PaymentError::NOT_FOUND_CARD",
  INVALID_AMOUNT = "PaymentError::INVALID_AMOUNT",
  INVALID_CREDIT = "PaymentError::INVALID_CREDIT",
  // 요청한 크레딧이 워크스페이스 크레딧 보다 큼
  OVER_CREDIT = "PaymentError::OVER_CREDIT",
  EXPIRED_PLAN = "PaymentError::EXPIRED_PLAN",
  // 추가 결제 및 자동 결제를 위한 기본 카드가 필요함
  NEED_DEFAULT_CARD_FOR_PURCHASE = "PaymentError::NEED_DEFAULT_CARD_FOR_PURCHASE",
  // 서버측에서 결제 실패
  PURCHASE_FAIL = "PaymentError::PURCHASE_FAIL",
  DUPLICATE_PAYMENT = "PaymentError::DUPLICATE_PAYMENT",
  /**
   * 500 에러
   * ENTERPRISE 책정 가격이 비어있음
   * 해당 오류 발생 시, 관리자에게 전달해서 가격을 부여해야함
   */
  INVALID_ORIGIN_PRICE = "PaymentError::INVALID_ORIGIN_PRICE",
  NOT_SUPPORT = "PaymentError::NOT_SUPPORT",
}

export type OGetWorkspacePayment = GoonoArrayResponse<PayplePaymentObject>;
export const OGetWorkspacePaymentSchema = mkArrayResultsSchema(
  PayplePaymentObjectSchema
);
export const validateOGetWorkspacePayment = T.mkValidator<OGetWorkspacePayment>(
  OGetWorkspacePaymentSchema
);
export type OGlobalPayPleAuth = {
  result: string;
  message: string;
  code: string;
  access_token: string;
  token_type: string;
  expires_in: string;
};
export const OGlobalPayPleAuthSchema = T.object()
  .addField("result", T.string())
  .addField("message", T.string())
  .addField("code", T.string())
  .addField("access_token", T.string())
  .addField("token_type", T.string())
  .addField("expires_in", T.string());

export const validateOGlobalPayPleAuth = T.mkValidator<OGlobalPayPleAuth>(
  OGlobalPayPleAuthSchema
);
type DomesticPartnerInfo = {
  cst_id: string;
  custKey: string;
};
export type ODomesticPayPleAuthWOPartnerInfo = {
  server_name: string;
  result: string;
  result_msg: string;
  AuthKey: string;
  PCD_PAY_HOST: string;
  PCD_PAY_URL: string;
  return_url: string;
};
export type ODomesticPayPleAuth = DomesticPartnerInfo &
  ODomesticPayPleAuthWOPartnerInfo;

const addDomesticPartnerInfo = (from: T): T => {
  return from.addField("cst_id", T.string()).addField("custKey", T.string());
};
const ODomesticPayPleAuthWOPartnerInfoSchema = T.object()
  .addField("server_name", T.string())
  .addField("result", T.string())
  .addField("result_msg", T.string())
  .addField("AuthKey", T.string())
  .addField("PCD_PAY_HOST", T.string())
  .addField("PCD_PAY_URL", T.string())
  .addField("return_url", T.string());

export const ODomesticPayPleAuthSchema = addDomesticPartnerInfo(
  ODomesticPayPleAuthWOPartnerInfoSchema.clone()
);
export const validateODomesticPayPleAuth = T.mkValidator<ODomesticPayPleAuth>(
  ODomesticPayPleAuthSchema
);

export type OGetGlobalPaypleAccessToken = GoonoResponse<OGlobalPayPleAuth>;
export const OGetGlobalPaypleAccessTokenSchema = mkResponseSchema(
  OGlobalPayPleAuthSchema
);
export const validateOGetGlobalPaypleAccessToken =
  T.mkValidator<OGetGlobalPaypleAccessToken>(OGetGlobalPaypleAccessTokenSchema);

export type OGetDomesticPaypleAccessToken =
  GoonoResponse<ODomesticPayPleAuthWOPartnerInfo>;
export const OGetDomesticPaypleAccessTokenSchema = mkResponseSchema(
  ODomesticPayPleAuthWOPartnerInfoSchema
);
export const validateOGetDomesticPaypleAccessToken =
  T.mkValidator<OGetDomesticPaypleAccessToken>(
    OGetDomesticPaypleAccessTokenSchema
  );

export type OPaypleDomesticPayment = GoonoResponse<WorkspaceWithPlan>;
export const OPaypleDomesticPaymentSchema = mkResponseSchema(
  WorkspaceWithPlanSchema
);
export const validateOPaypleDomesticPayment =
  T.mkValidator<OPaypleDomesticPayment>(OPaypleDomesticPaymentSchema);

export type ODowngradePlan = GoonoResponse<WorkspaceWithPlan>;
export const ODowngradePlanSchema = mkResponseSchema(WorkspaceWithPlanSchema);
export const validateODowngradePlan =
  T.mkValidator<ODowngradePlan>(ODowngradePlanSchema);

export type OPaymentCredit = GoonoResponse<WorkspaceWithPlan>;
export const OPaymentCreditSchema = mkResponseSchema(WorkspaceWithPlanSchema);
export const validateOPaymentCredit =
  T.mkValidator<OPaymentCredit>(OPaymentCreditSchema);
