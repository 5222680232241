import { gtmApi } from "../gtm/gtm-api";
import { mixPanelApi } from "../mixpanel/mixpanel-api";

export interface CreateLinkParam {
  email: boolean;
  download: boolean;
  print: boolean;
  nda: boolean;
}

export const useCreateLinkEffect = async (
  param: CreateLinkParam
): Promise<void> => {
  await gtmApi.linkCreate(param);
  await mixPanelApi.linkCreate(param);
};
