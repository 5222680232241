import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { AlarmObject } from "@basalt-commons/global-api/object/alarm";

export enum AlarmStateStatus {
  INIT = "AlarmStateStatus::INIT",
  SUCCESS = "AlarmStateStatus::SUCCESS",
}

export enum AlarmActionKind {
  TRY_GET_ALARM = "AlarmAction::TRY_GET_ALARM",
  TRY_CHECK_ALARM = "AlarmAction::TRY_CHECK_ALARM",
  TRY_DELETE_ALARM = "AlarmAction::TRY_DELETE_ALARM",
}

export type AlarmState =
  | {
      readonly status: AlarmStateStatus.INIT;
    }
  | {
      readonly status: AlarmStateStatus.SUCCESS;
      readonly workspaceId: string;
      readonly alarms: AlarmObject[];
    };

export type AlarmAction =
  | {
      readonly kind: AlarmActionKind.TRY_GET_ALARM;
      readonly workspaceName: string;
    }
  | {
      readonly kind: AlarmActionKind.TRY_CHECK_ALARM;
    }
  | {
      readonly kind: AlarmActionKind.TRY_DELETE_ALARM;
      readonly alarmId: string;
    };

export type AlarmError = never;

const smid = "ALARM_STATE_MACHINE3";
export type AlarmStateMachineType = StateMachine3<
  AlarmStateStatus,
  AlarmState,
  AlarmActionKind,
  AlarmAction,
  AlarmError
>;
export const alarmStateMachine: StateMachine3<
  AlarmStateStatus,
  AlarmState,
  AlarmActionKind,
  AlarmAction,
  AlarmError
> = new StateMachine3<
  AlarmStateStatus,
  AlarmState,
  AlarmActionKind,
  AlarmAction,
  AlarmError
>(smid, { status: AlarmStateStatus.INIT }, [
  transition(
    AlarmStateStatus.INIT,
    AlarmStateStatus.SUCCESS,
    AlarmActionKind.TRY_GET_ALARM
  ),
  transition(
    AlarmStateStatus.SUCCESS,
    AlarmStateStatus.SUCCESS,
    AlarmActionKind.TRY_GET_ALARM
  ),
  transition(
    AlarmStateStatus.SUCCESS,
    AlarmStateStatus.SUCCESS,
    AlarmActionKind.TRY_CHECK_ALARM
  ),
  transition(
    AlarmStateStatus.SUCCESS,
    AlarmStateStatus.SUCCESS,
    AlarmActionKind.TRY_DELETE_ALARM
  ),
]);

export type DispatchAlarmAction = Dispatch<
  StateMachineAction<
    AlarmStateStatus,
    AlarmState,
    AlarmActionKind,
    AlarmAction,
    AlarmError
  >
>;
export default mkReducer<
  AlarmStateStatus,
  AlarmState,
  AlarmActionKind,
  AlarmAction,
  AlarmError
>(alarmStateMachine);
export const doAlarmAction = (
  dispatch: DispatchAlarmAction,
  nextAction: AlarmAction,
  onResolve: () => void = () => {},
  onReject: (err: AlarmError | InternalError) => void = () => {}
) => {
  dispatch(alarmStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doAlarmActionAsync = (
  dispatch: DispatchAlarmAction,
  nextAction: AlarmAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(alarmStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetAlarm = (dispatch: DispatchAlarmAction) => {
  dispatch(alarmStateMachine.newResetAction());
};
