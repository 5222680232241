let WEB_ENDPOINT = "https://www.basalt.so/";
if (typeof process.env.REACT_APP_TEST_ENABLED === "string") {
  if (
    process.env.REACT_APP_TEST_ENABLED === "1" ||
    process.env.REACT_APP_TEST_ENABLED === "y" ||
    process.env.REACT_APP_TEST_ENABLED === "Y"
  ) {
    WEB_ENDPOINT = "https://test.basalt.so/";
  }
}

export default {
  WEB_ENDPOINT,
};
