import T from "@redwit-commons/utils/typecheck";

import { mkResponseSchema, GoonoResponse, GoonoPendingTasks } from "./common";

export type OInitNotification = GoonoResponse<string> &
  GoonoPendingTasks<{ notif_init: string }>;

export const OInitNotificationSchema = mkResponseSchema(
  T.string(),
  T.object().addField("notif_init", T.string())
);

export const ODeleteNotificationSchema = mkResponseSchema(T.string());

export type ODeviceCheck = GoonoResponse<string>;

export const ODeviceCheckSchema = mkResponseSchema(T.string());

export const validateOInitNotification = T.mkValidator<OInitNotification>(
  OInitNotificationSchema
);

export type PushNotificationResults = {
  success_count: number;
  failed_count: number;
  success_user_ids: string[];
  failed_user_ids: string[];
};
export const PushNotificationResultsSchema = T.object()
  .addField("success_count", T.number())
  .addField("failed_count", T.number())
  .addField("success_user_ids", T.array(T.string()))
  .addField("failed_user_ids", T.array(T.string()));

export type OPushNotification = GoonoResponse<PushNotificationResults>;
export const OPushNotificationSchema = mkResponseSchema(
  PushNotificationResultsSchema
);
export const validateOPushNotification = T.mkValidator<OPushNotification>(
  OPushNotificationSchema
);
