export default {
  ko: {
    nda_preview: {
      go_back: "NDA 문서로 돌아가기",
      go_back_settings: "워크스페이스  설정으로 돌아가기",
      contract_download: "전체 서명 다운",
      download: "다운로드",
      upload: "NDA 파일 업로드",
    },
    nda_tab_header: {
      file: "파일",
    },
    nda_create: {
      choose_location: "원하는 위치에 클릭해주세요",
      cancel: "취소",
      sign_info: "원하는 위치에 클릭해주세요",
      custom_explanation:
        "버튼을 클릭해서 서명 정보 입력할 곳을 선택할 수 있습니다. ",
      custom_toggle: "기본 템플릿으로 진행하기",
      view_templates: "템플릿 보기",
      required_info: "필수 정보",
      opt_info: "선택 정보",
      requester_name: "요청자 이름",
      requester_sign: "요청자 서명",
      request_time: "요청 시간",
      signer_name: "서명자 이름",
      signer_sign: "서명자 서명",
      signature_time: "서명 시간",
      requester_inst: "요청자 소속",
      signer_inst: "서명자 소속",
      requester_sign_text: "* 요청자의 서명이\n들어갈 자리입니다",
      signer_sign_text: "* 서명자의 서명이\n들어갈 자리입니다",
      preview: {
        title1: "해당 화면은 미리보기 화면입니다",
        title2: "실제와 약간의 오차가 있을 수 있습니다",
        requester: {
          name: "요청자 이름",
          inst: "요청자 소속",
          sign_time: "요청자 서명 시간",
          sign: {
            text1: "요청자의",
            text2: "서명 이미지",
          },
        },
        signer: {
          name: "서명자 이름",
          inst: "서명자 소속",
          sign_time: "서명자 서명 시간",
          sign: {
            text1: "서명자의",
            text2: "서명 이미지",
          },
        },
      },
      description: {
        title: "파일 설명",
        sub_title: "업로드 하려는 NDA 파일 설명을 적어 관리해보세요",
        placeholder: "파일 설명은 최대 100글자 이내로 입력 하실 수 있습니다",
      },
    },
    sign: {
      text: "워크스페이스 내부에서 사용할 회사 서명 파일을 업로드해보세요!",
      use_name: "내 이름으로 서명하기",
    },
    ndas: {
      tab_titles: {
        nda: "NDA 관리",
        stamp: "회사 서명",
        signed: "체결된 NDA",
      },
      created_en: "",
      created_ko: "생성되었습니다",
      update: "NDA 파일 업로드",
      sign_info: "서명 내역",
      connected_link: "연결된 링크",
      preview: "프리뷰 보기",
      default_pre: "",
      default_post: "개의 폴더에 기본 NDA로 설정되어 있습니다",
      description: "NDA파일 설명",
      search_placeholder: "NDA 파일명으로 검색해 주세요",
      drag_tip: "파일을 끌어다 놓으면 업로드 됩니다",
      or: "또는",
      upload_err: "하나의 파일만 업로드 할 수 있습니다.",
      download_err: "체결된 NDA가 없습니다",
      only_pdf: "pdf 파일을 업로드 해 주세요.",
      upload: "클릭하여 파일 업로드",
      image_format_err: "지원되지 않는 이미지 포맷입니다.",
    },

    nda_stamp_info: {
      nda_count: " NDA files",
      created: "생성",
      delete: "서명삭제",
      history: "서명 문서 확인",
      edit: "서명 수정",
    },

    company_signature: {
      title: "회사 서명",
      new: "새 서명 생성",
      search_placeholder: "서명 이름으로 검색해주세요",
      cancel: "취소",
      upload_err: "서명 업로드에 실패했습니다.",
      upload_success: "새 서명이 성공적으로 업로드 되었습니다",
      update_success: "서명이 업데이트 되었습니다",
      update_err: "서명이 업데이트에 실패했습니다",
    },

    all_signs: {
      title: "체결된 NDA 문서",
      download: "전체 다운로드",
      search_placeholder: "NDA 파일명으로 검색해 주세요",
      nda_filename: "NDA 파일",
      filename: "연결된 파일",
      sign_requester: "요청자(소속)",
      signer: "참여자(소속)",
      sign_date: "서명 날짜",
      no_sign: "서명 문서가 없습니다.",
    },

    nda_contract_item: {
      see_file: "파일 보기",
      email: "이메일 발송",
      download: "다운로드",
    },

    nda_item: {
      created: (text: string) => {
        return `${text} 생성되었습니다`;
      },
      nda_file: "NDA 파일",
      file_name: "NDA 파일 이름",
      sign_info: "서명 내역",
      connected_file: "연결된 파일",
      update: "업데이트",
      uploader: "업로더",
      preview: "NDA 보기",
      default_project: (count: number) => {
        return `${count} 개의 폴더에 기본 NDA로 설정되어 있습니다`;
      },
      add_description: "설명 추가",
      download_history: "서명 내역 다운로드",
      delete: "삭제하기",
      description_placeholder: "파일 설명을 입력하세요",
      save: "저장",
      no_sign_history: "서명 내역이 없습니다",
      link_name: "링크 이름",
      no_links: "링크가 없습니다",
      signed: "서명 횟수",
      file: "파일",
      sign_requester: "서명 요청자 (소속)",
      sign_participant: "서명 참여자 (소속)",
      sign_date: "서명 일자",
    },
    nda_setting: {
      nda: "NDA",
      on: "ON",
      off: "OFF",
    },
  },

  en: {
    nda_preview: {
      go_back: "Return to NDA documents",
      go_back_settings: "Return to Workspace Settings",
      contract_download: "Download full signature",
      download: "Download",
      upload: "Upload NDA",
    },
    nda_tab_header: {
      file: "File",
    },
    nda_create: {
      choose_location: "Please click on where you want",
      cancel: "Cancel",
      sign_info: "Please click on where you want",
      custom_explanation:
        "You can select where to enter your signature information by clicking the button",
      custom_toggle: "Proceed with default template",
      view_templates: "View templates",
      required_info: "Required Information",
      opt_info: "Optional Information",
      requester_name: "Requester Name",
      requester_sign: "Requester Signature",
      request_time: "Request Time",
      signer_name: "Signer Name",
      signer_sign: "Signer Signature",
      signature_time: "Signature Time",
      requester_inst: "Requester Institution",
      signer_inst: "Signer Institution",
      requester_sign_text: "* This is for requester signature.",
      signer_sign_text: "* This is for signer signature",
      preview: {
        title1: "This is a preview screen",
        title2: "There may be some errors from the actual",
        requester: {
          name: "Requester Name",
          inst: "Requester Institution",
          sign_time: "Requester's Signature Time",
          sign: {
            text1: "Requester's",
            text2: "Signature",
          },
        },
        signer: {
          name: "Signer Name",
          inst: "Signer Institution",
          sign_time: "Signer's Signature Time",
          sign: {
            text1: "Signer's",
            text2: "Signature",
          },
        },
      },
      description: {
        title: "File Description",
        sub_title: "Manage NDA file you want to upload by entering description",
        placeholder: "You can enter description within 100 letters",
      },
    },
    sign: {
      text: "Upload the company signature file that will be used in the workspace!",
      use_name: "Sign with my name",
    },

    nda_stamp_info: {
      nda_count: " NDA Files",
      created: "created",
      delete: "Delete",
      history: "Signature document",
      edit: "Edit signature",
    },

    ndas: {
      tab_titles: {
        nda: "NDA management",
        stamp: "Company Signature",
        signed: "Signed NDA",
      },
      update: "Upload NDA files",
      sign_info: "Signature History",
      connected_link: "Connected Link",
      preview: "See preview",
      default_pre: " folders",
      default_post: "Set as the default NDA in",
      description: "NDA File Description",
      created_en: "created",
      created_ko: "",
      search_placeholder: "Search for the NDA file name",
      drag_tip: "If you drag and drop the file, it will be uploaded",
      or: "or",
      upload: "Upload files",
      upload_err: "You can upload only 1 file",
      download_err: "There are no signed NDA documents",
      only_pdf: "You can only upload PDF file",
      image_format_err: "This image type is not supported.",
    },
    company_signature: {
      title: "Company Signature",
      new: "Create new signature",
      search_placeholder: "Search for the signature name",
      cancel: "Cancel",
      upload_err: "Failed to upload a company signature.",
      upload_success: "The new signature has been successfully uploaded",
      update_success: "The signature has been updated",
      update_err: "Failed to update the signature",
    },

    all_signs: {
      title: "All signed NDA documents",
      download: "Download all",
      search_placeholder: "Search for the NDA file name",
      nda_filename: "NDA File Name",
      filename: "Connected Files",
      sign_requester: "Signature Requester (Institution)",
      signer: "Signed by (Institution)",
      no_sign: "There is no signed document",
      sign_date: "Date Signed",
    },

    nda_contract_item: {
      see_file: "View files",
      email: "Send Email",
      download: "Download",
    },

    nda_item: {
      created: (text: string) => {
        return `${text} has been created`;
      },
      nda_file: "NDA File",
      file_name: "NDA File Name",
      sign_info: "Signature History",
      connected_file: "Connected File",
      update: "Updated",
      uploader: "Uploader",
      preview: "Open NDA",
      default_project: (count: number) => {
        return `Set as the default NDA in ${count} folders`;
      },
      add_description: "Add description",
      download_history: "Download signature history",
      delete: "Delete",
      description_placeholder: "Enter the file description",
      save: "Save",
      no_sign_history: "No Signature History",
      link_name: "Link Name",
      no_links: "There are no links",
      signed: "Number of Signing",
      file: "File",
      sign_requester: "Signature Requester (Institution)",
      sign_participant: "Signed by (Institution)",
      sign_date: "Date Signed",
    },
    nda_setting: {
      nda: "NDA",
      on: "ON",
      off: "OFF",
    },
  },
};
