import {
  OGetUserLicense,
  OResignLicense,
  validateOGetUserLicense,
  validateOResignLicense,
} from "@basalt-commons/api/response/admin";
import { doAPIPostRequest, doAPIGetRequest } from "./api";
import {
  ICheckLicenseInviteToken,
  IInviteLicense,
  IJoinLicense,
  IRemoveUserLicense,
} from "@basalt-commons/api/request/manager";
import {
  OCheckLicenseInviteToken,
  OGetPendingEmails,
  OGetUserAuthNotes,
  OJoinLicense,
  validateOCheckLicenseInviteToken,
  validateOGetPendingEmails,
  validateOGetUserAuthNotes,
  validateOJoinLicense,
  validateOGetLicenseLink,
} from "@basalt-commons/api/response/manager";
import { InternalErrorKind, isErr } from "@redwit-commons/utils/exception2";
import { getLoggers } from "@redwit-commons/utils/log";

const { WARN, INFO } = getLoggers("service/manager");

// OGetNote 형태 전체로 return
const getLicenseUsers = async (
  token: string,
  licenseId: string
): Promise<OGetUserLicense> => {
  const res = await doAPIGetRequest(`/license/${licenseId}/members`, token);
  const ret = validateOGetUserLicense(res);
  return ret;
};

const getLicenseLink = async (
  token: string,
  LicenseId: string
): Promise<string> => {
  const res = await doAPIGetRequest(`/license/${LicenseId}/link`, token);
  const ret = validateOGetLicenseLink(res);
  return ret.response;
};

// OGetNote 형태 전체로 return
const inviteLicense = async (
  token: string,
  licenseId: string,
  args: IInviteLicense
) => {
  await doAPIPostRequest(`/license/${licenseId}/invite`, token, args);
  return;
};

const joinLicense = async (
  token: string,
  args: IJoinLicense
): Promise<OJoinLicense | undefined> => {
  try {
    const res = await doAPIPostRequest(`/license/join`, token, args);
    const ret = validateOJoinLicense(res);
    return ret;
  } catch (err) {
    if (isErr(err)) {
      if (err.kind === InternalErrorKind.ResponseCode && err.code === 600) {
        INFO("1인 1라이센스 정책 위반", err.code);
        return undefined;
      }
      WARN("라이센스 참여 도중 에러 발생", err);
    }
    throw err;
  }
};

const deleteLicenseUser = async (
  token: string,
  licenseId: string,
  args: IRemoveUserLicense
): Promise<OResignLicense> => {
  const obj = await doAPIPostRequest(
    `/license/${licenseId}/resign`,
    token,
    args
  );
  const ret = validateOResignLicense(obj);
  return ret;
};

const getPendingEmails = async (
  token: string,
  LicenseId: string
): Promise<OGetPendingEmails> => {
  const res = await doAPIGetRequest(
    `/license/${LicenseId}/pending/emails`,
    token
  );
  const ret = validateOGetPendingEmails(res);
  return ret;
};

const getManagedUserAuthNotes = async (
  goonoToken: string,
  LicenseId: string,
  userId: string
): Promise<OGetUserAuthNotes> => {
  const ret = await doAPIGetRequest(
    `/license/${LicenseId}/${userId}/auth/notes`,
    goonoToken
  );
  return validateOGetUserAuthNotes(ret);
};

const checkLicenseToken = async (
  token: string,
  args: ICheckLicenseInviteToken
): Promise<OCheckLicenseInviteToken> => {
  const ret = validateOCheckLicenseInviteToken(
    await doAPIPostRequest(`/license/check/invite_token`, token, args)
  );
  return ret;
};

export default {
  getLicenseUsers,
  inviteLicense,
  joinLicense,
  deleteLicenseUser,
  getPendingEmails,
  getManagedUserAuthNotes,
  checkLicenseToken,
  getLicenseLink,
};
