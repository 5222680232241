import { createStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";

const styles = (MuiTheme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.white,
    },
    nda_item: {
      height: 72,
      paddingTop: 1,
      borderBottom: `solid 1px ${theme.gray_2}`,
      "&:hover": {
        backgroundColor: theme.gray_0,
      },
    },

    nda_item_grid: {
      display: "flex",
      padding: "0px 8px !important",
      alignItems: "center",
    },

    nda_index: {
      minWidth: 36,
      marginLeft: 8,
      ...theme.font14,
      color: theme.gray_6,
      height: 20,
    },

    nda_name_grid: {
      paddingLeft: 8,
      justifyContent: "space-between",
      flexWrap: "nowrap",
      maxWidth: "calc(100% - 44px)",
      alignItems: "center",
    },

    nda_name: {
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },

    nda_name_text: {
      ...theme.font16,
      color: theme.gray_7_text,
      width: "100%",
      maxWidth: 500,
    },

    nda_name_info: {
      alignItems: "center",
      gap: 4,
      flexWrap: "nowrap",
    },

    nda_folder_count: {
      color: theme.cian_6,
      ...theme.font10,
      backgroundColor: theme.gray_2,
      lineHeight: "14px",
      padding: "2px 4px",
      borderRadius: "8px",
      minWidth: "fit-content",
    },

    nda_description: {
      ...theme.font12,
      lineHeight: "16px",
      color: theme.gray_6,
      maxWidth: 300,
      width: "calc(100% - 60px)",
    },

    nda_signature: {
      padding: "8px 4px",
      width: "fit-content",
      gap: 4,
      borderRadius: 8,
      color: theme.white,
      "&:hover": {
        backgroundColor: `${theme.gray_2} !important`,
        color: `${theme.gray_5} !important`,
      },
    },

    nda_connected_file: {
      padding: "8px 4px",
      width: "fit-content",
      gap: 4,
      borderRadius: 8,
      color: theme.white,
      "&:hover": {
        backgroundColor: theme.gray_2,
        color: theme.gray_5,
      },
    },

    nda_info_text: {
      ...theme.font14,
      color: theme.gray_6,
      lineHeight: "20px",
      height: 20,
      overflow: "hidden",
    },

    icon_dropdown: {
      color: "currentColor",
      width: 20,
      height: 20,
    },

    nda_profile_grid: {
      paddingRight: 12,
      flexWrap: "nowrap",
      [MuiTheme.breakpoints.down(1280)]: {
        width: 40,
        flexBasis: 40,
        "& *": { display: "none" },
      },
    },

    nda_email_grid: {
      flexDirection: "column",
      width: "calc(100% - 44px)",
    },

    nda_avatar: {
      width: 36,
      height: 36,
      marginRight: 8,
    },

    nda_email: {
      ...theme.font12,
      lineHeight: "16px",
      color: theme.gray_5,
      width: "100%",
    },

    nda_sign_history: {
      paddingTop: 16,
      backgroundColor: theme.gray_0,
      position: "relative",
    },

    nda_sign_cover: {
      position: "absolute",
      width: 36,
      height: "calc(100% - 16px)",
      backgroundColor: theme.gray_0,
    },

    table_header: {
      marginTop: 0,
      marginBottom: 8,
    },

    table_header_grid: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },

    table_header_text: {
      ...theme.font14,
      color: theme.gray_6,
      lineHeight: "20px",
    },

    nda_sign_history_item: {
      height: 64,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 1,
      borderBottom: `solid 1px ${theme.gray_2}`,
    },

    nda_sign_history_grid: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },

    nda_sign_history_grid_file: {
      padding: "0px 8px 0px 52px !important",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },

    nda_sign_history_file: {
      ...theme.font16,
      color: theme.gray_7_text,
      height: 24,
    },

    nda_sign_history_name: {
      ...theme.font14,
      color: theme.gray_7_text,
      lineHeight: "20px",
      marginBottom: 2,
      width: "95%",
    },

    nda_sign_history_inst: {
      ...theme.font12,
      color: theme.gray_5,
      lineHeight: "16px",
    },

    nda_sign_history_date: {
      ...theme.font14,
      color: theme.cian_6,
      paddingRight: 12,
    },

    menu: {
      borderRadius: 8,
      border: `1px solid ${theme.gray_1}`,
      ...theme.shadow_2,
      zIndex: 3,
    },

    menu_list: {
      paddingTop: 7,
      paddingBottom: 7,
    },

    menu_item: {
      height: 40,
      paddingLeft: 11,
      paddingRight: 11,
      zIndex: 3,
      "&:hover": {
        backgroundColor: theme.indigo_0,
      },
    },

    menu_item_text: {
      ...theme.font14,
      color: theme.gray_9_title,
      lineHeight: "20px",
    },

    delete: {
      color: theme.system_red,
    },

    no_sign_history: {
      ...theme.font14,
      color: theme.gray_5,
      lineHeight: "20px",
    },

    no_sign_history_grid: {
      width: "100%",
      height: 62,
      padding: "21px 44px",
    },

    preview_button: {
      backgroundColor: theme.white,
      border: `1px solid ${theme.gray_2}`,
      padding: "5px 11px",
      borderRadius: 8,
      marginLeft: 8,
    },

    preview_button_text: {
      color: theme.gray_7_text,
      ...theme.font14,
      lineHeight: "20px",
    },

    link_table_label: {
      ...theme.font14,
      color: theme.gray_7_text,
    },

    link_table_label_icon: {
      color: theme.gray_7_text,
      fontSize: 18,
      marginRight: 4,
    },
  });

export default styles;
