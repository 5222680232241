import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import {
  mkGetURL,
  mkAuthHeader,
  mkJSONHeader,
} from "@redwit-commons/utils/request";

/* TODO : replace this with react-native-config */
const ENV_OBJS = {
  development: {
    API_SERVER: "https://dev.redwit.io:9443",
    BACKUP_SERVERS: [
      "https://dev2.redwit.io:9443",
      "https://dev1.redwit.io:9443",
      "https://183.107.13.61:9443",
      "https://183.107.13.26:9443",
    ],
    GOOGLE_LOGIN_WEBCLINET_ID:
      "962748495091-qu44kieo19mn4hpir854st9q55mjlei4.apps.googleusercontent.com",
    GOOGLE_LOGIN_IOSCLIENT_ID:
      "962748495091-na235b026s8i4d3hfq29ho95vur0cgri.apps.googleusercontent.com",
    AMPLITUDE_KEY: "f8c8d7d1ebd7f070bf1942b2bb9fe213",
  },
  test: {
    API_SERVER: "https://test.basalt-api.redwit.io",
    BACKUP_SERVERS: [
      "https://test.basalt-api.redwit.io:19443",
      "https://52.141.59.202:19443",
    ],
    GOOGLE_LOGIN_WEBCLINET_ID:
      "962748495091-qu44kieo19mn4hpir854st9q55mjlei4.apps.googleusercontent.com",
    GOOGLE_LOGIN_IOSCLIENT_ID:
      "962748495091-na235b026s8i4d3hfq29ho95vur0cgri.apps.googleusercontent.com",
    AMPLITUDE_KEY: "f8c8d7d1ebd7f070bf1942b2bb9fe213",
  },
  production: {
    API_SERVER: "https://basalt-api.redwit.io:9443",
    BACKUP_SERVERS: [
      "https://basalt-api2.redwit.io:9443",
      "https://basalt-api3.redwit.io:9443",
    ],
    GOOGLE_LOGIN_WEBCLINET_ID:
      "962748495091-qu44kieo19mn4hpir854st9q55mjlei4.apps.googleusercontent.com",
    GOOGLE_LOGIN_IOSCLIENT_ID:
      "962748495091-na235b026s8i4d3hfq29ho95vur0cgri.apps.googleusercontent.com",
    AMPLITUDE_KEY: "f8c8d7d1ebd7f070bf1942b2bb9fe213",
  },
  local: {
    API_SERVER: "http://localhost:8001",
    BACKUP_SERVERS: [""],
    GOOGLE_LOGIN_WEBCLINET_ID:
      "962748495091-qu44kieo19mn4hpir854st9q55mjlei4.apps.googleusercontent.com",
    GOOGLE_LOGIN_IOSCLIENT_ID:
      "962748495091-na235b026s8i4d3hfq29ho95vur0cgri.apps.googleusercontent.com",
    AMPLITUDE_KEY: "f8c8d7d1ebd7f070bf1942b2bb9fe213",
  },
};
const defaultEnv: ENV_TYPE = "production";
export let ENV = ENV_OBJS[defaultEnv];
export type ENV_TYPE = "production" | "local" | "test";
export function changeEnv(type: ENV_TYPE) {
  ENV = ENV_OBJS[type];
}
export const USE_BACKUP_SERVER = {
  backup_index: undefined as number | undefined,
};

const getAPIHost = () => {
  if (USE_BACKUP_SERVER.backup_index !== undefined) {
    return ENV.BACKUP_SERVERS[USE_BACKUP_SERVER.backup_index];
  }
  return ENV.API_SERVER;
};

/**
 * Request Interface
 */
type HTTPMethod =
  | "DELETE"
  | "GET"
  | "HEAD"
  | "PATCH"
  | "POST"
  | "PUT"
  | "OPTIONS";

const doGetRequest = async (
  host: string,
  endpoint: string,
  token?: string,
  queryObj?: { [key: string]: unknown }
): Promise<unknown> => {
  const response = await fetch(mkGetURL(host, endpoint, queryObj), {
    method: "GET",
    headers: token ? mkAuthHeader(token) : undefined, // any: react-native 사용을 위함
  }).catch((err) => {
    throw mkErr({
      kind: InternalErrorKind.Network,
      loc: "doGetRequest::fetch",
      host,
      endpoint,
      token,
      queryObj,
      err,
    });
  });
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    const err = await response.text().then((text) => {
      const err = new Error(text);
      return err;
    });
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "doGetRequest::response",
      code: response.status,
      msg: err.message,
    });
  }
  const ret: unknown = await response.json();
  return ret;
};

const doWriteRequest = async (
  host: string,
  method: HTTPMethod,
  endpoint: string,
  token?: string,
  bodyObj?: unknown
): Promise<unknown> => {
  const response = await fetch(mkGetURL(host, endpoint), {
    method,
    headers: {
      ...(bodyObj ? mkJSONHeader() : undefined),
      ...(token ? mkAuthHeader(token) : undefined),
    },
    body: bodyObj ? JSON.stringify(bodyObj) : undefined,
  }).catch((err) => {
    throw mkErr({
      kind: InternalErrorKind.Network,
      loc: "doWriteRequest::fetch",
      host,
      endpoint,
      token,
      bodyObj,
      err,
    });
  });
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    const err = await response.text().then((text) => {
      const err = new Error(text);
      return err;
    });
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "doWriteRequest::response",
      code: response.status,
      msg: err.message,
    });
  }
  return await response.json();
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const doAPIGetRequest = async (
  endpoint: string,
  token?: string,
  queryObj?: { [key: string]: any }
) => {
  return await doGetRequest(getAPIHost(), endpoint, token, queryObj);
};
/* eslint-enable @typescript-eslint/no-explicit-any */

const doAPIWriteRequest = async (
  method: HTTPMethod,
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doWriteRequest(getAPIHost(), method, endpoint, token, bodyObj);
};

export const doAPIPostRequest = async (
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doAPIWriteRequest("POST", endpoint, token, bodyObj);
};

export const doAPIDeleteRequest = async (
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doAPIWriteRequest("DELETE", endpoint, token, bodyObj);
};

export const doAPIPutRequest = async (
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doAPIWriteRequest("PUT", endpoint, token, bodyObj);
};

export const doAPIPatchRequest = async (
  endpoint: string,
  token?: string,
  bodyObj?: object
) => {
  return await doAPIWriteRequest("PATCH", endpoint, token, bodyObj);
};
