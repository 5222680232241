import { doAPIDeleteRequest, doAPIGetRequest, doAPIPostRequest } from "./api";
import {
  validateOGetAllStamp,
  validateOCreateStamp,
  validateODelteStamp,
  validateOUpdateStamp,
} from "@basalt-commons/global-api/response/stamp";
import {
  ICreateStamp,
  IUpdateStamp,
} from "@basalt-commons/global-api/request/stamp";

const getAllStamp = async (token: string, workspaceId: string) => {
  const ret = await doAPIGetRequest(`/stamp/${workspaceId}`, token);
  return validateOGetAllStamp(ret);
};
/**
 * Active되어 있는 stamp가 없을 때 생성 가능
 * @param token
 * @param workspaceId
 * @param args
 */
const createStamp = async (
  token: string,
  workspaceId: string,
  args: ICreateStamp
) => {
  const ret = await doAPIPostRequest(
    `/stamp/${workspaceId}/create`,
    token,
    args
  );
  return validateOCreateStamp(ret);
};

const deleteStamp = async (
  token: string,
  workspaceId: string,
  stampId: string
) => {
  const ret = await doAPIDeleteRequest(
    `/stamp/${workspaceId}/delete/${stampId}`,
    token
  );
  return validateODelteStamp(ret);
};

const updateStamp = async (
  token: string,
  workspaceId: string,
  stampId: string,
  args: IUpdateStamp
) => {
  const ret = await doAPIPostRequest(
    `/stamp/${workspaceId}/update/${stampId}`,
    token,
    args
  );
  return validateOUpdateStamp(ret);
};

export default {
  getAllStamp,
  createStamp,
  deleteStamp,
  updateStamp,
};
