import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import SpoqaHansTypography from "@SpoqaHansTypography";
import { theme } from "@theme";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: 16,
    padding: 8,
    position: "relative",
    backgroundColor: theme.gray_0,
  },
  root_clickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.gray_2,
    },
  },
  title: {
    ...theme.font16,
    marginLeft: 10,
    userSelect: "none",
    color: theme.gray_7_text,
  },
  title_when_there_is_icon: {},
}));

type SidebarDrawerTitleProps = {
  title: string;
  onClick?: () => void;
  rightIcon?: JSX.Element;
};

export default function SidebarDrawerTitle({
  title,
  onClick,
  rightIcon,
}: SidebarDrawerTitleProps) {
  const classes = useStyles();

  return (
    <Grid
      onClick={onClick}
      container
      item
      alignItems="center"
      direction="row"
      className={clsx(
        classes.root,
        onClick !== undefined && classes.root_clickable
      )}
    >
      <SpoqaHansTypography fontWeight="medium" className={classes.title}>
        {title}
      </SpoqaHansTypography>
      {rightIcon}
    </Grid>
  );
}
