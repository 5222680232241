export default {
  ko: {
    folder_item: {
      auth: {
        read_only: "",
        owner: "소유자",
        editor: "편집자",
      },

      private: "개인 폴더",
      public: "공용 폴더",
      shared: "공유 폴더",
      updated: (text: string) => `${text} 업데이트됨`,
      update_prefix: "",
      update_postfix: "업데이트됨",
      menu: {
        invite: "초대하기",
        settings: "설정하기",
        delete: "삭제하기",
        pin: "즐겨찾기 추가",
        unpin: "즐겨찾기에서 삭제",
      },
    },
  },

  en: {
    folder_item: {
      auth: {
        read_only: "Read only",
        owner: "Owner",
        editor: "Editor",
      },
      private: "Private folder",
      public: "Public folder",
      shared: "Shared folder",
      updated: (text: string) => `updated ${text}`,
      update_prefix: "updated",
      update_postfix: "",
      menu: {
        invite: "Invite",
        settings: "Settings",
        delete: "Delete",
        pin: "Pin",
        unpin: "Unpin",
      },
    },
  },
};
