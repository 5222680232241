let GLOBAL_PAYPLE_SCRIPT = "https://gpay.payple.kr/common/js/gpay-1.0.0.js";
let DOMESTIC_PAYPLE_SCRIPT = "https://cpay.payple.kr/js/cpay.payple.1.0.1.js";

if (typeof process.env.REACT_APP_TEST_ENABLED === "string") {
  if (
    process.env.REACT_APP_TEST_ENABLED === "1" ||
    process.env.REACT_APP_TEST_ENABLED === "y" ||
    process.env.REACT_APP_TEST_ENABLED === "Y"
  ) {
    GLOBAL_PAYPLE_SCRIPT =
      "https://demo-gpay.payple.kr/common/js/gpay-1.0.0.js";
    DOMESTIC_PAYPLE_SCRIPT =
      "https://democpay.payple.kr/js/cpay.payple.1.0.1.js";
  }
}

export default {
  GLOBAL_PAYPLE_SCRIPT,
  DOMESTIC_PAYPLE_SCRIPT,
};
