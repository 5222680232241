import { AccessTime, addAccessTime } from "../../api/object/access_time";
import T from "@redwit-commons/utils/typecheck";

export type ChatCore = {
  id: string;
  text: string;
  UserId: string;
  NoteId: string;
  pinnedUserId?: string;
  pageNum?: number;
};
export const ChatCoreSchema = T.object()
  .addField("id", T.string())
  .addField("text", T.string())
  .addField("UserId", T.string())
  .addField("NoteId", T.string())
  .addField("pinnedUserId", T.string(), false)
  .addField("pageNum", T.number(), false);

export type ChatObject = ChatCore & AccessTime;
export const ChatObjectSchema = addAccessTime(ChatCoreSchema.clone());

export const extractChatObject =
  T.mkObjectExtractor<ChatObject>(ChatObjectSchema);
export const validateChatObject = T.mkValidator<ChatObject>(ChatObjectSchema);
