export default {
  ko: {
    landing_solution: {
      banner_text: "\n문서 관리,\n한 큐에 끝내세요",
      banner_changing_text: [
        "투자 유치 시",
        "기업 간의 협력에서",
        "아이디어 및 기술 보호에서",
        "팀 내부적 문서 관리에서",
      ],
      banner_button: "지금 시작하기",
      person_title: "Harry, CSO of start-up",
      investment_person_review_headers:
        "바솔트를 이용하면서 <highlight>투자 유치<highlight>에 많은 <highlight>인사이트를<highlight> 얻을 수 있었죠!",
      cooperation_person_review_headers:
        "문서 보안 계약서와 파일 <highlight>공유<highlight>를 한 번에 진행할 수 있어서 편리합니다",
      idea_person_review_headers:
        "업로드 한 파일에 대한 블록체인 <highlight>인증서<highlight>로 <highlight>특허 가출원<highlight>까지 할 수 있다는게 놀라워요!",
      file_person_review_headers:
        "필수적으로 기록해야 하는 로그를 baSalt가 <highlight>자동<highlight>으로 기록해주니 일을 2번할 필요가 없어요!",
      investment_person_review_text:
        "“ 바솔트는 투자, 기금, 기술 자료 및 데이터와 문서를 빠르고 안전하게 공유하는 효과적인 도구입니다. 바솔트의 인사이트 리포트를 이용하면 파트너나 투자사의 관심과 잠재적인 니즈를 이해하는데 특히 유용합니다 ”",

      start_text: "더 많은 기능이 궁금하다면?",
      start_button: "지금 시작하기",
      card_section_header:
        "문서 관리에 대한 어떤 고민이든, baSalt가 해결합니다",
      cards: [
        {
          title: "드래그 앤 드랍 업로드하고,3초 블록체인 인증까지",
          highlight: "드래그 앤 드랍",
          text: "간편한 업로드 방식과 쉽고 블록체인으로 빠르고 안전하게 인증받을 수 있습니다.",
        },
        {
          title: "팀원 별 권한 설정을 통해, 세분화 된 문서 접근 관리",
          highlight: "권한 설정",
          text: "팀원 별로 세분화된 권한 설정을 통해 문서 보안을 강화할 수 있습니다.",
        },
        {
          title: "태그, OCR을 활용한 손쉬 운 문서 검색",
          highlight: ["태그", "OCR"],
          text: "문서에 대한 OCR을 제공하며, 태그를 이용하여 문서 검색 및 관리할 수 있습니다.",
        },
        {
          title: "NDA 및 옵션 설정을 통한 외부 공유 링크 생성",
          highlight: "옵션 설정",
          text: "다운로드, 프린트, NDA 비밀유지협약 등과 같은 다양한 공유 옵션을 설정하여 외부 사용자에게 더 안전하게 공유할 수 있습니다.",
        },
        {
          title: "링크에 대한 방문자 정보 및 인사이트 리포트 제공",
          highlight: "인사이트 리포트",
          text: "방문자 수, 페이지별 체류 시간에 대한 통계, 방문자 정보 등과 같은 공유 링크에 대한 다양한 리포트를 제공합니다.",
        },
        {
          title: "문서 열람/삭제/다운로드/공유 기록을 자동으로 수집",
          highlight: "자동으로 수집",
          text: "워크스페이스 내의 팀원들의 문서에 대한 활동 기록을 자동으로 수집하고, 이를 PDF로 추출할 수 있습니다.",
        },

        {
          title: "워크스페이스 코멘트를 이용한 협업 관리 증진",
          highlight: "코멘트",
          text: "파일 내부에서 팀원끼리 코멘트를 주고 받으면서 협업을 증진시킬 수 있습니다.",
        },

        {
          title: "외부 서비스 연동을 통한, 더 편리한 문서 업로드",
          highlight: "외부 서비스 연동",
          text: "외부 서비스 연동으로 더 쉽게 파일 업로드를 수행할 수 있도록 업데이트 될 예정입니다.",
        },

        {
          title: "워크스페이스 관리자를 위한 월간 리포트 제공",
          highlight: "워크스페이스 관리자",
          text: "워크스페이스 관리자에게 월간 리포트 제공 서비스를 업데이트 할 예정입니다.",
        },
      ],

      video_view: [
        {
          tab: "투자 유치 시",
          tab_xs: "투자 시",
          title:
            "IR 덱, 서비스 소개서 등과 같은 문서를 공유하고,\n어느 부분이 가장 많은 관심을 가졌는지 한 눈에 파악하세요!",
          text: "업로드한 파일에 대해 다운로드, 프린트, NDA 첨부 등과 같은 뷰어 옵션을 선택하여 공유 링크를 생성할 수 있습니다.\n파일을 열람하는 사용자에 대한 인사이트 및 정보에 대한 그래프를 실시간으로 확인할 수 있습니다.",
        },

        {
          tab: "기업 간의 협력에서",
          tab_xs: "협업 시",
          title:
            "NDA 첨부를 통해, 외부의 문서 공유 시 보안을\n한 층 더 강화하세요",
          text: "NDA 첨부를 통하여 문서 열람에 대한 보안 정책을 추가할 수 있고, 공유받은 사용자로부터 전자 서명을 받을 수 있습니다.\n기업 비밀, 협약 내용 등과 같이 외부 기업과의 문서 공유에 대해 보호를 하세요!",
        },

        {
          tab: "아이디어 및 기술 보호",
          tab_xs: "기술 보호",
          title:
            "블록체인 인증으로 파일을 보관하여\n아이디어, 기술과 업무의 과정을 손쉽게 증명할 수 있습니다!",
          text: "업로드한 파일은 즉시 블록체인에 저장되기 때문에, 해당 파일의 업로드 시점, 파일의 원본 진위 여부 등과 같은\n과정에 대한 법적 증명을 바로 할 수 있습니다.",
        },

        {
          tab: "팀 내부적 문서 관리",
          tab_xs: "문서 관리",
          title:
            "문서의 생성, 삭제, 열람, 공유 모든 내역을\n자동으로 기록하고, 관리하세요!",
          text: "워크스페이스 내부의 문서에 대한 열람, 다운로드, 삭제, 공유 등과 같은 활동들을 실시간으로 기록하고,\n이를 직관적인 형태의 타임라인으로 제공합니다. 제공된 타임라인은 날짜별, 팀원별, 활동 별로 필터링하여 확인할 수 있습니다.",
        },
      ],
    },
  },
  en: {
    landing_solution: {
      banner_text: "\nBe with baSalt!",
      banner_changing_text: [
        "In case of\nattracting investment",
        "For cooperation\nbetween enterprises",
        "For protecting\nidea and technology ",
        "For document management\nwithin a team",
      ],
      banner_button: "Start now",
      person_title: "Harry, CSO of start-up",
      investment_person_review_headers:
        "By using baSalt, I could earn a lot of insight for investiment attraction",
      cooperation_person_review_headers:
        "It is very convenient since it enables us to proceed security contract and file haring simultaneously",
      idea_person_review_headers:
        "It is amazing that we can apply a provisional patentwith the block chain verification for the uploaded files!",
      file_person_review_headers:
        "The necessary logs do not have to done twice since baSalt automatically takes care of them",
      investment_person_review_text:
        "“ baSalt is an effective tool for quickly and securely sharing investments, funds, knowledge base and data and documents. baSalt's Insight Report is useful for understanding the interests and potential needs of partners and investors ”",
      start_text: "For more?",
      start_button: "Free Trial Now",
      card_section_header: "BaSalt Docs will help you manage your documents",
      cards: [
        {
          title:
            "Drag-and-drop uploading and blockchain authentication in 3 seconds",
          highlight: "Drag-and-drop",
          text: "Easy and safe verification is possible through block chain and convenient upload method.",
        },
        {
          title:
            "Manage document access finely by setting permissions for team members",
          highlight: "Manage",
          text: "You can enhance document security through detailed authority settings for each person.",
        },
        {
          title: "Use tags and OCR to search documents easily",
          highlight: ["tags", "OCR"],
          text: "OCR for the documents are provided, and documents can be searched and managed  through tags.",
        },
        {
          title: "Create external share links with NDAs and option settings",
          highlight: "option",
          text: "Can be more safely shared to external users by setting various options such as downloading, printing and NDA confidentiality agreement.",
        },
        {
          title: "Analyzes visitor information and insight reports for links",
          highlight: "insight reports",
          text: "Various reports regarding share links such as the number of visitors, statistics for dwelling time per page and information of visitors.",
        },
        {
          title:
            "Automatically collects document viewing, deletion, downloading, and sharing history",
          highlight: "Automatically collects",
          text: "Activity logs for the teammates’ documents within the workspace can be automatically collected and extracted by PDF.",
        },

        {
          title: "Collaboration management with workspace comments",
          highlight: "Collaboration",
          text: "Teammates can exchange comments within the file itself, enhancing cooperation.",
        },

        {
          title:
            "Linking external services to make document uploading more convenient",
          highlight: "Linking external services",
          text: "The system will be updated so that files can be more easily uploaded by interlocking with external services.",
        },

        {
          title: "Provides monthly reports to workspace administrators",
          highlight: "workspace administrators",
          text: "Monthly report service for workspace manager will be updated.",
        },
      ],

      video_view: [
        {
          tab: "For Investment",
          tab_xs: "For Invest",
          title:
            "Documents such as pitch decks and service introductions should be shared, Find out which part attracted the mot attention!",
          text: "You can create a share link by selecting the viewer option for the uploaded files such as download, print, and NDA attachment.\nThe insights for those who are browsing the documents and graphs about information can be checked in real time.",
        },

        {
          tab: "For cooperation",
          tab_xs: "Cooperation",
          title:
            "Using NDA attachments, increase security\nfor external document sharing.",
          text: "Through attaching NDA, you can add security terms regarding document browsing, and can receive electronic signature from the shared users.\nProtect the externally shared documents such as trade secrets and confidential contracts!",
        },

        {
          tab: "For protecting idea",
          tab_xs: "Protect idea",
          title:
            "With blockchain authentication,\nyou can prove your ideas, skills, and processes easily",
          text: "The uploaded files are immediately stored in block chain, so it can be legally proved\nfor the processes such as uploaded point and authenticity check.",
        },

        {
          tab: "For file management",
          tab_xs: "Manage files",
          title:
            "Automatically record all\ncreations, deletions, viewings, and sharings of documents",
          text: "Activities such as browsing, downloading, deleting and sharing the documents within the workspace will be recorded real time,\nand will be provided as an intuitive timeline. The timeline can be filtered and checked according to date, person, and the kind of activity.",
        },
      ],
    },
  },
};
