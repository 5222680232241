import {
  GoonoArrayResponse,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "../../api/response/common";
import T from "@redwit-commons/utils/typecheck";
import {
  RefineWorkspaceWithPaymentInfoSchema,
  WorkspaceWithPaymentInfo,
} from "../object/admin";

/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum AdminErrorType {
  NOT_FOUND_PLAN = "AdminError::NOT_FOUND_PLAN",
  ALREADY_HAVE_PLAN = "AdminError::ALREADY_HAVE_PLAN",
}

export type OGetWorkspaceInfo = GoonoArrayResponse<WorkspaceWithPaymentInfo>;
export const OGetWorkspaceInfoSchema = mkArrayResultsSchema(
  RefineWorkspaceWithPaymentInfoSchema
);
export const validateOGetWorkspaceInfo = T.mkValidator<OGetWorkspaceInfo>(
  OGetWorkspaceInfoSchema
);

export type OCreatePlan = GoonoResponse<WorkspaceWithPaymentInfo>;
export const OCreatePlanSchema = mkResponseSchema(
  RefineWorkspaceWithPaymentInfoSchema
);
export const validateOCreatePlan =
  T.mkValidator<OCreatePlan>(OCreatePlanSchema);

export type OUpdatePlan = GoonoResponse<WorkspaceWithPaymentInfo>;
export const OUpdatePlanSchema = mkResponseSchema(
  RefineWorkspaceWithPaymentInfoSchema
);
export const validateOUpdatePlan =
  T.mkValidator<OUpdatePlan>(OUpdatePlanSchema);
