import T from "@redwit-commons/utils/typecheck";
import {
  GoonoResponse,
  mkResponseSchema,
  GoonoArrayResponse,
  mkArrayResultsSchema,
} from "./common";
import {
  GithubLinkageObject,
  GithubLinkageObjectSchema,
} from "../object/github";

export type OGithubRepos = GoonoArrayResponse<string>;
export const OGithubReposSchema = mkArrayResultsSchema(T.string());
export const validateOGithubRepos =
  T.mkValidator<OGithubRepos>(OGithubReposSchema);

export type OGithubNote = GoonoResponse<string>;
export const OGithubNoteSchema = mkResponseSchema(T.string());
export const validateOGithubNote =
  T.mkValidator<OGithubNote>(OGithubNoteSchema);

export type OGithubStatus = GoonoArrayResponse<GithubLinkageObject>;
export const OGithubStatusSchema = mkArrayResultsSchema(
  GithubLinkageObjectSchema
);
export const validateOGithubStatus =
  T.mkValidator<OGithubStatus>(OGithubStatusSchema);

export type ODeleteGithubNote = GoonoResponse<string>;
export const ODeleteGithubNoteSchema = mkResponseSchema(T.string());
export const validateODeleteGithubNote = T.mkValidator<ODeleteGithubNote>(
  ODeleteGithubNoteSchema
);
