export default {
  ko: {
    landing_service_intro: {
      banner: {
        header: "문서 관리에 필요한\n모든 서비스,\nbaSalt에 있습니다",
        button: "지금 시작하기",
      },
      row_1: {
        title_1: "업로드 즉시 이중",
        title_2: "되어 블록체인까지",
        title_highlight: "암호화",
        text: "업로드와 동시에 이중 암호화되어 블록체인에 안전하게\n보관됩니다. 또한, 업로드 한 시점에 대한 타임스탬프가\n적힌 블록체인 인증서를 제공합니다.",
      },

      row_2: {
        title: "워크스페이스 룰 설정을 통한\n팀 내부적 문서 보안 관리",
        text: "워크스페이스 내의 룰 설정을 통하여 팀원에게 자동으로 문서 보안\n에 관한 NDA 서명을 받을 수 있습니다. 또한, 세분화된 권한 설정을\n통해 문서의 팀원 접근 제어를 손쉽게 할 수 있습니다.",
      },

      row_3: {
        title: "공유 옵션 설정을 통한\n외부 공유 보안 강화",
        text: "다운로드, 프린트, NDA를 통한 비밀유지협약 등 다양한\n공유 옵션을 설정하여 외부 사용자의 파일 접근에 대한\n보안을 손쉽게 한층 더 강화할 수 있습니다.",
      },

      row_4: {
        title: "공유 링크에 대한\n인사이트 리포트 제공",
        text: "공유 링크를 통해 접속한 방문자의 정보 및 페이지 별\n체류 시간, 가장 관심 있게 본 페이지 등이 포함된\n인사이트 리포트를 제공합니다.",
      },

      row_5: {
        title: "문서에 관한 모든 기록\n자동 수집 기능",
        text: "문서의 열람, 삭제, 공유, 다운로드 등 모든 활동에 대해\n자동으로 수집하고 이를 직관적인 UI로 제공하며\nPDF 추출을 통해 별도의 문서로 보관하실 수 있습니다.",
      },

      row_6: {
        title: "OCR을 이용한 검색 및\n태그 기능 제공",
        text: "문서에 대한 OCR 검색이 가능하여, 어떤 문서 형식이든 손쉽게\n검색할 수 있습니다. 또한 문서에 태그를 달아검색 및 문서 관리를\n용이하게 할 수 있습니다.",
      },

      start_free_header:
        "이 모든 것을 무료로!\n지금 baSalt Docs에서 혜택 챙겨가세요!",
      start_free_button: "무료체험 시작하기",
      person_small_text: "Harry, CSO of Start-up",
      person_title:
        "팀 내부적 관리와 소통 측면에\n있어서 업무 과정을 바로\n확인할 수 있어요",
      person_text:
        "“ 바솔트를 사용하여 조직 전체 현안에 빠르게 대처하고 있습니다. 공유하는 파일들에 대해 언제 액세스 하였는지 등의 정보를 타임라인을 볼 수 있고, 권한 세분화 등의 기능을 이용하여 문서 체계를 쉽게 구축하였습니다. “",
      solution_button: "솔루션 확인하기",
      solution_text_large: "같은 고민을 하고 계신가요?",
      solution_text_small: "baSalt로 어떻게 해결하였는지 확인해보세요!",
      try_button: "무료로 다양한 기능 이용해보기",
      guide_button: "서비스 이용 가이드 확인하기",
      moving_text: "그 외에도 더 많은 기능을 제공합니다!",
    },
  },

  en: {
    landing_service_intro: {
      banner: {
        header: "All your needs for file management\nare here in baSalt Docs.",
        button: "Free Trial Now!",
      },

      row_1: {
        title_1: "Block data leakage with double encryption and",
        title_2: "immediately upon file upload!",
        title_highlight: "blockchain storage",
        text: "Once you upload a file on baSalt Docs, it is double-encrypted and securely stored in the blockchain. You can also download a blockchain certificate with a timestamp on the time of upload and use it as legal evidence if necessary.",
      },

      row_2: {
        title:
          "Securely manage documents that are shared internally by setting workspace rules!",
        text: "By setting rules within the workspace, you can automatically obtain Esignatures on NDA about document security from other members. In addition, granular permission settings make it easier for workspace members to control access to documents.",
      },

      row_3: {
        title:
          "Enhance external sharing security by setting various options when creating sharing-links!",
        text: "By setting various options such as dowload, print, creating NDA and getting Esignatures from received parties, you can provide more secure control over document access by external users.",
      },

      row_4: {
        title:
          "Use an Insight report which records all activities of users who have accessed sharing-links!",
        text: `You can download an "Insight Report" that includes information about visitors who have accessed the link, their duration of stay, and the page you are most interested in, and use it for your work.`,
      },

      row_5: {
        title: "Download all records for\nall documents on baSalt Docs!",
        text: "All records of creation, deletion, sharing, viewing and downloading, etc. of files can be viewed by member/activity. Download in PDF format also allows you to archive as a separate document. You can download it as PDF format.",
      },

      row_6: {
        title: "Advanced file search using OCR and tags!",
        text: "OCR search for documents makes it easy to find any document format You can search. You can also tag documents to manage searches and documents It can be done easily.",
      },

      start_free_header:
        "Do Not Hesitate To Try baSalt Docs.\nGet Started Right Now!",
      start_free_button: "Free Trial Now",
      person_small_text: "Harry, CSO of Start-up",
      person_title:
        "I can check the process of work regarding team management and communication.",
      person_text:
        "“ We are quickly responding to the issues throughout our organization with baSalt. Information such as time of access for the shared files can be seen through the timeline, and we have set a convenient document system through detailed authorization. “",
      solution_button: "Free Trial Now!",
      solution_text_large: "Do you have same concerns?",
      solution_text_small: "Check out how baSalt Docs has helped solve them!",
      try_button: "Meet Incredible Features Right Now!",
      guide_button: "Get Useful Insights Right Now!",
      moving_text: "And we provide so much more!",
    },
  },
};
