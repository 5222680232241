export default {
  ko: {
    signed_doc: {
      title: "내 보안 서약",
      search: "NDA 파일명으로 검색해 주세요",
      search_xs: "파일 검색하기",
      download: "전체 다운받기",
      download_tooltip: "현재 보이는 문서들을 모두 다운로드 합니다",
      inside_curr_workspace: "현재 워크스페이스 내부",
      all_workspaces: "전체 워크스페이스",
      no_files: "파일이 없습니다",
      rule_tab: {
        title: "워크스페이스 룰 서명",
        waiting_for_signature: "서명 대기 중",
        completed_signing: "완료된 서명",
        nda_filename: "NDA 파일명",
        browse_filename: "열람 파일명",
        workspace_name: "워크 스페이스명",
        signature_requester: "서명 요청자",
        request_date: "요청 시간",
        who_signed: "서명자",
        sign_date: "사인한 시간",
        sign: "서명하기",
        menu: {
          check_nda: "NDA 파일 확인",
          sign: "서명하기",
          view_file: "파일 보기",
        },
      },

      file_tab: {
        title: "파일 열람 동의 서명",
      },
    },
  },

  en: {
    signed_doc: {
      title: "Signed Documents",
      search: "Search for the NDA file name",
      search_xs: "Search",
      download: "Download all",
      download_tooltip: "Download all documents that are seen now",
      inside_curr_workspace: "Inside the current workspace",
      all_workspaces: "All workspaces",
      no_files: "No Files Found",
      rule_tab: {
        title: "Sign for workspace rules",
        waiting_for_signature: "Waiting for signing",
        completed_signing: "Completed Signature",
        nda_filename: "NDA File Name",
        browse_filename: "Browse filename",
        workspace_name: "Workspace Name",
        signature_requester: "Signature Requester",
        request_date: "Request Time",
        who_signed: "Signee",
        sign_date: "Date Signed",
        sign: "Sign",
        menu: {
          check_nda: "Check NDA file",
          sign: "Sign",
          view_file: "View file",
        },
      },

      file_tab: {
        title: "Signed consents to view files",
      },
    },
  },
};
