export const BaseDrawerWidth = 220;
export const BaseAppBarWidth = 56;
export const BaseSideBarWidth = BaseAppBarWidth + BaseDrawerWidth;
export const BaseHeaderHeight = 70;
export const BaseMobileGuide = 970;

export const BaseHeaderHeightXS = 48;

export const ServiceHeaderHeightXL = 72;
export const ServiceHeaderSidePaddingXL = 20;
export const ServiceHeaderSidePaddingXS = 16;
export const ViewerSubHeaderHeightXL = 56;
