import LocalizedStrings from "react-localization";
import common from "./common";
import screen from "./screens";
import error from "./error";
import modal from "./modal";
import snackbar from "./snackbar";
import items from "./items";

export const DEFAULT_LANG =
  navigator.language === "ko" || navigator.language === "ko-KR" ? "ko" : "en";
const translate = {
  ko: {
    ...common.ko,
    ...error.ko,
    ...modal.ko,
    ...screen.ko,
    ...snackbar.ko,
    ...items.ko,
  },
  en: {
    ...common.en,
    ...error.en,
    ...modal.en,
    ...screen.en,
    ...snackbar.en,
    ...items.en,
  },
};

export default new LocalizedStrings(translate);
