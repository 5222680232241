import T from "@redwit-commons/utils/typecheck";
import { GoonoResponse, mkResponseSchema, GoonoPendingTasks } from "./common";
import { GDTObject, GDTObjectSchema } from "../object/google_drive";

/*
 * types
 */
export type OMessage = GoonoResponse<{ msg: string }>;
export type OGoogleDriveToken = GoonoResponse<GDTObject>;

/*
 * Schema
 */
export const OMessageSchema = mkResponseSchema(
  T.object().addField("msg", T.string())
);
export const OGDTSchema = mkResponseSchema(GDTObjectSchema);

/*
 * Validator & Extractor
 */
export const validateOMessage = T.mkValidator<OMessage>(OMessageSchema);
export const validateOGDT = T.mkValidator<OGoogleDriveToken>(OGDTSchema);

// response only new notes, but RTN as NoteList.
export type OGoogleDriveNote = GoonoResponse<{ id: string }> &
  GoonoPendingTasks<{ auth: string; newnote: string; google_drive: string }>;
export const OGoogleDriveNoteSchema = mkResponseSchema(
  T.object().addField("id", T.string()),
  T.object()
    .addField("google_drive", T.string())
    .addField("newnote", T.string())
    .addField("auth", T.string())
);
export const validateOGoogleDriveNote = T.mkValidator<OGoogleDriveNote>(
  OGoogleDriveNoteSchema
);
