import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import Services from "@basalt-react-commons/services";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  SearchStateMachineType,
  searchStateMachine,
  SearchState,
  SearchAction,
  SearchStateStatus,
  SearchActionKind,
  SearchType,
  SearchObject,
} from "../../store/reducers/search";
import { TokenStateStatus } from "../../store/reducers/token";
import { mkErr, InternalErrorKind } from "@redwit-commons/utils/exception2";
import { HistoryObject, HistoryType } from "@basalt-commons/api/object/history";
import moment from "moment";

const { GlobalSearchService } = Services;

/**
 * HistoryList를 업데이트순으로 정리합니다
 * @param history HistoryObject[]
 */
const alignHistoryWithUpdateTime = (
  history: HistoryObject[]
): HistoryObject[] => {
  const sort = history.sort((a, b) => {
    let aDate = moment(a.updatedAt);
    let bDate = moment(b.updatedAt);
    return aDate.isAfter(bDate) ? -1 : 1;
  });
  return sort;
};

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.search,
    token: state.token,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SearchContainerProps = PropsFromRedux & {
  stateMachine: SearchStateMachineType;
};

class SearchContainer extends ReduxStateComponent3<SearchContainerProps> {
  static defaultProps = {
    stateMachine: searchStateMachine,
  };
  constructor(props: SearchContainerProps) {
    super(props);
  }
  SEARCH_FETCH_SIZE = 32;
  protected async onAction(
    prevState: SearchState,
    action: SearchAction
  ): Promise<SearchState> {
    const { token } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS) {
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "SearchContainer::onAction",
        msg: "Token state is unvalid",
      });
    }
    const userToken = token.state.token;

    switch (action.kind) {
      case SearchActionKind.TRY_READY: {
        const ret = await this.guardAwait(() =>
          GlobalSearchService.getSearchWord(userToken)
        );
        const hashtags = alignHistoryWithUpdateTime(
          ret.response.results.filter((h) => h.type === HistoryType.HASHTAG)
        );
        const words = alignHistoryWithUpdateTime(
          ret.response.results.filter((h) => h.type === HistoryType.SEARCH_WORD)
        );
        if (prevState.status === SearchStateStatus.INIT)
          return {
            status: SearchStateStatus.READY,
            recents: { hashtags, words },
          };
        else return { ...prevState, recents: { hashtags, words } };
      }
      case SearchActionKind.TRY_SEARCH: {
        const { word, workspaceId } = action;

        // hashtag update init으로 들어오는 경우는 update 해주지 않습니다
        // 검색어를 직접 입력해서 들어온 것이 아닙니다
        if (prevState.status !== SearchStateStatus.INIT)
          await this.guardAwait(() =>
            GlobalSearchService.updateSearchWord(userToken, {
              value: word,
              type: HistoryType.SEARCH_WORD,
            })
          );

        const history = await this.guardAwait(() =>
          GlobalSearchService.getSearchWord(userToken)
        );
        const hashtags = alignHistoryWithUpdateTime(
          history.response.results.filter((h) => h.type === HistoryType.HASHTAG)
        );
        const words = alignHistoryWithUpdateTime(
          history.response.results.filter(
            (h) => h.type === HistoryType.SEARCH_WORD
          )
        );
        const recents = { hashtags, words };

        const args = { q: word, fetchSize: this.SEARCH_FETCH_SIZE };
        const ret =
          workspaceId === undefined
            ? await this.guardAwait(() =>
                GlobalSearchService.search(userToken, args)
              )
            : await this.guardAwait(() =>
                GlobalSearchService.searchWorkspace(
                  userToken,
                  workspaceId,
                  args
                )
              );
        const {
          results_hash_tag,
          results_keyword,
          results_project,
          results_file,
          total_keyword,
          total_project,
          total_tag,
          total_file,
        } = ret.response;
        const results = {
          hashtags: results_hash_tag,
          keywords: results_keyword,
          projects: results_project,
          files: results_file,
          hashtagsTotal: total_tag,
          keywordsTotal: total_keyword,
          projectsTotal: total_project,
          filesTotal: total_file,
        };

        return {
          ...prevState,
          status: SearchStateStatus.SUCCESS,
          recents,
          results,
        };
      }
      case SearchActionKind.TRY_DELETE_WORD: {
        const { target } = action;
        if (prevState.status === SearchStateStatus.INIT)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: SearchActionKind.TRY_DELETE_WORD,
            msg: "prevState is unvalid",
          });
        let hashtags: HistoryObject[] = prevState.recents.hashtags;
        let words: HistoryObject[] = prevState.recents.words;
        await this.guardAwait(() =>
          GlobalSearchService.deleteSearchWord(userToken, target.id)
        );
        if (target.type === HistoryType.HASHTAG) {
          hashtags = prevState.recents.hashtags.filter(
            (h) => h.id !== target.id
          );
        } else {
          words = prevState.recents.words.filter((w) => w.id !== target.id);
        }
        return { ...prevState, recents: { hashtags, words } };
      }
      case SearchActionKind.TRY_SEARCH_MORE: {
        const { type, word, workspaceId } = action;
        const args = { q: word, fetchSize: this.SEARCH_FETCH_SIZE };

        // history 다시 받아와야 합니다 (init 상태에서 출발할 수 있음)
        const history = await this.guardAwait(() =>
          GlobalSearchService.getSearchWord(userToken)
        );
        const hashtag = alignHistoryWithUpdateTime(
          history.response.results.filter((h) => h.type === HistoryType.HASHTAG)
        );
        const words = alignHistoryWithUpdateTime(
          history.response.results.filter(
            (h) => h.type === HistoryType.SEARCH_WORD
          )
        );
        const recents = { hashtags: hashtag, words };

        let results: SearchObject =
          prevState.status === SearchStateStatus.SUCCESS
            ? prevState.results
            : {
                hashtags: [],
                keywords: [],
                projects: [],
                files: [],
                hashtagsTotal: 0,
                keywordsTotal: 0,
                projectsTotal: 0,
                filesTotal: 0,
              };
        if (prevState.status === SearchStateStatus.INIT) {
          const ret = await this.guardAwait(() =>
            GlobalSearchService.search(userToken, args)
          );
          const {
            results_hash_tag,
            results_keyword,
            results_project,
            results_file,
            total_keyword,
            total_project,
            total_tag,
            total_file,
          } = ret.response;
          results = {
            hashtags: results_hash_tag,
            keywords: results_keyword,
            projects: results_project,
            files: results_file,
            hashtagsTotal: total_tag,
            keywordsTotal: total_keyword,
            projectsTotal: total_project,
            filesTotal: total_file,
          };
        }

        switch (type) {
          case SearchType.HASHTAG: {
            if (results.hashtagsTotal === results.hashtags.length) break;
            const beforeAt =
              results.hashtags[results.hashtags.length - 1].createdAt;
            const ret = await this.guardAwait(() =>
              GlobalSearchService.searchMoreTagWorkspace(
                userToken,
                workspaceId,
                {
                  ...args,
                  beforeAt,
                }
              )
            );
            results = {
              ...results,
              hashtags: [...results.hashtags, ...ret.response.results],
            };
            break;
          }
          case SearchType.OCR: {
            if (results.keywordsTotal === results.keywords.length) break;
            const beforeAt =
              results.keywords[results.keywords.length - 1].createdAt;
            const ret = await this.guardAwait(() =>
              GlobalSearchService.searchMoreKeywordWorkspace(
                userToken,
                workspaceId,
                {
                  ...args,
                  beforeAt,
                }
              )
            );
            results = {
              ...results,
              keywords: [...results.keywords, ...ret.response.results],
            };
            break;
          }
          case SearchType.PROJECT: {
            if (results.projectsTotal === results.projects.length) break;
            const beforeAt =
              results.keywords[results.projects.length - 1].createdAt;
            const ret = await this.guardAwait(() =>
              GlobalSearchService.searchMoreProject(userToken, {
                ...args,
                beforeAt,
              })
            );
            results = {
              ...results,
              projects: [...results.projects, ...ret.response.results],
            };
            break;
          }
        }
        return { status: SearchStateStatus.SUCCESS, recents, results };
      }
    }
  }
}

export default connector(SearchContainer);
