import { Button, Grid, WithStyles, withStyles } from "@material-ui/core";
import { theme } from "@theme";
import React from "react";
import SpoqaHansTypography from "@SpoqaHansTypography";
import RobotoTypography from "@RobotoTypography";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styles from "./styles/MainSidebar";
import {
  doWorkspaceAction,
  doWorkspaceActionAsync,
  WorkspaceActionKind,
  WorkspaceErrorType,
  WorkspaceStateStatus,
} from "../store/reducers/workspace";
import WorkspaceIcon from "../components/pure/buttons/WorkspaceIcon";
import {
  getWorkspaceRoleLevel,
  WorkspaceRoleType,
} from "@basalt-commons/global-api/object/user_workspace_map";
import { TokenStateStatus } from "../store/reducers/token";
import {
  WorkspaceWithPlan,
  WorkspaceWithTeamObject,
} from "@basalt-commons/global-api/object/workspace";
import {
  doModalAction,
  ModalActionKind,
  ModalType,
} from "../store/reducers/modal";
import {
  doProjectAction,
  ProjectActionKind,
  ProjectStateStatus,
} from "../store/reducers/project";
import {
  ProjectObject,
  ProjectRoleType,
} from "@basalt-commons/api/object/project";
import {
  doSnackbarAction,
  SnackbarActionKind,
  SnackbarType,
} from "../store/reducers/snackbar";
import { ProjectAuthType } from "@basalt-commons/api/object/user_project_map";
import { ScreenURL } from "../routes/RouteList";
import { projectNameValidator } from "../utils/input-validator";
import {
  InternalError,
  InternalErrorKind,
  isErr,
} from "@redwit-commons/utils/exception2";
import translate from "../utils/translate";
import config from "../config";
import { getProjectAuthLevel } from "@basalt-commons/global-api/object/user_project_map";
import Sidebar from "./Sidebar";
import FolderDrawerInnerComponent from "./FolderDrawerInnerComponent";
import SecurityDrawerInnerComponent from "./SecurityDrawerInnerComponent";
import WorkspaceSettingDrawerInnerComponent from "./WorkspaceSettingDrawerInnerComponent";
import ActivityDrawerInnerComponent from "./ActivityDrawerInnerComponent";
import { doLogActionAsync, LogActionKind } from "@src/store/reducers/log";
import { ActivityType } from "@src/utils/data/LogActionType";
import {
  doPersistAction,
  PersistActionKind,
} from "@src/store/reducers/persist";
import { handleValidateWorkspaceError } from "@src/utils/fetch-utils";
import {
  getWorkspacePlanLevel,
  WorkspacePlanType,
} from "@basalt-commons/global-api/object/workspace_plan";

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    workspace: state.workspace,
    log: state.log,
    project: state.project,
    persist: state.persist,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type SidebarProps = {
  open?: boolean;
  onSidebarOpen: (state: boolean) => void;
  isMobile?: boolean;
};
type MainSidebarProps = PropsFromRedux &
  RouteComponentProps<Props> &
  SidebarProps &
  WithStyles<typeof styles>;

type MainSidebarState = {
  anchorEl: null | HTMLElement;
};

class MainSidebar extends React.PureComponent<
  MainSidebarProps,
  MainSidebarState
> {
  constructor(props: MainSidebarProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  getWorkspaceData() {
    const { workspace } = this.props;
    let workspace_curr: WorkspaceWithTeamObject | undefined,
      plan: WorkspaceWithPlan | undefined;
    if (workspace.state.status === WorkspaceStateStatus.SUCCESS) {
      workspace_curr = workspace.state.selectAuthWorkspace;
      plan = workspace.state.workspaceWithPlan;
    }
    const userId = this.getUserId();
    const userRole = workspace_curr?.Users.find(
      (user) => user.id === userId
    )?.roleType;
    return {
      workspace_curr,
      workspaces: workspace.state.workspaces,
      plan,
      userRole,
    };
  }

  getUserId() {
    const { token } = this.props;
    return token.state.status === TokenStateStatus.SUCCESS
      ? token.state.id
      : "invalid Id";
  }

  isAdmin(pj: ProjectObject) {
    const userId = this.getUserId();
    const userProjectAuth = pj.Users.find((u) => u.id === userId);
    return (
      getProjectAuthLevel(userProjectAuth?.authType) >=
      getProjectAuthLevel(ProjectAuthType.ADMIN)
    );
  }

  openFolderEditModal(pj: ProjectObject) {
    if (!this.isAdmin(pj)) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.snackbar.not_authorized,
      });
      return;
    }
    doModalAction(this.props.dispatch, {
      kind: ModalActionKind.TRY_OPEN,
      type: ModalType.TEXT_INPUT,
      title: `${pj.name} ${translate.modal.edit_folder.title}`,
      confirmMsg: translate.modal.save,
      placeholder: translate.modal.edit_folder.placeholder,
      errorValidator: (text: string) => projectNameValidator(text),
      onSubmit: (text: string) => {
        doProjectAction(
          this.props.dispatch,
          {
            kind: ProjectActionKind.TRY_EDIT_PROJECT,
            id: pj.id,
            name: text,
          },
          () => {
            doSnackbarAction(this.props.dispatch, {
              kind: SnackbarActionKind.TRY_OPEN,
              type: SnackbarType.CONFIRM,
              msg: translate.snackbar.folder.edit.success,
            });
          },
          (_err) => {
            doSnackbarAction(this.props.dispatch, {
              kind: SnackbarActionKind.TRY_OPEN,
              type: SnackbarType.ALERT,
              msg: translate.snackbar.folder.edit.fail,
            });
          }
        );
      },
    });
  }

  async onClickSignOutWorkspace() {
    try {
      await doWorkspaceActionAsync(this.props.dispatch, {
        kind: WorkspaceActionKind.TRY_SIGN_OUT_WORKSPACE,
      });
      this.props.history.replace(ScreenURL.SERVICE_MAIN);
      return;
    } catch (err) {
      if (isErr(err) && err.kind === InternalErrorKind.Abort) {
        if (err.msg === WorkspaceErrorType.WARN_OWNER_SIGN_OUT) {
          doSnackbarAction(this.props.dispatch, {
            kind: SnackbarActionKind.TRY_OPEN,
            type: SnackbarType.ALERT,
            msg: translate.snackbar.workspace.signout.fail.reject_owner,
          });
          return;
        }
      }
    }
    return;
  }

  getUserProfileCid() {
    const { token } = this.props;
    let userProfileCid =
      token.state.status === TokenStateStatus.SUCCESS
        ? token.state.profile_cid
        : undefined;
    return {
      userProfileCid,
    };
  }

  handleClickOutside = (event: MouseEvent) => {
    const targetId = (event.target as Element).id; // component click시 제외
    if (targetId === "simple-menu") return;

    this.setState({
      ...this.state,
      anchorEl: null,
    });
    return;
  };

  getProjects() {
    let pinProjects: ProjectObject[] = [],
      privateProjects: ProjectObject[] = [],
      sharedProjects: ProjectObject[] = [],
      publicProjects: ProjectObject[] = [];
    if (this.props.project.state.status !== ProjectStateStatus.SUCCESS)
      return { pinProjects, privateProjects, sharedProjects, publicProjects };
    pinProjects = this.props.project.state.pinnedProjects;
    privateProjects = this.props.project.state.projects.filter(
      (pj) => pj.project_type === ProjectRoleType.SHARE && pj.Users.length === 1
    );
    sharedProjects = this.props.project.state.projects.filter(
      (pj) => pj.project_type === ProjectRoleType.SHARE && pj.Users.length > 1
    );
    publicProjects = this.props.project.state.projects.filter(
      (pj) => pj.project_type === ProjectRoleType.PUBLIC
    );
    return { pinProjects, privateProjects, sharedProjects, publicProjects };
  }

  // TODO : move to sidebar
  renderWorkspaceSubMenuCard(workspace: WorkspaceWithTeamObject, idx: number) {
    const { classes } = this.props;
    if (!workspace) return <></>;

    return (
      <Button
        key={`WorkspaceCardItem-${idx}`}
        onClick={() => {
          this.props.history.push(`/${workspace.name}/main`);
        }}
        style={{
          display: "block",
          padding: 0,
          width: `100%`,
        }}
      >
        <div className={classes.workspaceSubMenuCardInnerDiv}>
          <WorkspaceIcon key={workspace.id} workspace={workspace} index={idx} />
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              width: "calc(100% - 100px)",
            }}
          >
            <RobotoTypography className={classes.workspaceCardTitle}>
              {workspace.name}
            </RobotoTypography>
            <SpoqaHansTypography className={classes.workspaceCardSubTitle}>
              {config.endpoint.WEB_ENDPOINT}
              {workspace.name}/main
            </SpoqaHansTypography>
          </Grid>
        </div>
      </Button>
    );
  }

  // TODO : move to sidebar
  renderAddWorkspace() {
    const { classes } = this.props;

    return (
      <Button
        onClick={() => {
          this.props.history.push(ScreenURL.WORKSPACE_CREATE);
        }}
        style={{
          display: "block",
          padding: 0,
          width: `100%`,
        }}
      >
        <div
          className={classes.workspaceSubMenuCardInnerDiv}
          style={{
            borderTop: `1px solid ${theme.gray_1}`,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <div className={classes.addNewWorkspaceIcon}>
            <SpoqaHansTypography
              style={{
                fontSize: 32,
                color: theme.white,
              }}
            >
              +
            </SpoqaHansTypography>
          </div>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              width: "calc(100% - 100px)",
            }}
          >
            <SpoqaHansTypography
              className={classes.workspaceCardTitle}
              fontWeight="regular"
            >
              {translate.main_sidebar.new_workspace}
            </SpoqaHansTypography>
          </Grid>
        </div>
      </Button>
    );
  }

  getWorkspaceRoleName(role?: WorkspaceRoleType) {
    switch (role) {
      case WorkspaceRoleType.OWNER:
        return translate.main_sidebar.role_type.owner;
      case WorkspaceRoleType.ADMIN:
        return translate.main_sidebar.role_type.admin;
      case WorkspaceRoleType.MEMBER:
        return translate.main_sidebar.role_type.member;
      case WorkspaceRoleType.GUEST:
      default:
        return translate.main_sidebar.role_type.guest;
    }
  }

  onClickSearch() {
    const { workspace } = this.props;

    if (workspace === undefined) return;
    doModalAction(this.props.dispatch, {
      kind: ModalActionKind.TRY_OPEN,
      type: ModalType.SEARCH,
      onSubmit: () => {
        return undefined;
      },
    });
  }

  onClickHelp() {
    doPersistAction(this.props.dispatch, {
      kind: PersistActionKind.TOGGLE_ONBOARD,
    });
  }

  // TODO: extract common used method
  userAuthNoLessThan(
    auth: WorkspaceRoleType,
    defaultUserAuth?: WorkspaceRoleType
  ) {
    const { workspace, token } = this.props;
    const userId =
      token.state.status === TokenStateStatus.SUCCESS && token.state.id;
    const selectedWorkspace =
      workspace.state.status === WorkspaceStateStatus.SUCCESS
        ? workspace.state.selectAuthWorkspace
        : undefined;
    const userAuth = selectedWorkspace?.Users.find((u) => u.id === userId);
    if (userAuth === undefined) return false;

    const inputAuthLevel = getWorkspaceRoleLevel(auth);
    const userAuthLevel = getWorkspaceRoleLevel(
      defaultUserAuth || userAuth.roleType
    );
    return userAuthLevel >= inputAuthLevel;
  }

  isPlanWithInvitePermission() {
    const { workspace } = this.props;
    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS) return;
    const workspacePlan = workspace.state.workspaceWithPlan.WorkspacePlan.plan;
    return (
      getWorkspacePlanLevel(workspacePlan) >=
      getWorkspacePlanLevel(WorkspacePlanType.TEAM)
    );
  }

  // TODO: extract common used method
  onInviteMembers() {
    const { history } = this.props;
    const { workspace_curr } = this.getWorkspaceData();
    if (workspace_curr === undefined) {
      return doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.snackbar.try_again,
      });
    }

    if (this.isPlanWithInvitePermission() === false) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.snackbar.forbidden_plan,
      });
      return history.push(
        ScreenURL.WORKSPACE_UPGRADE.replace(
          ":workspace_name",
          workspace_curr.name
        )
      );
    }
    if (!this.userAuthNoLessThan(WorkspaceRoleType.ADMIN)) {
      return doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.snackbar.not_authorized,
      });
    }

    doModalAction(this.props.dispatch, {
      kind: ModalActionKind.TRY_OPEN,
      type: ModalType.INVITE_WORKSPACE,
      workspace: workspace_curr,
      onSubmit: (members) => {
        doWorkspaceAction(
          this.props.dispatch,
          {
            kind: WorkspaceActionKind.TRY_SHARE_WORKSPACE_EMAILS,
            members,
          },
          () => {
            doSnackbarAction(this.props.dispatch, {
              kind: SnackbarActionKind.TRY_OPEN,
              type: SnackbarType.CONFIRM,
              msg: translate.snackbar.invite_success,
            });
          },
          (err) => {
            this.handleInviteMemberError(err);
          }
        );
      },
    });
    return;
  }
  handleInviteMemberError(err: InternalError) {
    const workspaceError = handleValidateWorkspaceError(err);
    if (workspaceError !== undefined) {
      return doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: workspaceError,
      });
    }
    if (
      err.kind === InternalErrorKind.Abort &&
      err.msg === WorkspaceErrorType.ONLY_ADMIN_OR_OWNER
    ) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.snackbar.workspace.share.fail.above_admin,
      });
      return;
    }
    doSnackbarAction(this.props.dispatch, {
      kind: SnackbarActionKind.TRY_OPEN,
      type: SnackbarType.ALERT,
      msg: translate.snackbar.workspace.share.fail.unknown,
    });
  }

  onNavigateToCreateFolderPage(
    workspaceName: string | undefined,
    userRole: WorkspaceRoleType | undefined
  ) {
    if (
      getWorkspaceRoleLevel(userRole) <=
      getWorkspaceRoleLevel(WorkspaceRoleType.GUEST)
    ) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.snackbar.not_authorized,
      });
      return;
    }
    this.props.history.push(`/${workspaceName}/project/create`);
  }

  getDrawerInnerComponent(workspace_name: string) {
    // TODO: use Map or Set
    const navigations = [
      {
        path: `/${workspace_name}/projects`,
      },
      {
        path: `/${workspace_name}/project/create`,
      },
      {
        path: `/${workspace_name}/ndas`,
      },
      {
        path: `/${workspace_name}/timeline`,
      },
      {
        path: `/${workspace_name}/settings`,
      },
      {
        path: `/${workspace_name}/rule/create`,
      },
    ];
    const currentPathIndex = navigations.findIndex((nav) =>
      this.props.history.location.pathname.includes(nav.path)
    );
    switch (currentPathIndex) {
      case 0:
      case 1: {
        return this.renderFolderInnerDrawerComponent();
      }
      case 2: {
        return <SecurityDrawerInnerComponent />;
      }
      case 3: {
        return this.renderActivityInnerDrawerComponent();
      }
      case 4:
      case 5: {
        return <WorkspaceSettingDrawerInnerComponent />;
      }
      default:
        return <></>;
    }
  }

  async setActivityFilters(activityType?: ActivityType, userId?: string) {
    try {
      await doLogActionAsync(this.props.dispatch, {
        kind: LogActionKind.TRY_SET_ACTIVITY_FILTER,
        activity_filter: { type: activityType, userId },
      });
    } catch (_) {}
  }

  renderActivityInnerDrawerComponent() {
    const { workspace_curr } = this.getWorkspaceData();
    const workspaceUsers =
      workspace_curr?.Users.map(({ profile_cid, name, id }) => {
        return { profile_cid, name, id };
      }) ?? [];
    return (
      <ActivityDrawerInnerComponent
        members={workspaceUsers}
        activity_type={this.props.log.state.activity_filter.type}
        userId={this.props.log.state.activity_filter.userId}
        setActivity={async (activityType, userId) =>
          await this.setActivityFilters(activityType, userId)
        }
      />
    );
  }

  renderFolderInnerDrawerComponent() {
    const { workspace_curr, userRole } = this.getWorkspaceData();
    const { pinProjects, publicProjects, sharedProjects, privateProjects } =
      this.getProjects();
    const userId = this.getUserId();

    return (
      <FolderDrawerInnerComponent
        openFolderEditModal={(pj) => this.openFolderEditModal(pj)}
        navigateToCreateFolderPage={() =>
          this.onNavigateToCreateFolderPage(workspace_curr?.name, userRole)
        }
        userId={userId}
        pinProjects={pinProjects}
        publicProjects={publicProjects}
        sharedProjects={sharedProjects}
        privateProjects={privateProjects}
      />
    );
  }

  render() {
    const { workspace_curr, workspaces } = this.getWorkspaceData();
    const { userProfileCid } = this.getUserProfileCid();
    if (!workspace_curr) return <></>;
    return (
      <Sidebar
        currentWorkspace={workspace_curr}
        workspaces={workspaces}
        drawerInnerComponent={this.getDrawerInnerComponent(workspace_curr.name)}
        onClickSearch={() => this.onClickSearch()}
        onClickHelp={() => this.onClickHelp()}
        onInviteWorkspace={() => this.onInviteMembers()}
        userProfileCid={userProfileCid}
        open={this.props.open}
        setOpen={(state: boolean) => {
          this.props.onSidebarOpen(state);
        }}
      />
    );
  }
}

export default connector(withStyles(styles)(withRouter(MainSidebar)));
