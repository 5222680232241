import T from "@redwit-commons/utils/typecheck";
import {
  TaskObjectWithOutJSON,
  TaskObjectWithoutJSONSchema,
} from "../object/task";
import { GoonoResponse, mkResponseSchema } from "./common";

export type OGetTask = GoonoResponse<TaskObjectWithOutJSON>;

export const OGetTaskSchema = mkResponseSchema(TaskObjectWithoutJSONSchema);

export const validateOGetTask = T.mkValidator<OGetTask>(OGetTaskSchema);
