import React from "react";
import { Tooltip, TooltipProps, makeStyles } from "@material-ui/core";
import { theme } from "@theme";
import clsx from "clsx";

type OverFlowTextTooltipProps = {
  viewOverflow?: boolean;
} & TooltipProps;

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: theme.gray_7_text,
    padding: "8px 12px",
    fontSize: 12,
    borderRadius: 8,
  },
  arrow: {
    color: theme.gray_7_text,
  },
  hidden: {
    display: "none",
  },
});

function OverFlowTextTooltip(props: OverFlowTextTooltipProps) {
  const classes = useStyles();
  const [overflowText, setOverflowText] = React.useState<boolean>(true);
  const { viewOverflow, children, ...rest } = props;

  const onOverflowTooltip = (evt: any) => {
    if (viewOverflow === false || viewOverflow === undefined) return;
    const elem = evt.target as HTMLElement;
    elem.scrollWidth > elem.offsetWidth || elem.scrollHeight > elem.offsetHeight
      ? setOverflowText(true)
      : setOverflowText(false);
  };

  return (
    <Tooltip
      {...rest}
      classes={{
        tooltip: clsx(
          classes.tooltip,
          overflowText ? undefined : classes.hidden
        ),
        arrow: classes.arrow,
      }}
      onOpen={(evt) => {
        onOverflowTooltip(evt);
      }}
    >
      {children}
    </Tooltip>
  );
}

export default OverFlowTextTooltip;
