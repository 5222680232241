export default {
  ko: {
    sign_in: "로그인",
    sign_up: "회원가입",
    logout: "로그아웃",
    back: "돌아가기",
    upgrade_button: "Upgrade Workspace",
    learn_more: "더 알아보기",
    add: "추가",
    sns_platform: {
      google: {
        sign_in: "Google 계정 로그인",
        sign_up: "Google 계정으로 가입",
      },
    },
  },
  en: {
    sign_in: "Sign in",
    sign_up: "Sign Up",
    logout: "Logout",
    back: "return",
    upgrade_button: "Upgrade Workspace",
    learn_more: "Learn more",
    add: "add",
    sns_platform: {
      google: {
        sign_in: "Sign in with Google",
        sign_up: "Sign up with Google",
      },
    },
  },
};
