import translate from "../translate";

export type ActivityType =
  | "Upload"
  | "Share"
  | "download"
  | "Download"
  | "View"
  | "Create"
  | "Invite"
  | "Delete";

export enum FilterActivityKind {
  UPLOAD = "Upload",
  SHARE = "Share",
  DOWNLOAD = "Download",
  VIEW = "View",
  CREATE = "Create",
  INVITE = "Invite",
  DELETE = "Delete",
}

export enum ActivityKind {
  UPLOAD = "Upload",
  SHARE = "Share",
  DOWNLOAD = "Download",
  VIEW = "View",
  CREATE = "Create",
  CREATE_WORKSPACE = "Create Workspace",
  INVITE = "Invite",
  INVITE_WORKSPACE = "Invite Workspace",
  DELETE = "Delete",
  DELETE_FOLDER = "Delete Folder",
}

export type LogActionType =
  | "upload"
  | "verified"
  | "link"
  | "download"
  | "view"
  | "open"
  | "create"
  | "join"
  | "delete";
export enum LogAction {
  UPLOAD = "upload",
  VERIFIED = "verified",
  LINK = "link",
  DOWNLOAD = "download",
  VIEW = "view",
  OPEN = "open",
  CREATE = "create",
  JOIN = "join",
  DELETE = "delete",
}

type LogActionsListType = {
  name: string;
  type?: LogActionType;
};

const LogActionsList: LogActionsListType[] = [
  { name: translate.timeline.all, type: undefined },
  {
    name: translate.timeline.upload,
    type: LogAction.UPLOAD,
  },
  { name: translate.timeline.verified, type: LogAction.VERIFIED },
  { name: translate.timeline.link, type: LogAction.LINK },
  {
    name: translate.timeline.download,
    type: LogAction.DOWNLOAD,
  },
  { name: translate.timeline.view, type: LogAction.VIEW },
  { name: translate.timeline.open, type: LogAction.OPEN },
  { name: translate.timeline.create, type: LogAction.CREATE },
  { name: translate.timeline.join, type: LogAction.JOIN },
  { name: translate.timeline.delete, type: LogAction.DELETE },
];

export default LogActionsList;
