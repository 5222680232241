import UserService from "./user";
import NoteService from "./note";
import ProjectService from "./project";
import SearchService from "./search";
import NotificationService from "./notification";
import TaskService from "./task";
import AuthService from "./auth";
import VersionService from "./version";
import AdminService from "./admin";
import PaymentService from "./payment";
import GithubService from "./github";
import ManagerService from "./manager";
import ContentService from "./content";
import GoogleDriveService from "./google_drive";
import IPFSService from "./ipfs";
import UserLogService from "./user_log";
import GlobalNoteService from "./global_note";
import WorkspaceService from "./workspace";
import GlobalProjectService from "./global_project";
import TimelineService from "./timeline";
import NDAService from "./nda";
import DocumentService from "./document";
import GlobalSearchService from "./global_search";
import StampService from "./stamp";
import NDAContractService from "./nda_contract";
import GlobalChatService from "./global_chat";
import GlobalPaymentService from "./global_payment";
import RuleService from "./rule";
import RuleContractService from "./rule_contract";
import GlobalAdminService from "./global_admin";
import AlarmService from "./alarm";

export default {
  UserService,
  NoteService,
  ProjectService,
  SearchService,
  NotificationService,
  TaskService,
  AuthService,
  VersionService,
  AdminService,
  PaymentService,
  GithubService,
  ManagerService,
  ContentService,
  GoogleDriveService,
  IPFSService,
  UserLogService,
  GlobalNoteService,
  WorkspaceService,
  GlobalProjectService,
  TimelineService,
  NDAService,
  DocumentService,
  GlobalSearchService,
  StampService,
  NDAContractService,
  GlobalChatService,
  GlobalPaymentService,
  RuleService,
  RuleContractService,
  GlobalAdminService,
  AlarmService,
};
