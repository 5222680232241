import { mkResponseSchema, GoonoResponse } from "./common";
import { VersionSchema, Version } from "../object/version";
import T from "@redwit-commons/utils/typecheck";

export type VersionResponse = GoonoResponse<Version>;
export const VersionResponseSchema = mkResponseSchema(VersionSchema);

export type OVersion = VersionResponse;
export const OVersionSchema = VersionResponseSchema;
export const validateOVersion = T.mkValidator<OVersion>(OVersionSchema);
