import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import {
  RuleObject,
  RuleObjectWithContract,
} from "@basalt-commons/global-api/object/rule";
import { ICreateRule } from "@basalt-commons/global-api/request/rule";
import { ICreateRuleContract } from "@basalt-commons/global-api/request/rule_contract";
import {
  WorkspaceWithRule,
  WorkspaceWithRuleContract,
} from "@basalt-commons/global-api/object/workspace";

export enum RuleStateStatus {
  INIT = "RuleState::INIT",
  SUCCESS = "RuleState::SUCCESS",
}

export enum RuleActionKind {
  TRY_CREATE_RULE = "RuleAction::TRY_CREATE_RULE",
  TRY_GET_RULES = "RuleAction::TRY_GET_RULES",
  TRY_UPDATE_RULE = "RuleAction::TRY_UPDATE_RULE",
  TRY_DELETE_RULE = "RuleAction::TRY_DELETE_RULE",
  TRY_CREATE_RULE_CONTRACT = "RuleAction::TRY_CREATE_RULE_CONTRACT",
  TRY_GET_RULE_CONTRACT = "RuleAction::TRY_GET_RULE_CONTRACT",
  TRY_GET_RULE_CONTRACT_LIST = "RuleAction::TRY_GET_RULE_CONTRACT_LIST",
  TRY_GET_WORKSPACE_RULE_LIST = "RuleAction::TRY_GET_WORKSPACE_RULE_LIST",
  TRY_GET_ALL_SIGNED_RULES = "RuleAction::TRY_GET_ALL_SIGNED_RULES",
  TRY_CHECK_CONTRACT_REQUIRED = "RuleAction::TRY_CHECK_CONTRACT_REQUIRED",
  TRY_DOWNLOAD_ALL_CONTRACT = "RuleAction::TRY_DOWNLOAD_ALL_CONTRACT",
}

export type RuleError = never;

export enum RuleErrorType {
  ONLY_ADMIN_OR_HIGHER = "RuleErrorType::ONLY_ADMIN_OR_HIGHER",
}

export type RuleState =
  | {
      readonly status: RuleStateStatus.INIT;
      readonly workspacesWithRule?: WorkspaceWithRule[];
      readonly workspacesWithRuleContract?: WorkspaceWithRuleContract[];
    }
  | {
      readonly status: RuleStateStatus.SUCCESS;
      readonly rules: RuleObject[];
      readonly rulesWithContractList: RuleObjectWithContract[];
      readonly workspacesWithRule?: WorkspaceWithRule[];
      readonly workspacesWithRuleContract?: WorkspaceWithRuleContract[];
      readonly needContract?: string[];
      readonly filePath?: string;
    };

export type RuleAction =
  | {
      readonly kind: RuleActionKind.TRY_GET_RULES;
    }
  | {
      readonly kind: RuleActionKind.TRY_CREATE_RULE;
      readonly args: ICreateRule;
    }
  | {
      readonly kind: RuleActionKind.TRY_UPDATE_RULE;
      readonly ruleId: string;
      readonly required: boolean;
    }
  | {
      readonly kind: RuleActionKind.TRY_DELETE_RULE;
      readonly ruleId: string;
    }
  | {
      readonly kind: RuleActionKind.TRY_CREATE_RULE_CONTRACT;
      readonly ruleId: string;
      readonly args: ICreateRuleContract;
    }
  | {
      readonly kind: RuleActionKind.TRY_GET_RULE_CONTRACT;
      readonly ruleId: string;
    }
  | {
      readonly kind: RuleActionKind.TRY_GET_RULE_CONTRACT_LIST;
    }
  | {
      readonly kind: RuleActionKind.TRY_GET_WORKSPACE_RULE_LIST;
    }
  | {
      readonly kind: RuleActionKind.TRY_GET_ALL_SIGNED_RULES;
    }
  | {
      readonly kind: RuleActionKind.TRY_CHECK_CONTRACT_REQUIRED;
    }
  | {
      readonly kind: RuleActionKind.TRY_DOWNLOAD_ALL_CONTRACT;
      readonly workspaceId: string;
      readonly RuleId?: string;
    };

const smid = "Rule_STATE_MACHINE3";
export type RuleStateMachineType = StateMachine3<
  RuleStateStatus,
  RuleState,
  RuleActionKind,
  RuleAction,
  RuleError
>;
export const RuleStateMachine: RuleStateMachineType = new StateMachine3<
  RuleStateStatus,
  RuleState,
  RuleActionKind,
  RuleAction,
  RuleError
>(smid, { status: RuleStateStatus.INIT }, [
  transition(
    RuleStateStatus.INIT,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_GET_RULES
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_GET_RULES
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_CREATE_RULE
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_UPDATE_RULE
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_DELETE_RULE
  ),
  transition(
    RuleStateStatus.INIT,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_CREATE_RULE_CONTRACT
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_CREATE_RULE_CONTRACT
  ),
  transition(
    RuleStateStatus.INIT,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_GET_RULE_CONTRACT
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_GET_RULE_CONTRACT
  ),
  transition(
    RuleStateStatus.INIT,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_GET_RULE_CONTRACT_LIST
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_GET_RULE_CONTRACT_LIST
  ),
  transition(
    RuleStateStatus.INIT,
    RuleStateStatus.INIT,
    RuleActionKind.TRY_GET_WORKSPACE_RULE_LIST
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_GET_WORKSPACE_RULE_LIST
  ),
  transition(
    RuleStateStatus.INIT,
    RuleStateStatus.INIT,
    RuleActionKind.TRY_GET_ALL_SIGNED_RULES
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_GET_ALL_SIGNED_RULES
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_CHECK_CONTRACT_REQUIRED
  ),
  transition(
    RuleStateStatus.SUCCESS,
    RuleStateStatus.SUCCESS,
    RuleActionKind.TRY_DOWNLOAD_ALL_CONTRACT
  ),
]);

export type DispatchRuleAction = Dispatch<
  StateMachineAction<
    RuleStateStatus,
    RuleState,
    RuleActionKind,
    RuleAction,
    RuleError
  >
>;
export default mkReducer<
  RuleStateStatus,
  RuleState,
  RuleActionKind,
  RuleAction,
  RuleError
>(RuleStateMachine);

export const doRuleAction = (
  dispatch: DispatchRuleAction,
  nextAction: RuleAction,
  onResolve: () => void = () => {},
  onReject: (err: RuleError | InternalError) => void = () => {}
) => {
  dispatch(RuleStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doRuleActionAsync = (
  dispatch: DispatchRuleAction,
  nextAction: RuleAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(RuleStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetRule = (dispatch: DispatchRuleAction) => {
  dispatch(RuleStateMachine.newResetAction());
};
