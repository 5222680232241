export default {
  ko: {
    search: {
      header_ko: "에 대한 검색 결과",
      header_ko_sm: "검색 결과",
      header_en: "",
      no_results: "검색 결과가 없습니다.",
      workspace_res_ko1: "내의 총 검색 결과",
      workspace_res_ko2: "개",
      workspace_res_en1: "",
      see_more: "더보기",
      no_results_small: "결과 없음",
      menu_text: "개요",
      share: "공유",
      other_name: "다른 이름을 검색해주세요",
      all: {
        title: "전체 결과",
        result_count_prefix: "총",
        result_count_postfix: "개",
      },
      hashtags: {
        title: "태그",
        search: "태그 검색",
      },
      ocr: {
        title: "OCR",
        search: "OCR 검색",
      },
      folders: {
        title: "폴더",
        search: "폴더 검색",
      },
      files: {
        title: "파일",
        search: "파일 검색",
      },
    },
  },

  en: {
    search: {
      menu_text: "Overview",
      header_ko: "",
      header_ko_sm: "",
      header_en: "Results of",
      no_results: "There are no search results.",
      workspace_res_ko1: "",
      workspace_res_ko2: "",
      workspace_res_en1: "search results within",
      see_more: "See more",
      no_results_small: "No Results Found",
      other_name: "Please search another name",
      share: "Share",
      all: {
        title: "All Results",
        result_count_prefix: "",
        result_count_postfix: "found",
      },
      hashtags: {
        title: "Tags",
        search: "Search for tags",
      },
      ocr: {
        title: "OCRs",
        search: "Search for OCRs",
      },
      folders: {
        title: "Folders",
        search: "Search for folder",
      },
      files: {
        title: "Files",
        search: "Search for file",
      },
    },
  },
};
