import service_header from "./service_header";
import folder from "./folder";
import service_sidebar from "./service_sidebar";
import file from "./file";
import search_note from "./search_note";
import link from "./link";
import log from "./log";
import file_preview from "./file_preview";
import comment from "./comment";

export default {
  ko: {
    ...service_header.ko,
    ...folder.ko,
    ...service_sidebar.ko,
    ...file.ko,
    ...search_note.ko,
    ...link.ko,
    ...log.ko,
    ...file_preview.ko,
    ...comment.ko,
  },
  en: {
    ...service_header.en,
    ...folder.en,
    ...service_sidebar.en,
    ...file.en,
    ...search_note.en,
    ...link.en,
    ...log.en,
    ...file_preview.en,
    ...comment.en,
  },
};
