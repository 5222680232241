import { createStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";
import {
  BaseHeaderHeightXS,
  BaseSideBarWidth,
  ServiceHeaderHeightXL,
  ServiceHeaderSidePaddingXL,
  ServiceHeaderSidePaddingXS,
} from "../../utils/templates/styles/BaseGrid";

const styles = (MuiTheme: Theme) =>
  createStyles({
    root: {
      [MuiTheme.breakpoints.up(600)]: {
        display: "none",
      },
      height: ServiceHeaderHeightXL,
      backgroundColor: "white",
      paddingLeft: ServiceHeaderSidePaddingXL,
      paddingRight: ServiceHeaderSidePaddingXL,
      borderBottom: `1px solid ${theme.gray_2}`,
      zIndex: 1,
      position: "sticky",
      top: 0,
      [MuiTheme.breakpoints.down(theme.breakSmall)]: {
        height: BaseHeaderHeightXS,
        boxShadow: "0 0.8px 0.8px 1.1px rgba(73, 80, 87, 0.02)",
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    basaltLogoGrid: { width: BaseSideBarWidth - ServiceHeaderSidePaddingXL },
    basaltLogoButton: {
      height: 56,
      width: 170,
      [MuiTheme.breakpoints.down("md")]: {
        width: 120,
      },
    },
    basaltIcon: {
      width: 170,
      height: 24,
      objectFit: "cover",
      [MuiTheme.breakpoints.down("md")]: {
        width: 120,
        height: 17,
        objectFit: "cover",
      },
    },
    searchGrid: {
      height: "100%",
      flex: 1,
      paddingLeft: 30,
      paddingRight: 10,
    },
    searchGrid2: {
      width: "calc(100% - 120px - 318px)",
      maxWidth: 658,
      minWidth: 318,
      height: ServiceHeaderHeightXL - 24,
      borderRadius: 8,
      backgroundColor: theme.gray_2,
      paddingLeft: 10,
    },
    searchInput: {
      width: "80%",
      height: 40,
      borderRadius: 8,
      color: theme.gray_9_title,
      marginTop: 2,
    },
    searchIcon: { color: theme.gray_7_text, ...theme.font20 },
    searchPopover: {
      zIndex: 999,
      padding: 14,
      marginLeft: -14,
      ...theme.shadow_2,
      border: `1px solid ${theme.gray_2}`,
      marginTop: 10,
      height: ServiceHeaderHeightXL - 24 + 270,
      overflow: "auto",
      backgroundColor: theme.white,
      position: "absolute",
    },
    searchPopoverGrid: {
      width: "100%",
      height: ServiceHeaderHeightXL - 24,
      backgroundColor: theme.gray_2,
      borderRadius: 8,
      paddingLeft: 10,
    },
    searchInputPopover: {
      width: "99%",
      height: 40,
      borderRadius: 8,
      color: theme.gray_9_title,
      marginTop: 2,
      marginBottom: 8,
    },
    recent_searches_title: {
      ...theme.font14,
      color: theme.gray_6,
      marginTop: 8,
    },
    recent_searches_text: {
      ...theme.font14,
      color: theme.black,
      marginTop: 5,
      marginBottom: 5,
    },
    folders: {
      ...theme.font14,
      color: theme.gray_6,
      margin: 4,
      marginTop: 20,
    },
    emptySpace: { height: 12, width: "100%" },
    rightGrid: {
      backgroundColor: "white",
      // height: "100%",
    },

    loginButton: {
      height: 44,
      padding: 6,
      textTransform: "none",
      marginRight: 18,
    },
    loginButtonText: { fontSize: 16, color: theme.black },
    signupButtonText: { fontSize: 16 },
    upgradeButton: {
      marginRight: 20,
    },
    xs_btn: {
      width: 32,
      height: 32,
      marginRight: 12,
    },
    xs_profile_btn: {
      width: 32,
      height: 32,
      [MuiTheme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    xs_icon: {
      fontSize: 24,
      color: "#495057",
    },
    logo_grid: {
      width: `calc(100% - ${ServiceHeaderSidePaddingXS * 2}px - 128px)`,
      height: 48,
    },
    account_gird: {
      width: 136,
      marginLeft: "auto",
    },
    header_nav_icon_button: {
      ...theme.icon_40,
      margin: 4,
    },
    header_nav_icon: {
      ...theme.icon_24,
      margin: 8,
    },
    search_icon: {
      color: theme.gray_7_text,
    },
    person_icon_background: {
      ...theme.icon_32,
      backgroundColor: theme.cian_4,
      borderRadius: "50%",
    },
    person_icon: {
      ...theme.icon_20,
      marginTop: 6,
      marginLeft: 6,
    },
    profile_image: {
      ...theme.icon_32,
      objectFit: "cover",
      borderRadius: "50%",
    },
  });

export default styles;
