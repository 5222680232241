import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "../../api/object/access_time";
import { UserObject, UserObjectSchema } from "../../api/object/user";

export enum DocumentStatus {
  ACTIVE = "DocumentStatus:ACTIVE",
  INACTIVE = "DocumentStatus:INACTIVE",
}

export type DocumentOption = {
  readonly chunks?: boolean /** @deparcated 삭제 가능한 필드 */;
  readonly allow_download?: boolean;
  readonly allow_print?: boolean;
  readonly require_email?: boolean;
  readonly require_nda?: boolean;
};

export type DocumentNdaOption = {
  readonly nda_contractor_name?: string;
  readonly nda_contractor_department?: string;
  readonly nda_contractor_sign_cid?: string;
  readonly nda_contractor_sign_extension?: string;
};

export type DocumentObjectCore = {
  readonly id: string;
  readonly title: string;
  readonly description?: string /** @deparcated 삭제 가능한 필드 */;
  readonly status: DocumentStatus;
  readonly file_name: string;
  readonly cid: string;
  readonly extension: string;
  readonly expires_in: string;
  readonly link_url?: string;
  readonly UserId: string;
  readonly NoteId: string;
  readonly ProjectId: string;
  readonly WorkspaceId: string;
} & DocumentOption &
  DocumentNdaOption;

export type DocumentObject = DocumentObjectCore & AccessTime;

export const DocumentObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("title", T.string())
  .addField("description", T.string(), false)
  .addField(
    "status",
    T.string().withEnum([DocumentStatus.ACTIVE, DocumentStatus.INACTIVE])
  )
  .addField("file_name", T.string())
  .addField("cid", T.string())
  .addField("extension", T.string())
  .addField("expires_in", T.string())
  .addField("link_url", T.string(), false)
  .addField("UserId", T.string())
  .addField("NoteId", T.string())
  .addField("ProjectId", T.string())
  .addField("WorkspaceId", T.string())
  .addField("chunks", T.boolean(), false)
  .addField("allow_download", T.boolean(), false)
  .addField("allow_print", T.boolean(), false)
  .addField("require_email", T.boolean(), false)
  .addField("require_nda", T.boolean(), false)
  .addField("nda_contractor_name", T.string(), false)
  .addField("nda_contractor_department", T.string(), false)
  .addField("nda_contractor_sign_cid", T.string(), false)
  .addField("nda_contractor_sign_extension", T.string(), false);

export const extractDocumentObject =
  T.mkObjectExtractor<DocumentObject>(DocumentObjectSchema);
export const validateDocumentObject =
  T.mkValidator<DocumentObject>(DocumentObjectSchema);

export type DocumentWithUserObject = DocumentObject & { User: UserObject };
export const DocumentWithUserObjectSchema =
  DocumentObjectSchema.clone().addField("User", UserObjectSchema.clone());

export const extractDocumentWithUserObject =
  T.mkObjectExtractor<DocumentWithUserObject>(DocumentWithUserObjectSchema);
export const validateDocumentWithUserObject =
  T.mkValidator<DocumentWithUserObject>(DocumentWithUserObjectSchema);
