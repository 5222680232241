import { ScreenURL } from "./RouteList";
import { RouteProps } from "react-router-dom";
import { lazy } from "react";

const routes_landing: Array<RouteProps> = [
  {
    path: ScreenURL.LANDING_MAIN,
    exact: true,
    component: lazy(() => import("../screens/LandingMainScreen")),
  },
  {
    path: ScreenURL.LANDING_SERVICE,
    exact: true,
    component: lazy(() => import("../screens/LandingServiceIntroScreen2")),
  },
  {
    path: ScreenURL.SIGN_UP,
    exact: true,
    component: lazy(() => import("../screens/SignUpScreen")),
  },
  {
    path: ScreenURL.SIGN_UP_EMAIL_SENT,
    exact: true,
    component: lazy(() => import("../screens/SignUpEmailSentScreen")),
  },
  {
    path: ScreenURL.SIGN_IN,
    exact: true,
    component: lazy(() => import("../screens/SignInScreen")),
  },

  {
    path: ScreenURL.LANDING_CALLBACK,
    exact: true,
    component: lazy(() => import("../screens/LandingCallbackScreen")),
  },
  {
    path: ScreenURL.REGISTER_EMAIL,
    exact: true,
    component: lazy(() => import("../screens/RegisterEmailScreen")),
  },
  {
    path: ScreenURL.REGISTER_SNS,
    exact: true,
    component: lazy(() => import("../screens/RegisterSNSScreen")),
  },
  {
    path: ScreenURL.CONTACT,
    exact: true,
    component: lazy(() => import("../screens/LandingContactScreen")),
  },

  {
    path: ScreenURL.PW_UPDATE,
    exact: true,
    component: lazy(() => import("../screens/PWUpdateScreen")),
  },
  {
    path: ScreenURL.PW_UPDATE_EMAIL,
    exact: true,
    component: lazy(() => import("../screens/PWVerificationScreen")),
  },
  {
    path: ScreenURL.PDF_VIEWER,
    exact: true,
    component: lazy(() => import("../screens/ViewerScreen")),
  },
  {
    path: ScreenURL.LANDING_PRICING,
    exact: true,
    component: lazy(() => import("../screens/ProductScreen")),
  },
  {
    path: ScreenURL.DOCUMENTS,
    exact: true,
    component: lazy(() => import("../screens/DocumentScreen")),
  },

  {
    path: ScreenURL.LANDING_SOLUTION,
    exact: true,
    component: lazy(() => import("../screens/LandingSolutionScreen")),
  },
  {
    path: ScreenURL.NDA_CONTRACT_PREVIEW_GUEST,
    exact: true,
    component: lazy(() => import("../screens/NDAPreviewScreen")),
  },
];

const routes_error: Array<RouteProps> = [];

const routes_service: Array<RouteProps> = [
  {
    path: ScreenURL.PURCHASE,
    exact: true,
    component: lazy(() => import("../screens/PurchaseScreen")),
  },
  {
    path: ScreenURL.SERVICE_MAIN,
    exact: true,
    component: lazy(() => import("../screens/ServiceMainScreen")),
  },
  {
    path: ScreenURL.WORKSPACE_UPGRADE,
    exact: true,
    component: lazy(() => import("../screens/ProductScreen")),
  },
  {
    path: ScreenURL.WORKSPACE,
    exact: true,
    component: lazy(() => import("../screens/WorkspaceScreen")),
  },
  {
    path: ScreenURL.WORKSPACE_SETTINGS,
    exact: true,
    component: lazy(() => import("../screens/WorkspaceSettingsRouter")),
  },
  {
    path: ScreenURL.RULE_CREATE,
    exact: true,
    component: lazy(() => import("../screens/RuleCreateScreen")),
  },
  {
    path: ScreenURL.RULE_SIGN,
    exact: true,
    component: lazy(() => import("../screens/RuleSignScreen")),
  },
  {
    path: ScreenURL.WORKSPACE_CREATE,
    exact: true,
    component: lazy(() => import("../screens/WorkspaceCreateScreen")),
  },
  {
    path: ScreenURL.WORKSPACE_INVITE,
    exact: true,
    component: lazy(() => import("../screens/WorkspaceInviteScreen")),
  },
  {
    path: ScreenURL.WORKSPACE_SEARCH,
    exact: true,
    component: lazy(() => import("../screens/WorkspaceSearchScreen")),
  },
  {
    path: ScreenURL.SEARCH_WORKSPACE,
    exact: true,
    component: lazy(() => import("../screens/SearchResultScreen")),
  },
  {
    path: ScreenURL.SEARCH_ALL,
    exact: true,
    component: lazy(() => import("../screens/SearchResultScreen")),
  },
  {
    path: ScreenURL.WORKSPACE_KNOCK,
    exact: true,
    component: lazy(() => import("../screens/WorkspaceKnockScreen")),
  },
  {
    path: ScreenURL.TIMELINE,
    exact: true,
    component: lazy(() => import("../screens/ActivityScreen")),
  },
  {
    path: ScreenURL.PROJECT,
    exact: true,
    component: lazy(() => import("../screens/ProjectScreen")),
  },
  {
    path: ScreenURL.PROJECT_CREATE,
    exact: true,
    component: lazy(() => import("../screens/ProjectCreateScreen")),
  },
  {
    path: ScreenURL.FOLDER_INSIDE,
    exact: true,
    component: lazy(() => import("../screens/FolderInsideDisplay")),
  },
  {
    path: ScreenURL.PROJECT_SETTINGS,
    exact: true,
    component: lazy(() => import("../screens/ProjectSettingsScreen")),
  },
  {
    path: ScreenURL.NOTE_PREVIEW_TAB,
    exact: true,
    component: lazy(() => import("../screens/NewNotePreviewScreen")),
  },
  {
    path: ScreenURL.LINK_CREATE,
    exact: true,
    component: lazy(() => import("../screens/LinkCreateScreen")),
  },
  {
    path: ScreenURL.STAMP_CREATE,
    exact: true,
    component: lazy(() => import("../screens/StampCreateScreen")),
  },

  {
    path: ScreenURL.SEND_EMAIL,
    exact: true,
    component: lazy(() => import("../screens/SendEmailScreen")),
  },
  {
    path: ScreenURL.LINK_DONE,
    exact: true,
    component: lazy(() => import("../screens/LinkDoneScreen")),
  },
  {
    path: ScreenURL.NDA,
    exact: true,
    component: lazy(() => import("../screens/NDAScreen")),
  },
  {
    path: ScreenURL.NDA_PREVIEW,
    exact: true,
    component: lazy(() => import("../screens/NDAPreviewScreen")),
  },
  {
    path: ScreenURL.NDA_CONTRACT_PREVIEW,
    exact: true,
    component: lazy(() => import("../screens/NDAPreviewScreen")),
  },
  {
    path: ScreenURL.RULE_CONTRACT_PREVIEW,
    exact: true,
    component: lazy(() => import("../screens/NDAPreviewScreen")),
  },
  {
    path: ScreenURL.NDA_CREATE,
    exact: true,
    component: lazy(() => import("../screens/NDACreateScreen")),
  },
  {
    path: ScreenURL.ACCOUNT_SETTING,
    exact: true,
    component: lazy(() => import("../screens/AccountSettingScreen")),
  },
  {
    path: ScreenURL.ACCOUNT_SECURITY_PLEDGE,
    exact: true,
    component: lazy(() => import("../screens/AccountSecurityPledgeScreen")),
  },
];

export { ScreenURL, routes_landing, routes_service, routes_error };
