import { doAPIPostRequest } from "./api";
import { ICreateUserLog } from "@basalt-commons/api/request/user_log";

const createUserLog = async (token: string, args: ICreateUserLog) => {
  await doAPIPostRequest("/user_log", token, args);
  return;
};

export default {
  createUserLog,
};
