export default {
  ko: {
    file_preview: {
      page: "페이지",
      avg_time: "소요 시간 (평균치)",
      min: "min",
      sec: "sec",
      no_visitor: "아직 링크를 방문한 방문자가 없습니다!",
      no_data: "아직 데이터가 없습니다",
      only_pdf: "PDF 파일만 지원하는 기능입니다",
    },
  },

  en: {
    file_preview: {
      page: "PAGE",
      avg_time: "TIME SPENT (AVG)",
      min: "min",
      sec: "sec",
      no_visitor: "There's no visitors on link",
      no_data: "There's no datas",
      only_pdf: "Support only PDF",
    },
  },
};
