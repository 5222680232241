import { mixPanelTrackEvent, mixPanelTrackIdentify } from "./mixpanel-raw-api";
import type { CreateLinkParam } from "../marketing-flow/use-create-link-effect";
import { detect } from "detect-browser";

export const mixPanelApi = {
  async identifyUser(auth: any) {
    if (!auth.name) return;
    let browser = detect();
    let user_name = auth.name;
    let user_property = {
      name: auth.name,
      sigup_date: auth.createdAt.split("T")[0],
      platform_type: browser?.os,
    };
    await mixPanelTrackIdentify(user_name, user_property);
  },
  async login(platform: string) {
    await mixPanelTrackEvent({
      eventName: "signIn_completed",
      object: {
        method: platform,
      },
    });
  },
  async signIn(platform: string) {
    await mixPanelTrackEvent({
      eventName: "signUp_completed",
      object: {
        method: platform,
      },
    });
  },
  async emailVerification() {
    await mixPanelTrackEvent({
      eventName: "signUp_emailVerified",
      object: null,
    });
  },
  async spaceCreate(isPublic: boolean) {
    await mixPanelTrackEvent({
      eventName: "createWs_completed",
      object: {
        public: isPublic,
      },
    });
  },
  async folderCreate(isPublic: boolean) {
    await mixPanelTrackEvent({
      eventName: "createFolder_completed",
      object: {
        public: isPublic,
      },
    });
  },
  async fileUpload() {
    await mixPanelTrackEvent({
      eventName: "uploadFile_completed",
      object: null,
    });
  },
  async linkCreateTry() {
    await mixPanelTrackEvent({
      eventName: "shareLinkCreate_click_start",
      object: null,
    });
  },
  async linkCreate(param: CreateLinkParam) {
    await mixPanelTrackEvent({
      eventName: "shareLinkCreate_complete",
      object: param,
    });
  },
  async ndaUpload() {
    await mixPanelTrackEvent({
      eventName: "uploadNda_completed",
      object: null,
    });
  },
  async signatureUpload() {
    await mixPanelTrackEvent({
      eventName: "signCreate_completed",
      object: null,
    });
  },
  async linkSend() {
    await mixPanelTrackEvent({
      eventName: "shareLink_send_mail",
      object: null,
    });
  },
};
