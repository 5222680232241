import { Grid, GridProps, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { BaseAppBarWidth, BaseSideBarWidth } from "./styles/BaseGrid";
import LoadingView from "../../components/pure/view/LoadingView";
import FailView from "../../components/pure/view/FailView";
import ScreenLoadingView from "../../components/pure/view/ScreenLoadingView";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "../../routes/RouteList";
import MainSidebar from "../../navs/MainSidebar";
import ServiceHeader from "../../navs/ServiceHeader";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { theme } from "@theme";
import { RootState } from "@src/store/reducers";
import {
  BaseGridActionKind,
  doBaseGridAction,
  BaseErrorArgs,
  BaseGridMode,
} from "@src/store/reducers/basegrid";
import { WindowSizeHandler } from "./handler";

const useStyles = makeStyles((MuiTheme) => ({
  root: {
    minWidth: "fit-content",
    // height: "100vh",
    // overflowY: "auto",
    // overflowX: "hidden",
    backgroundColor: theme.white,
    [MuiTheme.breakpoints.down(600)]: {
      marginLeft: 0,
    },
    [MuiTheme.breakpoints.between(600, 1024)]: {
      marginLeft: BaseAppBarWidth,
    },
    [MuiTheme.breakpoints.up(1024)]: {
      marginLeft: BaseSideBarWidth,
    },
  },
  open_sidebar: {
    height: "100vh",
    overflowY: "hidden",
  },
  no_sidebar: {
    marginLeft: 0,
  },
}));

type BaseGridProps = {
  onChangeWindow?: (
    width: number,
    height: number,
    mobileMode?: boolean
  ) => void;
};

/**
 * BaseGridWindowSizeState 는 screen에서 용이하게 windowSizeState type을 사용하기 위함입니다
 * WindowWidth, WindowHeight, MobileMode field를 보유합니다
 * 호출 시 반드시 state에 BaseGridDefalutState를 포함시켜주세요 (초기세팅)
 */
export type BaseGridWindowSizeState = {
  WindowWidth: number;
  WindowHeight: number;
};

export const BaseGridDefaultState: BaseGridWindowSizeState = {
  WindowHeight: window.innerHeight,
  WindowWidth: window.innerWidth,
};

/**
 * 통합 BaseGrid Component
 * BaseGrid는 크게 3가지의 역할을 합니다
 * 1) screen render type에 따른 render 변화
 * 2) token state에 따른 grid 제공
 * (TODO) 3-1) token state에 따른 sidebar or header 제공
 * @param param0 GridProps & BaseGridProps
 * @returns
 */
export const BaseGrid: React.FC<GridProps & BaseGridProps> = ({
  children,
  onChangeWindow,
  ...rest
}) => {
  const dispatch = useDispatch();
  const basegrid = useSelector((store: RootState) => store.basegrid);
  const { screenMode, sidebar, header, search, landingMode } = basegrid.state;
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const history = useHistory();
  const classes = useStyles();
  const sizeHandler = WindowSizeHandler(onChangeWindow);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (basegrid.state.screenMode.mode !== BaseGridMode.SUCCESS) {
        doBaseGridAction(dispatch, {
          kind: BaseGridActionKind.TRY_RESET,
        });
      }
    });
    () => {
      unlisten();
    };
  }, [basegrid]);

  /**
   * screen의 loading 화면을 담당함
   * @returns React.Component
   */
  const loadingView = (type?: "full" | "overlay") => {
    if (type === "full" || type === undefined) return <LoadingView />;
    else
      return (
        <ScreenLoadingView
          width={sizeHandler.getWindowWidth()}
          height={sizeHandler.getWindowHeight()}
        />
      );
  };

  /**
   * screen의 failed 화면을 담당함
   * @param failArgs {code?: number, action?: (onPressHome: () => void , onPressPrev: () => void)}
   * @returns React.Component
   */
  const failedView = (failArgs: BaseErrorArgs) => {
    return (
      <FailView
        errorCode={failArgs.code}
        onPressHome={() => {
          history.replace(ScreenURL.LANDING_MAIN);
        }}
        onPressPrev={() => {
          history.goBack();
        }}
        height={sizeHandler.getWindowHeight()}
      />
    );
  };

  const Header = () =>
    header !== false && landingMode !== true ? (
      <ServiceHeader
        showSearchInput={search}
        onOpenSidebar={() => {
          setSidebarOpen((prevState) => !prevState);
        }}
      />
    ) : (
      <div />
    );
  const Sidebar = () =>
    sidebar !== false && landingMode !== true ? (
      <MainSidebar
        open={
          sizeHandler.getWindowWidth() < 1024 === true ? sidebarOpen : undefined
        }
        onSidebarOpen={(state: boolean) => {
          setSidebarOpen(state);
        }}
      />
    ) : (
      <div />
    );

  // render type 에 따른 view 분류

  if (screenMode?.mode === BaseGridMode.LOADING) return loadingView();
  else if (screenMode?.mode === BaseGridMode.FAILED)
    return failedView({ ...screenMode });
  else {
    return (
      <>
        <Sidebar />
        <Grid
          {...rest}
          className={
            landingMode
              ? ""
              : clsx(
                  classes.root,
                  sidebarOpen === true && classes.open_sidebar,
                  sidebar === false && classes.no_sidebar
                )
          }
        >
          <Header />
          {children}
        </Grid>
        {screenMode?.state === "loading" && loadingView("overlay")}
      </>
    );
  }
};
