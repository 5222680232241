import React from "react";
import { Grid, InputBase, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ButtonWithIcon from "../../components/pure/buttons/ButtonWithIcon";
import SpoqaHansTypography from "../../components/pure/typo/SpoqaHansTypography";
import { headers, theme } from "@theme";
import translate from "@src/utils/translate";
import { NDA_TAB_KIND, TabState } from ".";
import clsx from "clsx";
import LinearLoadingWrap from "@src/components/pure/utils/LoadingWrap/LinearLoadingWrap";
import FixedTitle from "@src/components/pure/utils/FixedTitle";

const useStyles = makeStyles((MuiTheme) => ({
  title_row: {
    height: 88,

    [MuiTheme.breakpoints.only("xs")]: {
      height: 68,
    },
  },
  title: {
    ...headers.h6,
  },
  input_search: {
    width: "100%",
    maxWidth: 246,
    border: `solid 1px ${theme.gray_3}`,
    borderRadius: 8,
    ...theme.font14,
    ...theme.spoqahans_regular,
    padding: "7px 11px",
  },
  icon_search: {
    width: 24,
    height: 24,
    marginRight: 4,
    color: theme.gray_5,
  },
  input: {
    color: theme.black,
    padding: 0,
    height: 20,
    lineHeight: "20px",
    "&::placeholder": {
      height: 20,
      lineHeight: "20px",
      color: theme.gray_5,
      opacity: 1,
    },
  },
  mobile: {
    [MuiTheme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
}));

type ScreenTitleViewProps = {
  tab: TabState;
  searchWord: string;
  disabled: boolean;
  ndaStatus: "loading" | "fetched";
  onInputSearchWord: (inpu: string) => void;
  onClickStampAddBtn: () => void;
  onClickSignedDownloadBtn: () => void;
};

const ScreenTitleView = ({
  tab,
  searchWord,
  disabled,
  ndaStatus,
  onInputSearchWord,
  onClickStampAddBtn,
  onClickSignedDownloadBtn,
}: ScreenTitleViewProps) => {
  const classes = useStyles();
  return (
    <FixedTitle bgHeight={138} xsBgHeight={138}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.title_row}
      >
        <LinearLoadingWrap loading={ndaStatus === "loading"}>
          <SpoqaHansTypography fontWeight="bold" className={classes.title}>
            {tab === NDA_TAB_KIND.NDA
              ? translate.ndas.tab_titles.nda
              : tab === NDA_TAB_KIND.SIGNED
              ? translate.ndas.tab_titles.signed
              : translate.ndas.tab_titles.stamp}
          </SpoqaHansTypography>
          <InputBase
            className={clsx(classes.input_search, classes.mobile)}
            value={searchWord}
            onChange={(evt) => {
              onInputSearchWord(evt.currentTarget.value);
            }}
            placeholder={
              tab === NDA_TAB_KIND.NDA
                ? translate.ndas.search_placeholder
                : tab === NDA_TAB_KIND.STAMP
                ? translate.company_signature.search_placeholder
                : translate.all_signs.search_placeholder
            }
            startAdornment={<SearchIcon className={classes.icon_search} />}
            inputProps={{ className: classes.input }}
          />
        </LinearLoadingWrap>
      </Grid>
      <Grid className={classes.mobile}>
        {tab === NDA_TAB_KIND.NDA ? (
          <ButtonWithIcon
            buttonType="primary"
            htmlFor="upload-nda-file"
            icon="add"
          >
            {translate.ndas.update}
          </ButtonWithIcon>
        ) : tab === NDA_TAB_KIND.STAMP ? (
          <ButtonWithIcon
            buttonType="primary"
            disabled={disabled}
            icon="add"
            onClick={() => onClickStampAddBtn()}
          >
            {translate.company_signature.new}
          </ButtonWithIcon>
        ) : (
          <ButtonWithIcon
            buttonType="primary"
            disabled={disabled}
            icon="download"
            onClick={() => onClickSignedDownloadBtn()}
          >
            {translate.all_signs.download}
          </ButtonWithIcon>
        )}
      </Grid>
    </FixedTitle>
  );
};

export default ScreenTitleView;
