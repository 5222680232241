import React from "react";
import {
  Grid,
  Collapse,
  WithStyles,
  withStyles,
  Avatar,
  ButtonBase,
} from "@material-ui/core";
import SpoqaHansTypography from "@SpoqaHansTypography";
import NDASignatureItem from "./NDASignatureItem";
import { theme } from "@theme";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import { ReactComponent as SignatureIcon } from "@ui/basalt/images/note/nav_draw.svg";
import { ReactComponent as ConnectedFileIcon } from "@ui/basalt/images/note/nav_addFile.svg";
import { NdaObject } from "@basalt-commons/global-api/object/nda";
import moment from "moment-timezone";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";
import { ContractObject } from "@basalt-commons/global-api/object/nda_contract";
import styles from "./styles/NDAItem";
import { RouteComponentProps, withRouter } from "react-router-dom";
import translate from "../../../utils/translate";
import NDACardItemMenu from "@src/screens/NDAScreen/NDACardItemMenu";
import OverFlowTextTooltip from "@src/components/pure/utils/OverFlowTextTooltip";

type Props = {};

type NDAItemProps = {
  index: number;
  hasAuth: boolean;
  nda: NdaObject;
  time_zone: string;
  format: string;
  contracts: ContractObject[];
  deleteNDA: () => void;
  onClick: () => void;
  onOpenConnectedFiles?: () => void;
  onOpenAddDescriptionModal: () => void;
  onDownloadAllContract: (ndaId: string) => Promise<void>;
} & WithStyles<typeof styles> &
  RouteComponentProps<Props>;

type NDAItemState = {
  hover: boolean;
  open: boolean;
  anchorEl: null | HTMLElement;
  backDrop: boolean;
  tooltipOpen: boolean;
};

class NDAItem extends React.PureComponent<NDAItemProps, NDAItemState> {
  constructor(props: NDAItemProps) {
    super(props);
    this.state = {
      hover: false,
      open: false,
      anchorEl: null,
      backDrop: false,
      tooltipOpen: false,
    };
  }

  onClickDelete() {
    this.setState({ anchorEl: null });
    this.props.deleteNDA();
    return;
  }

  renderNDAItemTableHeader() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.table_header}>
        <Grid
          item
          xs={6}
          className={classes.table_header_grid}
          style={{ paddingLeft: 52 }}
        >
          <SpoqaHansTypography className={classes.table_header_text}>
            {translate.nda_item.file}
          </SpoqaHansTypography>
        </Grid>
        <Grid item xs={2} className={classes.table_header_grid}>
          <SpoqaHansTypography className={classes.table_header_text}>
            {translate.nda_item.sign_requester}
          </SpoqaHansTypography>
        </Grid>
        <Grid item xs={2} className={classes.table_header_grid}>
          <SpoqaHansTypography className={classes.table_header_text}>
            {translate.nda_item.sign_participant}
          </SpoqaHansTypography>
        </Grid>
        <Grid item xs={2} className={classes.table_header_grid}>
          <SpoqaHansTypography className={classes.table_header_text}>
            {translate.nda_item.sign_date}
          </SpoqaHansTypography>
        </Grid>
      </Grid>
    );
  }

  renderNDAItemSignedhistory() {
    const { contracts, nda, time_zone, format, classes } = this.props;
    const sort = this.props.contracts.sort((a, b) => {
      let aDate = moment(a.createdAt).toDate().getTime();
      let bDate = moment(b.createdAt).toDate().getTime();
      return aDate > bDate ? -1 : 1;
    });
    return (
      <Collapse
        in={this.state.open}
        timeout={"auto"}
        unmountOnExit
        style={{ width: "100%" }}
      >
        <Grid container item xs={12} className={classes.nda_sign_history}>
          <Grid className={classes.nda_sign_cover} />
          {this.renderNDAItemTableHeader()}
          {contracts.length === 0 && (
            <Grid container item className={classes.no_sign_history_grid}>
              <SpoqaHansTypography
                fontWeight="medium"
                className={classes.no_sign_history}
              >
                {translate.nda_item.no_sign_history}
              </SpoqaHansTypography>
            </Grid>
          )}
          {sort.map((contract, idx) => (
            <NDASignatureItem
              key={idx}
              contract={contract}
              doc={nda.Documents.find((d) => d.id === contract.DocumentId)}
              time_zone={time_zone}
              format={format}
            />
          ))}
        </Grid>
      </Collapse>
    );
  }

  setAnchorEl(el: HTMLElement | null) {
    this.setState({ anchorEl: el });
  }

  setBackdrop(backdrop: boolean) {
    this.setState({ backDrop: backdrop });
  }

  setHover(h: boolean) {
    this.setState({ hover: h });
  }

  async onDownloadAllContract() {
    await this.props.onDownloadAllContract(this.props.nda.id);
  }

  renderFileName() {
    const { nda, classes, index, onOpenAddDescriptionModal } = this.props;
    return (
      <>
        <SpoqaHansTypography className={classes.nda_index}>
          {index}
        </SpoqaHansTypography>
        <Grid container item className={classes.nda_name_grid}>
          <Grid
            container
            item
            className={classes.nda_name}
            style={{
              width:
                this.props.hasAuth && this.state.hover
                  ? "calc(100% - 44px)"
                  : "100%",
            }}
          >
            <OverFlowTextTooltip
              arrow
              placement="bottom"
              title={`${nda.file_name}`}
              viewOverflow={true}
            >
              <SpoqaHansTypography noWrap className={classes.nda_name_text}>
                {nda.file_name}
              </SpoqaHansTypography>
            </OverFlowTextTooltip>

            {(nda.Projects.length > 0 || nda.description) && (
              <Grid container item className={classes.nda_name_info}>
                {nda.Projects.length > 0 && (
                  <SpoqaHansTypography
                    fontWeight="bold"
                    className={classes.nda_folder_count}
                  >
                    {nda.Projects.length} folders
                  </SpoqaHansTypography>
                )}
                <SpoqaHansTypography noWrap className={classes.nda_description}>
                  {nda.description ?? ""}
                </SpoqaHansTypography>
              </Grid>
            )}
          </Grid>
          {((this.props.hasAuth && this.state.hover) ||
            this.state.anchorEl) && (
            <NDACardItemMenu
              anchorEl={this.state.anchorEl}
              setAnchorEl={(el) => this.setAnchorEl(el)}
              setBackdrop={(bd) => this.setBackdrop(bd)}
              setHover={(h) => this.setHover(h)}
              setEditDescription={() => onOpenAddDescriptionModal()}
              onClickDelete={() => this.onClickDelete()}
              onDownloadAllContract={() => this.onDownloadAllContract()}
            />
          )}
        </Grid>
      </>
    );
  }

  renderSignatureHistory() {
    const { contracts, classes } = this.props;
    return (
      <Grid container item xs={4} lg={3}>
        <ButtonBase
          className={classes.nda_signature}
          style={{
            backgroundColor:
              contracts.length == 0
                ? "transparent"
                : this.state.hover || this.state.anchorEl
                ? theme.gray_2
                : "transparent",
            color:
              this.state.hover || this.state.anchorEl
                ? theme.gray_5
                : "transparent",
          }}
          onClick={(evt) => {
            evt.stopPropagation();
            this.setState({ open: !this.state.open });
          }}
          disabled={contracts.length == 0}
        >
          <SignatureIcon
            style={{ color: contracts.length ? theme.gray_6 : theme.gray_4 }}
          />
          <SpoqaHansTypography
            className={classes.nda_info_text}
            style={{ color: contracts.length ? theme.gray_6 : theme.gray_4 }}
          >
            {contracts.length > 99 ? "99+" : contracts.length}
          </SpoqaHansTypography>
          {contracts.length > 0 && (
            <ArrowDropDownRoundedIcon className={classes.icon_dropdown} />
          )}
        </ButtonBase>
      </Grid>
    );
  }

  renderConnectedFile() {
    const { nda, classes } = this.props;
    return (
      <Grid container item xs={4} lg={3}>
        <ButtonBase
          className={classes.nda_connected_file}
          onClick={(evt) => {
            evt.stopPropagation();
            if (this.props.onOpenConnectedFiles) {
              this.props.onOpenConnectedFiles();
            }
          }}
          disabled={nda.Documents.length == 0}
        >
          <ConnectedFileIcon
            style={{
              color: nda.Documents.length ? theme.gray_6 : theme.gray_4,
            }}
          />
          <SpoqaHansTypography
            className={classes.nda_info_text}
            style={{
              color: nda.Documents.length ? theme.gray_6 : theme.gray_4,
            }}
          >
            {nda.Documents.length > 99 ? "99+" : nda.Documents.length}
          </SpoqaHansTypography>
          <ArrowDropDownRoundedIcon className={classes.icon_dropdown} />
        </ButtonBase>
      </Grid>
    );
  }

  renderUpdate() {
    const { nda, time_zone, format, classes } = this.props;
    return (
      <Grid container item xs={4} lg={3} style={{ paddingRight: 8 }}>
        <SpoqaHansTypography className={classes.nda_info_text}>
          {moment(nda.createdAt).tz(time_zone).format(format)}
        </SpoqaHansTypography>
      </Grid>
    );
  }

  renderUploader() {
    const { nda, classes } = this.props;
    return (
      <Grid container item xs={3} className={classes.nda_profile_grid}>
        <Avatar
          alt="profile picture"
          src={getIPFSUrl(nda.User.profile_cid)}
          className={classes.nda_avatar}
        />
        <Grid container item className={classes.nda_email_grid}>
          <SpoqaHansTypography
            noWrap
            className={classes.nda_info_text}
            style={{ width: "100%" }}
          >
            {nda.User.name}
          </SpoqaHansTypography>
          <SpoqaHansTypography noWrap className={classes.nda_email}>
            {nda.User.email}
          </SpoqaHansTypography>
        </Grid>
      </Grid>
    );
  }

  renderNDAItemView() {
    const { classes } = this.props;
    return (
      <Grid
        container
        className={classes.nda_item}
        style={{
          backgroundColor:
            this.state.hover || this.state.anchorEl ? theme.gray_0 : "",
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseOver={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={(evt) => {
          evt.stopPropagation();
          if (this.state.backDrop) {
            this.setState({ backDrop: false });
            return;
          }
          this.props.onClick();
        }}
      >
        <Grid item xs={6} lg={5} className={classes.nda_item_grid}>
          {this.renderFileName()}
        </Grid>
        <Grid item xs={6} lg={7} className={classes.nda_item_grid}>
          {this.renderSignatureHistory()}
          {this.renderConnectedFile()}
          {this.renderUpdate()}
          {this.renderUploader()}
        </Grid>
      </Grid>
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid container item className={classes.root}>
        {this.renderNDAItemView()}
        {this.renderNDAItemSignedhistory()}
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(NDAItem));
