export default {
  ko: {
    link_create: {
      title: "파일 공유 링크 생성하기",
      create: "공유 링크 생성",
      link: "공유 링크",
      settings: "링크 기본 설정",
      link_name: "링크 이름",
      required: "필수",
      link_name_placeholder: "링크 이름을 작성해주세요",
      link_name_placeholder_ex: "예시) A투자사 공유를 위한 링크",
      expire_date: "유효기간",
      finish: "링크 생성 완료",
      share_option: "공유 옵션",
      option_settings: {
        title: "링크 옵션 설정",
        email: "이메일 수집하기",
        email_helpertext: "링크를 보려면 이메일을 입력해야 합니다.",
        download: "다운로드 허용하기",
        download_helpertext: "링크 안 파일을 다운로드 할 수 있습니다.",
        nda_sign: "NDA 사인 받기",
        nda_agree: "NDA(기밀 유지 서약) 동의",
        nda_agree_label: "링크를 보려면 NDA에 서명해야 합니다.",
        print: "프린트 허용하기",
        print_helpertext:
          "다운로드를 허용해야 프린트 옵션을 선택할 수 있습니다.",
        name_helpertext: "미입력시 “파일이름-n” 이름으로 생성됩니다.",
      },
      invalid_auth: "링크 생성 권한이 없습니다",
      nda: {
        set: "NDA 파일 설정하기",
        set_text: "NDA파일 설정해주세요",
        title: "NDA 파일 설정",
        file: "NDA 파일",
        file_helpertext: "기밀 유지 서약을 등록해주세요.",
        change: "NDA파일 바꾸기",
        name: "이름",
        name_placeholder: "이름을 작성해주세요",
        name_placeholder_lg: "공유 링크를 생성하는 사람의 이름",
        affiliation: "소속",
        affiliation_placeholder: "소속을 작성해주세요",
        sign: "서명",
        sign_from_name: "이름으로 서명 대체하기",
        sign_from_name_short: "이름으로 서명 대체",
        set_default_sign: "현재 서명을 기본 서명으로 설정하기",
        set_default_sign_sm: "기본 서명으로 설정하기",
        sign_change: "서명을 바꾸시겠습니까?",
        no_saved_signature: "저장된 서명이 없습니다",
        upload_sign: "서명 업로드",
        draw_sign: "서명 그리기",
        upload_file: "파일 업로드",
        company_sign: "회사 서명",
        replace_name: "이름으로 대체",
        sign_placeholder: "서명을 입력해주세요",
        change_sign: "변경하기",
        choose_file: "파일 선택",
      },
    },
    link_done: {
      header: "링크가 생성되었습니다!",
      copy: "복사",
      created_link: "생성된 링크",
      link_info: "링크 정보",
      file_name: "파일 이름",
      link_name: "링크 이름",
      expire: "유효 기간",
      expire_helpertext: "공유 링크 만료 일자를 선택하세요.",
      option: "링크 옵션",
      return: "폴더 화면으로 돌아가기",
    },

    link_send_email: {
      send_mail: "공유 링크 이메일 발송",
      send_mail_contract: "체결된 NDA 이메일 발송",
      button_send_email: "이메일 발송",
      receiver: "받는 사람",
      email_placeholder: "이메일을 입력해주세요.",
      add: "추가",
      message: "이메일 메세지",
      character: "자",
      message_description:
        "메일에 입력할 메세지를 입력해주세요. 이메일 미리보기에서 실시간으로 확인할 수 있습니다.",
      message_placeholder: "메일에 입력할 내용을 150자 이내로 작성해주세요",
      email_preview: "이메일 미리보기",
      tooltip: "미리보기에서는 클릭할 수 없습니다",
      message_body: {
        undefined: "익명의 유저",
        share: "Share the file",
        sentence_ko1: "님이",
        sentence_ko2: "파일을 공유했습니다.",
        sentence_en1: "",
        sentence_en2: "",
        instructions: "아래 버튼을 클릭하면 파일을 확인할 수 있습니다.",
        view_file: "View the file",
        footer_text: "Thank you, 세상에서 가장 안전한 문서 관리 솔루션 baSalt",
        footer_credentials:
          "ReDWit Inc _ 대전광역시 유성구 어은로 44, 2층, basaltdocs@redwit.io",
        footer_note:
          "* 본 메일은 발신 전용으로, 회신 내용을 확인할 수 없습니다.",
      },
    },
  },

  en: {
    link_create: {
      title: "Create sharing-link for files",
      create: "Create a link",
      link: "Sharing-link",
      settings: "Link Settings",
      link_name: "Link Name",
      required: "Required",
      link_name_placeholder: "Enter the link name",
      link_name_placeholder_ex: "Ex) Link for sharing with A investment",
      expire_date: "Expiration date",
      finish: "The sharing-link was successfully created",
      share_option: "Sharing Option",
      option_settings: {
        title: "Link Option Settings",
        email: "Require Email",
        email_helpertext: "You must enter the Email to open the link.",
        download: "Allow downloading",
        download_helpertext: "You can download files in the link.",
        nda_sign: "Require to sign on NDA",
        nda_agree: "NDA agreement",
        nda_agree_label: "You must sign on NDA to see link.",
        print: "Allow printing",
        print_helpertext:
          "You are available to select print option if you allow downloading.",
        name_helpertext: `It will be created as ‘filename-n’ if you don't enter the name.`,
      },
      invalid_auth: "You don't have an authority to create a link",
      nda: {
        set: "Setting up NDA files",
        set_text: "Please set up the NDA file",
        title: "NDA File Settings",
        file: "NDA File",
        file_helpertext: "Please register the NDA",
        change: "Change the NDA file",
        name: "Name",
        name_placeholder: "Enter your name",
        name_placeholder_lg: "Creator of the link",
        affiliation: "Institution",
        affiliation_placeholder: "Enter your institution",
        sign: "Signature",
        sign_from_name: "Use your name as the signature",
        sign_from_name_short: "Sign with my name",
        set_default_sign: "Set the current signature by default.",
        set_default_sign_sm: "Set as default signature",
        sign_change: "Do you want to change your signature?",
        no_saved_signature: "No signature",
        upload_sign: "Upload a signature",
        draw_sign: "Draw a signature",
        upload_file: "Upload files",
        company_sign: "Company Signature",
        replace_name: "Use my name",
        sign_placeholder: "Enter your signature",
        change_sign: "Change",
        choose_file: "Select files",
      },
    },

    link_done: {
      header: "Link has been created!",
      copy: "Copy",
      created_link: "Created Link",
      link_info: "Link Information",
      file_name: "File Name",
      link_name: "Link Name",
      expire: "Expiration Date",
      expire_helpertext:
        "Please select an expiration date of the sharing-link.",
      option: "Link Options",
      return: "Return to the folder",
    },

    link_send_email: {
      send_mail: "Send Email for sharing-links",
      send_mail_contract: "Send Email for signed NDA",
      button_send_email: "Send Email",
      receiver: "Recipient",
      email_placeholder: "Enter the Email address",
      add: "Add",
      message: "Message",
      message_description:
        "Enter the message to display on Email. You can check it on Email preview in real time.",
      message_placeholder:
        "Please enter the content of Email within 150 characters.",
      email_preview: "Email Preview",
      character: " characters",
      tooltip: "You can't click on it in preview",
      message_body: {
        undefined: "Anonymous",
        share: "Share the file",
        sentence_ko1: "",
        sentence_ko2: "",
        sentence_en1: "has shared the file",
        sentence_en2: ".",
        instructions: "You can check the file by clicking the button below.",
        view_file: "View the file",
        footer_text:
          "Thank you, the world's safest document management solution, baSalt",
        footer_credentials:
          "ReDWit Inc _ 2F, 44, Eoeun-ro, Yuseong-gu, Daejeon, South Korea basaltdocs@redwit.io",
        footer_note:
          "* This email is for sending only, please do not reply to it.",
      },
    },
  },
};
