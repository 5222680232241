export default {
  ko: {
    document: {
      personal_info: {
        title: "개인정보 수집 / 이용 내역에 관한 동의 (필수)",
        text: [
          `㈜레드윗(이하 "회사")는  수집한 개인정보를 관리함에 있어서 개인정보보호법에서 규정하고 있는 책임과 의무를 준수하고 있습니다. 회사는 개인 정보 수집 및 이용과 관련하여 다음 사항을 안내 드리오니, 내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.`,
          "table",
          "\n*** 귀하는 개인정보 수집과 이용에 동의하지 않을 권리가 있습니다. 그러나 동의를 거부할 경우에는 원활한 서비스 제공에 일부 제한을 받을 수 있습니다.",
          "*** 법령에 따른 개인정보의 수집 및 이용, 계약의 이행과 편의 증진을 위한 개인정보 처리위탁 기타 개인정보 처리와 관련된 일반 사항은 최앤리 법률사무소의 개인정보처리방침에 따릅니다.",
        ],

        table: {
          headers: ["수집 및 이용 목적", "수집 항목", "보유 기간"],
          body: [
            [
              "baSalt 회원가입 및 회원 계정의 제공 (필수)\n\u2022 본인여부 확인 및 본인 인증",
              "이름, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI), 이메일",
              "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
            [
              "워크스페이스 기능 이용",
              "소속 회사의 명칭, 소속 회사 내에서의 직책/부서명, 프로필 사진, 업로드 파일 및 해시",
              "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
            [
              "(만 14세 미만의 경우) 법정 대리인의 동의 취득",
              "보호자의 성명(성과 이름), 생년월일, 성별, 휴대전화 번호, 통신사업자, 내/외국인 여부",
              "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
            [
              "서비스 부정 이용 확인 및 방지",
              "서비스 이용 기록, 쿠키, 접속지 정보, 기기 정보, 기기 브라우저 정보",
              "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
            [
              "baSalt 전자 서명 서비스 이용",
              "전자 서명",
              "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
            [
              "baSalt 구독 결제",
              "신용카드 결제 시: 카드번호(일부), 카드사명 등",
              "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
            [
              "환불 처리 시",
              "계좌은행, 계좌번호, 예금주명, 이메일",
              "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
            [
              "고객센터 문의시",
              "문의 및 상담 내용에 포함된 개인정보",
              "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
            [
              " 서비스 및 콘텐츠 관련 통계작성(상업적 목적 포함) 및 연구(산업적 연구 포함) 등",
              "이름,  암호화된 이용자 확인값(CI), 중복가입확인정보(DI), 소속회사의 명칭,  이메일",
              "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
          ],
        },
      },
      third_party: {
        title: "개인정보 제 3자 제공에 관한 동의 (필수)",
        text: [
          "㈜레드윗{이하 ‘회사’}은 수집한 개인정보를 관리함에 있어서 개인정보보호법에서 규정하고 있는 책임과 의무를 준수하고 있습니다. 회사는 개인 정보 수집 및 이용과 관련하여 다음 사항을 안내 드리오니, 내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.",
          "table",
          "\n*** 귀하는 개인정보 수집과 이용에 동의하지 않을 권리가 있습니다. 그러나 동의를 거부할 경우에는 원활한 서비스 제공에 일부 제한을 받을 수 있습니다.",
          "*** 법령에 따른 개인정보의 수집 및 이용, 계약의 이행과 편의 증진을 위한 개인정보 처리위탁 기타 개인정보 처리와 관련된 일반 사항은 최앤리 법률사무소의 개인정보처리방침에 따릅니다.",
        ],
        table: {
          headers: ["수집 목적", "수집 항목", "보유 기간"],
          body: [
            [
              "워크스페이스 내부의 팀원으로서 워크스페이스 내부의 서비스를 이용하려는 회원의 정보",
              "성명(성과 이름), 소속 기관, 이메일, 업로드한 파일, 업로드 시간, 업로드 보관 장소 및 블록체인 인증서, 개인 전자 서명",
              "서비스 마지막 이용일 또는 회원탈퇴 후 1년 또는 법령에 따른 보존 기간",
            ],
            [
              "문서 열람 기록에 관한 정보 제공",
              "성명(성과 이름), 이메일, 접속지 정보, 기기 정보, 기기 브라우저 정보",
              "법령에 따른 보존 기간",
            ],
          ],
        },
      },
      privacy: {
        title: "baSalt 개인정보 취급방침",
        button_terms: "baSalt 서비스 이용약관 확인하기",
        button_signature: "전자서명 약관 확인하기",
        text_headers: [
          `1. 수집 및 이용 목적`,
          `2. 개인정보 수집 및 이용에 대한 동의`,
          `3. 개인정보 제공`,
          `4. 개인정보의 보유 및 이용기간`,
          `5. 개인정보 파기 절차 및 방법`,
          `6. 만 14세 미만 아동의 개인정보 보호`,
          `7. 이용자 및 법정 대리인의 권리와 그 행사 방법`,
          `8. 개인정보 자동 수집 장치의 설치 / 운영 및 거부에 관한 사항 `,
          `9. 개인정보의 기술적 / 관리적 보호 대책 `,
          `10. 가명정보 처리에 관한 사항`,
          `11. 개인정보 보호책임자 작성`,
          `12. 기타`,
          `13. 고지의 의무`,
        ],
        text: [
          `㈜레드윗(이하 "회사")는 이용자의 동의를 기반으로 개인정보를 수집·이용 및 제공하고 있으며 이용자의 권리를 적극적으로 보장합니다. 회사는 준수하여야 하는 대한민국의 관계 법령을 준수하고 있으며 이용자의 소중한 개인정보를 보호함으로써 이용자가 안심하고 서비스를 이용할 수 있도록 본 개인정보취급방침을 마련하여 준수하고 있습니다.`,
          `1. 수집 및 이용 목적`,
          `1) 목적`,
          `회사는 회원 가입 시 또는 서비스 이용 과정에서 아래와 같은 서비스 제공을 위해 필요한 최소한의 개인정보를 수집하고 있습니다.`,
          `table_1`,
          `\n2) 수집 방법`,
          `회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등이벤트 / 행사 등 참여수집한 개인정보의 일부를 삭제하거나 일부 또는 전부를 대체하는 등의 방법으로 추가 정보가 없이는 특정 개인을 알아볼 수 없도록 처리`,
          ` \u2022 협력 회사로부터의 제공`,
          ` \u2022 그 외 서비스 이용 기록 등의 정보가 자동으로 생성되어 수집되는 경우`,
          `2. 개인정보 수집 및 이용에 대한 동의`,
          `1) 동의 방법`,
          `회사는 “개인정보 수집 및 이용”에 대한 별도의 동의 획득 절차를 두고, 이용자가 이에 대해 ‘동의한다’ 또는 ‘동의하지 않는다’ 버튼을 통해 클릭할 수 있는 절차를 마련하고 있습니다. ‘동의한다’ 버튼을 클릭하면 개인정보 수집 및 이용 에 동의한 것으로 봅니다.`,

          `\n2) 이용자의 거부할 권리`,
          `이용자는 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 받는 별도의 불이익은 없습니다. 단, 서비스 이용이 불가능 하거나, 서비스 이용 목적에 따른 서비스 
제공에 제한이 따르게 됩니다.`,

          `3. 개인정보 제공`,
          `1) 개인정보의 제3자`,
          `회사는 이용자의 동의를 받아 개인정보를 제 3 자에게 제공하고 있습니다.`,
          `table_2`,

          `4. 개인정보의 보유 및 이용기간`,
          `1) 이용자 동의에 따른 이용 등`,
          `회사는 이용자가 회원으로서 서비스를 이용하는 동안 이용자의 개인정보를 보유 및 이용하며, 이용자가 회원탈퇴를 요청한 경우나 개인정보의 수집 및 이용목적을 달성하거나 보유 및 이용기간이 종료한 경우 또는 사업폐지 등의 사유가 발생한 경우 해당 정보를 지체 없이 파기합니다.`,

          `\n2) 관련 법령에 의한 정보 보유 사유`,
          `상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.`,
          `이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.`,
          `table_3`,

          `5. 개인정보 파기 절차 및 방법`,
          `회사는 다른 법률에 따라 개인정보를 보존하여야 하는 경우가 아닌 한, 수집한 이용자의 개인정보의 수집 및 이용 목적이 달성되거나, 이용자가 회원탈퇴를 요청한 경우 지체 없이 파기하여 향후 어떠한 용도로도 열람 또는 이용할 수 없도록 처리합니다. 회사의 개인정보 파기절치 및 방법은 다음과 같습니다.`,

          `\n1) 파기절차`,
          `이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보 보호 사유에 따라(4. 보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다. 동 개인정보는 법률에 의한 경우가 아니고 서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.`,

          `\n2) 파기방법`,
          `종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하며, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.`,

          `6. 만 14세 미만 아동의 개인정보 보호`,
          `회사가 운영하는 사이트에서는 만14세 미만 아동의 경우 법정대리인(부모님 등)의 동의절차를 거쳐야만 회원 가입 및 아동의 개인정보 수집이 가능합니다. 만 14세 미만 아동의 법정대리인은 아동의 개인정보의 열람, 정정, 가입해지(동의 철회)를 요청할 수 있으며, 이러한 요청이 있을 경우 회사는 법정대리인 확인 후 지체 없이 필요한 조치를 취합니다. 만14세 미만 아동의 개인정보도 성인의 개인정보와 동일하게 관리되며, 법정대리인의 동의 없이는 제3자와 공유하지 않습니다.`,

          `7. 이용자 및 법정 대리인의 권리와 그 행사 방법`,
          `이용자는 언제든지 등록되어 있는 자신 또는 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지(동의철회)를 요청할 수도 있습니다. 이용자 또는 만14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는’회원탈퇴’를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치 하겠습니다.`,

          `\n이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "4. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.`,

          `8. 개인정보 자동 수집 장치의 설치 / 운영 및 거부에 관한 사항 `,
          `회사는 이용자들에게 특화된 맞춤 서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는 데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 작은 텍스트 조각이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.`,

          `\n1) 쿠키의 사용 목적`,
          `이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 로그인 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.`,

          `\n2) 쿠키의 설치 / 운영 및 거부 `,
          `이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때 마다 확인을 거치거나, 아니면 모든 
쿠키의 저장을 거부할 수도 있습니다. 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나 모든 쿠키의 저장을 거부할 수 있습니다. 
설정방법 (예: 인터넷 익스플로러의 경우): 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
다만, 쿠키의 저장을 거부할 경우에는 회사의 서비스 이용에 어려움이 있을 수 있습니다.`,

          `9. 개인정보의 기술적 / 관리적 보호 대책 `,
          `회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.`,

          `\n1) 개인정보 암호화`,
          `이용자의 주요 개인정보는 암호화하여 저장하고 있으며, 파일 및 전송데이터를 암호화하여 혹시 발생할 수 있는 사고 시라도 이용자의 개인정보가 유출되지 않도록 관리되고 있습니다.`,

          `\n2) 해킹 등에 대비한 대책`,
          `회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 24시간 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.`,

          `\n3) 취급 직원의 최소화 및 교육`,
          `회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 개인정보취급방침의 준수를 항상 강조하고 있습니다. 또한, 임직원이 이용자의 개인정보를 취급하기 이전에 보안서약서를 통하여 이용자의 개인정보에 대한 정보유출을 사전에 방지하고 관련 사태규정을 마련하여 이에 대한 이행사항 및 준수 여부를 감시하기 위한 내부절차를 마련하고 있습니다.`,

          `\n4) 개인정보보호 전담기구의 운영`,
          `사내 개인정보보호전담기구 등을 통하여 개인정보취급방침의 이행사항 및 담당자의 준수 여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 하지만 근본적으로 개인정보의 보안관리에는 이용자 본인의 노력도 중요합니다. 온라인 상 오직 아이디와 비밀번호를 알고 있는 본인에게만 개인 정보에 접근할 수 있는 권한이 있으며, 비밀번호를 유지하고 관리할 책임 역시도 본인에게 있습니다. 반드시 본인만이 알 수 있는 내용으로 비밀번호를 구성해야 하며, 주민번호의 일부 또는 전화번호 등 타인이 쉽게 도용할 수 있는 비밀번호의 사용을 지양해야 합니다. 또한 공동으로 사용하는 PC에서 회사의 서비스에 접속하여 로그인한 상태에서 다른 사이트로 이동할 경우, 혹은 서비스 이용을 종료하였을 경우에는 반드시 해당 브라우저를 종료하시기 바랍니다. 그렇지 않을 경우, 해당 브라우저를 통해 아이디, 비밀번호, 주민등록번호가 포함된 이용자의 개인정보가 타인에게 손쉽게 유출될 위험이 있습니다. 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.`,

          `10. 가명정보 처리에 관한 사항`,
          `회사는 수집한 개인정보의 일부를 삭제하거나 일부 또는 전부를 대체하는 등의 방법으로 추가 정보가 없이는 특정 개인을 알아볼 수 없도록 가명처리하여 이용하는 경우, 원래의 상태로 복원하기 위한 추가 정보를 별도로 분리하여 보관ㆍ관리하는 등 해당 정보가 분실ㆍ도난ㆍ유출ㆍ위조ㆍ변조 또는 훼손되지 않도록 안전성 확보에 필요한 기술적ㆍ관리적 및 물리적 조치를 취하고 있으며, 가명정보의 처리 목적, 제3자 제공 시 제공받는 자 등 가명정보의 처리 내용을 관리하기 위하여 관련 기록을 작성하여 보관하고 있습니다.
`,
          `11. 개인정보 보호책임자 작성`,
          `회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
개인정보 보호책임자`,
          ` \u2022  성명: 김지원`,
          ` \u2022  소속: 주식회사 레드윗`,
          ` \u2022  이메일: jiwonkim@redwit.io`,

          `\n귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.`,
          ` \u2022  개인정보 보호 종합지원 포털(안전행정부 운영) / www.privacy.go.kr / 02-2100-3343`,
          ` \u2022 개인정보 침해신고센터 (한국인터넷진흥원 운영) / privacy.kisa.or.kr / (국번없이) 118`,
          ` \u2022 개인정보보호지원센터 (한국정보화진흥원) / privacy.nia.or.kr / 02-2131-0111`,

          `12. 기타`,
          `회사가 운영하는 사이트에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본 "개인정보취급방침"이 적용되지 않음을 알려 드립니다.`,

          `13. 고지의 의무`,
          `현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 변경이유 및 내용을 홈페이지의 '공지사항'에 게시 또는 이용자에 대한 개별 고지할 것입니다. 본 개인정보취급방침의 내용은 수시로 변경될 수 있으므로 사이트를 방문하실 때마다, 이를 확인하시기 바랍니다.`,

          `\n제정일자 : 2021. 12. 01`,
        ],

        tables: [
          {
            headers: ["수집 및 이용 목적", "수집 항목", "보유 기간"],
            body: [
              [
                "baSalt 회원가입 및 회원 계정 제공 (필수)",
                "이름, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI), 이메일",
                "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],
              [
                "워크스페이스 기능 이용",
                "  소속 회사의 명칭, 소속 회사 내에서의 직책/부서명, 프로필 사진, 업로드 파일 및 해시",
                "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],
              [
                "(만 14세 미만의 경우) 법정 대리인의 동의 취득",
                "보호자의 성명(성과 이름), 생년월일, 성별, 휴대전화 번호, 통신사업자, 내/외국인 여부",
                "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],
              [
                "서비스 부정 이용 확인 및 방지",
                "서비스 이용 기록, 쿠키, 접속지 정보, 기기 정보, 기기 브라우저 정보",
                "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],
              [
                "baSalt 전자 서명 서비스 이용",
                "전자 서명",
                "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],
              [
                "baSalt 구독 결제",
                "신용카드 결제 시: 카드번호(일부), 카드사명 등",
                "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],
              [
                "환불 처리 시",
                "계좌은행, 계좌번호, 예금주명, 이메일",
                "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],
              [
                "고객센터 문의시",
                "문의 및 상담 내용에 포함된 개인정보",
                "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],
              [
                "서비스 및 콘텐츠 관련 통계작성(상업적 목적 포함) 및 연구(산업적 연구 포함) 등 ",
                "이름, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI), 소속회사의 명칭, 이메일",
                "서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],
            ],
          },

          {
            headers: [
              "제공받는 자",
              "제공 목적",
              "제공하는 개인정보 항목",
              "보유기간",
            ],
            body: [
              [
                "워크스페이스",
                "워크스페이스 내부의 팀원으로서 워크스페이스 내부의 서비스를 이용하려는 회원의 정보",
                "성명(성과 이름), 소속 기관, 이메일, 업로드한 파일, 업로드 시간, 업로드 보관 장소 및 블록체인 인증서, 개인 전자 서명",
                "서비스 마지막 이용일 또는 회원탈퇴 후 1년 또는 법령에 따른 보존 기간",
              ],

              [
                "문서 소유자 (회원)",
                "문서 열람 기록에 관한 정보 제공",
                "성명(성과 이름), 이메일, 접속지 정보, 기기 정보, 기기 브라우저 정보",
                "법령에 따른 보존 기간",
              ],
            ],
          },

          {
            headers: ["보관하는 정보", "보존 이유", "보유 기간"],
            body: [
              [
                "계약 또는 청약철회 등에 관한 기록",
                "전자상거래 등에서의 소비자보호에 관한 법률",
                "5년",
              ],

              [
                "소비자의 불만 또는 분쟁처리에 관한 기록",
                "전자상거래 등에서의 소비자보호에 관한 법률",
                "3년",
              ],

              [
                "만본인 확인에 관한 기록",
                "정보통신 이용촉진 및 정보보호 등에 관한 법률",
                "6개월",
              ],
              ["방문에 관한 기록", "통신 비밀 보호법", "3개월"],
            ],
          },
        ],
      },

      signature: {
        title: "baSalt 전자서명 이용약관",
        button_terms: "baSalt 서비스 이용약관 확인하기",
        button_info: "개인정보 처리 방침 확인하기",
        text_headers: [
          "동의하기",
          "용어 정의",
          `전자 문서의 법적 효력`,
          `전자 서명의 법적 효력`,
          `서명 정보의 블록체인 저장`,
          `원본 문서의 법적 효력`,
          `당사자 확인의 의무`,
          `이메일 계정 유지 의무`,
          `문서 보안의 의무`,
          `문서 저장`,
          `서명 거절 및 취소 방법`,
          `최소 사용 환경`,
          `준거법`,
          `회사의 의무`,
        ],
        text: [
          "동의하기",
          "서명 요청자는 회원가입, 서명 참여자는 전자서명을 진행하며 “동의하고 서명 제출” 버튼을 누름으로써 아래의 내용에 동의하였습니다.",
          "용어 정의",
          `1. "서비스"란 블록체인 기반 서비스 baSalt를 말합니다. (URL : https://basalt.so)`,
          `2. 회사란 baSalt를 운영하는 주식회사 레드윗을 말합니다.`,
          `3. 이용자란 baSalt에 접속하여 본 약관에 따라 basalt가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.`,
          `4. "회원"이라 함은 회사가 정한 절차에 따라 회원등록을 한 자로서, 계속적으로 basalt가 제공하는 서비스를 이용할 수 있는 자를 말합니다.`,
          `5. "비회원"이라 함은 회원에 가입하지 않고 basalt가 제공하는 제한된 서비스를 이용하는 자를 말합니다.`,
          `6. “전자서명”이란 서명자를 확인하고 서명자가 당해 전자문서에 서명을 하였음을 나타내는데 이용하기 위하여 당해 전자문서에 첨부되거나 논리적으로 결합된 전자적 형태의 정보를 말합니다.`,
          `7. “서명 요청자”란 서명 참여자에게 문서에 대한 전자서명을 요청하는 회원을 말합니다.`,
          `8. “서명 참여자”란 서명 요청자로부터 문서에 서명을 요청받거나 서명을 하는 이용자를 말합니다.`,

          `전자 문서의 법적 효력`,
          `1. 전자서명에 사용되는 전자문서는 전자문서및전자거래기본법 제4조 제1항에 근거하여 전자적 형태로 되어 있다는 이유로 문서로서의 효력이 부인되지 않습니다. 다만, 다른 법령에 특별한 규정이 있을 경우 전자문서의 법적 효력이 인정되지 않을 수 있습니다.`,
          `2. 서명 요청자는 전자서명에 사용하는 전자문서가 다른 법령의 특별한 규정에 의해 법적 효력이 인정되지 않는지 확인하고 서명을 요청해야 합니다. 만약 해당 사실을 인지하지 못해 문제가 발생할 경우 회사는 책임지지 않습니다.`,
          `3. 서명 참여자는 전자서명에 사용하는 전자문서가 관련 법령의 특별한 규정에 의해 법적 효력이 인정되지 않는지 확인하고 서명해야 합니다. 만약 해당 사실을 인지하지 못해 문제가 발생할 경우 회사는 책임지지 않습니다.`,

          `전자 서명의 법적 효력`,
          `1. 서비스에 사용되는 전자서명은 반드시 서명 참여자의 동의가 있어야 가능하며, 전자서명법 제3조 제2항에 근거하여 당사자간의 약정에 따라 서명, 서명날인 또는 기명날인의 방식으로 전자서명을 선택한 경우 그 전자서명은 서명, 서명날인 또는 기명날인으로서의 효력을 가집니다. `,
          `2. 서비스에 사용되는 전자서명은 다른 법령에 특별한 규정이 있을 경우 서명의 법적 효력이 인정되지 않을 수 있습니다.`,
          `3. 서명 요청자는 서비스에 사용되는 전자서명이 법령에 의해 법적 효력이 인정되지 않는지 확인하고 서명을 요청해야 합니다. 만약 해당 사실을 인지하지 못해 문제가 발생할 경우 회사는 책임지지 않습니다.`,
          `4. 서명 참여자는 서비스에 사용되는 전자서명이 법령에 의해 법적 효력이 인정되지 않는지 확인하고 서명해야 합니다. 만약 해당 사실을 인지하지 못해 문제가 발생할 경우 회사는 책임지지 않습니다.`,

          `서명 정보의 블록체인 저장`,
          `서명 요청자 및 서명 참여자의 서명 정보 및 서명을 위한 관련된 문서 정보는 모두 블록체인에 저장되며, 해당 블록체인을 통해 인증받은 시점에 대해 제공해드립니다.`,

          `원본 문서의 법적 효력`,
          `서명 요청자 및 서명 참여자는 다음 문서를 사본이 아닌 원본 문서로 인정합니다.`,
          `\u2022 모든 서명 참여자가 서명을 완료 했을 때 이메일을 통해 전송되는 문서와 해당 문서의 출력본`,
          `\u2022 모든 서명 참여자가 서명을 완료 했을 때 서비스에서 확인할 수 있는 문서와 해당 문서의 출력본`,

          `당사자 확인의 의무`,
          `1. 서명 요청자는 서명 참여자 실명, 본인 소유 이메일, 서명 이미지 파일을 확인을 하고 서명을 요청해야 합니다.`,
          `2. 서명 요청자는 서명 참여자 실명, 이메일, 본인 서명 이미지 파일을 통해 서명을 할 수 있습니다.`,
          `3. 서명 요청자 및 서명 참여자는 모든 서명 참여자가 정당한 권한(본인, 대리인 등)을 가진 자임을 확인해야 합니다. `,
          `4. 회사는 정당한 권한이 없는 서명 요청자나 서명 참여자와 서명을 함으로 인해 발생한 문제에 대해 책임지지 않습니다. `,

          `이메일 계정 유지 의무`,
          `전자서명과 관련된 내용이 서명 참여자의 이메일로 전송됩니다. 서명 요청자 및 서명 참여자는 전자서명과 관련된 내용을 수신할 수 있는 상황을 유지해야 하며, 회사는 메일 삭제, 수신 거부 등
서명 참여자 및 요청자의 귀책사유로 전자서명과 관련된 내용이 전송되지 않아 발생하는 문제에 대해 책임지지 않습니다.`,

          `문서 보안의 의무`,
          `서명 요청자 및 서명 참여자는 상호 동의 없이 이메일, 공유 등으로 링크, 문서, 감사 추적 인증서 등 서명 진행과 관련된 사항을 유출해서는 안 됩니다.`,

          `문서 저장`,
          `서명 진행 사항 및 문서는 서버 및 블록체인에 저장되며, 회원은 언제 어디서나 서비스에 접속해 문서 내용을 확인할 수 있습니다.`,

          `서명 거절 및 취소 방법`,
          `서명 요청자 및 서명 참여자는 모든 서명 참여자가 서명을 완료하기 전까지 거절 또는 취소를 통해 서명 진행을 중단할 수 있습니다.`,

          `최소 사용 환경`,
          `사용자는 아래의 최소 사용 환경하에 서명을 진행해야 합니다. 최소 사용 환경을 충족하지 못하는 환경에서 서명을 진행할 경우 이와 발생하는 문제에 대해 회사는 책임지지 않습니다.`,
          `\u2022 브라우저 : Chrome(49 이상), Safari(9.0 이상), Firefox(43.0 이상)`,
          `\u2022 쿠키 : 모든 쿠키 허용`,

          `준거법`,
          `전자서명 진행 및 법적 효력과 관련된 사항은 대한민국 법률의 적용을 받고 그에 따라 해석됩니다.`,

          `회사의 의무`,
          `회사는 쾌적한 환경속에 서비스 이용할 수 있도록 최선을 다합니다.`,
          `본 약관에 따라 정상적인 방법으로 전자서명을 했음에도 당사자 확인 문제 등 법적 분쟁이 발생할 경우, 회사는 재판에 증거로 사용할 블록체인 인증 정보 및 감사/서버 로그를 제출하는 등 분쟁이 원활하게 해결될 수 있도록 최선을 다하여 협조합니다.`,

          `\n기타 회사의 의무와 관련된 자세한 사항은 서비스 이용약관을 준용합니다.`,

          `\n제정일자 : 2021. 12. 01`,
        ],
      },
      marketing: {
        title: `마케팅 활용에 관한 동의 (선택)`,
        table: {
          headers: ["수집 목적", "수집 항목", "보유기간", "동의 / 미동의"],

          body: [
            [
              "마케팅 및 광고에 활용 (선택) : 신규 서비스 (제품) 개발 및 특화, 이벤트 등 광고성 정보 전달,",
              "이름, 생년월일, 휴대폰번호, 접속빈도, 통신 사업자, 내/외국인 여부, 암호화된 이용자 확인값(CI), 중복가입확인정보(DI)",
              "서비스 마지막 이용일 또는 회원탈퇴 후 1년 또는 법령에 따른 보존 기간",
              "Basalt 회원가입 (선택)",
            ],
          ],
        },
        text: `*** 귀하는 개인정보 수집과 이용에 동의하지 않을 권리가 있습니다. 그러나 동의를 거부할 경우에는 원활한 서비스 제공에 일부 제한을 받을 수 있습니다.\n*** 법령에 따른 개인정보의 수집 및 이용, 계약의 이행과 편의 증진을 위한 개인정보 처리위탁 기타 개인정보 처리와 관련된 일반 사항은 최앤리 법률사무소의 개인정보처리방침에 따릅니다.`,
      },
      cookies: {
        title: "baSalt 쿠키 사용 정책",
        body: [
          `baSalt는 이용자들에게 더 나은 서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는 데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 작은 텍스트 조각이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
          \n`,
          `1. 쿠키의 사용 목적`,
          `이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 로그인 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.
          \n`,
          `2. 쿠키의 설치 / 운영 및 거부`,
          `이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때 마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나 모든 쿠키의 저장을 거부할 수 있습니다.
          \n`,

          `설정방법 (예: 인터넷 익스플로러의 경우): 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보\n다만, 쿠키의 저장을 거부할 경우에는 회사의 서비스 이용에 어려움이 있을 수 있습니다.\n`,
        ],
      },

      terms: {
        title: "baSalt 서비스 이용약관",
        button_info: "개인정보 처리 방침 확인하기",
        button_sign: "전자서명 약관 확인하기",
        body: [
          {
            title: `환영합니다`,
            body: `주식회사 레드윗이 제공하는 서비스를 이용해주셔서 감사합니다. 서비스를 이용하시거나 회원으로 가입하실 경우 본 약관에 동의하시게 되므로, 잠시 시간을 내셔서 주의 깊게 살펴봐 주시기 바랍니다.
                  \n`,
          },

          {
            title: `서비스 목적`,
            body: `본 약관은 주식회사 레드윗이 운영하는 문서관리 솔루션인 baSalt에서 제공하는 서비스를 이용함에 있어 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.
              \n`,
          },

          {
            title: `용어 정의`,
            body: `1. "서비스"란 블록체인 기반 서비스 baSalt를 말합니다. (URL : https://basalt.so)
2. 회사란 baSalt를 운영하는 주식회사 레드윗을 말합니다.
3. 이용자란 baSalt에 접속하여 본 약관에 따라 basalt가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
4. "회원"이라 함은 회사가 정한 절차에 따라 회원등록을 한 자로서, 계속적으로 basalt가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
5. "비회원"이라 함은 회원에 가입하지 않고 basalt가 제공하는 제한된 서비스를 이용하는 자를 말합니다.
6. “전자서명”이란 서명자를 확인하고 서명자가 당해 전자문서에 서명을 하였음을 나타내는데 이용하기 위하여 당해 전자문서에 첨부되거나 논리적으로 결합된 전자적 형태의 정보를 말합니다.
7. “서명 요청자”란 서명 참여자에게 문서에 대한 전자서명을 요청하는 회원을 말합니다.
8. “서명 참여자”란 서명 요청자로부터 문서에 서명을 요청받거나 서명을 하는 이용자를 말합니다.
\n`,
          },

          {
            title: `약관의 명시와 설정/개정`,
            body: `1. basalt는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 baSalt의 웹사이트 (https://basalt.so/)에 게시하고 있습니다.\n2. basalt는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.\n3. 회사는 본 약관을 개정할 수 있습니다. 개정하는 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 baSalt의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 basalt는 개정 전 내용과 개정 후 내용을 명확하게 비교하여    이용자가 알기 쉽도록 표시합니다.\n4. 전항에 따른 개정 약관 중 변경 또는 개정한 내용이 고객에게 불리한 경우에는 회원은 언제든지 본 계약의 해지 및 회원탈퇴를 할 수 있으며, 이 경우 basalt는 회원에게 불이익이 없도록 조치를 취해야 합니다.\n5. 본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.
              \n`,
          },

          {
            title: `서비스 제공 및 변경`,
            body: `1)  basalt는 다음과 같은 업무를 수행합니다
    1. 워크스페이스 내 팀원들의 활동 기록 서비스 제공
    2. 문서 업로드 및 블록체인 인증서 발급 기능 제공  
    3. 문서 외부 공유용 링크 생성 기능 제공
    4. 사내 NDA 문서 보관 및 법인용 도장/사인 공유 기능 제공
    5. 외부 공유된 문서에 대한 인사이트 리포트 제공
    6. 문서 공유시 NDA 첨부 기능 제공
    7. NDA 서명 문서 관리 기능 제공
2) basalt는 서비스의 기술적 사양의 변경 등의 경우에는 변경된 서비스의 내용 및 제공일자를 명시하여 현재의 서비스의 내용을 게시한 곳에 즉시 공지합니다.
3) basalt가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 기술적 사양의 변경, 정책 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 방법으로 통지합니다.
기술적 사양의 변경, 정책 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 방법으로 통지합니다.
               \n`,
          },

          {
            title: `서비스 중단 및 이용제한`,
            body: `1. 회사는 basalt에서 제공하는 서비스의 품질을 향상시키기 위하여 설비의 보수, 교체, 정기점검, 공사 등 기타 이에 준하는 작업을 수행하기 위하여 서비스의 제공을 일시적으로 중단할 수 있습니다.\n2. 회사는 예상하지 못한 사이버 해킹, 컴퓨터 바이러스의 공격을 감지하는 등 긴급한 사유가 발생하는 경우, 이용자의 계정을 보호하기 위하여 이용자 계정 및 관련 서비스의 제공을 일시적으로 중단할 수 있습니다.\n3. 회사는 이용자의 계정이 사이버 해킹 또는 컴퓨터 바이러스의 공격에 이용되고 있다고 판단할 상당한 이유가 있는 경우, 해당 계정에 대한 이용제한 조치를 할 수 있습니다. 이러한 조치에 대하여 이용자는 이의신청을 할 수 있고, 회원의 이의신청 사유가 정당하다고 인정되는 경우 회사는 즉시 회원의 서비스 이용을 재개하여야 합니다.\n4. 위 제1항부터 제3항의 사유로 인하여 이용자에게 손해가 발생하더라도 회사는 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
              \n`,
          },

          {
            title: `계약의 성립 및 회원가입`,
            body: `1) 이용계약은 회원이 되고자 하는 자가 이 약관의 내용에 대하여 동의를 한 다음 baSalt가 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로써 회원가입 
    신청을 하고, 회사가 그 신청에 대하여 승낙함으로써 체결됩니다. 회사의 승낙의 의사표시는 웹 사이트를 통해 안내드립니다.
2)  basalt는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
    1. 가입신청자가 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 basalt의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
    2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
    3. 기타 회원으로 등록하는 것이 basalt의 기술상 현저히 지장이 있다고 판단되는 경우
3) 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 basalt에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다. 
              \n`,
          },

          {
            title: `회원에 대한 통지`,
            body: `1. basalt가 회원에 대한 통지를 하는 경우, 회원이 회원가입시 basalt에게 제공한 전자우편 또는 전화번호 등으로 할 수 있습니다. 이용자가 원하지 않는 경우에는 수신을 거부할 수 있습니다.\n2. basalt는 회원 이외의 이용자에 대한 통지의 경우 1주일이상 basalt 웹 사이트에 게시함으로써 개별 통지에 갈음할 수 있습니다.
              \n`,
          },
          {
            title: `회원탈퇴 및 자격 상실 등`,
            body: `1) 회원은 basalt에 언제든지 탈퇴를 요청할 수 있으며 basalt는 즉시 회원탈퇴를 처리합니다.
2) 회원이 다음 각 호의 사유에 해당하는 경우, basalt는 회원자격을 제한 및 정지시킬 수 있습니다. 
    1. 가입 신청 시에 허위 내용을 등록한 경우
    2. basalt를 이용하여 구입한 서비스 등의 대금, 기타 회원이 부담하는 채무를 기일에 지급하지 않는 경우
    3. 다른 사람의 basalt 이용을 방해하거나 그 정보를 도용하는 등 서비스 이용과 관련된 질서를 위협하는 경우
    4. basalt를 이용하여 법령 또는 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
    5. basalt 서비스에 본인이 아닌 타 이용자의 연구노트를 적법한 권원 없이 위조, 변조하여 이용하는 경우
    6. basalt 서비스에 음란물이나 불법 도화, 문서 등을 촬영 및 업로드하여 basalt 서비스 취지에 부합하지 않은 목적으로 이용하는 경우
    7. 그 밖의 일반 사회 통념상 현저히 사회 상규에 부합하지 않은 행위를 한 경우
3) 회사가 회원 자격을 제한 및 정지시킨 후에도 제2항 각호에 해당하는 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 basalt는 회원자격을 상실시킬 수 있습니다. 회사는 당해 회원에게 소명할 기회를 제공할 수 있습니다.
4) basalt가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지합니다. 
               \n`,
          },

          {
            title: `구독 결제`,
            body: `1) 회원은 basalt 웹사이트에서 회사가 제공하는 플랜에 대하여 구독을 신청할 수 있습니다. 
2) 구독 신청시 카드를 등록함으로써 첫 31일의 이용료를 선결제 하여야 하며, 등록한 카드를 통하여 매 31일마다 정기적으로 결제가 진행됩니다. 정기 결제일에 크레딧이 남아 있는 경우, 크레딧을 먼저 소진한 후 남은 금액에 대해서만 자동 결제가 이루어집니다.
3) 회사는 회원의 구독 신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.
    1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
    2. 기타 구독 신청을 승낙하는 것이 basalt 기술상 또는 정책상 현저히 지장이 있다고 판단하는 경우
4) basalt 서비스 계약은 제1항의 결제가 완료된 후 회사가 이를 확인하여 서비스 이용 승낙에 대한 의사표시를 웹사이트 또는 앱을 통해 알림으로서 성립한 것으로 봅니다. 
5) basalt는 이용자와 서비스 제공 개시일에 관하여 별도의 약정이 없는 이상, 이용자가 구독 결제를 한 날부터 basalt 서비스를 이용할 수 있도록 필요한 조치를 취합니다.
               \n`,
          },

          {
            title: `구독 변경 및 취소`,
            body: `1) 구독 신청을 한 이용자는 구매신청 변경 또는 취소를 요청할 수 있고 basalt는 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제11조 환불 규정에 따릅니다. 
2) 구독 인원을 변경하는 경우에는 다음과 같이 처리합니다.
    A. 구독 인원 제외시에는 다음의 금액을 크레딧으로 자동 적립:제외 인원 수 * (오늘을 제외한 남은 기간 수) / 31 * (VAT 제외한) 1인 결제 금액
    B. 구독 인원 추가시에는 다음의 금액을 카드로 결제: 추가 인원 수 * (오늘을 포함한 기간 수) / 31 * 결제 금액 + VAT 
3) 구독을 취소하면 다음달의 정기 결제는 이루어지지 않고, 플랜의 남은 일수 동안에는 서비스 이용이 가능합니다.
4) 등록한 카드가 없거나, 결제 시도가 실패한 경우 곧바로 플랜 구독은 취소됩니다.
               \n`,
          },

          {
            title: `환불 규정`,
            body: `1) '회사'와 '회원' 구독권 계약을 체결한 결제일로부터 이용 기록(로그인)이 7일 이내에 없을 시 '회사'에 100% 환불 요청과 계약 철회를 요청할 수 있습니다.
2) 구독권 계약을 체결한 7일 이내 이용 기록(로그인)이 있는 경우 환불이 불가하다.
3) 구독 시작일 기준 7일 경과 이후에는 환불이 불가하다.
4) 크레딧으로 변경된 것은 환불이 불가하다.
5) 구독인원 변경 정책과 플랜 변경 정책은 회사의 정책에 따른다.
    \n`,
          },

          {
            title: `개인정보보호`,
            body: `1. 회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침에 따릅니다. 
2. 회사는 법령에 의해 국가기관 등의 요청이 있는 경우를 제외하고는 회원의 개인정보를 본인의 동의 없이 타인에게 제공하지 않습니다.
3. 회사는 회원의 귀책사유로 개인정보가 유출되어 발생한 피해에 대하여 책임을 지지 않습니다.
4. 이용자는 언제든지 basalt가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 basalt는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 basalt는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. 단, 이용자의 개인정보가 basalt 서비스의 블록체인 시스템을 통해 보관될 경우에는 기술적인 원인으로 정정 또는 삭제를 할 수 없을 수 있습니다.
\n`,
          },

          {
            title: `레드윗(회사)의 의무`,
            body: `1. 회사는 basalt의 운영과 관련하여 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적인 서비스를 제공하는데 최선을 다하여야 합니다.
2. 회사는 이용자가 안전하게 basalt 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
3. 회사는 수신동의를 하지 않은 이용자에게 영리목적의 광고성 전자우편을 발송하지 않습니다.
\n`,
          },

          {
            title: `회원의 이메일 및 비밀번호에 대한 의무`,
            body: `1. 회원의 이메일와 비밀번호에 관한 관리책임은 회원에게 있습니다.
 2. 회원은 자신의 이메일 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.
3. 회원이 자신의 이메일 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 basalt에 통보하고 basalt의 안내가 있는 경우에는 그에 따라야 합니다.
\n`,
          },

          {
            title: `이용자의 금지행위`,
            body: `이용자는 다음 행위를 하여서는 안 됩니다.
1. 타인의 문서의 무단 도용, 위조, 변조 등록
2. 타인의 개인정보 도용
3. basalt가 제공하는 서비스의 목적 및 취지와 관련 없는 자료의 업로드
4. 전호의 자료를 타인에게 전송 또는 공유
5. basalt 기타 제3자의 저작권 등 지적재산권에 대한 침해
6. basalt 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
7. 외설 또는 폭력적인 사진 등 기타 공서양속에 반하는 자료를 basalt 서비스를 통해 이용 및 공유, 전송하는 행위
               \n`,
          },

          {
            title: `저작권의 귀속`,
            body: `1. basalt가 작성한 저작물에 대한 저작권 기타 지적재산권은 basalt에 귀속합니다.
2. 이용자가 작성한 저작물에 대한 저작권 기타 지적재산권은 이용자에 귀속합니다.
3. 이용자는 basalt를 이용함으로써 얻은 정보 중 basalt에게 지식재산권이 귀속된 정보를 basalt의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
4. basalt는 이용자에게 귀속된 저작물을 서비스 개선, 포트폴리오 및 홍보 등의 목적으로 사용하려는 경우 사전에 이용자에게 동의를 받아야 합니다. 다만, basalt 사용 후기와 같은 이용자의 저작물에 대하여서는 이용자가 명시적으로 거부의사를 밝히지 않는 한, 회사가 서비스 홍보 활동에 그 저작물을 이용할 수 있는 사용권을 이용자가 회사에게 부여한 것으로 간주한다. 
               \n`,
          },

          {
            title: `계정의 양도금지`,
            body: `이용자는 basalt에서 생성한 계정에 관련된 모든 정보 및 비밀번호, 계정 등을 회사의 사전 서면 동의 없이 제3자에게 양도할 수 없으며, 이를 위반할 경우 이용계약이 해지될 수 있습니다.
               \n`,
          },

          {
            title: `계약의 해지`,
            body: `1) 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 회사에 해지신청을 함으로써 이용계약을 해지할 수 있습니다. 회사는 관련법령 등에서 정하는 바에 따라 이를 즉시 처리하여야 합니다.
2) 회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 이용계약을 해지하거나 해당 회원의 계정 이용을 중단시키는 등 필요한 조치를 취할 수 있습니다.
    1. 회원이 제8조 및 제13조의 금지행위를 한 경우
    2. 서비스 운영을 고의로 방해한 경우
    3. 공공질서 및 미풍양속에 저해되는 내용을 유포시킨 경우
    4. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 등 운영자의 합리적이고 객관적인 판단에 의하여 회원의 자격이 없다고 인정되는 경우
    5. 컴퓨터 바이러스 프로그램 등을 유포하는 경우
    6. 회사 및 제3자의 지식재산권을 침해하는 행위
    7. 기타 본 약관이나 관련법규에 위배된다고 판단되는 경우
3) 이용계약을 해지할 경우, 관련법령 및 회사의 개인정보보호정책에서 정한 바에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 후 즉시 회원의 모든 데이터는 지체없이 파기됩니다.
\n`,
          },

          {
            title: `손해배상 및 책임의 한계`,
            body: `1. 회사 또는 이용자는 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 고의 또는 과실이 없는 경우에는 그러하지 아니 합니다. 
2. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관하여 책임을 지지 않습니다.
3. 회사는 회원의 고의 또는 과실로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다. 다만, 회원에게 부득이하거나 정당한 사유가 있는 경우에는 그러하지 아니합니다.
4. 회원이 서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.
5. 회사는 회원이 다른 회원 또는 타인과 서비스를 매개로 발생한 거래나 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해에 대해 책임을 지지 않습니다.
6. 회사는 무료로 제공되는 서비스 이용과 관련하여 회원에게 발생한 손해에 대해서는 책임을 지지 않습니다. 그러나 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
\n`,
          },

          {
            title: `분쟁해결`,
            body: `1. 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상하기 위하여 자체적인 피해보상처리 절차를 수립하고 운영합니다.
2. 회사는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
\n`,
          },

          {
            title: `준거법 및 관할`,
            body: `이 약관은 대한민국 법률에 따라 규율되고 해석됩니다.\n회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는 민사소송법 등에서 정한 절차에 따른 법원을 관할 법원으로 합니다.
               \n`,
          },

          {
            title: `전자서명 이용약관의 적용`,
            body_1: `회원이 서명 요청자로서 서명 참여자에게 전자서명을 요청할 경우`,
            link: `전자서명 이용약관`,
            body_2: `이 함께 적용됩니다.
               \n`,
          },

          {
            title: ``,
            body: `이용약관 시행일자 2021. 12. 01.`,
          },
        ],
      },

      refund: {
        title: "baSalt 환불 정책",
        button_info: "개인정보 처리 방침 확인하기",
        button_term: "baSalt 서비스 이용약관 확인하기",
        body: [
          {
            title: `Plan 결제 지침 및 환불규정`,
            body: `Plan의 구매신청을 한 회원은 의사표시의 불일치 등이 있는 경우에는 결제한 즉시 구매신청 변경 및 취소를 요청할 수 있고 “baSalt”는 회원의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 “baSalt”의 환불 기준에 관한 규정에 따릅니다.
                  \n`,
          },

          {
            title: `결제지침`,
            body: `1. '회사'와 ‘회원’ 결제는 구독 선불 결제를 원칙으로 한다.
2. Guest 인원 추가 초대 시 인원 추가로 간주하여 추가 결제를 자동으로 진행한다.
3. 워크스페이스 내 크레딧이 있다면, 크레딧 소진 후 남은 금액 자동 결제 진행된다.
\n`,
          },

          {
            title: `환불 규정`,
            body: `1. '회사'와 '회원' 구독권 계약을 체결한 결제일로부터 이용 기록(로그인)이 7일 이내에 없을 시 '회사'에 100% 환불 요청과 계약 철회를 요청할 수 있습니다.
2. 구독권 계약을 체결한 7일 이내 이용 기록(로그인)이 있는 경우 환불이 불가하다.
3. 구독 시작일 기준 7일 경과 이후에는 환불이 불가하다.
4. 크레딧으로 변경된 것은 환불이 불가하다.
5. 구독인원 변경 정책과 플랜 변경 정책은 회사의 정책에 따른다.
\n`,
          },
        ],
      },
    },
  },

  en: {
    document: {
      personal_info: {
        title:
          "Consent to the collection/use of personal information (mandatory)",
        text: [
          `ReDWit Inc.(hereby “company”) complies with the responsibilities and obligations stipulated in the Personal Information Protection Act in managing the collected personal information. The company provides the following information regarding the collection and use of personal information, so please read the details carefully before deciding whether to agree or not.`,
          "table",
          "\n*** You have the right not to consent to the collection and use of your personal information. However, if you refuse to consent, there may be some restrictions on smooth service provision.",
          "*** General matters related to the collection and use of personal information in accordance with laws and consignment processing of personal information for contract implementation and convenience enhancement, etc., are subject to the personal information processing policy of Choi & Lee Law Firm.",
        ],

        table: {
          headers: [
            "Purpose of collection and usage",
            "Collected categories",
            "Retention Period",
          ],
          body: [
            [
              "Registration and provision of member account for baSalt (mandatory)\n\u2022 Identity verification",
              "Name, Coded user identification value(CI), Duplicate registration confirmation information(DI), Name of affiliation institution, e-mail",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
            [
              "Usage of workspace functions",
              "Name of affiliation institution, Name of position/department in the affiliation institution, profile picture, upload files and hash",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
            [
              "(In case of age under 14) Consent of legal representative",
              "Full name of legal representative, Date of birth, gender, cell phone number, telecommunication service provider, domestic/foreigner indication",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
            [
              "Verification and Prohibition of fraudulent use of the service",
              "Service usage log, cookie, information of location of access, information of device, information of device browser",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
            [
              "Using baSalt electronic signature service",
              "Electronic signature",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
            [
              "Payment for baSalt subscription",
              "Credit card payment : Card license number(partial), Name of the card company, etc",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
            [
              "In case of refund",
              "Name of Bank, Bank account number, Name of account holder, e-mail",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
            [
              "In case of requesting the customer center",
              "Personal information that are included in the request and consulting",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
            [
              "Statistics for service and contents(commercial purpose included), Research(Industrial purpose included), etc",
              "Name, Coded user identification value(CI), Duplicate registration confirmation information(DI), Name of affiliation institution, e-mail",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
          ],
        },
      },
      third_party: {
        title:
          "Consent to Provision of Personal Information to Third Parties (mandatory)",
        text: [
          `ReDWit Inc.(hereby “company”) complies with the responsibilities and obligations stipulated in the Personal Information Protection Act in managing the collected personal information. The company provides the following information regarding the collection and use of personal information, so please read the details carefully before deciding whether to agree or not.`,
          "table",
          "\n*** You have the right not to consent to the collection and use of your personal information. However, if you refuse to consent, there may be some restrictions on smooth service provision.",
          "*** General matters related to the collection and use of personal information in accordance with laws and consignment processing of personal information for contract implementation and convenience enhancement, etc., are subject to the personal information processing policy of Choi & Lee Law Firm.",
        ],
        table: {
          headers: [
            "Purpose of collection and usage",
            "Collected categories",
            "Retention Period",
          ],
          body: [
            [
              "Provide the inform ation of the users who wish to use t he service within the workspace to the workspace teammates",
              "Full name, affiliation institution, e-mail, uploaded files, uploaded time, storage location and block chain certificate, personal electronic signature",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
            ],
            [
              "Provision of browsing information of the document",
              "Full name, e-mail, location of access, information of device, information of device browser",
              "Retention period set by the regarding law",
            ],
          ],
        },
      },
      privacy: {
        title: "baSalt Statement on privacy",
        button_terms: "baSalt Terms of Service",
        button_signature: "baSalt terms for electronic signature",
        text_headers: [
          `1. Purpose of Collection and Usage`,
          `2. Consent for personal information collection and usage`,
          `3. Provision of Personal information`,
          `4. Period of retention and use for personal information`,
          `5. Process and method for deleting personal information`,
          `6. Protection of personal information for children under the age of 14`,
          `7. The rights of users and legal representative and the means to exercise the rights`,
          `8. Issues regarding the installation/operation and rejection for automatic personal information collection device`,
          `9. Technical/Managerial protective measures for personal information`,
          `10. Issues regarding procession of alias information`,
          `11. Appointment of personal information protection manager`,
          `12. Etc`,
          `13. Obligation of Notice`,
        ],
        text: [
          `ReDWit Inc.(hereby “company”) collect/use and provide the personal information based on the users’ consent, and actively ensures the users’ rights. The company abides by the related laws of Republic of Korea, and also abides by the Statement on Privacy so that the valuable personal information can be protected and the users can safely use the service.`,
          `1. Purpose of Collection and Usage`,
          `1) Purpose`,
          `The company collects minimal personal information in case of registration or service usage which is necessary to provide the following service.`,
          `table_1`,
          `\n2) Method for collection`,
          `For cases in which the users consent to the collection of personal information and enters the information him/herself : partial deletion or replacement of the information gathered from webpage, e-mail, fax or phone-call take place in the process of consulting through the customer center, so that individuals cannot be identified without additional information.`,
          ` \u2022 Provision from the cooperative enterprises`,
          ` \u2022 Cases in which information such as service usage log is automatically created and collected`,
          `2. Consent for personal information collection and usage`,
          `1) Method of consent`,
          `The company has separate procedure for acquiring the consent regarding “collection and usage of personal information”, and enable the users to “agree” or “disagree” by clicking the corresponding buttons. If a user clicks the “agree” button, he/she will be considered as agreeing to the collection and usage of personal information.`,

          `\n2) The right of the users to reject`,
          `The users have the rights to reject personal information collection and usage, and wil l receive no disadvantage in case of rejection. But, there will be certain limitation reg arding the service usage due to the purpose of the provided service.`,

          `3. Provision of Personal information`,
          `1) Provision of personal information to a third party`,
          `The company provides personal information to a third party with the consent of the users.`,
          `table_2`,

          `4. Period of retention and use for personal information`,
          `1) Usage due to the users’ consent, etc`,
          `The company will own and use the user’s personal information while the user use the service as a member, and the information will be immediately deleted when the user requests withdrawal from membership, when the purpose of collection and usage of personal information is achieved, when the period of use as expired or when the busi ness has been shut down.`,

          `\n2) Reason for owning the information stated in the related laws`,
          `The company will own the members’ personal information when the company mu st retain the information for a certain period due to the regulations or rules set by the related laws such as Commercial Acts or Act on Protection of Customers in Ele ctronic Commerce, etc.`,
          `For these cases, the company will use the information only for the purpose of reta ining, and the retention period is as follows :`,
          `table_3`,

          `5. Process and method for deleting personal information`,
          `Unless the company needs to own the personal information because of the related laws, the company will immediately delete the information to make sure that the information c annot be browsed or used for any purpose when the purpose of personal information co llection and usage is achieved or when the user has requested withdrawal from members hip.`,

          `\n1) Process of deletion`,
          `The information entered by the user for registration etc. will be moved into separate DB(separate filing cabinet in case of paperworks) after achieving their purpose of use, and will be destroyed after being stored for certain period set by the related laws or company’s own rules. The personal information will not be used for purposes other t han retention, unless it is stated in the related laws.`,

          `\n2) Method of deletion`,
          `The personal information printed on paper will be destroyed with grinder or will be b urned, while the electronic information will be deleted with technical methods in whic h the log cannot be seen.`,

          `6. Protection of personal information for children under the age of 14`,
          `For the website operated by the company, children under the age of 14 can register and be the subject for personal information collection only after the consent of their legal re presentative. The legal representative can browse and modify the personal information of children or request cancellation of collection, and the company will immediately take the necessary measures after the confirmation of legal representative. The personal informati on of children under age 14 will be managed equally as the adult’s personal information, and will not be shared with a third party without the consent of legal representative.`,

          `7. The rights of users and legal representative and the means to exercise the rights`,
          `Users can always browse or modify the personal information of registered self or children under age 14, and can request termination of registration. Select ‘modify personal information’(or ‘modify member information’) to browse or modify the personal information, and click ‘terminate membership’ to terminate the registration. You can browse, modify or terminate membership after identification procedures.`,

          `\nOr contact the personal information manager through written means, phone call or e-mail, and it will be immediately dealt with. In case of modification request for errors regarding the users’ personal information, the personal information will not be provided until the errors have been corrected. Also, if the wrong information have already been provided to a thrid party, the third party will be immediately notified of it and modification will be held. The company makes sure that the information that are deleted or terminated by the user or legal representative’s request are dealt with by the terms stated in “Period of retention and use for personal information”, and will not be browsed or used as other purposes.`,

          `8. Issues regarding the installation/operation and rejection for automatic personal information collection device`,
          `The company uses ‘cookies’ that stores and updates users’ information in order to provided users with optimized service. A cookie is a small text piece sent from the operation server(HTTP) to a user’s computer browser, and can be stored inside a user’s computer hard disk.`,

          `\n1) Purpose of using cookies`,
          `It is used to provide optimized information to the users by acknowledging the log-in records for websites and services that the users have visited.`,

          `\n2) Installation/Operation and Rejection for the cookies`,
          `Users can choose whether to install the cookies or not. This means the users can set option in the web browser to allow all cookies, check every time a cookie is being stored, or reject all cookie storage.
Users can choose the web browser option to allow every cookies/check for each cookie/or reject all cookie storage.
How to set option(ex. in case of internet explorer) : ‘Tools’ tab in the upper side of web browser > Internet options > personal information
Please be informed that if you reject the storage of cookies, you can have certain difficulties in using our services.`,

          `9. Technical/Managerial protective measures for personal information`,
          `The company works on the technical/managerial measures to secure the stability of protection regarding the loss, theft, leakage, forgery or damage of personal information of the users.`,

          `\n1) Encryption of personal information`,
          `The major personal information of the users are stored after encryption, and the files along with data are also encrypted so as to prevent any leakage of personal information in case of unexpected accidents.`,

          `\n2) Preparative measures for hacking, etc.`,
          `The company tries its best to prevent leakage or damage of personal information due to hacking or computer virus. The data is being backed up constantly in case of damage, and the newest vaccine program is used to prevent leakage/tampering of the users’ personal information and data. Also, personal information can be safely sent through network through encrypted communication. We also restric unauthorized external access via 24-7 invasion blockage system, and are trying everything we can to have every technical device possible for security and safety.`,

          `\n3) Minimizing and training the staff`,
          `The company limits the staff dealing with personal information only to the manager, and granted separate password which is renewed regularly, along with frequent training of the manager to emphasize the importance of Statement on privacy. Also, the staff must sign the security pledge before dealing with the users’ personal information to prevent personal information leakage, and internal procedures are set to make sure these measures are kept.`,

          `\n4) Operation of personal information protection department`,
          `The company is trying to modify and right the problems by operating personal information protection department and checking the measures regarding Statement on Privacy along with the manager’s reliability. But fundamentally, the users’ efforts are also vital when it comes to personal information security. Only him/herself has the authority to access personal information, and the responsibility to manage and secure the password also lies on the users. You must set the password that only you can know, and be advised not to use numbers that can be easily predicted such as parts of social license number or cell phone number. Also, when switching to other websites while logged in to the company’s website in public PC or when you are done using the service, you must terminate the browser. If not, the personal information including ID, password and social license number can easily be leaked through the browser. The company will not be responsible for any problems caused by the insecurity of user him/herself.`,

          `10. Issues regarding procession of alias information`,
          `In case of using an alias in order to make an individual unidentifiable without additional information through deletion or replacement of collected personal information, the company takes technical, managerial and physical measures to secure the stability of protection regarding the loss, theft, leakage, forgery, tampering or damage of information such as separate storage of the additional information necessary to restore the information to its original form. Also, the related records of the alias information, such as the purpose of use and the third party receiving the information, will be stored.`,

          `11. Appointment of personal information protection manager`,
          `The company appoints the personal information protection manager for comprehensive responsibility over the personal information procession, and to deal with complaints/compensation regarding personal information procession.
Personal Information Protection Manager`,
          ` \u2022 Name : Jiwon Kim`,
          ` \u2022 Affiliation Institution : RedWit Inc.`,
          ` \u2022 E-mail : jiwonkim@redwit.io`,

          `\nYou can report all requests or complaints related with personal information protection that happen while using the company’s service to personal information manager or related department. The company will quickly and thoroughly answer to the users’ calls. Please contact the below agencies in case you need to report or consult regarding invasion of personal information.`,
          ` \u2022  Personal information protection Comprehensive support Portal(Operated by Ministry of Security and Public Administration) / www.privacy.go.kr / 02-2100-3343`,
          ` \u2022  Personal information infringement report center (Operated by Korea Internet & Security Agency) / privacy.kisa.or.kr / (without area code) 118`,
          ` \u2022  Personal information protection support center (National Information Society Agency) / privacy.nia.or.kr / 02-2131-0111`,

          `12. Etc`,
          `This is to notify that “Statement on Privacy” stated here does not apply to the personal information collection done by the websites linked in the website operated by the company.`,

          `13. Obligation of Notice`,
          `If there is an addition, deletion or modification of the current statement on privacy, we will notify the reason and content of them at least 7 days earlier on the “notice” board in website or individually. The contents of Statement on Privacy can be frequently modified, so please check it when you visit the website.`,

          `\nEnactment Date : 2021. 12.  01`,
        ],

        tables: [
          {
            headers: [
              "Purpose of collection and usage",
              "Collected categories",
              "Retention Period",
            ],
            body: [
              [
                "Registration and provision of member account for baSalt (mandatory)",
                "Name, Coded user identification value(CI), Duplicate registration confirmation information(DI), Name of affiliation institution, e-mail",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],
              [
                "Usage of workspace functions",
                "Name of affiliation institution, Name of position/department in the affiliation institution, profile picture, upload files and hash",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],
              [
                "(In case of age under 14) Consent of legal representative",
                "Full name of legal representative, Date of birth, gender, cell phone number, telecommunication service provider, domestic/foreigner indication",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],
              [
                "Verification and Prohibition of fraudulent use of the service",
                "Service usage log, cookie, information of location of access, information of device, information of device browser",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],
              [
                "Using baSalt electronic signature service",
                "Electronic signature",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],
              [
                "Payment for baSalt subscription",
                "Credit card payment : Card license number(partial), Name of the card company, etc",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],
              [
                "In case of refund",
                "Name of Bank, Bank account number, Name of account holder, e-mail",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],
              [
                "In case of requesting the customer center",
                "Personal information that are included in the request and consulting",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],
              [
                "Statistics for service and contents(commercial purpose included), Research(Industrial purpose included), etc",
                "Name, Coded user identification value(CI), Duplicate registration confirmation information(DI), Name of affiliation institution, e-mail",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],
            ],
          },

          {
            headers: [
              "Provided party",
              "Purpose of provisi on",
              "Provided personal inf ormation",
              "Retention period",
            ],
            body: [
              [
                "Workspace",
                "Provide the inform ation of the users who wish to use t he service within the workspace to the workspace teammates",
                "Full name, affiliation institution, e-mail, uploaded files, uploaded time, storage location and block chain certificate, personal electronic signature",
                "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              ],

              [
                "Owner of the document (User)",
                "Provision of browsing information of the document",
                "Full name, e-mail, location of access, information of device, information of device browser",
                "Retention period set by the regarding law",
              ],
            ],
          },

          {
            headers: [
              "Collected information",
              "Reason for collection",
              "Retention period",
            ],
            body: [
              [
                "Log regarding contract or withdrawal of subscription",
                "Act on Consumer Protection in Electronic Commerce, etc",
                "5 years",
              ],

              [
                "Log regarding complaints or conflicts from the customers",
                "Act on Consumer Protection in Electronic Commerce, etc",
                "3 years",
              ],

              [
                "Identification log",
                "Act on Utilization of Information Communication and Information Protection",
                "6 months",
              ],
              [
                "Visitation log",
                "Act on communication confidentiality",
                "3 months",
              ],
            ],
          },
        ],
      },
      signature: {
        title: "baSalt terms for electronic signature",
        button_terms: "baSalt Terms of Service",
        button_info: "baSalt Statement on privacy",
        text_headers: [
          "Agree to the terms",
          "Definition of words",
          `Legal effect of electonic documents`,
          `Legal effect of electronic signature`,
          `Block chain storage of signature information`,
          `Legal effect of the original document`,
          `Obligation to verify the party`,
          `Obligation to maintain e-mail account`,
          `Obligation to secure the documents`,
          `Document storage`,
          `Rejection and cancellation of signature`,
          `Minimum environment for usage`,
          `Governing Law`,
          `Obligation of company`,
        ],
        text: [
          "Agree to the terms",
          "The requester proceeds with registration while the participant proceeds with electronic signature, and both agree to the below terms by clicking the “Agree and submit signature” button.",
          "Definition of words",
          `1. “Service” refers to baSalt, the block chain based service. (URL : https://basalt.so)`,
          `2. “Company” refers to RedWit Inc. that operates baSalt.`,
          `3. “User” refers to the members and non-members who access the basalt and makes use of the service that basalt provides due to these terms.`,
          `4. “Member” refers to the users who have registered according to the procedures set by the company, and those who can access continuously to the baSalt services.`,
          `5. “Non-members” refers to the users who have not registered and can only access to limited baSalt services.`,
          `6. “Electronic signature” refers to the electronic information that are attached to electronic documents or logically combined to verify the person who signed or to verify whether the person has signed the document.`,
          `7. “Signature requester” refers to the members who request electronic signature for the documents to the signature participant.`,
          `8. “Signature participant” refers to the users who are requested or have signed the document with the request of signature requester.`,

          `Legal effect of electonic documents`,
          `1. Although it has an electronic format, the electronic documents used for electronic signature cannot be denied of its effects due to article 4, paragraph 1 of the Framework Act on Electronic Documents and Transactions. But, it can be denied of its legal effects if there is a specific statement in other law.`,
          `2. The signature requester must request the signature after checking whether the document does not have any other law denying it of its legal effect. If a conflict occurs due to the lack of knowledge regarding this matter, the company will not take responsibility.`,
          `3. The signature participant must sign after checking whether the document used for the signature does not have any other law denying of its legal effect. If a conflict occurs due to the lack of knowledge regarding this matter, the company will not take responsibility.`,

          `Legal effect of electronic signature`,
          `1. The electronic signature used for service is only possible if the signature participant consents, and if the electronic signature is done through signature or signature seal then that signature will be effective as a signature or a seal, due to Article 3, paragraph 2 of the Act on Electronic Signature.`,
          `2. The signature used for service might not be legally effective if there is a specific law regarding it.`,
          `3. The signature requester must request the signature after checking whether the document does not have any other law denying it of its legal effect. If a conflict occurs due to the lack of knowledge regarding this matter, the company will not take responsibility.`,
          `4. The signature participant must sign after checking whether the document used for the signature does not have any other law denying of its legal effect. If a conflict occurs due to the lack of knowledge regarding this matter, the company will not take responsibility.`,

          `Block chain storage of signature information`,
          `The signature information of signature requester and participant along with all document information regarding the signature will be stored in block chain, and the verification point will be provided through the block chain.`,

          `Legal effect of the original document`,
          `The signature requester and signature participant will acknowledge the following documents as original and not copied.`,
          `\u2022 The documents that are sent via e-mail after all signature participants have signed, and the printed version of these documents.`,
          `\u2022 The documents that can be verified through service after all signature participants have signed, and the printed version of these documents.`,

          `Obligation to verify the party`,
          `1. Signature requester should request the signature after checking the name and e-mail of signature participant and the image file of the signature.`,
          `2. Signature requester can sign via the name and e-mail of signature participants and self-signed image file.`,
          `3. Signature requester/participant should check whether all participants have legal rights.`,
          `4. The company will not be responsible for the problems caused because of signature by requester/participant without legitimate authority.`,

          `Obligation to maintain e-mail account`,
          `The contents regarding electronic signature will be sent to the signature participants’ e-mail. Signature requester and participant can always be available to receive the contents regarding the electronic signature, and the company will not be responsible for the problems occurred from the signature requester/participant’s insecurity such as e-mail deletion or rejection.`,

          `Obligation to secure the documents`,
          `Signature requester and participant should not leak any issues regarding the process of signing such as link, doucments or audit trail certificate through e-mail or sharing without mutual agreement.`,

          `Document storage`,
          `The process of signing and documents will be stored in the server and block chain, and members can always access to the documents through service.`,

          `Rejection and cancellation of signature`,
          `Signature requester and participant can cease the process of signing through rejection or cancellation before all participant completes signing.`,

          `Minimum environment for usage`,
          `Users should proceed with signing under at least the following environment. The company will not be responsible for the problems caused by not following the instruction.`,
          `\u2022 Browser : Chrome(at least 49), Safari(at least 9.0), Firefox(at least 43.0)`,
          `\u2022 Cookies : Allow all cookies`,

          `Governing Law`,
          `Issues regarding the electronic signature and legal effects of it will be judged by the law of Republic of Korea, and will be interpreted by the law of Republic of Korea.`,

          `Obligation of company`,
          `The company will do its best to keep the service in the best possible environment.`,
          `If legal conflict occurs although the electronic signature is signed through methods abiding by these terms, the company will fully cooperate to solve the conflict, such as submitting the block chain verification information and audit trail log to be used as evidence in court.`,

          `\nOther details regarding the company’s obligation will abide by the terms of service.`,

          `\nEnactment Date: 2021. 12. 01`,
        ],
      },
      marketing: {
        title: `Consent to use of marketing (optional)`,
        table: {
          headers: [
            "Purpose of collection and usage",
            "Collected categories",
            "Retention Period",
            "Agree / Disagree",
          ],

          body: [
            [
              "Utilization for marketing and advertising (optional): development and specialization of new services (products), delivery of advertising information such as events",
              "Name, Date of birth, cell phone number, access frequency, telecommunication service provider, domestic/foreign indication,  Coded user identification value(CI), Duplicate registration confirmation information(DI)",
              "1 year after the last access to the service or withdrawal from membership, or retention period set by the regarding law",
              "baSalt Registration (optional)",
            ],
          ],
        },
        text: `*** You have the right not to consent to the collection and use of your personal information. However, if you refuse to consent, there may be some restrictions on smooth service provision.\n*** General matters related to the collection and use of personal information in accordance with laws and consignment processing of personal information for contract implementation and convenience enhancement, etc., are subject to the personal information processing policy of Choi & Lee Law Firm.`,
      },
      cookies: {
        title: "baSalt Cookie policy",
        body: [
          `baSalt uses ‘cookies’ that stores and updates users’ information in order to provided our users with better service. A cookie is a small text piece sent from the operation server(HTTP) to a user’s computer browser, and can be stored inside a user’s computer hard disk.
          \n`,
          `1. Purpose of using cookies`,
          `It is used to provide optimized information to the users by acknowledging the log-in records for websites and services that the users have visited.
          \n`,
          `2. Installation/Operation and Rejection for the cookies`,
          `Users can choose whether to install the cookies or not. This means the users can set option in the web browser to allow all cookies, check every time a cookie is being stored, or reject all cookie storage. Users can choose the web browser option to allow every cookies/check for each cookie/or reject all cookie storage.
          \n`,

          `How to set option(ex. in case of internet explorer) : ‘Tools’ tab in the upper side of web browser > Internet options > personal information\nPlease be informed that if you reject the storage of cookies, you can have certain difficulties in using our services.\n`,
        ],
      },

      terms: {
        title: "baSalt Terms of Service",
        button_info: "baSalt Statement on privacy",
        button_sign: "baSalt terms for electronic signature",
        body: [
          {
            title: `Welcoming words`,
            body: `Thank you for using the service provided by RedWit Inc. You will be agreeing to the following terms if you wish to use our service or register as a member, so please take time to read the following terms carefully.
                    \n`,
          },

          {
            title: `Purpose of Service`,
            body: `The purpose of these terms is to set the rights, obligations and responsibilities of the users in making use of the service that basalt, a document management solution operated by RedWit Inc., provides.
                \n`,
          },

          {
            title: `Definition of words`,
            body: `  1. “Service” refers to baSalt, the block chain based service. (URL : https://basalt.so)
  2. “Company” refers to RedWit Inc. that operates baSalt.
  3. “User” refers to the members and non-members who access the basalt and makes use of the service that basalt provides due to these terms.
  4. “Member” refers to the users who have registered according to the procedures set by the company, and those who can access continuously to the baSalt services.
  5. “Non-members” refers to the users who have not registered and can only access to limited baSalt services.
  6. “Electronic signature” refers to the electronic information that are attached to electronic documents or logically combined to verify the person who signed or to verify whether the person has signed the document.
  7. “Signature requester” refers to the members who request electronic signature for the documents to the signature participant.
  8. “Signature participant” refers to the users who are requested or have signed the document with the request of signature requester.
  \n`,
          },

          {
            title: `Clarification and setting/revision of the terms`,
            body: `1. baSalt posts these terms on baSalt’s website(https://basalt.so/) for the users to easily comprehend.\n2. baSalt can revise these terms unless it does not violate the regarding laws such as 「Act on Consumer Protection in Electronic Commerce, etc.」, 「Act on Regulation of terms」, 「Framework act on Electronic Documents and Electronic Transactions」, 「Act on Electronic Financial Transactions」, 「Act on Electronic Signature」, 「Act on Utilization of information network and Information Protection」, 「Act on door-to-door sales」, and 「Framework Act on consumers」.\n3. The company can revise these terms. In case of revision, the date of application and the reason for revision must be posted on the starting screen of basalt with the current terms, 7 days prior to the application date until the day before the application date. But, in case the revision unfavors the uses, there should be at least 30 days period before the actual application. In this case, basalt should clearly compare the revised version with the version before revision and post it so that the users can comprehend it easily.\n4. If the revision contents following the prior paragraph unfavor the customers, users can always cancel the contract and withdraw from the membership, and basalt should seek to not give any disadvantage to the users.\n5. Issues that are not stated in these terms and the interpretation of these terms will follow the Act on Regulation of Terms, Customer protection acts and related laws in Electronic Commerce set by the Fair Trade Commission, or business practices.
                \n`,
          },

          {
            title: `Provision and Modification of Service`,
            body: `1)  Basalt works on the followings 
      1. Provision of activity log of teammates within the workspace
      2. Document uploads and Provision of block chain certificate
      3. Provision of creation of external sharing link for documents
      4. Provision of NDA document storage and corporate stamp/signature sharing
      5. Provision of inside report on documents that are externally shared
      6. NDA attachment provided in case of document sharing
      7. Provision of management function on NDA signed documents
  2) In cases such as modifying the service’s technical specification, basalt should immediately notify the modified contents and application date on the current
  location of service contents.
  3) If the contents of service that are set by contract between the user and basalt is being modified due to technical specification or policy revision, the reason
  for modification must be notified by possible methods to the users.
                 \n`,
          },

          {
            title: `Abortion and Restriction of Service`,
            body: `1. The company can temporarily abort the provision of service in order to enhance the quality of service by acts such as equipment maintenance, replacement, regular inspection, and construction.\n2. The company can temporarily abort the provision of service and users’ accounts in cases of urgent issues such as unexpected cyber hacking or computer virus attack, in order to protect the users’ accounts.\n3. The company can restrict the use of an account if there is a significant reason to believe that the account is being used for cyber hacking or computer virus attack. The user can object to this restriction, and if the objection is deemed to be rightful, the company must stop the restriction immediately.\n4. The company does not take responsibility for the losses of users caused by the issues regarding paragraph 1 to 3, with the exception for cases when the act was on purpose or when it was due to the company’s significant mistake.
                \n`,
          },

          {
            title: `Contract and Registration`,
            body: `1) The contract of use is set when a person who wishes to be a member consents to be contents of these terms and enters the member information set by
      baSalt’s registration format, then agreeing to the following terms by requesting registration, followed by the company’s approval or registration.
      The registration approval will be notified through website. 
2) baSalt will register a user who requested following the issue 1 as a member as long as the user does not match any of the following issues.
      1. In case the user who requested the registration has a history of disqualification, but with an exception for those who have been 3 years since the 
          disqualification andhave been approved of re-registration from basalt due to Article 7, paragraph 3.
      2. In case there is false, empty or wrong content in registration
      3. In case the registration of the user is unavailable due to baSalt’s current technology
3) If the contents of registration have been modified, the user must inform basalt about the modification within the set period through modification of member information, etc. 
                \n`,
          },

          {
            title: `Notice for the members`,
            body: `1. In case of notification, baSalt can use the e-mail or phone number that the members provided to basalt when registering. This can be rejected if the user does not want to receive them.\n2. For notification to the non-member users, baSalt can replace individual notification by notifying the content on the basalt website for more than a week.
                \n`,
          },
          {
            title: `Withdrawal from membership and disqualification`,
            body: `1) A member can always request for withdrawal from membership to basalt, and basalt will immediately process it.
2) baSalt can restrict or stop the membership of a member if the member is one of the followings:
      1. False content in registration
      2. Not paying the necessary payments or member’s debt held through basalt.
      3. Threatening the order related with service usage, such as tampering with other people’s basalt use or theft of information.
      4. Taking actions through baSalt that violates these terms for social practices.
      5. Forgering or tampering other users’ research notes on basalt service without the legal rights
      6. Using baSalt as a means that do not fit the purpose of basalt service, such as filming or uploading pornography, illegal pictures or illegal documents.
      7. Any other actions that obviously violates the social practices
3) Even after restricting or stopping the membership of a member, baSalt can disqualify a member if any of the actions stated in paragraph 2 is repeated twice or more, or if the action is not fixed within 30 days. The company can provide the member an opportunity to explain him/herself.
4) The registration will be deleted when a member is disqualified by basalt. The member will be notified of this action.
                 \n`,
          },

          {
            title: `Payment for Subscription`,
            body: `1) A member can request subscription for the company’s plan on basalt website.
2) When requesting subscription, fee for the first 31 days must be pre-paid by registering a card, and regular payment will take place every 31 days. If credits remain on the regular payment date, the credits will be used first followed by automatic payment for the unpaid fee.
3) The company can reject the subscription request of a member in one of the following cases
      1. In case there is false, empty or wrong content in the request
      2. In case the approval of subscription is unavailable due to baSalt’s current technology or policy.
4) baSalt service contract is considered as set when the company verifies the payment of paragraph 1, then approves and notifies the usage of service through website or app.
5) Unless there is no specific agreement regarding the starting date of service, basalt will do what is necessary for the users to use the basalt service from the date of subscription payment.
                 \n`,
          },

          {
            title: `Modification and Cancellation of subscription`,
            body: `1) A member who has requested subscription can request for payment modification or cancellation, and basalt must immediately reply to the user’s request. But if the payment have taken place, Article 11 refund policy will be applied.
2) If the number of subscription members is modified, these will be the procedures.
      A. In case of excluding a subscription member, the following fee will be automatically accumulated as credit :
      Number of excluded members * (remaining days excluding today) / 31 * payment per person(without VAT)
      B. In case of additional subscription member, the following fee must be paid via credit card:
      Number of additional members * (Remaining days including today) / 31 * payment + VAT 
3) If the subscription is cancelled, the regular payment for next month will not be held, and the service can be used for the remaining days of the plan.
4) Subsctiption will be immediately cancelled if there is no registered card or the payment has failed. 
                 \n`,
          },

          {
            title: `Refund policy`,
            body: `1) If there is no usage record (login) within 7 days from the payment date when the 'member' subscription contract was signed with the 'company', you can request a 100% refund and withdraw the contract from the 'company'.
2) If there is a usage record (login) within 7 days of signing the subscription contract, no refund is possible.
3) No refunds are possible after 7 days from the subscription start date.
4) Changes made to credit are non-refundable.
5) The policy of changing the number of subscribers and changing the plan follows the company's policy.
      \n`,
          },

          {
            title: `Protecting Personal information`,
            body: `1. The company will protect the personal information of the members due to the related laws, and will follow the related laws and company’s own statement on privacy regarding protection and usage of personal information.
2. The company, due to the related laws, does not provide the members’ personal information to a third party without permission of the members, with the exception for cases in which a request from national organization etc. is present.
3. The company will not take responsibility for damage caused by personal information leakage due to insecurity of a member.
4. Users can always reqeust to browse or right the errors regarding his/her personal information that baSalt has, and baSalt is obligated to immediately respond to this. If a user has requested to right the errors in information, baSalt must not use the personal information until the error is fixed. But, if the personal information of the user is stored through block chain system of baSalt service, baSalt can modify or delete the information due to technical reasons.
  \n`,
          },

          {
            title: `Obligation of RedWit(company)`,
            body: `1. The company must not violate these terms, the related laws and the social practices regarding operation of baSalt, and must do its best to provide sustainable and stable service abiding by these terms.
2. The company must have security system for the protection of the users’ personal information for their safe usage of baSalt.
3. The company will not send advertising e-mail for profit to the users who have not agreed to receive them.
  \n`,
          },

          {
            title: `Obligation on the e-mails and passwords of the users`,
            body: `1. The responsibility for the users’ e-mails and passwords lies on the users.
2. A user must not allow his/her e-mail or password to be used be a third party.
3. If a user finds out that his/her e-mail or password is stolen or being used by a third party, he/she must immediately notify basalt and follow baSalt’s guidelines.
  \n`,
          },

          {
            title: `Forbidden acts for users`,
            body: `The users must not do the following acts.
  1. Theft, forgery, or tamping with other people’s documents
  2. Personal information theft
  3. Uploading data irrelevant to baSalt service’s purporse or goal.
  4. Sending or sharing the data of issue 3 to other people
  5. Invading the intellectual property such as copyright of a third party.
  6. Actions that defame a third party’s reputation for tamper with the work task
  7. Actions of using, sharing or sending data that violates public and social practices such as pornography or violent photos.  
                 \n`,
          },

          {
            title: `Copyright belonging`,
            body: `1. The copyright and intellectual property for the literary works from baSalt belongs to baSalt.
2. The copyright and intellectual property for the literary works from a user belongs to the user.
3. A user must not let a third party to use the information which intellectual property belongs to baSalt, for purpose of earning profit through copying, sending, publishing, distributing, announcing or other methods.
4. baSalt must ask for the users’ permission in case of using the literary work which belong to the users for purposes such as service improvement, portfolio or advertisement. But, for the literary work that the user has not obviously declared rejection for usage such as review for baSalt service, it is considered that the user has granted the company the rights ro use the literary work for service advertisement.

                 \n`,
          },

          {
            title: `Transfer of account is forbidden`,
            body: `A user cannot lend any information, password or account regarding the created baSalt account without written agreement from the company in advance. User contract may be terminated in case of violation.
                 \n`,
          },

          {
            title: `Cancellation of contract`,
            body: `1) A user can terminate the user contract by requesting the termination to the company directly. The company must deal with this request abiding by the related laws, etc.
2) The company can take necessary measures such as terminating the user contract or stopping the member’s account when a member has done one of the followings :
    1. when a member has violated article 8, paragraph 13.
    2. Deliverate tampering with service operation
    3. When a member has distributed contents that violate social practices and public order
    4. When the operator has reasonable reasons to disqualify the member, such as sending large amount of information or sending advertisement to tamper with
        the stable operation of the service
    5. Distributing computer virus program
    6. Invading the company’s or a third party’s intellectual property rights
    7. Other actions that are deemed to violate these terms or related laws.
3) When terminating the user contract, the member’s data will be immediately deleted, with the exception for cases in which the company retains the member’s information due to the related laws or the company’s statement on privacy.
  \n`,
          },

          {
            title: `Compensation and limit of responsibility`,
            body: `1. The company or user has responsibility to compensate for the loss caused by violating these terms, with the exception for cases in which the actions were not on purpose, or if no wrong has been performed. 
2. The company will not be responsible for the provision of service in case of natural disaster or situation equivalent with it.
3. The company will not be responsible for service failure caused by problems from deliberate behaviors or insecurity of members.
4. The company will not be responsible for the reliability or credibility of the data uploaded by the members, unless it is on purpose or there is an insecurity
    regarding the matter.
5. The company does not has any obligation to interfere with conflict or interaction between the members, and will not take responsibility for the loss caused
    from them.
6. The company will not be responsible to the loss caused to the members through free service provided by the company, unless it is due to the deliberate
    actions or insecurity of the company.
            
  \n`,
          },

          {
            title: `Solving conflicts`,
            body: `1. The company sets and operates its own damage compensation procedure in order to reflect rightful opinions and complaints of the users and to compensate for the loss.
2. The company will primarily deal with the complaints and opinions from the users. But, if quick response cannot be done, the user will be notified of the reason and the date of solution.
  \n`,
          },

          {
            title: `Governing Law and Jurisdiction`,
            body: `These terms are judged and interpreted by the laws of Republic of Korea.\nIn case a lawsuit is filed because of the conflict between the company and user, the court jurisdiction will be determined by the procedures set by civil procedure law.
                 \n`,
          },

          {
            title: `Application of Electronic Signature Usage Terms`,
            body_1: `If a member requests signature participant an electronic signature as a signature requester, `,
            link: `the electronic signature usage terms`,
            body_2: ` will be applied as well.
                 \n`,
          },

          {
            title: ``,
            body: `Application date of Usage Terms 2021. 12. 01.`,
          },
        ],
      },

      refund: {
        title: "baSalt Refund Policy",
        button_info: "baSalt Statement on privacy",
        button_term: "baSalt Terms of Service",
        body: [
          {
            title: `Plan payment guidelines and refund policy`,
            body: `A member who has applied for a purchase of the Plan may request to change or cancel the purchase application immediately after payment if there is any discrepancy in the expression of intention, and “baSalt” shall process the member’s request without delay. However, if the payment has already been made, the refund standards of “baSalt” will follow.
                  \n`,
          },

          {
            title: `Payment Guidelines`,
            body: `1. In principle, the payment of 'company' and 'member' is prepaid for subscription.
2. When an additional guest is invited, it is considered as an additional person and the additional payment is made automatically.
3. If there is credit in the workspace, the remaining amount is automatically paid after the credit is exhausted.
\n`,
          },

          {
            title: `Refund Policy`,
            body: `1. If there is no usage record (login) within 7 days from the payment date when the 'member' subscription contract was signed with the 'company', you can request a 100% refund and withdraw the contract from the 'company'.
2. If there is a usage record (login) within 7 days of signing the subscription contract, no refund is possible.
3. No refunds are possible after 7 days from the subscription start date.
4. Changes made to credit are non-refundable.
5. The policy of changing the number of subscribers and changing the plan follows the company's policy.
\n`,
          },
        ],
      },
    },
  },
};
