import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./styles/ButtonWithIcon";
import clsx from "clsx";
import EmailIcon from "@material-ui/icons/Email";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import PersonAddRoundedIcon from "@material-ui/icons/PersonAddRounded";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ReactComponent as TeamIcon } from "@ui/basalt/images/buttons/team-24.svg";
import { ReactComponent as EditIcon } from "@ui/basalt/images/buttons/edit-20.svg";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import CustomButton, { CustomButtonProps } from "./CustomButton2";

type Icon =
  | "email"
  | "member"
  | "add"
  | "upload"
  | "download"
  | "link"
  | "more"
  | "team"
  | "edit"
  | "delete";

export type ButtonWithIconProps = CustomButtonProps & {
  icon: Icon;
};

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  icon,
  children,
  ...buttonProps
}: ButtonWithIconProps) => {
  const classes = useStyles();
  return (
    <CustomButton {...buttonProps}>
      <Grid className={classes.content}>
        <Adornment icon={icon} className={classes.adornment} />
        {children}
      </Grid>
    </CustomButton>
  );
};

type AdornmentProps = {
  icon?: Icon;
  className?: string;
};

const Adornment = (props: AdornmentProps) => {
  const classes = useStyles({});
  switch (props.icon) {
    case "email":
      return <EmailIcon className={props.className} />;
    case "member":
      return (
        <PersonAddRoundedIcon
          className={clsx(classes.horizontalFlip, props.className)}
        />
      );
    case "add":
      return <AddRoundedIcon className={classes.addIcon} />;
    case "upload":
      return <PublishRoundedIcon className={props.className} />;
    case "download":
      return <GetAppRoundedIcon className={props.className} />;
    case "link":
      return <LinkRoundedIcon className={props.className} />;
    case "more":
      return <MoreVertIcon className={props.className} />;
    case "team":
      return <TeamIcon className={props.className} />;
    case "edit":
      return <EditIcon className={props.className} />;
    case "delete":
      return <DeleteRoundedIcon className={props.className} />;
    default:
      return <></>;
  }
};

export default ButtonWithIcon;
