import {
  imageExtensionList,
  heicExtensionList,
  officeExtensionList,
  pdfExtensionList,
} from "@basalt-commons/api/request/note";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { mkAuthHeader, mkGetURL } from "@redwit-commons/utils/request";
import { ENV } from "./api";

const uploadFileIPFS = async (token: string, file: File, extension: string) => {
  const data = new FormData();
  data.append("file", file);
  const allowList = [
    ...imageExtensionList,
    ...heicExtensionList,
    ...officeExtensionList,
    ...pdfExtensionList,
  ];
  if (!allowList.includes(extension)) {
    throw mkErr({
      kind: InternalErrorKind.Abort,
      loc: "uploadFileIPFS",
      msg: "invalid file type",
    });
  }

  const response = await fetch(
    mkGetURL(ENV.API_SERVER, `ipfs/upload.${extension}`, {}),
    {
      headers: { ...mkAuthHeader(token) },
      method: "POST",
      body: data,
    }
  );
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "uploadFileIPFS",
      code: response.status,
      text: await response.text(),
    });
  }
  return await response.text();
};

// token: signed DocumentTokenObject
const uploadGuestFileIPFS = async (
  token: string,
  file: File,
  extension: string
) => {
  const data = new FormData();
  data.append("file", file);
  const allowList = [
    ...imageExtensionList,
    ...heicExtensionList,
    ...officeExtensionList,
    ...pdfExtensionList,
  ];
  if (!allowList.includes(extension)) {
    throw mkErr({
      kind: InternalErrorKind.Abort,
      loc: "uploadGuestFileIPFS",
      msg: "invalid file type",
    });
  }

  const response = await fetch(
    mkGetURL(ENV.API_SERVER, `ipfs/guest/upload.${extension}`, {}),
    {
      headers: { ...mkAuthHeader(token) },
      method: "POST",
      body: data,
    }
  );
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "uploadGuestFileIPFS",
      code: response.status,
      text: await response.text(),
    });
  }
  return await response.text();
};

// React-native 전용
const uploadImageIPFS = async (
  token: string,
  uri: string,
  fileExtension: string
) => {
  const data = new FormData();
  /* eslint-disable @typescript-eslint/no-explicit-any */
  data.append("file", {
    uri,
    type: `image/${fileExtension}`,
    name: "GoonoPhoto",
  } as any); // any: React-native 의 파일과 호환을 유지하기 위함
  /* eslint-enable @typescript-eslint/no-explicit-any */
  const allowList = [...imageExtensionList, ...heicExtensionList];

  if (!allowList.includes(fileExtension)) {
    throw mkErr({
      kind: InternalErrorKind.Abort,
      loc: "uploadImageIPFS",
      msg: "invalid file type",
    });
  }

  const response = await fetch(
    mkGetURL(ENV.API_SERVER, `ipfs/upload.${fileExtension}`, {}),
    {
      headers: { ...mkAuthHeader(token) },
      method: "POST",
      body: data,
    }
  );
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "uploadImageIPFS",
      code: response.status,
      text: await response.text(),
    });
  }
  return await response.text();
};

export const getIPFSUrl = (cid: string) => {
  return `https://ipfs.redwit.io/ipfs/${cid}`;
};

export const getIPFSNote = (cid: string | undefined) => {
  if (cid === undefined) {
    // default_note.png
    return "https://ipfs.redwit.io/ipfs/QmSRNyHrKMDuyNR5RvkY6u2DzvuhiHD8gyqyd6YijPJrPX";
  }
  return `https://ipfs.redwit.io/ipfs/${cid}`;
};

/**
 *
 * @param token
 * @param taskId
 * @param userId
 * @returns cid
 */
const getIPFSPdfUrl = async (token: string, taskId: string, userId: string) => {
  const response = await fetch(
    mkGetURL(
      ENV.API_SERVER,
      `ipfs/cids/users/${userId}/tasks/${taskId}/main.pdf`,
      {}
    ),
    {
      headers: { ...mkAuthHeader(token) },
      method: "GET",
    }
  );
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "getIPFSPdfUrl",
      code: response.status,
      text: await response.text(),
    });
  }
  return await response.text();
};
/**
 * zip 파일 조회
 * @param token
 * @param taskId
 * @param userId
 * @returns cid
 */
const getIPFSZipUrl = async (token: string, taskId: string, userId: string) => {
  const response = await fetch(
    mkGetURL(
      ENV.API_SERVER,
      `ipfs/cids/users/${userId}/tasks/${taskId}/main.zip`,
      {}
    ),
    {
      headers: { ...mkAuthHeader(token) },
      method: "GET",
    }
  );
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "getIPFSZipUrl",
      code: response.status,
      text: await response.text(),
    });
  }
  return await response.text();
};

const getFileSizeIPFS = async (token: string, cid: string) => {
  const response = await fetch(
    mkGetURL(ENV.API_SERVER, `ipfs/size/${cid}`, {}),
    {
      headers: { ...mkAuthHeader(token) },
      method: "GET",
    }
  );
  if (!response.ok) {
    /// 응답이 왔는데 에러인게 더 hard 함
    /// TODO 혹시 이를 다른 에러 타입으로 구분해야 하나?
    throw mkErr({
      kind: InternalErrorKind.ResponseCode,
      loc: "getFileSizeIPFS",
      code: response.status,
      text: await response.text(),
    });
  }
  return await response.text();
};

const getTotalSizeIPFS = (_token: string, _userId: string) => {
  /**
   * @stub get total User's storage size
   */
  return -1;
};

export default {
  uploadFileIPFS,
  uploadGuestFileIPFS,
  uploadImageIPFS,
  getIPFSPdfUrl,
  getIPFSUrl,
  getFileSizeIPFS,
  getTotalSizeIPFS,
  getIPFSZipUrl,
};
