import React from "react";
import { Grid } from "@material-ui/core";

type ScreenLoadingViewProps = {
  width: number;
  height: number;
};
class ScreenLoadingView extends React.PureComponent<ScreenLoadingViewProps> {
  static defaultProps = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  constructor(props: ScreenLoadingViewProps) {
    super(props);
  }

  render() {
    const { width, height } = this.props;
    return (
      <Grid
        xs={12}
        container
        item
        alignItems="center"
        justifyContent="center"
        style={{
          width,
          height,
          backgroundColor: "white",
          position: "absolute",
        }}
      >
        <img
          src={require("@ui/basalt/images/loading.gif")}
          style={{ width: 120, height: 120, objectFit: "contain" }}
        />
      </Grid>
    );
  }
}

export default ScreenLoadingView;
