import T from "@redwit-commons/utils/typecheck";
import { addTXInfo, TXInfo } from "./tx_info";

export interface NoteTagMapObjectCore {
  readonly NoteId: string;
  readonly TagId: string;
  readonly score?: number;
}

export const NoteTagMapObjectSchema = T.object()
  .addField("NoteId", T.string())
  .addField("TagId", T.string())
  .addField("score", T.integer(), false);

export interface TagObjectCore {
  readonly id: string;
  readonly type: "note" | "project";
  readonly value: string;
}

//  withAccessTime won't be needed
export type TagObject = TagObjectCore;

export enum TagType {
  NOTE = "note",
  PROJECT = "project",
}

export enum CommentType {
  OCR = "googleOcr", // XXX 현재 이름이 마음에 안드는데 어떻게 해야 할 지...
  TSA_TOKEN = "tsaToken", // Base64 encoded ASN.1 object from TSA
  GITHUB = "github",
  TEST = "test",
}

export interface CommentObjectCore {
  readonly id: string;
  readonly type: "googleOcr" | "test" | "tsaToken" | "github";
  readonly provider?: string;
  readonly text: string;
  readonly NoteId: string;
}

export type CommentObject = CommentObjectCore;

export const CommentObjectSchema = T.object()
  .addField("id", T.string())
  .addField(
    "type",
    T.string().withEnum(["googleOcr", "test", "tsaToken", "github"])
  )
  .addField("text", T.string())
  .addField("provider", T.string(), false)
  .addField("NoteId", T.string());

export const TagObjectSchema = T.object()
  .addField("id", T.string())
  .addField("type", T.string().withEnum(["note", "project"]))
  .addField("value", T.string());

export const addTag = (from: T) => {
  return from
    .addField("Tags", T.array(TagObjectSchema.clone()))
    .addField("Comments", T.array(CommentObjectSchema.clone()));
};

export const extractTagObject = T.mkObjectExtractor<TagObject>(TagObjectSchema);
export const extractCommentObject =
  T.mkObjectExtractor<CommentObject>(CommentObjectSchema);

export const CommentEssentialSchema = T.object()
  .addField("id", T.string())
  .addField("type", T.string().withEnum(["tsaToken"]))
  .addField("text", T.string())
  .addField("NoteId", T.string());

export const CommentEssentialWithTXSchema = addTXInfo(
  CommentEssentialSchema.clone()
);

export type CommentEssential = Omit<CommentObjectCore, "type"> & {
  /**
   * tsaToken 이외의 다른 개인정보를 실수로 올리는 것을 미연에 방지
   */
  readonly type: "tsaToken";
};

/**
 * 성공한 note essential 을 불러올 때 필요
 */
export type CommentEssentialWithTX = CommentEssential & TXInfo;

export const extractCommentEssentialWithTX =
  T.mkObjectExtractor<CommentEssentialWithTX>(CommentEssentialWithTXSchema);
export const extractCommentEssential = T.mkObjectExtractor<CommentEssential>(
  CommentEssentialSchema
);
