import React from "react";

import {
  Button,
  Grid,
  InputBase,
  IconButton,
  InputAdornment,
  WithStyles,
  withStyles,
  ClickAwayListener,
  Avatar,
} from "@material-ui/core";
import SpoqaHansTypography from "../components/pure/typo/SpoqaHansTypography";
import SearchIcon from "@material-ui/icons/Search";
import HeaderLogo from "@ui/basalt/images/header-docs-logo3x.png";
import SearchWordsButton from "../components/pure/buttons/SearchWordsButton";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { TokenStateStatus } from "../store/reducers/token";
import { ProjectStateStatus } from "../store/reducers/project";
// import ProfileCard from "../components/pure/cards/ProfileMenuCard";
import {
  doSearchAction,
  SearchActionKind,
  SearchStateStatus,
} from "../store/reducers/search";
import { ScreenURL } from "../routes/RouteList";

import { doPersistAction, PersistActionKind } from "../store/reducers/persist";
import translate from "../utils/translate";
import styles from "./styles/ServiceHeader";
import CustomButton from "../components/pure/buttons/CustomButton";
import {
  AlarmActionKind,
  AlarmStateStatus,
  doAlarmAction,
} from "../store/reducers/alarm";
import { FutureComponent } from "@redwit-react-commons/template/FutureComponent";
import clsx from "clsx";
import { WorkspaceStateStatus } from "../store/reducers/workspace";
import AlarmButton from "../components/pure/buttons/AlarmButton";
import {
  AlarmObject,
  AlarmType,
} from "@basalt-commons/global-api/object/alarm";
import { MenuRounded } from "@material-ui/icons";
import AccountMenu from "./AccountMenu";
import { ReactComponent as IconPerson } from "@ui/basalt/images/sidebar/ic-person-20.svg";
import {
  doModalAction,
  ModalActionKind,
  ModalType,
} from "@src/store/reducers/modal";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";
// [TODO::Disabled] Search 기능 비활성화 일단
// import SearchRounded from "@material-ui/icons/SearchRounded";

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    project: state.project,
    alarm: state.alarm,
    searchRed: state.search,
    workspace: state.workspace,
  };
};

type UserInfo = {
  profile_cid: string | undefined;
  user_name: string;
  user_email: string;
  user_id: string;
  time_zone: string | undefined;
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type ServiceHeaderProps = {
  showSearchInput?: boolean;
  onOpenSidebar?: () => void;
} & RouteComponentProps<Props> &
  PropsFromRedux &
  WithStyles<typeof styles>;

type ServiceHeaderState = {
  hoverAccount: boolean;
  anchorEl: null | HTMLElement;
  anchorElSerach: null | HTMLElement;
  searchWord: string;
};

class ServiceHeader extends FutureComponent<
  ServiceHeaderProps,
  ServiceHeaderState
> {
  constructor(props: ServiceHeaderProps) {
    super(props);
    this.state = {
      hoverAccount: false,
      anchorEl: null,
      anchorElSerach: null,
      searchWord: "",
    };
  }

  componentDidMount() {
    super.componentDidMount();
    // const { dispatch } = this.props;
    try {
      // this.scheduleBackgroundTask(async () => {
      //   await doAlarmActionAsync(dispatch, {
      //     kind: AlarmActionKind.TRY_GET_ALARM,
      //     workspaceName: this.props.match.params.workspace_name,
      //   });
      //   this.setBackgroundDelay(60000);
      //   return true;
      // });
    } catch {
      //ignore
    }
  }

  getSelectedWorkspace() {
    const { workspace } = this.props;
    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS) return;
    return workspace.state.selectAuthWorkspace;
  }

  getUserInfoWithDefaultValue() {
    const { token } = this.props;
    let userInfo: UserInfo = {
      profile_cid: undefined,
      user_name: "Unknown",
      user_email: "-",
      time_zone: undefined,
      user_id: "Unknown",
    };
    if (token.state.status === TokenStateStatus.SUCCESS) {
      userInfo = {
        profile_cid: token.state.profile_cid,
        user_name: token.state.name,
        user_email: token.state.email,
        time_zone: token.state.time_zone,
        user_id: token.state.id,
      };
    }
    return userInfo;
  }

  getProjectsFilteredBySearchQuery() {
    const { project } = this.props;
    const { searchWord } = this.state;
    if (
      project.state.status === ProjectStateStatus.INIT ||
      searchWord.trim().length < 1
    )
      return [];
    return project.state.projects.filter((pj) => {
      return pj.name.includes(searchWord);
    });
  }

  /**
   * 알림 메뉴가 꺼지면 실행되어야 합니다
   */
  makeAllAlarmsRead() {
    doAlarmAction(this.props.dispatch, {
      kind: AlarmActionKind.TRY_CHECK_ALARM,
    });
    return;
  }

  onDeleteAlarm(alarm: AlarmObject) {
    doAlarmAction(this.props.dispatch, {
      kind: AlarmActionKind.TRY_DELETE_ALARM,
      alarmId: alarm.id,
    });
    return;
  }

  naviagteByAlarmType(alarm: AlarmObject) {
    const currentWorkspace = this.getSelectedWorkspace();
    if (currentWorkspace === undefined) return;

    switch (alarm.type) {
      case AlarmType.MENTION: {
        if (alarm.NoteId === undefined || alarm.ProjectId === undefined) return;
        this.props.history.push(
          `/${currentWorkspace.name}/projects/${alarm.ProjectId}/${alarm.NoteId}/preview`
        );
        return;
      }
      case AlarmType.INVTIE_PROJECT: {
        if (alarm.ProjectId === undefined) return;
        this.props.history.push(
          `/${currentWorkspace.name}/projects/${alarm.ProjectId}`
        );
        return;
      }
      case AlarmType.NEW_RULE: {
        if (alarm.RuleId === undefined) return;
        this.props.history.push(
          `/${currentWorkspace.name}/rule/sign/${alarm.RuleId}`
        );
        return;
      }
    }
  }

  onToggleOnboard() {
    doPersistAction(this.props.dispatch, {
      kind: PersistActionKind.TOGGLE_ONBOARD,
    });
  }

  clearSearchInputWithCallback(callback: () => void = () => {}) {
    callback && callback();
    this.setState({ anchorElSerach: null, searchWord: "" });
  }

  onClickSearch() {
    const { workspace } = this.props;

    if (workspace === undefined) return;
    doModalAction(this.props.dispatch, {
      kind: ModalActionKind.TRY_OPEN,
      type: ModalType.SEARCH,
      onSubmit: () => {
        return undefined;
      },
    });
  }

  renderHeaderLogoButton() {
    const { classes } = this.props;
    return (
      <Button
        onClick={() => {
          this.props.history.replace(ScreenURL.LANDING_MAIN);
        }}
        className={classes.basaltLogoButton}
      >
        {/* Todo:: svg로 교체 가능한지 (https://github.com/redwit-dev/global-goono-web/issues/173) */}
        <img
          alt="BasaltIcon"
          id="BasaltIcon"
          src={HeaderLogo}
          className={classes.basaltIcon}
        />
      </Button>
    );
  }

  renderHeaderLogoView() {
    // const { classes } = this.props;
    return this.renderHeaderLogoViewForXS();
    // if (window.innerWidth < 970) {
    //   return this.renderHeaderLogoViewForXS();
    // }
    // return (
    //   <Grid container item className={classes.basaltLogoGrid}>
    //     {this.renderHeaderLogoButton()}
    //   </Grid>
    // );
  }

  renderHeaderLogoViewForXS() {
    const { classes } = this.props;
    return (
      <Grid
        container
        item
        alignItems="center"
        className={classes.logo_grid}
        style={{ flexWrap: "nowrap" }}
      >
        <IconButton
          onClick={() => {
            if (this.props.onOpenSidebar !== undefined) {
              this.props.onOpenSidebar();
            }
          }}
          className={classes.xs_btn}
        >
          <MenuRounded className={classes.xs_icon} />
        </IconButton>
        {this.renderHeaderLogoButton()}
      </Grid>
    );
  }

  renderHeaderSearchInputGrid() {
    if (this.props.showSearchInput === false) return;
    const { classes } = this.props;
    return (
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        className={classes.searchGrid2}
        style={{ display: window.innerWidth < 1032 ? "none" : undefined }}
        onClick={(evt) => {
          this.setState({ anchorElSerach: evt.currentTarget }, () => {
            doSearchAction(this.props.dispatch, {
              kind: SearchActionKind.TRY_READY,
            });
          });
          return;
        }}
      >
        <InputBase
          placeholder={translate.service_header.search.placeholder}
          className={classes.searchInput}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          }
        />
      </Grid>
    );
  }

  renderHeaderSearchQueriedMenuItems() {
    const { classes, searchRed } = this.props;
    const currentWorkspace = this.getSelectedWorkspace();
    if (this.state.searchWord.length < 1) {
      const searchHistories =
        searchRed.state.status !== SearchStateStatus.INIT
          ? searchRed.state.recents.words
          : [];

      return (
        <Grid container direction="column">
          <SpoqaHansTypography
            fontWeight="medium"
            className={classes.recent_searches_title}
          >
            {translate.service_header.search.most_searched}
          </SpoqaHansTypography>
          {searchHistories.map((recent, i) => (
            <Button
              key={i}
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                textTransform: "unset",
              }}
              size="small"
              variant="text"
              onClick={() => {
                this.setState({ anchorElSerach: null }, () => {
                  this.props.history.push(
                    `/${currentWorkspace?.name}/search/${recent.value}?type=workspace`
                  );
                });
              }}
            >
              <SpoqaHansTypography className={classes.recent_searches_text}>
                {recent.value}
              </SpoqaHansTypography>
            </Button>
          ))}
        </Grid>
      );
    }
    return (
      <>
        <SearchWordsButton
          value={this.state.searchWord}
          type={translate.service_header.search.in_this_workspace}
          onClick={() => {
            this.clearSearchInputWithCallback(() =>
              this.props.history.push(
                `/${currentWorkspace?.name}/search/${this.state.searchWord}?type=workspace`
              )
            );
          }}
        />
        <SearchWordsButton
          value={this.state.searchWord}
          type={translate.service_header.search.in_all_workspace}
          onClick={() => {
            this.clearSearchInputWithCallback(() =>
              this.props.history.push(
                `/${currentWorkspace?.name}/search/${this.state.searchWord}?type=all`
              )
            );
          }}
        />
        <SpoqaHansTypography className={classes.folders}>
          {translate.service_header.search.folders}
        </SpoqaHansTypography>
        {this.getProjectsFilteredBySearchQuery().map((pj) => {
          return (
            <SearchWordsButton
              key={pj.id}
              onClick={() => {
                this.clearSearchInputWithCallback(() =>
                  this.props.history.push(
                    `/${currentWorkspace?.name}/projects/${pj.id}`
                  )
                );
              }}
              folder={true}
              value={pj.name}
            />
          );
        })}
        <div className={classes.emptySpace}></div>
      </>
    );
  }

  renderSearchHistoriesAnchorView() {
    if (this.state.anchorElSerach === null) return;
    const { classes } = this.props;
    return (
      <ClickAwayListener
        onClickAway={() => {
          this.clearSearchInputWithCallback();
        }}
      >
        <div
          className={clsx(classes.searchGrid2, classes.searchPopover)}
          style={{
            top: this.state.anchorElSerach.clientTop - 10,
            left: this.state.anchorElSerach.offsetLeft,
            width: this.state.anchorElSerach.clientWidth,
          }}
        >
          <Grid
            direction="row"
            alignItems="center"
            container
            item
            className={classes.searchPopoverGrid}
          >
            <InputBase
              autoComplete="off"
              autoFocus={true}
              value={this.state.searchWord}
              placeholder={translate.service_header.search.placeholder}
              onChange={(evt) => {
                evt.preventDefault();
                this.setState({ searchWord: evt.currentTarget.value });
              }}
              className={classes.searchInputPopover}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              }
            />
            {this.renderHeaderSearchQueriedMenuItems()}
          </Grid>
        </div>
      </ClickAwayListener>
    );
  }

  renderHeaderSearchInputView() {
    const { classes } = this.props;
    if (window.innerWidth < 970) {
      return <>{this.renderSearchHistoriesAnchorView()}</>;
    }
    return (
      <Grid container item alignItems="center" className={classes.searchGrid}>
        {this.renderHeaderSearchInputGrid()}
        {this.renderSearchHistoriesAnchorView()}
      </Grid>
    );
  }

  renderHeaderSignInView() {
    const { classes, token } = this.props;
    if (token.state.status === TokenStateStatus.SUCCESS) return;
    return (
      <React.Fragment>
        <Button
          disableRipple
          onClick={() => {
            this.props.history.push(ScreenURL.SIGN_IN);
          }}
          className={classes.loginButton}
        >
          <SpoqaHansTypography
            fontWeight="bold"
            className={classes.loginButtonText}
          >
            {translate.sign_in}
          </SpoqaHansTypography>
        </Button>
        <CustomButton
          size="small"
          buttonType="primary"
          onClick={() => {
            this.props.history.push(ScreenURL.SIGN_UP);
          }}
        >
          <SpoqaHansTypography
            fontWeight="bold"
            className={classes.signupButtonText}
          >
            {translate.sign_up}
          </SpoqaHansTypography>
        </CustomButton>
      </React.Fragment>
    );
  }

  renderUserProfile() {
    const { token, classes } = this.props;
    const info = this.getUserInfoWithDefaultValue();
    if (token.state.status !== TokenStateStatus.SUCCESS) return;
    return (
      <Grid
        className={clsx(
          classes.header_nav_icon_button,
          classes.person_icon_background
        )}
        onClick={(evt) => {
          this.setState({ anchorEl: evt.currentTarget });
        }}
      >
        {info.profile_cid ? (
          <Avatar
            src={getIPFSUrl(info.profile_cid)}
            className={classes.profile_image}
          />
        ) : (
          <IconPerson
            className={clsx(classes.header_nav_icon, classes.person_icon)}
          />
        )}
      </Grid>
    );

    // return (
    //   <ProfileCard
    //     dropdown
    //     name={userInfo.user_name}
    //     email={userInfo.user_email}
    //     profile_cid={userInfo.profile_cid}
    //     onClick={(evt) => {
    //       this.setState({ anchorEl: evt.currentTarget });
    //     }}
    //   />
    // );
  }

  renderSearchView() {
    const { classes } = this.props;

    return (
      <IconButton
        className={classes.header_nav_icon_button}
        onClick={() => this.onClickSearch()}
      >
        <SearchIcon
          className={clsx(classes.header_nav_icon, classes.search_icon)}
        />
      </IconButton>
    );
  }

  renderHeaderUserInfo(userInfo: UserInfo) {
    const { token } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS) return;
    const { alarm } = this.props;
    const alarms =
      alarm.state.status === AlarmStateStatus.SUCCESS ? alarm.state.alarms : [];

    return (
      <>
        {this.renderSearchView()}
        <AlarmButton
          isMobile={true}
          alarms={alarms}
          timezone={userInfo.time_zone}
          onDeleteAlarm={(alarm) => {
            this.onDeleteAlarm(alarm);
          }}
          onCheckAlarm={(alarm) => {
            this.naviagteByAlarmType(alarm);
          }}
          onReadAllAlarms={() => {
            this.makeAllAlarmsRead();
          }}
        />
        {this.renderUserProfile()}
      </>
    );
  }

  renderHeaderUserAccountView() {
    const { classes } = this.props;
    const info = this.getUserInfoWithDefaultValue();
    const onAnchorElHandler = (value: null | HTMLElement) => {
      this.setState({ anchorEl: value });
    };
    return (
      <>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="flex-end"
          className={classes.account_gird}
        >
          {this.renderHeaderUserInfo(info)}
        </Grid>
        <AccountMenu
          anchorEl={this.state.anchorEl}
          setAnchorEl={onAnchorElHandler}
        />
      </>
    );
    // return (
    //   <Grid item>
    //     <Grid
    //       container
    //       justifyContent="flex-end"
    //       alignItems="center"
    //       className={classes.rightGrid}
    //     >
    //       {token.state.status !== TokenStateStatus.SUCCESS
    //         ? this.renderHeaderSignInView()
    //         : this.renderHeaderUserInfo(info)}
    //       {this.renderAccountMenu(info)}
    //     </Grid>
    //   </Grid>
    // );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        item
        className={classes.root}
        style={{
          userSelect: "none",
        }}
      >
        {this.renderHeaderLogoView()}
        {/* {this.renderHeaderSearchInputView()} */}
        {this.renderHeaderUserAccountView()}
      </Grid>
    );
  }
}

export default connector(withStyles(styles)(withRouter(ServiceHeader)));
