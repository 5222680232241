import React from "react";
import { Button, ButtonProps, FormLabel, Typography } from "@material-ui/core";
import useStyles from "./styles/CustomButton";

type CustomButtonProps = {
  simple?: boolean;
  customId?: string;
  buttonType?:
    | "primary"
    | "secondary"
    | "default"
    | "default_outlined"
    | "white"
    | "danger"
    | "danger_fill"
    | "cyan"
    | "cyan_outlined"
    | "gray_outlined";
  size?: "xs" | "small" | "default" | "large";
  textStyle?: React.CSSProperties;
  textClass?: string;
  adornmentClass?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  htmlFor?: string;
} & Omit<ButtonProps, "size">;

function CustomButton({
  simple = false,
  customId,
  textStyle,
  buttonType,
  size,
  className,
  textClass,
  children,
  adornmentClass,
  startAdornment,
  endAdornment,
  htmlFor,
  ...rest
}: CustomButtonProps) {
  const classes = useStyles();
  const [sizeClassBtn, sizeClassTxt] =
    size === "xs"
      ? [classes.xs, classes.xs_text]
      : size === "small"
      ? [classes.small, classes.small_text]
      : size === "large"
      ? [classes.large, classes.large_text]
      : [classes.default, classes.default_text];

  const buttonTypeClass =
    buttonType === "primary"
      ? classes.primary_button
      : buttonType === "cyan"
      ? classes.cyan_button
      : buttonType === "cyan_outlined"
      ? classes.cyan_outlined_button
      : buttonType === "secondary"
      ? classes.secondary_button
      : buttonType === "danger"
      ? classes.danger_button
      : buttonType === "danger_fill"
      ? classes.danger_fill_button
      : buttonType === "default_outlined"
      ? classes.default_outlined_button
      : buttonType === "white"
      ? classes.white_button
      : buttonType === "gray_outlined"
      ? classes.gray_button
      : classes.default_button;

  const component =
    htmlFor && !rest.disabled ? { component: FormLabel, htmlFor } : {};
  if (simple) {
    return (
      <Button
        {...rest}
        {...component}
        id={customId}
        className={[
          classes.base,
          buttonTypeClass,
          sizeClassBtn,
          className,
        ].join(" ")}
      >
        {startAdornment}
        <Typography
          id={customId}
          noWrap={true}
          className={[classes.default_text, sizeClassTxt, textClass].join(" ")}
          style={{
            ...textStyle,
          }}
        >
          {children}
        </Typography>
        {endAdornment}
      </Button>
    );
  }
  return (
    <Button
      {...rest}
      {...component}
      id={customId}
      className={[classes.base, buttonTypeClass, sizeClassBtn, className].join(
        " "
      )}
    >
      <Typography
        id={customId}
        noWrap={true}
        className={[classes.default_text, sizeClassTxt, textClass].join(" ")}
        style={{
          ...textStyle,
        }}
      >
        {startAdornment && (
          <span id={customId} className={adornmentClass ?? classes.adornment}>
            {startAdornment}
          </span>
        )}
        {children}
        {endAdornment && (
          <span
            id={customId}
            className={adornmentClass ?? classes.adornment_end}
          >
            {endAdornment}
          </span>
        )}
      </Typography>
    </Button>
  );
}

export default CustomButton;
