import React, { RefObject, createRef } from "react";
import {
  Grid,
  WithStyles,
  withStyles,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import SpoqaHansTypography from "@SpoqaHansTypography";
import styles from "./styles/StampInfoItem";
import { StampWithNDA } from "@basalt-commons/global-api/object/stamp";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";
import moment from "moment-timezone";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import translate from "../../../utils/translate";
import KCCTypography from "../typo/KCCTypography";
import OverFlowTextTooltip from "@src/components/pure/utils/OverFlowTextTooltip";

type StampInfoItemProps = {
  stamp: StampWithNDA;
  time_zone?: string;
  format: string;
  hasAuth: boolean;
  onDelete: () => void;
  onViewHistory: () => void;
  onEdit: () => void;
  nda_count: number;
} & WithStyles<typeof styles>;

type StampInfoItemState = {
  hover: boolean;
  anchorEl: null | HTMLElement;
  width: number;
  open: boolean;
};

class StampInfo extends React.PureComponent<
  StampInfoItemProps,
  StampInfoItemState
> {
  itemRef: RefObject<HTMLDivElement>;
  constructor(props: StampInfoItemProps) {
    super(props);
    this.itemRef = createRef();
    this.state = {
      hover: false,
      anchorEl: null,
      width: 0,
      open: false,
    };
  }

  updateWidth() {
    if (
      this.itemRef.current &&
      this.state.width != this.itemRef.current?.clientWidth
    ) {
      this.setState({ width: this.itemRef.current?.clientWidth });
    }
  }

  componentDidMount() {
    this.updateWidth();
  }

  componentDidUpdate() {
    this.updateWidth();
  }

  renderMenu() {
    const { classes, onDelete, onViewHistory, onEdit } = this.props;

    return (
      <>
        <IconButton
          className={classes.menu_button}
          onClick={(evt) => {
            if (this.state.anchorEl === null)
              this.setState({ anchorEl: evt.currentTarget });
          }}
        >
          <MoreVertRoundedIcon className={classes.more_icon} />
        </IconButton>
        <Menu
          getContentAnchorEl={undefined}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.setState({ anchorEl: null });
          }}
          PaperProps={{ className: classes.menu }}
          classes={{ list: classes.menu_list }}
        >
          <MenuItem onClick={onViewHistory} className={classes.menu_item}>
            <SpoqaHansTypography className={classes.menu_text}>
              {translate.nda_stamp_info.history}
            </SpoqaHansTypography>
          </MenuItem>

          {this.props.hasAuth && (
            <MenuItem onClick={onEdit} className={classes.menu_item}>
              <SpoqaHansTypography className={classes.menu_text}>
                {translate.nda_stamp_info.edit}
              </SpoqaHansTypography>
            </MenuItem>
          )}

          {this.props.hasAuth && (
            <MenuItem
              onClick={() => {
                onDelete();
              }}
              className={classes.menu_item}
            >
              <SpoqaHansTypography
                className={[classes.menu_text, classes.delete].join(" ")}
              >
                {translate.nda_stamp_info.delete}
              </SpoqaHansTypography>
            </MenuItem>
          )}
        </Menu>
      </>
    );
  }

  render() {
    const { stamp, time_zone, format, classes } = this.props;
    return (
      <Grid
        container
        item
        xs={window.innerWidth < 1280 ? 4 : 3}
        className={classes.item_grid}
      >
        <Grid
          container
          onMouseEnter={() => {
            this.setState({ hover: true });
          }}
          onMouseLeave={() => {
            this.setState({ hover: false });
          }}
          className={classes.item_container}
          ref={this.itemRef}
        >
          {(this.state.hover || this.state.anchorEl) && this.renderMenu()}
          <Grid
            container
            item
            className={classes.img_container}
            justifyContent="center"
            alignItems="center"
          >
            {stamp.stamp_cid && (
              <img
                className={classes.img}
                alt={stamp.name}
                src={stamp.stamp_cid ? getIPFSUrl(stamp.stamp_cid) : undefined}
              />
            )}
            {!stamp.stamp_cid && (
              <KCCTypography
                style={{
                  fontSize: 40,
                }}
              >
                {stamp.User.name}
              </KCCTypography>
            )}
          </Grid>
          <Grid
            container
            item
            direction="column"
            className={classes.info_container}
          >
            <SpoqaHansTypography
              fontWeight="bold"
              className={classes.stamp_nda_count}
            >
              {this.props.nda_count}
              {translate.nda_stamp_info.nda_count}
            </SpoqaHansTypography>
            <OverFlowTextTooltip
              arrow
              title={`${stamp.name ? stamp.name : "undefined stamp name"}`}
              placement="bottom"
              viewOverflow={true}
            >
              <SpoqaHansTypography
                fontWeight="medium"
                className={classes.stamp_name}
                noWrap
              >
                {stamp.name ? stamp.name : "undefined stamp name"}
              </SpoqaHansTypography>
            </OverFlowTextTooltip>
            <SpoqaHansTypography noWrap className={classes.stamp_create_date}>
              {moment(stamp.createdAt)
                .tz(time_zone === undefined ? moment.tz.guess(true) : time_zone)
                .format(format)}
            </SpoqaHansTypography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(StampInfo);
