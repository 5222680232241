import T from "@redwit-commons/utils/typecheck";
import {
  GoonoArrayResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
  GoonoResponse,
  GoonoPendingTasks,
} from "./common";
import {
  NoteObject,
  AuthNoteObject,
  AuthNoteObjectSchema,
  NoteObjectSchema,
  NoteTagObjectSchema,
  NoteObjectWithDocumentsSchema,
  NoteObjectWithDocuments,
} from "../object/note";
import { TaskObject, TaskObjectSchema } from "../object/task";
import { ProjectObject, ProjectObjectSchema } from "../object/project";
import { TagObject, TagObjectSchema } from "../object/tag";

/**
 * {@type NoteObject[]}로 rtn하는 공통된 Response가 많아서 네이밍 변경 했습니다.
 *
 * */
export type NoteListResponse = GoonoArrayResponse<NoteObject>;
export const NoteListSchema = mkArrayResultsSchema(NoteObjectSchema);
export const validateNoteListResponse =
  T.mkValidator<NoteListResponse>(NoteListSchema);

export type NoteWithDocumentsListResponse =
  GoonoArrayResponse<NoteObjectWithDocuments>;
export const NoteWithDocuemntsListSchema = mkArrayResultsSchema(
  NoteObjectWithDocumentsSchema
);
export const validateNoteWithDocuementsListResponse =
  T.mkValidator<NoteWithDocumentsListResponse>(NoteWithDocuemntsListSchema);

export type OGetNotePDF = GoonoResponse<TaskObject> &
  GoonoPendingTasks<{ pdf: string }>;
export const OGetNotePDFSchema = mkResponseSchema(
  TaskObjectSchema,
  T.object().addField("pdf", T.string())
);
export const validateOGetNotePDF =
  T.mkValidator<OGetNotePDF>(OGetNotePDFSchema);

export type OMergeNotes = GoonoResponse<NoteObject> &
  GoonoPendingTasks<{ pdf: string }>;
export const OMergeNotesSchema = mkResponseSchema(
  NoteObjectSchema,
  T.object().addField("pdf", T.string())
);
export const validateOMergeNotes =
  T.mkValidator<OMergeNotes>(OMergeNotesSchema);

export type OGetAuthNotes = GoonoArrayResponse<AuthNoteObject>;
export const OGetAuthNotesSchema = mkArrayResultsSchema(AuthNoteObjectSchema);
export const validateOGetAuthNotes =
  T.mkValidator<OGetAuthNotes>(OGetAuthNotesSchema);

export interface OSearchResults {
  readonly results_hash_tag: Array<NoteObjectWithDocuments>;
  readonly results_keyword: Array<NoteObjectWithDocuments>;
  readonly results_project: Array<ProjectObject>;
  readonly results_file: Array<NoteObjectWithDocuments>;
  readonly total_tag: number;
  readonly total_keyword: number;
  readonly total_project: number;
  readonly total_file: number;
}

export type OSearch = GoonoResponse<OSearchResults>;
export const OSearchSchema = mkResponseSchema(
  T.object()
    .addField("results_hash_tag", T.array(NoteObjectWithDocumentsSchema))
    .addField("results_keyword", T.array(NoteObjectWithDocumentsSchema))
    .addField("results_project", T.array(ProjectObjectSchema))
    .addField("results_file", T.array(NoteObjectWithDocumentsSchema))
    .addField("total_tag", T.integer())
    .addField("total_keyword", T.integer())
    .addField("total_project", T.integer())
    .addField("total_file", T.integer())
);
export const validateOSearch = T.mkValidator<OSearch>(OSearchSchema);

export type OTagSearch = NoteWithDocumentsListResponse;
export const OTagSearchSchema = NoteWithDocuemntsListSchema;
export const validateOTagSearch = validateNoteWithDocuementsListResponse;

export type OKeywordSearch = NoteWithDocumentsListResponse;
export const OKeywordSearchSchema = NoteWithDocuemntsListSchema;
export const validateOKeywordSearch = validateNoteWithDocuementsListResponse;

export type OProjectSearch = GoonoArrayResponse<ProjectObject>;
export const OProjectSearchSchema = mkArrayResultsSchema(ProjectObjectSchema);
export const validateOProjectSearch =
  T.mkValidator<OProjectSearch>(OProjectSearchSchema);

export type OGetNotes = NoteListResponse;
export const OGetNotesSchema = NoteListSchema;
export const validateOGetNotes = validateNoteListResponse;

// response only new notes, but RTN as NoteList.
export type OCreateNote = GoonoResponse<NoteObject> &
  GoonoPendingTasks<
    { hlf_create_note: string } & { auth: string } & { newnote: string } & {
      tsa: string;
    }
  >;
export const OCreateNoteSchema = mkResponseSchema(
  NoteTagObjectSchema,
  T.object()
    .addField("hlf_create_note", T.string())
    .addField("newnote", T.string())
    .addField("auth", T.string())
    .addField("tsa", T.string())
);
export const validateOCreateNote =
  T.mkValidator<OCreateNote>(OCreateNoteSchema);

export type ODeleteNote = GoonoResponse<string>;
export const ODeleteNoteSchema = mkResponseSchema(T.string());
export const validateODeleteNote =
  T.mkValidator<ODeleteNote>(ODeleteNoteSchema);

export type OCreateNoteTags = GoonoArrayResponse<TagObject>;
export const OCreateNoteTagsSchema = mkArrayResultsSchema(TagObjectSchema);
export const validateOCreateNoteTags = T.mkValidator<OCreateNoteTags>(
  OCreateNoteTagsSchema
);

export type ODeleteNoteTag = GoonoResponse<string>;
export const ODeleteNoteTagSchema = mkResponseSchema(T.string());
export const validateODeleteNoteTag =
  T.mkValidator<ODeleteNoteTag>(ODeleteNoteTagSchema);

export type OShareNote = GoonoResponse<string>;
export const OShareNoteSchema = mkResponseSchema(T.string());
export const validateOShareNote = T.mkValidator<OShareNote>(OShareNoteSchema);

export enum ShareNotePDFError {
  INVALID_TOKEN,
}
export type DecodedShareInfoObject = {
  avaliable: boolean;
  require_email?: boolean;
  require_nda?: boolean;
  downloadable?: boolean;
  errorMsg?: ShareNotePDFError;
};
export const DecodedShareInfoObjectSchema = T.object()
  .addField("available", T.string())
  .addField("require_email", T.boolean(), false)
  .addField("require_nda", T.boolean(), false)
  .addField("downloadable", T.boolean(), false)
  .addField(
    "errorMsg",
    T.string().withEnum([ShareNotePDFError.INVALID_TOKEN]),
    false
  );

export type ODecodeShareNotePDF = GoonoResponse<DecodedShareInfoObject>;
export const ODecodeShareNotePDFSchema = mkResponseSchema(
  DecodedShareInfoObjectSchema.clone()
);
export const validateODecodeShareNotePDF = T.mkValidator<ODecodeShareNotePDF>(
  ODecodeShareNotePDFSchema
);

export type OGetShareNotePDF = GoonoResponse<{
  taskId: string;
}>;
export const OGetShareNotePDFSchema = mkResponseSchema(
  T.object().addField("taskId", T.string())
);
export const validateOGetShareNotePDF = T.mkValidator<OGetShareNotePDF>(
  OGetShareNotePDFSchema
);
