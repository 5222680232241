export default {
  ko: {
    account: {
      save: "변경사항 저장하기",
      save_xs: "저장하기",
      settings: "계정 설정",
      updated: "업데이트 날짜",
      cancel: "취소하기",
      cancel_xs: "취소",
      edit: "편집하기",
      email: "이메일",
      name: "이름",
      name_placeholder: "이름을 작성해주세요",
      institution: "소속",
      inst_placeholder: "소속을 작성해주세요",
      timezone: "Timezome",
      signature: "서명",
      use_my_name: "내 이름으로 서명을 합니다",
      no_sign_text: "설정한 서명이 없습니다. 지금 서명을 등록하세요!",
      sign_with_image: "이미지로 서명 등록",
      draw_sign: "사인으로 서명 등록",
      update_sign: "서명 이미지를 업데이트하거나 그립니다",
      how_signature_used: "서명을 등록하면 어디에 쓰나요?",
      sign_tooltip: `등록한 서명을 가지고 NDA에 서명을 하거나, 
      워크스페이스에 초대 받아 워크스페이스 설정에 따라 
      사인을 하게 되는 경우 사용하실 수 있습니다. 
      해당 서명은 baSalt 약관에 따라 법적 효력을 가지며, 
      본인의 서명임에 동의합니다.`,
      search_timezone: "표준 시간대를 검색합니다",
      now: "이제",
      loading: "로드 중...",
    },
  },

  en: {
    account: {
      save: "Save",
      save_xs: "Save",
      settings: "Account Settings",
      updated: "Updated",
      cancel: "Cancel",
      cancel_xs: "Cancel",
      edit: "Edit",
      email: "Email",
      name: "Name",
      name_placeholder: "Please write your name",
      institution: "Inst.",
      inst_placeholder: "Please write down your affiliation",
      timezone: "Timezone",
      signature: "Signature",
      use_my_name: "Sign with my name",
      no_sign_text:
        "You have not set a signature yet. Register your signature now!",
      sign_with_image: "Sign with an image",
      draw_sign: "Draw your signature",
      update_sign: "Update a Signature image or Draw",
      how_signature_used: "When I register my signature, where is it used?",
      sign_tooltip: `You can sign the NDA with the registered signature.
      After being invited to a workspace, you may be required to input your signature to use it.
      The signature has legal effect in accordance with the terms and conditions of baSalt.`,
      search_timezone: "Search for your time zone",
      now: "Now",
      loading: "Loading...",
    },
  },
};
