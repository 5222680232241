import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  tokenStateMachine,
  TokenStateMachineType,
  TokenState,
  TokenAction,
  TokenActionKind,
  TokenStateStatus,
} from "../../store/reducers/token";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import {
  IRegister,
  IRegisterWithEmail,
  IUpdatePW,
} from "@basalt-commons/api/request/user";
import Services from "@basalt-react-commons/services";
import moment from "moment-timezone";

const profileCids = {
  blue: "QmesQa5YauiP9W4kMwYMPZHWkzKnXSTHugJfiJHeLPNrhv",
  cyan: "QmcgJc7UJn8QjfRhe2e96YEFMubrhYhyMeY4ni75316rc9",
  green: "QmQCMW1byBheGkBDjXdikFbbAanbVbaF9i15mhmKJbXoan",
  indigo: "QmXVVHFHs8Ps8oQvgwDvHkTE5s1Y7jrWnqkpteFAGmPhEH",
  orange: "QmfZbhVBLF5D7ACtTtoQR16VYWuCbuK7YrKHhWA83wHBhH",
  pink: "Qmf5DDuuwHeQFN4SGkZ7aG96GRXQz7QiF6Rf4rvq6hbk5v",
  violet: "QmTvB5QP47aWb5shBVXFP3NyuqbJZ8eFmGUQG96pkRsPdX",
  yellow: "QmWkSnkKuircbURNZP3nLt7FqDcXJBhPV7zykA6wab3Fet",
};

const randomProfile = [
  profileCids.blue,
  profileCids.cyan,
  profileCids.green,
  profileCids.indigo,
  profileCids.orange,
  profileCids.pink,
  profileCids.violet,
  profileCids.yellow,
];

const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
};

const { UserService, IPFSService } = Services;

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.token,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TokenContainerProps = PropsFromRedux & {
  stateMachine: TokenStateMachineType;
};

class TokenContainer extends ReduxStateComponent3<TokenContainerProps> {
  static defaultProps = {
    stateMachine: tokenStateMachine,
  };
  constructor(props: TokenContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: TokenState,
    action: TokenAction
  ): Promise<TokenState> {
    switch (action.kind) {
      /** 로그인과 관련된 액션 */
      case TokenActionKind.TRY_LOGIN: {
        const loc = TokenActionKind.TRY_LOGIN;
        if (
          prevState.status !== TokenStateStatus.INIT &&
          prevState.status !== TokenStateStatus.TEMP_FOR_JOIN
        )
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            msg: "Invalid path, check the prevState",
            loc,
          });
        const { args } = action;
        const token = await this.guardAwait(() =>
          UserService.basaltLogin(args)
        );

        const info = await this.guardAwait(() =>
          UserService.goonoProfile(token)
        );

        const shareInfo =
          prevState.status === TokenStateStatus.TEMP_FOR_JOIN
            ? prevState.shareInfo
            : undefined;

        return {
          status: TokenStateStatus.SUCCESS,
          token,
          ...info.response,
          shareInfo,
        };
      }
      case TokenActionKind.TRY_LOGOUT: {
        return { status: TokenStateStatus.INIT };
      }
      /** 이메일 회원가입과 관련된 액션 */
      case TokenActionKind.TRY_CHECK_REGISTERED: {
        if (
          prevState.status !== TokenStateStatus.INIT &&
          prevState.status !== TokenStateStatus.TEMP_FOR_JOIN &&
          prevState.status !== TokenStateStatus.SUCCESS_SIGN_UP_SNS
        )
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_CHECK_REGISTERED,
            msg: "Invalid path, check the prevState",
          });
        const { email } = action;
        await this.guardAwait(() =>
          UserService.basaltEmailCheckRegister({ email })
        );
        if (prevState.status === TokenStateStatus.SUCCESS_SIGN_UP_SNS) {
          return prevState.shareInfo !== undefined
            ? {
                status: TokenStateStatus.TEMP_FOR_JOIN,
                shareInfo: prevState.shareInfo,
                email,
              }
            : { status: TokenStateStatus.INIT, email };
        }
        return { ...prevState, email };
      }
      case TokenActionKind.TRY_EMAIL_REGISTER: {
        if (
          prevState.status !== TokenStateStatus.INIT &&
          prevState.status !== TokenStateStatus.TEMP_FOR_JOIN
        )
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_EMAIL_REGISTER,
            msg: "Invalid path, check the prevState",
          });
        const { args, name, marketing_term } = action;

        if (args.platform !== "email") {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: TokenActionKind.TRY_EMAIL_REGISTER,
            msg: "invalid action param",
          });
        }

        const defaultInfo = {
          name,
          time_zone: moment.tz.guess(true), // guest time zone setting
          gender: "not-to-disclose",
          school: "-",
          department: "-",
          profile_cid: randomProfile[getRandomInt(0, randomProfile.length - 1)],
          profile_extension: "png",
          marketing_term,
          user_sign_cid: undefined,
          user_sign_extension: undefined,
        };
        const RegisterArgs: IRegisterWithEmail = {
          ...args,
          ...defaultInfo,
        } as IRegisterWithEmail;

        await this.guardAwait(() =>
          UserService.basaltEmailRegister(RegisterArgs)
        );
        return { ...prevState };
      }
      /** SNS 회원가입과 관련된 액션 */
      case TokenActionKind.TRY_GET_SNS_TOKEN: {
        if (
          prevState.status !== TokenStateStatus.INIT &&
          prevState.status !== TokenStateStatus.TEMP_FOR_JOIN &&
          prevState.status !== TokenStateStatus.SUCCESS_SIGN_UP_SNS
        )
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_GET_SNS_TOKEN,
            msg: "Invalid path, check the prevState",
          });
        const { args, checkEmail } = action;
        if (checkEmail !== undefined) {
          await this.guardAwait(() =>
            UserService.basaltEmailCheckRegister({ email: checkEmail })
          );
        }
        const shareInfo =
          prevState.status !== TokenStateStatus.INIT
            ? prevState.shareInfo
            : undefined;
        return {
          status: TokenStateStatus.SUCCESS_SIGN_UP_SNS,
          args,
          shareInfo,
        };
      }
      case TokenActionKind.TRY_RESET_SNS_TOKEN: {
        if (prevState.status !== TokenStateStatus.SUCCESS_SIGN_UP_SNS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_RESET_SNS_TOKEN,
            msg: "Invalid path, check the prevState",
          });
        return prevState.shareInfo !== undefined
          ? {
              status: TokenStateStatus.TEMP_FOR_JOIN,
              shareInfo: prevState.shareInfo,
            }
          : { status: TokenStateStatus.INIT };
      }
      case TokenActionKind.TRY_SNS_REGISTER: {
        if (prevState.status !== TokenStateStatus.SUCCESS_SIGN_UP_SNS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_SNS_REGISTER,
            msg: "Invalid path, check the prevState",
          });
        const { name, marketing_term } = action;

        const defaultInfo = {
          name,
          time_zone: moment.tz.guess(true), // guest time zone setting
          gender: "not-to-disclose",
          school: "-",
          department: "-",
          profile_cid: randomProfile[getRandomInt(0, randomProfile.length - 1)],
          profile_extension: "png",
          marketing_term,
          user_sign_cid: undefined,
          user_sign_extension: undefined,
        };
        const prevArgs = prevState.args;
        const RegisterArgs: IRegister = {
          ...prevArgs,
          ...defaultInfo,
        } as IRegister;

        const ret = await this.guardAwait(() =>
          UserService.basaltSNSRegister(RegisterArgs)
        );
        const { token } = ret.response;
        const info = await this.guardAwait(() =>
          UserService.goonoProfile(token)
        );

        return {
          status: TokenStateStatus.SUCCESS,
          token,
          ...info.response,
          shareInfo: prevState.shareInfo,
        };
      }
      /** 비밀번호 변경과 관련된 액션 */
      case TokenActionKind.TRY_AUTH_PW_EMAIL: {
        if (prevState.status !== TokenStateStatus.INIT)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_AUTH_PW_EMAIL,
            msg: "Invalid path, check the prevState",
          });
        const { email } = action;

        const ret = await this.guardAwait(() =>
          UserService.globalAuthenticationPW({ email })
        );
        return { ...prevState, url: ret.response.url };
      }
      case TokenActionKind.TRY_SAVE_PW_TOKEN: {
        if (
          prevState.status !== TokenStateStatus.INIT &&
          prevState.status !== TokenStateStatus.READY_UPDATE_PW
        )
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_SAVE_PW_TOKEN,
            msg: "Invalid path, check the prevState",
          });
        const token = action.pwToken;
        return {
          status: TokenStateStatus.READY_UPDATE_PW,
          pwToken: token,
        };
      }
      case TokenActionKind.TRY_UPDATE_PW: {
        if (prevState.status !== TokenStateStatus.READY_UPDATE_PW)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_UPDATE_PW,
            msg: "Invalid path, check the prevState",
          });
        const input: IUpdatePW = {
          pwToken: prevState.pwToken,
          newPW: action.newPW,
        };
        await this.guardAwait(() => UserService.globalUpdatePW(input));
        return { status: TokenStateStatus.INIT };
      }
      case TokenActionKind.TRY_RESET_PW_TOKEN: {
        if (prevState.status !== TokenStateStatus.READY_UPDATE_PW)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_RESET_PW_TOKEN,
            msg: "Invalid path, check the prevState",
          });
        return { status: TokenStateStatus.INIT };
      }
      /** 프로필과 관련된 액션 */
      case TokenActionKind.TRY_GET_PROFILE: {
        if (prevState.status !== TokenStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_GET_PROFILE,
            msg: "invalid prevstate",
          });
        const { token } = prevState;
        const ret = await this.guardAwait(() =>
          UserService.goonoProfile(token)
        );
        return { ...prevState, ...ret.response };
      }
      case TokenActionKind.TRY_UPDATE_PROFILE: {
        if (prevState.status !== TokenStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_UPDATE_PROFILE,
            msg: "prevState is invalid",
          });
        const userToken = prevState.token;
        const { new_user_sign, new_profile, new_user_sign_cids } = action;
        let args = { ...action };
        if (new_user_sign !== undefined) {
          const res = await this.guardAwait(() => fetch(new_user_sign));
          const blob = await this.guardAwait(() => res.blob());
          const userSign = new File([blob], "sign.png", { type: "image/png" });
          const extension = "png";
          const cid = await this.guardAwait(() =>
            IPFSService.uploadFileIPFS(userToken, userSign, extension)
          );
          args.new_user_sign = undefined;
          args.user_sign_cid = cid;
          args.user_sign_extension = extension;
        } else if (new_user_sign_cids !== undefined) {
          args.new_user_sign = undefined;
          args.user_sign_cid = new_user_sign_cids.cid;
          args.user_sign_extension = new_user_sign_cids.extension;
        }
        if (new_profile !== undefined) {
          args.profile_cid = new_profile.cid;
          args.profile_extension = new_profile.extension;
        }
        await this.guardAwait(() =>
          UserService.goonoProfileUpdate(userToken, { ...args })
        );
        const newProfile = await this.guardAwait(() =>
          UserService.goonoProfile(userToken)
        );
        return { ...prevState, ...newProfile.response };
      }
      /** 공유 토큰과 관련된 액션 */
      case TokenActionKind.TRY_GET_TEMP_TOKEN: {
        if (
          prevState.status !== TokenStateStatus.INIT &&
          prevState.status !== TokenStateStatus.TEMP_FOR_JOIN &&
          prevState.status !== TokenStateStatus.SUCCESS_SIGN_UP_SNS
        )
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: TokenActionKind.TRY_GET_TEMP_TOKEN,
            msg: "invalid prevstate",
          });
        const { shareInfo } = action;
        if (prevState.status === TokenStateStatus.INIT) {
          return { status: TokenStateStatus.TEMP_FOR_JOIN, shareInfo };
        } else {
          return { ...prevState, shareInfo };
        }
      }
      case TokenActionKind.TRY_RESET_SHARE_TOKEN: {
        if (prevState.status !== TokenStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: TokenActionKind.TRY_RESET_SHARE_TOKEN,
            msg: "prevState is invalid",
          });
        return { ...prevState, shareInfo: undefined };
      }
    }
  }
}

export default connector(TokenContainer);
