import { NoteObject } from "@basalt-commons/api/object/note";
import { DEFAULT_LANG } from "../../utils/translate";
import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  StateMachineAction,
  mkReducer,
  transition,
} from "@redwit-react-commons/reducers/state3";

export type CaptureType = {
  upload_nda?: {
    // nda 업로드 모달을 위함
    back_link: string | null;
    link_opt?: {
      allowPrint: boolean;
      allowDownload: boolean;
      requireEmail: boolean;
      linkName: string;
      date: Date;
    };
    targetNote?: NoteObject;
    forFolder?: boolean;
    modalOpen: boolean;
  };
  folder_setting_back?: boolean;
  doc_id?: string;
};

export enum PersistStateStatus {
  INIT = "Persist::INIT",
}

export enum PersistActionKind {
  TRY_RESET = "PersistAction::TRY_RESET",
  TRY_CHANGE_LANG = "PersistAction::TRY_CHANGE_LANG",
  UPDATE_VISIT_TIME = "PersistAction::UPDATE_VISIT_TIME",
  UPDATE_CAPTURE = "PersistAction::UPDATE_CAPTURE",
  RESET_CAPTURE = "PersistAction::RESET_CAPTURE",
  TOGGLE_ONBOARD = "PersistAction::TOGGLE_ONBOARD",
}

export type PersistState = {
  readonly status: PersistStateStatus.INIT;
  readonly lang: "ko" | "en";
  readonly visitLogTime?: string;
  readonly capture?: CaptureType;
  readonly onboard?: boolean;
};

export type PersistAction =
  | {
      readonly kind: PersistActionKind.TRY_RESET;
    }
  | {
      readonly kind: PersistActionKind.TRY_CHANGE_LANG;
      readonly lang: "ko" | "en";
    }
  | {
      readonly kind: PersistActionKind.UPDATE_VISIT_TIME;
      readonly time: string;
    }
  | {
      readonly kind: PersistActionKind.UPDATE_CAPTURE;
      readonly capture?: CaptureType;
    }
  | {
      readonly kind: PersistActionKind.RESET_CAPTURE;
    }
  | {
      readonly kind: PersistActionKind.TOGGLE_ONBOARD;
    };

export type PersistError = never;

const smid = "PERSIST_STATE_MACHINE3";
export type PersistStateMachineType = StateMachine3<
  PersistStateStatus,
  PersistState,
  PersistActionKind,
  PersistAction,
  PersistError
>;
export const persistStateMachine: PersistStateMachineType = new StateMachine3<
  PersistStateStatus,
  PersistState,
  PersistActionKind,
  PersistAction,
  PersistError
>(
  smid,
  {
    status: PersistStateStatus.INIT,
    lang: DEFAULT_LANG,
    visitLogTime: undefined,
    capture: undefined,
    onboard: true,
  },
  [
    transition(
      PersistStateStatus.INIT,
      PersistStateStatus.INIT,
      PersistActionKind.TRY_RESET
    ),
    transition(
      PersistStateStatus.INIT,
      PersistStateStatus.INIT,
      PersistActionKind.UPDATE_CAPTURE
    ),
    transition(
      PersistStateStatus.INIT,
      PersistStateStatus.INIT,
      PersistActionKind.RESET_CAPTURE
    ),
    transition(
      PersistStateStatus.INIT,
      PersistStateStatus.INIT,
      PersistActionKind.TRY_CHANGE_LANG
    ),
    transition(
      PersistStateStatus.INIT,
      PersistStateStatus.INIT,
      PersistActionKind.UPDATE_VISIT_TIME
    ),
    transition(
      PersistStateStatus.INIT,
      PersistStateStatus.INIT,
      PersistActionKind.TOGGLE_ONBOARD
    ),
  ]
);

export type DispatchPersistAction = Dispatch<
  StateMachineAction<
    PersistStateStatus,
    PersistState,
    PersistActionKind,
    PersistAction,
    PersistError
  >
>;
export default mkReducer<
  PersistStateStatus,
  PersistState,
  PersistActionKind,
  PersistAction,
  PersistError
>(persistStateMachine);

export const doPersistAction = (
  dispatch: DispatchPersistAction,
  nextAction: PersistAction,
  onResolve: () => void = () => {},
  onReject: (err: PersistError | InternalError) => void = () => {}
) => {
  dispatch(persistStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doPersistActionAsync = (
  dispatch: DispatchPersistAction,
  nextAction: PersistAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(persistStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetPersist = (dispatch: DispatchPersistAction) => {
  dispatch(persistStateMachine.newResetAction());
};
