import { combineReducers } from "redux";
import persist from "./persist";
import snackbar from "./snackbar";
import modal from "./modal";
import token from "./token";
import ipfs from "./ipfs";
import workspace from "./workspace";
import project from "./project";
import note from "./note";
import file from "./file";
import document from "./document";
import log from "./log";
import comment from "./comment";
import nda from "./nda";
import stamp from "./stamp";
import payment from "./payment";
import rule from "./rule";
import search from "./search";
import alarm from "./alarm";
import lazy_script from "./lazy_script";
import basegrid from "./basegrid";
/**
 * root reducer
 */
const rootReducer = combineReducers({
  persist,
  snackbar,
  modal,
  token,
  ipfs,
  workspace,
  project,
  note,
  file,
  document,
  log,
  comment,
  nda,
  stamp,
  payment,
  rule,
  search,
  alarm,
  lazy_script,
  basegrid,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
