import { doAPIGetRequest, doAPIDeleteRequest, doAPIPostRequest } from "./api";
import {
  OGetDomesticPaypleAccessToken,
  OGetGlobalPaypleAccessToken,
  OGetWorkspacePayment,
  validateOGetDomesticPaypleAccessToken,
  validateOGetGlobalPaypleAccessToken,
  validateOGetWorkspacePayment,
  validateOPaymentCredit,
  validateOPaypleDomesticPayment,
} from "@basalt-commons/global-api/response/payment";
import {
  ODeleteCard,
  OSetDefaultCard,
  validateODeleteCard,
  validateOSetDefaultCard,
} from "@basalt-commons/global-api/response/card";
import { WorkspaceWithPlan } from "@basalt-commons/global-api/object/workspace";
import {
  IPaymentCredit,
  IPaypleDomesticPayment,
} from "@basalt-commons/global-api/request/payment";

const setDefaultCard = async (
  userToken: string,
  workspaceId: string,
  cardId: string
): Promise<OSetDefaultCard> => {
  const ret = await doAPIGetRequest(
    `workspaces/${workspaceId}/card/${cardId}`,
    userToken
  );
  return validateOSetDefaultCard(ret);
};

const deleteCard = async (
  userToken: string,
  workspaceId: string,
  cardId: string
): Promise<ODeleteCard> => {
  const ret = await doAPIDeleteRequest(
    `workspaces/${workspaceId}/card/${cardId}`,
    userToken
  );
  return validateODeleteCard(ret);
};

const getWorkspacePayment = async (
  userToken: string,
  workspaceId: string
): Promise<OGetWorkspacePayment> => {
  const ret = await doAPIGetRequest(`basalt/payment/${workspaceId}`, userToken);
  return validateOGetWorkspacePayment(ret);
};

const getGlobalPaypleAuth = async (
  userToken: string,
  workspaceId: string
): Promise<OGetGlobalPaypleAccessToken> => {
  const ret = await doAPIGetRequest(
    `basalt/payple/${workspaceId}/global/auth`,
    userToken
  );
  return validateOGetGlobalPaypleAccessToken(ret);
};

const getDomesticPaypleAuth = async (
  userToken: string,
  workspaceId: string
): Promise<OGetDomesticPaypleAccessToken> => {
  const ret = await doAPIGetRequest(
    `basalt/payple/${workspaceId}/domestic/auth`,
    userToken
  );
  return validateOGetDomesticPaypleAccessToken(ret);
};

const domesticPurchase = async (
  userToken: string,
  workspaceId: string,
  args: IPaypleDomesticPayment
): Promise<WorkspaceWithPlan> => {
  const obj = await doAPIPostRequest(
    `/basalt/payple/domestic/purchase/${workspaceId}`,
    userToken,
    args
  );
  const ret = validateOPaypleDomesticPayment(obj);
  return ret.response;
};

const creditPurchase = async (
  userToken: string,
  workspaceId: string,
  args: IPaymentCredit
): Promise<WorkspaceWithPlan> => {
  const obj = await doAPIPostRequest(
    `/basalt/payment/credit/${workspaceId}`,
    userToken,
    args
  );
  const ret = validateOPaymentCredit(obj);
  return ret.response;
};
export default {
  setDefaultCard,
  deleteCard,
  getWorkspacePayment,
  getGlobalPaypleAuth,
  getDomesticPaypleAuth,
  domesticPurchase,
  creditPurchase,
};
