import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  IPFSAction,
  IPFSActionKind,
  IPFSState,
  IPFSStateMachineType,
  IPFSStateStatus,
  ipfsStateMachine,
  IPFSObject,
} from "../../store/reducers/ipfs";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { TokenStateStatus } from "../../store/reducers/token";
import {
  heicExtensionList,
  imageExtensionList,
} from "@basalt-commons/api/request/note";
import Services from "@basalt-react-commons/services";

const { IPFSService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.ipfs,
    token: state.token,
    document: state.document,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IPFSContainerProps = PropsFromRedux & {
  stateMachine: IPFSStateMachineType;
};

class IPFSContainer extends ReduxStateComponent3<IPFSContainerProps> {
  static defaultProps = { stateMachine: ipfsStateMachine };
  constructor(props: IPFSContainerProps) {
    super(props);
  }

  protected async onAction(
    _prevState: IPFSState,
    action: IPFSAction
  ): Promise<IPFSState> {
    switch (action.kind) {
      case IPFSActionKind.TRY_UPLOAD_GUEST_FILE: {
        const { files } = action;
        const { token } = this.props.document.state;
        if (token === undefined) {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: IPFSActionKind.TRY_UPLOAD_GUEST_FILE,
            msg: "invalid token",
          });
        }
        const promiseFiles = files.map(async (file) => {
          const extension = file.type.split("/")[1].toLowerCase();
          const available = [...imageExtensionList, ...heicExtensionList];
          if (!available.includes(extension)) {
            throw mkErr({
              kind: InternalErrorKind.Abort,
              loc: IPFSActionKind.TRY_UPLOAD_GUEST_FILE,
              msg: "profile invalid mimeType",
            });
          }
          const cid = await this.guardAwait(() =>
            IPFSService.uploadGuestFileIPFS(token, file, extension)
          );
          const cid_obj: IPFSObject = { cid, extension };
          return cid_obj;
        });
        const ret_array = await this.guardAwait(() =>
          Promise.all(promiseFiles)
        );
        return { status: IPFSStateStatus.INIT, cids: ret_array };
      }
      case IPFSActionKind.TRY_UPLOAD_IMAGE: {
        const { files } = action;
        const { token } = this.props;
        if (token.state.status !== TokenStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "FileContainer::onAction",
            msg: "not goono login",
          });
        const userToken = token.state.token;
        const promiseFiles = files.map(async (file) => {
          const extension = file.type.split("/")[1].toLowerCase();
          const available = [...imageExtensionList, ...heicExtensionList];
          if (!available.includes(extension)) {
            throw mkErr({
              kind: InternalErrorKind.Abort,
              loc: IPFSActionKind.TRY_UPLOAD_IMAGE,
              msg: "profile invalid mimeType",
            });
          }
          const cid = await this.guardAwait(() =>
            IPFSService.uploadFileIPFS(userToken, file, extension)
          );
          const cid_obj: IPFSObject = { cid, extension };
          return cid_obj;
        });
        const ret_array = await this.guardAwait(() =>
          Promise.all(promiseFiles)
        );
        return { status: IPFSStateStatus.INIT, cids: ret_array };
      }
      case IPFSActionKind.TRY_RESET: {
        return { status: IPFSStateStatus.INIT, cids: [] };
      }
    }
  }
}

export default connector(IPFSContainer);
