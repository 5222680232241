export default {
  ko: {
    sign_up_screen: {
      verified_mail:
        "바솔트 회원가입 인증 이메일 전송이 완료되었습니다. (메일함을 확인해주세요)",
      verified_err: "바솔트 초대 이메일 전송에 실패하였습니다.",
      sign_up: {
        sut_title: "구글과 이메일 계정으로 간편하게",
        title: "baSalt를 시작하세요",
        email: "이메일로 가입하기",
      },
      already_account: "이미 계정이 있으신가요?",
      error: {
        already_account: "이미 가입되어 있는 이메일 계정입니다.",
        not_auth: "인증 메일이 이미 발송되었습니다. 메일함을 확인해주세요!",
        email_validator: {
          not_enter: "이메일을 입력해주세요",
          not_format: "이메일 형식에 어긋납니다",
        },
        pw_validator: {
          requirement:
            "비밀번호는 특수기호, 숫자, 영문자를 포함한 8글자 이상으로 작성해주세요",
        },
      },
      sign_up_email_sent: {
        title1: "",
        title2: "로 인증메일이 발송되었습니다!",
        start: "메일함에서 인증을 진행해 주세요",
        home_btn: "홈으로 돌아가기",
        guide: {
          btn: "baSalt 사용가이드 보기",
          link: "https://blog.basalt.so/",
        },
      },
    },
  },
  en: {
    sign_up_screen: {
      verified_mail:
        "We've sent you a verification Email. (Please check your mailbox)",
      verified_err: "Failed to send an invitation. please try it again.",
      sign_up: {
        sut_title: "With your Email or Google account",
        title: "Get started with baSalt",
        email: "Sign up with Email",
      },
      already_account: "Already have an account?",
      error: {
        already_account: "This Email address is already registered",
        not_auth:
          "The verification Email has been already sent. Please check your mailbox.",
        email_validator: {
          not_enter: "Please enter your Email",
          not_format: "The Email address does not fit the format",
        },
        pw_validator: {
          requirement:
            "Password must include more than 8 letters consisting of special signs, numbers, and English letters.",
        },
      },
      sign_up_email_sent: {
        title1: "A verification Email has been sent to",
        title2: "",
        start:
          "We've already sent a verification Email. Please check your mailbox",
        home_btn: "Return to home",
        guide: {
          btn: "View the baSalt Usage Guide",
          link: "https://blog.basalt.so/?lang=en",
        },
      },
    },
  },
};
