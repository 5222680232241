import { doAPIGetRequest, doAPIPostRequest } from "../services/api";
import {
  ICreateDocument,
  ISendDocument,
  ISetDocumentStatus,
} from "@basalt-commons/global-api/request/document";
import {
  OCreateDocument,
  OGetDocumentList,
  validateOCreateDocument,
  validateOGetDocumentList,
  validateOGetDocumentInfo,
  OSetDocumentStatus,
  validateOSetDocumentStatus,
  validateOSendDocument,
  OSendDocument,
} from "@basalt-commons/global-api/response/document";
import {
  OGetNoteOrigin,
  validateOGetNoteOrigin,
} from "@basalt-commons/global-api/response/note";
import { IGetNoteOrigin } from "@basalt-commons/global-api/request/note";

const createDocument = async (
  userToken: string,
  workspace_id: string,
  project_id: string,
  args: ICreateDocument
): Promise<OCreateDocument> => {
  const obj = await doAPIPostRequest(
    `/document/${workspace_id}/${project_id}/create`,
    userToken,
    args
  );
  const ret = validateOCreateDocument(obj);
  return ret;
};

const getDocuments = async (
  userToken: string,
  workspace_id: string,
  project_id: string
): Promise<OGetDocumentList> => {
  const res = await doAPIGetRequest(
    `/document/${workspace_id}/${project_id}`,
    userToken
  );
  const ret = validateOGetDocumentList(res);
  return ret;
};

const getDocumentShareInfo = async (document_id: string) => {
  const res = await doAPIGetRequest(`/document/${document_id}/shared`);
  const ret = validateOGetDocumentInfo(res);
  return ret;
};

const updateDocumentStatus = async (
  userToken: string,
  workspace_id: string,
  project_id: string,
  document_id: string,
  args: ISetDocumentStatus
): Promise<OSetDocumentStatus> => {
  const obj = await doAPIPostRequest(
    `/document/${workspace_id}/${project_id}/${document_id}/status`,
    userToken,
    args
  );
  const ret = validateOSetDocumentStatus(obj);
  return ret;
};

const shareDocument = async (
  userToken: string,
  workspace_id: string,
  project_id: string,
  document_id: string,
  args: ISendDocument
): Promise<OSendDocument> => {
  const obj = await doAPIPostRequest(
    `/document/${workspace_id}/${project_id}/${document_id}/send`,
    userToken,
    args
  );
  const ret = validateOSendDocument(obj);
  return ret;
};
/**
 * 공유한 링크의 원본 파일의 CID 요청
 * @param token
 * @param args
 * @requires ProjectAuthType.ADMIN
 */
const getOriginNote = async (args: IGetNoteOrigin): Promise<OGetNoteOrigin> => {
  const ret = await doAPIPostRequest(
    `/document/download/origin`,
    undefined,
    args
  );
  const obj = validateOGetNoteOrigin(ret);
  return obj;
};

export default {
  createDocument,
  getDocuments,
  getDocumentShareInfo,
  updateDocumentStatus,
  shareDocument,
  getOriginNote,
};
