import React from "react";
import {
  Grid,
  Menu,
  MenuItem,
  IconButton,
  makeStyles,
  PopoverOrigin,
} from "@material-ui/core";
import { theme } from "@theme";
import LoopRoundedIcon from "@material-ui/icons/LoopRounded";
import SpoqaHansTypography from "@SpoqaHansTypography";
import PersonIcon from "@material-ui/icons/Person";
import { ReactComponent as ExitToAppIcon } from "@ui/basalt/images/sidebar/account_menu_logout.svg";
import { ReactComponent as SignDocumentIcon } from "@ui/basalt/images/service-header/sign-document.svg";
import translate from "../utils/translate";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import { TokenStateStatus } from "../store/reducers/token";
import { doPersistAction, PersistActionKind } from "../store/reducers/persist";
import {
  WorkspaceActionKind,
  doWorkspaceActionAsync,
} from "../store/reducers/workspace";
import { WorkspaceStateStatus } from "../store/reducers/workspace";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ScreenURL } from "../routes/RouteList";
import { doTokenActionAsync, TokenActionKind } from "../store/reducers/token";
import TranslateIcon from "@material-ui/icons/Translate";

type Props = {};
type AccountMenuProps = {
  anchorEl: null | HTMLElement;
  setAnchorEl: (value: null | HTMLElement) => void;
  anchorOrigin?: PopoverOrigin | undefined;
  transformOrigin?: PopoverOrigin | undefined;
} & RouteComponentProps<Props>;

type UserInfo = {
  profile_cid: string | undefined;
  user_name: string;
  user_email: string;
  user_id: string;
  time_zone: string | undefined;
};

const useStyles = makeStyles((MuiTheme) => ({
  profilePaper: {
    width: 220,
    borderRadius: 8,
    ...theme.shadow_2,
    [MuiTheme.breakpoints.down(600)]: {
      marginTop: 25,
    },
  },
  menuList: {
    padding: 0,
  },
  profileMenu: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    padding: "8px 12px",
    borderBottom: `1px solid ${theme.gray_2}`,
    gap: 8,
  },
  menuSubgrid: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  toggleIconButton: { marginLeft: 4, width: 20, height: 20 },
  toggleIcon: { ...theme.font20, color: theme.gray_6 },
  menuIcon: {
    width: 20,
    height: 20,
    color: theme.gray_7_text,
    marginRight: 4,
  },

  menuText: { ...theme.font14, height: 20, color: theme.gray_9_title },
  menuTextLight: { ...theme.font14, height: 20, color: theme.gray_7_text },
  menuItem: {
    width: "100%",
    height: "20px",
    padding: "10px 12px",
    "&:hover": {
      backgroundColor: theme.indigo_0,
    },
  },
  menuItemHeight: {
    minHeight: 40,
  },
}));

function AccountMenu(props: AccountMenuProps) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { token, persist, workspace } = useSelector((state: RootState) => ({
    token: state.token,
    persist: state.persist,
    workspace: state.workspace,
  }));

  const getUserInfoWithDefaultValue = () => {
    let userInfo: UserInfo = {
      profile_cid: undefined,
      user_name: "Unknown",
      user_email: "-",
      time_zone: undefined,
      user_id: "Unknown",
    };
    if (token.state.status === TokenStateStatus.SUCCESS) {
      userInfo = {
        profile_cid: token.state.profile_cid,
        user_name: token.state.name,
        user_email: token.state.email,
        time_zone: token.state.time_zone,
        user_id: token.state.id,
      };
    }
    return userInfo;
  };

  const userInfo = getUserInfoWithDefaultValue();

  const getSelectedWorkspace = () => {
    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS) return;
    return workspace.state.selectAuthWorkspace;
  };

  const currentWorkspace = getSelectedWorkspace();

  const onToggleLang = () => {
    doPersistAction(dispatch, {
      kind: PersistActionKind.TRY_CHANGE_LANG,
      lang: persist.state.lang === "ko" ? "en" : "ko",
    });
  };

  const onLogout = async () => {
    await doWorkspaceActionAsync(dispatch, {
      kind: WorkspaceActionKind.TRY_CLEAR_WORKSPACE,
    });
    await doTokenActionAsync(dispatch, {
      kind: TokenActionKind.TRY_LOGOUT,
    });
    props.history.replace(ScreenURL.LANDING_MAIN);
  };

  return (
    <Menu
      id="account-menu"
      keepMounted
      elevation={0}
      PaperProps={{
        className: classes.profilePaper,
      }}
      classes={{ list: classes.menuList }}
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      onClose={() => {
        props.setAnchorEl(null);
      }}
      getContentAnchorEl={null}
      anchorOrigin={props.anchorOrigin}
      transformOrigin={props.transformOrigin}
    >
      <Grid container item xs={12} className={classes.profileMenu}>
        <SpoqaHansTypography className={classes.menuText} noWrap>
          {userInfo.user_email}
        </SpoqaHansTypography>

        <Grid container item className={classes.menuSubgrid}>
          <Grid container item alignItems="center">
            <SpoqaHansTypography className={classes.menuTextLight} noWrap>
              {`${translate.account.timezone}: ${userInfo.time_zone}`}
            </SpoqaHansTypography>
          </Grid>
        </Grid>
      </Grid>
      <MenuItem
        onClick={() => {
          onToggleLang();
        }}
        className={classes.menuItem}
        classes={{ root: classes.menuItemHeight }}
      >
        <TranslateIcon className={classes.menuIcon} />
        <SpoqaHansTypography className={classes.menuText}>
          {translate.service_header.language}
        </SpoqaHansTypography>
        <IconButton className={classes.toggleIconButton}>
          <LoopRoundedIcon className={classes.toggleIcon} />
        </IconButton>
      </MenuItem>
      <MenuItem
        onClick={() => {
          props.history.push(`/${currentWorkspace?.name}/accounts/settings`);
          props.setAnchorEl(null);
        }}
        className={classes.menuItem}
        classes={{ root: classes.menuItemHeight }}
      >
        <PersonIcon className={classes.menuIcon} />
        <SpoqaHansTypography className={classes.menuText}>
          {translate.service_header.settings}
        </SpoqaHansTypography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          props.history.push(
            `/${currentWorkspace?.name}/accounts/contracts/signed`
          );
          props.setAnchorEl(null);
        }}
        className={classes.menuItem}
        classes={{ root: classes.menuItemHeight }}
      >
        <SignDocumentIcon className={classes.menuIcon} />
        <SpoqaHansTypography className={classes.menuText}>
          {translate.service_header.signature_doc}
        </SpoqaHansTypography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          props.setAnchorEl(null);
          if (onLogout !== undefined) {
            void onLogout();
          }
        }}
        className={classes.menuItem}
        classes={{ root: classes.menuItemHeight }}
      >
        <ExitToAppIcon className={classes.menuIcon} />
        <SpoqaHansTypography className={classes.menuText}>
          {translate.service_header.logout}
        </SpoqaHansTypography>
      </MenuItem>
    </Menu>
  );
}

export default withRouter(AccountMenu);
