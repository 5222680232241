import T from "@redwit-commons/utils/typecheck";

export interface AccessTime {
  readonly createdAt: string;
  readonly updatedAt: string;
}

export const addAccessTime = (from: T): T => {
  return from
    .addField("createdAt", T.string())
    .addField("updatedAt", T.string());
};

export const withAccessTime = (): T => {
  return addAccessTime(T.object());
};
