export default {
  ko: {
    search_note: {
      view: "파일 보기",
      file: "이 파일에는",
      active: "개의 활성 링크가 있습니다",
      menu: {
        overview: "개요",
        download: "다운로드",
        delete: "삭제하기",
      },
    },
  },

  en: {
    search_note: {
      view: "View file",
      file: "This file has",
      active: " Active Links",
      menu: {
        overview: "Overview",
        download: "Download",
        delete: "Delete",
      },
    },
  },
};
