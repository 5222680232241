import T from "@redwit-commons/utils/typecheck";

export interface GoonoResponse<R> {
  response: R;
}

export interface GoonoPendingTasks<R> {
  pending_tasks: R;
}
// GoonoResponse<UserOBject> & GoonoPendingTasks<NoteOCRWork & HLFAddNote>

/**
 * 특정 객체를 `{ response: ... }` 로 리턴하는 형식
 * @param responseObjectSchema
 */
export const mkResponseSchema = (
  responseObjectSchema: T,
  pendingTaskSchema?: T
): T => {
  const ret = T.object().addField("response", responseObjectSchema);
  if (pendingTaskSchema !== undefined)
    ret.addField("pending_tasks", pendingTaskSchema);
  return ret;
};

export interface GoonoArrayResponse<T> {
  response: {
    results: T[];
    metadata: {
      fetched_records_count: number;
      total?: number;
    };
  };
}

/**
 * 특정 객체를 `{ response: results: [] }` 로 리턴하는 형식
 * @param responseObjectSchema
 */
export const mkArrayResultsSchema = (
  arrayItemSchema: T,
  pendingTaskSchema?: T
): T => {
  const ret = T.object().addField(
    "response",
    T.object()
      .addField("results", T.array(arrayItemSchema))
      .addField(
        "metadata",
        T.object()
          .addField("fetched_records_count", T.integer())
          .addField("total", T.integer(), false),
        false
      )
  );
  if (pendingTaskSchema !== undefined)
    ret.addField("pending_tasks", pendingTaskSchema);
  return ret;
};
