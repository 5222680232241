export default {
  ko: {
    sign_in_screen: {
      sign_in: "로그인",
      sign_up: "회원가입",
      title: {
        sub_title: "가입한 이메일과 비밀번호로",
        title: "baSalt 로그인하기",
      },
      email: {
        placeholder: "이메일을 입력하세요 ",
      },
      pw: {
        placeholder: "비밀번호를 입력하세요",
        forgot: "비밀번호를 잊으셨나요?",
      },
    },
  },
  en: {
    sign_in_screen: {
      sign_in: "Sign In",
      sign_up: "Sign up",
      title: {
        sub_title: "Using email and password",
        title: "Sign in to baSalt",
      },
      email: {
        placeholder: "Enter your email.",
      },
      pw: {
        placeholder: "Enter your password",
        forgot: "Forgot password?",
      },
    },
  },
};
