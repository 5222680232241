import {
  ICreateRule,
  IUpdateRule,
} from "@basalt-commons/global-api/request/rule";
import {
  OCreateRule,
  ODeleteRule,
  OGetRuleList,
  OUpdateRule,
  validateOCreateRule,
  validateODeleteRule,
  validateOGetRuleList,
  validateOUpdateRule,
} from "@basalt-commons/global-api/response/rule";
import {
  doAPIPostRequest,
  doAPIGetRequest,
  doAPIDeleteRequest,
} from "../services/api";

/**
 * Rule 생성을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * ADMIN 권한 이상의 유저가 요청할 수 있음
 * @param userToken string
 * @param workspaceId string
 * @param args ICreateRule
 */
const createRule = async (
  userToken: string,
  workspaceId: string,
  args: ICreateRule
): Promise<OCreateRule> => {
  const obj = await doAPIPostRequest(
    `/rule/${workspaceId}/create`,
    userToken,
    args
  );
  return validateOCreateRule(obj);
};

/**
 * 룰 목록을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * GUEST 권한 이상의 유저가 요청할 수 있음
 */
const getRuleList = async (
  userToken: string,
  workspaceId: string
): Promise<OGetRuleList> => {
  const obj = await doAPIGetRequest(`/rules/${workspaceId}`, userToken);
  return validateOGetRuleList(obj);
};

/**
 * 룰 삭제를 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * ADMIN 권한 이상의 유저가 요청할 수 있음
 */
const deleteRule = async (
  userToken: string,
  workspaceId: string,
  ruleId: string
): Promise<ODeleteRule> => {
  const obj = await doAPIDeleteRequest(
    `/rule/${workspaceId}/delete/${ruleId}`,
    userToken
  );
  return validateODeleteRule(obj);
};

/**
 * 룰 필수 사항 업데이트를 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * ADMIN 권한 이상의 유저가 요청할 수 있음
 */
const updateRule = async (
  userToken: string,
  workspaceId: string,
  ruleId: string,
  args: IUpdateRule
): Promise<OUpdateRule> => {
  const obj = await doAPIPostRequest(
    `/rule/${workspaceId}/update/${ruleId}`,
    userToken,
    args
  );
  return validateOUpdateRule(obj);
};

export default {
  getRuleList,
  createRule,
  deleteRule,
  updateRule,
};
