import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import SpoqaHansTypography from "../../typo/SpoqaHansTypography";
import { theme } from "@theme";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    width: 204,
    height: 36,
    marginLeft: 8,
    marginRight: 8,
    paddingLeft: 8,
    position: "relative",
    cursor: "pointer",
    backgroundColor: theme.gray_0,
    borderRadius: 8,
  },
  root_hovered: {
    "&:hover": {
      backgroundColor: theme.gray_2,
    },
  },
  icon_img: {
    ...theme.icon_20,
    fill: theme.gray_7_text,
  },
  title: {
    ...theme.font14,
    marginLeft: 12,
    userSelect: "none",
    color: theme.gray_7_text,
    maxWidth: 144,
  },
  item_selected: {
    backgroundColor: theme.indigo_0,
    color: theme.indigo_6,
    fill: theme.indigo_6,
  },
}));

type SidebarDrawerListItemProps = {
  item_text: string;
  selected: boolean;
  iconFactory: (className: string) => JSX.Element;
  onClick: (args?: unknown) => void;
};

export default function SidebarDrawerListItem({
  item_text,
  onClick,
  iconFactory,
  selected,
}: SidebarDrawerListItemProps) {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      direction="row"
      onClick={onClick}
      className={clsx(
        classes.root,
        selected && classes.item_selected,
        !selected && classes.root_hovered
      )}
    >
      {iconFactory(clsx(classes.icon_img, selected && classes.item_selected))}
      <SpoqaHansTypography
        fontWeight="medium"
        className={clsx(classes.title, selected && classes.item_selected)}
        noWrap
      >
        {item_text}
      </SpoqaHansTypography>
    </Grid>
  );
}
