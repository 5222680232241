import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

/**
 * persist redux 수정할 때 마다 반드시 버전 1씩 계속 증가시킬 것.
 */
export const VERSION = 2;

export const KEY_STRING = "baSalt_Web_ReduxStore" + VERSION;

const persistConfig = {
  key: KEY_STRING,
  storage: storage,
  whitelist: ["persist", "token"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, applyMiddleware(thunk));

export const persistor = persistStore(store as any);
