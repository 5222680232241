export default {
  ko: {
    workspace_settings: {
      save: "저장하기",
      header: "워크스페이스 세팅",
      settings: "Settings",
      members: "멤버",
      update_prefix: "Updated",
      update_postfix: "",
      settings_tab: {
        edit: "편집",
        cancel: "취소",
        name: "워크스페이스 이름",
        default: "기본 설정",
        copy: "워크스페이스 URL 복사",
        no_img: "워크스페이스\n이미지가 없습니다.",
        workspace_url: "워크스페이스 url",
        rule_settings: "워크스페이스 룰 설정",
        rule_add: "룰 추가하기",
        no_rules: "워크스페이스 룰이 없습니다",
        new_img_upload: "새로운 이미지 업로드",
        img_upload: "이미지 업로드",
        public: "워크스페이스 공개",
      },

      members_tab: {
        title: "Members/Teams",
        workspace_members: "워크스페이스 멤버",
        workspace_teams: "워크스페이스 팀",
        invite: "워크스페이스에 멤버 추가",
        search: "팀원 검색",
        no_teams: "팀이 없습니다.",
        no_teams_found_ko: "에 대한 검색 결과가 없습니다.",
        no_teams_found_en: "",
        account: "팀",
        role: "롤",
        create_team: "팀 만들기",
        edit: "수정하기",
        invite_wait: "초대 중...",
        invite_expired: "유효기간 만료",
        resend_email: "초대 메일 다시 발송",
        resend_email_xs: "메일 재발송",
        see_more: "+MEMBER명 더보기",
        see_less: "접어 보기",
        delete_member_err: (folder_name: string) => {
          return `"${folder_name}"의 Owner 입니다. 해당 폴더의 Owner를 변경해주세요`;
        },
      },

      billing_tab: {
        title: "Billing",
        header: "결제 정보",
        plan: "구독 중인 Plan",
        no_plan: "구독 중인 Plan이 없습니다.",
        no_plan_subheader:
          "지금 Team Plan으로 업그레이드 하시면, 아래의 혜택을 바로 사용하실 수 있습니다.",
        upgrade_now: "지금 업그레이드 하기",
        team_plan_benefits: [
          "파일 업로드 무제한",
          " 블록체인 인증서 제공",
          " 워크스페이스 팀원 추가 불가능",
          " 외부 링크 생성 무제한",
          " NDA 첨부 무제한",
          " 링크 인사이트 및 그래프 제공",
          " 방문자 목록 제공",
        ],
        change_plan: "Plan 변경",
        cancel_plan: "구독 취소",
        payment_date: "결제 날짜",
        expiry_date: "만료 날짜",
        payment_amount: "결제 금액",

        registered_card: "등록된 카드",
        add_card: "카드 추가하기",
        no_card: "등록된 카드가 없습니다.",
        no_card_warning:
          "결제일까지 등록하지 않으시면 자동으로 Plan이 해지됩니다.",
        no_card_warning_xs:
          "결제일까지 등록하지 않으시면 자동으로\nPlan이 해지됩니다.",
        card_subheader: "님이 추가하였습니다.",
        default_card: "Default Card",
        set_default_card: "기본 카드로 설정하기",

        person: "인",

        content: " 내역",
        filter_month_format: "YY년 MM월",
        filter_date_format: "YYYY년 MM월 DD일",
        filter_date_format_xs: "YY년 MM월 DD일",
        payment_history_title: "결제 내역",
        date: "날짜",
        category: "항목",
        amount: "금액",
        remarks: "비고",
        see_receipt: "영수증 보기",
      },
    },
  },

  en: {
    workspace_settings: {
      save: "Save",
      header: "Workspace Settings",
      settings: "Settings",
      members: "Members",
      update_prefix: "Updated",
      update_postfix: "",
      settings_tab: {
        edit: "Edit",
        cancel: "Cancel",
        name: "Workspace name",
        copy: "Copy your workspace URL",
        default: "Default settings",
        no_img: "No workspace\nimage.",
        workspace_url: "Workspace url",
        rule_settings: "Workspace rule settings",
        rule_add: "Add a rule",
        new_img_upload: "Upload new image",
        img_upload: "Upload image",
        public: "Reveal your workspace",
        no_rules: "There are no workspace rules",
      },

      members_tab: {
        title: "Members/Teams",
        workspace_members: "Workspace members",
        workspace_teams: "Workspace teams",
        invite: "Invite Members",
        search: "Search a team",
        account: "Account",
        role: "Role",
        create_team: "Create a Team",
        edit: "Edit",
        invite_wait: "Pending",
        invite_expired: "Invitation expired",
        resend_email: "Resend Invitation",
        resend_email_xs: "Send again",
        see_more: "See MEMBER more",
        see_less: "See less",
        no_teams: "There are no teams.",
        no_teams_found_en: "No teams found for",
        no_teams_found_ko: "",
        delete_member_err: (folder_name: string) => {
          return `Change the owner of the "${folder_name}"`;
        },
      },
      billing_tab: {
        title: "Billing",
        header: "Payment Information",
        plan: "Subscription Plan",
        no_plan: "There is no subscription plan",
        no_plan_subheader:
          "If you upgrade to the Team Plan now, you can start using the benefits below right away.",
        upgrade_now: "Upgrade Now",
        team_plan_benefits: [
          "Unlimited file uploads",
          "Provide blockchain certificates",
          "Unable to add workspace team members",
          "Unlimited external link creation",
          "Unlimited NDA attachments",
          "Provide link insights and graphs",
          "Provide visitor list",
        ],

        change_plan: "Change",
        cancel_plan: "Cancel",
        payment_date: "Payment Date",
        expiry_date: "Expiration Date",
        payment_amount: "Payment Amount",

        registered_card: "Registered Card",
        add_card: "Add a Card",
        no_card: "There is no registered card",
        no_card_warning:
          "If you don't register by the payment date, the plan will be automatically terminated",
        no_card_warning_xs:
          "If you don't register by the payment date, \nthe plan will be automatically terminated",
        card_subheader: " added this",
        default_card: "Default Card",
        set_default_card: "Set as Default",

        person: "member(s)",

        content: "",
        filter_month_format: "MMM, YYYY",
        filter_date_format: "ll",
        filter_date_format_xs: "ll",
        payment_history_title: "Payment History",
        date: "Date",
        category: "Category",
        amount: "Amount",
        remarks: "Remarks",
        see_receipt: "See Receipt",
      },
    },
  },
};
