import {
  GoonoArrayResponse,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "../../api/response/common";
import T from "@redwit-commons/utils/typecheck";
import {
  NdaObject,
  NdaObjectSchema,
  NdaPureObject,
  NdaPureObjectSchema,
} from "../object/nda";

export type OCreateNDA = GoonoResponse<NdaPureObject>;
export const OCreateNDASchema = mkResponseSchema(NdaPureObjectSchema);
export const validateOCreateNDA = T.mkValidator<OCreateNDA>(OCreateNDASchema);

export type OGetNDAList = GoonoArrayResponse<NdaObject>;
export const OGetNDAListSchema = mkArrayResultsSchema(NdaObjectSchema);
export const validateOGetNDAList =
  T.mkValidator<OGetNDAList>(OGetNDAListSchema);

export type ODeleteNDA = GoonoResponse<string>;
export const ODeleteNDASchema = mkResponseSchema(T.string());
export const validateODeleteNDA = T.mkValidator<ODeleteNDA>(ODeleteNDASchema);

export type OUpdateNDA = GoonoResponse<NdaObject>;
export const OUpdateNDASchema = mkResponseSchema(NdaObjectSchema);
export const validateOUpdateNDA = T.mkValidator<OUpdateNDA>(OUpdateNDASchema);
