import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "../../api/object/access_time";
import { UserObject, UserObjectSchema } from "../../api/object/user";
import {
  RuleContractWithUserSchema,
  RuleContractWithUser,
} from "./rule_contract";

export type RuleOption = {
  readonly required: boolean;
  readonly alert_members: boolean;
};

export type RulePureCore = {
  readonly id: string;
  readonly name: string;
  /** nda file info ( must pdf ) */
  readonly file_name: string;
  readonly cid: string;
  readonly extension: string;
  readonly thumbCid?: string;
  readonly thumbExtension?: string;
  readonly UserId: string;
  readonly WorkspaceId: string;
} & RuleOption;

export type RuleWithUserDB = {
  readonly User: UserObject;
} & RulePureCore;

export type RuleWithContractsDB = {
  readonly RuleContracts: Array<RuleContractWithUser>;
} & RuleWithUserDB;

export type RulePureObject = RulePureCore & AccessTime;
export type RuleObject = RuleWithUserDB & AccessTime;
export type RuleObjectWithContract = RuleWithContractsDB & AccessTime;

export const RulePureObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("file_name", T.string())
  .addField("cid", T.string())
  .addField("extension", T.string())
  .addField("thumbCid", T.string(), false)
  .addField("thumbExtension", T.string(), false)
  .addField("UserId", T.string())
  .addField("WorkspaceId", T.string())
  .addField("required", T.boolean())
  .addField("alert_members", T.boolean());

export const RuleObjectSchema = RulePureObjectSchema.clone().addField(
  "User",
  UserObjectSchema.clone()
);

export const RuleObjectWithContractSchema = RuleObjectSchema.clone().addField(
  "RuleContracts",
  T.array(RuleContractWithUserSchema.clone())
);

export const extractRulePureObject =
  T.mkObjectExtractor<RulePureObject>(RulePureObjectSchema);
export const validateRulePureObject =
  T.mkValidator<RulePureObject>(RulePureObjectSchema);
export const extractRuleObject =
  T.mkObjectExtractor<RuleObject>(RuleObjectSchema);
export const validateRuleObject = T.mkValidator<RuleObject>(RuleObjectSchema);
export const extractRuleObjectWithContract =
  T.mkObjectExtractor<RuleObjectWithContract>(RuleObjectWithContractSchema);
export const validateRuleObjectWithContract =
  T.mkValidator<RuleObjectWithContract>(RuleObjectWithContractSchema);
