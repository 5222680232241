import { ChatObject, ChatObjectSchema } from "../../global-api/object/chat";
import T from "@redwit-commons/utils/typecheck";

import {
  GoonoArrayResponse,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "./common";

export type OGetChats = GoonoArrayResponse<ChatObject>;
export const OGetChatsSchema = mkArrayResultsSchema(ChatObjectSchema.clone());
export const validateOGetChats = T.mkValidator<OGetChats>(OGetChatsSchema);

export type OCreateChat = GoonoResponse<ChatObject>;
export const OCreateChatSchema = mkResponseSchema(ChatObjectSchema.clone());
export const validateOCreateChat =
  T.mkValidator<OCreateChat>(OCreateChatSchema);

export type OEditChat = GoonoResponse<ChatObject>;
export const OEditChatSchema = mkResponseSchema(ChatObjectSchema.clone());
export const validateOEditChat = T.mkValidator<OEditChat>(OEditChatSchema);

export type ODeleteChat = GoonoResponse<string>;
export const ODeleteChatSchema = mkResponseSchema(T.string());
export const validateODeleteChat =
  T.mkValidator<ODeleteChat>(ODeleteChatSchema);
