import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { NoteObject } from "@basalt-commons/api/object/note";
import { ProjectObject } from "@basalt-commons/api/object/project";
import { NDAInfo } from "@basalt-commons/global-api/request/document";
import {
  DocumentObject,
  DocumentOption,
} from "@basalt-commons/global-api/object/document";

export enum NoteStateStatus {
  INIT = "NoteState::INIT",
  SUCCESS = "NoteState::SUCCESS",
  SUCCESS_SELECT = "NoteState::SUCCESS_SELECT",
}

export enum NoteCreateLinkErrorType {
  CREATION_LIMIT_EXCEEDED = "INDIVIDUAL_Plan: Link creation limit exceeded",
}

export enum NoteActionKind {
  TRY_GET_NOTES = "NoteAction::TRY_GET_NOTES",
  TRY_UPLOAD_NOTES = "NoteAction::TRY_UPLOAD_NOTES",
  TRY_REMOVE_NOTES = "NoteAction::TRY_REMOVE_NOTES",
  TRY_SELECT_NOTE = "NoteAction::TRY_SELECT_NOTE",
  TRY_ALL_SELECT_NOTE = "NoteAction::TRY_ALL_SELECT",
  TRY_UNSELECT_NOTE = "NoteAction::TRY_UNSELECT_NOTE",
  TRY_DOWNLOAD_NOTE = "NoteAction::TRY_DOWNLOAD_NOTE",
  TRY_EDIT_HASHTAGS = "NoteAction::TRY_EDIT_HASHTAGS",
  TRY_GET_MORE_NOTES = "NoteAction::TRY_GET_MORE_NOTES",
  TRY_SELECT_SEARCH = "NoteAction::TRY_SELECT_SEARCH",
  TRY_RESET_NOTE = "NoteAction::TRY_RESET_NOTE",
  TRY_CREATE_LINK = "NoteAction::TRY_CREATE_LINK",
  TRY_DOWNLOAD_CERTIFICATION = "NoteAction::TRY_DOWNLOAD_CERTIFICATION",
}

interface NoteStateTrait {
  readonly notes: Array<NoteObject>;
  readonly docs: DocumentObject[];
  readonly total?: number;
  readonly project: ProjectObject;
  readonly filePath?: string;
  readonly fileSize?: string;
  readonly recentHashtag: string[];
  readonly create_link?: string;
}

export type NoteError = never;

export enum NoteErrorType {
  // When project Id passed to getNotes is not in the list of projects in the given Workspace
  PROJECT_NOT_IN_WORKSPACE = "TokenErrorType::PROJECT_NOT_IN_WORKSPACE",
}

export type NoteState =
  | {
      readonly status: NoteStateStatus.INIT;
      readonly counts?: number;
    }
  | ({
      readonly status: NoteStateStatus.SUCCESS;
    } & NoteStateTrait)
  | ({
      readonly status: NoteStateStatus.SUCCESS_SELECT;
      readonly allSelect?: boolean;
      readonly selects: Array<NoteObject>;
    } & NoteStateTrait);

export type NoteAction =
  | {
      readonly kind: NoteActionKind.TRY_GET_NOTES;
      readonly workspaceId: string;
      readonly projectId: string;
    }
  | {
      readonly kind: NoteActionKind.TRY_UPLOAD_NOTES;
      readonly writtenAt: string;
      // TODO: delete action param
      readonly workspaceId: string;
      readonly projectId: string;
    }
  | {
      readonly kind: NoteActionKind.TRY_REMOVE_NOTES;
      // TODO: delete action param
      readonly workspaceId?: string;
    }
  | {
      readonly kind: NoteActionKind.TRY_SELECT_NOTE;
      readonly selects: NoteObject[];
    }
  | {
      readonly kind: NoteActionKind.TRY_UNSELECT_NOTE;
    }
  | {
      readonly kind: NoteActionKind.TRY_DOWNLOAD_NOTE;
    }
  | {
      readonly kind: NoteActionKind.TRY_EDIT_HASHTAGS;
      readonly note: NoteObject;
      readonly tags: string[];
      readonly workspaceId: string;
    }
  | {
      readonly kind: NoteActionKind.TRY_GET_MORE_NOTES;
      readonly workspaceId: string;
    }
  | {
      readonly kind: NoteActionKind.TRY_SELECT_SEARCH;
      readonly selectNote: NoteObject;
      readonly project: ProjectObject;
      readonly recentHashtag: string[];
    }
  | {
      readonly kind: NoteActionKind.TRY_RESET_NOTE;
    }
  | {
      readonly kind: NoteActionKind.TRY_ALL_SELECT_NOTE;
    }
  | {
      readonly kind: NoteActionKind.TRY_CREATE_LINK;
      readonly noteId: string;
      readonly link_title: string;
      readonly file_name: string;
      readonly expires_in: string;
      readonly DocumentOption: DocumentOption;
      readonly NDAInfo?: NDAInfo;
    }
  | {
      readonly kind: NoteActionKind.TRY_DOWNLOAD_CERTIFICATION;
      readonly noteId: string;
      readonly workspaceId: string;
    };

const smid = "NOTE_STATE_MACHINE3";
export type NoteStateMachineType = StateMachine3<
  NoteStateStatus,
  NoteState,
  NoteActionKind,
  NoteAction,
  NoteError
>;
export const noteStateMachine: NoteStateMachineType = new StateMachine3<
  NoteStateStatus,
  NoteState,
  NoteActionKind,
  NoteAction,
  NoteError
>(smid, { status: NoteStateStatus.INIT, counts: undefined }, [
  transition(
    NoteStateStatus.INIT,
    NoteStateStatus.SUCCESS,
    NoteActionKind.TRY_GET_NOTES
  ),
  transition(
    NoteStateStatus.SUCCESS,
    NoteStateStatus.SUCCESS,
    NoteActionKind.TRY_GET_NOTES
  ),
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_GET_NOTES
  ), // select 상태에서도 추가 정보 가져오기 가능해야 합니다 (refreshing)
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS,
    NoteActionKind.TRY_UNSELECT_NOTE
  ),

  transition(
    NoteStateStatus.INIT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_SELECT_SEARCH
  ), // Search에서의 로직을 위함입니다
  transition(
    NoteStateStatus.SUCCESS,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_SELECT_SEARCH
  ), // Search에서의 로직을 위함입니다
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_SELECT_SEARCH
  ), // Search에서의 로직을 위함입니다

  transition(
    NoteStateStatus.SUCCESS,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_SELECT_NOTE
  ),
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_SELECT_NOTE
  ), // 멀티 셀렉트가 되야하므로 필요함

  transition(
    NoteStateStatus.INIT,
    NoteStateStatus.INIT,
    NoteActionKind.TRY_UPLOAD_NOTES
  ),
  transition(
    NoteStateStatus.SUCCESS,
    NoteStateStatus.SUCCESS,
    NoteActionKind.TRY_UPLOAD_NOTES
  ),
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_UPLOAD_NOTES
  ), // 노트를 선택하고 있는 상태에서 업로드도 가능해야함

  transition(
    NoteStateStatus.SUCCESS,
    NoteStateStatus.SUCCESS,
    NoteActionKind.TRY_GET_MORE_NOTES
  ),
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_GET_MORE_NOTES
  ),

  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS,
    NoteActionKind.TRY_REMOVE_NOTES
  ),

  transition(
    NoteStateStatus.SUCCESS,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_ALL_SELECT_NOTE
  ),
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_ALL_SELECT_NOTE
  ),

  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_DOWNLOAD_NOTE
  ),
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_EDIT_HASHTAGS
  ), // 수정된 해시태그 정보 업데이트
  transition(
    NoteStateStatus.SUCCESS,
    NoteStateStatus.SUCCESS,
    NoteActionKind.TRY_EDIT_HASHTAGS
  ),

  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.INIT,
    NoteActionKind.TRY_RESET_NOTE
  ),
  transition(
    NoteStateStatus.SUCCESS,
    NoteStateStatus.INIT,
    NoteActionKind.TRY_RESET_NOTE
  ),

  transition(
    NoteStateStatus.SUCCESS,
    NoteStateStatus.SUCCESS,
    NoteActionKind.TRY_CREATE_LINK
  ),
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS,
    NoteActionKind.TRY_CREATE_LINK
  ),
  transition(
    NoteStateStatus.SUCCESS_SELECT,
    NoteStateStatus.SUCCESS_SELECT,
    NoteActionKind.TRY_DOWNLOAD_CERTIFICATION
  ),
]);

export type DispatchNoteAction = Dispatch<
  StateMachineAction<
    NoteStateStatus,
    NoteState,
    NoteActionKind,
    NoteAction,
    NoteError
  >
>;
export default mkReducer<
  NoteStateStatus,
  NoteState,
  NoteActionKind,
  NoteAction,
  NoteError
>(noteStateMachine);

export const doNoteAction = (
  dispatch: DispatchNoteAction,

  nextAction: NoteAction,
  onResolve: () => void = () => {},
  onReject: (err: NoteError | InternalError) => void = () => {}
) => {
  dispatch(noteStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doNoteActionAsync = (
  dispatch: DispatchNoteAction,
  nextAction: NoteAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(noteStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetNote = (dispatch: DispatchNoteAction) => {
  dispatch(noteStateMachine.newResetAction());
};

export const softResetNote = (
  dispatch: DispatchNoteAction,
  onResolve: () => void = () => {},
  onReject: (err: NoteError | InternalError) => void = () => {}
) => {
  dispatch(
    noteStateMachine.newTryAction(
      { kind: NoteActionKind.TRY_RESET_NOTE },
      onResolve,
      onReject
    )
  );
};
