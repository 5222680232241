export default {
  ko: {
    workspace_upgrade: {
      title: "결제 플랜",
      start_individual_plan: "무료 체험 시작하기",
      start_team_plan: "팀 플랜 시작하기",
      current_plan: "현재 플랜",
      enquire: "문의하기",
      downgrade: "다운그레이드",
      individual_price: "Free",
      team_price: "$ 5",
      team_price_desc: "매달 1인당",
      features: "제공 기능",
      unlimited: "무제한",
      plan: {
        team: {
          header: "Team",
          title: "최고 수준의 문서 관리와 보안을 위한 팀 Plan",
          text: {
            item_1: "파일 업로드 무제한",
            item_2: "블록체인 인증서 제공",
            item_3: "워크스페이스 팀원 추가 가능",
            item_4: "외부 링크 생성 무제한",
            item_5: "NDA 첨부 무제한",
            item_6: "링크 리포트 및 인사이트 제공",
            item_7: "방문자 목록 제공",
          },
        },
        individual: {
          header: "Individual",
          title: "안전하게 문서를 공유하고 싶은 개인 Plan",
          text: {
            item_1: "파일 업로드 무제한",
            item_2: "워크스페이스 멤버 추가 불가능",
            item_3: "링크 리포트 및 인사이트 제공",
          },
        },
        enterprise: {
          header: "Enterprise",
          title: "최적화된 금액으로 이용할 수 있는 팀 Plan",
        },
      },
      tables: {
        table_1: {
          title: "기본",
          row_1: "파일 업로드",
          row_2: "블록체인 인증서 제공",
          row_3: "워크스페이스 팀원 초대",
          row_4: "폴더 공유",
        },
        table_2: {
          title: "공유 링크",
          row_1: "공유 링크",
          row_2: "공유 링크 NDA 첨부",
          row_3: "워크스페이스 서명 등록",
          row_4: "링크 메일 발송 ",
        },
        table_3: {
          title: "문서 내부 트래킹",
          row_1: "워크스페이스 타임라인",
          row_2: "타임라인 PDF 다운로드",
        },
        table_4: {
          title: "공유 링크 트래킹",
          row_1: "PDF 페이지 별 리포트",
          row_2: "리포트 PDF 다운로드",
          row_3: "방문자 목록 제공",
          row_4: "방문자 목록 Excel 다운로드",
        },
      },
    },
  },

  en: {
    workspace_upgrade: {
      title: "Payment Plan",
      start_individual_plan: "Get started for free",
      start_team_plan: "Get started for team",
      current_plan: "Current Plan",
      enquire: "Make an inquiry",
      downgrade: "Downgrade",
      individual_price: "Free",
      team_price: "$ 5",
      team_price_desc: "for each user every month",
      features: "Features Provided",
      unlimited: "Unlimited",
      plan: {
        team: {
          header: "Team",
          title:
            "Team-only plan for top-level document management and security",
          text: {
            item_1: "Unlimited file upload",
            item_2: "Provide a blockchain certificate",
            item_3: "Add members to the workspace",
            item_4: "Unlimited Creation of External Links",
            item_5: "Unlimited NDA Attachment",
            item_6: "Provide link insights and graphs",
            item_7: "Provide a list of visitors",
          },
        },
        individual: {
          header: "Individual",
          title:
            "Plan for Individual users who want to share documents safely with others, ",
          text: {
            item_1: "Unlimited File Upload",
            item_2: "Unable to add members to the workspace",
            item_3: "Provide link insights and graphs",
          },
        },
        enterprise: {
          header: "Enterprise",
          title: "Team plan at a reasonable price",
        },
      },
      tables: {
        table_1: {
          title: "Default",
          row_1: "File Upload",
          row_2: "Provide blockchain certificate",
          row_3: "Invite Team Members to Workspace",
          row_4: "Folder Sharing",
        },
        table_2: {
          title: "About creating sharing-links",
          row_1: "Create Sharing-ink",
          row_2: "Attach NDA to the sharing-link",
          row_3: "Register Workspace Signature",
          row_4: "Send a sharing-link via Email",
        },
        table_3: {
          title: "Tracking within documents",
          row_1: "Workspace Timeline",
          row_2: "Download timeline in PDF",
        },
        table_4: {
          title: "Sharing-link tracking",
          row_1: "Provide summary reports for each page",
          row_2: "Download summary report PDF",
          row_3: "Provide a list of visitors",
          row_4: "Export a list of visitors to Excel",
        },
      },
    },
  },
};
