import {
  IAssignLicense,
  IChangeLicenseRole,
  ICreateLicense,
  IResignLicense,
  IUpdateLicense,
} from "@basalt-commons/api/request/admin";
import {
  OAssignLicense,
  OChangeLicenseRole,
  OCreateLicense,
  ODeleteLicense,
  OGetUserLicense,
  OResignLicense,
  OUpdateLicense,
  validateOAssignLicense,
  validateOChangeLicenseRole,
  validateOCreateLicense,
  validateODeleteLicense,
  validateOGetUserLicense,
  validateOResignLicense,
} from "@basalt-commons/api/response/admin";
import {
  OGetAllLicense,
  validateOGetAllLicense,
} from "@basalt-commons/api/response/user";
import { doAPIPostRequest, doAPIGetRequest, doAPIDeleteRequest } from "./api";
import {
  IGetUserLogs,
  IGetNoteExtensions,
} from "@basalt-commons/api/request/user_log";
import {
  OGetUserLogs,
  validateOGetUserLogs,
  OGetNoteExtensions,
  validateOGetNoteExtensions,
} from "@basalt-commons/api/response/user_log";

// OGetNote 형태 전체로 return
const getAllLicenses = async (token: string): Promise<OGetAllLicense> => {
  const obj = await doAPIGetRequest(`admin/license`, token);
  const ret = validateOGetAllLicense(obj);
  return ret;
};

// OGetNote 형태 전체로 return
const getAllUsers = async (token: string): Promise<OGetUserLicense> => {
  const obj = await doAPIGetRequest(`admin/user`, token);
  const ret = validateOGetUserLicense(obj);
  return ret;
};

const createLicense = async (
  token: string,
  args: ICreateLicense
): Promise<OCreateLicense> => {
  const obj = await doAPIPostRequest(`/admin/license/create`, token, args);
  const ret = validateOCreateLicense(obj);
  return ret;
};

const updateLicense = async (
  token: string,
  args: IUpdateLicense
): Promise<OUpdateLicense> => {
  const obj = await doAPIPostRequest(`/admin/license/update`, token, args);
  const ret = validateOCreateLicense(obj);
  return ret;
};

const deleteLicense = async (
  token: string,
  licenseID: string
): Promise<ODeleteLicense> => {
  const obj = await doAPIDeleteRequest(`/admin/license/${licenseID}`, token);
  const ret = validateODeleteLicense(obj);
  return ret;
};

const assignLicense = async (
  token: string,
  args: IAssignLicense
): Promise<OAssignLicense> => {
  const obj = await doAPIPostRequest(`/admin/license/assign`, token, args);
  const ret = validateOAssignLicense(obj);
  return ret;
};

// unassign
const resignLicense = async (
  token: string,
  args: IResignLicense
): Promise<OResignLicense> => {
  const obj = await doAPIPostRequest(`/admin/license/resign`, token, args);
  const ret = validateOResignLicense(obj);
  return ret;
};

// unassign
const updateRole = async (
  token: string,
  args: IChangeLicenseRole
): Promise<OChangeLicenseRole> => {
  const obj = await doAPIPostRequest(`/admin/license/role/update`, token, args);
  const ret = validateOChangeLicenseRole(obj);
  return ret;
};

const getUserLogs = async (
  token: string,
  args: IGetUserLogs
): Promise<OGetUserLogs> => {
  const res = await doAPIGetRequest(`user_logs`, token, args);
  const ret = validateOGetUserLogs(res);
  return ret;
};

const getExtenstionsLogs = async (
  token: string,
  args: IGetNoteExtensions
): Promise<OGetNoteExtensions> => {
  const res = await doAPIGetRequest("/user_log/extensions", token, args);
  const ret = validateOGetNoteExtensions(res);
  return ret;
};

export default {
  getAllLicenses,
  getAllUsers,
  createLicense,
  updateLicense,
  deleteLicense,
  assignLicense,
  resignLicense,
  updateRole,
  getUserLogs,
  getExtenstionsLogs,
};
