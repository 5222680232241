import React from "react";
import { IconButton, Menu, Badge, Grid } from "@material-ui/core";
import styles from "./styles/AlarmButton";
import { ServiceHeaderHeightXL } from "../../../utils/templates/styles/BaseGrid";
import SpoqaHansTypography from "@SpoqaHansTypography";
import { AlarmObject } from "@basalt-commons/global-api/object/alarm";
import translate from "../../../utils/translate";
import { withStyles, WithStyles } from "@material-ui/core";
import AlarmItem, { AlarmItemEvent } from "../items/AlarmItem";
import { ReactComponent as EmptyAlarmImg } from "@ui/basalt/images/alarm/img-empty-alarm-90.svg";
import { ReactComponent as AlarmIcon } from "@ui/basalt/images/alarm/ic-alarm-24.svg";
const ITEM_HEIGHT = 195;

type AlarmButtonProps = {
  alarms: AlarmObject[];
  timezone?: string;
  onReadAllAlarms: () => void;
  isMobile?: boolean;
} & WithStyles<typeof styles> &
  AlarmItemEvent;

type AlarmButtonState = {
  anchorEl: null | HTMLElement;
};

class AlarmButton extends React.PureComponent<
  AlarmButtonProps,
  AlarmButtonState
> {
  constructor(props: AlarmButtonProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClose() {
    this.setState({ anchorEl: null }, () => {
      this.props.onReadAllAlarms();
    });
  }

  render() {
    const { classes, alarms, timezone } = this.props;
    return (
      <>
        {/* <Tooltip title={translate.service_header.notifications.tooltip} arrow> */}
        <IconButton
          onClick={(evt) => {
            this.setState({ anchorEl: evt.currentTarget });
          }}
          className={classes.iconButton}
        >
          <Badge
            color="secondary"
            overlap="circular"
            badgeContent={
              alarms.filter((alarm) => alarm.isCheck === false).length
            }
            variant="dot"
          >
            <AlarmIcon className={classes.noti_icon} />
          </Badge>
        </IconButton>
        {/* </Tooltip> */}
        <div>
          <Menu
            anchorEl={this.state.anchorEl}
            id="alarm-list"
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.handleClose();
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 3,
                width: 500,
                marginTop: ServiceHeaderHeightXL - 30,
                borderRadius: 8,
                boxShadow: "0 8px 28px 2px rgba(33, 37, 41, 0.12)",
              },
            }}
          >
            {alarms.length === 0 && (
              <Grid
                container
                item
                xs={12}
                style={{
                  width: "100%",
                  padding: 80,
                  paddingTop: 118,
                  paddingBottom: 118,
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  style={{ width: "100%" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <EmptyAlarmImg style={{ width: 90, height: 90 }} />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ width: "100%", marginTop: 24 }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <SpoqaHansTypography
                    fontWeight="medium"
                    className={classes.no_alarm_title}
                  >
                    {translate.service_header.notifications.no_alarm.title}
                  </SpoqaHansTypography>
                </Grid>
                <SpoqaHansTypography className={classes.no_alarm_body}>
                  {translate.service_header.notifications.no_alarm.body}
                </SpoqaHansTypography>
              </Grid>
            )}
            {alarms.map((alarm, index) => (
              <AlarmItem
                key={`${alarm.id}`}
                {...this.props}
                alarm={alarm}
                timezone={timezone}
                borderBottom={index === alarms.length - 1 ? false : true}
              />
            ))}
          </Menu>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(AlarmButton);
