import { Dispatch } from "redux";
import { UserObject, UserProfile } from "@basalt-commons/api/object/user";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { GoonoLoginArgs } from "@basalt-react-commons/services/user";
import { ILogin, ILoginWithEmail } from "@basalt-commons/api/request/user";
import { IPFSObject } from "./ipfs";

export enum TokenStateStatus {
  INIT = "TokenState::INIT",
  SUCCESS = "TokenState::SUCCESS",
  TEMP_FOR_JOIN = "TokenState::TEMP_FOR_JOIN",
  SUCCESS_SIGN_UP_SNS = "TokenState::SUCCESS_SIGN_UP_SNS",
  READY_UPDATE_PW = "TokenState::READY_UPDATE_PW",
}

export type TokenShareInfo = {
  token: string;
  type: "project" | "workspace";
};

export enum TokenActionKind {
  // 로그인 액션
  TRY_LOGIN = "TokenAction::TRY_LOGIN",
  TRY_LOGOUT = "TokenAction::TRY_LOGOUT",
  // 회원가입 액션
  TRY_CHECK_REGISTERED = "TokenAction::TRY_CHECK_REGISTERED",
  TRY_EMAIL_REGISTER = "TokenAction::TRY_EMAIL_REGISTER",
  TRY_GET_SNS_TOKEN = "TokenAction::TRY_GET_SNS_TOKEN",
  TRY_RESET_SNS_TOKEN = "TokenAction::TRY_RESET_SNS_TOKEN",
  TRY_SNS_REGISTER = "TokenAction::TRY_SNS_REGISTER",
  // 비밀번호 변경 액션
  TRY_AUTH_PW_EMAIL = "TokenAction::TRY_AUTH_PW_EMAIL",
  TRY_SAVE_PW_TOKEN = "TokenAction::TRY_SAVE_PW_TOKEN",
  TRY_UPDATE_PW = "TokenAction::TRY_UPDATE_PW",
  TRY_RESET_PW_TOKEN = "TokenAction::TRY_RESET_PW_TOKEN",
  // 프로필 변경 액션
  TRY_GET_PROFILE = "TokenAction::TRY_GET_PROFILE",
  TRY_UPDATE_PROFILE = "TokenAction::TRY_UPDATE_PROFILE",
  // 공유 토큰 저장 액션
  TRY_GET_TEMP_TOKEN = "TokenAction::TRY_GET_TEMP_TOKEN",
  TRY_RESET_SHARE_TOKEN = "TokenAction::TRY_RESET_SHARE_TOKEN",
}

export type TokenError = never;

export enum TokenErrorType {}

export type TokenState =
  | {
      readonly status: TokenStateStatus.INIT;
      readonly url?: string;
      readonly email?: string;
    }
  | ({
      readonly status: TokenStateStatus.SUCCESS;
      readonly token: string;
      readonly shareInfo?: TokenShareInfo;
    } & UserObject)
  | {
      readonly status: TokenStateStatus.TEMP_FOR_JOIN;
      readonly shareInfo: TokenShareInfo;
      readonly url?: string;
      readonly email?: string;
    }
  | {
      readonly status: TokenStateStatus.SUCCESS_SIGN_UP_SNS;
      readonly args: ILogin;
      readonly shareInfo?: TokenShareInfo;
    }
  | {
      readonly status: TokenStateStatus.READY_UPDATE_PW;
      readonly pwToken: string;
    };

export type TokenAction =
  | {
      readonly kind: TokenActionKind.TRY_LOGIN;
      readonly args: GoonoLoginArgs;
    }
  | {
      readonly kind: TokenActionKind.TRY_LOGOUT;
    }
  | {
      readonly kind: TokenActionKind.TRY_CHECK_REGISTERED;
      readonly email: string;
    }
  | {
      readonly kind: TokenActionKind.TRY_EMAIL_REGISTER;
      readonly args: ILoginWithEmail;
      readonly name: string;
      readonly marketing_term: boolean;
    }
  | {
      readonly kind: TokenActionKind.TRY_GET_SNS_TOKEN;
      readonly args: ILogin;
      readonly checkEmail?: string;
    }
  | {
      readonly kind: TokenActionKind.TRY_RESET_SNS_TOKEN;
    }
  | {
      readonly kind: TokenActionKind.TRY_SNS_REGISTER;
      readonly marketing_term: boolean;
      readonly name: string;
    }
  | {
      readonly kind: TokenActionKind.TRY_AUTH_PW_EMAIL;
      readonly email: string;
    }
  | {
      readonly kind: TokenActionKind.TRY_SAVE_PW_TOKEN;
      readonly pwToken: string;
    }
  | {
      readonly kind: TokenActionKind.TRY_UPDATE_PW;
      readonly newPW: string;
    }
  | {
      readonly kind: TokenActionKind.TRY_RESET_PW_TOKEN;
    }
  | ({
      readonly kind: TokenActionKind.TRY_UPDATE_PROFILE;
      readonly new_profile?: IPFSObject;
      readonly new_user_sign?: string;
      readonly new_user_sign_cids?: IPFSObject;
    } & Partial<UserProfile>)
  | {
      readonly kind: TokenActionKind.TRY_GET_PROFILE;
    }
  | {
      readonly kind: TokenActionKind.TRY_GET_TEMP_TOKEN;
      readonly shareInfo: TokenShareInfo;
    }
  | {
      readonly kind: TokenActionKind.TRY_RESET_SHARE_TOKEN;
    };

const smid = "TOKEN_STATE_MACHINE3";
export type TokenStateMachineType = StateMachine3<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>;
export const tokenStateMachine: TokenStateMachineType = new StateMachine3<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>(smid, { status: TokenStateStatus.INIT }, [
  // join을 위한 token 보관하기
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenActionKind.TRY_GET_TEMP_TOKEN
  ),
  transition(
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenActionKind.TRY_GET_TEMP_TOKEN
  ),
  transition(
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenActionKind.TRY_GET_TEMP_TOKEN
  ),
  // email register sequence
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_CHECK_REGISTERED
  ),
  transition(
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenActionKind.TRY_CHECK_REGISTERED
  ),
  /** SNS 회원가입 도중 이메일 가입으로 전환하려 할때 */
  transition(
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenActionKind.TRY_CHECK_REGISTERED
  ),
  transition(
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_CHECK_REGISTERED
  ),
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_EMAIL_REGISTER
  ),
  transition(
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenActionKind.TRY_EMAIL_REGISTER
  ),
  // sns register sequence
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenActionKind.TRY_GET_SNS_TOKEN
  ),
  transition(
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenActionKind.TRY_GET_SNS_TOKEN
  ),
  transition(
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenActionKind.TRY_GET_SNS_TOKEN
  ),
  transition(
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_RESET_SNS_TOKEN
  ),
  transition(
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenActionKind.TRY_RESET_SNS_TOKEN
  ),
  transition(
    TokenStateStatus.SUCCESS_SIGN_UP_SNS,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_SNS_REGISTER
  ),
  // login sequence
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_LOGIN
  ),
  transition(
    TokenStateStatus.TEMP_FOR_JOIN,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_LOGIN
  ),
  transition(
    TokenStateStatus.SUCCESS,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_LOGOUT
  ),
  // pw 업데이트 해주는 경우는 shareToken 저장하지 못함
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_AUTH_PW_EMAIL
  ),
  transition(
    TokenStateStatus.INIT,
    TokenStateStatus.READY_UPDATE_PW,
    TokenActionKind.TRY_SAVE_PW_TOKEN
  ),
  transition(
    TokenStateStatus.READY_UPDATE_PW,
    TokenStateStatus.READY_UPDATE_PW,
    TokenActionKind.TRY_SAVE_PW_TOKEN
  ),
  transition(
    TokenStateStatus.READY_UPDATE_PW,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_UPDATE_PW
  ),
  transition(
    TokenStateStatus.READY_UPDATE_PW,
    TokenStateStatus.INIT,
    TokenActionKind.TRY_RESET_PW_TOKEN
  ),
  // profile
  transition(
    TokenStateStatus.SUCCESS,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_GET_PROFILE
  ),
  transition(
    TokenStateStatus.SUCCESS,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_UPDATE_PROFILE
  ),
  transition(
    TokenStateStatus.SUCCESS,
    TokenStateStatus.SUCCESS,
    TokenActionKind.TRY_RESET_SHARE_TOKEN
  ),
]);

export type DispatchTokenAction = Dispatch<
  StateMachineAction<
    TokenStateStatus,
    TokenState,
    TokenActionKind,
    TokenAction,
    TokenError
  >
>;
export default mkReducer<
  TokenStateStatus,
  TokenState,
  TokenActionKind,
  TokenAction,
  TokenError
>(tokenStateMachine);

export const doTokenAction = (
  dispatch: DispatchTokenAction,

  nextAction: TokenAction,
  onResolve: () => void = () => {},
  onReject: (err: TokenError | InternalError) => void = () => {}
) => {
  dispatch(tokenStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doTokenActionAsync = (
  dispatch: DispatchTokenAction,
  nextAction: TokenAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(tokenStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetToken = (dispatch: DispatchTokenAction) => {
  dispatch(tokenStateMachine.newResetAction());
};
