import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  PersistAction,
  PersistActionKind,
  PersistState,
  persistStateMachine,
  PersistStateMachineType,
  PersistStateStatus,
} from "../../store/reducers/persist";
import translate, { DEFAULT_LANG } from "../../utils/translate";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import GlobalTranslate from "@ui/basalt/translate";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.persist,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PersistContainerProps = PropsFromRedux & {
  stateMachine: PersistStateMachineType;
};

class PersistContainer extends ReduxStateComponent3<PersistContainerProps> {
  static defaultProps = { stateMachine: persistStateMachine };
  constructor(props: PersistContainerProps) {
    super(props);
  }

  protected onAction(
    prevState: PersistState,
    action: PersistAction
  ): PersistState {
    switch (action.kind) {
      case PersistActionKind.TRY_CHANGE_LANG: {
        const globarTranslateUpdator = GlobalTranslate.mkTranslateUpdater();
        globarTranslateUpdator(action.lang);

        translate.setLanguage(action.lang);
        return {
          ...prevState,
          status: PersistStateStatus.INIT,
          lang: action.lang,
        };
      }
      case PersistActionKind.TRY_RESET: {
        translate.setLanguage(DEFAULT_LANG);
        return {
          status: PersistStateStatus.INIT,
          lang: DEFAULT_LANG,
        };
      }
      case PersistActionKind.UPDATE_VISIT_TIME: {
        const { time } = action;
        return {
          ...prevState,
          visitLogTime: time,
        };
      }
      case PersistActionKind.UPDATE_CAPTURE: {
        const { capture } = action;
        return {
          ...prevState,
          capture,
        };
      }
      case PersistActionKind.RESET_CAPTURE: {
        return {
          ...prevState,
          capture: undefined,
        };
      }
      case PersistActionKind.TOGGLE_ONBOARD: {
        return {
          ...prevState,
          onboard: !prevState.onboard,
        };
      }
      default: {
        throw mkErr({
          kind: InternalErrorKind.Fatal,
          loc: "PersistActionKind::default",
          msg: "Unknown action",
          action,
        });
      }
    }
  }
}

export default connector(PersistContainer);
