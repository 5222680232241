export default {
  ko: {
    purchase: {
      error: {
        expried_date: "카드 유효기간이 이상합니다.",
        input_err: "형식에 어긋납니다.",
      },
      payment_info: "결제 정보",
      select_card: "카드 선택",
      info: {
        person: "인",
        month: "월",
      },
      back: {
        go_back: {
          first: "",
          second: " 으로 돌아가기",
        },
        back_info: "결제 정보 화면으로 돌아가기",
      },
      purchase: "결제하기",
      card: {
        domestic: "한국 발급 카드",
        desc_domestic: "신한, 국민, 우리은행 및 한국 기업에서 발급받은 카드",
        global: "한국 외 발급 카드",
        desc_global: "한국 외 타국적 소속 기업에서 발급받은 신용 카드",
      },
      credit: {
        title: "워크스페이스 내 크레딧",
        use: "크레딧 사용하기",
        use_placeholder: "사용할 크레딧을 숫자로 입력해주세요",
        apply: "적용하기",
        use_all: "전액 사용",
        use_credit: "사용한 Credit",
        now_credit: "현재 보유 Credit",
        overflow_error: "보유하신 크레딧보다 많습니다",
      },
    },
  },

  en: {
    purchase: {
      error: {
        expried_date: "The expiration date of the credit card is invalid",
        input_err: "This is an invalid format",
      },
      payment_info: "Payment Information",
      select_card: "Select Card",
      info: {
        person: "seats",
        month: "month",
      },
      back: {
        go_back: {
          first: "Return to ",
          second: "",
        },
        back_info: "Return to payment information",
      },
      purchase: "Pay now",
      card: {
        domestic: "Card issued in Korea",
        desc_domestic:
          "Card issued by Shinhan, Kookmin, Woori Bank and Korean Companies",
        global: "Card issued in countries other than Korea",
        desc_global:
          "Credit card issued by companies affiliated with countries other than Korea",
      },
      credit: {
        title: "Credits in Workspace",
        use: "Use credits",
        use_placeholder: "Please enter the number of credit to be used",
        apply: "Apply",
        use_all: "Use all",
        use_credit: "Expired Credits",
        now_credit: "Owned Credits",
        overflow_error: "It's more than owned credits",
      },
    },
  },
};
