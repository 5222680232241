import T from "@redwit-commons/utils/typecheck";
export interface Version {
  version: string;
  min_app_version: string;
  hello: "Goono API server";
}
export const VersionSchema = T.object()
  .addField("version", T.string())
  .addField("min_app_version", T.string())
  .addField("hello", T.string().withEnum(["Goono API server"]));
