import { doAPIDeleteRequest, doAPIGetRequest, doAPIPostRequest } from "./api";
import { ISearch } from "@basalt-commons/api/request/note";
import {
  validateOSearch,
  validateOTagSearch,
  validateOKeywordSearch,
  validateOProjectSearch,
} from "@basalt-commons/api/response/note";
import { ICreateHistory } from "@basalt-commons/api/request/history";
import {
  validateOCreateHistory,
  validateODeleteHistory,
  validateOGetHistory,
} from "@basalt-commons/api/response/history";

// TODO: Specify return types
const search = async (token: string, args: ISearch) => {
  const ret = await doAPIGetRequest("search", token, args);
  return validateOSearch(ret);
};

const updateSearchWord = async (token: string, args: ICreateHistory) => {
  const ret = await doAPIPostRequest("search/history/word", token, args);
  return validateOCreateHistory(ret);
};

const getSearchWord = async (token: string) => {
  const ret = await doAPIGetRequest("search/history", token);
  return validateOGetHistory(ret);
};

const deleteSearchWord = async (token: string, historyId: string) => {
  const ret = await doAPIDeleteRequest(`search/history/${historyId}`, token);
  return validateODeleteHistory(ret);
};

const searchMoreTag = async (token: string, args: ISearch) => {
  const ret = await doAPIGetRequest("search/tag", token, args);
  return validateOTagSearch(ret);
};

const searchMoreKeyword = async (token: string, args: ISearch) => {
  const ret = await doAPIGetRequest("search/keyword", token, args);
  return validateOKeywordSearch(ret);
};

const searchMoreProject = async (token: string, args: ISearch) => {
  const ret = await doAPIGetRequest("search/project", token, args);
  return validateOProjectSearch(ret);
};

export default {
  search,
  searchMoreTag,
  searchMoreKeyword,
  searchMoreProject,
  updateSearchWord,
  getSearchWord,
  deleteSearchWord,
};
