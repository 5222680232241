import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { NdaObjectWithContract } from "@basalt-commons/global-api/object/nda";
import EmptyList from "@src/components/pure/view/EmptyList";
import stampDragImg from "@ui/basalt/images/note/stamp.png";
import translate from "@src/utils/translate";
import StampInfoItem from "@src/components/pure/items/StampInfoItem";
import { StampWithNDA } from "@basalt-commons/global-api/object/stamp";
import CircularLoadingWrap from "@src/components/pure/utils/LoadingWrap/CircularLoadingWrap";
import NotFoundView from "./NotFoundView";

const useStyles = makeStyles((MuiTheme) => ({
  stamp: {
    marginTop: 16,
    [MuiTheme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
}));

type TabStampViewProps = {
  ndas: NdaObjectWithContract[];
  stamps: StampWithNDA[];
  searchQuery: string;
  hasAuth: boolean;
  time_zone: string;
  format: string;
  stampStatus: "loading" | "fetched";
  onViewHistory: (stamp: StampWithNDA, ndas: NdaObjectWithContract[]) => void;
  onDeleteStamp: (stamp: StampWithNDA) => void;
  onEditStamp: (stamp: StampWithNDA) => void;
};

const TabStampView = ({
  ndas,
  stamps,
  searchQuery,
  hasAuth,
  time_zone,
  format,
  stampStatus,
  onViewHistory,
  onDeleteStamp,
  onEditStamp,
}: TabStampViewProps) => {
  const classes = useStyles();

  const renderStampGrid = () => {
    return (
      <Grid
        direction="row"
        container
        item
        className={classes.stamp}
        spacing={2}
      >
        {stamps.map((stamp) => {
          const filteredNdas = ndas.filter((nda) =>
            nda.Documents.find(
              (doc) => doc.nda_contractor_sign_cid === stamp.stamp_cid
            )
          );

          return (
            <StampInfoItem
              hasAuth={hasAuth}
              onEdit={() => {
                onEditStamp(stamp);
              }}
              onDelete={() => {
                onDeleteStamp(stamp);
              }}
              onViewHistory={() => {
                onViewHistory(stamp, filteredNdas);
              }}
              key={stamp.id}
              stamp={stamp}
              time_zone={time_zone}
              format={format}
              nda_count={filteredNdas.length}
            />
          );
        })}
      </Grid>
    );
  };
  const renderEmptyList = () => {
    return (
      <EmptyList
        imgElem={<img src={stampDragImg} alt="Upload tip" />}
        title={translate.ndas.drag_tip}
        button={{
          htmlFor: "upload-stamp-file",
          text: translate.add_files,
        }}
      />
    );
  };

  return (
    <>
      <CircularLoadingWrap loading={stampStatus === "loading"}>
        {stamps.length > 0 ? (
          renderStampGrid()
        ) : searchQuery.length === 0 ? (
          renderEmptyList()
        ) : (
          <NotFoundView
            title={` No signatures were found for "${searchQuery}"`}
          />
        )}
      </CircularLoadingWrap>
    </>
  );
};

export default TabStampView;
