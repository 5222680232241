import React, { Suspense } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { TokenStateStatus } from "../store/reducers/token";
import {
  ModalStateStatus,
  ModalType,
  resetModal,
} from "../store/reducers/modal";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ScreenLoadingView from "../components/pure/view/ScreenLoadingView";
import { doPersistAction, PersistActionKind } from "../store/reducers/persist";
import { UnregisterCallback } from "history";

const SignModal = React.lazy(() => import("../modals/SignModal"));
const TextInputModal = React.lazy(() => import("../modals/TextInputModal"));
const DeleteModal = React.lazy(() => import("../modals/DeleteModal"));
const TermModal = React.lazy(() => import("../modals/TermModal"));
const InviteWorkspaceModal = React.lazy(
  () => import("../modals/InviteWorkspaceModal")
);
const CreateEditTeamModal = React.lazy(
  () => import("../modals/CreateEditTeamModal")
);
const InviteProjectModal = React.lazy(
  () => import("../modals/InviteProjectModal")
);
const ProjectChangeOwnerModal = React.lazy(
  () => import("../modals/ProjectChangeOwnerModal")
);
const SolutionVideoModal = React.lazy(
  () => import("../modals/SolutionVideoModal")
);

const NDASelectModal = React.lazy(() => import("../modals/NDASelectModal"));
const EmailSendModal = React.lazy(() => import("../modals/EmailSendModal"));
const SignHistoryModal = React.lazy(() => import("../modals/SignHistoryModal"));
const ConnectedFilesModal = React.lazy(
  () => import("../modals/ConnectedFilesModal")
);
const LinkCreateModal = React.lazy(() => import("../modals/LinkCreateModal"));
const HashtagModal = React.lazy(() => import("../modals/HashTagModal"));
const PolicyInfoModal = React.lazy(() => import("../modals/PolicyInfoModal"));
const CompanyStampModal = React.lazy(
  () => import("../modals/CompanyStampModal")
);
const SetDefaultCardModal = React.lazy(
  () => import("../screens/BillingScreen/SetDefaultCardModal")
);
const OnboardModal = React.lazy(() => import("../modals/OnboardModal"));
const SearchModal = React.lazy(() => import("../modals/SearchModal"));
const LinkDoneModal = React.lazy(() => import("../modals/LinkDoneModal"));

const mapStateToProps = (state: RootState) => {
  return {
    modal: state.modal,
    token: state.token,
    persist: state.persist,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type ModalsProps = PropsFromRedux & RouteComponentProps<Props>;
type ModalsState = {
  windowWidth: number;
  unlisten?: UnregisterCallback;
};
class Modals extends React.PureComponent<ModalsProps, ModalsState> {
  private _unmounted: boolean = false;
  constructor(props: ModalsProps) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      unlisten: undefined,
    };
  }

  onResize = () => {
    if (window.innerWidth !== window.innerWidth) {
      this.setState({ windowWidth: window.innerWidth });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this.onResize, true);
    const { history } = this.props;
    // Close any open modals when the page is redirected
    this.setState({
      unlisten: history.listen((_location: RouteComponentProps["location"]) => {
        resetModal(this.props.dispatch);
      }),
    });
  }

  componentWillUnmount() {
    if (this._unmounted === true) return;
    if (this.state.unlisten) {
      this.state.unlisten();
    }
    this._unmounted = false;
    window.removeEventListener("resize", this.onResize, true);
    return;
  }

  isSignedIn() {
    const { token } = this.props;
    if (token.state.status === TokenStateStatus.SUCCESS) return true;
    return false;
  }

  modalType() {
    const { modal } = this.props;
    if (modal.state.status === ModalStateStatus.INIT) return;
    return modal.state.type;
  }

  getModalOtherType() {
    const { modal } = this.props;
    if (modal.state.status === ModalStateStatus.INIT) return;
    return modal.state.other?.type;
  }

  onCloseOnboard() {
    doPersistAction(this.props.dispatch, {
      kind: PersistActionKind.TOGGLE_ONBOARD,
    });
  }

  renderOnboard() {
    const { token, persist } = this.props;
    if (
      token.state.status === TokenStateStatus.SUCCESS &&
      persist.state.onboard
    ) {
      return (
        <OnboardModal
          onClose={() => {
            this.onCloseOnboard();
          }}
        />
      );
    }
    return;
  }

  render() {
    const userSignedIn = this.isSignedIn();
    const type = this.modalType();

    if (!userSignedIn) {
      return (
        <>
          <Suspense fallback={<ScreenLoadingView />}>
            <SignModal windowWidth={window.innerWidth} />
            {type === ModalType.TERMS && (
              <TermModal windowWidth={window.innerWidth} />
            )}
            {type === ModalType.SOLUTION && (
              <SolutionVideoModal windowWidth={window.innerWidth} />
            )}
          </Suspense>
        </>
      );
    }
    return (
      <>
        <Suspense fallback={<ScreenLoadingView />}>
          <SignModal windowWidth={window.innerWidth} />
          <TextInputModal windowWidth={window.innerWidth} />
          <DeleteModal windowWidth={window.innerWidth} />
          <CompanyStampModal windowWidth={window.innerWidth} />
          {(type === ModalType.SELECT_NDA ||
            this.getModalOtherType() === ModalType.SELECT_NDA) && (
            <NDASelectModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.INVITE_PROJECT && (
            <InviteProjectModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.EMAIL_SEND && (
            <EmailSendModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.PROJECT_CHANGE_OWNER && (
            <ProjectChangeOwnerModal windowWidth={window.innerWidth} />
          )}

          {type === ModalType.EDIT_TAG && (
            <HashtagModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.INVITE_WORKSPACE && (
            <InviteWorkspaceModal windowWidth={window.innerWidth} />
          )}
          <CreateEditTeamModal windowWidth={window.innerWidth} />
          {type === ModalType.TERMS && (
            <TermModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.SOLUTION && (
            <SolutionVideoModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.VIEW_HISTORY && (
            <SignHistoryModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.CONNECTED_FILES && (
            <ConnectedFilesModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.LINK_CREATE && (
            <LinkCreateModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.POLICY_INFO && (
            <PolicyInfoModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.SET_DEFAULT_CARD && (
            <SetDefaultCardModal windowWidth={window.innerWidth} />
          )}
          {type === ModalType.SEARCH && (
            <SearchModal windowWidth={this.state.windowWidth} />
          )}
          {type === ModalType.LINK_DONE && (
            <LinkDoneModal windowWidth={this.state.windowWidth} />
          )}
          {this.renderOnboard()}
        </Suspense>
      </>
    );
  }
}

export default connector(withRouter(Modals));
