import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { ChatObject } from "@basalt-commons/global-api/object/chat";

export enum CommentStateStatus {
  INIT = "CommentState::INIT",
  SUCCESS = "CommentState::SUCCESS",
}

export enum CommentActionKind {
  TRY_GET_COMMENT = "CommentAction::TRY_GET_COMMENT",
  TRY_CREATE_COMMENT = "CommentAction::TRY_CREATE_COMMENT",
  TRY_REMOVE_COMMENT = "CommentAction::TRY_REMOVE_COMMENT",
  TRY_EDIT_COMMENT = "CommentAction::TRY_EDIT_COMMENT",
  TRY_PIN_COMMENT = "CommentAction::TRY_PIN_COMMENT",
}

export type CommentState =
  | {
      readonly status: CommentStateStatus.INIT;
    }
  | {
      readonly status: CommentStateStatus.SUCCESS;
      readonly workspaceId: string;
      readonly noteId: string;
      readonly projectId: string;
      readonly comments: ChatObject[];
    };

export type CommentAction =
  | {
      readonly kind: CommentActionKind.TRY_GET_COMMENT;
      readonly workspaceName: string;
      readonly noteId: string;
      readonly projectId: string;
    }
  | {
      readonly kind: CommentActionKind.TRY_CREATE_COMMENT;
      readonly text: string;
      readonly isPinned: boolean;
      readonly mentionIds?: string[];
      readonly pageNum?: number;
    }
  | {
      readonly kind: CommentActionKind.TRY_REMOVE_COMMENT;
      readonly commentId: string;
    }
  | {
      readonly kind: CommentActionKind.TRY_EDIT_COMMENT;
      readonly text: string;
      readonly commentId: string;
      readonly pageNum?: number;
    }
  | {
      readonly kind: CommentActionKind.TRY_PIN_COMMENT;
      readonly commentId: string;
    };

export type CommentError = never;

const smid = "COMMENT_STATE_MACHINE3";
export type CommentStateMachineType = StateMachine3<
  CommentStateStatus,
  CommentState,
  CommentActionKind,
  CommentAction,
  CommentError
>;
export const commentStateMachine: StateMachine3<
  CommentStateStatus,
  CommentState,
  CommentActionKind,
  CommentAction,
  CommentError
> = new StateMachine3<
  CommentStateStatus,
  CommentState,
  CommentActionKind,
  CommentAction,
  CommentError
>(smid, { status: CommentStateStatus.INIT }, [
  transition(
    CommentStateStatus.INIT,
    CommentStateStatus.SUCCESS,
    CommentActionKind.TRY_GET_COMMENT
  ),
  transition(
    CommentStateStatus.SUCCESS,
    CommentStateStatus.SUCCESS,
    CommentActionKind.TRY_GET_COMMENT
  ),

  transition(
    CommentStateStatus.SUCCESS,
    CommentStateStatus.SUCCESS,
    CommentActionKind.TRY_CREATE_COMMENT
  ),

  transition(
    CommentStateStatus.SUCCESS,
    CommentStateStatus.SUCCESS,
    CommentActionKind.TRY_EDIT_COMMENT
  ),
  transition(
    CommentStateStatus.SUCCESS,
    CommentStateStatus.SUCCESS,
    CommentActionKind.TRY_REMOVE_COMMENT
  ),

  transition(
    CommentStateStatus.SUCCESS,
    CommentStateStatus.SUCCESS,
    CommentActionKind.TRY_PIN_COMMENT
  ),
]);

export type DispatchCommentAction = Dispatch<
  StateMachineAction<
    CommentStateStatus,
    CommentState,
    CommentActionKind,
    CommentAction,
    CommentError
  >
>;
export default mkReducer<
  CommentStateStatus,
  CommentState,
  CommentActionKind,
  CommentAction,
  CommentError
>(commentStateMachine);
export const doCommentAction = (
  dispatch: DispatchCommentAction,
  nextAction: CommentAction,
  onResolve: () => void = () => {},
  onReject: (err: CommentError | InternalError) => void = () => {}
) => {
  dispatch(commentStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doCommentActionAsync = (
  dispatch: DispatchCommentAction,
  nextAction: CommentAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(commentStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetComment = (dispatch: DispatchCommentAction) => {
  dispatch(commentStateMachine.newResetAction());
};
