import { makeStyles } from "@material-ui/core";
import { theme } from "@theme";

export const useStyles = makeStyles({
  drag_tip_text: {
    ...theme.font24,
    color: theme.gray_6,
    marginBottom: 8,
    marginTop: 12,
  },

  drag_or_text: {
    ...theme.font16,
    color: theme.gray_6,
    marginBottom: 12,
  },

  upload_button: {
    backgroundColor: theme.cian_5,
    height: 64,
    padding: "22px 36px",
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.cian_8,
    },
  },
  upload_button_text: {
    color: theme.white,
    ...theme.font16,
  },
  nda_upload_border: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  no_nda_grid: {
    width: "100%",
    height: 480,
  },

  table_label_row: {
    paddingLeft: 26,
    paddingRight: 26,
  },

  button_text_cancel: {
    ...theme.font16,
    color: theme.cian_5,
  },
});
