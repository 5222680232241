import React from "react";
import { Grid } from "@material-ui/core";
import { ProjectObject } from "@basalt-commons/api/object/project";
import SidebarFolderItem, {
  SidebarFolderItemHeight,
} from "./items/SidebarFolderItem";

type SidebarFolderProps = {
  userId: string;
  folders: ProjectObject[];
  onEditProject:
    | ((pj: ProjectObject) => void)
    | ((pj: ProjectObject) => Promise<void>);
  onClick: (pj: ProjectObject) => void;
  selectedId?: string;
};

type SidebarFolderState = {};

class SidebarFolderList extends React.PureComponent<
  SidebarFolderProps,
  SidebarFolderState
> {
  static defaultProps = {
    onEditProject: () => {},
    onClick: () => {},
  };

  render() {
    const { folders } = this.props;
    return (
      <Grid
        container
        item
        xs={12}
        style={{
          width: "100%",
          maxHeight: SidebarFolderItemHeight * folders.length,
        }}
      >
        {folders.map((folder) => {
          return (
            <SidebarFolderItem
              key={folder.id}
              {...this.props}
              folder={folder}
              onEdit={async (pj) => {
                await this.props.onEditProject(pj);
              }}
              selected={this.props.selectedId === folder.id}
            />
          );
        })}
      </Grid>
    );
  }
}

export default SidebarFolderList;
