export default {
  ko: {
    timeline: {
      timeline: "타임라인",
      empty: "로그가 비어 있습니다.",
      all: "All",
      upload: "Upload",
      verified: "Verified",
      link: "Link",
      download: "Download",
      view: "View",
      open: "Open",
      create: "Create",
      join: "Join",
      delete: "Delete",
      reset: "재설정",
      members: "멤버",
      me: "(나)",
      refresh: "새로고침",
      activities: "활동",
      time: "시간",
      activity: "활동 내용",
      team_member: "팀원",
      folder_name: "폴더 명",
      file_name: "파일 명",
      block_number: "블록 넘버",
    },
  },

  en: {
    timeline: {
      empty: "The log is empty",
      timeline: "Timeline",
      all: "All",
      upload: "Upload",
      verified: "Verified",
      link: "Link",
      download: "Download",
      view: "View",
      open: "Open",
      create: "Create",
      join: "Join",
      delete: "Delete",
      reset: "Reset",
      members: "Members",
      me: "(Me)",
      refresh: "Refresh",
      activities: "Activities",
      time: "Time",
      activity: "Activity",
      team_member: "Team Member",
      folder_name: "Folder Name",
      file_name: "File Name",
      block_number: "Block №",
    },
  },
};
