export default {
  ko: {
    link_create: {
      title: "파일 공유 링크 생성하기",
      settings: "링크 기본 설정",
      link_name: "링크 이름",
      required: "필수",
      link_name_placeholder: "링크 이름을 작성해주세요",
      expire_date: "유효기간",
      finish: "링크 생성 완료",
      option_settings: {
        title: "링크 옵션 설정",
        email: "이메일 수집하기",
        download: "다운로드 허용하기",
        nda_sign: "NDA 사인 받기",
        print: "프린트 허용하기",
      },
      invalid_auth: "링크 생성 권한이 없습니다",
      nda: {
        set: "NDA 파일 설정하기",
        set_text: "NDA파일 설정해주세요",
        title: "NDA 파일 설정",
        file: "NDA 파일",
        change: "NDA파일 바꾸기",
        name: "이름",
        name_placeholder: "이름을 작성해주세요",
        affiliation: "소속",
        affiliation_placeholder: "소속을 작성해주세요",
        sign: "서명",
        sign_from_name: "이름으로 서명 대체하기",
        set_default_sign: "현재 서명을 기본 서명으로 설정하기",
        sign_change: "서명을 바꾸시겠습니까?",
        no_saved_signature: "저장된 서명이 없습니다",
        upload_sign: "서명 업로드",
        draw_sign: "서명 그리기",
        upload_file: "파일 업로드",
        company_sign: "회사 서명",
        replace_name: "이름으로 대체",
        sign_placeholder: "서명을 입력해주세요",
      },
    },

    link_done: {
      header: "링크가 생성되었습니다!",
      copy: "복사",
      created_link: "생성된 링크",
      link_info: "링크 정보",
      file_name: "파일 이름",
      link_name: "링크 이름",
      expire: "유효 기간",
      option: "링크 옵션",
      return: "폴더 화면으로 돌아가기",
    },

    link_send_email: {
      send_mail: "이메일 발송",
      button_send_email: "이메일 발송하기",
      receiver: "받으시는 분",
      email_placeholder: "이메일을 입력하세요",
      add: "추가",
      message: "메세지",
      character: "자",
      message_placeholder:
        "메일에 입력할 메세지를 입력해주세요. 이메일 미리보기에서 실시간으로 확인할 수 있어요.",
      email_preview: "이메일 미리보기",
      message_body: {
        share: "Share the file",
        sentence_ko1: "님이",
        sentence_ko2: "파일을 공유했습니다.",
        sentence_en: "",
        instructions: "아래 버튼을 클릭하면 파일을 확인할 수 있습니다.",
        view_file: "View the file",
        footer_text: "Thank you, 세상에서 가장 안전한 문서 관리 솔루션 baSalt",
        footer_credentials:
          "ReDWit Inc _ 대전광역시 유성구 어은로 44, 2층, basaltdocs@redwit.io",
        footer_note:
          "* 본 메일은 발신 전용으로, 회신 내용을 확인할 수 없습니다.",
      },
    },
  },

  en: {
    link_create: {
      title: "Create a file sharing link",
      settings: "Link preferences",
      link_name: "Link name",
      required: "required",
      link_name_placeholder: "Enter the link name",
      expire_date: "Expiration date",
      finish: "Create link",
      option_settings: {
        title: "Link option settings",
        email: "Require email",
        download: "Allow downloading",
        nda_sign: "Require NDA sign",
        print: "Allow printing",
      },
      invalid_auth: "You don't have permission to create a link",
      nda: {
        set: "Setting up NDA files",
        set_text: "Please set up the NDA file",
        title: "NDA file settings",
        file: "NDA file",
        change: "Change the NDA file",
        name: "Name",
        name_placeholder: "Enter your name",
        affiliation: "Institution",
        affiliation_placeholder: "Enter your institution",
        sign: "Signature",
        sign_from_name: "Use your name for the signature",
        set_default_sign: "Set the current signature as the default signature",
        sign_change: "Do you want to change your signature?",
        no_saved_signature: "No signature",
        upload_sign: "Upload a sign",
        draw_sign: "Draw a sign",
        upload_file: "Upload file",
        company_sign: "Company sign",
        replace_name: "Replace by name",
        sign_placeholder: "Enter your signature",
      },
    },

    link_done: {
      header: "A link has been created!",
      copy: "Copy",
      created_link: "Created link",
      link_info: "Link information",
      file_name: "File name",
      link_name: "Link name",
      expire: "Expiration date",
      option: "Sharing options",
      return: "Return to the folder",
    },

    link_send_email: {
      send_mail: "Send an email",
      button_send_email: "Send an email",
      receiver: "Recipient",
      email_placeholder: "Enter the email address",
      add: "Add",
      message: "Message",
      message_placeholder:
        "Enter the message to display in the mail. You can check it in real time in the email preview.",
      email_preview: "Email preview",
      character: " characters",
      message_body: {
        share: "Share the file",
        sentence_ko1: "",
        sentence_ko2: "",
        sentence_en: "has shared the file",
        instructions: "You can check the file by clicking the button below.",
        view_file: "View the file",
        footer_text:
          "Thank you, the world's safest document management solution, baSalt",
        footer_credentials:
          "ReDWit Inc _ Eoeun-ro 44, 2nd floor, Yuseong-gu, Daejeon, basaltdocs@redwit.io",
        footer_note:
          "* This email is for sending only, please do not reply to it.",
      },
    },
  },
};
