import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  StateMachineAction,
  mkReducer,
  transition,
} from "@redwit-react-commons/reducers/state3";

export enum BaseGridMode {
  SUCCESS,
  LOADING,
  FAILED,
}

export enum OnboardingType {
  PROJECT,
  NDA,
  TIMELINE,
}

export type BaseErrorArgs = {
  code?: number;
};

export type BaseGridModeType =
  | {
      mode: BaseGridMode.SUCCESS;
      state?: "loading";
    }
  | {
      mode: BaseGridMode.LOADING;
    }
  | ({
      mode: BaseGridMode.FAILED;
    } & BaseErrorArgs);

export enum BaseGridStateStatus {
  INIT = "BaseGrid::INIT",
}

export enum BaseGridActionKind {
  TRY_RESET = "BaseGridAction::TRY_RESET",
  TRY_UPDATE_SCREEN_MODE = "BaseGridAction::TRY_UPDATE_SCREEN_MODE",
}

export type BaseGridState = {
  readonly status: BaseGridStateStatus.INIT;
  readonly screenMode: BaseGridModeType;
  readonly sidebar?: boolean;
  readonly header?: boolean;
  readonly footer?: boolean;
  readonly search?: boolean;
  readonly onboarding?: OnboardingType;
  readonly landingMode?: boolean;
};

export type BaseGridAction =
  | {
      readonly kind: BaseGridActionKind.TRY_RESET;
    }
  | {
      readonly kind: BaseGridActionKind.TRY_UPDATE_SCREEN_MODE;
      readonly screenMode?: BaseGridModeType;
      readonly sidebar?: boolean;
      readonly header?: boolean;
      readonly footer?: boolean;
      readonly search?: boolean;
      readonly onboarding?: OnboardingType;
      readonly landingMode?: boolean;
    };

export type BaseGridError = never;

const smid = "BaseGrid_STATE_MACHINE3";
export type BaseGridStateMachineType = StateMachine3<
  BaseGridStateStatus,
  BaseGridState,
  BaseGridActionKind,
  BaseGridAction,
  BaseGridError
>;
export const BaseGridStateMachine: BaseGridStateMachineType = new StateMachine3<
  BaseGridStateStatus,
  BaseGridState,
  BaseGridActionKind,
  BaseGridAction,
  BaseGridError
>(
  smid,
  {
    status: BaseGridStateStatus.INIT,
    screenMode: {
      mode: BaseGridMode.SUCCESS,
    },
  },
  [
    transition(
      BaseGridStateStatus.INIT,
      BaseGridStateStatus.INIT,
      BaseGridActionKind.TRY_RESET
    ),
    transition(
      BaseGridStateStatus.INIT,
      BaseGridStateStatus.INIT,
      BaseGridActionKind.TRY_UPDATE_SCREEN_MODE
    ),
  ]
);

export type DispatchBaseGridAction = Dispatch<
  StateMachineAction<
    BaseGridStateStatus,
    BaseGridState,
    BaseGridActionKind,
    BaseGridAction,
    BaseGridError
  >
>;
export default mkReducer<
  BaseGridStateStatus,
  BaseGridState,
  BaseGridActionKind,
  BaseGridAction,
  BaseGridError
>(BaseGridStateMachine);

export const doBaseGridAction = (
  dispatch: DispatchBaseGridAction,
  nextAction: BaseGridAction,
  onResolve: () => void = () => {},
  onReject: (err: BaseGridError | InternalError) => void = () => {}
) => {
  dispatch(BaseGridStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doBaseGridActionAsync = (
  dispatch: DispatchBaseGridAction,
  nextAction: BaseGridAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(BaseGridStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetBaseGrid = (dispatch: DispatchBaseGridAction) => {
  dispatch(BaseGridStateMachine.newResetAction());
};
