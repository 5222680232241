import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";
import {
  extractUserObject,
  UserObject,
  UserObjectSchema,
  UserObjectWithAuthTypeSchema,
  validateUserObjectWithAuthType,
} from "./user";
import {
  ProjectAuthType,
  UserProjectMapObjectCore,
  UserProjectMapObjectSchema,
} from "./user_project_map";

// tslint:disable-next-line: interface-name

// globalservice 에서 사용됨
export enum ProjectRoleType {
  /**
   * @deprecated 삭제할 필드 입니다.
   */
  PRIVATE = "Project::Private",
  PUBLIC = "Project::Public",
  SHARE = "Project::Share",
}

export const ProjectRoleTypeList = [
  ProjectRoleType.PRIVATE,
  ProjectRoleType.PUBLIC,
  ProjectRoleType.SHARE,
];

export type ProjectResearchInfo = {
  readonly researchName?: string;
  readonly researchOwner?: string;
  readonly researchNumber?: string;
  readonly researchStart?: string;
  readonly researchEnd?: string;
};
export type ProjectEmojiInfo = {
  readonly emoji_lbl?: string;
};
// globalservice 에서 사용됨
export type ProjectWorkspaceInfo = {
  readonly WorkspaceId?: string; // Relation
  readonly project_type?: ProjectRoleType;
  readonly NdaId?: string;
};

export type ProjectPureObjectCore = {
  readonly id: string;
  readonly name: string;
} & ProjectResearchInfo &
  ProjectEmojiInfo &
  ProjectWorkspaceInfo;

export type ProjectDBObjectCore = {
  readonly Users: Array<
    UserObject & { User_Project_Map: UserProjectMapObjectCore }
  >;
} & ProjectPureObjectCore;

export type RefinedProjectObjectCore = ProjectPureObjectCore & {
  readonly Users: Array<
    UserObject & { authType: ProjectAuthType; pinned: string }
  >;
};

export type ProjectDBObject = ProjectDBObjectCore & AccessTime;
export type ProjectPureObject = ProjectPureObjectCore & AccessTime;
export type ProjectObject = RefinedProjectObjectCore & AccessTime;

export const refineProjectObject = (rdbProject: ProjectDBObject) => {
  const Users = rdbProject.Users.map((user) => {
    const userObject = extractUserObject(user);
    const authType = user.User_Project_Map.authType;
    const pinned = user.User_Project_Map.pinned;
    const userObjWithAuth = validateUserObjectWithAuthType({
      ...userObject,
      authType,
      pinned,
    });
    return userObjWithAuth;
  });

  const ret: ProjectObject = {
    ...rdbProject,
    Users,
  };
  return ret;
};

export const ProjectPureObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("emoji_lbl", T.string(), false)
  .addField("researchName", T.string(), false)
  .addField("researchOwner", T.string(), false)
  .addField("researchNumber", T.string(), false)
  .addField("researchStart", T.string(), false)
  .addField("WorkspaceId", T.string(), false)
  .addField("project_type", T.string().withEnum(ProjectRoleTypeList), false)
  .addField("researchEnd", T.string(), false)
  .addField("NdaId", T.string(), false);

export const ProjectDBObjectSchema = ProjectPureObjectSchema.clone().addField(
  "Users",
  T.array(
    UserObjectSchema.clone().addField(
      "User_Project_Map",
      UserProjectMapObjectSchema.clone()
    )
  )
);

export const ProjectObjectSchema = ProjectPureObjectSchema.clone().addField(
  "Users",
  T.array(UserObjectWithAuthTypeSchema.clone())
);

export const extractProjectPureObject = T.mkObjectExtractor<ProjectPureObject>(
  ProjectPureObjectSchema
);
export const extractProjectObject =
  T.mkObjectExtractor<ProjectObject>(ProjectObjectSchema);
export const validateProjectObject =
  T.mkValidator<ProjectObject>(ProjectObjectSchema);
export const extractProjectDBObject = T.mkObjectExtractor<ProjectDBObject>(
  ProjectDBObjectSchema
);
