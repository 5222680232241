// @flow
import React from "react";
import SnackbarContainer from "./SnackbarContainer";
import ModalContainer from "./ModalContainer";
import PersistContainer from "./PersistContainer";
import TokenContainer from "./TokenContainer";
import IPFSContainer from "./IPFSContainer";
import WorkspaceContainer from "./WorkspaceContainer";
import ProjectContainer from "./ProjectContainer";
import NoteContainer from "./NoteContainer";
import FileContainer from "./FileContainer";
import DocumentContainer from "./DocumentContainer";
import LogContainer from "./LogContainer";
import CommentContainer from "./CommentContainer";
import NDAContainer from "./NDAContainer";
import StampContainer from "./StampContainer";
import PaymentContainer from "./PaymentContainer";
import RuleContainer from "./RuleContainer";
import SearchContainer from "./SearchContainer";
import AlarmContainer from "./AlarmContainer";
import BaseGridContainer from "./BaseGridContainer";

// 다양한 container 들을 포함하도록 감싸줌.
export default class ContainerProvider extends React.PureComponent {
  render() {
    return (
      <>
        <TokenContainer />
        <IPFSContainer />
        <SnackbarContainer />
        <ModalContainer />
        <PersistContainer />
        <WorkspaceContainer />
        <ProjectContainer />
        <NoteContainer />
        <FileContainer />
        <DocumentContainer />
        <LogContainer />
        <CommentContainer />
        <NDAContainer />
        <StampContainer />
        <PaymentContainer />
        <RuleContainer />
        <SearchContainer />
        <AlarmContainer />
        <BaseGridContainer />
        {this.props.children}
      </>
    );
  }
}
