import React from "react";
import { Grid } from "@material-ui/core";
import SpoqaHansTypography from "../../components/pure/typo/SpoqaHansTypography";
import { theme } from "@theme";

type NotFoundViewProps = {
  title: string;
  border?: boolean;
};

const NotFoundView = ({ title, border }: NotFoundViewProps) => {
  return (
    <Grid
      style={{
        width: "100%",
        height: 72,
        textAlign: "center",
        borderBottom: border ? `1px solid ${theme.gray_2}` : "0",
      }}
    >
      <SpoqaHansTypography
        style={{
          lineHeight: "72px",
          color: theme.gray_6,
          ...theme.font14,
        }}
      >
        {title}
      </SpoqaHansTypography>
    </Grid>
  );
};

export default NotFoundView;
