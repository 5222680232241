import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  BaseGridAction,
  BaseGridActionKind,
  BaseGridState,
  BaseGridStateMachine,
  BaseGridStateMachineType,
  BaseGridStateStatus,
  BaseGridMode,
} from "@src/store/reducers/basegrid";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.basegrid,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type BaseGridContainerProps = PropsFromRedux & {
  stateMachine: BaseGridStateMachineType;
};

class BaseGridContainer extends ReduxStateComponent3<BaseGridContainerProps> {
  static defaultProps = { stateMachine: BaseGridStateMachine };
  constructor(props: BaseGridContainerProps) {
    super(props);
  }

  protected onAction(
    prevState: BaseGridState,
    action: BaseGridAction
  ): BaseGridState {
    switch (action.kind) {
      case BaseGridActionKind.TRY_RESET: {
        return {
          status: BaseGridStateStatus.INIT,
          screenMode: { mode: BaseGridMode.SUCCESS },
          sidebar: undefined,
          header: undefined,
          footer: undefined,
          search: undefined,
          onboarding: undefined,
          landingMode: undefined,
        };
      }
      case BaseGridActionKind.TRY_UPDATE_SCREEN_MODE: {
        const {
          screenMode,
          sidebar,
          header,
          footer,
          search,
          onboarding,
          landingMode,
        } = action;
        return {
          status: BaseGridStateStatus.INIT,
          screenMode: screenMode ?? prevState.screenMode,
          sidebar,
          header,
          footer,
          search,
          onboarding,
          landingMode,
        };
      }

      default: {
        throw mkErr({
          kind: InternalErrorKind.Fatal,
          loc: "BaseGridActionKind::default",
          msg: "Unknown action",
          action,
        });
      }
    }
  }
}

export default connector(BaseGridContainer);
