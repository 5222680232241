import { doAPIPostRequest, doAPIGetRequest, doAPIDeleteRequest } from "./api";
import {
  IGetNotes,
  ICreateNote,
  IGetNotePDF,
  ICreateNoteTags,
  IDeleteNoteTag,
  IMergeNotes,
} from "@basalt-commons/api/request/note";
import {
  OGetNotes,
  validateOGetNotes,
  validateOGetNotePDF,
  validateOCreateNote,
  OCreateNote,
  validateOCreateNoteTags,
  validateODeleteNoteTag,
  ODeleteNote,
  validateODeleteNote,
  validateOMergeNotes,
  OMergeNotes,
} from "@basalt-commons/api/response/note";
import {
  IGetNoteCertificationPDF,
  IGetNoteOrigin,
  IGetNoteOriginList,
} from "@basalt-commons/global-api/request/note";
import {
  OGetNoteCertificationPDF,
  OGetNoteOrigin,
  OGetNoteOriginList,
  validateOGetNoteCertificationPDF,
  validateOGetNoteOrigin,
  validateOGetNoteOriginList,
} from "@basalt-commons/global-api/response/note";

// OGetNote 형태 전체로 return
const getNotes = async (
  token: string,
  workspaceID: string,
  proejctID: string,
  args?: IGetNotes
): Promise<OGetNotes> => {
  const obj = await doAPIGetRequest(
    `global/${workspaceID}/projects/${proejctID}/notes`,
    token,
    args
  );
  const ret = validateOGetNotes(obj);
  return ret;
};

const createNoteWithPreview = async (
  token: string,
  workspaceID: string,
  proejctID: string,
  args: ICreateNote
): Promise<OCreateNote> => {
  const res = await doAPIPostRequest(
    `global/${workspaceID}/projects/${proejctID}/note`,
    token,
    args
  );
  const ret = validateOCreateNote(res);
  return ret;
};

const deleteNote = async (
  token: string,
  workspaceID: string,
  proejctID: string,
  noteID: string
): Promise<ODeleteNote> => {
  const res = await doAPIDeleteRequest(
    `global/${workspaceID}/projects/${proejctID}/note/${noteID}`,
    token
  );
  const ret = validateODeleteNote(res);
  return ret;
};

const getPdfUrl = async (
  token: string,
  workspaceID: string,
  projectID: string,
  args: IGetNotePDF
) => {
  return validateOGetNotePDF(
    await doAPIPostRequest(
      `global/${workspaceID}/projects/${projectID}/pdf`,
      token,
      args
    )
  );
};

const createNoteTags = async (
  token: string,
  noteId: string,
  args: ICreateNoteTags
) => {
  const res = await doAPIPostRequest(`global/note/${noteId}/tags`, token, args);
  const ret = validateOCreateNoteTags(res);
  return ret;
};

const deleteNoteTag = async (
  token: string,
  noteId: string,
  args: IDeleteNoteTag
) => {
  const res = await doAPIDeleteRequest(
    `global/note/${noteId}/tag`,
    token,
    args
  );
  return validateODeleteNoteTag(res);
};

const mergeNotes = async (
  token: string,
  workspaceId: string,
  projectId: string,
  args: IMergeNotes
): Promise<OMergeNotes> => {
  const ret = await doAPIPostRequest(
    `/global/${workspaceId}/projects/${projectId}/merge`,
    token,
    args
  );
  const obj = validateOMergeNotes(ret);
  return obj;
};

const getNoteCerti = async (
  token: string,
  workspaceId: string,
  projectId: string,
  args: IGetNoteCertificationPDF
): Promise<OGetNoteCertificationPDF> => {
  const ret = await doAPIPostRequest(
    `/basalt/${workspaceId}/projects/${projectId}/note/certification`,
    token,
    args
  );
  const obj = validateOGetNoteCertificationPDF(ret);
  return obj;
};
/**
 * 원본 파일의 CID 요청
 * @param token
 * @param workspaceId
 * @param projectId
 * @param args
 * @requires ProjectAuthType.ADMIN
 */
const getOriginNote = async (
  token: string,
  workspaceId: string,
  projectId: string,
  args: IGetNoteOrigin
): Promise<OGetNoteOrigin> => {
  const ret = await doAPIPostRequest(
    `/global/${workspaceId}/projects/${projectId}/download`,
    token,
    args
  );
  const obj = validateOGetNoteOrigin(ret);
  return obj;
};
/**
 * 원본 파일들 다운로드 요청
 * @param token
 * @param workspaceId
 * @param projectId
 * @param args
 * @requires ProjectAuthType.ADMIN
 */
const getOriginNoteList = async (
  token: string,
  workspaceId: string,
  projectId: string,
  args: IGetNoteOriginList
): Promise<OGetNoteOriginList> => {
  const ret = await doAPIPostRequest(
    `/global/${workspaceId}/projects/${projectId}/download/notes`,
    token,
    args
  );
  const obj = validateOGetNoteOriginList(ret);
  return obj;
};

export default {
  getNotes,
  deleteNote,
  getPdfUrl,
  createNoteWithPreview,
  createNoteTags,
  deleteNoteTag,
  mergeNotes,
  getNoteCerti,
  getOriginNote,
  getOriginNoteList,
};
