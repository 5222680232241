import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  WorkspaceAction,
  WorkspaceActionKind,
  WorkspaceErrorType,
  WorkspaceState,
  WorkspaceStateMachine,
  WorkspaceStateMachineType,
  WorkspaceStateStatus,
} from "../../store/reducers/workspace";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import Services from "@basalt-react-commons/services";
import {
  getWorkspaceRoleLevel,
  WorkspaceRoleType,
} from "@basalt-commons/global-api/object/user_workspace_map";
import { WorkspaceWithTeamObject } from "@basalt-commons/global-api/object/workspace";
import { TokenStateStatus } from "../../store/reducers/token";
import moment from "moment";

import {
  IDeleteJoinedUserTeam,
  IDeleteJoinedUserWorkspace,
} from "@basalt-commons/global-api/request/workspace";
import { UserLogType } from "@basalt-commons/api/object/user_log";
import { TaskObject } from "@basalt-commons/api/object/task";
import { IPaypleDomesticPayment } from "@basalt-commons/global-api/request/payment";

const alignWorkspaceWithCreateTime = (
  Workspaces: WorkspaceWithTeamObject[]
): WorkspaceWithTeamObject[] => {
  const sort = Workspaces.sort((a, b) => {
    let aDate = moment(a.createdAt).toDate().getTime();
    let bDate = moment(b.createdAt).toDate().getTime();
    return aDate > bDate ? -1 : 1;
  });
  return sort;
};

const {
  WorkspaceService,
  TimelineService,
  NDAContractService,
  RuleContractService,
  TaskService,
  IPFSService,
  GlobalPaymentService,
} = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.workspace,
    token: state.token,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type WorkspaceContainerProps = PropsFromRedux & {
  stateMachine: WorkspaceStateMachineType;
};

class WorkspaceContainer extends ReduxStateComponent3<WorkspaceContainerProps> {
  static defaultProps = {
    stateMachine: WorkspaceStateMachine,
  };
  constructor(props: WorkspaceContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: WorkspaceState,
    action: WorkspaceAction
  ): Promise<WorkspaceState> {
    const { token } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "WorkspaceContainer::onAction",
        msg: "not goono login",
      });

    const userToken = token.state.token;
    const userName = token.state.name;
    const userProfileCid = token.state.profile_cid;
    const userId = token.state.id;
    const time_zone = token.state.time_zone
      ? token.state.time_zone
      : moment.tz.guess(true);
    switch (action.kind) {
      case WorkspaceActionKind.TRY_GET_WORKSPACE: {
        const { get_all } = action;
        const myWorkspaces = await this.guardAwait(() =>
          WorkspaceService.getWorkspaces(userToken)
        );
        // default align - 최신순
        const mySortedWorkspaces = alignWorkspaceWithCreateTime(myWorkspaces);
        if (get_all === true) {
          const otherWorkspaces = await this.guardAwait(() =>
            WorkspaceService.getAllWorkspaces(userToken)
          );
          const othersSortedWorkspaces =
            alignWorkspaceWithCreateTime(otherWorkspaces);
          return {
            ...prevState,
            otherWorkspaces: othersSortedWorkspaces,
            workspaces: mySortedWorkspaces,
          };
        }
        return {
          ...prevState,
          workspaces: mySortedWorkspaces,
        };
      }
      case WorkspaceActionKind.TRY_SELECT_WORKSPACE: {
        const { name } = action;
        const selectAuthWorkspace = prevState.workspaces.find(
          (w) => w.name === name
        );
        if (selectAuthWorkspace === undefined) {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_SELECT_WORKSPACE,
            msg: `can not find ${name} workspace`,
          });
        }
        const targetWSId = selectAuthWorkspace.id;
        const retPlan = await this.guardAwait(() =>
          WorkspaceService.getWorkspacePlan(userToken, targetWSId)
        );
        return {
          ...prevState,
          status: WorkspaceStateStatus.SUCCESS,
          selectAuthWorkspace,
          workspaceWithPlan: retPlan,
        };
      }
      case WorkspaceActionKind.TRY_CREATE_WORKSPACE: {
        const { name, allow_search } = action;
        const newWorkspace = await this.guardAwait(() =>
          WorkspaceService.createWorkspace(userToken, {
            time_zone,
            name,
            allow_search,
          })
        );
        // Record Create Workspace Log
        this.scheduleBackgroundTask(async () => {
          await TimelineService.createTimeline(userToken, newWorkspace.id, {
            type: UserLogType.CREATE_WORKSPACE,
            UserId: userId,
            userName: userName,
            profile_cid: userProfileCid,
            WorkspaceId: newWorkspace.id,
            workspaceName: newWorkspace.name,
          });
          return false;
        });

        const workspaces = prevState.workspaces.concat(newWorkspace);
        return {
          ...prevState,
          workspaces,
        };
      }
      case WorkspaceActionKind.TRY_JOIN_WORKSPACE: {
        const { shareToken } = action;
        await this.guardAwait(() =>
          WorkspaceService.joinWorkspace(userToken, { shareToken })
        );
        return prevState;
      }
      case WorkspaceActionKind.TRY_SIGN_OUT_WORKSPACE: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_SIGN_OUT_WORKSPACE,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          userAuth.roleType === WorkspaceRoleType.OWNER
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_SIGN_OUT_WORKSPACE,
            msg: WorkspaceErrorType.WARN_OWNER_SIGN_OUT,
          });

        await this.guardAwait(() =>
          WorkspaceService.signOutWorkspace(userToken, selectAuthWorkspace.id)
        );

        const newWorkspaces = prevState.workspaces.filter(
          (ws) => ws.id !== selectAuthWorkspace.id
        );

        return {
          ...prevState,
          status: WorkspaceStateStatus.INIT,
          workspaces: newWorkspaces,
        };
      }
      case WorkspaceActionKind.TRY_CLEAR_WORKSPACE: {
        return {
          status: WorkspaceStateStatus.INIT,
          workspaces: [],
          otherWorkspaces: undefined,
        };
      }
      case WorkspaceActionKind.TRY_UPDATE_WORKSPACE: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_UPDATE_WORKSPACE,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.ADMIN)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_UPDATE_WORKSPACE,
            msg: WorkspaceErrorType.ONLY_ADMIN_OR_OWNER,
          });

        const { name, workspace_cid, workspace_extension, allow_search } =
          action;
        const ret = await this.guardAwait(() =>
          WorkspaceService.updateWorkspace(userToken, selectAuthWorkspace.id, {
            name,
            workspace_cid,
            workspace_extension,
            allow_search,
          })
        );

        const newWorkspaces = prevState.workspaces.map((ws) =>
          ws.id !== ret.id ? ws : ret
        );

        return {
          ...prevState,
          workspaces: newWorkspaces,
          selectAuthWorkspace: ret,
        };
      }
      case WorkspaceActionKind.TRY_SHARE_WORKSPACE_EMAILS: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_SHARE_WORKSPACE_EMAILS,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.MEMBER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_SHARE_WORKSPACE_EMAILS,
            msg: WorkspaceErrorType.ONLY_MEMBER_AND_ABOVE,
          });
        const { members } = action;
        await this.guardAwait(() =>
          WorkspaceService.shareWorkspaceWithMembers(
            userToken,
            selectAuthWorkspace.id,
            { members }
          )
        );

        const tokenLogs = await this.guardAwait(() =>
          WorkspaceService.getWorkspaceShareEmailList(
            userToken,
            selectAuthWorkspace.id
          )
        );

        return { ...prevState, logs: tokenLogs };
      }
      case WorkspaceActionKind.TRY_UPDATE_MEMBER_WORKSPACE: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_UPDATE_MEMBER_WORKSPACE,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.ADMIN)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_UPDATE_MEMBER_WORKSPACE,
            msg: WorkspaceErrorType.ONLY_ADMIN_OR_OWNER,
          });

        const { member } = action;
        if (member.userId === userId) {
          // cannot change self role
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_UPDATE_MEMBER_WORKSPACE,
            msg: WorkspaceErrorType.INVALID_INPUT,
          });
        }
        if (
          selectAuthWorkspace.Users.find((u) => u.id === member.userId) ===
          undefined
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_UPDATE_MEMBER_WORKSPACE,
            msg: WorkspaceErrorType.INVALID_INPUT,
          });
        // cannot change member's role which is higer level
        if (
          getWorkspaceRoleLevel(member.role_type) >=
          getWorkspaceRoleLevel(userAuth.roleType)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_UPDATE_MEMBER_WORKSPACE,
            msg: WorkspaceErrorType.NOT_AUTHORIZED,
          });
        const newSelectedWorkspace = await this.guardAwait(() =>
          WorkspaceService.updateMemberAuth(
            userToken,
            selectAuthWorkspace.id,
            member
          )
        );
        const newWorkspaces = prevState.workspaces.map((ws) =>
          ws.id !== newSelectedWorkspace.id
            ? ws
            : { ...ws, ...newSelectedWorkspace }
        );
        return {
          ...prevState,
          selectAuthWorkspace: newSelectedWorkspace,
          workspaces: newWorkspaces,
        };
      }
      case WorkspaceActionKind.TRY_DELETE_USER_IN_WORKSPACE: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_DELETE_USER_IN_WORKSPACE,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.ADMIN)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_DELETE_USER_IN_WORKSPACE,
            msg: WorkspaceErrorType.ONLY_ADMIN_OR_OWNER,
          });

        const { targetUserId } = action;
        const args: IDeleteJoinedUserWorkspace = {
          UserId: targetUserId,
        };
        await this.guardAwait(() =>
          WorkspaceService.deleteJoinedUserWorkspace(
            userToken,
            selectAuthWorkspace.id,
            args
          )
        );

        let newWorkspace = selectAuthWorkspace;
        const newUsers = newWorkspace.Users.filter(
          (u) => u.id !== targetUserId
        );
        newWorkspace = { ...newWorkspace, Users: newUsers };

        const newWorkspaces = prevState.workspaces.map((ws) =>
          ws.id !== newWorkspace.id ? ws : newWorkspace
        );

        return {
          ...prevState,
          workspaces: newWorkspaces,
          selectAuthWorkspace: newWorkspace,
        };
      }
      case WorkspaceActionKind.TRY_CREATE_WORKSPACE_TEAM: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_CREATE_WORKSPACE_TEAM,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.ADMIN)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_CREATE_WORKSPACE_TEAM,
            msg: WorkspaceErrorType.ONLY_ADMIN_OR_OWNER,
          });

        const { name, members } = action;
        const newWorkspace = await this.guardAwait(() =>
          WorkspaceService.createWorkspaceTeam(
            userToken,
            selectAuthWorkspace.id,
            { name, members }
          )
        );

        const newWorkspaces = prevState.workspaces.map((ws) =>
          ws.id !== newWorkspace.id ? ws : newWorkspace
        );

        return {
          ...prevState,
          workspaces: newWorkspaces,
          selectAuthWorkspace: newWorkspace,
        };
      }
      case WorkspaceActionKind.TRY_UPDATE_WORKSPACE_TEAM: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_UPDATE_WORKSPACE_TEAM,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.ADMIN)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_UPDATE_WORKSPACE_TEAM,
            msg: WorkspaceErrorType.ONLY_ADMIN_OR_OWNER,
          });

        const { name, teamId, members } = action;
        const newWorkspace = await this.guardAwait(() =>
          WorkspaceService.updateWorkspaceTeam(
            userToken,
            selectAuthWorkspace.id,
            teamId,
            { name, members }
          )
        );

        const newWorkspaces = prevState.workspaces.map((ws) =>
          ws.id !== newWorkspace.id ? ws : newWorkspace
        );

        return {
          ...prevState,
          workspaces: newWorkspaces,
          selectAuthWorkspace: newWorkspace,
        };
      }
      case WorkspaceActionKind.TRY_DELETE_JOINED_USER_TEAM: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_DELETE_JOINED_USER_TEAM,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.ADMIN)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_DELETE_JOINED_USER_TEAM,
            msg: WorkspaceErrorType.ONLY_ADMIN_OR_OWNER,
          });

        const { teamId } = action;
        const args: IDeleteJoinedUserTeam = {
          UserId: action.userId,
        };
        await this.guardAwait(() =>
          WorkspaceService.deleteJoinedUserTeam(
            userToken,
            selectAuthWorkspace.id,
            teamId,
            args
          )
        );

        const newTeams = selectAuthWorkspace.Teams.map((team) =>
          team.id === teamId
            ? {
                ...team,
                Users: team.Users.filter((user) => user.id !== userId),
              }
            : team
        );
        const newWorkspace = { ...selectAuthWorkspace, Teams: newTeams };

        const newWorkspaces = prevState.workspaces.map((ws) =>
          ws.id !== newWorkspace.id ? ws : newWorkspace
        );

        return {
          ...prevState,
          workspaces: newWorkspaces,
          selectAuthWorkspace: newWorkspace,
        };
      }
      case WorkspaceActionKind.TRY_DELETE_WORKSPACE_TEAM: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_DELETE_WORKSPACE_TEAM,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.ADMIN)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_DELETE_WORKSPACE_TEAM,
            msg: WorkspaceErrorType.ONLY_ADMIN_OR_OWNER,
          });

        const { teamId } = action;

        await this.guardAwait(() =>
          WorkspaceService.deleteWorkspaceTeam(
            userToken,
            selectAuthWorkspace.id,
            teamId
          )
        );

        const newTeams = selectAuthWorkspace.Teams.filter(
          (team) => team.id !== teamId
        );
        const newWorkspace = { ...selectAuthWorkspace, Teams: newTeams };

        const newWorkspaces = prevState.workspaces.map((ws) =>
          ws.id !== newWorkspace.id ? ws : newWorkspace
        );

        return {
          ...prevState,
          workspaces: newWorkspaces,
          selectAuthWorkspace: newWorkspace,
        };
      }
      case WorkspaceActionKind.TRY_DELETE_WORKSPACE: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_DELETE_WORKSPACE,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_DELETE_WORKSPACE,
            msg: WorkspaceErrorType.ONLY_OWNER,
          });

        const { password } = action;
        await this.guardAwait(() =>
          WorkspaceService.deleteWorkspace(userToken, selectAuthWorkspace.id, {
            password,
          })
        );
        const newWorkspaces = prevState.workspaces.filter(
          (ws) => ws.id !== selectAuthWorkspace.id
        );
        return {
          ...prevState,
          status: WorkspaceStateStatus.INIT,
          workspaces: newWorkspaces,
        };
      }
      case WorkspaceActionKind.TRY_GET_WORKSPACE_SHARE_EMAILS: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_GET_WORKSPACE_SHARE_EMAILS,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.MEMBER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_GET_WORKSPACE_SHARE_EMAILS,
            msg: WorkspaceErrorType.ONLY_MEMBER_AND_ABOVE,
          });

        const tokenLogs = await this.guardAwait(() =>
          WorkspaceService.getWorkspaceShareEmailList(
            userToken,
            selectAuthWorkspace.id
          )
        );

        return { ...prevState, logs: tokenLogs };
      }
      case WorkspaceActionKind.TRY_KNOCK_WORKSPACE: {
        const { workspaceId } = action;
        await this.guardAwait(() =>
          WorkspaceService.knockWorkspace(userToken, workspaceId)
        );

        return prevState;
      }
      case WorkspaceActionKind.TRY_RESEND_WORKSPACE_SHARE_EMAIL: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_RESEND_WORKSPACE_SHARE_EMAIL,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.MEMBER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_RESEND_WORKSPACE_SHARE_EMAIL,
            msg: WorkspaceErrorType.ONLY_MEMBER_AND_ABOVE,
          });

        await this.guardAwait(() =>
          WorkspaceService.resendWorkspaceShareEmail(
            userToken,
            selectAuthWorkspace.id,
            action.args
          )
        );
        return { ...prevState };
      }
      case WorkspaceActionKind.TRY_UPDATE_WORKSPACE_SHARE_EMAIL_ROLE: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_UPDATE_WORKSPACE_SHARE_EMAIL_ROLE,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace, logs } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.ADMIN)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_UPDATE_WORKSPACE_SHARE_EMAIL_ROLE,
            msg: WorkspaceErrorType.ONLY_ADMIN_OR_OWNER,
          });

        if (logs?.find((log) => log.id === action.args.logId) === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_UPDATE_WORKSPACE_SHARE_EMAIL_ROLE,
            msg: WorkspaceErrorType.INVALID_INPUT,
          });

        const ret = await this.guardAwait(() =>
          WorkspaceService.updateWorkspaceRoleSharedEmail(
            userToken,
            selectAuthWorkspace.id,
            action.args
          )
        );

        const newLogs = logs?.map((log) => (log.id === ret.id ? ret : log));

        return { ...prevState, logs: newLogs };
      }
      case WorkspaceActionKind.TRY_DELETE_WORKSPACE_SHARE_EMAIL: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_DELETE_WORKSPACE_SHARE_EMAIL,
            msg: "state status invalid",
          });

        const { selectAuthWorkspace, logs } = prevState;
        const userAuth = selectAuthWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          getWorkspaceRoleLevel(userAuth.roleType) <
            getWorkspaceRoleLevel(WorkspaceRoleType.MEMBER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_DELETE_WORKSPACE_SHARE_EMAIL,
            msg: WorkspaceErrorType.ONLY_MEMBER_AND_ABOVE,
          });

        if (logs?.find((log) => log.id === action.args.logId) === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: WorkspaceActionKind.TRY_DELETE_WORKSPACE_SHARE_EMAIL,
            msg: WorkspaceErrorType.INVALID_INPUT,
          });

        const logId = await this.guardAwait(() =>
          WorkspaceService.deleteWorkspaceSharedEmail(
            userToken,
            selectAuthWorkspace.id,
            action.args
          )
        );

        const newLogs = logs.filter((log) => log.id !== logId);

        return { ...prevState, logs: newLogs };
      }
      case WorkspaceActionKind.TRY_GET_SIGNED_NDA_CONTRACT: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_GET_SIGNED_NDA_CONTRACT,
            msg: "invalid prevstate",
          });
        const userToken = token.state.token;
        const ret = await this.guardAwait(() =>
          NDAContractService.getSignedNdaContract(userToken)
        );

        return {
          ...prevState,
          myNdaContractList: ret.response.results,
        };
      }
      case WorkspaceActionKind.TRY_DOWNLOAD_ALL_MY_CONTRACT: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_DOWNLOAD_ALL_MY_CONTRACT,
            msg: "Invalid prev state",
          });
        const userToken = token.state.token;
        const { fileType, WorkspaceId } = action;
        // ZIP 파일 입니다.
        let contractsArchivedZip: TaskObject;
        let pendingId = "";

        if (fileType === "nda") {
          const ret = await this.guardAwait(() =>
            NDAContractService.getMyNDAContractAll(userToken, {
              WorkspaceId,
            })
          );
          contractsArchivedZip = ret.response;
          pendingId = ret.pending_tasks.zip;
        } else {
          const ret = await this.guardAwait(() =>
            RuleContractService.getMyRuleContractAll(userToken, {
              WorkspaceId,
            })
          );
          contractsArchivedZip = ret.response;
          pendingId = ret.pending_tasks.zip;
        }

        // 시간이 걸리는 작업이므로 Task처리를 필요로 한다.
        const taskResult = await this.guardAwait(() =>
          TaskService.waitforTaskComplete(contractsArchivedZip.id, userToken)
        );
        if (taskResult) {
          const zip = await this.guardAwait(() =>
            IPFSService.getIPFSZipUrl(
              userToken,
              pendingId,
              contractsArchivedZip.UserId
            )
          );
          const filePath = IPFSService.getIPFSUrl(zip) + ".zip";
          return { ...prevState, filePath };
        } else {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_DOWNLOAD_ALL_MY_CONTRACT,
            msg: "zip get failed",
          });
        }
      }
      case WorkspaceActionKind.TRY_DOWNGRADE_PLAN: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_DOWNGRADE_PLAN,
            msg: "invalid prevstate",
          });
        const userToken = token.state.token;
        const { selectAuthWorkspace } = prevState;

        const ret = await this.guardAwait(() =>
          WorkspaceService.downgradePlan(userToken, selectAuthWorkspace.id, {
            ...action,
          })
        );
        return {
          ...prevState,
          workspaceWithPlan: ret,
        };
      }
      case WorkspaceActionKind.TRY_CERT_DOMESTIC_PAYMENT: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_CERT_DOMESTIC_PAYMENT,
            msg: "invalid prevstate",
          });
        const userToken = token.state.token;
        const { selectAuthWorkspace } = prevState;
        const { payment_info, used_credit } = action;
        const args: IPaypleDomesticPayment = {
          ...payment_info,
          used_credit,
        };
        const ret = await this.guardAwait(() =>
          GlobalPaymentService.domesticPurchase(
            userToken,
            selectAuthWorkspace.id,
            args
          )
        );
        return {
          ...prevState,
          workspaceWithPlan: ret,
        };
      }
      case WorkspaceActionKind.TRY_PAYMENT_CREDIT: {
        if (prevState.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: WorkspaceActionKind.TRY_PAYMENT_CREDIT,
            msg: "invalid prevstate",
          });
        const userToken = token.state.token;
        const { selectAuthWorkspace } = prevState;
        const { args } = action;
        const ret = await this.guardAwait(() =>
          GlobalPaymentService.creditPurchase(
            userToken,
            selectAuthWorkspace.id,
            args
          )
        );
        return {
          ...prevState,
          workspaceWithPlan: ret,
        };
      }
    }
  }
}

export default connector(WorkspaceContainer);
