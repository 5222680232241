export default {
  ko: {
    workspace_settings: {
      save: "저장하기",
      header: "기본 설정",
      info_header: "워크스페이스 정보",
      public: "워크스페이스 공개",
      make_public: "전체 공개 워크스페이스로 전환",
      only_owner: "워크스페이스 소유자만 수정이 가능합니다",
      settings: "Settings",
      members: "멤버",
      person: "명",
      update_prefix: "Updated",
      update_postfix: "",
      settings_tab: {
        edit: "편집",
        cancel: "취소",
        name: "워크스페이스 이름",
        name_subheader: "워크스페이스 이름을 15자 이내로 입력하세요",
        default: "기본 설정",
        copy: "워크스페이스 URL 복사",
        no_img: "워크스페이스\n이미지가 없습니다.",
        workspace_url: "워크스페이스 url",
        rule_settings: "워크스페이스 룰 설정",
        pledge_title: "내부 보안 서약",
        upload_pledge: "보안 서약 업로드",
        public: "Open Workspace",
        no_rules: "워크스페이스 룰이 없습니다",
        new_img_upload: "새로운 이미지 업로드",
        img_upload: "이미지 업로드",
      },

      members_tab: {
        title: "멤버 및 팀",
        workspace_members: "워크스페이스 멤버",
        workspace_teams: "워크스페이스 팀",
        invite: "멤버 추가",
        search: "팀원 검색",
        no_teams: "멤버들을 팀으로 묶어서 한번에 관리할 수 있습니다.",
        account: "팀",
        role: "롤",
        create_team: "팀 만들기",
        edit: "수정하기",
        invite_wait: "초대 중",
        invite_expired: "유효기간 만료",
        resend_email: "초대 메일 다시 발송",
        resend_email_xs: "메일 재발송",
        see_more: "+MEMBER명 더보기",
        see_less: "접어 보기",
        delete_member_err: (folder_name: string) => {
          return `"${folder_name}"의 Owner 입니다. 해당 폴더의 Owner를 변경해주세요`;
        },
      },

      billing_tab: {
        title: "결제",
        promotion: {
          header: "프로모션 코드",
          register_title: "코드 등록",
          register_desc: "바솔트를 특정 기간 무료로 사용하실 수 있습니다.",
          input_placeholder: "프로모션 코드를 입력하세요",
          register_btn: "등록",
          success_register: "프로모션 코드가 등록 되었습니다!",
          fail_register: "유효하지 않은 코드입니다.",
        },
        info: "결제 정보",
        using_free_plan:
          "무료 플랜을 이용 중입니다. 업그레드하여 모든 기능을 사용해보세요.",
        team_plan_benefits: [
          "파일 업로드 무제한",
          " 블록체인 인증서 제공",
          " 워크스페이스 팀원 추가 불가능",
          " 외부 링크 생성 무제한",
          " NDA 첨부 무제한",
          " 링크 인사이트 및 그래프 제공",
          " 방문자 목록 제공",
        ],
        change_plan: "플랜 변경하기",
        current_plan: "현재 플랜",
        free_trial_ended: (days: number) => {
          return `(무료 체험 종료 D-${days})`;
        },
        terminate_payment: "결제 해지",
        cancel_plan: "플랜 해지",
        cancel_plan_desc: "해지하면 모든 프리미엄 기능을 이용할 수 없습니다.",
        payment_date: "결제 날짜",
        expiry_date: "만료 날짜",
        payment_amount: "결제 금액",
        payment_method: "결제 방법",
        registered_card: "등록된 카드",
        add_card: "카드 등록",
        no_card: "등록된 카드가 없습니다.",
        no_card_warning:
          "결제일 까지 등록하지 않으시면 자동으로 Plan이 해지됩니다.",
        card_subheader: "님이 추가하였습니다.",
        default_card: "기본 카드",
        set_default_card: "기본 카드로 설정하기",

        person: "인",

        content: " 내역",
        filter_month_format: "YY년 MM월",
        filter_date_format: "YYYY년 MM월 DD일",
        filter_date_format_xs: "YY년 MM월 DD일",
        payment_history_title: "결제 내역",
        date: "날짜",
        category: "항목",
        amount: "금액",
        remarks: "비고",
        receipt: "영수증",
        fetch_more_payments: (available_num: number) => {
          return `${available_num}개 항목 더 보기`;
        },
      },
    },
  },

  en: {
    workspace_settings: {
      save: "Save",
      header: "Default Setting",
      info_header: "Workspace Information",
      public: "Open Workspace",
      make_public: "Switch workspace to public",
      only_owner: "Only the workspace owner can modify it",
      settings: "Settings",
      members: "Members",
      update_prefix: "Updated",
      update_postfix: "",
      person: " members",
      settings_tab: {
        edit: "Edit",
        cancel: "Cancel",
        name: "Workspace name",
        name_subheader: "Please enter the workspace name within 15 characters",
        copy: "Copy your workspace URL",
        default: "Default settings",
        no_img: "No workspace\nimage.",
        workspace_url: "Workspace URL",
        rule_settings: "Workspace Rule Settings",
        pledge_title: "Internal Security Pledge",
        upload_pledge: "Upload Security Pledge",
        new_img_upload: "Upload new image",
        img_upload: "Upload image",
        public: "Open Workspace",
        no_rules: "There are no workspace rules",
      },

      members_tab: {
        title: "Members / Team",
        workspace_members: "Workspace Members",
        workspace_teams: "Workspace Teams",
        invite: "Invite members",
        search: "Search for members",
        account: "Team",
        role: "Role",
        create_team: "Create a Team",
        edit: "Modify",
        invite_wait: "Pending",
        invite_expired: "Invitation Expired",
        resend_email: "Send the Invitation again",
        resend_email_xs: "Send again",
        see_more: "See more members ",
        see_less: "Hide",
        no_teams: "Members can be grouped into teams and managed at once.",

        delete_member_err: (folder_name: string) => {
          return `Change the owner of the "${folder_name}"`;
        },
      },
      billing_tab: {
        title: "Billing",
        promotion: {
          header: "Promotion Code",
          register_title: "Register Code",
          register_desc:
            "You can use baSalt for free during the designated period",
          input_placeholder: "Enter the promotion code",
          register_btn: "Register",
          success_register: "The promotion code was successfully registered!",
          fail_register: "This is an invalid code.",
        },
        info: "Payment Information",
        using_free_plan:
          "You have free membership now. Use it without limitation by upgrading your plan.",
        team_plan_benefits: [
          "Unlimited file uploads",
          "Provide blockchain certificates",
          "Unable to add members to the workspace",
          "Unlimited external link creation",
          "Unlimited NDA attachments",
          "Provide link insights and graphs",
          "Provide a list of visitors",
        ],

        change_plan: "Change",
        current_plan: "Current Plan",
        free_trial_ended: (days: number) => {
          return `(Expiration of the free trial D-${days})`;
        },
        terminate_payment: "Cancel payment",
        cancel_plan: "Unsubscribe plan",
        cancel_plan_desc:
          "If you unsubscribe it, you won't use all premium functionalities.",
        payment_date: "Payment Date",
        expiry_date: "Expiration Date",
        payment_amount: "Payment Amount",
        payment_method: "Payment Method",
        registered_card: "Registered Credit Card",
        add_card: "Add Credit Card",
        no_card: "There is no registered credit card",
        no_card_warning:
          "If you don't register by the payment date, the plan will be automatically terminated",
        card_subheader: " added this",
        default_card: "Default Credit Card",
        set_default_card: "Set as Default",

        person: "member(s)",

        content: "",
        filter_month_format: "DD, MM, YYYY",
        filter_date_format: "ll",
        filter_date_format_xs: "ll",
        payment_history_title: "Payment History",
        date: "Date",
        category: "Category",
        amount: "Amount",
        remarks: "Remarks",
        receipt: "Receipt",
        fetch_more_payments: (available_num: number) => {
          return `${available_num} Categories`;
        },
      },
    },
  },
};
