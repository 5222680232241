import T from "@redwit-commons/utils/typecheck";
import {
  GoonoArrayResponse,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "../../api/response/common";
import { AlarmObject, AlarmObjectSchema } from "../object/alarm";

export type OGetAlarmList = GoonoArrayResponse<AlarmObject>;
export const OGetAlarmListSchema = mkArrayResultsSchema(AlarmObjectSchema);
export const validateOGetAlarmList =
  T.mkValidator<OGetAlarmList>(OGetAlarmListSchema);

export type OCheckAlarm = GoonoResponse<string>;
export const OCheckAlarmSchema = mkResponseSchema(T.string());
export const validateOCheckAlarm =
  T.mkValidator<OCheckAlarm>(OCheckAlarmSchema);

export type ODeleteAlarm = GoonoResponse<string>;
export const ODeleteAlarmSchema = mkResponseSchema(T.string());
export const validateODeleteAlarm =
  T.mkValidator<ODeleteAlarm>(ODeleteAlarmSchema);
