import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  LogStateMachineType,
  logStateMachine,
  LogAction,
  LogState,
  LogActionKind,
  LogStateStatus,
} from "../../store/reducers/log";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { TokenStateStatus } from "../../store/reducers/token";
import Services from "@basalt-react-commons/services";
import { WorkspaceStateStatus } from "../../store/reducers/workspace";
import moment from "moment-timezone";
import { UserLogObject } from "@basalt-commons/api/object/user_log";

export const alignLogsWithCreateTime = (
  logs: UserLogObject[]
): UserLogObject[] => {
  const sort = logs.sort((a, b) => {
    let aDate = moment(a.createdAt).toDate().getTime();
    let bDate = moment(b.createdAt).toDate().getTime();
    return aDate > bDate ? -1 : 1;
  });
  return sort;
};

const { TimelineService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.log,
    token: state.token,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LogContainerProps = PropsFromRedux & { stateMachine: LogStateMachineType };

class LogContainer extends ReduxStateComponent3<LogContainerProps> {
  static defaultProps = { stateMachine: logStateMachine };
  constructor(props: LogContainerProps) {
    super(props);
  }
  private logCreationMinInterval = 1; // second
  protected async onAction(
    prevState: LogState,
    action: LogAction
  ): Promise<LogState> {
    const { token, workspace } = this.props;
    if (action.kind === LogActionKind.TRY_CREATE_VIEW_LOG) {
      const { log } = action;
      await this.guardAwait(() =>
        TimelineService.createUnsignedTimeline({ ...log })
      );
      return prevState;
    }
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "LogContainer::onAction",
        msg: "user not signed in",
      });

    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
      return {
        status: LogStateStatus.INIT,
        logs: [],
        doc_logs: [],
        note_logs: [],
        activity_filter: {},
        activity_period_filter: {},
      };
    const userToken = token.state.token;
    const workspaceId = workspace.state.selectAuthWorkspace.id;

    switch (action.kind) {
      case LogActionKind.TRY_CREATE: {
        const { log } = action;
        // 같은 타입의 logging은 1초 이내 생성 막아둠.
        if (
          prevState.logs.find(
            (l) =>
              l.type === log.type &&
              moment(l.createdAt).isAfter(
                moment().subtract(this.logCreationMinInterval, "second")
              )
          )
        ) {
          return prevState;
        }
        await this.guardAwait(() =>
          TimelineService.createTimeline(userToken, workspaceId, { ...log })
        );
        const ret = await this.guardAwait(() =>
          TimelineService.getAllTimeline(userToken, workspaceId, {})
        );

        const logs = alignLogsWithCreateTime(ret.response.results);
        return {
          ...prevState,
          logs,
        };
      }
      case LogActionKind.TRY_SET_ACTIVITY_FILTER: {
        const { activity_filter } = action;
        const ret = await this.guardAwait(() =>
          TimelineService.getAllTimeline(userToken, workspaceId, {
            ...activity_filter,
            ...prevState.activity_period_filter,
            fetchSize: 30,
          })
        );
        const logs = alignLogsWithCreateTime(ret.response.results);
        return {
          ...prevState,
          logs,
          activity_filter: {
            type: activity_filter.type,
            userId: activity_filter.userId,
          },
          no_more_logs: false,
        };
      }
      case LogActionKind.TRY_GET: {
        const { search_params } = action;
        const ret = await this.guardAwait(() =>
          TimelineService.getAllTimeline(userToken, workspaceId, {
            ...search_params,
            ...prevState.activity_filter,
          })
        );
        const logs = alignLogsWithCreateTime(ret.response.results);
        return {
          ...prevState,
          logs,
          activity_period_filter: {
            afterAt: search_params.afterAt,
            beforeAt: search_params.beforeAt,
          },
        };
      }
      case LogActionKind.TRY_GET_MORE_ACTIVITIES: {
        const search_args = {
          ...prevState.activity_period_filter,
          ...prevState.activity_filter,
          beforeAt: prevState.logs[prevState.logs.length - 1].createdAt,
          fetchSize: 11,
        };
        const ret = await this.guardAwait(() =>
          TimelineService.getAllTimeline(userToken, workspaceId, search_args)
        );
        const fetchedLogs = alignLogsWithCreateTime(ret.response.results);
        const nextPageLogs = fetchedLogs.slice(0, -1);
        if (nextPageLogs.length < 1) {
          return { ...prevState, no_more_logs: true };
        }
        if (fetchedLogs.length < 11) {
          return {
            ...prevState,
            logs: [...prevState.logs, ...nextPageLogs],
            no_more_logs: true,
          };
        }
        return {
          ...prevState,
          logs: [...prevState.logs, ...nextPageLogs],
        };
      }
      case LogActionKind.TRY_GET_DOC_LOG: {
        const { noteId, search_params } = action;
        const ret = await this.guardAwait(() =>
          TimelineService.getAllNoteDocTimeline(userToken, workspaceId, {
            noteId,
            ...search_params,
          })
        );
        const logs = alignLogsWithCreateTime(ret.response.results);
        return { ...prevState, doc_logs: logs };
      }
      case LogActionKind.TRY_GET_NOTE_LOG: {
        const { noteId, search_params } = action;
        const ret = await this.guardAwait(() =>
          TimelineService.getNoteTimeline(userToken, workspaceId, {
            noteId,
            ...search_params,
          })
        );
        const logs = alignLogsWithCreateTime(ret.response.results);
        return { ...prevState, note_logs: logs };
      }
    }
  }
}

export default connector(LogContainer);
