import { doAPIGetRequest, doAPIPostRequest } from "./api";
import { ICreateUserLog } from "@basalt-commons/api/request/user_log";
import {
  IGetAllTimeLine,
  IGetNoteTimeLine,
  IGetOneTypeTimeLine,
} from "@basalt-commons/global-api/request/timeline";
import {
  OGetUserLogs,
  validateOGetUserLogs,
} from "@basalt-commons/api/response/user_log";

const createTimeline = async (
  token: string,
  workspaceId: string,
  args: ICreateUserLog
) => {
  await doAPIPostRequest(`timeline/${workspaceId}/create`, token, args);
  return;
};

const createUnsignedTimeline = async (args: ICreateUserLog) => {
  await doAPIPostRequest(`timeline/create`, undefined, args);
  return;
};

const getAllTimeline = async (
  token: string,
  workspaceId: string,
  args: IGetAllTimeLine
): Promise<OGetUserLogs> => {
  const res = await doAPIGetRequest(`timeline/${workspaceId}`, token, args);
  return validateOGetUserLogs(res);
};

const getSpecificTypeTimeline = async (
  token: string,
  workspaceId: string,
  args: IGetAllTimeLine
): Promise<OGetUserLogs> => {
  const res = await doAPIGetRequest(
    `/timeline/${workspaceId}/type`,
    token,
    args
  );
  return validateOGetUserLogs(res);
};

const getNoteTimeline = async (
  token: string,
  workspaceId: string,
  args: IGetNoteTimeLine
): Promise<OGetUserLogs> => {
  const res = await doAPIGetRequest(
    `/timeline/${workspaceId}/note`,
    token,
    args
  );
  return validateOGetUserLogs(res);
};

const getDocTimeline = async (
  token: string,
  DocumentId: string,
  args: IGetOneTypeTimeLine
): Promise<OGetUserLogs> => {
  const res = await doAPIGetRequest(
    `timeline/document/${DocumentId}/type`,
    token,
    args
  );
  return validateOGetUserLogs(res);
};

const getAllNoteDocTimeline = async (
  token: string,
  workspaceId: string,
  args: IGetNoteTimeLine
): Promise<OGetUserLogs> => {
  const res = await doAPIGetRequest(
    `/timeline/${workspaceId}/note/document`,
    token,
    args
  );
  return validateOGetUserLogs(res);
};

export default {
  createTimeline,
  getAllTimeline,
  getSpecificTypeTimeline,
  getNoteTimeline,
  getDocTimeline,
  getAllNoteDocTimeline,
  createUnsignedTimeline,
};
