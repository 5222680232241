import React, { useState, useEffect, useMemo } from "react";
import { Box, BoxProps, makeStyles } from "@material-ui/core";
import { theme } from "@theme";
import clsx from "clsx";
import { throttle } from "lodash";

const useStyles = makeStyles((MuiTheme) => ({
  fixed_title: {
    position: "sticky",
    ...theme.fixed_title,
    transition: ".25s",
    [MuiTheme.breakpoints.down(theme.breakSmall)]: {
      top: 48,
    },
  },
  fixed_title_shadow: {
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    backgroundColor: theme.white,
    zIndex: -1,
    transition: ".25s",

    [MuiTheme.breakpoints.down(theme.breakSmall)]: {
      top: 48,
    },
  },
  shaodw_on: {
    ...theme.shadow_1,
  },
}));

type FixedTitleProps = { bgHeight: number; xsBgHeight: number } & BoxProps;

function FixedTitle({ bgHeight, xsBgHeight, children }: FixedTitleProps) {
  const classes = useStyles();
  const [isShadowOn, setIsShadowOn] = useState(false);
  const handleTitleShadow = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY >= 100 && !isShadowOn) {
          setIsShadowOn(true);
          return;
        }
        if (window.scrollY < 100 && isShadowOn) {
          setIsShadowOn(false);
          return;
        }
      }, 300),
    [isShadowOn]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleTitleShadow);

    return () => {
      window.removeEventListener("scroll", handleTitleShadow);
    };
  }, [handleTitleShadow]);

  return (
    <Box className={classes.fixed_title}>
      <Box
        className={clsx(
          classes.fixed_title_shadow,
          isShadowOn ? classes.shaodw_on : undefined
        )}
        style={{
          height: window.innerWidth < 600 ? xsBgHeight : bgHeight,
        }}
      ></Box>
      {children}
    </Box>
  );
}

export default FixedTitle;
