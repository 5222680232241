export default {
  ko: {
    commentItem: {
      just_now: "방금 전",
      minute_before: "분 전",
      hour_before: "시간 전",
      pin_comment: "님이 고정한 댓글",
    },
  },
  en: {
    commentItem: {
      just_now: "Just now",
      minute_before: "minutes before",
      hour_before: "hours before",
      pin_comment: "pinned",
    },
  },
};
