import { ProjectAuthType } from "../../api/object/user_project_map";

export const getAuthType = (auth?: ProjectAuthType) => {
  switch (auth) {
    case ProjectAuthType.OWNER:
      return "Owner";
    case ProjectAuthType.ADMIN:
      return "Admin";
    case ProjectAuthType.WRITE:
    case ProjectAuthType.RW:
      return "Write";
    case ProjectAuthType.READONLY:
      return "Read only";
    default:
      return "Read only";
  }
};

export const getProjectAuthLevel = (role?: ProjectAuthType) => {
  switch (role) {
    case ProjectAuthType.OWNER:
      return 3;
    case ProjectAuthType.ADMIN:
      return 2;
    case ProjectAuthType.WRITE:
    case ProjectAuthType.RW:
      return 1;
    case ProjectAuthType.READONLY:
      return 0;
    default:
      return -1;
  }
};
