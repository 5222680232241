import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { routes_landing, routes_service } from "./path";
import { ProvideAuth, PrivateRoute, LandingRoute, RootSwitch } from "./auth";
import SnackBars from "./snackbar";
import Modals from "./new_modal";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import translate from "../utils/translate";
import { TokenStateStatus } from "../store/reducers/token";
import { UserLogType } from "@basalt-commons/api/object/user_log";
import { doPersistAction, PersistActionKind } from "../store/reducers/persist";
import moment from "moment";
import Services from "@basalt-react-commons/services";
import ScreenLoadingView from "../components/pure/view/ScreenLoadingView";
import { BaseGrid } from "@src/utils/templates/BaseGrid";
import {
  BaseGridDefaultState,
  BaseGridWindowSizeState,
} from "@src/utils/templates/BaseGrid";
import {
  unstable_createMuiStrictModeTheme,
  ThemeProvider,
} from "@material-ui/core";

const customTheme = unstable_createMuiStrictModeTheme();

const { UserLogService } = Services;

const mapStateToProps = (state: RootState) => {
  return {
    persist: state.persist,
    token: state.token,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type RouterProps = PropsFromRedux;
type RouterState = BaseGridWindowSizeState & {};

class Root extends React.Component<RouterProps, RouterState> {
  constructor(props: RouterProps) {
    super(props);
    this.state = {
      ...BaseGridDefaultState,
    };
  }

  getUserAgent() {
    const userAgent = window.navigator.userAgent;

    const isMobile =
      userAgent.indexOf("Mobile") ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
        ? true
        : false;
    const isChrome = userAgent.indexOf("Chrome");
    const isSamsung = userAgent.indexOf("SamsungBrowser");
    const isWhale = userAgent.indexOf("Whale");
    const isIE = userAgent.indexOf("Trident");
    const isEdge = userAgent.indexOf("Edge");
    const isWindows = userAgent.indexOf("Windows");
    const isSafari = userAgent.indexOf("Safari");
    const isFireFox = userAgent.indexOf("Firefox");
    const isOpera = userAgent.indexOf("Opera");
    let device = "";
    if (isWhale > -1) {
      device = "Whale";
    } else if (isChrome > -1) {
      device = "Chrome";
    } else if (isSafari > 1) {
      device = "Safari";
    } else if (isFireFox > -1) {
      device = "Firefox";
    } else if (isIE > -1) {
      device = "IE";
    } else if (isSamsung > -1) {
      device = "Samsung Browser";
    } else if (isEdge > -1) {
      device = "Edge";
    } else if (isWindows > -1) {
      device = "Windows";
    } else if (isOpera > -1) {
      device = "Opera";
    }

    if (isMobile === true) device = device + "(Mobile)";
    return device;
  }

  async createVisitLog() {
    if (this.props.token.state.status === TokenStateStatus.SUCCESS) {
      const userId = this.props.token.state.id;
      const userToken = this.props.token.state.token;
      const now = moment();
      if (this.props.persist.state.visitLogTime !== undefined) {
        const prevTime = this.props.persist.state.visitLogTime;
        if (moment(prevTime).isBefore(now.add(10, "minutes"))) {
          return;
        }
      }
      try {
        await UserLogService.createUserLog(userToken, {
          type: UserLogType.VISIT,
          UserId: userId,
          platformOS: "web",
          device: this.getUserAgent(),
        });
        doPersistAction(this.props.dispatch, {
          kind: PersistActionKind.UPDATE_VISIT_TIME,
          time: now.toISOString(),
        });
      } catch (err) {
        console.log(err, "failed create log");
      }
    }
  }

  async componentDidMount() {
    if (this.props.persist.state.lang !== translate.getLanguage()) {
      doPersistAction(this.props.dispatch, {
        kind: PersistActionKind.TRY_CHANGE_LANG,
        lang: this.props.persist.state.lang,
      });
      this.forceUpdate();
      return;
    }
    await this.createVisitLog();
  }

  async componentDidUpdate(prevProps: RouterProps) {
    if (prevProps.persist.state.lang !== this.props.persist.state.lang) {
      this.forceUpdate();
      return;
    }
    await this.createVisitLog();
  }

  render() {
    return (
      <ProvideAuth>
        <BrowserRouter>
          <ThemeProvider theme={customTheme}>
            <Suspense fallback={<ScreenLoadingView />}>
              <RootSwitch>
                {routes_landing.map((route, id) => {
                  return <LandingRoute {...route} key={"l" + id} />;
                })}
                <BaseGrid
                  onChangeWindow={(w, h) => {
                    this.setState({ WindowHeight: h, WindowWidth: w });
                  }}
                >
                  {routes_service.map((route, id) => {
                    return <PrivateRoute {...route} key={"s" + id} />;
                  })}
                </BaseGrid>
              </RootSwitch>
              <Modals />
              <SnackBars />
            </Suspense>
          </ThemeProvider>
        </BrowserRouter>
      </ProvideAuth>
    );
  }
}

export default connector(Root);
