import React from "react";
import styles from "./styles/AlarmItem";
import { withStyles, WithStyles } from "@material-ui/styles";
import { Grid, MenuItem, IconButton, Button } from "@material-ui/core";
import SpoqaHansTypography from "@SpoqaHansTypography";
import { CancelRounded } from "@material-ui/icons";
import {
  AlarmObject,
  AlarmType,
} from "@basalt-commons/global-api/object/alarm";
import translate from "../../../utils/translate";
import { theme } from "@theme";
import moment from "moment-timezone";
import { ProjectAuthType } from "@basalt-commons/api/object/user_project_map";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";

export type AlarmItemEvent = {
  onDeleteAlarm?: (alarm: AlarmObject) => void;
  onCheckAlarm?: (alarm: AlarmObject) => void;
};

type AlarmItemProps = {
  alarm: AlarmObject;
  timezone?: string;
  borderBottom?: boolean;
} & WithStyles<typeof styles> &
  AlarmItemEvent;

type AlarmItemState = {
  hover: boolean;
};

class AlarmItem extends React.PureComponent<AlarmItemProps, AlarmItemState> {
  constructor(props: AlarmItemProps) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  getTitle(type: AlarmType, name?: string, folderName?: string) {
    switch (type) {
      case AlarmType.MENTION: {
        return (
          <>
            <strong>{name}</strong>
            {translate.service_header.notifications.mention.title}
          </>
        );
      }
      case AlarmType.NEW_RULE: {
        return translate.service_header.notifications.new_rule.title;
      }
      case AlarmType.INVTIE_PROJECT: {
        return (
          <>
            <strong>{name}</strong>
            {translate.service_header.notifications.invite_folder.title1}
            <strong>{folderName}</strong>
            {translate.service_header.notifications.invite_folder.title2}
          </>
        );
      }
    }
  }

  getBackgroundColor(alarm: AlarmObject) {
    if (alarm.isCheck === true) {
      if (this.state.hover === true) return theme.gray_0;
      else return theme.white;
    } else {
      if (this.state.hover === true) return theme.indigo_1;
      else return theme.indigo_0;
    }
  }

  extractMention(text?: string) {
    const reg1 = text?.replaceAll("{{", "@");
    const reg2 = reg1?.substring(0, reg1.lastIndexOf("}}") + 2);
    const reg3 = reg2?.replaceAll("}}", " ");
    return reg3;
  }

  excludeMention(text?: string) {
    const reg1 = text?.replaceAll("{{", "@");
    const reg2 = reg1?.replaceAll("}}", "/");
    const reg3 = reg2?.substring(reg2.lastIndexOf("/") + 1);
    return reg3;
  }

  getAuthType(authtype: ProjectAuthType) {
    switch (authtype) {
      case ProjectAuthType.ADMIN:
        return "Admin";
      case ProjectAuthType.WRITE:
        return "Write";
      case ProjectAuthType.READONLY:
        return "Read Only";
      case ProjectAuthType.OWNER:
        return "Owner";
      default:
        return "Read Only";
    }
  }

  getBody(alarm: AlarmObject) {
    const { classes } = this.props;
    switch (alarm.type) {
      case AlarmType.MENTION: {
        return (
          <SpoqaHansTypography className={classes.bodyText} noWrap>
            <strong style={{ marginRight: 14 }}>
              {this.extractMention(alarm.mention_text)}{" "}
            </strong>
            {this.excludeMention(alarm.mention_text)}
          </SpoqaHansTypography>
        );
      }
      case AlarmType.NEW_RULE: {
        return (
          <SpoqaHansTypography className={classes.bodyText} noWrap>
            {translate.service_header.notifications.new_rule.add}
            <strong style={{ marginLeft: 14 }}>{alarm.User.name}</strong>
          </SpoqaHansTypography>
        );
      }
      case AlarmType.INVTIE_PROJECT: {
        if (alarm.authType === undefined) return <></>;
        return (
          <SpoqaHansTypography className={classes.bodyText} noWrap>
            {translate.service_header.notifications.invite_folder.role}
            <strong style={{ marginLeft: 14 }}>
              {this.getAuthType(alarm.authType)}
            </strong>
          </SpoqaHansTypography>
        );
      }
    }
  }

  render() {
    const {
      classes,
      alarm,
      timezone,
      onDeleteAlarm,
      onCheckAlarm,
      borderBottom,
    } = this.props;
    const time_zone = timezone === undefined ? moment.tz.guess() : timezone;
    return (
      <MenuItem style={{ padding: 0 }}>
        <Grid
          onMouseEnter={() => {
            this.setState({ hover: true });
          }}
          onMouseLeave={() => {
            this.setState({ hover: false });
          }}
          onClick={() => {
            if (onCheckAlarm !== undefined) onCheckAlarm(alarm);
          }}
          style={{
            backgroundColor: this.getBackgroundColor(alarm),
            borderBottom:
              borderBottom === false ? undefined : `1px solid #dee2e6`,
          }}
          container
          item
          xs={12}
          className={classes.alarmContainer}
        >
          <Grid
            container
            item
            xs={12}
            direction="row"
            style={{ width: "100%" }}
          >
            <div className={classes.profileImageContainer}>
              {/** profile image */}
              <img
                src={getIPFSUrl(alarm.User.profile_cid)}
                className={classes.profileImage}
              />
            </div>

            <Grid container item xs={12} className={classes.alarmBodyContainer}>
              {/** 프로필과 타이틀 */}
              <Grid
                container
                item
                xs={12}
                direction="row"
                style={{ width: "100%" }}
              >
                <Grid container direction="row" item style={{ flex: 1 }}>
                  <SpoqaHansTypography className={classes.titleText}>
                    {this.getTitle(
                      alarm.type,
                      alarm.User.name,
                      alarm.folder_name
                    )}
                  </SpoqaHansTypography>
                </Grid>
                {onDeleteAlarm !== undefined && (
                  <IconButton
                    onClick={(evt) => {
                      evt.stopPropagation();
                      onDeleteAlarm(alarm);
                    }}
                    style={{ width: 36, height: 36, marginLeft: 0 }}
                  >
                    <CancelRounded className={classes.closeIcon} />
                  </IconButton>
                )}
              </Grid>

              {/* 알람 일자와 정보 */}
              <Grid
                container
                item
                xs={12}
                direction="row"
                style={{ maxWidth: "100%", marginTop: 5 }}
              >
                <SpoqaHansTypography
                  className={classes.alarmSubText}
                  style={{ marginRight: 20 }}
                >
                  {moment(alarm.createdAt)
                    .tz(time_zone)
                    .format("YYYY.MM.DD HH:mm:ss")}
                </SpoqaHansTypography>
                {alarm.type === AlarmType.MENTION && (
                  <SpoqaHansTypography className={classes.alarmSubText}>
                    {alarm.file_name}
                  </SpoqaHansTypography>
                )}
              </Grid>

              {/* 기타 정보 */}
              {alarm.type === AlarmType.MENTION &&
                alarm.folder_name !== undefined && (
                  <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    style={{ width: "100%", marginTop: 4 }}
                  >
                    <SpoqaHansTypography className={classes.folderName}>
                      {alarm.folder_name}
                    </SpoqaHansTypography>
                  </Grid>
                )}

              {/* BODY */}
              <Grid
                container
                item
                xs={12}
                direction="row"
                style={{ width: "100%", marginTop: 12 }}
              >
                {this.getBody(alarm)}
              </Grid>

              {/* Button */}
              {onCheckAlarm !== undefined && (
                <Grid container item xs={12}>
                  <Button
                    onClick={() => {
                      onCheckAlarm(alarm);
                    }}
                    className={classes.confirmButtn}
                  >
                    <SpoqaHansTypography
                      fontWeight="bold"
                      className={classes.confirmText}
                    >
                      {translate.modal.term.check}
                    </SpoqaHansTypography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MenuItem>
    );
  }
}

export default withStyles(styles)(AlarmItem);
