import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { NdaObjectWithContract } from "@basalt-commons/global-api/object/nda";
import { ContractObject } from "@basalt-commons/global-api/object/nda_contract";
import ContractItem from "@src/components/pure/items/ContractItem";
import EmptyList from "@src/components/pure/view/EmptyList";
import signedDocumentImg from "@ui/basalt/images/note/img-file.png";
import { theme } from "@theme";
import SpoqaHansTypography from "../../components/pure/typo/SpoqaHansTypography";
import translate from "@src/utils/translate";
import CircularLoadingWrap from "@src/components/pure/utils/LoadingWrap/CircularLoadingWrap";
import NotFoundView from "./NotFoundView";

const useStyles = makeStyles((MuiTheme) => ({
  signed: {
    width: "100%",
    [MuiTheme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
  table_header: {
    height: 40,
    marginTop: 12,
    marginBottom: 0,
    borderBottom: `solid 1px ${theme.gray_2}`,
    paddingTop: 1,
  },
  table_header_grid: {
    display: "flex",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    alignItems: "center",
  },
  table_header_grid_logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 2,
  },
  table_header_text: {
    ...theme.font14,
    color: theme.gray_6,
    height: 20,
  },
}));

type TabNDAViewProps = {
  filteredNDAs: NdaObjectWithContract[];
  searchQuery: string;
  time_zone: string;
  format: string;
  ndaStatus: "loading" | "fetched";
  onDownloadNDAContract: (contract: ContractObject) => Promise<void>;
  onShareSignedContract: (contract: ContractObject) => void;
  onClickContract: (contract_id: string) => void;
};

const TabNDAView = ({
  filteredNDAs,
  searchQuery,
  time_zone,
  format,
  ndaStatus,
  onDownloadNDAContract,
  onShareSignedContract,
  onClickContract,
}: TabNDAViewProps) => {
  const classes = useStyles();

  const renderSignedTableHeader = () => {
    return (
      <Grid container spacing={2} className={classes.table_header}>
        <Grid item xs={3} className={classes.table_header_grid}>
          <SpoqaHansTypography
            className={classes.table_header_text}
            style={{ marginLeft: 8 }}
          >
            {translate.all_signs.nda_filename}
          </SpoqaHansTypography>
        </Grid>
        <Grid item xs={3} className={classes.table_header_grid}>
          <SpoqaHansTypography className={classes.table_header_text}>
            {translate.all_signs.filename}
          </SpoqaHansTypography>
        </Grid>
        <Grid item xs={2} className={classes.table_header_grid}>
          <SpoqaHansTypography className={classes.table_header_text}>
            {translate.all_signs.sign_requester}
          </SpoqaHansTypography>
        </Grid>
        <Grid item xs={2} className={classes.table_header_grid}>
          <SpoqaHansTypography className={classes.table_header_text}>
            {translate.all_signs.signer}
          </SpoqaHansTypography>
        </Grid>
        <Grid item xs={2} className={classes.table_header_grid}>
          <SpoqaHansTypography className={classes.table_header_text}>
            {translate.all_signs.sign_date}
          </SpoqaHansTypography>
        </Grid>
      </Grid>
    );
  };

  const emptyListView = () => {
    return (
      <EmptyList
        imgElem={<img src={signedDocumentImg} alt="Upload tip" />}
        title={translate.all_signs.no_sign}
      />
    );
  };

  const contractItemListView = () => {
    return (
      <Grid container direction="column">
        {filteredNDAs.map((nda) =>
          nda.NdaContracts.map((contract) => {
            const doc = nda.Documents.find((d) => d.id === contract.DocumentId);
            return (
              <ContractItem
                time_zone={time_zone}
                format={format}
                key={contract.id}
                contract={contract}
                doc={doc}
                onDownloadNDAContract={async () => {
                  await onDownloadNDAContract(contract);
                }}
                onShareSignedContract={() => {
                  onShareSignedContract(contract);
                }}
                onClickContract={() => {
                  onClickContract(contract.id);
                }}
              />
            );
          })
        )}
      </Grid>
    );
  };

  const renderSignedTableBody = () => {
    return (
      <>
        {filteredNDAs.length > 0 ? (
          contractItemListView()
        ) : searchQuery.length === 0 ? (
          emptyListView()
        ) : (
          <NotFoundView
            title={`No signed NDA contracts were found for "${searchQuery}"`}
            border={true}
          />
        )}
      </>
    );
  };
  return (
    <Grid className={classes.signed}>
      <CircularLoadingWrap loading={ndaStatus === "loading"}>
        {renderSignedTableHeader()}
        {renderSignedTableBody()}
      </CircularLoadingWrap>
    </Grid>
  );
};

export default TabNDAView;
