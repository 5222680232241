export default {
  ko: {
    viewer: {
      step1: "[Step 1/2]",
      step2: "[Step 2/2]",
      step_email: "Email 입력하기",
      step_nda: "NDA 서명하기",
      download: "다운받기",
      print: "프린트 하기",
      email: {
        title: "이메일 정보를 입력해주세요",
        sub1: "문서 보안을 위하여 해당 파일은 이메일 정보를 입력해야만 확인하실 수 있습니다.",
        sub2: "개인 정보 처리 방침을 확인하시고, 동의 후 이메일 정보를 입력해주세요",
        placeholder: "이메일을 입력하세요",
        agreement: "문서 보안 정책에 동의합니다",
        agreement_1: "개인정보 처리 방침에 동의합니다",
        agreement_2: "사이트 쿠키 정책에 동의합니다",
        open: "전문 보기",
        submit: "동의 및 제출",
      },

      nda: {
        header: "문서 열람 정책에 동의해주세요",
        subheader_1:
          "문서 보안을 위하여 해당 파일은 옆의 보안 정책에 의해 동의 후 ",
        subheader_2:
          "열람하실 수 있습니다. 확인 후 동의하시면 아래에 서명을 해주세요.",
        name: "이름",
        inst: "소속",
        inst_placeholder: "소속을 작성해주세요",
        sign: "서명",
        sign_with_name: " 이름으로 서명 대체하기",
        upload_sign: "서명 업로드",
        draw_sign: "서명 그리기",
        email: "서명 문서를 받아볼 이메일 주소",
        terms_header: `baSalt는 아래와 전자 서명 관련 정책을 취하고 있습니다. “정책
        동의 및 서명 제출하기” 버튼을 클릭하시면, 자동으로 서명 정책에
        동의하며 전자 서명 법적 효력에 동의함을 알립니다.`,
        terms_sign: `전자 서명 및 전자 문서의 법적 효력에 대해 동의합니다.`,
        terms_original:
          "서명 완료 후 발송되는 문서에 대해 원본 임에 동의합니다.",
        terms_basalt:
          "기타 자세한 내용은 baSalt 전자서명 이용약관에 동의합니다.",
        agree_sumbit: "정책 동의 및 서명 제출하기",
      },

      view: {
        full_screen: "전체 화면",
      },
    },
  },
  en: {
    viewer: {
      step1: "[Step 1/2]",
      step2: "[Step 2/2]",
      step_email: "Email Input",
      step_nda: "NDA Signature",
      download: "Download",
      print: "Print",
      email: {
        title: "Enter your email information",
        sub1: "To ensure document security, the file can only be accessed by entering the email information.",
        sub2: "Please check your personal information processing policy and enter your email information after agreeing to it.",
        placeholder: "Enter your email",
        agreement: "I accept the document security policy",
        agreement_1: "I agree with the personal information processing policy.",
        agreement_2: "I agree with the site cookie policy.",
        open: "View the text",
        submit: "Agree & Submit",
      },
      nda: {
        header: "Please agree to the document reading policy",
        subheader_1:
          "For document security, the file can be viewed after agreeing to ",
        subheader_2:
          "the security policy next to it. If you agree after checking, please sign below.",
        name: "Name",
        inst: "Inst",
        inst_placeholder: "Please enter your institution",
        sign: "Signature",
        sign_with_name: "Sign with name",
        upload_sign: "Upload a sign",
        draw_sign: "Draw a sign",
        email: "Email address to receive signature documents",
        terms_header: `BaSalt is taking the policies related to electronic signatures below. Click the "Agreed to Policy and Submit Signature" button to automatically agree to the signature policy and notify us that you agree to the legal effect of electronic signatures.`,
        terms_sign: `I agree with the legal effect of electronic signatures and electronic documents.`,
        terms_original:
          "I agree that the document sent after the signature is completed is original.",
        terms_basalt:
          "For more information, I agree to the terms and conditions of BaSalt electronic signature.",
        agree_sumbit: "Agree and Submit",
      },
      view: {
        full_screen: "Full Screen",
      },
    },
  },
};
