export default {
  ko: {
    purchase: {
      error: {
        expried_date: "카드 유효기간이 이상합니다.",
        input_err: "형식에 어긋납니다.",
      },
      payment_info: "결제 정보",
      info: {
        person: "인",
        month: "월",
      },
      back: {
        go_back: {
          first: "",
          second: " 으로 돌아가기",
        },
        back_info: "결제 정보 화면으로 돌아가기",
      },
      purchase: "결제하기",
      register_card: "카드 등록 후 결제하기",
      card: {
        title: "카드를 등록해주세요",
        number: {
          title: "카드 번호",
          placeholder: "등록할 카드 번호를 입력해주세요",
        },
        expired: {
          title: "유효기간",
        },
        birthday: {
          title: "생년월일 또는 사업자등록번호",
        },
        password: {
          title: "카드 비밀번호 앞 두자리",
          placeholder: "카드 비밀번호 앞 두자리를 입력해주세요",
        },
        cvc: {
          title: "카드 인증 번호",
          placeholder: "카드 뒷면 3자리, AMEX의 경우 4자리",
        },
        description:
          "해당 결제 정보는 baSalt 서비스 내 워크스페이스 구독 결제 용도로만 사용되며, 환불/취소는 문의를 통해 진행하실 수 있습니다.",
        register: "카드 등록",
      },
      credit: {
        title: "워크스페이스 내 크레딧",
        use: "크레딧 사용하기",
        use_placeholder: "사용할 크레딧을 숫자로 입력해주세요",
        apply: "적용하기",
        use_all: "전액 사용",
        use_credit: "사용한 Credit",
        now_credit: "현재 보유 Credit",
        overflow_error: "보유하신 크레딧보다 많습니다",
      },
    },
  },

  en: {
    purchase: {
      error: {
        expried_date: "The expiration date of card is invalid",
        input_err: "This is an invalid format",
      },
      payment_info: "Payment Information",
      info: {
        person: "seats",
        month: "month",
      },
      back: {
        go_back: {
          first: "Back to ",
          second: "",
        },
        back_info: "Back to payment information",
      },
      purchase: "Paying now",
      register_card: "Register a card & Paying now",
      card: {
        title: "Register your card",
        number: {
          title: "Card number",
          placeholder: "Enter your credit card number",
        },
        expired: {
          title: "Card expiration date",
        },
        birthday: {
          title: "Date of birth or EIN",
        },
        password: {
          title: "The first 2 digits of the card password",
          placeholder: "Enter the first 2 digits of your password",
        },
        cvc: {
          title: "Card verification number",
          placeholder:
            "Enter 3 digits on the back of the card or 4 digits for AMEX",
        },
        description:
          "The payment/card informations are used only for workspace subscription in baSalt service, and refunds/cancellations can be made through inquiries.",
        register: "Register card",
      },
      credit: {
        title: "Workspace's credit",
        use: "Using credit",
        use_placeholder: "Please enter the number of credit",
        apply: "Apply",
        use_all: "Use all",
        use_credit: "Used credit",
        now_credit: "Credit that workspace have",
        overflow_error: "It's more than workspace's credit",
      },
    },
  },
};
