import { createStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";

const styles = (MuiTheme: Theme) =>
  createStyles({
    iconButton: {
      width: 44,
      height: 44,
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.icon_40,
        margin: "4px 4px 4px 0",
      },
    },
    noti_icon: { color: theme.gray_7_text },
    noti_icon_xs: { color: theme.gray_7_text, ...theme.icon_24, margin: 8 },
    cancel_icon: { color: theme.gray_5, height: 24 },
    profileImage: {
      width: 36,
      height: 36,
      borderRadius: 20,
      objectFit: "cover",
    },

    alarmContainer: {
      padding: "1.5rem",
    },
    titleText: {
      wordBreak: "break-word",
      wordWrap: "break-word",
      whiteSpace: "normal",
      fontSize: 16,
      color: theme.gray_7_text,
    },
    closeIcon: { fontSize: 20, color: theme.gray_5 },

    alarmSubText: { fontSize: 12, color: theme.gray_7_text },

    no_alarm_title: {
      textAlign: "center",
      fontSize: 16,
      color: theme.gray_7_text,
    },
    no_alarm_body: {
      textAlign: "center",
      fontSize: 14,
      color: theme.gray_7_text,
      marginTop: 8,
      wordBreak: "break-word",
    },
  });

export default styles;
