import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  RuleAction,
  RuleActionKind,
  RuleErrorType,
  RuleState,
  RuleStateMachine,
  RuleStateMachineType,
  RuleStateStatus,
} from "../../store/reducers/rule";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import Services from "@basalt-react-commons/services";
import { TokenStateStatus } from "../../store/reducers/token";
import moment from "moment";

import { RuleObject } from "@basalt-commons/global-api/object/rule";
import { WorkspaceStateStatus } from "../../store/reducers/workspace";
import { WorkspaceRoleType } from "@basalt-commons/global-api/object/user_workspace_map";

const alignRuleWithCreateTime = (rules: RuleObject[]): RuleObject[] => {
  const sort = rules.sort((a, b) => {
    let aDate = moment(a.createdAt).toDate().getTime();
    let bDate = moment(b.createdAt).toDate().getTime();
    return aDate > bDate ? -1 : 1;
  });
  return sort;
};

const { RuleService, RuleContractService, TaskService, IPFSService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.rule,
    token: state.token,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type RuleContainerProps = PropsFromRedux & {
  stateMachine: RuleStateMachineType;
};

class RuleContainer extends ReduxStateComponent3<RuleContainerProps> {
  static defaultProps = {
    stateMachine: RuleStateMachine,
  };
  constructor(props: RuleContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: RuleState,
    action: RuleAction
  ): Promise<RuleState> {
    const { token } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "LogContainer::onAction",
        msg: "not goono login",
      });
    const userToken = token.state.token;
    const userId = token.state.id;

    const getWorkspace = () => {
      const { workspace } = this.props;
      if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
        throw mkErr({
          kind: InternalErrorKind.Fatal,
          loc: "LogContainer::onAction",
          msg: "no selectAuthWorkspace",
        });
      return workspace.state.selectAuthWorkspace;
    };

    switch (action.kind) {
      case RuleActionKind.TRY_GET_RULES: {
        const selectedWorkspace = getWorkspace();

        const ret = await this.guardAwait(() =>
          RuleService.getRuleList(userToken, selectedWorkspace.id)
        );

        // default align - 최신순
        const rules = alignRuleWithCreateTime(ret.response.results);
        return {
          status: RuleStateStatus.SUCCESS,
          rules,
          rulesWithContractList:
            prevState.status === RuleStateStatus.SUCCESS
              ? prevState.rulesWithContractList
              : [],
        };
      }
      case RuleActionKind.TRY_CREATE_RULE: {
        if (prevState.status !== RuleStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: RuleActionKind.TRY_CREATE_RULE,
            msg: "invalid prevState",
          });
        const selectedWorkspace = getWorkspace();
        const userAuth = selectedWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          (userAuth.roleType !== WorkspaceRoleType.ADMIN &&
            userAuth.roleType !== WorkspaceRoleType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: RuleActionKind.TRY_CREATE_RULE,
            msg: RuleErrorType.ONLY_ADMIN_OR_HIGHER,
          });
        const { args } = action;
        const ret = await this.guardAwait(() =>
          RuleService.createRule(userToken, selectedWorkspace.id, args)
        );

        // Update the local rules list
        const rules = [ret.response, ...prevState.rules];

        return { ...prevState, rules };
      }
      case RuleActionKind.TRY_UPDATE_RULE: {
        if (prevState.status !== RuleStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: RuleActionKind.TRY_UPDATE_RULE,
            msg: "invalid prevState",
          });
        const selectedWorkspace = getWorkspace();
        const userAuth = selectedWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          (userAuth.roleType !== WorkspaceRoleType.ADMIN &&
            userAuth.roleType !== WorkspaceRoleType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: RuleActionKind.TRY_UPDATE_RULE,
            msg: RuleErrorType.ONLY_ADMIN_OR_HIGHER,
          });

        const { ruleId, required } = action;
        const ret = await this.guardAwait(() =>
          RuleService.updateRule(userToken, selectedWorkspace.id, ruleId, {
            required,
          })
        );

        // Update the local rules list
        const rules = prevState.rules.map((rule) =>
          rule.id === ret.response.id ? ret.response : rule
        );

        const rulesWithContractList = prevState.rulesWithContractList.map(
          (rule) => {
            return rule.id === ret.response.id
              ? { ...rule, required: ret.response.required }
              : rule;
          }
        );

        const workspacesWithRuleContract = prevState.workspacesWithRuleContract
          ? prevState.workspacesWithRuleContract.map((rule) => {
              return rule.id === ret.response.id
                ? { ...rule, required: ret.response.required }
                : rule;
            })
          : [];

        return {
          ...prevState,
          rules,
          rulesWithContractList,
          workspacesWithRuleContract,
        };
      }
      case RuleActionKind.TRY_DELETE_RULE: {
        if (prevState.status !== RuleStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: RuleActionKind.TRY_DELETE_RULE,
            msg: "invalid prevState",
          });
        const selectedWorkspace = getWorkspace();
        const userAuth = selectedWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          (userAuth.roleType !== WorkspaceRoleType.ADMIN &&
            userAuth.roleType !== WorkspaceRoleType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: RuleActionKind.TRY_DELETE_RULE,
            msg: RuleErrorType.ONLY_ADMIN_OR_HIGHER,
          });

        const { ruleId } = action;
        const ret = await this.guardAwait(() =>
          RuleService.deleteRule(userToken, selectedWorkspace.id, ruleId)
        );

        // Update the local rules list
        const rules = prevState.rules.filter(
          (rule) => rule.id !== ret.response
        );
        const rulesWithContractList = prevState.rulesWithContractList.filter(
          (rule) => rule.id !== ret.response
        );

        return { ...prevState, rules, rulesWithContractList };
      }
      case RuleActionKind.TRY_GET_RULE_CONTRACT: {
        const selectedWorkspace = getWorkspace();

        const ret = await this.guardAwait(() =>
          RuleContractService.getRuleWithContract(
            userToken,
            selectedWorkspace.id,
            action.ruleId
          )
        );

        return {
          ...prevState,
          status: RuleStateStatus.SUCCESS,
          rulesWithContractList: [ret.response],
          rules:
            prevState.status === RuleStateStatus.SUCCESS ? prevState.rules : [],
        };
      }
      case RuleActionKind.TRY_GET_RULE_CONTRACT_LIST: {
        const selectedWorkspace = getWorkspace();
        const userAuth = selectedWorkspace.Users.find((u) => u.id === userId);
        if (
          userAuth === undefined ||
          (userAuth.roleType !== WorkspaceRoleType.ADMIN &&
            userAuth.roleType !== WorkspaceRoleType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: RuleActionKind.TRY_DELETE_RULE,
            msg: RuleErrorType.ONLY_ADMIN_OR_HIGHER,
          });

        const ret = await this.guardAwait(() =>
          RuleContractService.getRuleWithContractList(
            userToken,
            selectedWorkspace.id
          )
        );

        return {
          ...prevState,
          status: RuleStateStatus.SUCCESS,
          rulesWithContractList: ret.response.results,
          rules:
            prevState.status === RuleStateStatus.SUCCESS ? prevState.rules : [],
        };
      }
      case RuleActionKind.TRY_CREATE_RULE_CONTRACT: {
        const selectedWorkspace = getWorkspace();
        const { ruleId, args } = action;

        await this.guardAwait(() =>
          RuleContractService.createRuleContract(
            userToken,
            selectedWorkspace.id,
            ruleId,
            args
          )
        );

        return { ...prevState };
      }
      case RuleActionKind.TRY_GET_WORKSPACE_RULE_LIST: {
        const ret = await this.guardAwait(() =>
          RuleContractService.getWorkspaceRuleList(userToken)
        );

        return { ...prevState, workspacesWithRule: ret.response.results };
      }
      case RuleActionKind.TRY_GET_ALL_SIGNED_RULES: {
        const ret = await this.guardAwait(() =>
          RuleContractService.getSignedRuleList(userToken)
        );

        return {
          ...prevState,
          workspacesWithRuleContract: ret.response.results,
        };
      }
      case RuleActionKind.TRY_CHECK_CONTRACT_REQUIRED: {
        if (prevState.status !== RuleStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: RuleActionKind.TRY_CHECK_CONTRACT_REQUIRED,
            msg: "invalid prevState",
          });
        const selectedWorkspace = getWorkspace();
        const ret = await this.guardAwait(() =>
          RuleContractService.checkSignRequired(userToken, selectedWorkspace.id)
        );
        return {
          ...prevState,
          needContract: ret.response.needSignRequiredRule,
        };
      }
      case RuleActionKind.TRY_DOWNLOAD_ALL_CONTRACT: {
        if (prevState.status !== RuleStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: RuleActionKind.TRY_DOWNLOAD_ALL_CONTRACT,
            msg: "Invalid prev state",
          });
        const { workspaceId, RuleId } = action;
        // ZIP 파일 입니다.
        const contractsArchivedZip = await this.guardAwait(() =>
          RuleContractService.getRuleContractAllPDF(userToken, workspaceId, {
            RuleId,
          })
        );

        // 시간이 걸리는 작업이므로 Task처리를 필요로 한다.
        const taskResult = await this.guardAwait(() =>
          TaskService.waitforTaskComplete(
            contractsArchivedZip.response.id,
            userToken
          )
        );
        if (taskResult) {
          const zip = await this.guardAwait(() =>
            IPFSService.getIPFSZipUrl(
              userToken,
              contractsArchivedZip.pending_tasks.pdf,
              contractsArchivedZip.response.UserId
            )
          );
          const filePath = IPFSService.getIPFSUrl(zip) + ".zip";
          return { ...prevState, filePath };
        } else {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: RuleActionKind.TRY_DOWNLOAD_ALL_CONTRACT,
            msg: "pdf get failed",
          });
        }
      }
    }
  }
}

export default connector(RuleContainer);
