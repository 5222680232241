import { GoonoResponse, mkResponseSchema } from "../../api/response/common";
import T from "@redwit-commons/utils/typecheck";

/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum CheckRegisterErrorType {
  ALREADY_REGISTER = "CheckRegisterError::ALREADY_REGISTER",
  NEED_VERIFY = "CheckRegisterError::NEED_VERIFY",
  NOT_FOUND_AUTH = "CheckRegisterError::NOT_FOUND_AUTH",
  REGISTERED_BY_EMAIL = "CheckRegisterError::REGISTERED_BY_EMAIL",
  REGISTERED_BY_GOOGLE = "CheckRegisterError::REGISTERED_BY_GOOGLE",
  REGISTERED_BY_KAKAO = "CheckRegisterError::REGISTERED_BY_KAKAO",
  REGISTERED_BY_APPLE = "CheckRegisterError::REGISTERED_BY_APPLE",
}
/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum UpdatePWErrorType {
  NOT_REGISTER = "UpdatePWError::NOT_REGISTER",
  NOT_FOUND_TOKEN = "UpdatePWError::NOT_FOUND_TOKEN",
  INVALID_TOKEN = "UpdatePWError::INVALID_TOKEN",
  NEED_VERIFY = "UpdatePWError::NEED_VERIFY",
}
/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum RegisterEmailErrorType {
  NOT_FOUND_TOKEN = "RegisterEmailError::NOT_FOUND_TOKEN",
  INVALID_TOKEN = "RegisterEmailError::INVALID_TOKEN",
  ALREADY_REGISTER = "RegisterEmailError::ALREADY_REGISTER",
  NEED_VERIFY = "RegisterEmailError::NEED_VERIFY",
}
export enum VerifyEmailErrorType {
  NOT_FOUND_TOKEN = "VerifyEmailError::NOT_FOUND_TOKEN",
  NOT_FOUND_USER = "VerifyEmailError::NOT_FOUND_USER",
  EXPIRED_TOKEN = "VerifyEmailError::EXPIRED_TOKEN",
  ALREADY_REGISTER = "VerifyEmailError::ALREADY_REGISTER",
}
/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum RegisterErrorType {
  NEED_VERIFY = "RegisterError::NEED_VERIFY",
  NOT_FOUND_AUTH = "RegisterError::NOT_FOUND_AUTH",
  INVALID_PLATFORM = "RegisterError::INVALID_PLATFORM",
  ALREADY_REGISTER = "RegisterError::ALREADY_REGISTER",
}
/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum LoginErrorType {
  NOT_FOUND_USER = "LoginError::NOT_FOUND_USER",
  REGISTERED_BY_EMAIL = "LoginError::REGISTERED_BY_EMAIL",
  REGISTERED_BY_GOOGLE = "LoginError::REGISTERED_BY_GOOGLE",
  REGISTERED_BY_KAKAO = "LoginError::REGISTERED_BY_KAKAO",
  REGISTERED_BY_APPLE = "LoginError::REGISTERED_BY_APPLE",
  INVALID_PASSWORD = "LoginError::INVALID_PASSWORD",
  RESEND_VERIFY_MAIL = "LoginError::RESEND_VERIFY_MAIL",
  NEED_VERIFY = "LoginError::NEED_VERIFY",
}
export type OSendAuthMail = GoonoResponse<{ email_verification: string }>;
export const OSendAuthMailSchema = mkResponseSchema(
  T.object().addField("email_verification", T.string())
);
export const validateOSendAuthMail =
  T.mkValidator<OSendAuthMail>(OSendAuthMailSchema);

export type OGlobalRegister = GoonoResponse<{ token: string }>;
export const OGlobalRegisterSchema = mkResponseSchema(
  T.object().addField("token", T.string())
);
export const validateOGlobalRegister = T.mkValidator<OGlobalRegister>(
  OGlobalRegisterSchema
);

export type OBasaltLogin = GoonoResponse<{
  token: string;
}>;
export const OBasaltLoginSchema = mkResponseSchema(
  T.object().addField("token", T.string())
);
export const validateOBasaltLogin =
  T.mkValidator<OBasaltLogin>(OBasaltLoginSchema);
