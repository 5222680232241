export default {
  ko: {
    folder_item: {
      auth: {
        admin: "관리자",
        read_only: "읽기 전용",
        owner: "소유자",
        editor: "편집자",
      },
      members: "멤버",
      private: "개인 폴더",
      public: "공용 폴더",
      shared: "공유 폴더",
      updated: (text: string) => `${text} 업데이트됨`,
      new_updated: (text: string) => `업데이트 ${text}`,
      update_prefix: "",
      update_postfix: "업데이트됨",
      select_complete: "선택 완료",
      select_all: "모두 선택",
      upload: "업로드",
      cancel: "취소",
      download: "다운로드",
      delete: "삭제",
      files: " 개",
      menu: {
        invite: "멤버설정",
        settings: "폴더설정",
        delete: "폴더삭제",
        pin: "즐겨찾기 추가",
        unpin: "즐겨찾기 삭제",
      },
    },
  },

  en: {
    folder_item: {
      auth: {
        admin: "Admin",
        read_only: "Read only",
        owner: "Owner",
        editor: "Editor",
      },
      members: "members",
      private: "Private folder",
      public: "Public folder",
      shared: "Shared folder",
      updated: (text: string) => `updated ${text}`,
      new_updated: (text: string) => `updated ${text}`,
      update_prefix: "updated",
      update_postfix: "",
      select_complete: "Selected",
      select_all: "Select all",
      upload: "Upload",
      cancel: "Cancel",
      download: "Download",
      delete: "Delete",
      files: " files",
      menu: {
        invite: "Invite",
        settings: "Setting",
        delete: "Delete",
        pin: "Add to favorites",
        unpin: "Delete from favorites",
      },
    },
  },
};
