import sign_up from "./sign_up";
import landing_main from "./landing_main";
import project from "./project";
import pw_update from "./pw_update";
import preview from "./preview";
import pw_verify from "./pw_verify";
import landing_service_intro from "./landing_service_intro";
import payment from "./payment";
import sign_up_verified from "./sign_up_verified";
import document from "./document";
import timeline from "./timeline";
import landing_solution from "./landing_solution";
import viewer from "./viewer";
import nda from "./nda";
import account from "./account";
import link from "./link";
import search from "./search";
import workspace_setting from "./workspace_setting";
import rule from "./rule";
import signed_doc from "./signed_doc";
import purchase from "./purchase";
import sign_in from "./sign_in";
import landing_contact from "./landing_contact";
import stamp from "./stamp";
import workspace_create from "./workspace_create";

export default {
  ko: {
    ...sign_up.ko,
    ...landing_main.ko,
    ...project.ko,
    ...pw_update.ko,
    ...preview.ko,
    ...pw_verify.ko,
    ...landing_service_intro.ko,
    ...payment.ko,
    ...sign_up_verified.ko,
    ...document.ko,
    ...timeline.ko,
    ...landing_solution.ko,
    ...viewer.ko,
    ...nda.ko,
    ...account.ko,
    ...link.ko,
    ...search.ko,
    ...workspace_setting.ko,
    ...rule.ko,
    ...signed_doc.ko,
    ...purchase.ko,
    ...sign_in.ko,
    ...landing_contact.ko,
    ...stamp.ko,
    ...workspace_create.ko,
  },
  en: {
    ...sign_up.en,
    ...landing_main.en,
    ...project.en,
    ...pw_update.en,
    ...preview.en,
    ...pw_verify.en,
    ...landing_service_intro.en,
    ...payment.en,
    ...sign_up_verified.en,
    ...document.en,
    ...timeline.en,
    ...landing_solution.en,
    ...viewer.en,
    ...nda.en,
    ...account.en,
    ...link.en,
    ...search.en,
    ...workspace_setting.en,
    ...rule.en,
    ...signed_doc.en,
    ...purchase.en,
    ...sign_in.en,
    ...landing_contact.en,
    ...stamp.en,
    ...workspace_create.en,
  },
};
