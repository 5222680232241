import { makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import translate from "../utils/translate";
import SidebarDrawerTitle from "../components/pure/lists/items/SidebarDrawerTitle";
import SidebarDrawerListItem from "../components/pure/lists/items/SidebarDrawerListItem";
import { ReactComponent as NDAIcon } from "@ui/basalt/images/sidebar/sidebar_item_nda.svg";
import { ReactComponent as CorpSign } from "@ui/basalt/images/sidebar/sidebar_item_corp_sign.svg";
import { ReactComponent as AllDocsIcon } from "@ui/basalt/images/sidebar/sidebar_item_all_docs.svg";
import { NDA_TAB_KIND } from "@src/screens/NDAScreen";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

export default function SecurityDrawerInnerComponent() {
  const classes = useStyles();
  const match = useRouteMatch<{
    workspace_name: string;
  }>("/:workspace_name/");
  const workspace_name = match?.params.workspace_name;
  const search = new URLSearchParams(window.location.search);
  const tab = search.get("tab");
  const stamp = search.get("mode");
  const router = useHistory();

  const createStyledIconComponent = (
    className: string,
    type: "nda" | "stamp" | "signed"
  ) => {
    if (type === "nda") return <NDAIcon className={className} />;
    else if (type === "stamp") return <CorpSign className={className} />;
    return <AllDocsIcon className={className} />;
  };

  const items = [
    {
      type: "nda",
      mode: [],
      item_text: translate.main_sidebar.security_drawer.manage_nda,
      onClick: () => {
        const path = `/${workspace_name}/ndas?tab=${NDA_TAB_KIND.NDA}`;
        router.push(path);
      },
    },
    {
      type: "stamp",
      mode: ["create", "edit"],
      item_text: translate.main_sidebar.security_drawer.corp_sign,
      onClick: () => {
        const path = `/${workspace_name}/ndas?tab=${NDA_TAB_KIND.STAMP}`;
        router.push(path);
      },
    },
    {
      type: "signed",
      mode: [],
      item_text: translate.main_sidebar.security_drawer.all_documents,
      onClick: () => {
        const path = `/${workspace_name}/ndas?tab=${NDA_TAB_KIND.SIGNED}`;
        router.push(path);
      },
    },
  ] as {
    type: "nda" | "stamp" | "signed";
    mode: Array<string>;
    item_text: string;
    onClick: () => void;
  }[];

  const renderDrawerTitle = () => {
    return (
      <SidebarDrawerTitle
        title={translate.main_sidebar.security_drawer.title}
      />
    );
  };

  return (
    <div className={classes.root}>
      {renderDrawerTitle()}
      {items.map(({ type, mode, item_text, onClick }, index) => (
        <SidebarDrawerListItem
          key={index}
          iconFactory={(className) =>
            createStyledIconComponent(className, type)
          }
          selected={tab === type || mode.includes(stamp!)}
          item_text={item_text}
          onClick={onClick}
        />
      ))}
    </div>
  );
}
