export default {
  ko: {
    nda_preview: {
      go_back: "NDA 문서로 돌아가기",
      go_back_settings: "워크스페이스  설정으로 돌아가기",
      contract_download: "전체 서명 다운",
      download: "다운로드",
      upload: "NDA 업로드 하기",
    },
    nda_create: {
      choose_location: "원하는 위치에 클릭해주세요",
      cancel: "취소",
      sign_info: "원하는 위치에 클릭해주세요",
      custom_explanation:
        "버튼을 클릭해서 서명 정보 입력할 곳을 선택할 수 있습니다. ",
      custom_toggle: "기본 템플릿으로 진행하기",
      view_templates: "템플릿 보기",
      required_info: "필수 정보",
      opt_info: "선택 정보",
      requester_name: "요청자 이름",
      requester_sign: "요청자 서명",
      request_time: "요청 시간",
      signer_name: "서명자 이름",
      signer_sign: "서명자 서명",
      signature_time: "서명 시간",
      requester_inst: "요청자 소속",
      signer_inst: "서명자 소속",
      requester_sign_text: "* 요청자의 서명이\n들어갈 자리입니다",
      signer_sign_text: "* 서명자의 서명이\n들어갈 자리입니다",
      preview: {
        title1: "해당 화면은 미리보기 화면입니다",
        title2: "실제와 약간의 오차가 있을 수 있습니다",
        requester: {
          name: "요청자 이름",
          inst: "요청자 소속",
          sign_time: "요청자 서명 시간",
          sign: {
            text1: "요청자의",
            text2: "서명 이미지",
          },
        },
        signer: {
          name: "서명자 이름",
          inst: "서명자 소속",
          sign_time: "서명자 서명 시간",
          sign: {
            text1: "서명자의",
            text2: "서명 이미지",
          },
        },
      },
      description: {
        title: "파일 설명",
        sub_title: "업로드 하려는 NDA 파일 설명을 적어 관리해보세요",
        placeholder: "파일 설명은 최대 100글자 이내로 입력 하실 수 있습니다",
      },
    },
    sign: {
      text: "워크스페이스 내부에서 사용할 회사 서명 파일을 업로드해보세요!",
      use_name: "내 이름으로 서명하기",
    },
    ndas: {
      title: "NDA",
      created_en: "",
      created_ko: "생성되었습니다",
      update: "새 NDA 업데이트",
      sign_info: "서명 내역",
      connected_link: "연결된 링크",
      preview: "프리뷰 보기",
      default_pre: "",
      default_post: "개의 폴더에 기본 NDA로 설정되어 있습니다",
      description: "NDA파일 설명",
      search_placeholder: "NDA 파일을 검색하세요",
      drag_tip: "파일을 끌어다 놓으면 업로드 됩니다",
      or: "또는",
      upload_err: "하나의 파일만 업로드 할 수 있습니다.",
      only_pdf: "pdf 파일을 업로드 해 주세요.",
      upload: "클릭하여 파일 업로드",
      image_format_err: "지원되지 않는 이미지 포맷입니다.",
    },

    nda_stamp_info: {
      nda_count_pre: "",
      nda_count_post: "개의 NDA파일에서 사용되었습니다",
      created: "생성",
      delete: "삭제하기",
      history: "사인 내역 확인하기",
      edit: "서명 수정하기",
    },

    company_signature: {
      title: "회사 서명",
      new: "새로운 서명 등록",
      search_placeholder: "회사 사인 파일을 검색하세요",
      cancel: "취소",
      upload_err: "서명 업로드에 실패했습니다.",
      upload_success: "새 서명이 성공적으로 업로드 되었습니다",
      update_success: "서명이 업데이트 되었습니다",
      update_err: "서명이 업데이트에 실패했습니다",
    },

    all_signs: {
      title: "모든 서명 문서",
      download: "전체 다운받기",
      search_placeholder: "파일을 검색하세요",
      nda_filename: "NDA 파일명",
      filename: "열람 파일명",
      sign_requester: "서명 요청자",
      signer: "서명한 사람",
      sign_date: "사인한 날짜",
      no_sign: "서명 문서가 없습니다.",
    },

    nda_contract_item: {
      see_file: "파일 보기",
      email: "이메일 발송하기",
      download: "다운로드",
    },

    nda_item: {
      created: (text: string) => {
        return `${text} 생성되었습니다`;
      },
      sign_info: "서명 내역",
      connected_link: "연결된 링크",
      preview: "프리뷰 보기",
      default_project: (count: number) => {
        return `${count} 개의 폴더에 기본 NDA로 설정되어 있습니다`;
      },
      description: "NDA파일 설명",
      download_history: "서명 내역 전체 다운로드",
      delete: "삭제하기",
      description_placeholder: "파일 설명을 입력하세요",
      save: "저장",
      no_sign_history: "서명 내역이 없습니다",
      file_name: "파일 이름",
      link_name: "링크 이름",
      no_links: "링크가 없습니다",
      signed: "서명 횟수",
    },
  },

  en: {
    nda_preview: {
      go_back: "Return to NDA documents",
      go_back_settings: "Return to Workspace Settings",
      contract_download: "Full signature download",
      download: "Download",
      upload: "Upload NDA",
    },
    nda_create: {
      choose_location: "Please click on the desired location",
      cancel: "Cancel",
      sign_info: "Signature Information",
      custom_explanation:
        "You can select where to enter your signature information by clicking the button",
      custom_toggle: "Proceed with default template",
      view_templates: "View Templates",
      required_info: "Required Information",
      opt_info: "Optional Information",
      requester_name: "Requester Name",
      requester_sign: "Requester Signature",
      request_time: "Request Time",
      signer_name: "Signer Name",
      signer_sign: "Signer Signature",
      signature_time: "Signature Time",
      requester_inst: "Requester Institution",
      signer_inst: "Signer Institution",
      requester_sign_text: "Requester Signature",
      signer_sign_text: "Signer Signature",
      preview: {
        title1: "This screen is a preview screen",
        title2: "There may be some errors from the actual",
        requester: {
          name: "Requester Name",
          inst: "Requester Institution",
          sign_time: "Requester's Signature Time",
          sign: {
            text1: "Requester's",
            text2: "Signature",
          },
        },
        signer: {
          name: "Signer Name",
          inst: "Signer Institution",
          sign_time: "Signer's Signature Time",
          sign: {
            text1: "Signer's",
            text2: "Signature",
          },
        },
      },
      description: {
        title: "File Description",
        sub_title: "Manage NDA file you want to upload by entering description",
        placeholder: "You can enter description within 100 letters",
      },
    },
    sign: {
      text: "Upload the cooperation signature file that you will use within the workspace!",
      use_name: "Sign with my name",
    },

    nda_stamp_info: {
      nda_count_pre: "Used in ",
      nda_count_post: " NDA files",
      created: "created",
      delete: "Delete",
      history: "Check the sign history",
      edit: "Edit signature",
    },

    ndas: {
      title: "NDAs",
      update: "Create",
      sign_info: "Signature history",
      connected_link: "Connected link",
      preview: "See preview",
      default_pre: "Set as the default NDA in",
      default_post: "folders",
      description: "NDA file description",
      created_en: "created",
      created_ko: "",
      search_placeholder: "Search the NDA file",
      drag_tip: "If you drag and drop the file, it will be uploaded",
      or: "or",
      upload: "Click and upload files",
      upload_err: "You can upload only 1 file",
      only_pdf: "You can only upload PDF file",
      image_format_err: "It is an unsupported image type.",
    },
    company_signature: {
      title: "Company Signature",
      new: "Create",
      search_placeholder: "Search the company's signature file",
      cancel: "Cancel",
      upload_err: "Failed to upload a company signature.",
      upload_success: "The new signature has been successfully uploaded",
      update_success: "The signature has been updated",
      update_err: "Failed to update the signature",
    },

    all_signs: {
      title: "All signed documents",
      download: "Download all",
      search_placeholder: "Search the file",
      nda_filename: "NDA file name",
      filename: "Read file name",
      sign_requester: "Signature requester",
      signer: "Signed by",
      no_sign: "There is no signed paper",
      sign_date: "Signing date",
    },

    nda_contract_item: {
      see_file: "View the file",
      email: "Send email",
      download: "Download",
    },

    nda_item: {
      sign_info: "Signature history",
      connected_link: "Connected link",
      preview: "See preview",
      default_project: (count: number) => {
        return `Set as the default NDA in ${count} folders`;
      },
      description: "NDA file description",
      created: (text: string) => {
        return `created ${text}`;
      },
      download_history: "Download full signature history",
      delete: "Delete",
      description_placeholder: "Enter the file description",
      save: "Save",
      no_sign_history: "No signature history",
      file_name: "File name",
      link_name: "Link name",
      no_links: "There are no links",
      signed: "Signed",
    },
  },
};
