import {
  ICreateRuleContract,
  IGetMyRuleContractAll,
  IGetRuleContractAllPDF,
} from "@basalt-commons/global-api/request/rule_contract";
import {
  OCreateRuleContract,
  OGetMyRuleContractAll,
  OGetRuleContractAllPDF,
  OGetRuleContractList,
  OGetRuleWithContract,
  OGetWorkspacesRuleContractList,
  OGetWorkspacesRuleList,
  ONeedSignRequiredRule,
  validateOCreateRuleContract,
  validateOGetMyRuleContractAll,
  validateOGetRuleContractAllPDF,
  validateOGetRuleContractList,
  validateOGetRuleWithContract,
  validateOGetWorkspacesRuleContractList,
  validateOGetWorkspacesRuleList,
  validateONeedSignRequiredRule,
} from "@basalt-commons/global-api/response/rule_contract";
import { doAPIPostRequest, doAPIGetRequest } from "../services/api";

/**
 * 룰 서명 생성을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * GUEST 권한 이상의 유저가 요청할 수 있음
 */
const createRuleContract = async (
  userToken: string,
  workspaceId: string,
  ruleId: string,
  args: ICreateRuleContract
): Promise<OCreateRuleContract> => {
  const obj = await doAPIPostRequest(
    `/workspaces/${workspaceId}/rules/${ruleId}/contract/create`,
    userToken,
    args
  );
  return validateOCreateRuleContract(obj);
};

/**
 * 특정 룰의 서명 목록을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * GUEST 권한 이상의 유저가 요청할 수 있음
 */
const getRuleWithContract = async (
  userToken: string,
  workspaceId: string,
  ruleId: string
): Promise<OGetRuleWithContract> => {
  const obj = await doAPIGetRequest(
    `/workspaces/${workspaceId}/rules/${ruleId}/contract`,
    userToken
  );
  return validateOGetRuleWithContract(obj);
};

/**
 * 특정 워크스페이스의 모든 룰 서명 목록을 가져오는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * ADMIN 권한 이상의 유저가 요청할 수 있음
 */
const getRuleWithContractList = async (
  userToken: string,
  workspaceId: string
): Promise<OGetRuleContractList> => {
  const obj = await doAPIGetRequest(
    `/workspaces/${workspaceId}/rule/contracts`,
    userToken
  );
  return validateOGetRuleContractList(obj);
};

/**
 * 사용자가 참여한 모든 워크스페이스의 룰을 가져오는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 */
const getWorkspaceRuleList = async (
  userToken: string
): Promise<OGetWorkspacesRuleList> => {
  const obj = await doAPIGetRequest(`/workspaces/rules`, userToken);
  return validateOGetWorkspacesRuleList(obj);
};

/**
 * 사용자가 참여한 모든 워크스페이스의 사인한 룰을 가져오는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 */
const getSignedRuleList = async (
  userToken: string
): Promise<OGetWorkspacesRuleContractList> => {
  const obj = await doAPIGetRequest(`/workspaces/rules/signed`, userToken);
  return validateOGetWorkspacesRuleContractList(obj);
};
/**
 * 특정 워크스페이스의 필수 룰에 서명 여부를 확인하는 함수
 * @param userToken
 * @param workspaceId
 */
const checkSignRequired = async (
  userToken: string,
  workspaceId: string
): Promise<ONeedSignRequiredRule> => {
  const obj = await doAPIGetRequest(
    `/workspaces/${workspaceId}/check/contract/required`,
    userToken
  );
  return validateONeedSignRequiredRule(obj);
};
/**
 * 룰에 서명된 모든 문서를 다운로드 하는 함수
 * @param userToken
 * @param workspaceId
 */
const getRuleContractAllPDF = async (
  userToken: string,
  workspaceId: string,
  args: IGetRuleContractAllPDF
): Promise<OGetRuleContractAllPDF> => {
  const obj = await doAPIPostRequest(
    `/${workspaceId}/rule/contracts/download/all`,
    userToken,
    args
  );
  return validateOGetRuleContractAllPDF(obj);
};
/**
 * 본인이 서명한 모든 룰 서명 문서를 다운로드 하는 함수
 * @param userToken
 * @param args
 */
const getMyRuleContractAll = async (
  userToken: string,
  args: IGetMyRuleContractAll
): Promise<OGetMyRuleContractAll> => {
  const obj = await doAPIPostRequest(
    `/my/rule/contracts/download/all`,
    userToken,
    args
  );
  return validateOGetMyRuleContractAll(obj);
};

export default {
  createRuleContract,
  getRuleWithContract,
  getRuleWithContractList,
  getWorkspaceRuleList,
  getSignedRuleList,
  checkSignRequired,
  getRuleContractAllPDF,
  getMyRuleContractAll,
};
