export default {
  ko: {
    error: {
      email_validator: {
        not_enter: "이메일을 입력해주세요",
        not_format: "이메일 형식에 어긋납니다",
      },

      pw_validator: {
        more_8_letter: "8자 이상 작성해주세요",
        not_format: "영어, 숫자, 특수문자를 혼합하여 사용해주세요",
        invalid_special: `사용 가능한 특수기호: !@#$%^&*()_+~"<>?{}[]`,
      },

      workspace_name_validator: {
        more_than_one: "이름 길이는  1자 이상이어야 합니다",
        more_15_letter: "15자 미만으로 작성해 주세요",
        no_emoji: "이모지를 사용할 수 없습니다",
        no_special_char: "특수 문자를 사용할 수 없습니다",
      },

      folder_name_validator: {
        more_than_two: "폴더 이름은 2자 이상이어야 합니다",
      },

      tag_validator: {
        more_than_30: "30자 이하로 작성해주세요",
        invalid_format: "유효하지 않은 형식입니다",
      },

      emoji_error: "이모티콘을 사용할 수 없습니다.",
      special_character_error: "특수문자는 ~_-/ 만 사용가능합니다.",
      overflow_error: (length: number) =>
        `${length}자 이상 작성할 수 없습니다.`,

      name_validator: {
        overflow: "30자 이하로 작성해주세요",
        not_format: "이름 형식이 알맞지 않습니다.",
        special_char: "특수 기호를 포함할 수 없습니다.",
        emoji: "이모지를 포함하실 수 없습니다.",
      },
      inst_validator: {
        overflow: "50자 이하로 작성해주세요",
        not_format: "소속 형식이 알맞지 않습니다.",
        special_char: "특수 기호를 포함할 수 없습니다.",
        emoji: "이모지를 포함하실 수 없습니다.",
      },

      pw_not_match: "비밀번호가 일치하지 않습니다.",
      unexpected: "예상하지 못한 에러가 발생했습니다.",

      sns: {
        popup_blocked: "팝업창을 허용해주세요.",
        google_secret_tab:
          "시크릿 모드 혹은 쿠키 차단 시 구글 로그인은 불가능합니다 (쿠키를 허용해주세요)",
        disallowed_useragent:
          "지원되지 않는 브라우저입니다. 크롬 브라우저를 사용해주세요.",
        login_fail: "로그인에 실패하였습니다. 새로고침 후 다시 이용해주세요",
      },
    },
  },

  en: {
    error: {
      email_validator: {
        not_enter: "Please enter your Email",
        not_format: "The Email address does not fit the format",
      },

      pw_validator: {
        more_8_letter: "Please write it with 8 or more letters",
        not_format:
          "Please set your password including special character, number, and English.",
        invalid_special: `Allowed Special Character: !@#$%^&*()_+~"<>?{}[]`,
      },

      workspace_name_validator: {
        more_than_one: "The name should be at least 1 character long",
        more_15_letter: "Enter the name within 15 characters",
        no_emoji: "You cannot use emoji",
        no_special_char: "You cannot use special characters",
      },

      folder_name_validator: {
        more_than_two: "Folder name should be at least 2 characters long",
      },

      tag_validator: {
        more_than_30: "Enter tags within 30 characters",
        invalid_format: "Invalid format",
      },

      emoji_error: "You cannot use emoji",
      special_character_error:
        "You cannot use special characters other than ~_- /.",
      overflow_error: (length: number) =>
        `You cannot enter more than ${length} characters`,

      name_validator: {
        overflow: "Enter within 30 characters",
        not_format: "The name format is invalid.",
        special_char: "You cannot include special characters on your name",
        emoji: "You cannot include emojis on your name",
      },
      inst_validator: {
        overflow: "Enter within 50 characters",
        not_format: "The institution format is invalid.",
        special_char:
          "You cannot enter a special character on your institution",
        emoji: "You cannot enter an emoji on your institution",
      },

      pw_not_match: "Password is not correct.",
      unexpected: "Unexpected error occurred.",
      sns: {
        popup_blocked: "Allow pop-ups on your browser.",
        google_secret_tab:
          "If you turn on the incognito mode or block cookies, you cannot sign in with Google.",
        disallowed_useragent:
          "This browser is not supported to sign up. Please use Chrome browser.",
        login_fail: "Failed to sign in. Please try again.",
      },
    },
  },
};
