import { createStyles } from "@material-ui/core";
import { theme } from "@theme";

const styles = createStyles({
  item_grid: {},

  item_container: {
    flexDirection: "column",
    flexWrap: "nowrap",
    position: "relative",
    borderRadius: 8,
    border: `1px solid ${theme.gray_2}`,
    backgroundColor: theme.white,
    aspectRatio: "1/1",
    transition: ".25s",
    overflow: "hidden",
    ...theme.shadow_1,

    "&:hover": {
      borderColor: theme.indigo_2,
      boxShadow: `0 0 0 1px ${theme.indigo_2}, 0 0 10px 0 rgba(173, 181, 189, 0.2)`,
    },
  },

  img_container: {
    flex: "1 1 0",
    backgroundColor: theme.gray_0,
    overflow: "hidden",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },

  info_container: {
    height: 96,
    padding: 11,
    borderTop: `1px solid ${theme.gray_2}`,
  },

  stamp_nda_count: {
    ...theme.font10,
    color: theme.cian_6,
    lineHeight: "14px",
    backgroundColor: theme.gray_2,
    padding: "3px 8px 1px",
    marginBottom: 4,
    borderRadius: 8,
    width: "fit-content",
  },

  stamp_name: {
    color: theme.black,
    ...theme.font14,
    lineHeight: "20px",
    marginBottom: 10,
    width: "100%",
  },

  stamp_create_date: {
    ...theme.font12,
    lineHeight: "14px",
    color: theme.gray_6,
  },

  menu_button: {
    position: "absolute",
    top: 12,
    right: 12,
    width: 36,
    height: 36,
    borderRadius: 8,
    border: `1px solid ${theme.gray_2}`,
    backgroundColor: theme.white,
    "&:hover": {
      backgroundColor: theme.gray_0,
    },
  },
  more_icon: {
    color: theme.gray_7_text,
  },

  menu: {
    ...theme.shadow_2,
    border: `1px solid ${theme.gray_1}`,
    borderRadius: 8,
  },
  menu_list: {
    padding: "7px 0px",
  },
  menu_item: {
    height: 40,
    minHeight: 40,
    minWidth: 118,
    padding: "10px 11px",
    "&:focus-visible": {
      backgroundColor: theme.white,
    },
    "&:hover": {
      backgroundColor: theme.gray_0,
    },
  },
  menu_text: {
    ...theme.font14,
    color: theme.gray_9_title,
    lineHeight: "20px",
  },

  delete: {
    color: theme.system_red,
  },
});
export default styles;
