import T from "@redwit-commons/utils/typecheck";
import { HistoryObject, HistoryObjectSchema } from "../object/history";
import {
  GoonoResponse,
  mkResponseSchema,
  GoonoArrayResponse,
  mkArrayResultsSchema,
} from "./common";

export type OGetHistory = GoonoArrayResponse<HistoryObject>;
export type OCreateHistory = GoonoResponse<HistoryObject>;
export type ODeleteHistory = GoonoResponse<string>;

export const OGetHistorySchema = mkArrayResultsSchema(HistoryObjectSchema);
export const OCreateHistorySchema = mkResponseSchema(HistoryObjectSchema);
export const ODeleteHistorySchema = mkResponseSchema(T.string());

export const validateOGetHistory =
  T.mkValidator<OGetHistory>(OGetHistorySchema);
export const validateOCreateHistory =
  T.mkValidator<OCreateHistory>(OCreateHistorySchema);
export const validateODeleteHistory =
  T.mkValidator<ODeleteHistory>(ODeleteHistorySchema);
