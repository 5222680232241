import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import Services from "@basalt-react-commons/services";
import {
  AlarmStateMachineType,
  alarmStateMachine,
  AlarmState,
  AlarmAction,
  AlarmActionKind,
  AlarmStateStatus,
} from "../../store/reducers/alarm";
import { mkErr, InternalErrorKind } from "@redwit-commons/utils/exception2";
import { TokenStateStatus } from "../../store/reducers/token";
import { WorkspaceStateStatus } from "../../store/reducers/workspace";

const { AlarmService } = Services;

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    reduxState: state.alarm,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type AlarmContainerProps = PropsFromRedux & {
  stateMachine: AlarmStateMachineType;
};

class AlarmContainer extends ReduxStateComponent3<AlarmContainerProps> {
  static defaultProps = {
    stateMachine: alarmStateMachine,
  };
  constructor(props: AlarmContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: AlarmState,
    action: AlarmAction
  ): Promise<AlarmState> {
    const { token, workspace } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "AlarmContainer",
        msg: "user not loggin-in",
      });
    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "AlarmContainer",
        msg: "workspace status invalid",
      });
    if (workspace.state.selectAuthWorkspace === undefined)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "AlarmContainer",
        msg: "selectAuthWorkspace is undefined",
      });

    const userToken = token.state.token;
    switch (action.kind) {
      case AlarmActionKind.TRY_GET_ALARM: {
        if (workspace.state.selectAuthWorkspace.name !== action.workspaceName)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AlarmActionKind.TRY_GET_ALARM,
            msg: "selectAuthWorkspace is old",
          });
        const workspaceId = workspace.state.selectAuthWorkspace.id;
        const ret = await this.guardAwait(() =>
          AlarmService.getAlarmList(userToken, workspaceId)
        );

        return {
          status: AlarmStateStatus.SUCCESS,
          workspaceId,
          alarms: ret,
        };
      }
      case AlarmActionKind.TRY_DELETE_ALARM: {
        if (prevState.status !== AlarmStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AlarmActionKind.TRY_DELETE_ALARM,
            msg: "prevState is invalid",
          });
        const { alarmId } = action;
        await this.guardAwait(() =>
          AlarmService.deleteAlarm(userToken, prevState.workspaceId, alarmId)
        );
        const newAlarms = prevState.alarms.filter(
          (alarm) => alarm.id !== alarmId
        );
        return { ...prevState, alarms: newAlarms };
      }
      case AlarmActionKind.TRY_CHECK_ALARM: {
        if (prevState.status !== AlarmStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: AlarmActionKind.TRY_CHECK_ALARM,
            msg: "prevState is invalid",
          });
        const alarms = prevState.alarms;
        const new_alarms = alarms.filter((alarm) => alarm.isCheck === false);
        const checkPromise = new_alarms.map(async (alarm) => {
          await this.guardAwait(() =>
            AlarmService.checkAlarm(userToken, prevState.workspaceId, alarm.id)
          );
        });
        await this.guardAwait(() => Promise.all(checkPromise));
        const newAlarm = alarms.map((alarm) => ({ ...alarm, isCheck: true }));
        return { ...prevState, alarms: newAlarm };
      }
    }
  }
}

export default connector(AlarmContainer);
