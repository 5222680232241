interface gtmParams {
  eventName: string;
  object?: any;
}

export const gtmTrackEvent = async (params: gtmParams): Promise<void> => {
  try {
    const dataLayer = await getDataLayer();
    dataLayer.push({
      event: params.eventName,
      eventModel: params.object,
    });
  } catch (err) {
    console.log(err);
  }
};

const getDataLayer = () => {
  if (typeof window == "undefined") {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.dataLayer) {
    return console.log("gtm event called but, not exist");
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.dataLayer || [];
};
