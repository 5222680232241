import { createStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";

const styles = (MuiTheme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      outline: "none",
      padding: 0,
      "& .MuiList-root.MuiList-padding": {
        padding: 0,
      },
    },
    top_container: {
      padding: "12px 16px",
    },
    workspace_name_row: {
      display: "flex",
      alignItems: "center",
      flexGrow: 0,
      flexDirection: "row",
      minWidth: 196,
      height: 24,
      marginBottom: 4,
    },
    workspace_icon: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
    workspace_name_box: {
      minWidth: 168,
      textAlign: "center",
    },
    workspace_name: {
      fontSize: 14,
      color: theme.gray_9_title,
      fontWeight: 500,
      marginLeft: 6,
      textAlign: "left",
    },
    workspace_url_row: {
      minWidth: 196,
      height: 20,
      marginBottom: 2,
      display: "flex",
      flexDirection: "row",
    },
    workspace_url_text: {
      ...theme.font12,
      color: theme.gray_5,
      userSelect: "none",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
    copy_button: {
      backgroundColor: theme.gray_1,
      minWidth: 44,
      minHeight: 20,
      borderRadius: 8,
      position: "relative",
      padding: "2px 6px",
      marginLeft: 4,
      transition: "0.25s",
      "&:hover": {
        backgroundColor: theme.gray_2,
      },
    },
    copy_text: {
      color: theme.gray_6,
      fontSize: 12,
      fontWeight: 500,
      textTransform: "initial",
    },
    workspace_type_and_member_row: {
      minWidth: 142,
      height: 16,
      flexGrow: 0,
      marginBottom: 4,
      display: "flex",
      flexDirection: "row",
    },
    workspace_type: {
      fontSize: 12,
      color: theme.gray_7_text,
      marginRight: 4,
    },
    line: {
      ...theme.font12,
      fontWeight: "bold",
      userSelect: "none",
      color: theme.gray_2,
    },
    avatar_icon: { width: 16, height: 16, marginLeft: 4 },
    member_number: { fontSize: 12, color: theme.gray_7_text, marginLeft: 2 },
    workspace_auth_row: {
      width: 196,
      height: 16,
      display: "flex",
      flexDirection: "row",
    },
    my_auth_text: { fontSize: 12, color: theme.gray_7_text },
    user_auth_text: { fontSize: 12, color: theme.indigo_6, marginLeft: 2 },
    menu_divider: {
      backgroundColor: theme.gray_2,
    },
    signout_workspace_button: {
      width: "100%",
      height: 44,
      display: "block",
      paddingLeft: 16,
      "&:hover": {
        backgroundColor: theme.gray_1,
        borderRadius: 0,
      },
      [MuiTheme.breakpoints.down(600)]: {},
    },
    sign_out_text: {
      fontSize: 14,
      textAlign: "left",
      color: theme.gray_9_title,
    },
    create_workspace_button: {
      display: "block",
      padding: 0,
      width: "100%",
    },
    create_workspace_row: {
      display: "flex",
      padding: "12px 16px",
    },
    new_workspace_text: {
      fontSize: 14,
      color: theme.gray_9_title,
    },
    workspace_list_button: { display: "block", padding: 0, width: `100%` },
    workspaceSubMenuCardInnerDiv: {
      width: "100%",
      borderRadius: 8,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      transition: "0.25s",
      height: 60,
      paddingLeft: 4,
      "&:hover": {
        backgroundColor: theme.gray_0,
        borderRadius: 0,
      },
    },
    workspace_list_text_box: {
      display: "flex",
      flexDirection: "column",
      width: "calc(100% - 100px)",
      maxHeight: 56,
    },
    workspace_list_container: {
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      maxHeight: 280,
      overflowX: "hidden",
    },
    add_new_workspace_icon: {
      borderRadius: 8,
      width: 32,
      height: 32,
      backgroundColor: theme.white,
      border: `1px solid ${theme.gray_2}`,
      marginRight: 8,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    new_workspace_text_box: {
      display: "flex",
      margin: "8px 0",
    },
    add_new_workspace_icon_text: { fontSize: 32, color: theme.indigo_6 },

    workspace_title: {
      fontSize: 14,
      color: theme.gray_9_title,
      maxWidth: 156,
      fontWeight: 500,
      textTransform: "initial",
      textAlign: "left",
      textOverflow: "ellipsis",
      overflow: "hidden",
      letterSpacing: "normal",
    },
    workspace_url: {
      fontSize: 12,
      color: theme.gray_6,
      minWidth: 156,
      textTransform: "initial",
      textAlign: "left",
      textOverflow: "ellipsis",
      overflow: "hidden",
      letterSpacing: "normal",
    },
  });

export default styles;
