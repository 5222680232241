import React from "react";
import { Grid, Tooltip, withStyles, WithStyles } from "@material-ui/core";
import { getRandomColor } from "@theme";
import { WorkspaceObject } from "@basalt-commons/global-api/object/workspace";
import SpoqaHansTypography from "../typo/SpoqaHansTypography";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";
import styles from "./styles/WorkspaceIcon";

type WorkspaceIconProps = {
  workspace: WorkspaceObject;
  index: number;
  isSmall: boolean;
} & WithStyles<typeof styles>;

type WorkspaceIconState = {};

class WorkspaceIcon extends React.PureComponent<
  WorkspaceIconProps,
  WorkspaceIconState
> {
  static defaultProps = {
    index: 0,
    isSmall: false,
  };

  constructor(props: WorkspaceIconProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { workspace, classes, isSmall } = this.props;

    return (
      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        className={isSmall ? classes.root_small : classes.root}
      >
        <Tooltip
          title={
            <SpoqaHansTypography
              className={
                isSmall ? classes.tooltipText_small : classes.tooltipText
              }
            >
              {workspace.name}
            </SpoqaHansTypography>
          }
          arrow
          placement="right"
        >
          <Grid
            alignItems="center"
            justifyContent="center"
            container
            item
            className={isSmall ? classes.icon_grid_small : classes.iconGrid}
            style={{
              backgroundColor:
                workspace.workspace_cid === undefined
                  ? getRandomColor(this.props.index)
                  : undefined,
            }}
          >
            {workspace.workspace_cid !== undefined ? (
              <img
                alt="workspace"
                src={getIPFSUrl(workspace.workspace_cid)}
                className={isSmall ? classes.image_small : classes.image}
              />
            ) : (
              <SpoqaHansTypography
                fontWeight="bold"
                className={isSmall ? classes.title_small : classes.title}
              >
                {workspace.name[0]}
              </SpoqaHansTypography>
            )}
          </Grid>
        </Tooltip>
      </Grid>
    );
  }
}

export default withStyles(styles)(WorkspaceIcon);
