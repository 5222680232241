import {
  mkResponseSchema,
  mkArrayResultsSchema,
  GoonoResponse,
  GoonoArrayResponse,
  GoonoPendingTasks,
} from "./common";
import { ProjectObject, ProjectObjectSchema } from "../object/project";
import T from "@redwit-commons/utils/typecheck";

export enum shareTokenErrorType {
  INVALID_TOKEN = "INVALID_TOKEN",
  ALREADY_JOINED = "ALREADY_JOINED",
  PROJECT_NOT_EXISTED = "PROJECT_NOT_EXISTED",
}

/**
 * @type ProjectObject[]는 GET/projects에서만 쓰이기 때문에 일단 그대로 두었습니다.
 */
export type OGetProjects = GoonoArrayResponse<ProjectObject>;
export type OGetProject = GoonoResponse<ProjectObject>;
export type OCreateProject = GoonoResponse<ProjectObject> &
  GoonoPendingTasks<{ hlf_create_project: string }>;
export type OUpdateProject = GoonoResponse<ProjectObject>;
export type ODeleteProject = GoonoResponse<string>;
export type OShareProject = GoonoResponse<{ token: string }>;
export type OShareProjectLink = GoonoResponse<{ link: string }>;

export type OJoinProject = GoonoResponse<ProjectObject> &
  GoonoPendingTasks<{
    hlf_create_project: string;
    hlf_create_workspace?: string;
  }>;
export type ODeleteJoinedUser = GoonoResponse<string>;
export type OPinnedProject = GoonoResponse<ProjectObject>;
export type OUpdateJoinedUser = GoonoResponse<string>;
export type OCheckProjectShareToken = GoonoResponse<{
  available: boolean;
  errorMessage?: string;
}>;

export const OGetProjectsSchema = mkArrayResultsSchema(ProjectObjectSchema);
export const OGetProjectSchema = mkResponseSchema(ProjectObjectSchema);
export const OCreateProjectSchema = mkResponseSchema(
  ProjectObjectSchema,
  T.object().addField("hlf_create_project", T.string())
);
export const OUpdateProjectSchema = mkResponseSchema(ProjectObjectSchema);
export const ODeleteProjectSchema = mkResponseSchema(T.string());
export const OShareProjectSchema = mkResponseSchema(
  T.object().addField("token", T.string())
);
export const OShareProjectLinkSchema = mkResponseSchema(
  T.object().addField("link", T.string())
);

export const OJoinProjectSchema = mkResponseSchema(
  ProjectObjectSchema,
  T.object()
    .addField("hlf_create_project", T.string())
    .addField("hlf_create_workspace", T.string(), false)
);
export const ODeleteJoinedUserSchema = mkResponseSchema(T.string());
export const OPinnedProjectSchema = mkResponseSchema(ProjectObjectSchema);
export const OUpdateJoinedUserSchema = mkResponseSchema(T.string());
export const OCheckProjectShareTokenSchema = mkResponseSchema(
  T.object()
    .addField("available", T.boolean())
    .addField("errorMessage", T.string(), false)
);

export const validateOGetProjects =
  T.mkValidator<OGetProjects>(OGetProjectsSchema);
export const validateOGetProject =
  T.mkValidator<OGetProject>(OGetProjectSchema);
export const validateOCreateProject =
  T.mkValidator<OCreateProject>(OCreateProjectSchema);
export const validateOUpdateProject =
  T.mkValidator<OUpdateProject>(OUpdateProjectSchema);
export const validateODeleteProject =
  T.mkValidator<ODeleteProject>(ODeleteProjectSchema);
export const validateOShareProject =
  T.mkValidator<OShareProject>(OShareProjectSchema);
export const validateOShareProjectLink = T.mkValidator<OShareProjectLink>(
  OShareProjectLinkSchema
);

export const validateOJoinProject =
  T.mkValidator<OJoinProject>(OJoinProjectSchema);
export const validateODeleteJoinedUser = T.mkValidator<ODeleteJoinedUser>(
  ODeleteJoinedUserSchema
);
export const validateOPinnedProject =
  T.mkValidator<OPinnedProject>(OPinnedProjectSchema);
export const validateOUpdateJoinedUser = T.mkValidator<OUpdateJoinedUser>(
  OUpdateJoinedUserSchema
);
export const validateOCheckProjectShareToken =
  T.mkValidator<OCheckProjectShareToken>(OCheckProjectShareTokenSchema);
