import { Grid, GridProps } from "@material-ui/core";
import React from "react";
import _ from "lodash";
import GoonoSnackBar from "../../components/pure/modals/SnackBar";
import { SnackbarType } from "../../store/reducers/snackbar";
import translate from "../translate";

export type DragDropGridProps = {
  filesUpload?: (files: File[]) => Promise<void>;
  ignore: boolean;
} & GridProps;

type DropScreenState = {
  dragging: boolean;
};
class DropScreen extends React.PureComponent<
  DragDropGridProps,
  DropScreenState
> {
  static defaultProps = {
    ignore: false,
  };
  private myRef: React.RefObject<HTMLDivElement>;
  constructor(props: DragDropGridProps) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      dragging: false,
    };
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {
    this.myRef.current!.addEventListener(
      "dragenter",
      this.handleDragEnter,
      true
    );
    this.myRef.current!.addEventListener(
      "dragleave",
      this.handleDragLeave,
      true
    );
    this.myRef.current!.addEventListener("dragover", this.handleDragOver, true);
    this.myRef.current!.addEventListener("drop", this.handleDrop, true);
  }

  componentWillUnmount() {
    this.myRef.current!.removeEventListener(
      "dragenter",
      this.handleDragEnter,
      true
    );
    this.myRef.current!.removeEventListener(
      "dragleave",
      this.handleDragLeave,
      true
    );
    this.myRef.current!.removeEventListener(
      "dragover",
      this.handleDragOver,
      true
    );
    this.myRef.current!.removeEventListener("drop", this.handleDrop, true);
  }

  handleDragEnter(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.props.ignore) return;
    if (this.state.dragging === false) {
      this.setState({ dragging: true });
      return;
    }
    return;
  }

  handleDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.setState({ dragging: false });
    return;
  }

  handleDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.props.ignore) return;
    if (this.state.dragging === false) {
      this.setState({ dragging: true });
      return;
    }
    return;
  }

  handleDrop(evt: DragEvent) {
    const { filesUpload } = this.props;
    evt.preventDefault();
    evt.stopPropagation();
    if (this.props.ignore) return;
    if (evt.dataTransfer === null) return this.setState({ dragging: false });
    if (evt.dataTransfer.files.length < 1)
      return this.setState({ dragging: false });
    const files = evt.dataTransfer.files;
    const fileList = [...files];

    if (filesUpload !== undefined) {
      this.setState({ dragging: false });
      void filesUpload(fileList);
      return;
    }
    this.setState({ dragging: false });
    return;
  }

  render() {
    const backgroundColor =
      this.state.dragging === true
        ? { opacity: 0.3 }
        : { backgroundColor: "inherit", opacity: 1 };

    return (
      <>
        <Grid
          ref={this.myRef}
          {..._.omit(this.props, ["filesUpload", "ignore"])}
          style={{
            ...this.props.style,
            ...backgroundColor,
          }}
        >
          {this.props.children}
        </Grid>
        <GoonoSnackBar
          open={this.state.dragging}
          type={SnackbarType.INFO}
          msg={translate.snackbar.drag_here}
          onClose={() => {}}
        />
      </>
    );
  }
}

export default DropScreen;
