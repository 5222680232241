import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";

export enum HistoryType {
  HASHTAG = "HistoryType::HAHSTAG",
  SEARCH_WORD = "HistoryType::SEARCHWORD",
}

export type HistoryObjectCore = {
  readonly id: string;
  readonly type: HistoryType;
  readonly value: string;
  readonly UserId: string;
};

export type HistoryObject = AccessTime & HistoryObjectCore;

export const HistoryObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("value", T.string())
  .addField("UserId", T.string())
  .addField(
    "type",
    T.string().withEnum([HistoryType.HASHTAG, HistoryType.SEARCH_WORD])
  );

export const extractHistoryObject =
  T.mkObjectExtractor<HistoryObject>(HistoryObjectSchema);
