import { Grid, makeStyles, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NdaObject } from "@basalt-commons/global-api/object/nda";
import { WorkspaceWithNdaContract } from "@basalt-commons/global-api/object/workspace";
import { ContractObject } from "@basalt-commons/global-api/object/nda_contract";
import { DocumentObject } from "@basalt-commons/global-api/object/document";
import NDAItem from "@src/components/pure/items/NDAItem";
import EmptyList from "@src/components/pure/view/EmptyList";
import { ReactComponent as GridIcon } from "@ui/basalt/images/folder/grid.svg";
import ndaDragImg from "@ui/basalt/images/note/img-drag.png";
import { theme } from "@theme";
import SpoqaHansTypography from "../../components/pure/typo/SpoqaHansTypography";
import translate from "@src/utils/translate";
import {
  PersistActionKind,
  doPersistAction,
} from "@src/store/reducers/persist";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/store/reducers";
import CircularLoadingWrap from "@src/components/pure/utils/LoadingWrap/CircularLoadingWrap";
import NotFoundView from "./NotFoundView";
import { ReactComponent as FileListViewIcon } from "@ui/basalt/images/buttons/file-list-view.svg";
import Flex from "@src/components/pure/utils/Flex";
import { getIPFSNote, getIPFSUrl } from "@basalt-react-commons/services/ipfs";
import { useHistory } from "react-router-dom";
import { CSSObject } from "styled-components";
import NDACardItem from "./NDACarditem";

const useStyles = makeStyles((MuiTheme) => ({
  nda: {
    width: "100%",
    [MuiTheme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
  table_header: {
    height: 40,
    marginTop: 12,
    marginBottom: 0,
    borderBottom: `solid 1px ${theme.gray_2}`,
    paddingTop: 1,
  },
  table_header_grid: {
    display: "flex",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    alignItems: "center",
    paddingLeft: "8px",
  },
  table_header_grid_logo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 2,
    flexBasis: "25%",
    width: "25%",
    [MuiTheme.breakpoints.down(1280)]: {
      flexBasis: 40,
      width: 40,
      "& $table_header_text": {
        display: "none",
      },
    },
  },
  table_header_text: {
    ...theme.font14,
    color: theme.gray_6,
    height: 20,
  },
  gridIcon: {
    padding: 6,
  },
}));

type TabNDAViewProps = {
  filteredNDAs: NdaObject[];
  workspaceWithNdaContract: WorkspaceWithNdaContract | undefined;
  searchQuery: string;
  hasAuth: boolean;
  time_zone: string;
  format: string;
  ndaStatus: "loading" | "fetched";
  onDownloadAllContract: (
    contracts: ContractObject[]
  ) => (ndaId: string) => Promise<void>;
  onClickNDA: (nda_id: string) => void;
  onOpenConnectedFiles: (
    docs: DocumentObject[],
    docName: string,
    contracts: ContractObject[]
  ) => void;
  onChangeDescription: (nda: NdaObject) => void;
  onDeleteNDA: (nda: NdaObject) => void;
};

const VIEW_MODE_QUERY_KEY = "view_mode";

const TabNDAView = ({
  filteredNDAs,
  workspaceWithNdaContract,
  searchQuery,
  hasAuth,
  time_zone,
  format,
  ndaStatus,
  onDownloadAllContract,
  onClickNDA,
  onOpenConnectedFiles,
  onChangeDescription,
  onDeleteNDA,
}: TabNDAViewProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const persist = useSelector((state: RootState) => state.persist);
  const [viewMode, setViewMode] = useState<"list" | "grid">("list");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const history = useHistory();

  const setViewModeQuery = () => {
    history.push({
      search: `?tab=nda&${VIEW_MODE_QUERY_KEY}=${viewMode}`,
    });
  };

  const setViewModeWithQuery = () => {
    const params = new URLSearchParams(location.search);
    const viewMode: "list" | "grid" | string | null =
      params.get(VIEW_MODE_QUERY_KEY);
    if (viewMode === "grid") setViewMode("grid");
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (persist.state.capture) {
      doPersistAction(dispatch, {
        kind: PersistActionKind.RESET_CAPTURE,
      });
    }
  }, []);

  useEffect(setViewModeWithQuery, []);

  useEffect(setViewModeQuery, [viewMode]);

  const renderHeaderText = (text: string, style?: CSSObject) => {
    return (
      <SpoqaHansTypography className={classes.table_header_text} style={style}>
        {text}
      </SpoqaHansTypography>
    );
  };

  const renderListHeaderIconButton = () => {
    return (
      <IconButton
        className={classes.gridIcon}
        onClick={() => setViewMode("grid")}
      >
        <GridIcon />
      </IconButton>
    );
  };

  const renderHeaderWithListMode = () => {
    return (
      <Grid container className={classes.table_header}>
        <>
          <Grid item xs={6} lg={5} className={classes.table_header_grid}>
            {renderHeaderText("No", { width: 36 })}
            {renderHeaderText(translate.nda_item.file_name)}
          </Grid>
          <Grid item xs={6} lg={7} className={classes.table_header_grid}>
            <Grid item xs={4} lg={3}>
              {renderHeaderText(translate.nda_item.sign_info)}
            </Grid>
            <Grid item xs={4} lg={3}>
              {renderHeaderText(translate.nda_item.connected_file)}
            </Grid>
            <Grid item xs={4} lg={3}>
              {renderHeaderText(translate.nda_item.update)}
            </Grid>
            <Grid item className={classes.table_header_grid_logo}>
              {renderHeaderText(translate.nda_item.uploader)}
              {renderListHeaderIconButton()}
            </Grid>
          </Grid>
        </>
      </Grid>
    );
  };

  const renderHeaderWithGridMode = () => {
    return (
      <Flex
        item
        container
        justifyContent="space-between"
        className={classes.table_header}
      >
        <Grid className={classes.table_header_grid}>
          <SpoqaHansTypography className={classes.table_header_text}>
            {translate.nda_tab_header.file}
          </SpoqaHansTypography>
        </Grid>
        <Grid className={classes.table_header_grid}>
          <IconButton
            className={classes.gridIcon}
            onClick={() => setViewMode("list")}
          >
            <FileListViewIcon />
          </IconButton>
        </Grid>
      </Flex>
    );
  };

  const renderNDAsTableHeader = () => {
    return (
      <>
        {viewMode === "list"
          ? renderHeaderWithListMode()
          : renderHeaderWithGridMode()}
      </>
    );
  };

  const emptyListView = () => {
    return (
      <Grid>
        <EmptyList
          imgElem={<img src={ndaDragImg} alt="Upload tip" />}
          title={translate.ndas.drag_tip}
          button={{
            htmlFor: "upload-nda-file",
            text: translate.ndas.upload,
          }}
        />
      </Grid>
    );
  };

  const getContracts = (filtered_nda: NdaObject) => {
    const contracts = workspaceWithNdaContract
      ? workspaceWithNdaContract.Ndas.reduce(
          (contracts, nda) =>
            nda.id === filtered_nda.id
              ? contracts.concat(nda.NdaContracts)
              : contracts,
          [] as ContractObject[]
        )
      : [];
    return contracts;
  };

  const ndaListView = () => {
    return (
      <>
        {filteredNDAs.map((filtered_nda, index) => {
          const contracts = getContracts(filtered_nda);
          return (
            <NDAItem
              index={index + 1}
              hasAuth={hasAuth}
              key={filtered_nda.id}
              nda={filtered_nda}
              contracts={contracts}
              time_zone={time_zone}
              format={format}
              onDownloadAllContract={onDownloadAllContract(contracts)}
              onClick={() => {
                onClickNDA(filtered_nda.id);
              }}
              onOpenConnectedFiles={() =>
                onOpenConnectedFiles(
                  filtered_nda.Documents,
                  filtered_nda.file_name,
                  contracts
                )
              }
              onOpenAddDescriptionModal={() => {
                onChangeDescription(filtered_nda);
              }}
              deleteNDA={() => {
                onDeleteNDA(filtered_nda);
              }}
            />
          );
        })}
      </>
    );
  };

  const ndaGridView = () => {
    return (
      <Grid
        spacing={windowWidth < 600 ? 1 : 2}
        container
        item
        xs={12}
        style={{ width: "100%", paddingTop: "16px" }}
      >
        {filteredNDAs.map((filtered_nda, index) => {
          const contracts = getContracts(filtered_nda);
          return (
            <NDACardItem
              key={index}
              windowWidth={windowWidth}
              file_name={filtered_nda.file_name}
              uploader_name={filtered_nda.User.name}
              uploader_profile={getIPFSUrl(filtered_nda.User.profile_cid)}
              thumb_url={getIPFSNote(filtered_nda.thumbCid)}
              updated_at={filtered_nda.updatedAt}
              nda_id={filtered_nda.id}
              connected_file_count={filtered_nda.Documents.length}
              contract_count={contracts.length}
              onDeleteNDA={() => onDeleteNDA(filtered_nda)}
              onDownloadAllContract={onDownloadAllContract(contracts)}
            />
          );
        })}
      </Grid>
    );
  };

  const renderNDAsTableBody = () => {
    return (
      <>
        {filteredNDAs.length > 0 ? (
          viewMode === "list" ? (
            ndaListView()
          ) : (
            ndaGridView()
          )
        ) : searchQuery.length === 0 ? (
          emptyListView()
        ) : (
          <NotFoundView
            title={`No NDAs were found for  "${searchQuery}"`}
            border={true}
          />
        )}
      </>
    );
  };

  return (
    <Grid className={classes.nda}>
      <CircularLoadingWrap loading={ndaStatus === "loading"}>
        {renderNDAsTableHeader()}
        {renderNDAsTableBody()}
      </CircularLoadingWrap>
    </Grid>
  );
};

export default TabNDAView;
