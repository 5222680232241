import { AccessTime, withAccessTime } from "../../api/object/access_time";
import T from "@redwit-commons/utils/typecheck";

export type CardCore = {
  id: string; //billing_key
  card_number: string; // 카드번호(dddd-dddd-dddd-dddd)
  card_type?: "global" | "domestic";
  card_name?: string;
  owner_name?: string;
  UserId?: string;
};

export type CardObject = CardCore & AccessTime;
export type CardObjectWithDefault = CardObject & {
  default_card: boolean;
};

export const CardObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("card_number", T.string())
  .addField("card_type", T.string().withEnum(["global", "domestic"]), false)
  .addField("card_name", T.string(), false)
  .addField("owner_name", T.string(), false)
  .addField("UserId", T.string(), false);

export const CardObjectWithDefaultSchema = CardObjectSchema.clone().addField(
  "default_card",
  T.boolean()
);

export const extractCardObject =
  T.mkObjectExtractor<CardObject>(CardObjectSchema);
export const validateCardObject = T.mkValidator<CardObject>(CardObjectSchema);
export const validateCardObjectWithDefault =
  T.mkValidator<CardObjectWithDefault>(CardObjectWithDefaultSchema);
