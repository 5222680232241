import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "../../api/object/access_time";
import { DocumentObject } from "./document";
import { NdaPureObject } from "./nda";

export type ContractSign = {
  readonly client_name: string;
  readonly client_department: string;
  readonly client_sign_cid: string;
  readonly client_sign_extension: string;
};

export type ContractObjectCore = {
  readonly id: string;
  readonly file_name: string;
  readonly cid: string;
  readonly extension: string;
  readonly NdaId: string;
  readonly DocumentId: string;
  readonly UserId?: string;
} & ContractSign;

export type ContractObject = ContractObjectCore & AccessTime;
export type ContractDocumentObject = ContractObject & {
  Document: DocumentObject;
  Nda: NdaPureObject;
};

export const ContractObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("file_name", T.string())
  .addField("cid", T.string())
  .addField("extension", T.string())
  .addField("NdaId", T.string())
  .addField("DocumentId", T.string())
  .addField("UserId", T.string(), false)
  .addField("client_name", T.string())
  .addField("client_department", T.string())
  .addField("client_sign_cid", T.string())
  .addField("client_sign_extension", T.string());

export const extractContractObject =
  T.mkObjectExtractor<ContractObject>(ContractObjectSchema);
export const validateContractObject =
  T.mkValidator<ContractObject>(ContractObjectSchema);
