import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  FileAction,
  FileActionKind,
  FileState,
  fileStateMachine,
  FileStateMachineType,
  FileStateStatus,
} from "../../store/reducers/file";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.file,
    token: state.token,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type FileContainerProps = PropsFromRedux & {
  stateMachine: FileStateMachineType;
};

class FileContainer extends ReduxStateComponent3<FileContainerProps> {
  static defaultProps = { stateMachine: fileStateMachine };
  constructor(props: FileContainerProps) {
    super(props);
  }

  protected onAction(prevState: FileState, action: FileAction): FileState {
    switch (action.kind) {
      case FileActionKind.TRY_FETCH: {
        const { files } = prevState;

        const { project } = action;

        const newFiles: File[] = files;
        const nameSet = new Set(files.map((f) => f.name));
        action.files.map((f) => {
          if (!nameSet.has(f.name)) newFiles.push(f);
        });

        return { status: FileStateStatus.INIT, files: newFiles, project };
      }
      case FileActionKind.TRY_REMOVE_FILE: {
        const { files } = prevState;
        const updatedFiles = files.filter(
          (file) => file.name !== action.file.name
        );
        return { status: FileStateStatus.INIT, files: updatedFiles };
      }
      case FileActionKind.TRY_RESET: {
        const newFiles: File[] = [];
        return {
          status: FileStateStatus.INIT,
          files: newFiles,
          project: undefined,
        };
      }
    }
  }
}

export default connector(FileContainer);
