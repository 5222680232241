import { doAPIDeleteRequest, doAPIGetRequest, doAPIPostRequest } from "./api";
import { ICreateChat, IEditChat } from "@basalt-commons/api/request/chat";
import { ChatObject } from "@basalt-commons/global-api/object/chat";
import {
  validateOGetChats,
  validateOCreateChat,
  validateOEditChat,
  validateODeleteChat,
} from "@basalt-commons/api/response/chat";

const getChats = async (
  token: string,
  projectId: string,
  noteId: string
): Promise<ChatObject[]> => {
  const res = await doAPIGetRequest(
    `projects/${projectId}/notes/${noteId}/chats`,
    token
  );
  const ret = validateOGetChats(res).response.results;
  return ret;
};

const createChat = async (
  token: string,
  workspaceId: string,
  projectId: string,
  noteId: string,
  args: ICreateChat
): Promise<ChatObject> => {
  const res = await doAPIPostRequest(
    `${workspaceId}/projects/${projectId}/notes/${noteId}/chat`,
    token,
    args
  );
  const ret = validateOCreateChat(res).response;
  return ret;
};

const updateChat = async (
  token: string,
  workspaceId: string,
  projectId: string,
  noteId: string,
  chatId: string,
  args: IEditChat
): Promise<ChatObject> => {
  const res = await doAPIPostRequest(
    `${workspaceId}/projects/${projectId}/notes/${noteId}/chats/${chatId}`,
    token,
    args
  );
  const ret = validateOEditChat(res).response;
  return ret;
};

const deleteChat = async (
  token: string,
  projectId: string,
  noteId: string,
  chatId: string
): Promise<string> => {
  const res = await doAPIDeleteRequest(
    `projects/${projectId}/notes/${noteId}/chats/${chatId}`,
    token
  );
  const id = validateODeleteChat(res).response;
  return id;
};

export default {
  getChats,
  createChat,
  updateChat,
  deleteChat,
};
