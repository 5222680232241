import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";

const useStyles = makeStyles<Theme>((_MuiTheme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      alignItems: "center",
      gap: 4,
      zIndex: 2,
    },
    adornment: {
      minHeight: 24,
      minWidth: 24,
    },
    horizontalFlip: {
      WebkitTransform: `scaleX(-1)`,
      transform: `scaleX(-1)`,
    },
    addIcon: {
      ...theme.font22,
    },
  })
);

export default useStyles;
