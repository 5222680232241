import { createStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";

export const SnackBarPadding = 11;
export const SnackBarTextMargin = 8;
export const SnackBarIconSize = 24;

const styles = (MuiTheme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    snackbar: {
      bottom: 48,
      width: "calc((100% - 276px) / 2)",
      maxWidth: 770,
      minWidth: 280,
      [MuiTheme.breakpoints.only("xs")]: {
        left: "50%",
        transform: "translateX(-50%)",
      },
    },
    render_alert_container: {
      [MuiTheme.breakpoints.only("xs")]: {
        minWidth: 280,
      },
      border: `1px solid ${theme.system_red}`,
      backgroundColor: theme.system_red_bg,
      height: 48,
      padding: 11,
      borderRadius: 10,
      justifyContent: "flex-start",
      ...theme.shadow_2,
    },
    render_alert_icon: {
      color: theme.system_red,
    },
    render_alert_text: {
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font12,
        whiteSpace: "pre-line",
      },
      marginLeft: 8,
      color: theme.system_red,
      ...theme.font14,
    },

    render_warning_container: {
      [MuiTheme.breakpoints.only("xs")]: {
        minWidth: 280,
      },
      border: `1px solid ${theme.system_yellow}`,
      backgroundColor: theme.system_yellow_bg,
      height: 48,
      padding: 11,
      borderRadius: 10,
      justifyContent: "flex-start",
      ...theme.shadow_2,
    },
    render_warning_icon: {
      color: theme.system_yellow,
    },
    render_warning_text: {
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font12,
        whiteSpace: "pre-line",
      },
      marginLeft: 8,
      color: theme.gray_9_title,
      ...theme.font14,
    },

    render_confirm_container: {
      [MuiTheme.breakpoints.only("xs")]: {
        minWidth: 280,
      },
      border: `1px solid ${theme.system_green}`,
      backgroundColor: theme.green_0,
      height: 48,
      padding: 11,
      borderRadius: 10,
      justifyContent: "flex-start",
      ...theme.shadow_2,
    },
    render_confirm_icon: {
      color: theme.system_green,
    },
    render_confirm_text: {
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font12,
        whiteSpace: "pre-line",
      },
      marginLeft: 8,
      color: theme.system_green,
      ...theme.font14,
    },

    render_info_container: {
      border: `1px solid ${theme.indigo_5}`,
      backgroundColor: theme.indigo_0,
      height: 48,
      padding: 11,
      borderRadius: 10,
      justifyContent: "flex-start",
      ...theme.shadow_2,
    },
    render_info_icon: {
      color: theme.indigo_5,
    },
    render_info_text: {
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font12,
        whiteSpace: "pre-line",
      },
      color: theme.indigo_9,
      ...theme.font14,
    },

    render_loading_container: {
      border: `1px solid ${theme.gray_5}`,
      backgroundColor: theme.gray_0,
      height: 48,
      padding: 11,
      borderRadius: 10,
      justifyContent: "flex-start",
      ...theme.shadow_2,
    },
    render_loading_icon: {
      color: theme.gray_7_text,
    },
    render_loading_text: {
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font12,
        whiteSpace: "pre-line",
      },
      color: theme.gray_9_title,
      marginLeft: 12,
      ...theme.font14,
    },
  });
export default styles;
