import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "../../api/object/access_time";

export enum WorkspacePlanType {
  INDIVIDUAL = "Workspace::Individual",
  TEAM = "Workspace::Team",
  ENTERPRISE = "Workspace::Enterprise",
  TRIAL = "Workspace::Trial",
}

export const getPlanType = (plan?: WorkspacePlanType) => {
  switch (plan) {
    case WorkspacePlanType.ENTERPRISE:
      return "Enterprise Plan";
    case WorkspacePlanType.TRIAL:
    case WorkspacePlanType.TEAM:
      return "Team Plan";
    case WorkspacePlanType.INDIVIDUAL:
    default:
      return "Individual Plan";
  }
};

export const getWorkspacePlanLevel = (plan?: WorkspacePlanType) => {
  switch (plan) {
    case WorkspacePlanType.ENTERPRISE:
    case WorkspacePlanType.TEAM:
    case WorkspacePlanType.TRIAL:
      return 2;
    case WorkspacePlanType.INDIVIDUAL:
    default:
      return 1;
  }
};

export type WorkspacePlanPureCore = {
  readonly id: string;
  readonly plan: WorkspacePlanType;
  readonly currency: string;
  readonly amount: number;
  readonly start: string;
  readonly end: string;
  readonly WorkspaceId: string;
  readonly origin_price?: number;
};

export type WorkspacePlanPureObject = WorkspacePlanPureCore & AccessTime;

export const WorkspacePlanPureCoreSchema = withAccessTime()
  .addField("id", T.string())
  .addField(
    "plan",
    T.string().withEnum([
      WorkspacePlanType.INDIVIDUAL,
      WorkspacePlanType.TEAM,
      WorkspacePlanType.ENTERPRISE,
      WorkspacePlanType.TRIAL,
    ])
  )
  .addField("currency", T.string())
  .addField("amount", T.number())
  .addField("start", T.string())
  .addField("end", T.string())
  .addField("WorkspaceId", T.string())
  .addField("origin_price", T.number(), false);

export const extractWorkspacePlanPureObject =
  T.mkObjectExtractor<WorkspacePlanPureObject>(WorkspacePlanPureCoreSchema);
export const validateWorkspacePlanPureObject =
  T.mkValidator<WorkspacePlanPureObject>(WorkspacePlanPureCoreSchema);
