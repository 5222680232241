import React from "react";
import { ButtonBase } from "@material-ui/core";
import useStyles from "./styles/CustomButton2";
import clsx from "clsx";
import { theme } from "@theme";

type ButtonType =
  | "primary"
  | "secondary"
  | "line_indigo"
  | "line_gray"
  | "danger_line"
  | "danger_fill";
export type CustomButtonProps = {
  buttonType?: ButtonType;
  disabled?: boolean;
  className?: string;
  padding?: string | number;
  onClick?: (evt: React.MouseEvent<HTMLElement>) => void;
  htmlFor?: string;
  children?: React.ReactNode;
};

const CustomButton: React.FC<CustomButtonProps> = (
  props: CustomButtonProps
) => {
  const {
    buttonType,
    disabled,
    className,
    padding,
    onClick,
    htmlFor,
    children,
  } = props;

  const getRippleColor = () => {
    switch (buttonType) {
      case "primary":
        return "rgba(255, 255, 255, 0.3)";
      case "secondary":
        return theme.indigo_2;
      case "line_indigo":
        return theme.indigo_1;
      case "line_gray":
        return theme.gray_1;
      case "danger_line":
        return theme.system_red_bg_pressed;
      case "danger_fill":
        return theme.system_red_focused;
      default:
        return "white";
    }
  };

  const classes = useStyles({
    rippleColor: getRippleColor(),
  });

  const getClass = () => {
    const baseClass = [classes.button_base, classes.text];

    switch (buttonType) {
      case "primary":
        return [...baseClass, classes.button_primary, classes.text_primary];
      case "secondary":
        return [...baseClass, classes.button_secondary, classes.text_secondary];
      case "line_indigo":
        return [
          ...baseClass,
          classes.button_line_indigo,
          classes.text_line_indigo,
        ];
      case "line_gray":
        return [...baseClass, classes.button_line_gray, classes.text_line_gray];
      case "danger_line":
        return [
          ...baseClass,
          classes.button_danger_line,
          classes.text_danger_line,
        ];
      case "danger_fill":
        return [
          ...baseClass,
          classes.button_danger_fill,
          classes.text_danger_fill,
        ];
      default:
        return baseClass;
    }
  };

  const isFillButton = () => {
    return ["primary", "secondary", "danger_fill"].includes(buttonType!);
  };

  return (
    <ButtonBase
      component="label" // To enable htmlFor
      htmlFor={htmlFor}
      disabled={disabled}
      className={clsx(
        ...getClass(),
        isFillButton() ? classes.fill_disabled : classes.line_disabled,
        className
      )}
      style={{ padding: padding }}
      onClick={onClick}
      TouchRippleProps={{
        classes: {
          root: isFillButton()
            ? classes.ripple_root_fill
            : classes.ripple_root_line,
          rippleVisible: classes.ripple_visible,
        },
      }}
      focusVisibleClassName={classes.ripple_visible}
    >
      <div>{children}</div>
    </ButtonBase>
  );
};

CustomButton.defaultProps = {
  buttonType: "line_gray",
};

export default CustomButton;
