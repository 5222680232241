import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { StampWithNDA } from "@basalt-commons/global-api/object/stamp";

export enum StampStateStatus {
  INIT = "Stamp::INIT",
  SUCCESS = "Stamp::SUCCESS",
}

export enum StampActionKind {
  TRY_CREATE = "StampActionKind::TRY_CREATE",
  TRY_GET = "StampActionKind::TRY_GET",
  TRY_EDIT = "StampActionKind::TRY_EDIT",
  TRY_DELETE = "StampActionKind::TRY_DELETE",
}

export type StampState =
  | {
      readonly status: StampStateStatus.INIT;
    }
  | {
      readonly status: StampStateStatus.SUCCESS;
      readonly currStamp?: StampWithNDA;
      readonly stamps: StampWithNDA[];
    };

export type StampAction =
  | {
      readonly kind: StampActionKind.TRY_CREATE;
      readonly cid?: string;
      readonly extension?: string;
      readonly name: string;
      readonly description?: string;
    }
  | {
      readonly kind: StampActionKind.TRY_GET;
    }
  | {
      readonly kind: StampActionKind.TRY_EDIT;
      readonly targetId: string;
      readonly name?: string;
      readonly description?: string;
      readonly stamp_cid?: string;
      readonly extension?: string;
    }
  | {
      readonly kind: StampActionKind.TRY_DELETE;
      readonly targetId: string;
    };

export type StampError = never;

const smid = "STAMP_STATE_MACHINE3";
export type StampStateMachineType = StateMachine3<
  StampStateStatus,
  StampState,
  StampActionKind,
  StampAction,
  StampError
>;
export const stampStateMachine: StampStateMachineType = new StateMachine3<
  StampStateStatus,
  StampState,
  StampActionKind,
  StampAction,
  StampError
>(smid, { status: StampStateStatus.INIT }, [
  transition(
    StampStateStatus.INIT,
    StampStateStatus.SUCCESS,
    StampActionKind.TRY_GET
  ),
  transition(
    StampStateStatus.SUCCESS,
    StampStateStatus.SUCCESS,
    StampActionKind.TRY_GET
  ),
  transition(
    StampStateStatus.SUCCESS,
    StampStateStatus.SUCCESS,
    StampActionKind.TRY_CREATE
  ),
  transition(
    StampStateStatus.SUCCESS,
    StampStateStatus.SUCCESS,
    StampActionKind.TRY_EDIT
  ),
  transition(
    StampStateStatus.SUCCESS,
    StampStateStatus.SUCCESS,
    StampActionKind.TRY_DELETE
  ),
]);

/* */
export type DispatchStampAction = Dispatch<
  StateMachineAction<
    StampStateStatus,
    StampState,
    StampActionKind,
    StampAction,
    StampError
  >
>;
export default mkReducer<
  StampStateStatus,
  StampState,
  StampActionKind,
  StampAction,
  StampError
>(stampStateMachine);
export const doStampAction = (
  dispatch: DispatchStampAction,
  nextAction: StampAction,
  onResolve: () => void = () => {},
  onReject: (err: StampError | InternalError) => void = () => {}
) => {
  dispatch(stampStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doStampActionAsync = (
  dispatch: DispatchStampAction,
  nextAction: StampAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(stampStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetStamp = (dispatch: DispatchStampAction) => {
  dispatch(stampStateMachine.newResetAction());
};
