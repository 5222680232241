export default {
  ko: {
    account: {
      save: "변경사항 저장하기",
      save_xs: "저장하기",
      settings: "계정 설정",
      updated: "업데이트 날짜",
      cancel: "취소하기",
      cancel_xs: "취소",
      edit: "편집하기",
      email: "이메일",
      name: "이름",
      name_placeholder: "이름을 30자 이내로 입력하세요",
      institution: "소속",
      inst_placeholder: "소속을 50자 이내로 입력하세요",
      timezone: "타임존",
      signature: "서명",
      use_my_name: "이름으로 서명 대체",
      no_sign_text: "설정한 서명이 없습니다. 지금 서명을 등록하세요!",
      sign_with_image: "파일 업로드",
      draw_sign: "서명 그리기",
      update_sign: "변경하기",
      sign_terms: "해당 서명은 baSalt 약관에 따라 법적 효력을 가집니다.",
      confirm_mine: "본인의 서명임에 동의합니다.",
      how_signature_used: "서명을 등록하면 어디에 쓰나요?",
      sign_tooltip: `등록한 서명을 가지고 NDA에 서명을 하거나, 
      워크스페이스에 초대 받아 워크스페이스 설정에 따라 
      사인을 하게 되는 경우 사용하실 수 있습니다. 
      해당 서명은 baSalt 약관에 따라 법적 효력을 가지며, 
      본인의 서명임에 동의합니다.`,
      search_timezone: "도시를 입력하세요",
      now: "이제",
      loading: "로드 중...",
    },
  },

  en: {
    account: {
      save: "Save changes",
      save_xs: "Save",
      settings: "Account Setting",
      updated: "Updated",
      cancel: "Cancel",
      cancel_xs: "Cancel",
      edit: "Edit",
      email: "Email",
      name: "Name",
      name_placeholder: "Please write your name within 30 characters",
      institution: "Institution",
      inst_placeholder:
        "Please write down your institution name within 50 characters",
      timezone: "Time-zone",
      signature: "Signature",
      use_my_name: "Sign with my name",
      no_sign_text: "You didn't set the signature. Register it now!",
      sign_with_image: "Upload files",
      draw_sign: "Draw a signature",
      update_sign: "Change a signature",
      sign_terms:
        "The signature shall be legally valid under the baSalt Terms and Conditions.",
      confirm_mine: "I agree that this is my signature.",
      how_signature_used: "When I register my signature, where is it used for?",
      sign_tooltip: `You can sign the NDA with the registered signature.
      After being invited to a workspace, you may be required to input your signature to use it.
      The signature has legal effect in accordance with the terms and conditions of baSalt.`,
      search_timezone: "Search for your city",
      now: "Now",
      loading: "Loading...",
    },
  },
};
