import {
  GoonoArrayResponse,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "../../api/response/common";
import T from "@redwit-commons/utils/typecheck";
import {
  StampObject,
  StampObjectSchema,
  StampWithNDA,
  StampWithNDASchema,
} from "../object/stamp";

export type OCreateStamp = GoonoResponse<StampObject>;
export const OCreateStampSchema = mkResponseSchema(StampObjectSchema);
export const validateOCreateStamp =
  T.mkValidator<OCreateStamp>(OCreateStampSchema);

export type ODelteStamp = GoonoResponse<string>;
export const ODelteStampSchema = mkResponseSchema(T.string());
export const validateODelteStamp =
  T.mkValidator<ODelteStamp>(ODelteStampSchema);

export type OUpdateStamp = GoonoResponse<StampObject>;
export const OUpdateStampSchema = mkResponseSchema(StampObjectSchema);
export const validateOUpdateStamp =
  T.mkValidator<OUpdateStamp>(OUpdateStampSchema);

export type OGetAllStamp = GoonoArrayResponse<StampWithNDA>;
export const OGetAllStampSchema = mkArrayResultsSchema(StampWithNDASchema);
export const validateOGetAllStamp =
  T.mkValidator<OGetAllStamp>(OGetAllStampSchema);
