import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  ProjectAction,
  ProjectActionKind,
  ProjectErrorType,
  ProjectState,
  projectStateMachine,
  ProjectStateMachineType,
  ProjectStateStatus,
} from "../../store/reducers/project";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import Services from "@basalt-react-commons/services";
import { TokenStateStatus } from "../../store/reducers/token";
import {
  ProjectObject,
  ProjectRoleType,
} from "@basalt-commons/api/object/project";
import moment from "moment";
import { ProjectAuthType } from "@basalt-commons/api/object/user_project_map";

import {
  WorkspaceErrorType,
  WorkspaceStateStatus,
} from "../../store/reducers/workspace";
import { UserLogType } from "@basalt-commons/api/object/user_log";
import { validateIUpdateProjectAuthSharedEmail } from "@basalt-commons/global-api/request/project";
import {
  getWorkspacePlanLevel,
  WorkspacePlanType,
} from "@basalt-commons/global-api/object/workspace_plan";

export const alignProjectWithCreateTime = (
  projects: ProjectObject[]
): ProjectObject[] => {
  const sort = projects.sort((a, b) => {
    let aDate = moment(a.createdAt).toDate().getTime();
    let bDate = moment(b.createdAt).toDate().getTime();
    return aDate > bDate ? -1 : 1;
  });
  return sort;
};

type pinsTempObject = {
  pinned: string;
  index: number;
};

const { GlobalProjectService, TimelineService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.project,
    token: state.token,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ProjectContainerProps = PropsFromRedux & {
  stateMachine: ProjectStateMachineType;
};

class ProjectContainer extends ReduxStateComponent3<ProjectContainerProps> {
  static defaultProps = {
    stateMachine: projectStateMachine,
  };
  constructor(props: ProjectContainerProps) {
    super(props);
  }

  private guardWorkspaceTeamPlan() {
    const { workspace } = this.props;
    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "ProjectContainer::onAction",
        msg: "workspace statue invalid",
      });
    const { workspaceWithPlan } = workspace.state;
    if (
      workspaceWithPlan.WorkspacePlan === undefined ||
      moment(workspaceWithPlan.WorkspacePlan.end).isBefore(moment())
    )
      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: "ProjectContainer::onAction",
        msg: WorkspaceErrorType.PLAN_EXPIRED,
      });
    if (
      getWorkspacePlanLevel(workspaceWithPlan.WorkspacePlan?.plan) <
      getWorkspacePlanLevel(WorkspacePlanType.TEAM)
    )
      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: "ProjectContainer::onAction",
        msg: WorkspaceErrorType.NOT_ENOUGH_PLAN,
      });
  }

  protected async onAction(
    prevState: ProjectState,
    action: ProjectAction
  ): Promise<ProjectState> {
    const { token, workspace } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "ProjectContainer::onAction",
        msg: "not goono login",
      });

    const userToken = token.state.token;
    const userId = token.state.id;
    const userName = token.state.name;
    const userProfileCid = token.state.profile_cid;

    switch (action.kind) {
      case ProjectActionKind.TRY_GET_PROJECTS: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        const { id, workspaceName } = action;
        const selectedWorkspace = workspace.state.workspaces.find(
          (ws) => ws.name === workspaceName
        );

        if (selectedWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_GET_PROJECTS,
            msg: "can not find workspace",
          });

        const getPjs = await this.guardAwait(() =>
          GlobalProjectService.getProjects(userToken, selectedWorkspace.id)
        );

        let pinInfos: pinsTempObject[] = [];
        const sortedProjects = alignProjectWithCreateTime([...getPjs]);
        sortedProjects.map((rp, index) => {
          const userInfo = rp.Users.find((rpu) => rpu.id === userId);
          if (userInfo !== undefined) {
            if (userInfo.pinned !== undefined && userInfo.pinned.length > 0)
              pinInfos.push({ pinned: userInfo.pinned, index });
          }
        });
        const pinsInfoSort = pinInfos.sort((a, b) => {
          let aDate = moment(a.pinned).toDate().getTime();
          let bDate = moment(b.pinned).toDate().getTime();
          return aDate < bDate ? -1 : 1;
        });

        // 5개 확인하기
        const pins = pinsInfoSort.map((pi) => sortedProjects[pi.index]);

        if (id !== undefined) {
          const targetProject = getPjs.find((p) => p.id === id);
          return {
            ...prevState,
            status: ProjectStateStatus.SUCCESS,
            projects: sortedProjects,
            pinnedProjects: pins,
            selectProject: targetProject,
          };
        }

        return {
          ...prevState,
          status: ProjectStateStatus.SUCCESS,
          pinnedProjects: pins,
          projects: sortedProjects,
        };
      }
      case ProjectActionKind.TRY_CREATE_PROJECT: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_CREATE_PROJECT,
            msg: "state status unvalid",
          });

        const { name, project_type, workspaceMember, externalUser } = action;
        if (externalUser.length > 0) {
          this.guardWorkspaceTeamPlan();
        }
        const selectWorkspace = workspace.state.selectAuthWorkspace;
        if (selectWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_CREATE_PROJECT,
            msg: ProjectErrorType.WORKSPACE_NOT_SELECTED,
          });
        const newProject = await this.guardAwait(() =>
          GlobalProjectService.createProject(userToken, selectWorkspace.id, {
            name,
            project_type,
            members: workspaceMember,
          })
        );
        // Record CREATE Folder Log
        this.scheduleBackgroundTask(async () => {
          await TimelineService.createTimeline(userToken, selectWorkspace.id, {
            type: UserLogType.CREATE_FOLDER,
            UserId: userId,
            userName: userName,
            profile_cid: userProfileCid,
            WorkspaceId: selectWorkspace.id,
            workspaceName: selectWorkspace.name,
            projectName: newProject.name,
            ProjectId: newProject.id,
          });
          return false;
        });
        if (externalUser.length > 0) {
          await this.guardAwait(() =>
            GlobalProjectService.shareProjectWithMemebers(
              userToken,
              selectWorkspace.id,
              newProject.id,
              { members: externalUser }
            )
          );
          const shareEmailList = await this.guardAwait(() =>
            GlobalProjectService.getProjectShareEmailList(
              userToken,
              selectWorkspace.id,
              newProject.id
            )
          );
          return {
            ...prevState,
            shareEmailList,
            projects: [newProject, ...prevState.projects],
            selectProject: newProject,
          };
        }
        return {
          ...prevState,
          projects: [newProject, ...prevState.projects],
          selectProject: newProject,
        };
      }
      case ProjectActionKind.TRY_EDIT_PROJECT: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_EDIT_PROJECT,
            msg: "state status unvalid",
          });

        const { id } = action;
        const selectWorkspace = workspace.state.selectAuthWorkspace;
        if (selectWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_EDIT_PROJECT,
            msg: ProjectErrorType.WORKSPACE_NOT_SELECTED,
          });

        // 만약 Admin이 아니면, prevState 반환합니다
        const targetProject = prevState.projects.find((p) => p.id === id);
        if (targetProject === undefined) return prevState;
        const checkAuth = targetProject.Users.find((u) => u.id === userId);
        if (checkAuth === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_EDIT_PROJECT,
            msg: "not valid auth",
          });
        if (checkAuth.authType !== ProjectAuthType.OWNER) return prevState;
        const ret = await this.guardAwait(() =>
          GlobalProjectService.updateProject(
            userToken,
            selectWorkspace.id,
            id,
            {
              ...action,
            }
          )
        );

        const pinnedProjects = prevState.pinnedProjects.map((pj) =>
          pj.id !== ret.id ? pj : { ...pj, ...action }
        );
        const projects = prevState.projects.map((pj) =>
          pj.id !== ret.id ? pj : { ...pj, ...action }
        );

        if (
          prevState.selectProject !== undefined &&
          prevState.selectProject.id === id
        ) {
          return {
            ...prevState,
            pinnedProjects,
            projects,
            selectProject: {
              ...prevState.selectProject,
              name: action.name,
            },
          };
        }
        return {
          ...prevState,
          pinnedProjects,
          projects,
        };
      }
      case ProjectActionKind.TRY_DELETE_PROJECT: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_DELETE_PROJECT,
            msg: "state status unvalid",
          });

        const { id } = action;
        const selectWorkspace = workspace.state.selectAuthWorkspace;
        if (selectWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_DELETE_PROJECT,
            msg: ProjectErrorType.WORKSPACE_NOT_SELECTED,
          });

        // 만약 Admin이 아니면, prevState 반환합니다
        const targetProject = prevState.projects.find((p) => p.id === id);
        if (targetProject === undefined) return prevState;
        const checkAuth = targetProject.Users.find((u) => u.id === userId);
        if (
          checkAuth === undefined ||
          checkAuth.authType !== ProjectAuthType.OWNER
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_DELETE_PROJECT,
            msg: "not valid auth",
          });

        const deletedProjectId = await this.guardAwait(() =>
          GlobalProjectService.deleteProject(userToken, selectWorkspace.id, id)
        );

        const projects = prevState.projects.filter(
          (pj) => pj.id !== deletedProjectId
        );
        const pinnedProjects = prevState.projects.filter(
          (pj) => pj.id !== deletedProjectId
        );
        return {
          ...prevState,
          pinnedProjects,
          projects,
          selectProject: undefined,
          shareEmailList: undefined,
        };
      }
      case ProjectActionKind.TRY_SHARE_PROJECT_EMAILS: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_SHARE_PROJECT_EMAILS,
            msg: "prevState status is unvalid",
          });
        const { id, members } = action;
        const selectWorkspace = workspace.state.selectAuthWorkspace;
        if (selectWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_SHARE_PROJECT_EMAILS,
            msg: ProjectErrorType.WORKSPACE_NOT_SELECTED,
          });
        const prevProjects = prevState.projects;

        // target project 확인하기
        const targetProject = prevProjects.find((p) => p.id === id);
        if (targetProject === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_SHARE_PROJECT_EMAILS,
            msg: "Project does not exist",
          });

        const userAuth = targetProject.Users.find((u) => u.id === userId);

        if (
          userAuth === undefined ||
          (userAuth.authType !== ProjectAuthType.ADMIN &&
            userAuth.authType !== ProjectAuthType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_SHARE_PROJECT_EMAILS,
            msg: "Only owner and admin can share",
          });

        await this.guardAwait(() =>
          GlobalProjectService.shareProjectWithMemebers(
            userToken,
            selectWorkspace.id,
            id,
            { members }
          )
        );

        const shareEmailList = await this.guardAwait(() =>
          GlobalProjectService.getProjectShareEmailList(
            userToken,
            selectWorkspace.id,
            id
          )
        );
        return { ...prevState, shareEmailList };
      }

      case ProjectActionKind.TRY_GET_PROJECT_SHARE_EMAIL_LIST: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_GET_PROJECT_SHARE_EMAIL_LIST,
            msg: "prevState status is unvalid",
          });
        const { id } = action;
        const selectWorkspace = workspace.state.selectAuthWorkspace;
        if (selectWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_GET_PROJECT_SHARE_EMAIL_LIST,
            msg: ProjectErrorType.WORKSPACE_NOT_SELECTED,
          });
        const prevProjects = prevState.projects;

        // target project 확인하기
        const targetProject = prevProjects.find((p) => p.id === id);
        if (targetProject === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_GET_PROJECT_SHARE_EMAIL_LIST,
            msg: "Project does not exist",
          });

        const userAuth = targetProject.Users.find((u) => u.id === userId);

        if (
          userAuth === undefined ||
          (userAuth.authType !== ProjectAuthType.ADMIN &&
            userAuth.authType !== ProjectAuthType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_GET_PROJECT_SHARE_EMAIL_LIST,
            msg: "Only owner and admin can get the share email list",
          });

        const shareEmailList = await this.guardAwait(() =>
          GlobalProjectService.getProjectShareEmailList(
            userToken,
            selectWorkspace.id,
            id
          )
        );
        return { ...prevState, shareEmailList };
      }

      case ProjectActionKind.TRY_JOIN_PROJECT: {
        const { shareToken } = action;
        await this.guardAwait(() =>
          GlobalProjectService.joinProject(userToken, { shareToken })
        );

        return { status: ProjectStateStatus.INIT };
      }

      /**
       * if authType is undefined, remove the user.
       *  else, update authType of the user.
       */
      case ProjectActionKind.TRY_UDPATE_PROJECT_MEMBER_AUTH: {
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_UDPATE_PROJECT_MEMBER_AUTH,
            msg: "PrevState is unvalid",
          });

        const { targets, id } = action;

        // target project 확인하기
        const targetProject = prevState.projects.find((p) => p.id === id);
        if (targetProject === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_UDPATE_PROJECT_MEMBER_AUTH,
            msg: "Project does not exist",
          });

        // 내가 Admin인지 검사해주기
        const userAuth = targetProject.Users.find(
          (u) => u.id === userId
        )?.authType;
        if (
          userAuth === undefined ||
          (userAuth !== ProjectAuthType.ADMIN &&
            userAuth !== ProjectAuthType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_UDPATE_PROJECT_MEMBER_AUTH,
            msg: ProjectErrorType.ONLY_ADMIN_OR_OWNER,
          });

        // target users 확인하기
        targets.forEach((target) => {
          const targetUser = targetProject.Users.find(
            (u) => u.id === target.userId
          );
          if (targetUser === undefined)
            throw mkErr({
              kind: InternalErrorKind.Abort,
              loc: ProjectActionKind.TRY_UDPATE_PROJECT_MEMBER_AUTH,
              msg: "Project User does not exist",
            });
          if (targetUser.authType === ProjectAuthType.OWNER)
            throw mkErr({
              kind: InternalErrorKind.Abort,
              loc: ProjectActionKind.TRY_UDPATE_PROJECT_MEMBER_AUTH,
              msg: "Owner 계정은 수정할 수 없습니다",
            });
        });

        const updated = targets.map(async (target) => {
          const authType = target.authType;
          if (authType === undefined) {
            // authType이 없으면 share 해제 시켜줍니다
            await this.guardAwait(() =>
              GlobalProjectService.deleteJoinedUser(
                userToken,
                targetProject.id,
                { UserId: target.userId }
              )
            );
          } else {
            await this.guardAwait(() =>
              GlobalProjectService.updateMemberAuth(
                userToken,
                targetProject.id,
                { userId: target.userId, authType }
              )
            );
          }
        });
        await this.guardAwait(() => Promise.allSettled(updated));

        // TODO: remove nullable Relation on Workspace
        const { WorkspaceId } = targetProject;
        if (WorkspaceId) {
          // projects update해주기
          const getPjs = await this.guardAwait(() =>
            GlobalProjectService.getProjects(userToken, WorkspaceId)
          );
          return {
            ...prevState,
            projects: getPjs,
            selectProject: getPjs.find((pj) => pj.id === targetProject.id),
          };
        }
        return prevState;
      }

      case ProjectActionKind.TRY_UDPATE_PROJECT_OWNER: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_UDPATE_PROJECT_OWNER,
            msg: "PrevState is unvalid",
          });
        const selectWorkspace = workspace.state.selectAuthWorkspace;
        if (selectWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_GET_PROJECT_SHARE_EMAIL_LIST,
            msg: ProjectErrorType.WORKSPACE_NOT_SELECTED,
          });

        const { targetUserId, projectId } = action;

        // target project 확인하기
        const targetProject = prevState.projects.find(
          (p) => p.id === projectId
        );
        if (targetProject === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_UDPATE_PROJECT_OWNER,
            msg: "Project does not exist",
          });

        // 내가 Owner인지 검사해주기
        const userAuth = targetProject.Users.find(
          (u) => u.id === userId
        )?.authType;
        if (userAuth === undefined || userAuth !== ProjectAuthType.OWNER)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_UDPATE_PROJECT_OWNER,
            msg: ProjectErrorType.ONLY_OWNER,
          });

        // target user 확인하기
        const targetUser = targetProject.Users.find(
          (u) => u.id === targetUserId
        );
        if (targetUser === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_UDPATE_PROJECT_OWNER,
            msg: "Project User does not exist",
          });

        let newProject: ProjectObject = targetProject;
        await this.guardAwait(() =>
          GlobalProjectService.updateProjectOwner(
            userToken,
            selectWorkspace.id,
            targetProject.id,
            {
              userId: targetUserId,
            }
          )
        );
        const newUsers = newProject.Users.map((u) =>
          u.id === targetUserId
            ? { ...u, authType: ProjectAuthType.OWNER }
            : u.id === userId
            ? { ...u, authType: ProjectAuthType.ADMIN }
            : u
        );
        newProject = { ...newProject, Users: newUsers };

        // projects update해주기
        const projects = prevState.projects.map((p) =>
          p.id === projectId ? newProject : p
        );

        return {
          ...prevState,
          projects,
          selectProject: newProject,
        };
      }

      case ProjectActionKind.TRY_PIN_PROJECT: {
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_PIN_PROJECT,
            msg: "state status unvalid",
          });

        // pin 과 unipin에 대한 action 처리 모두를 합니다
        // pinned Project 인지 체크
        const targetProject = action.project;
        const isPinned = prevState.pinnedProjects.find(
          (p) => p.id === targetProject.id
        );
        if (isPinned !== undefined) {
          // pin 된 프로젝트 였음 (핀 해제 진행)
          const new_obj = await this.guardAwait(() =>
            GlobalProjectService.pinProject(userToken, targetProject.id, {
              pinned: undefined,
            })
          );
          const new_pinned = prevState.pinnedProjects.filter(
            (p) => p.id !== targetProject.id
          );

          // project object 교체
          const projects = prevState.projects.map((pj) =>
            pj.id === targetProject.id ? new_obj : pj
          );
          const new_selectAuth =
            prevState.selectProject?.id === new_obj?.id
              ? new_obj
              : prevState.selectProject;

          return {
            ...prevState,
            pinnedProjects: new_pinned,
            selectProject: new_selectAuth,
            projects,
          };
        } else {
          // pin 진행
          if (prevState.pinnedProjects.length === 5) return prevState; // 5개 이상 할 수 없음
          const now = moment().toISOString();
          const new_obj = await this.guardAwait(() =>
            GlobalProjectService.pinProject(userToken, targetProject.id, {
              pinned: now,
            })
          );

          // project object 교체
          const projects = prevState.projects.map((pj) =>
            pj.id === targetProject.id ? new_obj : pj
          );

          const new_selectAuth =
            prevState.selectProject?.id === new_obj?.id
              ? new_obj
              : prevState.selectProject;

          return {
            ...prevState,
            pinnedProjects: [...prevState.pinnedProjects, targetProject],
            selectProject: new_selectAuth,
            projects,
          };
        }
      }
      case ProjectActionKind.TRY_SET_NDA: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        const { nda, id } = action;
        if (workspace.state.selectAuthWorkspace === undefined) return prevState;
        if (prevState.status !== ProjectStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_SET_NDA,
            msg: "prevstate status invalid",
          });
        }
        // target project 확인하기
        const targetProject = prevState.projects.find((p) => p.id === id);
        if (targetProject === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_SET_NDA,
            msg: "Project does not exist",
          });

        // 내가 Owner인지 검사해주기
        const userAuth = targetProject.Users.find(
          (u) => u.id === userId
        )?.authType;
        if (userAuth === undefined || userAuth !== ProjectAuthType.OWNER)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_UDPATE_PROJECT_OWNER,
            msg: ProjectErrorType.ONLY_OWNER,
          });

        const workspace_id = workspace.state.selectAuthWorkspace.id;
        const ret = await this.guardAwait(() =>
          GlobalProjectService.setDefaultNDA(userToken, workspace_id, id, {
            NdaId: nda.id,
          })
        );
        const projects = prevState.projects.map((pj) =>
          pj.id === ret.id ? ret : pj
        );
        const pinned = prevState.pinnedProjects.map((pj) =>
          pj.id === ret.id ? ret : pj
        );
        return {
          ...prevState,
          projects,
          pinnedProjects: pinned,
          selectProject: ret,
        };
      }

      case ProjectActionKind.TRY_UPDATE_PROJECT_TYPE: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        const { id } = action;
        if (workspace.state.selectAuthWorkspace === undefined) return prevState;
        if (prevState.status !== ProjectStateStatus.SUCCESS) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_UPDATE_PROJECT_TYPE,
            msg: "prevstate status invalid",
          });
        }
        // target project 확인하기
        const targetProject = prevState.projects.find((p) => p.id === id);
        if (targetProject === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_UPDATE_PROJECT_TYPE,
            msg: "Project does not exist",
          });

        // 내가 Owner인지 검사해주기
        const userAuth = targetProject.Users.find(
          (u) => u.id === userId
        )?.authType;
        if (userAuth === undefined || userAuth !== ProjectAuthType.OWNER)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_UDPATE_PROJECT_OWNER,
            msg: ProjectErrorType.ONLY_OWNER,
          });

        const selectWorkspace = workspace.state.selectAuthWorkspace;
        const ret =
          targetProject.project_type === ProjectRoleType.PUBLIC
            ? await this.guardAwait(() =>
                GlobalProjectService.updateProject(
                  userToken,
                  selectWorkspace.id,
                  id,
                  {
                    name: targetProject.name,
                    project_type: ProjectRoleType.SHARE,
                  }
                )
              )
            : await this.guardAwait(() =>
                GlobalProjectService.updateProjectPublic(
                  userToken,
                  selectWorkspace.id,
                  id
                )
              );

        const projects = prevState.projects.map((pj) =>
          pj.id === ret.id ? ret : pj
        );
        const pinned = prevState.pinnedProjects.map((pj) =>
          pj.id === ret.id ? ret : pj
        );

        return {
          ...prevState,
          projects,
          pinnedProjects: pinned,
          selectProject: ret,
        };
      }
      case ProjectActionKind.TRY_RESEND_PROJECT_SHARE_EMAIL: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_RESEND_PROJECT_SHARE_EMAIL,
            msg: "prevState status is unvalid",
          });

        const { project_id } = action;
        const selectWorkspace = workspace.state.selectAuthWorkspace;
        if (selectWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_RESEND_PROJECT_SHARE_EMAIL,
            msg: ProjectErrorType.WORKSPACE_NOT_SELECTED,
          });
        const prevProjects = prevState.projects;

        // target project 확인하기
        const targetProject = prevProjects.find((p) => p.id === project_id);
        if (targetProject === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_RESEND_PROJECT_SHARE_EMAIL,
            msg: "Project does not exist",
          });

        const userAuth = targetProject.Users.find((u) => u.id === userId);

        if (
          userAuth === undefined ||
          (userAuth.authType !== ProjectAuthType.ADMIN &&
            userAuth.authType !== ProjectAuthType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_RESEND_PROJECT_SHARE_EMAIL,
            msg: "Only owner and admin can share",
          });

        await this.guardAwait(() =>
          GlobalProjectService.resendProjectShareEmail(
            userToken,
            selectWorkspace.id,
            project_id,
            action.args
          )
        );
        return prevState;
      }
      case ProjectActionKind.TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH: {
        if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "ProjectContainer::onAction",
            msg: "workspace statue invalid",
          });
        if (prevState.status !== ProjectStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH,
            msg: "prevState status is unvalid",
          });

        const { project_id } = action;
        const selectWorkspace = workspace.state.selectAuthWorkspace;
        if (selectWorkspace === undefined)
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH,
            msg: ProjectErrorType.WORKSPACE_NOT_SELECTED,
          });
        const prevProjects = prevState.projects;

        // target project 확인하기
        const targetProject = prevProjects.find((p) => p.id === project_id);
        if (targetProject === undefined)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: ProjectActionKind.TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH,
            msg: "Project does not exist",
          });

        const userAuth = targetProject.Users.find((u) => u.id === userId);

        if (
          userAuth === undefined ||
          (userAuth.authType !== ProjectAuthType.ADMIN &&
            userAuth.authType !== ProjectAuthType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: ProjectActionKind.TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH,
            msg: "Only owner and admin can update invitations",
          });

        const { args } = action;
        const updatePromise = args.map(async (arg) => {
          if (
            prevState.shareEmailList?.find((e) => e.id === arg.logId) ===
            undefined
          ) {
            throw mkErr({
              kind: InternalErrorKind.Abort,
              loc: ProjectActionKind.TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH,
              msg: "The invitation does not exist",
            });
          }
          if (arg.authType === undefined) {
            await this.guardAwait(() =>
              GlobalProjectService.deleteProjectSharedEmail(
                userToken,
                selectWorkspace.id,
                project_id,
                arg
              )
            );
          } else {
            const validArg = validateIUpdateProjectAuthSharedEmail(arg);
            await this.guardAwait(() =>
              GlobalProjectService.updateProjectAuthSharedEmail(
                userToken,
                selectWorkspace.id,
                project_id,
                validArg
              )
            );
          }
          return;
        });
        await this.guardAwait(() => Promise.allSettled(updatePromise));
        const shareEmailList = await this.guardAwait(() =>
          GlobalProjectService.getProjectShareEmailList(
            userToken,
            selectWorkspace.id,
            targetProject.id
          )
        );
        return { ...prevState, shareEmailList };
      }
    }
  }
}

export default connector(ProjectContainer);
