import T from "@redwit-commons/utils/typecheck";
import {
  mkResponseSchema,
  GoonoResponse,
  GoonoArrayResponse,
  mkArrayResultsSchema,
} from "../../api/response/common";
import { RuleObject, RuleObjectSchema } from "../object/rule";

export type OCreateRule = GoonoResponse<RuleObject>;
export type OUpdateRule = GoonoResponse<RuleObject>;
export type ODeleteRule = GoonoResponse<string>;
export type OGetRuleList = GoonoArrayResponse<RuleObject>;

export const OCreateRuleSchema = mkResponseSchema(RuleObjectSchema);
export const OUpdateRuleSchema = mkResponseSchema(RuleObjectSchema);
export const ODeleteRuleSchema = mkResponseSchema(T.string());
export const OGetRuleListSchema = mkArrayResultsSchema(RuleObjectSchema);

export const validateOCreateRule =
  T.mkValidator<OCreateRule>(OCreateRuleSchema);
export const validateOUpdateRule =
  T.mkValidator<OUpdateRule>(OUpdateRuleSchema);
export const validateODeleteRule =
  T.mkValidator<ODeleteRule>(ODeleteRuleSchema);
export const validateOGetRuleList =
  T.mkValidator<OGetRuleList>(OGetRuleListSchema);
