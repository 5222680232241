import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "../../api/object/access_time";
import { UserObject, UserObjectSchema } from "../../api/object/user";

export type RuleContractPureCore = {
  readonly UserId: string;
  readonly RuleId: string;
  readonly id: string;
  /** 서명 당시의 유저 정보 */
  readonly name: string;
  readonly email: string;
  /** 서명 당시의 유저 서명 정보 */
  readonly sign_cid: string;
  readonly sign_extension: string;
  /** 사인 정보 및 인증서 PDF의 파일 정보 */
  readonly file_name: string;
  readonly cid: string;
  readonly extension: string;
};

export type RuleContractPureObject = RuleContractPureCore & AccessTime;
export type RuleContractWithUser = RuleContractPureObject & {
  User: UserObject;
};

export const RuleContractPureObjectSchema = withAccessTime()
  .addField("UserId", T.string())
  .addField("RuleId", T.string())
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("email", T.string())
  .addField("sign_cid", T.string())
  .addField("sign_extension", T.string())
  .addField("file_name", T.string())
  .addField("cid", T.string())
  .addField("extension", T.string());

export const RuleContractWithUserSchema =
  RuleContractPureObjectSchema.clone().addField(
    "User",
    UserObjectSchema.clone()
  );

export const extractRuleContractPureObject =
  T.mkObjectExtractor<RuleContractPureObject>(RuleContractPureObjectSchema);
export const validateRuleContractPureObject =
  T.mkValidator<RuleContractPureObject>(RuleContractPureObjectSchema);

export const extractRuleContractWithUser =
  T.mkObjectExtractor<RuleContractWithUser>(RuleContractWithUserSchema);
export const validateRuleContractWithUser = T.mkValidator<RuleContractWithUser>(
  RuleContractWithUserSchema
);
