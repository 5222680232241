import common from "./common";
import screen from "./screens";
import error from "./error";
import modal from "./modal";
import snackbar from "./snackbar";
import items from "./items";

import { mkTranslateExport } from "@redwit-react-commons/hooks/translate";

const translate = {
  ko: {
    ...common.ko,
    ...screen.ko,
    ...error.ko,
    ...modal.ko,
    ...snackbar.ko,
    ...items.ko,
  },
  en: {
    ...common.en,
    ...screen.en,
    ...error.en,
    ...modal.en,
    ...snackbar.en,
    ...items.en,
  },
};

export default mkTranslateExport(translate, "ko", "basalt");
