import { doAPIPostRequest, doAPIGetRequest, doAPIDeleteRequest } from "./api";
import { ProjectObject } from "@basalt-commons/api/object/project";
import {
  IDeleteJoinedUserProject,
  IDeleteProjectSharedEmail,
  IResendProjectShare,
  ISetDefaultNDA,
  IUpdateJoinedUserProject,
  IUpdateOwner,
  IUpdateProjectAuthSharedEmail,
} from "@basalt-commons/global-api/request/project";
import {
  validateODeleteProjectSharedEmail,
  validateOGetProjectShareEmailList,
  validateOSetDefaultNDA,
  validateOUpdateOwner,
  validateOUpdateProjectAuthSharedEmail,
  validateOUpdatePublic,
} from "@basalt-commons/global-api/response/project";
import {
  validateOGetProjects,
  validateOCreateProject,
  validateODeleteProject,
  validateOUpdateProject,
  validateOShareProject,
  validateOJoinProject,
  validateOShareProjectLink,
  validateODeleteJoinedUser,
  validateOUpdateJoinedUser,
  validateOPinnedProject,
} from "@basalt-commons/api/response/project";
import { ICreateBasaltProject } from "@basalt-commons/global-api/request/project";
import {
  IUpdateProject,
  IShareProject,
  IJoinProject,
  IShareProjectMembers,
  IPinnedProject,
} from "@basalt-commons/api/request/project";
import { TokenLogWithUserObject } from "@basalt-commons/global-api/object/token_log";

/**
 * 프로젝트 리스트를 받아오는 API Service
 * @param token string, 구노 토큰
 * @param workspaceId
 * @returns Promise<ProjectObject[]>
 */
const getProjects = async (
  token: string,
  workspaceId: string
): Promise<ProjectObject[]> => {
  const obj = await doAPIGetRequest(`global/${workspaceId}/projects`, token);
  const ret = validateOGetProjects(obj);
  return ret.response.results;
};
/**
 * 프로젝트 생성 API Service
 * @param token string, 구노 토큰
 * @param args ICreateBasaltProject
 * @param workspaceId
 * @returns Promise<ProjectObejct>
 */
const createProject = async (
  token: string,
  workspaceId: string,
  args: ICreateBasaltProject
): Promise<ProjectObject> => {
  const ret = validateOCreateProject(
    await doAPIPostRequest(`global/${workspaceId}/project`, token, args)
  );
  return ret.response;
};
/**
 * 프로젝트 수정 API Service
 * @param token string, 구노 토큰
 * @param projectId
 * @param workspaceId
 * @param args IUpdateProject
 * @returns Promise<ProjectObejct>
 */
const updateProject = async (
  token: string,
  workspaceId: string,
  projectID: string,
  args: IUpdateProject
): Promise<ProjectObject> => {
  const ret = validateOUpdateProject(
    await doAPIPostRequest(
      `global/${workspaceId}/projects/${projectID}/update`,
      token,
      args
    )
  );
  return ret.response;
};
/**
 * 프로젝트 삭제 API Service
 * @param token string, 구노 토큰
 * @param projectID
 * @param workspaceId
 * @returns Promise<string>
 */
const deleteProject = async (
  token: string,
  workspaceId: string,
  projectID: string
): Promise<string> => {
  const ret = validateODeleteProject(
    await doAPIDeleteRequest(
      `global/${workspaceId}/projects/${projectID}`,
      token
    )
  );
  return ret.response;
};
/**
 * 프로젝트 공유 API Service
 * @param token string, 구노 토큰
 * @param projectID
 * @param workspaceId
 * @param args IShareProject
 * @returns shareToken
 */
const shareProject = async (
  token: string,
  workspaceId: string,
  projectID: string,
  args: IShareProject
): Promise<string> => {
  const ret = validateOShareProject(
    await doAPIPostRequest(
      `global/${workspaceId}/projects/${projectID}/share`,
      token,
      args
    )
  );
  return ret.response.token;
};
/**
 * 프로젝트 공유 API Service
 * @param token string, 구노 토큰
 * @param workspaceId
 * @param projectID
 * @param args IShareProject
 * @returns shareLink
 */
const shareProjectLink = async (
  token: string,
  workspaceId: string,
  projectID: string,
  args: IShareProject
): Promise<string> => {
  const ret = validateOShareProjectLink(
    await doAPIPostRequest(
      `global/${workspaceId}/projects/${projectID}/share/link`,
      token,
      args
    )
  );
  return ret.response.link;
};
/**
 * 프로젝트 공유 API Service
 * @param token string, 구노 토큰
 * @param workspaceId
 * @param projectID
 * @param args IShareProjectMembers
 */
const shareProjectWithMemebers = async (
  token: string,
  workspaceId: string,
  projectID: string,
  args: IShareProjectMembers
) => {
  await doAPIPostRequest(
    `global/${workspaceId}/projects/${projectID}/share/members`,
    token,
    args
  );
  return;
};

/**
 * 발송한 폴더 초대 메일 리스트를 요청하는 API Service
 * @param token string, baSalt 토큰
 * @param workspaceId
 * @param projectID
 * @returns Promise<TokenLogWithUserObject[]>
 */
const getProjectShareEmailList = async (
  token: string,
  workspaceId: string,
  projectID: string
): Promise<TokenLogWithUserObject[]> => {
  const obj = await doAPIGetRequest(
    `global/${workspaceId}/projects/${projectID}/share/emails`,
    token
  );
  const ret = validateOGetProjectShareEmailList(obj);
  return ret.response.results;
};

/**
 * 프로젝트 참여 API Service
 * @param token string, 구노 토큰
 * @param args IJoinProject
 * @returns ProjectObject
 */
const joinProject = async (
  token: string,
  args: IJoinProject
): Promise<ProjectObject> => {
  const ret = validateOJoinProject(
    await doAPIPostRequest(`global/projects/join`, token, args)
  );
  return ret.response;
};

const updateMemberAuth = async (
  token: string,
  projectId: string,
  args: IUpdateJoinedUserProject
): Promise<string> => {
  const ret = validateOUpdateJoinedUser(
    await doAPIPostRequest(`/global/projects/${projectId}/update`, token, args)
  );
  return ret.response;
};

const updateProjectOwner = async (
  token: string,
  workspaceId: string,
  projectId: string,
  args: IUpdateOwner
): Promise<ProjectObject> => {
  const ret = validateOUpdateOwner(
    await doAPIPostRequest(
      `/global/${workspaceId}/projects/${projectId}/update/owner`,
      token,
      args
    )
  );
  return ret.response;
};

const deleteJoinedUser = async (
  token: string,
  projectId: string,
  args: IDeleteJoinedUserProject
): Promise<string> => {
  const ret = validateODeleteJoinedUser(
    await doAPIDeleteRequest(`global/projects/${projectId}/joined`, token, args)
  );
  return ret.response;
};

const setDefaultNDA = async (
  token: string,
  workspaceId: string,
  projectId: string,
  args: ISetDefaultNDA
): Promise<ProjectObject> => {
  const ret = validateOSetDefaultNDA(
    await doAPIPostRequest(
      `/global/${workspaceId}/projects/${projectId}/nda`,
      token,
      args
    )
  );
  return ret.response;
};

const updateProjectPublic = async (
  token: string,
  workspaceId: string,
  projectId: string
): Promise<ProjectObject> => {
  const ret = validateOUpdatePublic(
    await doAPIGetRequest(
      `/global/${workspaceId}/projects/${projectId}/update/public`,
      token
    )
  );
  return ret.response;
};

/**
 * 폴더 초대 메일 재발송을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * ADMIN 권한 이상의 유저만이 요청할 수 있음
 */
const resendProjectShareEmail = async (
  userToken: string,
  workspaceId: string,
  projectId: string,
  args: IResendProjectShare
) => {
  await doAPIPostRequest(
    `/global/${workspaceId}/projects/${projectId}/resend`,
    userToken,
    args
  );
  return;
};

/**
 * 폴더 초대중인 이메일의 권한 변경을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * Admin 권한 이상의 유저만이 요청할 수 있음
 */
const updateProjectAuthSharedEmail = async (
  userToken: string,
  workspaceId: string,
  projectId: string,
  args: IUpdateProjectAuthSharedEmail
): Promise<TokenLogWithUserObject> => {
  const obj = await doAPIPostRequest(
    `/global/${workspaceId}/projects/${projectId}/update/sharedEmail/auth`,
    userToken,
    args
  );
  const ret = validateOUpdateProjectAuthSharedEmail(obj);
  return ret.response;
};

/**
 * 폴더 초대 내역 삭제를 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * OWNER 유저만이 요청할 수 있음
 */
const deleteProjectSharedEmail = async (
  userToken: string,
  workspaceId: string,
  projectId: string,
  args: IDeleteProjectSharedEmail
): Promise<string> => {
  const obj = await doAPIDeleteRequest(
    `/global/${workspaceId}/projects/${projectId}/sharedEmail`,
    userToken,
    args
  );
  const ret = validateODeleteProjectSharedEmail(obj);
  return ret.response;
};

const pinProject = async (
  token: string,
  projectID: string,
  args: IPinnedProject
): Promise<ProjectObject> => {
  const ret = validateOPinnedProject(
    await doAPIPostRequest(`basalt/projects/${projectID}/pinned`, token, args)
  );
  return ret.response;
};

export default {
  getProjects,
  createProject,
  deleteProject,
  updateProject,
  shareProject,
  shareProjectLink,
  shareProjectWithMemebers,
  getProjectShareEmailList,
  joinProject,
  updateMemberAuth,
  updateProjectOwner,
  deleteJoinedUser,
  setDefaultNDA,
  updateProjectPublic,
  resendProjectShareEmail,
  updateProjectAuthSharedEmail,
  deleteProjectSharedEmail,
  pinProject,
};
