import T from "@redwit-commons/utils/typecheck";
import { AccessTime, addAccessTime } from "./access_time";

export interface PaymentCoreWithoutEtc {
  readonly id: string;
  readonly seats: number;
  readonly months: number;
  readonly pg?: string;
  readonly pay_method: string;
  readonly imp_uid: string;
  readonly status: "ready" | "paid" | "cancelled" | "failed";
  readonly UserId: string;
  readonly WorkspaceId?: string;
}

export type PaymentCore = PaymentCoreWithoutEtc & { etc: string };
export type PaymentDBObjectWOEtc = PaymentCoreWithoutEtc & AccessTime;

export type VBankDescription = {
  vbank_code: string;
  vbank_num: string;
  vbank_name: string;
  vbank_holder: string;
  vbank_date: string;
  name: string;
  buyer_name: string;
  buyer_email: string;
};

export type CardDescription = {
  card_name: string;
  card_quota: number;
  name: string;
  buyer_name: string;
  buyer_email: string;
  paid_amount: number;
  paid_at: string;
  receipt_url: string;
};

export const addCardDescription = (from: T): T => {
  return from
    .addField("name", T.string())
    .addField("buyer_name", T.string())
    .addField("buyer_email", T.string())
    .addField("card_name", T.string())
    .addField("card_quota", T.number())
    .addField("paid_amount", T.number())
    .addField("paid_at", T.string())
    .addField("receipt_url", T.string());
};

export const addVBankDescription = (from: T): T => {
  return from
    .addField("vbank_code", T.string())
    .addField("vbank_num", T.string())
    .addField("vbank_name", T.string())
    .addField("vbank_holder", T.string())
    .addField("vbank_date", T.string())
    .addField("name", T.string())
    .addField("buyer_name", T.string())
    .addField("buyer_email", T.string());
};

export const validateVBankInfo = T.mkValidator<VBankDescription>(
  addVBankDescription(T.object())
);
export const validateCardInfo = T.mkValidator<CardDescription>(
  addCardDescription(T.object())
);

export type PaymentDBObject = PaymentCore & AccessTime;

export type vBankPaymentObject = PaymentDBObjectWOEtc & VBankDescription;
export type CardPaymentObject = PaymentDBObjectWOEtc & CardDescription;

export const PaymentCoreWithoutEtcSchema = T.object()
  .addField("id", T.string())
  .addField("pg", T.string(), false)
  .addField("seats", T.number())
  .addField("months", T.number())
  .addField("pay_method", T.string())
  .addField("imp_uid", T.string())
  .addField(
    "status",
    T.string().withEnum(["ready", "paid", "cancelled", "failed"])
  )
  .addField("UserId", T.string())
  .addField("WorkspaceId", T.string(), false);

export const PaymentDBObjectWOEtcSchema = addAccessTime(
  PaymentCoreWithoutEtcSchema.clone()
);

export const PaymentCoreSchema = PaymentCoreWithoutEtcSchema.clone().addField(
  "etc",
  T.string()
);
export const PaymentDBObjectSchema = addAccessTime(PaymentCoreSchema.clone());
export const extractPaymentDBObject = T.mkObjectExtractor<PaymentDBObject>(
  PaymentDBObjectSchema
);

export const extractPaymentDBObjectWOEtc =
  T.mkObjectExtractor<PaymentDBObjectWOEtc>(PaymentDBObjectWOEtcSchema);

export const vBankPaymentObjectSchema = addVBankDescription(
  PaymentDBObjectWOEtcSchema.clone()
);
export const validateVBankPaymentObject = T.mkValidator<vBankPaymentObject>(
  vBankPaymentObjectSchema
);

export const CardPaymentObjectSchema = addCardDescription(
  PaymentDBObjectWOEtcSchema.clone()
);
export const validateCardPaymentObject = T.mkValidator<CardPaymentObject>(
  CardPaymentObjectSchema
);

export const refineCardPayment = (rdbPayment: PaymentDBObject) => {
  const etc_string = rdbPayment.etc;
  // const vbankWOetc = extractPaymentDBObjectWOEtc( rdbPayment );
  const CardInfo = validateCardInfo(JSON.parse(etc_string));
  const CardPayment = validateCardPaymentObject({ ...rdbPayment, ...CardInfo });
  return CardPayment;
};

export const refineVBankPayment = (rdbPayment: PaymentDBObject) => {
  const etc_string = rdbPayment.etc;
  // const vbankWOetc = extractPaymentDBObjectWOEtc( rdbPayment );
  const vBankInfo = validateVBankInfo(JSON.parse(etc_string));
  const vBankPayment = validateVBankPaymentObject({
    ...rdbPayment,
    ...vBankInfo,
  });
  return vBankPayment;
};
