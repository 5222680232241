import React from "react";
import { BaseGridMode, OnboardingType } from "@src/store/reducers/basegrid";
import { WithStyles, withStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FutureComponent } from "@redwit-react-commons/template/FutureComponent";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { WorkspaceStateStatus } from "../../store/reducers/workspace";
import {
  ProjectActionKind,
  doProjectActionAsync,
} from "../../store/reducers/project";
import {
  doModalAction,
  ModalActionKind,
  ModalType,
} from "../../store/reducers/modal";
import {
  doNDAAction,
  doNDAActionAsync,
  NDAActionKind,
  NDAStateStatus,
} from "../../store/reducers/nda";
import {
  doSnackbarAction,
  SnackbarType,
  SnackbarActionKind,
  doSnackbarActionAsync,
} from "../../store/reducers/snackbar";
import {
  doStampAction,
  doStampActionAsync,
  StampActionKind,
  StampStateStatus,
} from "../../store/reducers/stamp";
import { StampWithNDA } from "@basalt-commons/global-api/object/stamp";
import { TokenStateStatus } from "../../store/reducers/token";
import moment from "moment";
import {
  NdaObject,
  NdaObjectWithContract,
} from "@basalt-commons/global-api/object/nda";
import translate from "../../utils/translate";
import styles from "./styles/NDAScreen";
import { ContractObject } from "@basalt-commons/global-api/object/nda_contract";
import { DocumentObject } from "@basalt-commons/global-api/object/document";
import { doFileActionAsync, FileActionKind } from "../../store/reducers/file";
import {
  getWorkspaceRoleLevel,
  WorkspaceRoleType,
} from "@basalt-commons/global-api/object/user_workspace_map";
import {
  getWorkspacePlanLevel,
  WorkspacePlanType,
} from "@basalt-commons/global-api/object/workspace_plan";
import DragDropGrid from "../../utils/templates/DragDropGrid";
import * as Hangul from "hangul-js";
import { imageExtensionList } from "@basalt-commons/api/request/note";
import {
  BaseGridActionKind,
  doBaseGridAction,
} from "@src/store/reducers/basegrid";
import { fetchWorkspace, handleDidMountError } from "@src/utils/fetch-utils";
import ScreenTitleView from "./ScreenTitleView";
import ScreenMobileView from "./ScreenMobileView";
import TabNDAView from "./TabNDAView";
import TabStampView from "./TabStampView";
import TabSignedView from "./TabSignedView";
import { ndaDescriptionValidator } from "../../utils/input-validator";

export const NDA_TAB_KIND = {
  STAMP: "stamp",
  NDA: "nda",
  SIGNED: "signed",
} as const;

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    workspace: state.workspace,
    project: state.project,
    note: state.note,
    nda: state.nda,
    stamp: state.stamp,
    ipfs: state.ipfs,
    persist: state.persist,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {
  workspace_name: string;
};
type NDAScreenProps = PropsFromRedux &
  RouteComponentProps<Props> &
  WithStyles<typeof styles>;

export type TabState =
  | typeof NDA_TAB_KIND.NDA
  | typeof NDA_TAB_KIND.SIGNED
  | typeof NDA_TAB_KIND.STAMP;

type NDAScreenState = {
  tab: TabState;
  searchNDA: string;
  searchStamp: string;
  searchSigned: string;
};

class NDAScreen extends FutureComponent<NDAScreenProps, NDAScreenState> {
  constructor(props: NDAScreenProps) {
    super(props);
    const search = new URLSearchParams(window.location.search);
    const tab = search.get("tab") as TabState;
    this.state = {
      tab: tab,
      searchNDA: "",
      searchSigned: "",
      searchStamp: "",
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.props.token.state.status !== TokenStateStatus.SUCCESS) {
      doBaseGridAction(this.props.dispatch, {
        kind: BaseGridActionKind.TRY_UPDATE_SCREEN_MODE,
        screenMode: {
          mode: BaseGridMode.FAILED,
          code: 401,
        },
      });
      return;
    }
    doBaseGridAction(this.props.dispatch, {
      kind: BaseGridActionKind.TRY_UPDATE_SCREEN_MODE,
      screenMode: { mode: BaseGridMode.SUCCESS },
      sidebar: true,
      onboarding: OnboardingType.NDA,
    });
    try {
      await fetchWorkspace(
        this.props.dispatch,
        this.props.match.params.workspace_name
      );

      if (this.hasNoAuth(WorkspaceRoleType.MEMBER)) {
        this.props.history.goBack();
        return;
      }
      if (this.props.workspace.state.status !== WorkspaceStateStatus.SUCCESS) {
        doBaseGridAction(this.props.dispatch, {
          kind: BaseGridActionKind.TRY_UPDATE_SCREEN_MODE,
          screenMode: { mode: BaseGridMode.FAILED, code: 404 },
        });
        return;
      }
      await doProjectActionAsync(this.props.dispatch, {
        kind: ProjectActionKind.TRY_GET_PROJECTS,
        workspaceName: this.props.workspace.state.selectAuthWorkspace.name,
      });
      await doNDAActionAsync(this.props.dispatch, {
        kind: NDAActionKind.TRY_GET_NDAS,
      });
      await doNDAActionAsync(this.props.dispatch, {
        kind: NDAActionKind.TRY_GET_CONTRACT_LIST,
      });
      await doStampActionAsync(this.props.dispatch, {
        kind: StampActionKind.TRY_GET,
      });
    } catch (err) {
      const errorMode = handleDidMountError(err);
      doBaseGridAction(this.props.dispatch, {
        kind: BaseGridActionKind.TRY_UPDATE_SCREEN_MODE,
        screenMode: errorMode,
      });
    }
  }

  componentDidUpdate(
    _prevProps?: Readonly<NDAScreenProps>,
    _prevState?: Readonly<NDAScreenState>
  ): void {
    const search = new URLSearchParams(window.location.search);
    const tab = search.get("tab");
    if (tab && _prevState?.tab != tab) this.setState({ tab: tab as TabState });
  }

  async onDropFiles(files: File[] | FileList | null) {
    // common early returns
    if (files === null || files.length !== 1) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.ndas.upload_err,
      });
      return;
    }
    switch (this.state.tab) {
      case "nda": {
        void (await this.onUploadNDA(files));
        break;
      }
      case "stamp": {
        void (await this.onUploadStamp(files));
        break;
      }
    }
  }

  /**
   * @requires WorkspaceAuthType.ADMIN or WorkspaceAuthType.MEMBER 이상
   */
  hasAuth(role: WorkspaceRoleType) {
    const { workspace, token } = this.props;
    const userId =
      token.state.status === TokenStateStatus.SUCCESS && token.state.id;
    const selectedWorkspace =
      workspace.state.status === WorkspaceStateStatus.SUCCESS
        ? workspace.state.selectAuthWorkspace
        : undefined;
    const userAuth = selectedWorkspace?.Users.find((u) => u.id === userId);
    return (
      getWorkspaceRoleLevel(userAuth?.roleType) >= getWorkspaceRoleLevel(role)
    );
  }

  hasNoAuth(role: WorkspaceRoleType) {
    if (!this.hasAuth(role)) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.snackbar.not_authorized,
      });
      return true;
    }
    return false;
  }

  getTimeZoneAndFormat() {
    const { token } = this.props;
    const time_zone =
      token.state.status === TokenStateStatus.SUCCESS &&
      token.state.time_zone !== undefined
        ? token.state.time_zone
        : moment.tz.guess(true);
    // const format = getFormat(time_zone, separator ? separator : "/");
    const format = "MMM D, YYYY h:mm A";
    return { time_zone, format };
  }

  getWorkspaceWithNdaContract() {
    const { nda } = this.props;
    return nda.state.status === NDAStateStatus.SUCCESS
      ? nda.state.workspaceWithNdaContract
      : undefined;
  }

  getSearchWord() {
    return this.state.tab === "nda"
      ? this.state.searchNDA
      : this.state.tab === "stamp"
      ? this.state.searchStamp
      : this.state.searchSigned;
  }

  onInputSearchWord(input: string) {
    if (this.state.tab === "nda") {
      this.setState({
        searchNDA: input,
      });
      return;
    } else if (this.state.tab === "stamp") {
      this.setState({ searchStamp: input });
    } else {
      this.setState({ searchSigned: input });
    }
  }

  /**
   * 1. NdaId가 있을 경우, 특정 NDA의 서명 내역을 다운로드
   * 2. NdaId가 없을 경우, 워크스페이스 내의 NDA 서명 내역을 다운로드
   * @param NdaId
   */
  async onDownloadAllNDAContract(NdaId?: string) {
    const { workspace } = this.props;
    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS) return;
    try {
      const workspaceId = workspace.state.selectAuthWorkspace.id;
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.LOADING,
        msg: translate.snackbar.download,
      });
      await doNDAActionAsync(this.props.dispatch, {
        kind: NDAActionKind.TRY_DOWNLOAD_ALL_CONTRACT,
        workspaceId,
        NdaId,
      });

      if (this.props.nda.state.status === NDAStateStatus.SUCCESS) {
        window.open(this.props.nda.state.filePath, "_blank");
        await doSnackbarActionAsync(this.props.dispatch, {
          kind: SnackbarActionKind.TRY_OPEN,
          type: SnackbarType.CONFIRM,
          msg: translate.snackbar.download_success,
        });
        return;
      }
    } catch (_err) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.snackbar.download_fail,
      });
      return;
    }
  }

  async onDownloadNDAContract(contract: ContractObject) {
    try {
      await doNDAActionAsync(this.props.dispatch, {
        kind: NDAActionKind.TRY_DOWNLOAD_CONTRACT,
        contract,
      });
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.CONFIRM,
        msg: translate.snackbar.download_success,
      });
    } catch {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.snackbar.download_fail,
      });
    }
  }

  containsSearchQuery(value: string, query: string) {
    return Hangul.search(value.toLowerCase(), query.toLowerCase()) >= 0;
  }

  getFilteredNDAs() {
    const { searchSigned } = this.state;
    const searchQuery = searchSigned.trim();
    const workspaceWithNdaContract = this.getWorkspaceWithNdaContract();
    const filteredNDAs = workspaceWithNdaContract
      ? workspaceWithNdaContract.Ndas.filter(
          (nda) =>
            this.containsSearchQuery(
              nda.file_name.normalize(),
              searchQuery.normalize()
            ) && nda.NdaContracts.length > 0
        )
      : [];
    return filteredNDAs;
  }

  historyPush(link: string) {
    this.props.history.push(
      `/${this.props.match.params.workspace_name}/${link}`
    );
    return;
  }

  /**
   * @param nda
   * @requires WorkspaceAuthType.Member 이상
   */
  onDeleteNDA(nda: NdaObject) {
    if (this.hasNoAuth(WorkspaceRoleType.MEMBER)) return;
    doModalAction(this.props.dispatch, {
      kind: ModalActionKind.TRY_OPEN,
      type: ModalType.DELETE,
      bodyMsg: `${translate.modal.delete.delete_file_pre} ${nda.file_name} ${translate.modal.delete.delete_file_post}`,
      bodySubMsg: translate.modal.delete.sub,
      title: translate.modal.delete.delete_nda,
      onDelete: () => {
        doNDAAction(
          this.props.dispatch,
          { kind: NDAActionKind.TRY_DELETE_NDA, nda_id: nda.id },
          () => {
            doSnackbarAction(this.props.dispatch, {
              kind: SnackbarActionKind.TRY_OPEN,
              type: SnackbarType.CONFIRM,
              msg: translate.snackbar.file.delete.success,
            });
          }
        );
      },
    });
  }

  onOpenConnectedFiles(
    docs: DocumentObject[],
    docName: string,
    contracts: ContractObject[]
  ) {
    doModalAction(this.props.dispatch, {
      kind: ModalActionKind.TRY_OPEN,
      type: ModalType.CONNECTED_FILES,
      docs: docs,
      docName: docName,
      contracts: contracts,
    });
  }

  /**
   * @param files
   * @requires WorkspaceAuthType.ADMIN 이상
   */
  async onUploadNDA(files: File[] | FileList | null) {
    if (this.hasNoAuth(WorkspaceRoleType.MEMBER)) return;
    if (files === null || files.length !== 1) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.ndas.upload_err,
      });
      return;
    }

    if (!files[0].name.endsWith(".pdf")) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.ndas.only_pdf,
      });
      return;
    }

    const file = files[0];

    await doFileActionAsync(this.props.dispatch, {
      kind: FileActionKind.TRY_FETCH,
      files: [file],
    });
    this.historyPush(`nda/custom/create`);
    return;
  }

  /**
   * @param nda
   * @requires WorkspaceAuthType.ADMIN
   */
  onChangeDescription(nda: NdaObject) {
    if (this.hasNoAuth(WorkspaceRoleType.ADMIN)) return;
    doModalAction(this.props.dispatch, {
      kind: ModalActionKind.TRY_OPEN,
      type: ModalType.TEXT_INPUT,
      title: nda.file_name,
      bodyMsg: translate.modal.nda_description.sub_title,
      confirmMsg: translate.modal.save,
      placeholder: translate.modal.nda_description.placeholder,
      multiline: true,
      errorValidator: (text: string) => ndaDescriptionValidator(text),
      onSubmit: (text: string) => {
        doNDAAction(
          this.props.dispatch,
          {
            kind: NDAActionKind.TRY_UPDATE_NDA,
            nda_id: nda.id,
            description: text,
          },
          () => {
            doSnackbarAction(this.props.dispatch, {
              kind: SnackbarActionKind.TRY_OPEN,
              type: SnackbarType.CONFIRM,
              msg: translate.snackbar.save_success,
            });
          },
          (_err) => {
            doSnackbarAction(this.props.dispatch, {
              kind: SnackbarActionKind.TRY_OPEN,
              type: SnackbarType.ALERT,
              msg: translate.snackbar.save_fail,
            });
          }
        );
      },
    });
    return;
  }

  onViewHistory(stamp: StampWithNDA, ndas: NdaObjectWithContract[]) {
    const { time_zone, format } = this.getTimeZoneAndFormat();

    const docs = ndas
      .map((nda) =>
        nda.Documents.filter(
          (doc) => doc.nda_contractor_sign_cid === stamp.stamp_cid
        )
      )
      .flat();
    const contracts = ndas
      .map((nda) =>
        nda.NdaContracts.filter((c) => docs.find((d) => d.id === c.DocumentId))
      )
      .flat();
    const docContracts = contracts.map((c) => {
      return { doc: docs.find((d) => d.id === c.DocumentId), contract: c };
    });
    doModalAction(this.props.dispatch, {
      kind: ModalActionKind.TRY_OPEN,
      type: ModalType.VIEW_HISTORY,
      stampName: stamp.name,
      onDownload: async (contract) => {
        await this.onDownloadNDAContract(contract);
      },
      docContracts: docContracts,
      timezone: time_zone,
      format: format,
    });
  }

  /**
   * @requires WorkspaceAuthType.ADMIN 이상
   */
  async onUploadStamp(files: File[] | FileList | null) {
    if (this.hasNoAuth(WorkspaceRoleType.ADMIN)) return;

    if (files === null || files.length !== 1) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.ndas.upload_err,
      });
      return;
    }

    const ext = files[0].name.split(".").pop() ?? "";

    if (!ext || !imageExtensionList.includes(ext)) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.ndas.image_format_err,
      });
      return;
    }

    try {
      // 기존에 혹시 파일이 존재한다면 제거.
      await doFileActionAsync(this.props.dispatch, {
        kind: FileActionKind.TRY_RESET,
      });

      await doFileActionAsync(this.props.dispatch, {
        kind: FileActionKind.TRY_FETCH,
        files: [files[0]],
      });

      this.historyPush(`ndas/stamp/create?mode=create`);
    } catch (err) {
      doSnackbarAction(this.props.dispatch, {
        kind: SnackbarActionKind.TRY_OPEN,
        type: SnackbarType.ALERT,
        msg: translate.company_signature.upload_err,
      });
    }
  }

  onEditStamp(stamp: StampWithNDA) {
    if (this.props.stamp.state.status === StampStateStatus.SUCCESS)
      this.historyPush(`ndas/stamp/create?mode=edit&stampId=${stamp.id}`);
    return;
  }

  /**
   * @param stamp
   * @requires WorkspaceAuthType.ADMIN 이상
   */
  onDeleteStamp(stamp: StampWithNDA) {
    if (this.hasNoAuth(WorkspaceRoleType.ADMIN)) return;
    doModalAction(this.props.dispatch, {
      kind: ModalActionKind.TRY_OPEN,
      type: ModalType.DELETE,
      // bodyMsg: stamp.name ?? "undefined stamp name",
      bodyMsg: `${translate.modal.delete.delete_file_pre} ${stamp.name} ${translate.modal.delete.delete_file_post}`,
      bodySubMsg: translate.modal.delete.sub,
      title: translate.modal.delete.delete_sign,
      onDelete: () => {
        doStampAction(
          this.props.dispatch,
          { kind: StampActionKind.TRY_DELETE, targetId: stamp.id },
          () => {
            doSnackbarAction(this.props.dispatch, {
              kind: SnackbarActionKind.TRY_OPEN,
              type: SnackbarType.CONFIRM,
              msg: translate.snackbar.sign_delete_success,
            });
          },
          () => {
            doSnackbarAction(this.props.dispatch, {
              kind: SnackbarActionKind.TRY_OPEN,
              type: SnackbarType.ALERT,
              msg: translate.snackbar.delete_fail,
            });
          }
        );
      },
    });
    return;
  }

  get ndaStatus(): "loading" | "fetched" {
    const { nda, match } = this.props;
    return nda.state.status === NDAStateStatus.INIT ||
      nda.state.workspaceWithNdaContract?.name !== match.params.workspace_name
      ? "loading"
      : "fetched";
  }

  get stampStatus(): "loading" | "fetched" {
    const { stamp } = this.props;
    return stamp.state.status === StampStateStatus.INIT ? "loading" : "fetched";
  }
  onShareSignedContract(contract: ContractObject) {
    // const { workspace } = this.props;
    // const selectWorkspace =
    //   workspace.state.status === WorkspaceStateStatus.SUCCESS
    //     ? workspace.state.selectAuthWorkspace
    //     : undefined;
    this.props.history.push(
      `/${this.props.match.params.workspace_name}/email/contract/${contract.id}`
    );
    // doModalAction(this.props.dispatch, {
    //   kind: ModalActionKind.TRY_OPEN,
    //   type: ModalType.EMAIL_SEND,
    //   workspace: selectWorkspace,
    //   onSubmit: async (emails) => {
    //     try {
    //       await doNDAActionAsync(this.props.dispatch, {
    //         kind: NDAActionKind.TRY_SHARE_CONTRACT,
    //         contractId: contract.id,
    //         emails,
    //       });
    //       doSnackbarAction(this.props.dispatch, {
    //         kind: SnackbarActionKind.TRY_OPEN,
    //         type: SnackbarType.CONFIRM,
    //         msg: translate.snackbar.contract_share_success,
    //       });
    //     } catch {
    //       doSnackbarAction(this.props.dispatch, {
    //         kind: SnackbarActionKind.TRY_OPEN,
    //         type: SnackbarType.CONFIRM,
    //         msg: translate.snackbar.contract_share_fail,
    //       });
    //     }
    //   },
    // });
  }

  renderTitle() {
    const { workspace } = this.props;
    const plan =
      workspace.state.status === WorkspaceStateStatus.SUCCESS
        ? workspace.state.workspaceWithPlan.WorkspacePlan?.plan
        : undefined;
    return (
      <ScreenTitleView
        tab={this.state.tab}
        searchWord={this.getSearchWord()}
        disabled={
          getWorkspacePlanLevel(plan) <
            getWorkspacePlanLevel(WorkspacePlanType.INDIVIDUAL) ||
          !this.hasAuth(WorkspaceRoleType.ADMIN) ||
          (this.state.tab == "signed" && this.getFilteredNDAs().length <= 0)
        }
        onInputSearchWord={(input) => this.onInputSearchWord(input)}
        onClickStampAddBtn={() => {
          this.historyPush(`ndas/stamp/create?mode=create`);
        }}
        onClickSignedDownloadBtn={async () =>
          await this.onDownloadAllNDAContract()
        }
        ndaStatus={this.ndaStatus}
      />
    );
  }

  renderNDAs(ndas: NdaObject[]) {
    const { searchNDA } = this.state;
    const { time_zone, format } = this.getTimeZoneAndFormat();
    const workspaceWithNdaContract = this.getWorkspaceWithNdaContract();
    const searchQuery = searchNDA.trim();
    let filteredNDAs =
      searchQuery.length > 0
        ? ndas.filter((nda) =>
            this.containsSearchQuery(
              nda.file_name.normalize(),
              searchQuery.normalize()
            )
          )
        : ndas;

    return (
      <TabNDAView
        filteredNDAs={filteredNDAs}
        workspaceWithNdaContract={workspaceWithNdaContract}
        searchQuery={searchQuery}
        hasAuth={this.hasAuth(WorkspaceRoleType.ADMIN)}
        time_zone={time_zone}
        format={format}
        ndaStatus={this.ndaStatus}
        onDownloadAllContract={(contracts: ContractObject[]) =>
          async (ndaId: string) => {
            if (!contracts.length) {
              doSnackbarAction(this.props.dispatch, {
                kind: SnackbarActionKind.TRY_OPEN,
                type: SnackbarType.WARNING,
                msg: translate.ndas.download_err,
              });
              return;
            }
            await this.onDownloadAllNDAContract(ndaId);
          }}
        onClickNDA={(nda_id: string) => this.historyPush(`nda/${nda_id}`)}
        onOpenConnectedFiles={(
          docs: DocumentObject[],
          docName: string,
          contracts: ContractObject[]
        ) => this.onOpenConnectedFiles(docs, docName, contracts)}
        onChangeDescription={(nda: NdaObject) => this.onChangeDescription(nda)}
        onDeleteNDA={(nda: NdaObject) => this.onDeleteNDA(nda)}
      />
    );
  }

  renderStamp() {
    const { stamp } = this.props;
    const { time_zone, format } = this.getTimeZoneAndFormat();
    const workspaceWithNdaContract = this.getWorkspaceWithNdaContract();
    const ndas = workspaceWithNdaContract?.Ndas ?? [];
    let stamps =
      stamp.state.status === StampStateStatus.SUCCESS ? stamp.state.stamps : [];
    const searchStamp = this.state.searchStamp.trim();
    if (searchStamp.length > 0) {
      stamps = stamps.filter((stamp) => {
        return stamp.name
          ? this.containsSearchQuery(
              stamp.name.normalize(),
              searchStamp.normalize()
            )
          : false;
      });
    }

    return (
      <TabStampView
        ndas={ndas}
        stamps={stamps}
        stampStatus={this.stampStatus}
        searchQuery={searchStamp}
        hasAuth={this.hasAuth(WorkspaceRoleType.ADMIN)}
        time_zone={time_zone}
        format={format}
        onEditStamp={(stamp: StampWithNDA) => this.onEditStamp(stamp)}
        onDeleteStamp={(stamp: StampWithNDA) => this.onDeleteStamp(stamp)}
        onViewHistory={(stamp: StampWithNDA, ndas: NdaObjectWithContract[]) =>
          this.onViewHistory(stamp, ndas)
        }
      />
    );
  }

  renderSigned() {
    const { time_zone, format } = this.getTimeZoneAndFormat();
    const searchSigned = this.state.searchSigned.trim();
    const filteredNDAs = this.getFilteredNDAs();

    return (
      <TabSignedView
        filteredNDAs={filteredNDAs}
        searchQuery={searchSigned}
        time_zone={time_zone}
        format={format}
        ndaStatus={this.ndaStatus}
        onDownloadNDAContract={async (contract: ContractObject) =>
          await this.onDownloadNDAContract(contract)
        }
        onShareSignedContract={(contract: ContractObject) =>
          this.onShareSignedContract(contract)
        }
        onClickContract={(contract_id: string) =>
          this.historyPush(`preview/contract/${contract_id}`)
        }
      />
    );
  }

  renderFileInput() {
    return (
      <>
        <input
          accept="application/pdf"
          style={{ display: "none" }}
          id="upload-nda-file"
          type="file"
          multiple={false}
          onChange={async (evt: React.ChangeEvent<HTMLInputElement>) => {
            evt.preventDefault();
            void (await this.onUploadNDA(evt.target.files));
          }}
        />
        <input
          accept="image/png, image/jpeg, image/jpg, image/heic, image/heif, image/svg"
          style={{ display: "none" }}
          id="upload-stamp-file"
          type="file"
          multiple={false}
          onChange={async (evt: React.ChangeEvent<HTMLInputElement>) => {
            evt.preventDefault();
            void (await this.onUploadStamp(evt.target.files));
          }}
        />
      </>
    );
  }

  render() {
    const { classes } = this.props;
    const ndas =
      this.props.nda.state.status === NDAStateStatus.SUCCESS
        ? this.props.nda.state.ndas
        : [];

    return (
      <DragDropGrid
        container
        className={classes.root}
        ignore={this.state.tab === "signed"}
        filesUpload={async (files: File[]) => {
          await this.onDropFiles(files);
        }}
      >
        {this.renderTitle()}
        {this.state.tab === "nda"
          ? this.renderNDAs(ndas)
          : this.state.tab === "stamp"
          ? this.renderStamp()
          : this.state.tab === "signed"
          ? this.renderSigned()
          : ""}
        <ScreenMobileView />
        {this.renderFileInput()}
      </DragDropGrid>
    );
  }
}

export default connector(withStyles(styles)(withRouter(NDAScreen)));
