export default {
  ko: {
    snackbar: {
      callback: {
        auth_mail_success: "인증이 완료되었습니다! 로그인 해주세요",
        auth_err: "공유된 권한으로 참여할 수 없습니다",
        over_memeber: "해당 워크스페이스의 수용 인원이 꽉 찼습니다",
        individual_plan: "개인 플랜 워크스페이스에는 참여가 불가능합니다",
        already_folder: "이미 참여한 폴더 입니다",
        already_ws: "이미 참여한 워크스페이스 입니다",
        error_token: "공유된 토큰을 찾을 수 없습니다. 새 토큰이 필요합니다",
        no_ws: "공유된 폴더의 워크스페이스를 찾을 수 없습니다",
        no_folder: "공유된 폴더를 찾을 수 없습니다",
        need_verify: "인증되지 않은 이메일 입니다",
      },
      drag_here: "여기에 드래그 하시면 파일이 업로드 됩니다",
      create_rule_success: "새로운 워크스페이스 룰이 생성되었습니다",
      create_rule_fail:
        "워크스페이스 룰 생성에 실패하였습니다. 다시 시도해주세요.",
      rule_sign_success: "워크스페이스 룰이 체결되었습니다",
      rule_already_signed: "워크스페이스 룰이 이미 체결되었습니다",
      info_update_fail: "정보 업데이트에 실패하였습니다",
      upload_fail: "업로드에 실패했습니다",
      link_update_fail: "링크를 업데이트 할 수 없습니다",
      link_expired: "만료된 링크입니다.",
      info_update_success: "정보가 정상적으로 업데이트 되었습니다",
      rule_update_success: "룰 정상적으로 업데이트 되었습니다",
      rule_update_fail: "룰 업데이트에 실패하였습니다",
      rule_delete_success: "룰이 삭제되었습니다",
      folder_create_fail: "폴더 생성에 실패하였습니다",
      folder_create_success: "폴더가 정상적으로 생성되었습니다",
      delete_fail: "삭제에 실패하였습니다",
      user_delete_success: "삭제되었습니다",
      not_authorized: "권한이 없습니다",
      download: "다운로드 중",
      download_success: "다운로드가 완료되었습니다",
      download_fail: "다운로드에 실패하였습니다. 다시 시도해주세요",
      link_copy_success: "복사 되었습니다.",
      deletion: "삭제 중입니다",
      folder_delete_success: "삭제되었습니다",
      folder_public_fail: "폴더가 공개되기 실패하였습니다",
      folder_public_success: "폴더 성공적으로 공개되었습니다",
      folder_shared_fail: "폴더 공유 폴더로 변환하기 실패하였습니다",
      folder_shared_success: "폴더가 공유 폴더로 변환되었습니다",
      progress: "진행 중",
      workspace_present:
        "이미 존재하는 워크스페이스 이름입니다. 다른 이름으로 진행해주세요.",
      invite_success: "초대 메일이 발송되었습니다",
      owner_delete_warning: "Owner 사용자를 삭제할 수 없습니다",
      authority_change_success: "권한이 변경되었습니다",
      authority_change_warning:
        "관리자만 다른 사용자의 권한을 편집할 수 있습니다",
      authority_change_fail: "변경에 실패하였습니다",
      contract_sign_warning: "서명 정보를 모두 입력하셔야 합니다",
      validation_expired: "유효기간이 만료되었습니다",
      mail_valid_expired:
        "유효기간이 만료되었습니다. 인증 메일을 다시 신청해주세요",
      upload_sign_signature: "서명을 업로드 해 주세요",
      link_invalid: "유효하지 않은 URL입니다",
      password_change_success: "비밀번호가 변경되었습니다",
      password_change_fail: "비밀번호 변경에 실패하였습니다",
      upload_success: "업로드 되었습니다",
      contract_share_success: "서명 문서 공유 메일을 발송했습니다",
      contract_share_fail: "이메일 전송에 실패하였습니다",
      folder_pinned_max: "최대 5개의 폴더를 즐겨찾기 할 수 있습니다",
      network_error:
        "네트워크 에러가 발생했습니다. 네트워크 환경을 확인해주세요",
      workspace_create_success: "워크스페이스 정상적으로 생성되었습니다",
      workspace_create_fail: "워크스페이스 생성에 실패하였습니다",
      workspace_name_warning: "사용할 수 없는 이름입니다",
      access_when_logged: "로그인 상태에서 액세스할 수 없습니다",
      need_sign_in: "로그인해 주세요",
      sign_in_check: "로그인했는지 확인하고 다시 시도해주세요",
      token_invalid: "유효하지 않은 토큰입니다.",
      loading: "로딩중...",
      try_again: "잠시 후에 다시 시도해주세요",
      error_warning: "문제가 발생하였습니다. 다시 시도해주세요",
      inquiry: "문의가 접수 중입니다",
      inquiry_send_success: "해당 내용으로 문의 접수가 완료되었습니다",
      inquiry_name: "이름을 입력해주세요",
      inquiry_contact: "연락처를 입력해주세요",
      inquiry_email: "이메일을 입력해주세요",
      inquiry_message: "메시지를 입력해주세요",
      require_one_letter_at_least: "이름을 1글자 이상 입력해주세요",
      inquiry_privacy_warning:
        "개인정보 보호 정책에 동의하셔야 발송 가능합니다",
      sign_in_error: {
        resent_mail:
          "인증 메일이 확인되지 않아, 메일을 재발송하였습니다. 메일함을 확인해주세요",
        wrong_pw: "비밀번호가 틀렸습니다. 다시 입력해주세요.",
        check_mail:
          "인증 메일 확인을 하지 않으셨습니다. 메일함을 확인해주세요.",
        not_registered: "가입되지 않은 계정입니다.",
        email_user:
          "해당 계정은 (Email)로 가입된 계정입니다. 해당 플랫폼에서 시도해주세요",
        goole_user:
          "해당 계정은 (Google)로 가입된 계정입니다. 해당 플랫폼에서 시도해주세요",
        unknown: "회원가입에 실패했습니다",
      },

      hashtag_edit_success: "해시태그가 변경되었습니다",
      hashtag_edit_fail: "해시태그 변경에 실패하였습니다",
      invite_email_success: "초대메일을 발송하였습니다.",
      invite_email_fail: "초대메일 발송에 실패하였습니다.",
      sign_delete_success: "서명이 삭제되었습니다",
      team: {
        success_create: "팀이 생성되었습니다",
        edit_success: "수정 내역이 반영되었습니다.",
        fail_create: "생성에 실패하였습니다. 다시 시도해주세요",
        edit_fail: "팀 수정에 실패하였습니다. 다시 시도해주세요.",
      },
      tag: {
        add_fail: "한 글자 이상 작성해주세요",
        format_warning: "해시태그는 최대 10개까지 가능합니다",
        length_warning: "길이가 깁니다",
        edit_success: "해시태그가 변경되었습니다",
        edit_fail: "해시태그 변경에 실패하였습니다",
      },
      expired_plan: "플랜의 유효 기간이 만료됐습니다",
      workspace: {
        not_present: "이 워크스페이스는 존재하지 않습니다",
        share: {
          fail: {
            invite_self: "본인을 초대할 수 없습니다",
            above_team_plan: "Team 플랜 이상부터 사용할 수 있는 기능입니다",
            above_admin: "관리자만 초대할 수 있습니다",
            unknown: "초대를 실패하였습니다. 다시 시도해주세요",
            exist_email: "이미 워크스페이스에 참여한 이메일 입니다",
          },
          success: "초대 메일이 발송되었습니다",
        },
        join: {
          fail: {
            unknown: "워크스페이스 가입하기에 실패하였습니다",
            over_guest: "해당 워크스페이스의 수용 게스트 인원이 꽉 찼습니다",
            invalid_role: "공유된 역할로 참여할 수 없습니다",
          },
          success: "워크스페이스가 공유되었습니다",
        },
        signout: {
          fail: {
            reject_owner: "구성원만 워크스페이스에서 로그아웃할 수 있습니다",
          },
        },
        copy_url: {
          success: "클립보드에 워크스페이스 url이 복사되었습니다!",
        },
        knock: {
          success:
            "관리자에게 확인 메일을 발송하였습니다. 승인 시 워크스페이스에 초대가 됩니다",
          success_xs:
            "관리자에게 확인 메일을 발송하였습니다.\n승인 시 워크스페이스에 초대가 됩니다",
          fail: {
            unknown: "워크스페이스 노크에 실패하였습니다. 다시 시도해주세요.",
            unknown_xs:
              "워크스페이스 노크에 실패하였습니다.\n다시 시도해주세요.",
            not_found_admin: "관리자를 찾을 수 없습니다",
            already_knock: "이미 노크를 요청한 워크스페이스 입니다",
          },
        },
        update: {
          success: "워크스페이스 설정이 변경되었습니다",
          fail: {
            empty: "워크스페이스 이름이 비어 있습니다",
            duplicate:
              "이미 있는 워크스페이스 이름입니다. 다른 이름으로 변경해주세요",
            invalid_name:
              "워크스페이스 이름이 올바르지 않습니다. 다른 이름으로 변경해주세요",
          },
        },
      },
      folder: {
        not_present: "이 폴더는 존재하지 않습니다",
        share: {
          fail: {
            invite_self: "본인을 초대할 수 없습니다",
            unknown: "이메일 전송에 실패하였습니다",
            exist_email: "이미 폴더에 참여한 이메일 입니다",
          },
          success: {
            send_email: "폴더 공유 메일을 발송했습니다",
            auto_join: "폴더에 멤버를 추가했습니다",
          },
        },
        join: {
          fail: {
            unknown: "폴더 가입하기에 실패하였습니다",
          },
          success: "폴더가 공유되었습니다",
        },
        owner_chage: {
          success: "폴더 관리자가 변경되었습니다",
          fail: "폴더 관리자가 변경에 실패하였습니다",
        },
        edit: {
          success: "프로젝트를 수정하였습니다",
          fail: "프로젝트를 수정에 실패하였습니다",
        },
      },
      comment: {
        add_fail: "한 글자 이상 작성해주세요",
      },
      send_email_self_fail: "본인에게 공유할 수 없습니다",
      get_plan_fail: "워크스페이스 현재 플랜을 가져오는데 실패했습니다",
      plan_not_support_feature: "현재 플랜에서는 지원되지 않는 기능입니다",
      link: {
        share_email: {
          success: "메일이 발송됐습니다",
          fail: "메일 발송에 실패했습니다",
        },
        create: {
          success: "링크가 생성됐습니다",
          fail: "링크 생성에 실패하였습니다",
        },
      },
      purchase: {
        loading: "결제 진행중...",
        deny_access: "잘못된 접근입니다",
        use_same_plan: "이미 같은 플랜을 사용하고 있습니다",
        success: "성공적으로 구매되었습니다!",
        fail: "워크스페이스 플랜을 구입하기에 실패하였습니다. 다시 시도해주세요.",
        fetch_fail: "최신 정보를 불러오는데 실패했습니다. 새로고침 해주세요",
        not_support_test_period: "테스트 기간 동안 지원하지 않는 기능입니다",
        error: {
          iamport: "결제 진행중 오류가 발생했습니다. 다시 시도해주세요",
          over_guest: "게스트 멤버를 줄이거나 구매 인원을 늘려주세요",
          over_seat: "구매 인원이 현재 워크스페이스 멤버보다 적습니다",
          not_found_card: "기본 카드를 설정해주세요",
        },
      },
      file: {
        delete: {
          success: "파일이 삭제되었습니다",
          fail: "파일 삭제에 실패했습니다",
        },
        upload: {
          fail: {
            format: "지원되지 않는 파일 형식입니다",
          },
        },
      },
      card: {
        register: {
          success: "카드 등록이 완료됐습니다",
          fail: "카드 등록에 실패했습니다",
        },
        set_default: {
          fail: "기본 카드 설정에 실패했습니다",
        },
        delete: {
          fail: "카드 삭제에 실패했습니다",
        },
      },
      download_timeline_pdf: {
        process: "다운로드 중..",
        success: "다운로드가 완료 되었습니다",
        fail: "다운로드에 실패했습니다. 잠시 후에 다시 시도해주세요",
      },
    },
  },

  en: {
    snackbar: {
      callback: {
        auth_mail_success: "Verification done! Please log in",
        auth_err: "You can't join with this authority.",
        over_memeber: "The workspace is full of members.",
        individual_plan:
          "You can't participate in the individual plan workspace.",
        already_folder: "You already joined this folder",
        already_ws: "You already joined this workspace",
        error_token: "Cannot find the shared token.",
        no_ws: "Unable to find the workspace of the shared folder",
        no_folder: "Unable to find the shared folder",
        need_verify: "It's an unauthenticated email",
      },
      drag_here: "Drag the file here to upload it.",
      create_rule_success: "A new workspace rule has been created.",
      create_rule_fail: "Failed to create a workspace rule. Please try again.",
      rule_sign_success: "Workspace rule has been successfully signed.",
      rule_already_signed: "You have already signed this rule",
      info_update_fail: "Failed to update information",
      upload_fail: "Failed to upload",
      link_update_fail: "Cannot update the link",
      link_expired: "The link has expired.",
      info_update_success: "Information has been successfully updated",
      rule_update_success: "The rule has been successfully updated",
      rule_update_fail: "Failed to update the rule",
      rule_delete_success: "Rule has been successfully deleted",
      folder_create_fail: "Failed to create folder",
      folder_create_success: "Folder has been successfully created",
      delete_fail: "Failed to delete",
      user_delete_success: "User has been successfully deleted",
      not_authorized: "Not authorized",
      download: "Downloading",
      download_success: "File has been successfully downloaded",
      download_fail: "Download failed. Please try again.",
      link_copy_success: "Link has been copied successfully",
      deletion: "Deleting",
      folder_delete_success: "Folder has been successfully deleted",
      folder_public_fail: "Folder failed to be converted into public",
      folder_public_success:
        "Folder has been successfully converted into public",
      folder_shared_fail: "Folder failed to be converted into shared",
      folder_shared_success:
        "Folder has been successfully converted into shared",
      progress: "in progress",
      workspace_present: "This workspace name is already in use",
      invite_success: "Invitation email has been successfully sent",
      owner_delete_warning: "You cannot delete the Owner user",
      authority_change_success: "Permissions have been successfully changed",
      authority_change_warning:
        "Only admin user can edit other users' authority",
      authority_change_fail: "Failed to change",
      workspace_name_warning: "This workspace name cannot be used",
      contract_sign_warning: "You must enter all signature information",
      validation_expired: "Validation date expired",
      mail_valid_expired:
        "Validation date expired. Please re-apply for the authentication mail",
      upload_sign_signature: "Please upload the sign signature",
      link_invalid: "Invalid link",
      password_change_success: "Your password has been successfully changed",
      password_change_fail: "Failed to change the password",
      more_than_one: "Please write more than one letter",
      upload_success: "Upload complete",
      contract_share_success: "Contract share mail has been sent",
      contract_share_fail: "Failed to send an email",
      folder_pinned_max: "You can bookmark up to 5 folders",
      network_error:
        "A network error has occurred. Please check the network environment",
      workspace_create_success: "Workspace has been successfully created",
      workspace_create_fail: "Failed to create a workspace",
      access_when_logged: "Cannot access in login state",
      need_sign_in: "Please sign in",
      sign_in_check: "Please make sure you are signed in and try again",
      token_invalid: "Token is invalid",
      loading: "Loading...",
      try_again: "Please try again later",
      error_warning: "An error has occurred. Please try again",
      inquiry: "Inquiry is underway",
      inquiry_send_success: "Your inquiry has been received",
      inquiry_name: "Please enter your name",
      inquiry_contact: "Please enter your contact information",
      inquiry_email: "Please enter your email",
      inquiry_message: "Please enter the message",
      require_one_letter_at_least:
        "Please one more letter to write the stamp name",
      inquiry_privacy_warning:
        "You must agree to the privacy policy before you can send it",
      sign_in_error: {
        resent_mail:
          "We resent an authenticate the email. Please check your mailbox",
        wrong_pw: "Wrong password",
        check_mail:
          "You have not authenticated your email. Please check your mailbox. (also spam mail-box)",
        not_registered: "This email is not registered",
        email_user:
          "This account is registered with Email. Please try on that platform",
        goole_user:
          "This account is registered with Google. Please try on that platform",
        unknown: "Failed to register",
      },
      hashtag_edit_success: "Hashtags has been successfully modified",
      hashtag_edit_fail: "Failed to modify hashtags",
      invite_email_success: "Invitation mail has been sent.",
      invite_email_fail: "Failed to send an invitation mail.",
      sign_delete_success: "Signature has been successfully deleted",
      team: {
        success_create: "Team has been created",
        edit_success: "The revision history has been reflected",
        fail_create: "Team Creation failed. Please try again.",
        edit_fail: "Failed to edit the team. Please try again",
      },
      tag: {
        add_fail: "Please write more than one letter",
        more_than_one: "Please write more than one letter",
        format_warning: "You can add up to 10 hashtags",
        length_warning: "The tag length is too long",
        edit_success: "Hashtags has been successfully modified",
        edit_fail: "Failed to modify hashtags",
      },
      expired_plan: "Plan date expired",
      workspace: {
        not_present: "This workspace does not exist",
        share: {
          fail: {
            invite_self: "You cannot invite yourself",
            above_team_plan:
              "This feature is available from the team plan or higher.",
            above_admin: "Only admin user can send invitations",
            unknown: "Invitation failed. Please try again",
            exist_email: "This email already participated in the workspace",
          },
          success: "Invitation mail has been sent",
        },
        join: {
          fail: {
            unknown: "Failed to join workspace",
            over_guest: "The workspace is full of guests",
            invalid_role: "You can't participate in shared roles",
          },
          success: "The workspace has been shared",
        },
        signout: {
          fail: {
            reject_owner: "Only members can log out of the workspace",
          },
        },
        copy_url: {
          success: "Your workspace url has been copied in the clipboard!",
        },
        knock: {
          success: "Successfully sent confirmation mail to the administrator.",
          success_xs:
            "Successfully sent confirmation mail to the administrator.",
          fail: {
            unknown: "Workspace knock failed. Please try again.",
            unknown_xs: "Workspace knock failed.\nPlease try again.",
            not_found_admin: "Cannot find the administrator",
            already_knock: "You've already knocked on this workspace",
          },
        },
        update: {
          success: "The workspace setting has been changed",
          fail: {
            empty: "The workspace name is empty",
            duplicate:
              "This name already exists. Please change it to a different name",
            invalid_name:
              "The workspace name is not valid. Please change it to a different name",
          },
        },
      },
      folder: {
        not_present: "This folder does not exist",
        share: {
          fail: {
            invite_self: "You cannot invite yourself",
            unknown: "Failed to send an email",
            exist_email: "This email already participated in the folder",
          },
          success: {
            send_email: "Folder share mail has been sent",
            auto_join: "A member has been added to the folder",
          },
        },
        join: {
          fail: {
            unknown: "Failed to join the folder",
          },
          success: "The folder has been shared",
        },
        owner_chage: {
          success: "Folder's owner has been successfully changed",
          fail: "Failed to change folder's owner",
        },
        edit: {
          success: "Folder has been successfully modified",
          fail: "Failed to modify the folder",
        },
      },
      comment: {
        add_fail: "Please write more than one letter",
      },
      send_email_self_fail: "You cannot share it yourself",
      get_plan_fail: "Failed to get the current plan of workspace",
      plan_not_support_feature:
        "This feature is not supported by the current plan",
      link: {
        share_email: {
          success: "An email has been sent",
          fail: "Failed to send an email",
        },
        create: {
          success: "Link is successfully created",
          fail: "Failed to create link",
        },
      },
      purchase: {
        loading: "Payment in progress...",
        deny_access: "The wrong approach",
        use_same_plan: "You are already using this plan",
        success: "The purchase has been successfully made!",
        fail: "Purchase of the Workspace Plan failed. Please try again.",
        fetch_fail:
          "Failed to fetch the latest data. Please refresh your browser",
        not_support_test_period: "It's not supported during the test period",
        error: {
          iamport: "An error occurred during payment. Please try again",
          over_guest: "Reduce the guests or increase the seats",
          over_seat:
            "The number of seats is less than the current workspace members",
          not_found_card: "Please set default card",
        },
      },
      file: {
        delete: {
          success: "File has been successfully deleted",
          fail: "Failed to delete the file",
        },
        upload: {
          fail: {
            format: "This is an unsupported file type",
          },
        },
      },
      card: {
        register: {
          success: "Completed to register the card",
          fail: "Failed to register the card",
        },
        set_default: {
          fail: "Failed to set the default card",
        },
        delete: {
          fail: "Failed to delete the card",
        },
      },
      download_timeline_pdf: {
        process: "Downloading..",
        success: "PDF has been successfully downloaded",
        fail: "Failed to download. Please try again later",
      },
    },
  },
};
