import T from "@redwit-commons/utils/typecheck";
import { AccessTime, addAccessTime } from "../../api/object/access_time";
import {
  extractUserObject,
  UserObject,
  UserObjectSchema,
} from "../../api/object/user";
import {
  UserWorkspaceMapObjectCore,
  UserWorkspaceMapObjectSchema,
} from "./user_workspace_map";
import {
  UserObjectWithWorkspaceRoleType,
  UserObjectWithWorkspaceRoleTypeSchema,
  validateUserObjectWithWorkspaceRoleType,
} from "./user";
import { TeamWithUsersObject, TeamPureObjectSchema } from "./team";
import {
  RuleObject,
  RuleObjectSchema,
  RuleObjectWithContract,
  RuleObjectWithContractSchema,
} from "./rule";
import { NdaObjectWithContract, NdaObjectWithContractSchema } from "./nda";
import {
  WorkspacePlanPureCoreSchema,
  WorkspacePlanPureObject,
} from "./workspace_plan";
import {
  CardObject,
  CardObjectSchema,
  CardObjectWithDefault,
  CardObjectWithDefaultSchema,
  extractCardObject,
  validateCardObjectWithDefault,
} from "./card";
import {
  WorkspaceCardMapObjectCore,
  WorkspaceCardMapObjectSchema,
} from "./workspace_card_map";

export type WorkspacePureCore = {
  readonly id: string;
  readonly name: string;
  readonly workspace_cid?: string;
  readonly workspace_extension?: string;
  readonly allow_search: boolean;
  readonly krw_credit: number;
  readonly usd_credit: number;
};

export type WorkspaceDBCore = {
  readonly Users: Array<
    UserObject & { User_Workspace_Map: UserWorkspaceMapObjectCore }
  >;
} & WorkspacePureCore;

export type RefinedWorkspaceCore = WorkspacePureCore & {
  readonly Users: Array<UserObjectWithWorkspaceRoleType>;
};

export type WorkspaceWithTeamDB = {
  readonly Teams: Array<TeamWithUsersObject>;
} & WorkspaceDBCore;

export type RefinedWorkspaceCoreWithTeam = {
  readonly Teams: Array<TeamWithUsersObject>;
} & RefinedWorkspaceCore;

export type WorkspacePureObject = WorkspacePureCore & AccessTime;
export type WorkspaceDBObject = WorkspaceDBCore & AccessTime;
export type WorkspaceObject = RefinedWorkspaceCore & AccessTime;
export type WorkspaceWithTeamDBObject = WorkspaceWithTeamDB & AccessTime;
export type WorkspaceWithTeamObject = RefinedWorkspaceCoreWithTeam & AccessTime;
export type WorkspaceWithRule = {
  readonly Rules: Array<RuleObject>;
} & WorkspaceDBObject;
export type WorkspaceWithRuleContract = {
  readonly Rules: Array<RuleObjectWithContract>;
} & WorkspaceDBObject;
export type WorkspaceWithNdaContract = {
  readonly Ndas: Array<NdaObjectWithContract>;
} & WorkspaceDBObject;
export type WorkspaceWithPlanDBObject = {
  readonly WorkspacePlan: WorkspacePlanPureObject;
  readonly Cards: Array<
    CardObject & { Workspace_Card_Map: WorkspaceCardMapObjectCore }
  >;
} & WorkspaceDBObject;

export type WorkspaceWithPlan = {
  readonly WorkspacePlan: WorkspacePlanPureObject;
  readonly Cards: Array<CardObjectWithDefault>;
} & WorkspaceObject;

export const refineWorkspaceObject = (rdbWorkspace: WorkspaceDBObject) => {
  const Users = rdbWorkspace.Users.map((user) => {
    const userObject = extractUserObject(user);
    const roleType = user.User_Workspace_Map.roleType;
    const userObjWorkspaceWithRole = validateUserObjectWithWorkspaceRoleType({
      ...userObject,
      roleType,
    });
    return userObjWorkspaceWithRole;
  });

  const ret: WorkspaceObject = {
    ...rdbWorkspace,
    Users,
  };
  return ret;
};

export const refineWorkspaceWithTeamObject = (
  rdbWorkspace: WorkspaceWithTeamDBObject
) => {
  const Users = rdbWorkspace.Users.map((user) => {
    const userObject = extractUserObject(user);
    const roleType = user.User_Workspace_Map.roleType;
    const userObjWorkspaceWithRole = validateUserObjectWithWorkspaceRoleType({
      ...userObject,
      roleType,
    });
    return userObjWorkspaceWithRole;
  });

  const ret: WorkspaceWithTeamObject = {
    ...rdbWorkspace,
    Users,
  };

  return ret;
};

export const refineWorkspaceWithPlanObject = (
  rdbWorkspace: WorkspaceWithPlanDBObject
) => {
  const Users = rdbWorkspace.Users.map((user) => {
    const userObject = extractUserObject(user);
    const roleType = user.User_Workspace_Map.roleType;
    const userObjWorkspaceWithRole = validateUserObjectWithWorkspaceRoleType({
      ...userObject,
      roleType,
    });
    return userObjWorkspaceWithRole;
  });
  const Cards = rdbWorkspace.Cards.map((card) => {
    const cardObject = extractCardObject(card);
    const default_card = card.Workspace_Card_Map.default_card;
    const cardObjectWithDefault = validateCardObjectWithDefault({
      ...cardObject,
      default_card,
    });
    return cardObjectWithDefault;
  });

  const ret: WorkspaceWithPlan = {
    ...rdbWorkspace,
    Users,
    Cards,
  };

  return ret;
};

export const WorkspacePureCoreSchema = T.object()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("workspace_cid", T.string(), false)
  .addField("workspace_extension", T.string(), false)
  .addField("allow_search", T.boolean())
  .addField("krw_credit", T.number())
  .addField("usd_credit", T.number());

export const WorkspacePureObjectSchema = addAccessTime(
  WorkspacePureCoreSchema.clone()
);
export const WorkspaceDBObjectSchema =
  WorkspacePureObjectSchema.clone().addField(
    "Users",
    T.array(
      UserObjectSchema.clone().addField(
        "User_Workspace_Map",
        UserWorkspaceMapObjectSchema.clone()
      )
    )
  );

export const WorkspaceObjectSchema = WorkspacePureObjectSchema.clone().addField(
  "Users",
  T.array(UserObjectWithWorkspaceRoleTypeSchema.clone())
);

export const WorkspaceWithTeamDBObjectSchema =
  WorkspaceDBObjectSchema.clone().addField(
    "Teams",
    T.array(
      TeamPureObjectSchema.clone().addField(
        "Users",
        T.array(UserObjectSchema.clone())
      )
    )
  );

export const WorkspaceWithTeamObjectSchema =
  WorkspaceObjectSchema.clone().addField(
    "Teams",
    T.array(
      TeamPureObjectSchema.clone().addField(
        "Users",
        T.array(UserObjectSchema.clone())
      )
    )
  );

export const WorkspaceWithRuleSchema = WorkspaceDBObjectSchema.clone().addField(
  "Rules",
  T.array(RuleObjectSchema.clone())
);
export const WorkspaceWithRuleContractSchema =
  WorkspaceDBObjectSchema.clone().addField(
    "Rules",
    T.array(RuleObjectWithContractSchema.clone())
  );
export const WorkspaceWithNdaContractSchema =
  WorkspaceDBObjectSchema.clone().addField(
    "Ndas",
    T.array(NdaObjectWithContractSchema.clone())
  );

export const WorkspaceWithPlanDBObjectSchema = WorkspaceDBObjectSchema.clone()
  .addField("WorkspacePlan", WorkspacePlanPureCoreSchema.clone())
  .addField(
    "Cards",
    T.array(
      CardObjectSchema.clone().addField(
        "Workspace_Card_Map",
        WorkspaceCardMapObjectSchema.clone()
      )
    )
  );
export const WorkspaceWithPlanSchema = WorkspaceObjectSchema.clone()
  .addField("WorkspacePlan", WorkspacePlanPureCoreSchema.clone())
  .addField("Cards", T.array(CardObjectWithDefaultSchema.clone()));

export const extractWorkspacePureObject =
  T.mkObjectExtractor<WorkspacePureObject>(WorkspacePureObjectSchema);
export const validateWorkspacePureObject = T.mkValidator<WorkspacePureObject>(
  WorkspacePureObjectSchema
);
export const extractWorkspaceDBObject = T.mkObjectExtractor<WorkspaceDBObject>(
  WorkspaceDBObjectSchema
);
export const extractWorkspaceObject = T.mkObjectExtractor<WorkspaceObject>(
  WorkspaceObjectSchema
);
export const validateWorkspaceObject = T.mkValidator<WorkspaceObject>(
  WorkspaceObjectSchema
);
export const extractWorkspaceWithTeamDBObject =
  T.mkObjectExtractor<WorkspaceWithTeamDBObject>(
    WorkspaceWithTeamDBObjectSchema
  );
export const extractWorkspaceWithTeamObject =
  T.mkObjectExtractor<WorkspaceWithTeamObject>(WorkspaceWithTeamObjectSchema);
export const validateWorkspaceWithTeamObject =
  T.mkValidator<WorkspaceWithTeamObject>(WorkspaceWithTeamObjectSchema);

export const extractWorkspaceWithRule = T.mkObjectExtractor<WorkspaceWithRule>(
  WorkspaceWithRuleSchema
);
export const validateWorkspaceWithRule = T.mkValidator<WorkspaceWithRule>(
  WorkspaceWithRuleSchema
);
export const extractWorkspaceWithRuleContract =
  T.mkObjectExtractor<WorkspaceWithRuleContract>(
    WorkspaceWithRuleContractSchema
  );
export const validateWorkspaceWithRuleContract =
  T.mkValidator<WorkspaceWithRuleContract>(WorkspaceWithRuleContractSchema);
export const extractWorkspaceWithNdaContract =
  T.mkObjectExtractor<WorkspaceWithNdaContract>(WorkspaceWithNdaContractSchema);
export const validateWorkspaceWithNdaContract =
  T.mkValidator<WorkspaceWithNdaContract>(WorkspaceWithNdaContractSchema);
export const extractWorkspaceWithPlanDBObject =
  T.mkObjectExtractor<WorkspaceWithPlanDBObject>(
    WorkspaceWithPlanDBObjectSchema
  );
export const validateWorkspaceWithPlanDBObject =
  T.mkValidator<WorkspaceWithPlanDBObject>(WorkspaceWithPlanDBObjectSchema);
