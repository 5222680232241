import { ProjectObject } from "@basalt-commons/api/object/project";
import { ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import { theme } from "@theme";
import SidebarFolderList from "../components/pure/lists/SidebarFolderList";
import { useHistory, useRouteMatch } from "react-router-dom";
import translate from "../utils/translate";
import SpoqaHansTypography from "@SpoqaHansTypography";
import SidebarDrawerTitle from "../components/pure/lists/items/SidebarDrawerTitle";
import { ReactComponent as CreateNewFolderIcon } from "@ui/basalt/images/main-sidebar/add.svg";
import SidebarDropDownMenuView from "../components/pure/menus/SidebarDropDownMenu";
import { ScreenURL } from "@src/routes/RouteList";

type FolderMenuViewProps = {
  menu_name: string;
  folders: ProjectObject[];
  userId: string;
  openFolderEditModal: (folder: ProjectObject) => void;
};

const FolderMenuView = ({
  menu_name,
  folders,
  userId,
  openFolderEditModal,
}: FolderMenuViewProps) => {
  const router = useHistory();
  const matchWorkspaceName = useRouteMatch<{
    workspace_name: string;
  }>("/:workspace_name/");
  const match = useRouteMatch<{
    project_id: string | undefined;
    workspace_name: string;
  }>(ScreenURL.FOLDER_INSIDE);

  const project_id = match?.params.project_id;
  const workspace_name = matchWorkspaceName?.params.workspace_name;

  const navigateToFolderPage = (dest_folder_id: string) => {
    if (dest_folder_id === project_id) return;
    router.push(`/${workspace_name}/projects/${dest_folder_id}`);
  };

  return (
    <SidebarDropDownMenuView
      menu_name={menu_name}
      listComponent={
        <SidebarFolderList
          userId={userId}
          folders={folders}
          selectedId={project_id}
          onEditProject={(pj) => {
            openFolderEditModal(pj);
          }}
          onClick={(pj) => {
            navigateToFolderPage(pj.id);
          }}
        />
      }
    />
  );
};

const useStyles = makeStyles(() => ({
  iconButton: {
    backgroundColor: theme.indigo_0,
    color: theme.indigo_5,
    position: "absolute",
    right: 16,
    maxWidth: 24,
    height: 24,
    border: `1px solid ${theme.indigo_2}`,
    borderRadius: 24,
    transition: "all .25s",

    "& .MuiIconButton-label": {
      width: 22,
    },
    "& rect": {
      fill: "transparent",
    },
    "& path": {
      fill: "currentColor",
    },

    "&:hover": {
      background: theme.indigo_6,
      borderColor: theme.indigo_6,
      color: theme.white,
      maxWidth: "100%",
      padding: "0 8px",
      paddingLeft: 2,
      borderRadius: 16,
      ...theme.shadow_1,
      "& $btnHoverText": {
        display: "unset",
      },
    },
  },
  btnHoverText: { marginLeft: 0, display: "none", ...theme.font12 },
  createNewIcon: {
    width: "auto",
  },
}));

type FolderDrawerInnerComponentProps = {
  pinProjects: ProjectObject[];
  publicProjects: ProjectObject[];
  sharedProjects: ProjectObject[];
  privateProjects: ProjectObject[];
  userId: string;
  openFolderEditModal: (folder: ProjectObject) => void;
  navigateToCreateFolderPage: () => void;
};

export default function FolderDrawerInnerComponent({
  pinProjects,
  publicProjects,
  sharedProjects,
  privateProjects,
  userId,
  openFolderEditModal,
  navigateToCreateFolderPage,
}: FolderDrawerInnerComponentProps) {
  const classes = useStyles();

  const renderDrawerTitle = () => {
    return (
      <SidebarDrawerTitle
        title={translate.main_sidebar.folder_drawer.title}
        rightIcon={
          <ButtonBase
            onClick={(evt) => {
              evt.stopPropagation();
              navigateToCreateFolderPage();
            }}
            className={classes.iconButton}
          >
            <CreateNewFolderIcon className={classes.createNewIcon} />
            <SpoqaHansTypography
              fontWeight="medium"
              noWrap
              className={classes.btnHoverText}
            >
              {translate.main_sidebar.add_folder}
            </SpoqaHansTypography>
          </ButtonBase>
        }
      />
    );
  };

  return (
    <>
      {renderDrawerTitle()}
      <FolderMenuView
        menu_name={translate.main_sidebar.favorite}
        folders={pinProjects}
        userId={userId}
        openFolderEditModal={openFolderEditModal}
      />
      <FolderMenuView
        menu_name={translate.main_sidebar.public}
        folders={publicProjects}
        userId={userId}
        openFolderEditModal={openFolderEditModal}
      />
      <FolderMenuView
        menu_name={translate.main_sidebar.shared}
        folders={sharedProjects}
        userId={userId}
        openFolderEditModal={openFolderEditModal}
      />
      <FolderMenuView
        menu_name={translate.main_sidebar.private}
        folders={privateProjects}
        userId={userId}
        openFolderEditModal={openFolderEditModal}
      />
    </>
  );
}
