import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { NoteObjectWithDocuments } from "@basalt-commons/api/object/note";
import { ProjectObject } from "@basalt-commons/api/object/project";
import { HistoryObject } from "@basalt-commons/api/object/history";

export enum SearchType {
  HASHTAG = "SearchType::HASHTAG",
  OCR = "SearchType::OCR",
  PROJECT = "SearchType::PROJECT",
}

export interface SearchObject {
  readonly hashtags: NoteObjectWithDocuments[];
  readonly keywords: NoteObjectWithDocuments[];
  readonly projects: ProjectObject[];
  readonly files: NoteObjectWithDocuments[];
  readonly hashtagsTotal: number;
  readonly keywordsTotal: number;
  readonly projectsTotal: number;
  readonly filesTotal: number;
}

export interface SearchRecentWords {
  hashtags: HistoryObject[];
  words: HistoryObject[];
}

export enum SearchStateStatus {
  INIT = "SearchState::INIT",
  READY = "SearchState::READY",
  SUCCESS = "SearchState::SUCCESS",
}

export enum SearchActionKind {
  TRY_READY = "SearchAction::TRY_READY",
  TRY_SEARCH = "SearchAction::TRY_SEARCH",
  TRY_SEARCH_MORE = "SearchAction::TRY_SEARCH_MORE",
  TRY_DELETE_WORD = "SearchAction::TRY_DELETE_WORD",
}

export type SearchState =
  | {
      readonly status: SearchStateStatus.INIT;
    }
  | {
      readonly status: SearchStateStatus.READY;
      readonly recents: SearchRecentWords;
    }
  | {
      readonly status: SearchStateStatus.SUCCESS;
      readonly recents: SearchRecentWords;
      readonly results: SearchObject;
    };

export type SearchAction =
  | {
      readonly kind: SearchActionKind.TRY_READY;
    }
  | {
      readonly kind: SearchActionKind.TRY_SEARCH;
      readonly workspaceId?: string;
      readonly word: string;
    }
  | {
      readonly kind: SearchActionKind.TRY_SEARCH_MORE;
      readonly workspaceId: string;
      readonly type: SearchType;
      readonly word: string;
    }
  | {
      readonly kind: SearchActionKind.TRY_DELETE_WORD;
      readonly target: HistoryObject;
    };

export type SearchError = never;

const smid = "SEARCH_STATE_MACHINE3";
export type SearchStateMachineType = StateMachine3<
  SearchStateStatus,
  SearchState,
  SearchActionKind,
  SearchAction,
  SearchError
>;
export const searchStateMachine: SearchStateMachineType = new StateMachine3<
  SearchStateStatus,
  SearchState,
  SearchActionKind,
  SearchAction,
  SearchError
>(smid, { status: SearchStateStatus.INIT }, [
  transition(
    SearchStateStatus.INIT,
    SearchStateStatus.READY,
    SearchActionKind.TRY_READY
  ),
  transition(
    SearchStateStatus.READY,
    SearchStateStatus.READY,
    SearchActionKind.TRY_READY
  ),
  transition(
    SearchStateStatus.SUCCESS,
    SearchStateStatus.SUCCESS,
    SearchActionKind.TRY_READY
  ),

  transition(
    SearchStateStatus.SUCCESS,
    SearchStateStatus.SUCCESS,
    SearchActionKind.TRY_DELETE_WORD
  ),
  transition(
    SearchStateStatus.READY,
    SearchStateStatus.READY,
    SearchActionKind.TRY_DELETE_WORD
  ),

  transition(
    SearchStateStatus.INIT,
    SearchStateStatus.SUCCESS,
    SearchActionKind.TRY_SEARCH
  ),
  transition(
    SearchStateStatus.READY,
    SearchStateStatus.SUCCESS,
    SearchActionKind.TRY_SEARCH
  ),
  transition(
    SearchStateStatus.SUCCESS,
    SearchStateStatus.SUCCESS,
    SearchActionKind.TRY_SEARCH
  ),

  transition(
    SearchStateStatus.SUCCESS,
    SearchStateStatus.SUCCESS,
    SearchActionKind.TRY_SEARCH_MORE
  ),
  transition(
    SearchStateStatus.INIT,
    SearchStateStatus.SUCCESS,
    SearchActionKind.TRY_SEARCH_MORE
  ),
]);

/* */
export type DispatchSearchAction = Dispatch<
  StateMachineAction<
    SearchStateStatus,
    SearchState,
    SearchActionKind,
    SearchAction,
    SearchError
  >
>;
export default mkReducer<
  SearchStateStatus,
  SearchState,
  SearchActionKind,
  SearchAction,
  SearchError
>(searchStateMachine);
export const doSearchAction = (
  dispatch: DispatchSearchAction,

  nextAction: SearchAction,
  onResolve: () => void = () => {},
  onReject: (err: SearchError | InternalError) => void = () => {}
) => {
  dispatch(searchStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doSearchActionAsync = (
  dispatch: DispatchSearchAction,
  nextAction: SearchAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(searchStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetSearch = (dispatch: DispatchSearchAction) => {
  dispatch(searchStateMachine.newResetAction());
};
