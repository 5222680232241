import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import translate from "../utils/translate";
import SidebarDrawerTitle from "../components/pure/lists/items/SidebarDrawerTitle";
import SidebarDrawerListItem from "../components/pure/lists/items/SidebarDrawerListItem";
import { ReactComponent as SettingsIcon } from "@ui/basalt/images/sidebar/sidebar_item_ws_setting.svg";
import { ReactComponent as MembersIcon } from "@ui/basalt/images/sidebar/sidebar_item_ws_members.svg";
import { ReactComponent as PaymentsIcon } from "@ui/basalt/images/sidebar/sidebar_item_ws_payments.svg";
import { useHistory, useRouteMatch } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

type TabType = "setting" | "members" | "billing";
enum TabValue {
  SETTINGS = "setting",
  MEMBERS = "members",
  PAYMENTS = "billing",
}

export default function WorkspaceSettingDrawerInnerComponent() {
  const search = new URLSearchParams(window.location.search);
  const tab = search.get("tab") as TabType | undefined;

  const classes = useStyles();
  const [selected, setSelected] = useState<TabType | undefined>(tab);
  const router = useHistory();
  const matchWorkspaceName = useRouteMatch<{
    workspace_name: string;
  }>("/:workspace_name/");
  const workspace_name = matchWorkspaceName?.params.workspace_name;

  const navigateToSettingsPage = (type: TabValue) => {
    if (workspace_name === undefined) return;
    router.push(`/${workspace_name}/settings?tab=${type}`);
  };

  const createStyledIconComponent = (className: string, type: TabType) => {
    if (type === TabValue.SETTINGS)
      return <SettingsIcon className={className} />;
    else if (type === TabValue.MEMBERS)
      return <MembersIcon className={className} />;
    return <PaymentsIcon className={className} />;
  };

  const items = [
    {
      type: TabValue.SETTINGS,
      item_text: translate.main_sidebar.workspace_setting.settings,
      onClick: () => {
        setSelected(TabValue.SETTINGS);
        navigateToSettingsPage(TabValue.SETTINGS);
      },
    },
    {
      type: TabValue.MEMBERS,
      item_text: translate.main_sidebar.workspace_setting.members,
      onClick: () => {
        setSelected(TabValue.MEMBERS);
        navigateToSettingsPage(TabValue.MEMBERS);
      },
    },
    {
      type: TabValue.PAYMENTS,
      item_text: translate.main_sidebar.workspace_setting.payments,
      onClick: () => {
        setSelected(TabValue.PAYMENTS);
        navigateToSettingsPage(TabValue.PAYMENTS);
      },
    },
  ] as {
    type: TabType;
    item_text: string;
    onClick: () => void;
  }[];

  const renderDrawerTitle = () => {
    return (
      <SidebarDrawerTitle
        title={translate.main_sidebar.workspace_setting.title}
      />
    );
  };

  return (
    <div className={classes.root}>
      {renderDrawerTitle()}
      {items.map(({ type, item_text, onClick }) => (
        <SidebarDrawerListItem
          key={type}
          iconFactory={(className) =>
            createStyledIconComponent(className, type)
          }
          selected={selected === type}
          item_text={item_text}
          onClick={onClick}
        />
      ))}
    </div>
  );
}
