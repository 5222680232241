import {
  doAPIPostRequest,
  doAPIGetRequest,
  doAPIDeleteRequest,
} from "../services/api";
import {
  WorkspaceDBObject,
  WorkspaceWithPlan,
  WorkspaceWithTeamObject,
} from "@basalt-commons/global-api/object/workspace";
import {
  ICreateTeam,
  ICreateWorkspace,
  IDeleteJoinedUserTeam,
  IDeleteJoinedUserWorkspace,
  IDeleteWorkspace,
  IDeleteWorkspaceSharedEmail,
  IJoinWorkspace,
  IResendWorkspaceShare,
  IShareWorkspaceWithMemebers,
  IUpdateJoinedUserWorkspace,
  IUpdateTeam,
  IUpdateWorkspace,
  IUpdateWorkspaceRoleSharedEmail,
} from "@basalt-commons/global-api/request/workspace";
import {
  OJoinWorkspace,
  validateOCreateTeam,
  validateOCreateWorkspace,
  validateODeleteJoinedUserTeam,
  validateODeleteJoinedUserWorkspace,
  validateODeleteTeam,
  validateODeleteWorkspaceSharedEmail,
  validateOGetAllWorkspaces,
  validateOGetWorkspaceInfo,
  validateOGetWorkspacePlan,
  validateOGetWorkspaces,
  validateOGetWorkspaceShareEmailList,
  validateOJoinWorkspace,
  validateOKnockWorkspace,
  validateOUpdateJoinedUserWorkspace,
  validateOUpdateTeam,
  validateOUpdateWorkspaceRoleSharedEmail,
  validateOUpdateWorspace,
} from "@basalt-commons/global-api/response/workspace";
import { TokenLogWithUserObject } from "@basalt-commons/global-api/object/token_log";
import { IDowngradePlan } from "@basalt-commons/global-api/request/payment";
import { validateODowngradePlan } from "@basalt-commons/global-api/response/payment";

const getWorkspaces = async (
  userToken: string
): Promise<WorkspaceWithTeamObject[]> => {
  const obj = await doAPIGetRequest("/global/workspaces", userToken);
  const ret = validateOGetWorkspaces(obj);
  return ret.response.results;
};

const getWorkspaceInfo = async (
  userToken: string,
  workspaceName: string
): Promise<WorkspaceDBObject> => {
  const obj = await doAPIGetRequest(
    `/global/workspace/${workspaceName}`,
    userToken
  );
  const ret = validateOGetWorkspaceInfo(obj);
  return ret.response;
};

const getWorkspacePlan = async (
  userToken: string,
  workspaceId: string
): Promise<WorkspaceWithPlan> => {
  const obj = await doAPIGetRequest(
    `/workspaces/${workspaceId}/plan`,
    userToken
  );
  const ret = validateOGetWorkspacePlan(obj);
  return ret.response;
};

const getAllWorkspaces = async (
  userToken: string
): Promise<WorkspaceWithTeamObject[]> => {
  const obj = await doAPIGetRequest("/global/workspaces/all", userToken);
  const ret = validateOGetAllWorkspaces(obj);
  return ret.response.results;
};

const createWorkspace = async (
  userToken: string,
  args: ICreateWorkspace
): Promise<WorkspaceWithTeamObject> => {
  const obj = await doAPIPostRequest("global/workspace", userToken, args);
  const ret = validateOCreateWorkspace(obj);
  return ret.response;
};
const updateWorkspace = async (
  userToken: string,
  workspaceId: string,
  args: IUpdateWorkspace
): Promise<WorkspaceWithTeamObject> => {
  const obj = await doAPIPostRequest(
    `/global/workspaces/${workspaceId}/update`,
    userToken,
    args
  );
  const ret = validateOUpdateWorspace(obj);
  return ret.response;
};

const shareWorkspaceWithMembers = async (
  userToken: string,
  workspaceId: string,
  args: IShareWorkspaceWithMemebers
) => {
  await doAPIPostRequest(
    `/global/workspaces/${workspaceId}/share/members`,
    userToken,
    args
  );
  return;
};
const joinWorkspace = async (
  userToken: string,
  args: IJoinWorkspace
): Promise<OJoinWorkspace> => {
  const obj = await doAPIPostRequest(
    `/global/workspaces/join`,
    userToken,
    args
  );
  const ret = validateOJoinWorkspace(obj);
  return ret;
};
const deleteJoinedUserWorkspace = async (
  userToken: string,
  workspaceId: string,
  args: IDeleteJoinedUserWorkspace
): Promise<string> => {
  const obj = await doAPIDeleteRequest(
    `/global/workspaces/${workspaceId}/joined`,
    userToken,
    args
  );
  const ret = validateODeleteJoinedUserWorkspace(obj);
  return ret.response;
};
const updateMemberAuth = async (
  userToken: string,
  workspaceId: string,
  args: IUpdateJoinedUserWorkspace
): Promise<WorkspaceWithTeamObject> => {
  const obj = await doAPIPostRequest(
    `/global/workspaces/${workspaceId}/update/member`,
    userToken,
    args
  );
  const ret = validateOUpdateJoinedUserWorkspace(obj);
  return ret.response;
};
const signOutWorkspace = async (
  userToken: string,
  workspaceId: string
): Promise<string> => {
  const obj = await doAPIDeleteRequest(
    `/global/workspaces/${workspaceId}/signout`,
    userToken
  );
  const ret = validateODeleteJoinedUserWorkspace(obj);
  return ret.response;
};

const deleteWorkspace = async (
  userToken: string,
  workspaceId: string,
  args: IDeleteWorkspace
): Promise<string> => {
  const obj = await doAPIDeleteRequest(
    `/global/workspaces/${workspaceId}`,
    userToken,
    args
  );
  const ret = validateODeleteTeam(obj);
  return ret.response;
};

const createWorkspaceTeam = async (
  userToken: string,
  workspaceId: string,
  args: ICreateTeam
): Promise<WorkspaceWithTeamObject> => {
  const obj = await doAPIPostRequest(
    `/global/workspaces/${workspaceId}/team`,
    userToken,
    args
  );
  const ret = validateOCreateTeam(obj);
  return ret.response;
};

const updateWorkspaceTeam = async (
  userToken: string,
  workspaceId: string,
  teamId: string,
  args: IUpdateTeam
): Promise<WorkspaceWithTeamObject> => {
  const obj = await doAPIPostRequest(
    `/global/workspaces/${workspaceId}/teams/${teamId}/update`,
    userToken,
    args
  );
  const ret = validateOUpdateTeam(obj);
  return ret.response;
};

const deleteJoinedUserTeam = async (
  userToken: string,
  workspaceId: string,
  teamId: string,
  args: IDeleteJoinedUserTeam
): Promise<string> => {
  const obj = await doAPIDeleteRequest(
    `/global/workspaces/${workspaceId}/teams/${teamId}/joined`,
    userToken,
    args
  );
  const ret = validateODeleteJoinedUserTeam(obj);
  return ret.response;
};

const deleteWorkspaceTeam = async (
  userToken: string,
  workspaceId: string,
  teamId: string
): Promise<string> => {
  const obj = await doAPIDeleteRequest(
    `/global/workspaces/${workspaceId}/teams/${teamId}`,
    userToken
  );
  const ret = validateODeleteTeam(obj);
  return ret.response;
};

const getWorkspaceShareEmailList = async (
  userToken: string,
  workspaceId: string
): Promise<TokenLogWithUserObject[]> => {
  const obj = await doAPIGetRequest(
    `/global/workspaces/${workspaceId}/share/emails`,
    userToken
  );
  const ret = validateOGetWorkspaceShareEmailList(obj);
  return ret.response.results;
};

const knockWorkspace = async (
  userToken: string,
  workspaceId: string
): Promise<string> => {
  const obj = await doAPIPostRequest(
    `/global/workspaces/${workspaceId}/knock`,
    userToken
  );
  const ret = validateOKnockWorkspace(obj);
  return ret.response;
};

/**
 * 워크스페이스 초대 메일 재발송을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * Member 권한 이상의 유저만이 요청할 수 있음
 */
const resendWorkspaceShareEmail = async (
  userToken: string,
  workspaceId: string,
  args: IResendWorkspaceShare
) => {
  await doAPIPostRequest(
    `/global/workspaces/${workspaceId}/resend`,
    userToken,
    args
  );
  return;
};

/**
 * 워크스페이스 초대중인 이메일의 권한 변경을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * Admin 권한 이상의 유저만이 요청할 수 있음
 */
const updateWorkspaceRoleSharedEmail = async (
  userToken: string,
  workspaceId: string,
  args: IUpdateWorkspaceRoleSharedEmail
): Promise<TokenLogWithUserObject> => {
  const obj = await doAPIPostRequest(
    `/global/workspaces/${workspaceId}/update/sharedEmail/role`,
    userToken,
    args
  );
  const ret = validateOUpdateWorkspaceRoleSharedEmail(obj);
  return ret.response;
};

/**
 * 워크스페이스 초대 내역 삭제를 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * Member 권한 이상의 유저만이 요청할 수 있음
 */
const deleteWorkspaceSharedEmail = async (
  userToken: string,
  workspaceId: string,
  args: IDeleteWorkspaceSharedEmail
): Promise<string> => {
  const obj = await doAPIDeleteRequest(
    `/global/workspaces/${workspaceId}/sharedEmail`,
    userToken,
    args
  );
  const ret = validateODeleteWorkspaceSharedEmail(obj);
  return ret.response;
};
const downgradePlan = async (
  userToken: string,
  workspaceId: string,
  args: IDowngradePlan
): Promise<WorkspaceWithPlan> => {
  const obj = await doAPIPostRequest(
    `/basalt/payment/downgrade/plan/${workspaceId}`,
    userToken,
    args
  );
  const ret = validateODowngradePlan(obj);
  return ret.response;
};

export default {
  getWorkspaces,
  getWorkspaceInfo,
  getWorkspacePlan,
  getAllWorkspaces,
  createWorkspace,
  updateWorkspace,
  joinWorkspace,
  deleteJoinedUserWorkspace,
  shareWorkspaceWithMembers,
  updateMemberAuth,
  deleteWorkspace,
  signOutWorkspace,
  createWorkspaceTeam,
  updateWorkspaceTeam,
  deleteJoinedUserTeam,
  deleteWorkspaceTeam,
  getWorkspaceShareEmailList,
  knockWorkspace,
  resendWorkspaceShareEmail,
  updateWorkspaceRoleSharedEmail,
  deleteWorkspaceSharedEmail,
  downgradePlan,
};
