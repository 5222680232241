import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import {
  WorkspaceWithNdaContract,
  WorkspaceWithTeamObject,
} from "@basalt-commons/global-api/object/workspace";
import { UserObjectWithWorkspaceRoleType } from "@basalt-commons/global-api/object/user";
import { TokenLogWithUserObject } from "@basalt-commons/global-api/object/token_log";
import {
  IDeleteWorkspaceSharedEmail,
  IResendWorkspaceShare,
  IShareWorkspace,
  IUpdateJoinedUserWorkspace,
  IUpdateWorkspaceRoleSharedEmail,
} from "@basalt-commons/global-api/request/workspace";
import { WorkspaceWithPlan } from "@basalt-commons/global-api/object/workspace";
import { WorkspacePlanType } from "@basalt-commons/global-api/object/workspace_plan";
import { PaypleDomesticPaymentResponse } from "@basalt-commons/global-api/object/payments";
import { IPaymentCredit } from "@basalt-commons/global-api/request/payment";

export enum WorkspaceStateStatus {
  INIT = "WorkspaceState::INIT",
  SUCCESS = "WorkspaceState::SUCCESS",
}

export enum WorkspaceActionKind {
  TRY_CREATE_WORKSPACE = "WorkspaceAction::TRY_CREATE_WORKSPACE",
  TRY_GET_WORKSPACE = "WorkspaceAction::TRY_GET_WORKSPACE",
  TRY_SELECT_WORKSPACE = "WorkspaceAction::TRY_SELECT_WORKSPACE",
  TRY_UPDATE_WORKSPACE = "WorkspaceAction::TRY_UDPATE_WORKSPACE",
  TRY_SHARE_WORKSPACE_EMAILS = "WorkspaceAction::TRY_SHARE_WORKSPACE_EMAILS",
  TRY_JOIN_WORKSPACE = "WorkspaceAction::TRY_JOIN_WORKSPACE",
  TRY_DELETE_USER_IN_WORKSPACE = "WorkspaceAction::TRY_DELETE_USER_IN_WORKSPACE",
  TRY_GET_MORE_LOGS = "WorkspaceAction::TRY_MORE_LOGS",
  TRY_UPDATE_MEMBER_WORKSPACE = "WorkspaceAction::TRY_UPDATE_MEMBER_WORKSPACE",
  TRY_SIGN_OUT_WORKSPACE = "WorkspaceAction::TRY_SIGN_OUT_WORKSPACE",
  TRY_CLEAR_WORKSPACE = "WorkspaceAction::TRY_CLEAR_WORKSPACE",
  TRY_CREATE_WORKSPACE_TEAM = "WorkspaceAction::TRY_CREATE_WORKSPACE_TEAM",
  TRY_UPDATE_WORKSPACE_TEAM = "WorkspaceAction::TRY_UPDATE_WORKSPACE_TEAM",
  TRY_DELETE_JOINED_USER_TEAM = "WorkspaceAction::TRY_DELETE_JOINED_USER_TEAM",
  TRY_DELETE_WORKSPACE_TEAM = "WorkspaceAction::TRY_DELETE_WORKSPACE_TEAM",
  TRY_DELETE_WORKSPACE = "WorkspaceAction::TRY_DELETE_WORKSPACE",
  TRY_GET_WORKSPACE_SHARE_EMAILS = "WorkspaceAction::TRY_GET_WORKSPACE_SHARE_EMAILS",
  TRY_KNOCK_WORKSPACE = "WorkspaceAction::TRY_KNOCK_WORKSPACE",
  TRY_RESEND_WORKSPACE_SHARE_EMAIL = "WorkspaceAction::TRY_RESEND_WORKSPACE_SHARE_EMAIL",
  TRY_UPDATE_WORKSPACE_SHARE_EMAIL_ROLE = "WorkspaceAction::TRY_UPDATE_WORKSPACE_SHARE_EMAIL_ROLE",
  TRY_DELETE_WORKSPACE_SHARE_EMAIL = "WorkspaceAction::TRY_DELETE_WORKSPACE_SHARE_EMAIL",
  TRY_GET_SIGNED_NDA_CONTRACT = "WorkspaceAction::TRY_GET_SIGNED_NDA_CONTRACT",
  TRY_DOWNLOAD_ALL_MY_CONTRACT = "WorkspaceAction::TRY_DOWNLOAD_ALL_MY_CONTRACT",
  TRY_DOWNGRADE_PLAN = "WorkspaceAction::TRY_DOWNGRADE_PLAN",
  TRY_CERT_DOMESTIC_PAYMENT = "WorkspaceAction::TRY_CERT_DOMESTIC_PAYMENT",
  TRY_PAYMENT_CREDIT = "WorkspaceAction::TRY_PAYMENT_CREDIT",
}

export type WorkspaceError = never;

export enum WorkspaceErrorType {
  NOT_AUTHORIZED = "WorkspaceErrorType::NOT_AUTHORIZED",
  ONLY_ADMIN_OR_OWNER = "WorkspaceErrorType::ONLY_ADMIN_OR_OWNER",
  ONLY_OWNER = "WorkspaceErrorType::ONLY_OWNER",
  WARN_OWNER_SIGN_OUT = "WorkspaceErrorType::WARN_OWNER_SIGN_OUT",
  ONLY_MEMBER_AND_ABOVE = "WorkspaceErrorType::ONLY_MEMBER_AND_ABOVE",
  NOT_SELECTED = "WorkspaceErrorType::NOT_SELECTED",
  INVALID_INPUT = "WorkspaceErrorType::INVALID_INPUT",
  NOT_ENOUGH_PLAN = "WorkspaceErrorType::NOT_ENOUGH_PLAN",
  PLAN_EXPIRED = "WorkspaceErrorType::PLAN_EXPIRED",
}

export type WorkspaceState =
  | {
      readonly status: WorkspaceStateStatus.INIT;
      readonly workspaces: WorkspaceWithTeamObject[]; // 로그인된 유저의 워크스페이스들
      readonly otherWorkspaces?: WorkspaceWithTeamObject[]; // 다른 유저들의 워크스페이스들
      readonly myNdaContractList?: WorkspaceWithNdaContract[]; // 로그인된 유저의 워크스페이스들의 NDA 서명내역들
    }
  | {
      readonly status: WorkspaceStateStatus.SUCCESS;
      readonly selectAuthWorkspace: WorkspaceWithTeamObject; //선택한 워크스페이스 정보
      readonly workspaceWithPlan: WorkspaceWithPlan;
      readonly logs?: TokenLogWithUserObject[]; // 발송한 워크스페이스 초대 메일 리스트를
      readonly workspaces: WorkspaceWithTeamObject[]; // 로그인된 유저의 워크스페이스들
      readonly otherWorkspaces?: WorkspaceWithTeamObject[]; // 다른 유저들의 워크스페이스들
      readonly myNdaContractList?: WorkspaceWithNdaContract[]; // 로그인된 유저의 워크스페이스들의 NDA 서명내역들
      readonly filePath?: string; // 본인 서명 압축 파일 경로
    };

export type WorkspaceAction =
  | {
      readonly kind: WorkspaceActionKind.TRY_GET_WORKSPACE;
      readonly get_all?: boolean;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_SELECT_WORKSPACE;
      readonly name: string;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_CREATE_WORKSPACE;
      readonly name: string;
      readonly allow_search: boolean;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_UPDATE_WORKSPACE;
      readonly name?: string;
      readonly workspace_cid?: string;
      readonly workspace_extension?: string;
      readonly allow_search?: boolean;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_SHARE_WORKSPACE_EMAILS;
      readonly members: IShareWorkspace[];
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_JOIN_WORKSPACE;
      readonly shareToken: string;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_DELETE_USER_IN_WORKSPACE;
      readonly targetUserId: string;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_UPDATE_MEMBER_WORKSPACE;
      readonly member: IUpdateJoinedUserWorkspace;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_SIGN_OUT_WORKSPACE;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_CLEAR_WORKSPACE;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_CREATE_WORKSPACE_TEAM;
      readonly name: string;
      readonly members: Array<UserObjectWithWorkspaceRoleType>;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_UPDATE_WORKSPACE_TEAM;
      readonly teamId: string;
      readonly name?: string;
      readonly members?: Array<UserObjectWithWorkspaceRoleType>;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_DELETE_JOINED_USER_TEAM;
      readonly teamId: string;
      readonly userId: string;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_DELETE_WORKSPACE_TEAM;
      readonly teamId: string;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_DELETE_WORKSPACE;
      readonly password: string;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_GET_WORKSPACE_SHARE_EMAILS;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_KNOCK_WORKSPACE;
      readonly workspaceId: string;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_RESEND_WORKSPACE_SHARE_EMAIL;
      readonly args: IResendWorkspaceShare;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_UPDATE_WORKSPACE_SHARE_EMAIL_ROLE;
      readonly args: IUpdateWorkspaceRoleSharedEmail;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_DELETE_WORKSPACE_SHARE_EMAIL;
      readonly args: IDeleteWorkspaceSharedEmail;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_GET_SIGNED_NDA_CONTRACT;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_DOWNLOAD_ALL_MY_CONTRACT;
      readonly fileType: "rule" | "nda";
      readonly WorkspaceId?: string;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_DOWNGRADE_PLAN;
      readonly new_plan: WorkspacePlanType.INDIVIDUAL;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_CERT_DOMESTIC_PAYMENT;
      readonly payment_info: PaypleDomesticPaymentResponse;
      readonly used_credit: number;
    }
  | {
      readonly kind: WorkspaceActionKind.TRY_PAYMENT_CREDIT;
      readonly args: IPaymentCredit;
    };

const smid = "WORKSPACE_STATE_MACHINE3";
export type WorkspaceStateMachineType = StateMachine3<
  WorkspaceStateStatus,
  WorkspaceState,
  WorkspaceActionKind,
  WorkspaceAction,
  WorkspaceError
>;
export const WorkspaceStateMachine: WorkspaceStateMachineType =
  new StateMachine3<
    WorkspaceStateStatus,
    WorkspaceState,
    WorkspaceActionKind,
    WorkspaceAction,
    WorkspaceError
  >(smid, { status: WorkspaceStateStatus.INIT, workspaces: [] }, [
    transition(
      WorkspaceStateStatus.INIT,
      WorkspaceStateStatus.INIT,
      WorkspaceActionKind.TRY_GET_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_GET_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.INIT,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_SELECT_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_SELECT_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.INIT,
      WorkspaceStateStatus.INIT,
      WorkspaceActionKind.TRY_CREATE_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_CREATE_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_UPDATE_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_SHARE_WORKSPACE_EMAILS
    ),

    transition(
      WorkspaceStateStatus.INIT,
      WorkspaceStateStatus.INIT,
      WorkspaceActionKind.TRY_JOIN_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_JOIN_WORKSPACE
    ),

    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_DELETE_USER_IN_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_UPDATE_MEMBER_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.INIT,
      WorkspaceActionKind.TRY_SIGN_OUT_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.INIT,
      WorkspaceStateStatus.INIT,
      WorkspaceActionKind.TRY_CLEAR_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.INIT,
      WorkspaceActionKind.TRY_CLEAR_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.INIT,
      WorkspaceActionKind.TRY_DELETE_WORKSPACE
    ),

    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_CREATE_WORKSPACE_TEAM
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_UPDATE_WORKSPACE_TEAM
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_DELETE_JOINED_USER_TEAM
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_DELETE_WORKSPACE_TEAM
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_GET_WORKSPACE_SHARE_EMAILS
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_KNOCK_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.INIT,
      WorkspaceStateStatus.INIT,
      WorkspaceActionKind.TRY_KNOCK_WORKSPACE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_RESEND_WORKSPACE_SHARE_EMAIL
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_UPDATE_WORKSPACE_SHARE_EMAIL_ROLE
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_DELETE_WORKSPACE_SHARE_EMAIL
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_GET_SIGNED_NDA_CONTRACT
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_DOWNLOAD_ALL_MY_CONTRACT
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_DOWNGRADE_PLAN
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_CERT_DOMESTIC_PAYMENT
    ),
    transition(
      WorkspaceStateStatus.SUCCESS,
      WorkspaceStateStatus.SUCCESS,
      WorkspaceActionKind.TRY_PAYMENT_CREDIT
    ),
  ]);

export type DispatchWorkspaceAction = Dispatch<
  StateMachineAction<
    WorkspaceStateStatus,
    WorkspaceState,
    WorkspaceActionKind,
    WorkspaceAction,
    WorkspaceError
  >
>;
export default mkReducer<
  WorkspaceStateStatus,
  WorkspaceState,
  WorkspaceActionKind,
  WorkspaceAction,
  WorkspaceError
>(WorkspaceStateMachine);

export const doWorkspaceAction = (
  dispatch: DispatchWorkspaceAction,
  nextAction: WorkspaceAction,
  onResolve: () => void = () => {},
  onReject: (err: WorkspaceError | InternalError) => void = () => {}
) => {
  dispatch(WorkspaceStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doWorkspaceActionAsync = (
  dispatch: DispatchWorkspaceAction,
  nextAction: WorkspaceAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(WorkspaceStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetWorkspace = (dispatch: DispatchWorkspaceAction) => {
  dispatch(WorkspaceStateMachine.newResetAction());
};
