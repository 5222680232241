import { theme } from "@theme";
import { createStyles } from "@material-ui/core";
const AlarmMenuWidth = 500;
const AlarmMenuSidePadding = 24;
const AlarmProfileImageSize = 36;
const AlarmProfileImageMargin = 12;
const AlarmBodyWidth =
  AlarmMenuWidth -
  AlarmMenuSidePadding * 2 -
  AlarmProfileImageSize -
  AlarmProfileImageMargin;
const styles = createStyles({
  alarmContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 32,
    paddingBottom: 18,
  },
  titleText: {
    wordBreak: "break-word",
    wordWrap: "break-word",
    whiteSpace: "normal",
    fontSize: 16,
    color: theme.gray_7_text,
  },
  closeIcon: { fontSize: 20, color: theme.gray_5 },

  profileImageContainer: {
    width: AlarmProfileImageSize,
    marginRight: AlarmProfileImageMargin,
  },
  profileImage: {
    width: AlarmProfileImageSize,
    height: AlarmProfileImageSize,
    borderRadius: AlarmProfileImageSize,
  },

  alarmBodyContainer: { maxWidth: AlarmBodyWidth },

  alarmSubText: { fontSize: 12, color: theme.gray_7_text },
  folderName: { fontSize: 12, color: theme.gray_5 },
  bodyText: {
    fontSize: 14,
    color: theme.gray_7_text,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  confirmButtn: {
    padding: 12,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: theme.indigo_0,
    borderRadius: 8,
    marginTop: 12,
  },
  confirmText: { color: theme.indigo_8, fontSize: 14 },
});

export default styles;
