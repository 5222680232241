import { makeStyles } from "@material-ui/core";
import { theme } from "@theme";

const useStyles = makeStyles(() => ({
  hover_btn_list: {
    position: "absolute",
    top: 12,
    right: 12,
    opacity: "0",
    visibility: "hidden",
    transition: "all .15s",
  },
  hovering: {
    opacity: "1",
    visibility: "visible",
  },
  bottom_icon_info_wrap: {
    display: "flex",
    flexWrap: "nowrap",
    width: 38,
  },
  bottom_icon_info_count: {
    marginLeft: 4,
    ...theme.font12,
  },
}));

export default useStyles;
