import T from "@redwit-commons/utils/typecheck";
import {
  GoonoResponse,
  mkResponseSchema,
  GoonoArrayResponse,
  mkArrayResultsSchema,
} from "./common";
import { LicenseObject, LicenseObjectSchema } from "../object/license";
import {
  LicenseTokenLogObject,
  LicenseTokenLogObjectSchema,
} from "../object/license_token_log";
import {
  AuthNoteObjectWithProject,
  AuthNoteObjectWithProjectSchema,
} from "../object/note";

export type OJoinLicense = GoonoResponse<LicenseObject>;
export const OJoinLicenseSchema = mkResponseSchema(LicenseObjectSchema);
export const validateOJoinLicense =
  T.mkValidator<OJoinLicense>(OJoinLicenseSchema);

export type OGetLicenseLink = GoonoResponse<string>;
export const OGetLicenseLinkSchema = mkResponseSchema(T.string());
export const validateOGetLicenseLink = T.mkValidator<OGetLicenseLink>(
  OGetLicenseLinkSchema
);

export type OGetPendingEmails = GoonoArrayResponse<LicenseTokenLogObject>;
export const OGetPendingEmailsSchema = mkArrayResultsSchema(
  LicenseTokenLogObjectSchema
);

export const validateOGetPendingEmails = T.mkValidator<OGetPendingEmails>(
  OGetPendingEmailsSchema
);

export type OGetUserAuthNotes = GoonoArrayResponse<AuthNoteObjectWithProject>;
export const OGetUserAuthNotesSchema = mkArrayResultsSchema(
  AuthNoteObjectWithProjectSchema
);
export const validateOGetUserAuthNotes = T.mkValidator<OGetUserAuthNotes>(
  OGetUserAuthNotesSchema
);

export enum inviteTokenErrorType {
  ALREADY_HAVE_LICENSE = "ALREADY_HAVE_LICENSE",
  INVALID_TOKEN = "INVALID_TOKEN",
  INVALID_INVITED_USER = "INVALID_INVITED_USER",
}
export type OCheckLicenseInviteToken = GoonoResponse<{
  available: boolean;
  errorMessage?: string;
}>;
export const OCheckLicenseInviteTokenSchema = mkResponseSchema(
  T.object()
    .addField("available", T.boolean())
    .addField("errorMessage", T.string(), false)
);
export const validateOCheckLicenseInviteToken =
  T.mkValidator<OCheckLicenseInviteToken>(OCheckLicenseInviteTokenSchema);
