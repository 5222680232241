export default {
  ko: {
    stamp_create: {
      create_stamp: "회사 서명 생성하기",
      create_stamp_placeholder: "서명 이름을 입력해주세요",
      create_stamp_success: "회사 서명 생성 완료",
      update_stamp: "회사 서명 수정하기",
      update_stamp_success: "회사 서명 수정 완료",
      sign_name: "서명 이름",
      sign_description: "서명에 대한 설명",
      tooltip: {
        question: "서명을 등록하면 어디에 쓰나요?",
        titie:
          "등록한 서명을 가지고 NDA에 서명을 하거나 워크스페이스에 초대 받아 워크스페이스 설정에 따라 사인을 하게 되는 경우 사용하실 수 있습니다.해당 서명은 baSalt 약관에 따라 법적 효력을 가지며, 본인의 서명임에 동의합니다.",
      },
      describe_stamp_placeholder: "서명에 대한 설명을 작성해주세요",
    },
  },

  en: {
    stamp_create: {
      create_stamp: "Create company signature",
      create_stamp_placeholder: "Enter the signature name",
      create_stamp_success: "Complete",
      update_stamp: "Update company signature",
      update_stamp_success: "Save",
      sign_name: "Signature name",
      sign_description: "Description of the signature",
      tooltip: {
        question: "If you register your signature, where do you use it?",
        titie:
          "You can use it to sign an NDA with the registered signature, or to be invited to a workspace and sign according to the workspace settings.",
      },
      describe_stamp_placeholder:
        "Please write an explanation about the signature",
    },
  },
};
