import { createStyles, Theme } from "@material-ui/core";
import { theme } from "@theme";

const styles = (MuiTheme: Theme) =>
  createStyles({
    nda_item_container: {
      margin: "0px -8px",
      height: 72,
      paddingTop: 1,
      borderBottom: `solid 1px ${theme.gray_2}`,
    },
    contract_item_grid: {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
    item_text: {
      ...theme.font14,
      color: theme.gray_7_text,
      lineHeight: "20px",
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font10,
      },
    },
    small_text: {
      ...theme.font12,
      color: theme.gray_6,
      lineHeight: "16px",
      width: "100%",
      marginTop: 2,
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font10,
        marginTop: 0,
      },
    },
    nda_date_text: {
      ...theme.font14,
      lineHeight: "20px",
      color: theme.cian_6,
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font10,
      },
    },
    nda_menu_button: {
      width: 40,
      height: 40,
      [MuiTheme.breakpoints.only("xs")]: {
        width: 24,
        height: 24,
      },
    },

    signed_item_container: {
      padding: "6px 24px",
      backgroundColor: theme.gray_1,
      borderRadius: 8,
      height: 72,
    },
    signed_text: {
      ...theme.font14,
      color: theme.gray_7_text,
      width: "90%",
      lineHeight: 1.2,
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font10,
      },
    },
    signed_date_text: {
      ...theme.font16,
      color: theme.indigo_6,
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font10,
      },
    },
    signed_menu_button: {
      width: 44,
      height: 44,
      [MuiTheme.breakpoints.only("xs")]: {
        width: 26,
        height: 26,
      },
    },

    menu_icon: {
      color: theme.gray_7_text,
    },
    menu: {
      ...theme.shadow_2,
      border: `1px solid ${theme.gray_1}`,
      borderRadius: 8,
    },
    menu_list: {
      padding: "7px 0px",
      [MuiTheme.breakpoints.only("xs")]: {
        padding: "5px 0px",
      },
    },
    menu_text: {
      color: theme.gray_9_title,
      ...theme.font14,
      lineHeight: "20px",
      [MuiTheme.breakpoints.only("xs")]: {
        ...theme.font10,
      },
    },
    menu_item: {
      height: 40,
      minHeight: 40,
      minWidth: 118,
      padding: "10px 11px",
      "&:focus-visible": {
        backgroundColor: theme.white,
      },
      "&:hover": {
        backgroundColor: theme.gray_0,
      },
      [MuiTheme.breakpoints.only("xs")]: {
        height: 32,
        minHeight: 32,
        width: 86,
      },
    },
  });

export default styles;
