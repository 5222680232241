import T from "@redwit-commons/utils/typecheck";
import {
  mkResponseSchema,
  GoonoResponse,
  GoonoPendingTasks,
  GoonoArrayResponse,
  mkArrayResultsSchema,
} from "../../api/response/common";
import {
  WorkspaceDBObject,
  WorkspaceDBObjectSchema,
  WorkspaceWithPlan,
  WorkspaceWithPlanSchema,
  WorkspaceWithTeamObject,
  WorkspaceWithTeamObjectSchema,
} from "../object/workspace";
import {
  TokenLogWithUserObject,
  TokenLogWithUserObjectSchema,
} from "../object/token_log";
/**
 * 404: Not found
 * 403: Forbidden
 * 401: Invalid auth
 */
export enum ValidateWorkspaceErrorType {
  NOT_FOUND_WS = "ValidateWorkspacePlanError::NOT_FOUND_WS",
  NOT_FOUND_PLAN = "ValidateWorkspacePlanError::NOT_FOUND_PLAN",
  FORBIDDEN_PLAN = "ValidateWorkspacePlanError::FORBIDDEN_PLAN",
  FORBIDDEN_USER = "ValidateWorkspacePlanError::FORBIDDEN_USER",
  INVALID_AUTH = "ValidateWorkspacePlanError::FORBIDDEN_PLAN",
  EXPIRED_PLAN = "ValidateWorkspacePlanError::EXPIRED_PLAN",
}
/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum JoinWorkspaceErrorType {
  NOT_FOUND_TOKEN = "JoinWorkspaceError::NOT_FOUND_TOKEN",
  NOT_FOUND_WORKSPACE = "JoinWorkspaceError::NOT_FOUND_WORKSPACE",
  NOT_FOUND_PLAN = "JoinWorkspaceError::NOT_FOUND_PLAN",
  INVALID_TOKEN = "JoinWorkspaceError::INVALID_TOKEN",
  INVALID_SHARE_ROLE = "JoinWorkspaceError::INVALID_SHARE_ROLE",
  REJECT_INDIVIDUAL_PLAN = "JoinWorkspaceError::REJECT_INDIVIDUAL_PLAN",
  ALREADY_JOIN = "JoinWorkspaceError::ALREADY_JOIN",
}
/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum KnockWorkspaceErrorType {
  NOT_FOUND_USER = "KnockWorkspaceError::NOT_FOUND_USER",
  NOT_FOUND_WORKSPACE = "KnockWorkspaceError::NOT_FOUND_WORKSPACE",
  NOT_FOUND_ADMIN = "KnockWorkspaceError::NOT_FOUND_ADMIN",
  NEED_ADMIN_APPROVAL = "KnockWorkspaceError::NEED_ADMIN_APPROVAL",
  ALREADY_JOIN = "KnockWorkspaceError::ALREADY_JOIN",
}
/**
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum EditWorkspaceErrorType {
  NOT_FOUND_WORKSPACE = "EditWorkspaceError::NOT_FOUND_WORKSPACE",
  // 이미 존재하는 이름
  DUPLICATED_NAME = "EditWorkspaceError::DUPLICATED_NAME",
  // 요청한 이름이 공백임
  EMPTY_NAME = "EditWorkspaceError::EMPTY_NAME",
  // 요청한 이름은 사용 불가능
  INVALID_NAME = "EditWorkspaceError::INVALID_NAME",
}
/**
 * 403 에러: REJECT
 * 나머지는 400 에러
 */
export enum ODeleteJoinedUserWorkspaceErrorType {
  REJECT_DELETE_USER = "EditWorkspaceError::REJECT_DELETE_USER",
}

// workspace 생성시 private 프로젝트도 생성함.
export type OCreateWorkspace = GoonoResponse<WorkspaceWithTeamObject> &
  GoonoPendingTasks<{
    hlf_create_workspace: string;
    hlf_create_project: string;
  }>;

export type OGetWorkspaces = GoonoArrayResponse<WorkspaceWithTeamObject>;
export type OGetWorkspaceInfo = GoonoResponse<WorkspaceDBObject>;
export type OUpdateWorkspace = GoonoResponse<WorkspaceWithTeamObject>;
export type OShareWorkspace = GoonoResponse<{ token: string }>;
export type OShareWorkspaceLink = GoonoResponse<{ link: string }>;
export type OJoinWorkspace = GoonoResponse<WorkspaceWithTeamObject> &
  GoonoPendingTasks<{
    hlf_create_workspace: string;
    hlf_create_project?: string;
  }>;
export type ODeleteJoinedUserWorkspace = GoonoResponse<string>;
export type OUpdateJoinedUserWorkspace = GoonoResponse<WorkspaceWithTeamObject>;
export type OCreateTeam = GoonoResponse<WorkspaceWithTeamObject>;
export type OUpdateTeam = GoonoResponse<WorkspaceWithTeamObject>;
export type ODeleteJoinedUserTeam = GoonoResponse<string>;
export type ODeleteTeam = GoonoResponse<string>;
export type ODeleteWorkspace = GoonoResponse<string>;
export type OGetAllWorkspaces = GoonoArrayResponse<WorkspaceWithTeamObject>;
export type OGetWorkspaceShareEmailList =
  GoonoArrayResponse<TokenLogWithUserObject>;
export type OKnockWorkspace = GoonoResponse<string>;
export type OGetWorkspacePlan = GoonoResponse<WorkspaceWithPlan>;
export type OUpdateWorkspaceRoleSharedEmail =
  GoonoResponse<TokenLogWithUserObject>;
export type ODeleteWorkspaceSharedEmail = GoonoResponse<string>;

export const OCreateWorkspaceSchema = mkResponseSchema(
  WorkspaceWithTeamObjectSchema,
  T.object()
    .addField("hlf_create_workspace", T.string())
    .addField("hlf_create_project", T.string())
);
export const OGetWorkspacesSchema = mkArrayResultsSchema(
  WorkspaceWithTeamObjectSchema
);
export const OGetWorkspaceInfoSchema = mkResponseSchema(
  WorkspaceDBObjectSchema
);
export const OUpdateWorkspaceSchema = mkResponseSchema(
  WorkspaceWithTeamObjectSchema
);
export const OShareWorkspaceSchema = mkResponseSchema(
  T.object().addField("token", T.string())
);
export const OShareWorkspaceLinkSchema = mkResponseSchema(
  T.object().addField("link", T.string())
);
export const OJoinWorkspaceSchema = mkResponseSchema(
  WorkspaceWithTeamObjectSchema,
  T.object()
    .addField("hlf_create_workspace", T.string())
    .addField("hlf_create_project", T.string(), false)
);
export const ODeleteJoinedUserWorkspaceSchema = mkResponseSchema(T.string());
export const OUpdateJoinedUserWorkspaceSchema = mkResponseSchema(
  WorkspaceWithTeamObjectSchema
);
export const OCreateTeamSchema = mkResponseSchema(
  WorkspaceWithTeamObjectSchema
);
export const OUpdateTeamSchema = mkResponseSchema(
  WorkspaceWithTeamObjectSchema
);
export const ODeleteJoinedUserTeamSchema = mkResponseSchema(T.string());
export const ODeleteTeamSchema = mkResponseSchema(T.string());
export const ODeleteWorkspaceSchema = mkResponseSchema(T.string());
export const OGetAllWorkspacesSchema = mkArrayResultsSchema(
  WorkspaceWithTeamObjectSchema
);
export const OGetWorkspaceShareEmailListSchema = mkArrayResultsSchema(
  TokenLogWithUserObjectSchema
);
export const OKnockWorkspaceSchema = mkResponseSchema(T.string());
export const OGetWorkspacePlanSchema = mkResponseSchema(
  WorkspaceWithPlanSchema
);
export const OUpdateWorkspaceRoleSharedEmailSchema = mkResponseSchema(
  TokenLogWithUserObjectSchema
);
export const ODeleteWorkspaceSharedEmailSchema = mkResponseSchema(T.string());

export const validateOCreateWorkspace = T.mkValidator<OCreateWorkspace>(
  OCreateWorkspaceSchema
);
export const validateOGetWorkspaces =
  T.mkValidator<OGetWorkspaces>(OGetWorkspacesSchema);
export const validateOGetWorkspaceInfo = T.mkValidator<OGetWorkspaceInfo>(
  OGetWorkspaceInfoSchema
);
export const validateOUpdateWorspace = T.mkValidator<OUpdateWorkspace>(
  OUpdateWorkspaceSchema
);
export const validateOShareWorkspace = T.mkValidator<OShareWorkspace>(
  OShareWorkspaceSchema
);
export const validateOShareWorkspaceLink = T.mkValidator<OShareWorkspaceLink>(
  OShareWorkspaceLinkSchema
);
export const validateOJoinWorkspace =
  T.mkValidator<OJoinWorkspace>(OJoinWorkspaceSchema);
export const validateODeleteJoinedUserWorkspace =
  T.mkValidator<ODeleteJoinedUserWorkspace>(ODeleteJoinedUserWorkspaceSchema);
export const validateOUpdateJoinedUserWorkspace =
  T.mkValidator<OUpdateJoinedUserWorkspace>(OUpdateJoinedUserWorkspaceSchema);
export const validateOCreateTeam =
  T.mkValidator<OCreateTeam>(OCreateTeamSchema);
export const validateOUpdateTeam =
  T.mkValidator<OUpdateTeam>(OUpdateTeamSchema);
export const validateODeleteJoinedUserTeam =
  T.mkValidator<ODeleteJoinedUserTeam>(ODeleteJoinedUserTeamSchema);
export const validateODeleteTeam =
  T.mkValidator<ODeleteTeam>(ODeleteTeamSchema);
export const validateODeleteWorkspace = T.mkValidator<ODeleteWorkspace>(
  ODeleteWorkspaceSchema
);
export const validateOGetAllWorkspaces = T.mkValidator<OGetAllWorkspaces>(
  OGetAllWorkspacesSchema
);
export const validateOGetWorkspaceShareEmailList =
  T.mkValidator<OGetWorkspaceShareEmailList>(OGetWorkspaceShareEmailListSchema);
export const validateOKnockWorkspace = T.mkValidator<OKnockWorkspace>(
  OKnockWorkspaceSchema
);
export const validateOGetWorkspacePlan = T.mkValidator<OGetWorkspacePlan>(
  OGetWorkspacePlanSchema
);
export const validateOUpdateWorkspaceRoleSharedEmail =
  T.mkValidator<OUpdateWorkspaceRoleSharedEmail>(
    OUpdateWorkspaceRoleSharedEmailSchema
  );
export const validateODeleteWorkspaceSharedEmail =
  T.mkValidator<ODeleteWorkspaceSharedEmail>(ODeleteWorkspaceSharedEmailSchema);
