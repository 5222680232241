import React from "react";

type LoadingWrapProps = {
  loading: boolean;
  loadingComponent: JSX.Element;
  children?: any;
};

const LoadingWrap = (props: LoadingWrapProps) => {
  const { loading, loadingComponent, children } = props;

  if (loading) {
    return <>{loadingComponent}</>;
  }
  return children;
};

export default LoadingWrap;
