import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  SnackbarActionKind,
  SnackBarState,
  SnackbarStateStatus,
  snackbarStateMachine,
  SnackbarStateMachineType,
  SnackBarAction,
} from "../../store/reducers/snackbar";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.snackbar,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SnackbarContainerProps = PropsFromRedux & {
  stateMachine: SnackbarStateMachineType;
};

class SnackbarContainer extends ReduxStateComponent3<SnackbarContainerProps> {
  static defaultProps = { stateMachine: snackbarStateMachine };
  constructor(props: SnackbarContainerProps) {
    super(props);
  }

  protected onAction(
    prevState: SnackBarState,
    action: SnackBarAction
  ): SnackBarState {
    switch (action.kind) {
      case SnackbarActionKind.TRY_OPEN: {
        return {
          status: SnackbarStateStatus.OPENED,
          ...action,
        };
      }
      case SnackbarActionKind.TRY_CLOSE: {
        if (
          prevState.status === SnackbarStateStatus.OPENED &&
          prevState.onClose !== undefined
        ) {
          prevState.onClose();
        }

        return {
          status: SnackbarStateStatus.INIT,
        };
      }
    }
  }
}

export default connector(SnackbarContainer);
