import React from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import { theme } from "@theme";

export type SpoqaHansTypographyProps = {
  fontWeight: "regular" | "medium" | "bold" | "thin" | "light";
  style: React.CSSProperties;
  className: string | undefined;
  id: string | undefined;
  noWrap: boolean;
} & TypographyProps;

type SpoqaHansTypographyState = {};

class SpoqaHansTypography extends React.PureComponent<
  SpoqaHansTypographyProps,
  SpoqaHansTypographyState
> {
  static defaultProps = {
    style: {},
    fontWeight: "regular",
    id: undefined,
    className: undefined,
    noWrap: false,
  };
  constructor(props: SpoqaHansTypographyProps) {
    super(props);
  }

  fontFamily() {
    const { fontWeight } = this.props;
    switch (fontWeight) {
      case "bold":
        return theme.spoqahans_bold;
      case "regular":
        return theme.spoqahans_regular;
      case "medium":
        return theme.spoqahans_medium;
      case "light":
        return theme.spoqahans_light;
      case "thin":
        return theme.spoqahans_thin;
    }
  }

  render() {
    const { style, className, id, noWrap } = this.props;
    const fontFamily = this.fontFamily();
    return (
      <Typography
        {...this.props}
        noWrap={noWrap}
        id={id}
        style={{ ...fontFamily, ...style, textTransform: "none" }}
        className={className}
      >
        {this.props.children}
      </Typography>
    );
  }
}

export default SpoqaHansTypography;
