import React from "react";
import {
  Grid,
  Menu,
  MenuItem,
  WithStyles,
  withStyles,
  IconButton,
} from "@material-ui/core";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import { ContractObject } from "@basalt-commons/global-api/object/nda_contract";
import { DocumentWithUserObject } from "@basalt-commons/global-api/object/document";
import SpoqaHansTypography from "@SpoqaHansTypography";
import moment from "moment-timezone";
import styles from "./styles/ContractItem";
import { theme } from "@theme";
import translate from "../../../utils/translate";
import { ReactComponent as FileIcon } from "@ui/basalt/images/modals/ConnectedFilesModal/file.svg";
import OverFlowTextTooltip from "@src/components/pure/utils/OverFlowTextTooltip";

type ContractItemProps = {
  contract: ContractObject;
  doc: DocumentWithUserObject | undefined;
  time_zone: string;
  format: string;
  workspace?: string;
  onDownloadNDAContract: () => void;
  onShareSignedContract?: () => void;
  onClickContract: () => void;
} & WithStyles<typeof styles>;

type ContractItemState = {
  anchorEl: null | HTMLElement;
  hover: boolean;
  backDrop: boolean;
  open: boolean;
};

class ContractItem extends React.PureComponent<
  ContractItemProps,
  ContractItemState
> {
  constructor(props: ContractItemProps) {
    super(props);
    this.state = {
      anchorEl: null,
      hover: false,
      backDrop: false,
      open: false,
    };
  }

  renderMenu() {
    const { onShareSignedContract, onDownloadNDAContract, classes } =
      this.props;
    return (
      <Menu
        getContentAnchorEl={undefined}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.anchorEl)}
        onClose={(evt: React.MouseEvent<HTMLLIElement, MouseEvent>, reason) => {
          evt.stopPropagation();
          this.setState({ anchorEl: null });
          if (reason == "backdropClick") {
            this.setState({ backDrop: true, hover: false });
          }
        }}
        PaperProps={{ className: classes.menu }}
        classes={{ list: classes.menu_list }}
      >
        {onShareSignedContract && (
          <MenuItem
            className={classes.menu_item}
            onClick={(evt: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
              evt.stopPropagation();
              onShareSignedContract();
            }}
          >
            <SpoqaHansTypography className={classes.menu_text}>
              {translate.nda_contract_item.email}
            </SpoqaHansTypography>
          </MenuItem>
        )}

        <MenuItem
          className={classes.menu_item}
          onClick={(evt: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            evt.stopPropagation();
            onDownloadNDAContract();
          }}
        >
          <SpoqaHansTypography className={classes.menu_text}>
            {translate.nda_contract_item.download}
          </SpoqaHansTypography>
        </MenuItem>
      </Menu>
    );
  }

  render() {
    const { doc, contract, time_zone, workspace, format, classes } = this.props;

    return (
      <Grid
        container
        alignItems="center"
        spacing={2}
        className={
          workspace ? classes.signed_item_container : classes.nda_item_container
        }
        style={{
          backgroundColor:
            this.state.hover || this.state.anchorEl
              ? theme.gray_0
              : theme.white,
        }}
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
        onClick={(evt) => {
          evt.stopPropagation();
          if (this.state.backDrop) {
            this.setState({ backDrop: false });
            return;
          }
          this.props.onClickContract();
        }}
      >
        <Grid xs={3} container item className={classes.contract_item_grid}>
          <OverFlowTextTooltip
            arrow
            title={`${workspace ? contract.file_name : doc?.file_name}`}
            placement="bottom"
            viewOverflow={true}
          >
            <SpoqaHansTypography
              noWrap
              className={classes.item_text}
              style={{ paddingLeft: 8 }}
            >
              {workspace ? contract.file_name : doc?.file_name}
            </SpoqaHansTypography>
          </OverFlowTextTooltip>
        </Grid>
        <Grid
          xs={3}
          container
          item
          className={classes.contract_item_grid}
          alignItems="center"
          style={{ flexWrap: "nowrap" }}
        >
          <FileIcon style={{ minWidth: 24, marginRight: 4 }} />
          <SpoqaHansTypography noWrap className={classes.item_text}>
            {workspace ?? contract.file_name}
          </SpoqaHansTypography>
        </Grid>
        <Grid
          xs={2}
          container
          item
          className={classes.contract_item_grid}
          direction="column"
        >
          <SpoqaHansTypography
            noWrap
            className={workspace ? classes.signed_text : classes.item_text}
            style={{ width: "100%" }}
          >
            {doc?.nda_contractor_name}
          </SpoqaHansTypography>
          <SpoqaHansTypography
            noWrap
            fontWeight="medium"
            className={classes.small_text}
          >
            {doc?.nda_contractor_department}
          </SpoqaHansTypography>
        </Grid>
        <Grid
          xs={2}
          container
          item
          className={classes.contract_item_grid}
          direction="column"
        >
          <SpoqaHansTypography
            noWrap
            className={workspace ? classes.signed_text : classes.item_text}
          >
            {contract.client_name}
          </SpoqaHansTypography>
          <SpoqaHansTypography noWrap className={classes.small_text}>
            {contract.client_department}
          </SpoqaHansTypography>
        </Grid>
        <Grid
          xs={2}
          container
          item
          className={classes.contract_item_grid}
          justifyContent="space-between"
          wrap="nowrap"
          alignItems="center"
        >
          <SpoqaHansTypography
            noWrap
            className={
              workspace ? classes.signed_date_text : classes.nda_date_text
            }
          >
            {moment(contract.createdAt).tz(time_zone).format(format)}
          </SpoqaHansTypography>
          <IconButton
            onClick={(evt) => {
              evt.stopPropagation();
              this.setState({ anchorEl: evt.currentTarget });
            }}
            className={
              workspace ? classes.signed_menu_button : classes.nda_menu_button
            }
          >
            <MoreVertRoundedIcon className={classes.menu_icon} />
          </IconButton>
          {this.renderMenu()}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ContractItem);
