import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";

export enum LicenseTokenStatusType {
  INITIALIZED = "LicenseToken::INITIALIZED",
  COMPLETED = "LicenseToken::COMPLETED",
  CANCELLED = "LicenseToken::CANCELLED",
}

export interface LicenseTokenLogCore {
  readonly licenseToken: string;
  readonly status: LicenseTokenStatusType | string;
  readonly email: string;
  readonly LicenseId: string;
}

export type LicenseTokenLogObject = LicenseTokenLogCore & AccessTime;
export const LicenseTokenLogObjectSchema = withAccessTime()
  .addField("licenseToken", T.string())
  .addField("status", T.string())
  .addField("email", T.string())
  .addField("LicenseId", T.string());

export const extractLicenseTokenLogObject =
  T.mkObjectExtractor<LicenseTokenLogObject>(LicenseTokenLogObjectSchema);
export const validateLicenseTokenLogObject =
  T.mkValidator<LicenseTokenLogObject>(LicenseTokenLogObjectSchema);
