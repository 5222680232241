import { doAPIPostRequest, doAPIGetRequest, doAPIDeleteRequest } from "./api";
import {
  IGetNotes,
  ICreateNote,
  IGetAuthNotes,
  IGetNotePDF,
  ICreateNoteTags,
  IDeleteNoteTag,
} from "@basalt-commons/api/request/note";
import {
  OGetNotes,
  validateOGetNotes,
  validateOGetNotePDF,
  validateOCreateNote,
  OCreateNote,
  validateOCreateNoteTags,
  validateODeleteNoteTag,
} from "@basalt-commons/api/response/note";
import { NoteObject } from "@basalt-commons/api/object/note";

// OGetNote 형태 전체로 return
const getNotes = async (
  token: string,
  proejctID: string,
  args?: IGetNotes
): Promise<OGetNotes> => {
  const obj = await doAPIGetRequest(`projects/${proejctID}/notes`, token, args);
  const ret = validateOGetNotes(obj);
  return ret;
};

// NoteObject return
const createNote = async (
  token: string,
  proejctID: string,
  args: ICreateNote
): Promise<NoteObject> => {
  const res = await doAPIPostRequest(`projects/${proejctID}/note`, token, args);
  const ret = validateOCreateNote(res);
  return ret.response;
};

const createNoteWithPreview = async (
  token: string,
  proejctID: string,
  args: ICreateNote
): Promise<OCreateNote> => {
  const res = await doAPIPostRequest(`projects/${proejctID}/note`, token, args);
  const ret = validateOCreateNote(res);
  return ret;
};

// TODO: Specify return types
const deleteNote = async (token: string, proejctID: string, noteID: string) => {
  return await doAPIDeleteRequest(
    `projects/${proejctID}/note/${noteID}`,
    token
  );
};

// TODO: Specify return types
const getAuthNotes = async (token: string, args: IGetAuthNotes) => {
  return await doAPIGetRequest(`authNotes`, token, args);
};

const getPdfUrl = async (
  token: string,
  projectID: string,
  args: IGetNotePDF
) => {
  return validateOGetNotePDF(
    await doAPIPostRequest(`projects/${projectID}/pdf`, token, args)
  );
};

const createNoteTags = async (
  token: string,
  noteId: string,
  args: ICreateNoteTags
) => {
  const res = await doAPIPostRequest(`note/${noteId}/tags`, token, args);
  const ret = validateOCreateNoteTags(res);
  return ret;
};

const deleteNoteTag = async (
  token: string,
  noteId: string,
  args: IDeleteNoteTag
) => {
  const res = await doAPIDeleteRequest(`note/${noteId}/tag`, token, args);
  return validateODeleteNoteTag(res);
};

export default {
  getNotes,
  createNote,
  deleteNote,
  getAuthNotes,
  getPdfUrl,
  createNoteWithPreview,
  createNoteTags,
  deleteNoteTag,
};
