import { WorkspaceWithTeamObject } from "@basalt-commons/global-api/object/workspace";
import { InternalErrorKind, isErr } from "@redwit-commons/utils/exception2";
import { doNoteActionAsync, NoteActionKind } from "@src/store/reducers/note";
import {
  doProjectActionAsync,
  ProjectActionKind,
} from "@src/store/reducers/project";
import { AnyAction, Dispatch } from "redux";
import {
  WorkspaceActionKind,
  doWorkspaceActionAsync,
} from "../store/reducers/workspace";
import { BaseGridMode } from "@src/store/reducers/basegrid";
import { ValidateWorkspaceErrorType } from "@basalt-commons/global-api/response/workspace";
import translate from "./translate";

export const fetchWorkspace = async (
  dispatch: Dispatch<AnyAction>,
  workspace_name: string
) => {
  try {
    await doWorkspaceActionAsync(dispatch, {
      kind: WorkspaceActionKind.TRY_GET_WORKSPACE,
    });
    await doWorkspaceActionAsync(dispatch, {
      kind: WorkspaceActionKind.TRY_SELECT_WORKSPACE,
      name: workspace_name,
    });
  } catch (error) {
    throw error;
  }
};

export const handleDidMountError = (err: unknown) => {
  if (isErr(err)) {
    if (err.kind === InternalErrorKind.Network)
      return { mode: BaseGridMode.FAILED, code: 404 };
    if (err.kind === InternalErrorKind.ResponseCode)
      return { mode: BaseGridMode.FAILED, code: 500 };
  }
  return { mode: BaseGridMode.FAILED, code: 520 };
};

export const fetchProjectWithNotes = async (
  dispatch: Dispatch<AnyAction>,
  targetWorkspace: WorkspaceWithTeamObject,
  project_id: string
) => {
  try {
    await doProjectActionAsync(dispatch, {
      kind: ProjectActionKind.TRY_GET_PROJECTS,
      workspaceName: targetWorkspace.name,
      id: project_id,
    });
    await doNoteActionAsync(dispatch, {
      kind: NoteActionKind.TRY_GET_NOTES,
      projectId: project_id,
      workspaceId: targetWorkspace.id,
    });
  } catch (error) {
    throw error;
  }
};

export const handleValidateWorkspaceError = (err: unknown) => {
  if (isErr(err) && err.kind === InternalErrorKind.ResponseCode) {
    if (err.code === 404) {
      if (err.msg === ValidateWorkspaceErrorType.NOT_FOUND_WS) {
        return translate.snackbar.not_found_workspace;
      }
      if (err.msg === ValidateWorkspaceErrorType.NOT_FOUND_PLAN) {
        return translate.snackbar.not_found_plan;
      }
    } else if (err.code === 403) {
      if (err.msg === ValidateWorkspaceErrorType.FORBIDDEN_USER) {
        return translate.snackbar.forbidden_user;
      }
      if (err.msg === ValidateWorkspaceErrorType.FORBIDDEN_PLAN) {
        return translate.snackbar.forbidden_plan;
      }
    } else if (err.code === 401) {
      if (err.msg === ValidateWorkspaceErrorType.INVALID_AUTH) {
        return translate.snackbar.invalid_auth;
      }
    } else if (err.code === 400) {
      if (err.msg === ValidateWorkspaceErrorType.EXPIRED_PLAN) {
        return translate.snackbar.expired_plan;
      }
    }
  }
  return undefined;
};
