import {
  heicExtensionList,
  imageExtensionList,
  officeExtensionList,
  pdfExtensionList,
} from "@basalt-commons/api/request/note";
import { Grid, Typography, Slide, Snackbar } from "@material-ui/core";
import React from "react";
import { useEffect, useState } from "react";
import { BaseMobileGuide } from "./styles/BaseGrid";

// callback function을 인자로 받습니다 (BaseGrid에서 사용할 때 width, height를 screen 단에 넘겨주기 위함)
export const WindowSizeHandler = (
  callback?: (w: number, h: number, m?: boolean) => void
) => {
  const mobileWidth = BaseMobileGuide; // mobile 기준 width, 모바일 가이드를 바꾸고 싶으면 여기를 수정하세요
  let unmounted = false;
  let WindowWidth: number = window.innerWidth; // 현재 스크린의 가로 길이
  let WindowHeight: number = window.innerHeight; // 현재 스크린의 세로 길이
  let MobileMode: boolean = window.innerWidth < mobileWidth ? true : false; // mobileMode에서의 view 상태

  const [timer, setTimer] = useState(Date.now());
  const windowResizeHandler = (_evt: UIEvent) => {
    if (unmounted === true) return;
    const currWidth = window.innerWidth;
    const currHeight = window.innerHeight;
    const currMobileMode = window.innerWidth < mobileWidth ? true : false;

    if (currWidth !== WindowWidth) {
      WindowWidth = currWidth;
    }

    if (currHeight !== WindowHeight) {
      WindowHeight = currHeight;
    }

    if (currMobileMode !== MobileMode) {
      MobileMode = currMobileMode;
    }
    if (callback !== undefined) {
      callback(currWidth, currHeight, MobileMode);
    }
    setTimeout(() => {
      setTimer(Date.now());
    }, 300);
    return;
  };

  useEffect(() => {
    window.addEventListener("resize", windowResizeHandler, true);
    return () => {
      window.removeEventListener("resize", windowResizeHandler, true);
      unmounted = true;
    };
  }, [timer]);

  const isMobile = () => MobileMode;
  const getWindowWidth = () => WindowWidth;
  const getWindowHeight = () => WindowHeight;

  return {
    isMobile,
    getWindowWidth,
    getWindowHeight,
  };
};

type FileDropHandlerProps = {
  filesUpload: (files: FileList) => void;
  dropStopCond: boolean;
};

export const FileDropHandler: React.FC<FileDropHandlerProps> = (props) => {
  const [isDragging, setDragging] = useState(false);
  const allowedExtends = [
    ...imageExtensionList,
    ...heicExtensionList,
    ...pdfExtensionList,
    ...officeExtensionList,
  ];
  const handleDragEnter = (evt: DragEvent) => {
    evt.preventDefault();
    const { dropStopCond } = props;
    if (dropStopCond === true) {
      return;
    }
    setDragging(true);
  };

  const handleDragLeave = (evt: DragEvent) => {
    evt.preventDefault();

    setDragging(false);
  };

  const handleDragOver = (evt: DragEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { dropStopCond } = props;
    if (dropStopCond === true) {
      return;
    }
    if (isDragging === false) setDragging(true);
  };

  const handleDrop = (evt: DragEvent) => {
    const { filesUpload, dropStopCond } = props;
    evt.preventDefault();
    if (dropStopCond === true) {
      alert("Uploading...");
      return;
    }
    if (isDragging === false) return;
    if (evt.dataTransfer === null) return;
    if (evt.dataTransfer.files.length < 1) return;
    const files = evt.dataTransfer.files;
    const fileList = [...files];
    const filterCond = fileList.some((f) => {
      const fileType = f.name.split(".").slice(-1)[0].toLowerCase();
      return !allowedExtends.includes(fileType);
    });
    if (filterCond === true) {
      alert("invalid file extenstion");
      setDragging(false);
      return;
    }
    setDragging(false);
    filesUpload(files);
  };

  useEffect(() => {
    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("drop", handleDrop);

    return () => {
      window.removeEventListener("dragenter", handleDragEnter, true);
      window.removeEventListener("dragleave", handleDragLeave, true);
      window.removeEventListener("dragover", handleDragOver, true);
      window.removeEventListener("drop", handleDrop);
    };
  });
  return (
    <Snackbar
      open={isDragging}
      autoHideDuration={2000}
      TransitionComponent={(props) => <Slide {...props} direction="up" />}
    >
      <Grid>
        <Typography>{"Drag files an create notes"}</Typography>
      </Grid>
    </Snackbar>
  );
};
