import { Avatar, Box, Button, Grid, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { theme } from "@theme";
import { ReactComponent as GnBLogo } from "@ui/basalt/images/sidebar/gnb-logo-40.svg";
import GnBFolder from "@ui/basalt/images/sidebar/gnb-folder-40.svg";
import GnBSecurity from "@ui/basalt/images/sidebar/gnb-security-40.svg";
import GnBSetting from "@ui/basalt/images/sidebar/gnb-setting-40.svg";
import GnBActivity from "@ui/basalt/images/sidebar/gnb-activity-40.svg";
import { ReactComponent as IconAddMember } from "@ui/basalt/images/sidebar/ic-add-member-24.svg";
import { ReactComponent as IconHelp } from "@ui/basalt/images/sidebar/ic-help-24.svg";
import { ReactComponent as IconSearch } from "@ui/basalt/images/sidebar/ic-search-24.svg";
import { ReactComponent as IconPerson } from "@ui/basalt/images/sidebar/ic-person-20.svg";
import WorkspaceIcon from "../components/pure/buttons/WorkspaceIcon";
import { WorkspaceWithTeamObject } from "@basalt-commons/global-api/object/workspace";
import SpoqaHansTypography from "@SpoqaHansTypography";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import { useHistory, useLocation } from "react-router-dom";
import AccountMenu from "./AccountMenu";
import SidebarWorkspaceMenu from "./SidebarWorkspaceMenu";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";
import { NDA_TAB_KIND } from "@src/screens/NDAScreen";

const drawerWidth = 220;
const appBarWidth = 56;

const useWorkspaceMenuStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: 56,
    borderBottom: `1px solid ${theme.gray_2}`,
    borderRadius: 0,
  },
  workspace_name: {
    ...theme.font14,
    color: theme.black,
    maxWidth: 136,
    marginLeft: 6,
    marginRight: "auto",
  },
  arrow_icon: {
    ...theme.icon_24,
  },
}));

type WorkspaceMenuProps = {
  currentWorkspace: WorkspaceWithTeamObject;
  workspaces: WorkspaceWithTeamObject[];
};
const WorkspaceMenuView = ({ currentWorkspace }: WorkspaceMenuProps) => {
  const classes = useWorkspaceMenuStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onWorkspaceMenu = (value: null | HTMLElement) => {
    setAnchorEl(value);
  };
  return (
    <Button className={classes.root} disableRipple>
      <Grid
        container
        alignItems="center"
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
        }}
      >
        <WorkspaceIcon
          isSmall
          key={currentWorkspace.id}
          workspace={currentWorkspace}
          index={0}
        />
        <SpoqaHansTypography
          fontWeight="bold"
          noWrap
          className={classes.workspace_name}
        >
          {currentWorkspace.name}
        </SpoqaHansTypography>
        <KeyboardArrowDownRoundedIcon className={classes.arrow_icon} />
      </Grid>
      <SidebarWorkspaceMenu
        currentWorkspace={currentWorkspace}
        anchorEl={anchorEl}
        setAnchorEl={onWorkspaceMenu}
      />
    </Button>
  );
};

const useStyles = makeStyles((MuiTheme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    left: 0,
    width: appBarWidth,
    height: "100%",
    position: "fixed",
    zIndex: 1211,
    backgroundColor: theme.primary_indigo,
    [MuiTheme.breakpoints.down(600)]: {
      left: -295,
    },
  },
  appBarOpened: {
    left: 0,
  },
  main_nav_icon_button: {
    width: appBarWidth,
    height: appBarWidth,
    backgroundColor: theme.indigo_6,
    borderBottom: `1px solid ${theme.indigo_9}`,
    boxSizing: "border-box",
  },
  main_nav_icon: {
    ...theme.icon_40,
    marginTop: 8,
    marginLeft: 8,
    // TODO: should be fixed
    // [MuiTheme.breakpoints.up(1024)]: {
    //   cursor: "auto",
    // },
  },
  nav_grid: {
    flexDirection: "column",
    marginTop: 12,
    [MuiTheme.breakpoints.down(600)]: {},
  },
  icon_button: {
    ...theme.icon_40,
    margin: 4,
    borderRadius: 8,
    opacity: ".7",
    transition: "0.3s",
    "&:hover": {
      opacity: 1,
    },
  },
  icon_clicked: {
    backgroundColor: theme.indigo_9,
    opacity: "1",
  },
  icon_hidden: {
    [MuiTheme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
  bottom_nav_icon: {
    ...theme.icon_24,
    marginTop: 8,
    marginLeft: 8,
  },
  bottom_nav_icon_person_background: {
    ...theme.icon_32,
    backgroundColor: theme.cian_4,
    borderRadius: "50%",
  },
  bottom_nav_icon_person: {
    ...theme.icon_20,
    marginTop: 6,
    marginLeft: 6,
  },
  bottom_nav_grid: {
    width: appBarWidth,
    bottom: 36,
    position: "fixed",
    "& $icon_button": {
      opacity: "1",
    },
  },
  drawer: {
    width: drawerWidth,
    height: "100%",
    position: "fixed",
    boxSizing: "border-box",
    zIndex: 1201,
    backgroundColor: theme.gray_0,
    borderRight: `1px solid ${theme.gray_2}`,
    left: appBarWidth,
    [MuiTheme.breakpoints.down(1024)]: {
      left: -295,
    },
    transition: MuiTheme.transitions.create("left", {
      easing: MuiTheme.transitions.easing.easeOut,
      duration: MuiTheme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    left: appBarWidth,
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 2,
    opacity: 0,
    [MuiTheme.breakpoints.up(1024)]: {
      display: "none",
    },
    transition: MuiTheme.transitions.create("opacity", {
      easing: MuiTheme.transitions.easing.easeInOut,
      duration: MuiTheme.transitions.duration.leavingScreen,
    }),
  },
  overlayOpen: {
    opacity: 1,
  },
  profile_image: {
    ...theme.icon_32,
    objectFit: "cover",
    borderRadius: "50%",
  },
}));

type SidebarComponentProps = {
  currentWorkspace: WorkspaceWithTeamObject;
  workspaces: WorkspaceWithTeamObject[];
  drawerInnerComponent: JSX.Element;
  onClickSearch: () => void;
  onClickHelp: () => void;
  onInviteWorkspace: () => void;
  userProfileCid: string | undefined;
  setOpen: (state: boolean) => void;
  open?: boolean;
};

const getNavigations = (workspace_name: string) => {
  const navigations = [
    {
      path: `/${workspace_name}/projects`,
      icon: GnBFolder,
    },
    {
      path: `/${workspace_name}/ndas?tab=${NDA_TAB_KIND.NDA}`,
      icon: GnBSecurity,
    },
    {
      path: `/${workspace_name}/timeline`,
      icon: GnBActivity,
    },
    {
      path: `/${workspace_name}/settings`,
      icon: GnBSetting,
    },
  ];
  return navigations;
};

export default function SidebarComponent(props: SidebarComponentProps) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const router = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onAnchorElHandler = (value: null | HTMLElement) => {
    setAnchorEl(value);
  };
  const { userProfileCid } = props;

  const sidebarHiddenUrls = [`/${props.currentWorkspace.name}/rule/create`];
  const sidebarHidden = sidebarHiddenUrls.some((url) => pathname.includes(url));

  useEffect(() => {
    {
      if (sidebarHidden) {
        props.setOpen(false);
      }
    }
  }, []);

  const renderAppBar = () => {
    return (
      <Box className={clsx(classes.appBar, props.open && classes.appBarOpened)}>
        <Box
          className={classes.main_nav_icon_button}
          onClick={() => {
            // TODO: should be fixed
            router.push("/");
          }}
        >
          <GnBLogo className={classes.main_nav_icon} />
        </Box>
        {renderNavigationTabs()}
        {renderBottomNavBox()}
      </Box>
    );
  };

  const renderNavigationTabs = () => {
    return (
      <Grid container alignItems="center" className={classes.nav_grid}>
        {getNavigations(props.currentWorkspace.name).map(
          ({ path, icon }, idx) => {
            return (
              <Grid
                key={idx}
                item
                className={clsx(
                  classes.icon_button,
                  pathname.includes(path.split("?")[0]) && classes.icon_clicked,
                  idx === 1 && classes.icon_hidden
                )}
                onClick={() => {
                  if (screen.width < 1024 && pathname.includes(path)) {
                    props.setOpen(!props.open);
                  } else {
                    if (props.open === false) {
                      props.setOpen(true);
                    }
                    router.push(idx === 3 ? `${path}?tab=setting` : path);
                  }
                }}
              >
                <img src={icon} />
              </Grid>
            );
          }
        )}
      </Grid>
    );
  };

  const renderBottomNavBox = () => {
    return (
      <Box className={classes.bottom_nav_grid}>
        <Grid container direction="column" alignItems="center">
          <Grid
            item
            className={classes.icon_button}
            onClick={() => props.onClickSearch()}
          >
            <IconSearch className={classes.bottom_nav_icon} />
          </Grid>
          <Grid item className={classes.icon_button}>
            <IconAddMember
              className={classes.bottom_nav_icon}
              onClick={() => props.onInviteWorkspace()}
            />
          </Grid>
          <Grid item className={classes.icon_button}>
            <IconHelp
              className={classes.bottom_nav_icon}
              onClick={() => props.onClickHelp()}
            />
          </Grid>
          <Grid
            className={clsx(
              classes.icon_button,
              classes.bottom_nav_icon_person_background
            )}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            {userProfileCid ? (
              <Avatar
                src={getIPFSUrl(userProfileCid)}
                className={classes.profile_image}
              />
            ) : (
              <IconPerson
                className={clsx(
                  classes.bottom_nav_icon,
                  classes.bottom_nav_icon_person
                )}
              />
            )}
          </Grid>
        </Grid>
        <AccountMenu
          anchorEl={anchorEl}
          setAnchorEl={onAnchorElHandler}
          anchorOrigin={{ horizontal: "right", vertical: "center" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
        />
      </Box>
    );
  };

  const renderDrawer = () => {
    return (
      <Box className={clsx(classes.drawer, props.open && classes.drawerOpen)}>
        <WorkspaceMenuView
          currentWorkspace={props.currentWorkspace}
          workspaces={props.workspaces}
        />
        {props.drawerInnerComponent}
      </Box>
    );
  };

  const renderOverlay = () => {
    return (
      <Box
        className={clsx(classes.overlay, props.open && classes.overlayOpen)}
        onClick={() => {
          props.setOpen(!props.open);
        }}
        style={{ pointerEvents: !props.open ? "none" : "auto" }}
      ></Box>
    );
  };

  return (
    <div className={classes.root}>
      {renderAppBar()}
      {renderDrawer()}
      {renderOverlay()}
    </div>
  );
}
