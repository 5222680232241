import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { getRandomBackgroundColor, getRandomColor, theme } from "@theme";
import { WorkspaceObject } from "@basalt-commons/global-api/object/workspace";
import SpoqaHansTypography from "../typo/SpoqaHansTypography";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";

type RandomWorkspaceIconProps = {
  workspace: WorkspaceObject;
  index: number;
  isIcon24?: boolean;
};

const useStyles = makeStyles({
  icon_box: {
    width: 56,
    height: 56,
    borderRadius: 6,
  },
  icon_box_small: {
    width: 24,
    height: 24,
  },
  icon_grid: {
    cursor: "pointer",
    width: 32,
    height: 32,
    borderRadius: 6,
  },
  icon_grid_small: {
    cursor: "pointer",
    width: 24,
    height: 24,
    borderRadius: 6,
  },
  image: { width: 32, height: 32, objectFit: "cover", borderRadius: 6 },
  image_small: { width: 24, height: 24, objectFit: "cover", borderRadius: 6 },
  title: { ...theme.font20, color: theme.white, lineHeight: "normal" },
  title_small: { ...theme.font14, color: theme.white, lineHeight: "normal" },
});

const RandomWorkspaceIcon = (props: RandomWorkspaceIconProps) => {
  const classes = useStyles();
  const { workspace, isIcon24 } = props;
  return (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="center"
      className={isIcon24 ? classes.icon_box_small : classes.icon_box}
    >
      <Grid
        alignItems="center"
        justifyContent="center"
        container
        item
        className={isIcon24 ? classes.icon_grid_small : classes.icon_grid}
        style={{
          backgroundColor:
            workspace.workspace_cid === undefined
              ? isIcon24
                ? getRandomColor(props.index)
                : getRandomBackgroundColor(props.index)
              : undefined,
        }}
      >
        {workspace.workspace_cid !== undefined ? (
          <img
            alt="workspace"
            src={getIPFSUrl(workspace.workspace_cid)}
            className={isIcon24 ? classes.image_small : classes.image}
          />
        ) : (
          <SpoqaHansTypography
            fontWeight="bold"
            className={isIcon24 ? classes.title_small : classes.title}
          >
            {workspace.name[0]}
          </SpoqaHansTypography>
        )}
      </Grid>
    </Grid>
  );
};

export default RandomWorkspaceIcon;
