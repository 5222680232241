import T from "@redwit-commons/utils/typecheck";
import { UserObject, UserObjectSchema } from "../../api/object/user";
import {
  WorkspaceRoleType,
  WorkspaceRoleTypeValues,
} from "../object/user_workspace_map";

export type UserObjectWithWorkspaceRoleType = UserObject & {
  roleType: WorkspaceRoleType;
};

export const UserObjectWithWorkspaceRoleTypeSchema =
  UserObjectSchema.clone().addField(
    "roleType",
    T.string().withEnum(WorkspaceRoleTypeValues)
  );

export const validateUserObjectWithWorkspaceRoleType =
  T.mkValidator<UserObjectWithWorkspaceRoleType>(
    UserObjectWithWorkspaceRoleTypeSchema
  );
