import T from "@redwit-commons/utils/typecheck";

export enum ProjectAuthType {
  // 구노 & 바솔트의 공통권한
  ADMIN = "Project::Admin", // BASALT_ADMIN: Can not edit project, admin authorization
  READONLY = "Project::ReadOnly", // BASALT_READONLY: Read File / View Information / View File Log
  // 구노의 권한
  RW = "Project::RW",
  // 바솔트의 권한
  /**
   * OWNER: Only one in project, have full auth
   * WRITE: Upload File / Read File / View Information / View File Log
   */
  OWNER = "Project::Owner",
  WRITE = "Project::Write",
}

export const ProjectAuthTypeValues = [
  ProjectAuthType.ADMIN,
  ProjectAuthType.READONLY,
  ProjectAuthType.RW,
  ProjectAuthType.OWNER,
  ProjectAuthType.WRITE,
];

export const UserProjectMapObjectSchema = T.object()
  .addField("UserId", T.string())
  .addField("ProjectId", T.string())
  .addField("pinned", T.string())
  .addField("authType", T.string().withEnum(ProjectAuthTypeValues));

export interface UserProjectMapObjectCore {
  readonly UserId: string;
  readonly ProjectId: string;
  readonly pinned: string;
  readonly authType: ProjectAuthType;
}
