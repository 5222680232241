import {
  doAPIPostRequest,
  doAPIGetRequest,
  doAPIDeleteRequest,
} from "../services/api";
import {
  NdaObject,
  NdaPureObject,
} from "@basalt-commons/global-api/object/nda";
import { ICreateNDA, IUpdateNDA } from "@basalt-commons/global-api/request/nda";

import {
  validateOCreateNDA,
  validateODeleteNDA,
  validateOGetNDAList,
  validateOUpdateNDA,
} from "@basalt-commons/global-api/response/nda";

const getNDAList = async (
  userToken: string,
  workspaceId: string
): Promise<NdaObject[]> => {
  const obj = await doAPIGetRequest(`/nda/${workspaceId}`, userToken);
  const ret = validateOGetNDAList(obj);
  return ret.response.results;
};
const createNDA = async (
  userToken: string,
  workspaceId: string,
  args: ICreateNDA
): Promise<NdaPureObject> => {
  const obj = await doAPIPostRequest(
    `/nda/${workspaceId}/create`,
    userToken,
    args
  );
  const ret = validateOCreateNDA(obj);
  return ret.response;
};
const deleteNDA = async (
  userToken: string,
  workspaceId: string,
  ndaId: string
): Promise<string> => {
  const obj = await doAPIDeleteRequest(
    `/nda/${workspaceId}/${ndaId}`,
    userToken
  );
  const ret = validateODeleteNDA(obj);
  return ret.response;
};

const updateNDA = async (
  userToken: string,
  workspaceId: string,
  ndaId: string,
  args: IUpdateNDA
): Promise<NdaObject> => {
  const obj = await doAPIPostRequest(
    `/nda/${workspaceId}/${ndaId}/update`,
    userToken,
    args
  );
  const ret = validateOUpdateNDA(obj);
  return ret.response;
};

export default {
  getNDAList,
  createNDA,
  deleteNDA,
  updateNDA,
};
