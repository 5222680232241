import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  allowDoubleOpenModal,
  ModalAction,
  ModalActionKind,
  ModalState,
  modalStateMachine,
  ModalStateMachineType,
  ModalStateStatus,
} from "../../store/reducers/modal";
import {
  doPersistAction,
  PersistActionKind,
} from "@src/store/reducers/persist";

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.modal,
    persist: state.persist,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ModalContainerProps = PropsFromRedux & {
  stateMachine: ModalStateMachineType;
};

class ModalContainer extends ReduxStateComponent3<ModalContainerProps> {
  static defaultProps = { stateMachine: modalStateMachine };
  constructor(props: ModalContainerProps) {
    super(props);
  }

  protected onAction(prevState: ModalState, action: ModalAction): ModalState {
    switch (action.kind) {
      case ModalActionKind.TRY_OPEN: {
        if (
          prevState.status === ModalStateStatus.OPENED &&
          allowDoubleOpenModal.find((t) => t === action.type) !== undefined
        ) {
          // allow double opened modals
          return {
            ...prevState,
            status: ModalStateStatus.OPENED,
            other: action,
          };
        }
        return {
          status: ModalStateStatus.OPENED,
          other: undefined,
          ...action,
        };
      }
      case ModalActionKind.TRY_CLOSE: {
        if (
          prevState.status === ModalStateStatus.OPENED &&
          prevState.other !== undefined
        ) {
          // 차례로 꺼져야 합니다
          return {
            ...prevState,
            status: ModalStateStatus.OPENED,
            other: undefined,
          };
        }
        return {
          status: ModalStateStatus.INIT,
        };
      }
      case ModalActionKind.TRY_OPEN_CAPTURE: {
        const { capture } = action;
        doPersistAction(this.props.dispatch, {
          kind: PersistActionKind.UPDATE_CAPTURE,
          capture: capture,
        });
        if (
          prevState.status === ModalStateStatus.OPENED &&
          allowDoubleOpenModal.find((t) => t === action.type) !== undefined
        ) {
          // allow double opened modals
          return {
            ...prevState,
            status: ModalStateStatus.OPENED,
            other: action,
          };
        }
        return {
          status: ModalStateStatus.OPENED,
          other: undefined,
          ...action,
        };
      }
      case ModalActionKind.TRY_CLOSE_RESET: {
        doPersistAction(this.props.dispatch, {
          kind: PersistActionKind.RESET_CAPTURE,
        });
        if (
          prevState.status === ModalStateStatus.OPENED &&
          prevState.other !== undefined
        ) {
          // 차례로 꺼져야 합니다
          return {
            ...prevState,
            status: ModalStateStatus.OPENED,
            other: undefined,
          };
        }
        return {
          status: ModalStateStatus.INIT,
        };
      }
    }
  }
}

export default connector(ModalContainer);
