export default {
  ko: {
    activities: {
      title: "모든 활동",
      subtitle: "워크스페이스 내 모든 활동 기록을 제공합니다.",
      download: "로그 다운로드",
      fetch_more: "10개 항목 더 보기",
      whole_period_with_default: "모든 기간",
      all_log_load: "모든 로그를 불러왔습니다",
      reset: "초기화",
      activity_factory: {
        invite: (invitee: string, invitor: string, auth: string) =>
          `<bold>${invitor}<bold>님이 <bold>${invitee}<bold>님을 폴더에 <bold>${auth}<bold>권한으로 초대했습니다`,
        invite_workspace: (invitee: string, invitor: string, auth: string) =>
          `<bold>${invitor}<bold>님이 <bold>${invitee}<bold>님을 워크스페이스에 <bold>${auth}<bold>권한으로 초대했습니다`,
        share: (user: string) =>
          `<bold>${user}<bold>님이 공유 링크를 만들었습니다`,
        view: (user: string) => `<bold>${user}<bold>님이 파일을 열람했습니다`,
        create: (user: string) => `<bold>${user}<bold>님이 폴더를 만들었습니다`,
        create_workspace: (user: string) =>
          `<bold>${user}<bold>님이 워크스페이스를 만들었습니다`,
        upload: (user: string) =>
          `<bold>${user}<bold>님이 파일을 업로드했습니다`,
        download: (user: string) =>
          `<bold>${user}<bold>님이 파일을 다운받았습니다`,
        delete: (user: string) => `<bold>${user}<bold>님이 파일을 삭제했습니다`,
        delete_folder: (user: string) =>
          `<bold>${user}<bold>님이 폴더를 삭제했습니다`,
      },
    },
  },

  en: {
    activities: {
      title: "All activities",
      subtitle: "Provides history of all activities within the workspace",
      download: "Download log",
      fetch_more: "View 10 more items",
      whole_period_with_default: "All period",
      all_log_load: "All logs were loaded",
      reset: "reset",
      activity_factory: {
        invite: (invitor: string, invitee: string, auth: string) =>
          `<bold>${invitor}<bold> invited <bold>${invitee}<bold> to the folder with <bold>${auth}<bold> rights`,
        invite_workspace: (invitor: string, invitee: string, auth: string) =>
          `<bold>${invitor}<bold> invited <bold>${invitee}<bold> to the workspace with <bold>${auth}<bold> rights`,
        share: (user: string) => `<bold>${user}<bold> created a sharing-link`,
        view: (user: string) => `<bold>${user}<bold> has viewed the file`,
        create: (user: string) => `<bold>${user}<bold> created a folder`,
        create_workspace: (user: string) =>
          `<bold>${user}<bold> created a workspace`,
        upload: (user: string) => `<bold>${user}<bold> uploaded a file`,
        download: (user: string) => `<bold>${user}<bold> downloaded the file`,
        delete: (user: string) => `<bold>${user}<bold> deleted the file`,
        delete_folder: (user: string) =>
          `<bold>${user}<bold> deleted the folder`,
      },
    },
  },
};
