import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  PaymentAction,
  PaymentActionKind,
  PaymentState,
  paymentStateMachine,
  PaymentStateMachineType,
} from "../../store/reducers/payment";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { TokenStateStatus } from "../../store/reducers/token";
import Services from "@basalt-react-commons/services";
import {
  doWorkspaceAction,
  WorkspaceActionKind,
  WorkspaceStateStatus,
} from "../../store/reducers/workspace";
import { WorkspaceRoleType } from "@basalt-commons/global-api/object/user_workspace_map";
const { GlobalPaymentService } = Services;

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.payment,
    token: state.token,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PaymentContainerProps = PropsFromRedux & {
  stateMachine: PaymentStateMachineType;
};

class PaymentContainer extends ReduxStateComponent3<PaymentContainerProps> {
  static defaultProps = { stateMachine: paymentStateMachine };
  constructor(props: PaymentContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: PaymentState,
    action: PaymentAction
  ): Promise<PaymentState> {
    const { token, workspace } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: `PaymentContainer::${action.kind}`,
        msg: "not basalt login",
      });

    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: `PaymentContainer::${action.kind}`,
        msg: "workspace statue invalid",
      });

    const userToken = token.state.token;
    const userId = token.state.id;
    const userAuth = workspace.state.selectAuthWorkspace.Users.find(
      (u) => u.id === userId
    );
    const name = workspace.state.selectAuthWorkspace.name;
    const workspaceId = workspace.state.selectAuthWorkspace.id;

    if (
      userAuth === undefined ||
      (userAuth.roleType !== WorkspaceRoleType.ADMIN &&
        userAuth.roleType !== WorkspaceRoleType.OWNER)
    )
      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: action.kind,
        msg: "Only Admin or Owner can perform this action",
      });

    switch (action.kind) {
      case PaymentActionKind.TRY_SET_DEFAULT_CARD: {
        await this.guardAwait(() =>
          GlobalPaymentService.setDefaultCard(
            userToken,
            workspaceId,
            action.cardId
          )
        );
        // 업데이트 된 내용 있음
        this.scheduleTask(() =>
          doWorkspaceAction(this.props.dispatch, {
            kind: WorkspaceActionKind.TRY_SELECT_WORKSPACE,
            name,
          })
        );
        return { ...prevState };
      }
      case PaymentActionKind.TRY_DELETE_CARD: {
        await this.guardAwait(() =>
          GlobalPaymentService.deleteCard(userToken, workspaceId, action.cardId)
        );
        // 업데이트 된 내용 있음
        this.scheduleTask(() =>
          doWorkspaceAction(this.props.dispatch, {
            kind: WorkspaceActionKind.TRY_SELECT_WORKSPACE,
            name,
          })
        );
        return { ...prevState };
      }
      case PaymentActionKind.TRY_GET_WORKSPACE_PAYMENT: {
        const result = await this.guardAwait(() =>
          GlobalPaymentService.getWorkspacePayment(userToken, workspaceId)
        );
        return {
          ...prevState,
          cardPayments: result.response.results,
        };
      }
      case PaymentActionKind.TRY_GET_PAYPLE_GLOBAL_AUTH: {
        const result = await this.guardAwait(() =>
          GlobalPaymentService.getGlobalPaypleAuth(userToken, workspaceId)
        );
        return {
          ...prevState,
          payple_global_auth_info: result.response,
        };
      }
      case PaymentActionKind.TRY_GET_PAYPLE_DOMESTIC_AUTH: {
        const result = await this.guardAwait(() =>
          GlobalPaymentService.getDomesticPaypleAuth(userToken, workspaceId)
        );
        return {
          ...prevState,
          payple_domestic_auth_info: result.response,
        };
      }
    }
  }
}

export default connector(PaymentContainer);
