import { doAPIPostRequest, doAPIGetRequest } from "../services/api";
import {
  ICreateNDAContract,
  IGetMyNdaContractAll,
  IGetNdaContractAllPDF,
  ISendContract,
} from "@basalt-commons/global-api/request/nda_contract";
import {
  OCheckDownloadableContract,
  OCheckSignedDocument,
  OCreateNDAContract,
  OGetMyNdaContractAll,
  OGetNDAContract,
  OGetNDAContractList,
  OGetWorkspacesNDAContractList,
  OSendContract,
  validateOCheckDownloadableContract,
  validateOCheckSignedDocument,
  validateOCreateNDAContract,
  validateOGetMyNdaContractAll,
  validateOGetNDAContract,
  validateOGetNDAContractList,
  validateOGetWorkspacesNDAContractList,
  validateOSendContract,
} from "@basalt-commons/global-api/response/nda_contract";
import {
  OGetNdaContractAllPDF,
  validateOGetNdaContractAllPDF,
} from "@basalt-commons/global-api/response/nda_contract";
/**
 * 게스트의 파일 서명 생성을 요청하는 함수
 */
const createGuestNDAContract = async (
  args: ICreateNDAContract
): Promise<OCreateNDAContract> => {
  const obj = await doAPIPostRequest(`/contract/create/guest`, undefined, args);
  const ret = validateOCreateNDAContract(obj);
  return ret;
};

/**
 * 로그인 유저의 파일 서명 생성을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 */
const createNDAContract = async (
  userToken: string,
  args: ICreateNDAContract
): Promise<OCreateNDAContract> => {
  const obj = await doAPIPostRequest(`/contract/create`, userToken, args);
  const ret = validateOCreateNDAContract(obj);
  return ret;
};

/**
 * 특정 워크스페이스의  서명 파일 목록을 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * MEMBER 권한 이상의 유저가 요청할 수 있음
 */
const getNdaContractList = async (
  userToken: string,
  workspaceId: string
): Promise<OGetNDAContractList> => {
  const obj = await doAPIGetRequest(`/nda/${workspaceId}/contracts`, userToken);
  const ret = validateOGetNDAContractList(obj);
  return ret;
};

/**
 * NDA의 특정 서명 파일을 요청하는 함수
 * 로그인이 필요하지 않습니다.
 */
const getNdaContract = async (contractId: string): Promise<OGetNDAContract> => {
  const obj = await doAPIGetRequest(`/nda/contract/${contractId}`);
  const ret = validateOGetNDAContract(obj);
  return ret;
};

/**
 * 파일 서명 이메일 공유를 요청하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * MEMBER 권한 이상의 유저가 요청할 수 있음
 */
const shareNDAContract = async (
  userToken: string,
  workspaceId: string,
  contractId: string,
  args: ISendContract
): Promise<OSendContract> => {
  const obj = await doAPIPostRequest(
    `/nda/${workspaceId}/contracts/${contractId}`,
    userToken,
    args
  );
  const ret = validateOSendContract(obj);
  return ret;
};

/**
 * 사용자가 참여한 모든 워크스페이스의 사인한 파일 서명들을 가져오는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 */
const getSignedNdaContract = async (
  userToken: string
): Promise<OGetWorkspacesNDAContractList> => {
  const obj = await doAPIGetRequest(
    `/workspaces/nda/contracts/signed`,
    userToken
  );
  const ret = validateOGetWorkspacesNDAContractList(obj);
  return ret;
};

/**
 * NDA 서명 문서를 다운받을 수 있는지 확인하는 함수
 * 로그인 시, 발급받은 토큰이 필요함
 * 웹에서 다운로드 버튼 클릭 시, 해당 함수를 통해 true일 때만 pdf 다운을 진행
 */
const checkDownloadableNdaContract = async (
  userToken: string,
  contractId: string
): Promise<OCheckDownloadableContract> => {
  const obj = await doAPIGetRequest(
    `/nda/contracts/${contractId}/check/downloadable`,
    userToken
  );
  const ret = validateOCheckDownloadableContract(obj);
  return ret;
};

/**
 * NDA의 서명된 모든 문서를 다운로드 하는 함수
 * @param userToken
 * @param workspaceId
 */
const getNDAContractAllPDF = async (
  userToken: string,
  workspaceId: string,
  args: IGetNdaContractAllPDF
): Promise<OGetNdaContractAllPDF> => {
  const obj = await doAPIPostRequest(
    `/${workspaceId}/nda/contracts/download/all`,
    userToken,
    args
  );
  return validateOGetNdaContractAllPDF(obj);
};
/**
 * 본인이 서명한 모든 NDA 서명 문서를 다운로드 하는 함수
 * @param userToken
 * @param args
 */
const getMyNDAContractAll = async (
  userToken: string,
  args: IGetMyNdaContractAll
): Promise<OGetMyNdaContractAll> => {
  const obj = await doAPIPostRequest(
    `/my/nda/contracts/download/all`,
    userToken,
    args
  );
  return validateOGetMyNdaContractAll(obj);
};

const checkSignDocument = async (
  userToken: string,
  ndaId: string,
  documentId: string
): Promise<OCheckSignedDocument> => {
  const obj = await doAPIGetRequest(
    `/nda/${ndaId}/check/${documentId}/contract`,
    userToken
  );
  const ret = validateOCheckSignedDocument(obj);
  return ret;
};

export default {
  createGuestNDAContract,
  createNDAContract,
  getNdaContractList,
  getNdaContract,
  shareNDAContract,
  getSignedNdaContract,
  checkDownloadableNdaContract,
  getNDAContractAllPDF,
  getMyNDAContractAll,
  checkSignDocument,
};
