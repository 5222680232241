export default {
  ko: {
    modal: {
      save: "저장",
      confirm: "확인",
      cancel: "취소",
      add: "추가",
      select_confirm: "선택 완료",
      team: {
        header1: "",
        header2: "에 팀 생성",
        header1_edit: "",
        header2_edit: "에 팀 수정",
        subheader: "워크스페이스에서 팀을 생성하세요",
        subheader_edit: "팀을 수정하세요",
        input_team: "팀 이름을 입력하세요",
        team_name: "팀 이름",
        add_members: "팀원 추가",
        add_all_members: "모든 팀원 추가하기",
        create_team: "팀 생성하기",
        save_team: "저장하기",
        deletion: "팀 삭제",
        del_confirm: "이 팀을 삭제하시겠습니까?",
      },
      folder_public: {
        title: "이 폴더를 공개하시겠습니까?",
        title_shared: "이 폴더를 공유 폴더로 되돌리시겠습니까?",
        make_public: "공개하기",
        make_shared: "공유로 전환하기",
      },
      edit_folder: {
        title: "의 이름 변경",
        placeholder: "새 폴더 이름을 작성해주세요",
      },
      set_defualt_card: {
        title: "기본 카드 변경",
        body_txt: (card_number: string) => {
          return `[${card_number}]를 기본 카드로 변경합니다`;
        },
        change: "변경",
      },
      share_link: {
        title: " 공유 링크",
        create_link: "공유링크 만들기",
      },
      link_done: {
        sub_title: "공유 링크 생성이 완료되었습니다",
        title: "링크 복사 또는 이메일로 공유하세요",
        copy_link: "링크 복사",
        send_email: "메일 전송",
        allow_download: "다운로드 허용",
        allow_print: "프린트 허용",
        require_email: "이메일 수집",
        require_nda: "NDA 동의",
      },
      create_folder: {
        title_private: "개인 폴더를 생성하기",
        title_public: "퍼블릭 폴더를 생성하기",
        title_shared: "공유 폴더를 생성하기",
        sub: "새로 생성할 폴더의 이름을 작성해주세요",
        placeholder: "새로운 폴더 이름을 작성해주세요",
      },

      delete: {
        cancel: "취소",
        title: "삭제",
        title_multiple: "개의 파일을",
        title_single: "개의 파일을",
        title_pre: "정말로 ",
        title_post: " 삭제하시겠습니까?",
        sub: "삭제하면 복구할 수 없습니다.",
        delete: "삭제",
        delete_file_pre: "",
        delete_file_post: " 파일을 삭제합니다.",
        delete_sign: "회사 서명 삭제",
        delete_nda: "NDA 파일 삭제",
      },

      folder_delete: {
        title: "폴더 삭제",
        sub: "폴더를 삭제하시겠습니까?",
      },

      folder_invite: {
        title_ko: "폴더 멤버 추가",
        title_en: "",
        invite: "초대 메일 발송",
        subtitle: "이메일을 이용해서 폴더에 멤버를 초대하세요!",
        in_workspace: "이 워크스페이스 안에",
        placeholder: "이메일 입력 또는 멤버를 선택하세요",
      },
      folder_change_owner: {
        title_1: "",
        title_2: "의 소유자 변경",
        change_owner: "소유자 변경",
        sub: "폴더 멤버 중에서 관리자를 변경할 수 있습니다.",
        search_placeholder: "멤버 검색하기",
      },
      hashtag: {
        title: "해시태그 추가하기",
        title_tag: "태그",
        body_add: "자신의 태그 추가해주세요",
        tag_add: "태그를 추가하세요",
        press_enter: "해시태그를 입력한 후 'Enter' 키를 누릅니다.",
        press_enter_lg: "30자 이내로 입력 후 'Enter' 키를 누르면 추가됩니다",
        body_recent: "최근 해시태그",
        recent_tag: "최근 태그",
        placeholder: "태그를 입력해주세요",
        placeholder_lg: "여기에 태그를 입력하세요",
        tag_max: "최대 10개",
        no_registe: "최근에 등록한 태그가 없습니다",
      },

      workspace_invite: {
        title_prefix: "",
        title_postfix: "에 멤버 초대",
        inviting: "초대 중인 멤버",
        sub: "이메일을 이용해서 워크스페이스에 멤버를 초대하세요!",
        send_mail: "초대 메일 발송",
        placeholder: "초대할 팀원의 이메일을 입력해주세요",
        placeholder_xs: "이메일을 입력하세요",
      },

      sign: {
        title: "서명",
        sub: "그려서 서명하세요!",
        clear_all: "모두 삭제",
        enter_sign: "서명을 입력해주세요",
      },

      sign_history: {
        title_ko: "에 대한 서명 문서 확인",
        title_en: "",
        subtitle: "사인된 문서에 대한 확인을 합니다",
        sign_user: "서명 사용자",
        nda_filename: "NDA 파일명",
        sign_person: "서명한 사람",
        sign_date: "서명 일자",
        download: "다운받기",
      },

      connected_file: {
        title_ko: (count: number) => {
          return ` 연결된 링크 ${count}개`;
        },
        title_en: (_count: number) => "",
        link_name: "공유 링크 이름",
        file_name: "파일 이름",
        sign_count: "서명 횟수",
      },

      onBoarding: {
        finish: "끝내기",
        next: "다음",
        skip: "건너뛰기",
        back: "뒤로",
        title_create_link: "링크 생성하기",
        title_drag_drop: "끌어서 놓기",
        title_reports: "리포트",
        title_upload_nda: "NDA 업로드하기",
        title_links_nda: "NDA와의 링크",
        title_sign: "전자 서명",
        title_timeline: "타임라인",

        text_create_link:
          "사용자 정의 설정과 파일을 공유할 링크를 만들 수 있습니다.",
        text_drag_drop: "끌어서 놓기를 통해 파일을 쉽게 업로드할 수 있습니다.",
        text_reports:
          "분석을 모니터링하고 공유 링크에 대한 통찰력을 얻을 수 있습니다.",
        text_upload_nda: "NDA 업로드하고 관리할 수 있습니다.",
        text_links_nda:
          "NDA가 연결된 모든 공유 링크를 추적하고 모니터링할 수 있습니다.",
        text_sign:
          "서명을 업로드하여 문서와 계약서에 서명하고 인증 및 유효성을 확인할 수 있습니다.",
        text_timeline1: "당일의 모든 활동을 볼 날짜를 선택할 수 있습니다.",
        text_timeline2: "멤버의 모든 활동을 볼 멤버를 선택할 수 있습니다.",
      },

      term: {
        nda_title: "baSalt 서명 정책",
        privacy_title: "baSalt 개인정보 처리 방침",
        cookie_titlie: "baSalt 사이트 쿠키 정책",
        check: "확인하기",
      },

      nda: {
        header: "NDA 선택",
        title_ko: "의 기본 NDA 파일 설정",
        title_en: "",
        sub: "가지고 있는 NDA 파일 중 해당 폴더의 기본 NDA 파일을 설정할 수 있습니다",
        button: "NDA 파일 설정하기",
        upload: "NDA 파일 업로드하기",
        placeholder: "NDA 파일 검색하기",
        new_title: "체결할 NDA(기밀 유지 서약)를 선택하세요",
        new_placeholder: "NDA 파일을 검색하세요",
        new_upload: "NDA 파일 업로드",
        new_upload_sub: "PDF 파일만 업로드 가능합니다",
      },
      nda_description: {
        sub_title: "파일 설명은 최대 100글자 이내로 입력 하실 수 있습니다",
        placeholder: "내용을 입력해주세요",
      },
      resend_invitation: {
        title: "초대 이메일을 다시 보내시기",
        sub: "이 초대 이메일을 다시 보내시겠습니까?",
        confirmMsg: "다시 보내기",
      },

      email_send: {
        title: "서명 문서 이메일 발송하기",
        subtitle: "서명한 문서에 대한 발송을 진행합니다.",
        placeholder: "초대할 팀원의 이메일을 입력해주세요",
        send: "이메일 발송하기",
      },
      policy_info: {
        workspace: {
          title: "워크스페이스 권한 정보",
          done: "확인",
          rule: "워크스페이스 룰 설정",
          team: "팀 개설/관리",
          stamp: "회사 서명 업로드",
          nda: "NDA 업로드/삭제",
          use_nda: "NDA 사용",
          invite: "팀원 초대",
          folder: "폴더 생성",
        },
        folder: {
          title: "폴더 권한 정보",
          done: "확인",
          setting: "폴더 기본 설정",
          delete: "폴더 삭제",
          invite: "폴더에 팀원 초대",
          link: "파일 링크 생성",
          file_delete: "파일 삭제",
          download: "파일 다운로드",
          report: "링크 리포트 다운로드",
          upload: "파일 업로드",
          view: "파일 열람/정보 확인",
        },
      },
      stamp_create: {
        title: "회사 서명 파일 업로드",
      },
      stamp_search: {
        placeholder: "회사 서명 파일을 검색하세요",
        title: "회사 서명을 선택해주세요.",
      },
      search: {
        placeholder: "검색어를 입력하세요",
        recently_searches: "최근 검색어",
        folders: "폴더",
        no_folders: "일치하는 폴더가 없습니다.",
        confirm: "상세검색",
        bottomText: "문서를 상세하게 찾으셔야 하나요?",
      },
      onboard: {
        title: {
          main: "바솔트 1분 튜토리얼",
          timeline: "👀  타임라인 확인하기",
          file_upload: "📄  파일 업로드하기",
          nda_upload: "🔏  NDA 등록하기",
          link_create: "🔗  링크 생성하기",
          report_summary: "📊  인사이트 레포트 한눈에 보기",
        },
        body: {
          timeline: {
            text: () =>
              `워크스페이스 내의 <highlight>모든 활동 이력<highlight>을 타임라인으로 실시간 모니터링을 할 수 있어요.`,
          },
          file_upload: {
            text: () =>
              `폴더 안 <highlight>파일 업로드 버튼<highlight>과 <highlight>드래그 앤 드랍<highlight>으로 파일을 업로드 할 수 있어요.`,
          },
          nda_upload: {
            text: () =>
              `<highlight>NDA(기밀유지협약서)<highlight>는 상호 간의 비밀을 유지한다는 협약으로, 왼쪽 툴바의 'NDA 관리'에서 등록할 수 있어요! `,
          },
          link_create: {
            text: () => `'공유 링크 생성하기'에서는 NDA 체결, 다운로드 허용, 열람 기한 제한 등의 옵션 설정으로 
<highlight>파일을 안전하게 공유<highlight>할 수 있어요.
`,
          },
          report_summary: {
            text: () => `'링크 요약'에서는 외부 방문자에 대한 열람 
정보 및 통계치에 대한 그래프를 제공하며, 
<highlight>페이지별 체류 시간을 추적<highlight>할 수 있어요.
`,
          },
        },
      },
      date_range_picker: {
        placeholder: "날짜 선택",
        day_of_week: ["일", "월", "화", "수", "목", "금", "토"],
        search: "기간 검색",
      },
    },
  },

  en: {
    modal: {
      save: "Save",
      confirm: "Confirm",
      cancel: "Cancel",
      add: "ADD",
      select_confirm: "Confirm",
      team: {
        header1: "Create a Team in ",
        header2: "",
        header1_edit: "Edit a team in ",
        header2_edit: "",
        subheader: "Create a team in your workspace",
        subheader_edit: "Edit this team",
        input_team: "Enter your team name",
        team_name: "Team name",
        add_members: "Add team members",
        add_all_members: "Add all members",
        create_team: "Create a team",
        save_team: "Save",
        deletion: "Delete selected team",
        del_confirm: "Are you sure to delete this team?",
      },
      folder_public: {
        title: "Are you sure to open this folder to public?",
        title_shared:
          "Are you sure to turn this folder back to the shared folder",
        make_public: "Switch to public",
        make_shared: "Switch to shared folder",
      },
      policy_info: {
        workspace: {
          title: "Workspace Authority Information",
          done: "Confirm",
          rule: "Set the workspace rule",
          team: "Create/Manage teams",
          stamp: "Upload company signatures",
          nda: "Upload/Delete NDA",
          use_nda: "Use NDAs",
          invite: "Invite members",
          folder: "Create folders",
        },
        folder: {
          title: "Folder Authority Information",
          done: "Confirm",
          setting: "Folder Preferences",
          delete: "Delete the folder",
          invite: "Invite members",
          link: "Create file links",
          file_delete: "Delete files",
          download: "Download files",
          report: "Download link reports",
          upload: "Upload files",
          view: "View files/Check information",
        },
      },
      edit_folder: {
        title: " Name Change",
        placeholder: "Enter a new folder name",
      },
      set_defualt_card: {
        title: "Update Default Card",
        body_txt: (card_number: string) => {
          return `Change [${card_number}] to default card`;
        },
        change: "Confirm",
      },
      share_link: {
        title: " Sharing-link",
        create_link: "Create a sharing-link",
      },
      link_done: {
        sub_title: "Sharing Link creation is completed.",
        title: "Share the link with email or copied url",
        copy_link: "Copy link",
        send_email: "Send with mail",
        allow_download: "Downloadable",
        allow_print: "Printable",
        require_email: "require email",
        require_nda: "require NDA",
      },
      create_folder: {
        title_private: "Add a private folder",
        title_public: "Add a public folder",
        title_shared: "Add a shared folder",
        sub: "Please enter the name of the new folder you want to create",
        placeholder: "Enter a folder name",
      },

      delete: {
        cancel: "Cancel",
        title: "Delete",
        title_multiple: " files",
        title_single: " file",
        title_pre: "Are you sure to delete ",
        title_post: "?",
        sub: "Once removed, it cannot be recovered.",
        delete: "Delete",
        delete_file_pre: "The file is deleted.",
        delete_file_post: "",
        delete_sign: "Delete company signature",
        delete_nda: "Delete NDA file",
      },

      folder_delete: {
        title: "Delete folder",
        sub: "Are you sure to delete the folder?",
      },

      folder_invite: {
        title_en: "Add members to folder",
        title_ko: "",
        invite: "Send an invitation",
        subtitle: "Invite members to your folder via Email!",
        in_workspace: "In this workspace",
        placeholder: "Enter an Email address or select members",
      },
      folder_change_owner: {
        title_1: "Change owner of",
        title_2: "",
        change_owner: "Change owner",
        sub: "You can change the owner among the folder members",
        search_placeholder: "Search for members",
      },
      hashtag: {
        title: "Add hashtags",
        title_tag: "Tags",
        body_add: "Add your own tags",
        tag_add: "Add tags",
        press_enter: "Type your hashtag and press 'Enter.'",
        press_enter_lg: "Type within 30 characters and Press 'Enter.'",
        body_recent: "Recent Hashtags",
        recent_tag: "Recent Tags",
        placeholder: "Enter your tags",
        placeholder_lg: "Enter your tags here",
        tag_max: "Up to 10",
        no_registe: "No Recently Registered Tags",
      },

      workspace_invite: {
        title_prefix: "Invite members in",
        title_postfix: "",
        inviting: "Inviting members",
        sub: "Invite members to the workspace via Email!",
        send_mail: "Send an invitation",
        placeholder:
          "Enter the Email address of the team member you want to invite",
        placeholder_xs: "Enter the Email",
      },

      sign: {
        title: "Sign",
        sub: "Draw and sign!",
        clear_all: "Clear all",
        enter_sign: "Enter your signature",
      },

      sign_history: {
        title_ko: "",
        title_en: "Check signed document for",
        subtitle: "Check the signed documents",
        sign_user: "Signature-using users",
        nda_filename: "NDA File Name",
        sign_person: "Signee",
        sign_date: "Date Signed",
        download: "Download",
      },

      connected_file: {
        title_ko: (_count: number) => "",
        title_en: (count: number) => {
          return `${count} Links connected to `;
        },
        file_name: "File Name",
        link_name: "Sharing-link Name",
        sign_count: "Number of Signing",
      },

      search: {
        placeholder: "Enter what are you looking for",
        recently_searches: "Recent Keywords",
        folders: "Folders",
        no_folders: "There are no matched folders",
        confirm: "Advanced Search",
        bottomText: "Do you want to search with advanced options?",
      },

      onBoarding: {
        finish: "Finish",
        next: "Next",
        skip: "Skip",
        back: "Back",
        title_create_link: "Create a link",
        title_drag_drop: "Drag and drop",
        title_reports: "Reports",
        title_upload_nda: "Upload NDA",
        title_links_nda: "Links with NDA",
        title_sign: "Esignature",
        title_timeline: "Timeline",

        text_create_link:
          "You can create a link to share your file and set custom settings.",
        text_drag_drop: "Upload your files easily with drag and drop.",
        text_reports:
          "Monitor analytics and get insights for the sharing-links.",
        text_upload_nda: "You can upload and manage your NDAs.",
        text_links_nda:
          "You can track and monitor all the sharing-links connected with an NDA.",
        text_sign:
          "Upload your signature to sign documents and contracts to certify and validate them.",
        text_timeline1: "Select a date to view all activities of that day.",
        text_timeline2:
          "Select a member to view all activities of that member.",
      },
      term: {
        nda_title: "baSalt Signature Policy",
        privacy_title: "baSalt Privacy Policy",
        cookie_titlie: "baSalt Cookie Policy",
        check: "Confirm",
      },

      nda: {
        header: "Select NDA",
        title_en: "Default NDA file settings for",
        title_ko: "",
        sub: "You can choose the default NDA file for the folder from the existing NDA files",
        button: "Set an NDA file",
        upload: "Upload an NDA file",
        placeholder: "Search for NDA files",
        new_title: "Select NDA file to sign",
        new_placeholder: "Search for NDA file",
        new_upload: "Upload an NDA file",
        new_upload_sub: "You can upload PDF file only.",
      },
      nda_description: {
        sub_title: "Enter the description within 100 characters",
        placeholder: "Enter the description",
      },
      resend_invitation: {
        title: "Send invitation again",
        sub: "Are you sure to send this invitation again?",
        confirmMsg: "Resend",
      },

      email_send: {
        title: "Send the signed document by Email",
        subtitle: "The signed document will be sent by Email.",
        placeholder:
          "Enter the Email address of the team member you want to invite",
        send: "Send an Email",
      },
      stamp_create: {
        title: "Upload company signature",
      },
      stamp_search: {
        placeholder: "Search for company signatures",
        title: "Select the company signature",
      },
      onboard: {
        title: {
          main: "Introduction to baSalt",
          timeline: "👀  Review the timeline",
          file_upload: "📄  Upload the file",
          nda_upload: "🔏  Register NDA",
          link_create: "🔗  Create the link",
          report_summary: "📊  Have a glance at the insight report",
        },
        body: {
          timeline: {
            text: () =>
              `You can view <highlight>all activity histories<highlight> within the workspace on the timeline in real time.`,
          },
          file_upload: {
            text: () =>
              `To upload files, click on <highlight>upload files<highlight> in the folder or <highlight>drag and drop<highlight>.`,
          },
          nda_upload: {
            text: () =>
              `<highlight>NDA<highlight> is a contractual agreement that maintains mutual confidentiality, and you can register it in 'NDA Management' from the toolbar on the left.`,
          },
          link_create: {
            text: () =>
              `'Create a sharing-link' feature enables you <highlight>to share files safely<highlight> by setting options related to signing NDA, allowing downloading, and limiting the browsing term.`,
          },
          report_summary: {
            text: () =>
              `'The 'Link Summary' feature displays the graph of the statistics and information on browsing by visitors from outside and allows you to <highlight>track the visiting time per page.<highlight>`,
          },
        },
      },
      date_range_picker: {
        placeholder: "Select date",
        day_of_week: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        search: "Search",
      },
    },
  },
};
