export default {
  ko: {
    landing_main: {
      main_title: {
        fist: "어디서도 볼 수 없었던",
        snd: "새로운 공유의 시작",
      },
      main_subtitle: {
        fist: "baSalt Docs로",
        snd: "기업의 모든 자산을 한번에 관리하세요",
      },
      verified_err: "바솔트 초대 이메일 전송에 실패하였습니다",
      email_auth_err:
        "해당 이메일 계정을 사용할 수 없습니다. 고객센터에 문의해주세요.",
      snd_box: {
        title: "업무용 기밀 문서 관리,\n바솔트로 한번에 해결할 수 있습니다",
        contents: {
          first: {
            title: {
              first: "투자 유치를 앞두고, 투자사가 어느 항목에",
              second: "제일 관심을 갖는지 궁금하신가요?",
            },
            body: {
              first:
                "바솔트에서 생성된 공유 링크를 통해 사용자의 인사이트 및 정보를 실시간으로",
              second: "확인하여 투자사의 인사이트와 니즈를 파악할 수 있습니다",
            },
          },
          second: {
            title: {
              first: "외부 파트너와의 협업에서, 문서를",
              second: "안전하게 공유할 수 있을까요?",
            },
            body: "바솔트의 기밀유지계약(NDA)와 전자서명을 통해 기업의 내외부 문서를 안전하게 공유할 수 있습니다",
          },
          third: {
            title: {
              first: "업무 기밀정보에 대한",
              second: "법적 효력을 가질 수 있을까요?",
            },
            body: {
              first:
                "바솔트에 업로드된 파일은 즉시 블록체인으로 안전하게 저장되며",
              second:
                "기록된 이력을 통해 원본 검증 및 법적 증거 자료로 활용할 수 있습니다",
            },
          },
          fourth: {
            title: {
              first: "문서의 접근부터, 다운로드 기록까지",
              second: "좀 더 효율적으로 관리할 수 있을까요?",
              third: "",
            },
            body: {
              first:
                "문서 외부 공유뿐만 아니라 내부의 모든 활동들이 실시간으로 기록되며",
              second:
                "바솔트의 타임라인 필터링 기능으로 문서를 효율적으로 관리할 수 있습니다",
            },
          },
          email_input: {
            title: "이 모든 문제를 바솔트Docs로\n한번에 해결할 수 있습니다!",
            placeholder: "이메일을 입력하고 지금 바로 시작하세요!",
          },
        },
      },
      thrd_box: {
        first: {
          title: "모든 업무 과정이\n타임라인에 자동으로\n기록됩니다",
          description:
            "모든 업무 과정이 워크스페이스에 자동으로\n기록되어 타임라인을 직관적으로 확인 및\n관리할 수 있습니다",
          titles: ["모든 업무 과정이", "타임라인에 자동으로", "기록됩니다"],
          descriptions: [
            "모든 업무 과정이 워크스페이스에 자동으로",
            "기록되어 타임라인을 직관적으로 확인 및",
            "관리할 수 있습니다",
          ],
        },
        second: {
          title: "링크 생성으로 쉽고,\n안전하게 외부로\n공유할 수 있습니다",
          description:
            "맞춤 설정을 통해 외부 공유에 대한 보안을\n한층 더 강화하여 안전하게 문서를 공유할\n수 있습니다",
          titles: [
            "링크 생성으로 쉽고,",
            "안전하게 외부로",
            "공유할 수 있습니다",
          ],
          descriptions: [
            "맞춤 설정을 통해 외부 공유에 대한 보안을",
            "한층 더 강화하여 안전하게 문서를 공유할",
            "수 있습니다",
          ],
        },
        third: {
          title: "NDA 설정으로\n빠르게 법적 효력을\n생성할 수 있습니다",
          description:
            "몇번의 클릭만으로 전문가의 도움 없이 쉽게\n법적 효력을 확보할 수 있습니다",
          titles: ["NDA 설정으로", "빠르게 법적 효력을", "생성할 수 있습니다"],
          descriptions: [
            "몇번의 클릭만으로 전문가의 도움 없이 쉽게",
            "법적 효력을 확보할 수 있습니다",
          ],
        },
      },
      fourth_box: {
        titles:
          "강력한 문서 보안과 관리를 위한\n다양한 기능을 제공하고 있습니다",
        security: {
          title: "이중 암호화",
          descriptions: [
            "업로드와 동시에 이중 암호화되어 블록체인에",
            "안전하게 보관합니다",
          ],
        },
        timeline: {
          title: "타임라인",
          descriptions: [
            "문서 열람, 다운로드, 삭제 등에 대한 활동 등을 실시간으로 ",
            "기록하고 타임라인으로 제공합니다",
          ],
        },
        link: {
          title: "링크생성",
          descriptions: [
            "외부 사용자의 파일 접근에 대한 보안을 강화하여",
            "안전하게 공유할 수 있습니다",
          ],
        },
        auth: {
          title: "권한설정",
          descriptions: [
            "팀원 별 권한 설정을 통해 문서의 팀원 접근 제어를 손쉽게",
            "할 수 있습니다",
          ],
        },
        nda: {
          title: "NDA",
          descriptions: [
            "NDA를 첨부하여 보안 정책을 추가하고, 공유받은",
            "사용자로부터 전자 서명을 받을 수 있습니다",
          ],
        },
        certification: {
          title: "인증서 발급",
          descriptions: [
            "문서를 업로드하면, 블록체인 네트워크 인증과 인증서를 발급",
            "받을 수 있습니다",
          ],
        },
        report: {
          title: "인사이트 레포트",
          descriptions: [
            "접속한 방문자의 정보 및 페이지 별 체류 시간 등이",
            "포함된 리포트를 제공합니다",
          ],
        },
        ocr: {
          title: "OCR 검색",
          descriptions: [
            "문서에 대한 OCR 검색이 가능하여, 어떤 문서 형식이든",
            "손쉽게 검색할 수 있습니다",
          ],
        },
      },
      articles: {
        titles:
          "많은 기업들이 왜 블록체인 기술을\n채택했는지 직접 확인해 보세요",
        first: {
          titles: [
            "지속적인 혁신 촉진을 위한 미국의",
            "블록체인 규제 동향과 지침 범위",
          ],
          descriptions: [
            "미국의 많은 주에서 블록체인 기술을 활용한 기록물에 대한",
            " 효력을 인정한 사례가 증가하고 있습니다. 일부 주에서...",
          ],
        },
        second: {
          titles: [
            "딜로이트 글로벌 서베이에서",
            "증명된 블록체인의 혁신적 중요성",
          ],
          descriptions: [
            "10여 년 전, 최초의 블록체인 기술이 홍보되기 시작한 이후",
            " 업계 전반의 지도자들은 종종 이 기술로 무엇을 해야 할지...",
          ],
        },
        third: {
          titles: ["세계국제연합기구(UN)에서 말하는", "블록체인의 혁신과 미래"],
          descriptions: [
            "블록체인은 융합과 상호작용이 교차하는 확장된 기술 중",
            "하나입니다. 물리적, 디지털 및 생물학적 시스템은 4차...",
          ],
        },
      },
      reviews: {
        harry: {
          title:
            "바솔트를 이용하면서,\n투자 유치에 많은\n인사이트를 얻을 수 있었죠!",
          description:
            "“ 바솔트는 투자, 기금, 기술 자료 및 데이터와 문서를 빠르고\n안전하게 공유하는 효과적인 도구입니다. 바솔트의 인사이트 리포트\n를 이용하면 파트너나 투자사의 관심과 잠재적인 니즈를 이해하는데\n특히 유용합니다. ”",
          titles: [
            "바솔트를 이용하면서",
            ["<b>투자 유치</b>", " 에 많은 ", "<b>인사이트를</b>"],
            "얻을 수 있었죠!",
          ],

          descriptions: [
            "“ 바솔트는 투자, 기금, 기술 자료 및 데이터와 문서를 빠르고 ",
            "안전하게 공유하는 효과적인 도구입니다. 바솔트의 인사이트 리포트",
            "를 이용하면 파트너나 투자사의 관심과 잠재적인 니즈를 이해하는데",
            "특히 유용합니다. ”",
          ],
        },
        terra: {
          title:
            "팀 내부 관리와 소통에\n있어서 업무 과정을\n바로 확인할 수 있어요!",
          description:
            "“ 바솔트를 사용하여 조직 전체 현안에 빠르게 대처하고 있습니다.\n공유하는 파일들에 대해 언제 액세스 하였는지의 등의 정보를\n타임라인을 통해 볼 수 있고, 권한 세분화 등의 기능을 이용하여\n문서 체계를 쉽게 구축할 수 있었어요. ”",
          titles: [
            ["<b>팀 내부 관리</b>", "와 소통에"],
            ["있어서 ", "<b>업무 과정</b>", "을"],
            "바로 확인할 수 있어요!",
          ],
          descriptions: [
            "“ 바솔트를 사용하여 조직 전체 현안에 빠르게 대처하고 있습니다",
            "공유하는 파일들에 대해 언제 액세스 하였는지의 등의 정보를",
            "타임라인을 통해 볼 수 있고, 권한 세분화 등의 기능을 이용하여",
            "문서 체계를 쉽게 구축할 수 있었어요. ”",
          ],
        },
        umid: {
          title:
            "올리기만 하면 바로\n블록체인으로 인증되기 때문에,\n공증 걱정을 안해요!",
          description:
            "“ 바솔트는 문서 변조 및 아이디어 도용을 방지하는 효과적인 솔루션\n입니다. 기밀문서뿐만 아니라 지식재산권을 확보하고자 하는\n아이디어, 문서, 회의록 등에도 적용할 수 있어 일반적인 문서관리 솔루션\n보다 공신력을 확보할 수 있고 관리도 어렵지 않아요. ”",
          titles: [
            "올리기만 하면 바로",
            ["<b>블록체인</b>", "으로 ", "<b>인증</b>", "되기 때문에,"],
            "공증 걱정을 안해요!",
          ],
          descriptions: [
            "“ 바솔트는 문서 변조 및 아이디어 도용을 방지하는 효과적인 솔루션",
            "입니다. 기밀문서뿐만 아니라 지식재산권을 확보하고자 하는 아이디",
            "어, 문서, 회의록 등에도 적용할 수 있어 일반적인 문서관리 솔루션",
            "보다 공신력을 확보할 수 있고 관리도 어렵지 않아요. ”",
          ],
        },
      },
      curr_report: {
        titles: [
          "바솔트의 실시간",
          ["<b>인사이트 리포트</b>", "를"],
          "지금 바로",
          "확인해보세요!",
        ],
        titles2: [
          "바솔트의 실시간",
          "인사이트 리포트를",
          "지금 바로\n확인해보세요!",
        ],
        description:
          "바솔트는 외부 공유에 대한\n인사이트 리포트를 제공하며, 각 링크에\n대한 외부 사용자의 기록을 쉽게 확인할 수\n있습니다",
        descriptions: [
          "바솔트는 외부 공유에 대한",
          "인사이트 리포트를 제공하며, 각 링크에",
          "대한 외부 사용자의 기록을 쉽게 확인할 수",
          "있습니다",
        ],
      },
      go_workspaces: "나의 워크스페이스로 가기",
      more_workspace: "더 많은 워크스페이스 보기",
      create_workspace: "새 워크스페이스 만들기",
      my_workspaces: "나의 모든 워크스페이스",
      start_button: "지금 시작하기",
      header: {
        service: "서비스 소개",
        solution: "솔루션",
        pricing: "가격 안내",
        blog: "블로그",
        contact: "문의/지원",
        signup: "회원가입",
        login: "로그인",
      },

      footer: {
        service: "서비스 소개",
        solution: "솔루션",
        info: "안내",
        download: "자료 다운로드",
        customer_center: "고객센터",
        service_1: "Features",
        service_2: "Pricing",
        solution_1: "투자 유치 시",
        solution_2: "기업 간의 협력에서",
        solution_3: "아이디어/기술 보호",
        solution_4: "문서 외부 공유",
        info_1: "공지사항",
        info_2: "제휴 문의",
        info_3: "이용 가이드 (Kor)",
        info_4: "이용 가이드 (Eng)",
        download_1: "서비스 소개서 (국문)",
        download_2: "서비스 소개서 (영문)",
        customer_1: "FAQ",
        customer_2: "Contact us",
        terms: "Terms of Service",
        privacy: "Privacy Policy",
        refund: "Refund Policy",
        redwit_info: {
          ceo: "ReDWit, Inc  |   Business Registration Number 144-87-01498   |   CEO. Jiwon Kim",
          contact:
            "Email. basaltdocs@redwit.io   |   Tel. +82 42-861-5001   |   Fax. +82 42-861-5002",
          address:
            "E19 building (9F), 291, Daehak-ro, Yuseong-gu, Daejeon, Repulic of Korea   |  44, Eoeun-ro(2F), Yuseong-gu, Daejeon, Republic of Korea",
          rights: "Copyright ⓒ 2021 ReDWit Inc. All Rights Reserved",
        },
      },

      title: {
        titles: {
          title1: "문서 비밀 공유 솔루션",
          title2: "baSalt",
        },
        body: "개인과 조직의 문서를 강력한 보안과 블록체인 기술로 안전하게 보관합니다",
      },
      features: {
        title: "안전하게 문서를 관리하고 싶으신가요?",
        sub_title:
          "바솔트는 당신의 문서를 가장 안전하게 관리하고 공유하며, 모든 작업의 과정을 기록합니다",
        feature_upload: {
          titles: {
            fst_line: "문서를 업로드하면,",
            fst_line2: "",
            snd_line1: "블록체인",
            snd_line2: "에 안전하게",
            thrd_line: "보관됩니다",
          },
          sub_title:
            "모든 업로드된 문서는 이중 암호화 되어 즉각적으로\n블록체인 네트워크 인증과 인증서를 발급받을 수 있습니다",
        },

        feature_link: {
          titles: {
            fst_line1: "개별",
            fst_line2: "링크 생성",
            fst_line3: "을 통해",
            snd_line: "쉽게 외부로 공유할 수 있습니다",
          },
          sub_title:
            "맞춤 설정을 통해 외부 공유에 대한 보안을 한층 더\n강화하여 안전하게 문서를 공유할 수 있습니다",
        },

        feature_nda: {
          titles: {
            fst_line1: "NDA 설정",
            fst_line2: "으로 쉽고 빠르게",
            snd_line: "법적 효력을 생성할 수 있습니다",
          },
          sub_title:
            "몇번의 클릭만으로 전문가의 도움 없이 쉽게\n법적 효력을 확보할 수 있습니다",
        },

        feature_report: {
          titles: {
            fst_line: "외부 공유에 대한 인사이트",
            snd_line1: "리포트",
            snd_line2: "를 제공합니다",
          },
          sub_title:
            "각 링크에 대한 외부 사용자의 기록을\n쉽게 확인할 수 있습니다",

          box_title: "사용자 님은 오늘,",
          graph_title: "이미지별 평균 체류 시간",
          most_view: "가장 오래 본 이미지",
          least_view: "가장 적게 본 이미지",
          sec: "초",
          th_image: "번째 이미지",
        },

        feature_logs: {
          box_title: "사용자 님의 오늘 baSalt 관련 기록",
          title: "이 모든 과정이 자동으로 기록됩니다!",
          sub_title:
            "모든 업무 과정이 워크스페이스에 자동으로 기록되어 타임라인을 직관적으로 확인 및 관리할 수 있습니다",
        },

        logs: {
          user: "사용자",
          view: {
            view_1: "님이",
            view_2: "문서를",
            view_3: "열람하였습니다",
          },
          download: {
            download_1: "님이 baSalt 내의",
            download_2: "문서를",
            download_3: "다운받았습니다",
          },
          link: {
            link_1: "님이 문서에",
            link_2: "링크를",
            link_3: "생성하였습니다",
          },
          nda: {
            nda_1: "님이 문서 링크에",
            nda_2: "NDA를",
            nda_3: "첨부하였습니다",
          },
          upload: {
            upload1: "님이",
            upload2: "baSalt에",
            upload3: "문서를 업로드하였습니다",
          },
        },
      },
      customer_case: {
        title: "baSalt 도입, 실제 어떤 효과가 있었나요?",
        sub_title: "baSalt를 도입한 실제 기업의 생생한 이야기!",
        ps_title:
          "(* 해당 리뷰는 익명으로 게시하는 것을 허락받고 작성되었습니다.)",
      },
      free_trial_footer: {
        fst_line: "지금 무료로 체험하시고,",
        snd_line: "스마트한 문서 관리를 시작해보세요!",
      },

      effect: {
        protect: {
          title: "안전하고 투명한 문서 보안",
          secure: {
            title: "이중 암호화로\n안전하게",
            body: "모든 문서는 이중 암호화되어 저장되어 있습니다",
          },
          certified: {
            title: "생성과 동시에\n블록체인 인증까지",
            body: "문서를 업로드하면, 즉각적으로 블록체인 네트워크 인증과 인증서를 발급받을 수 있습니다",
          },
          trans: {
            title: "인증의 투명성",
            body: "인증 과정의 모든 기록은 투명하고 변조되지 않습니다. ",
          },
          legal: {
            title: "쉽고 빠르게 법적 효력 생성",
            body: "문서 공유시 NDA, 전자서명 기능을 활용해, 몇번의 클릭만으로 법적 효력을 확보할 수 있습니다",
          },
        },

        prove: {
          title: "추가적 공증 없는 법적 효력",
          ownership: {
            title: "소유권의 증명",
            body: "블록체인에 기록된 이력을 통해, 원본 검증 및 소유권 증명을 할 수 있습니다",
          },
          prove_process: {
            title: "독창성의 증명",
            body: "누가 문서를 열람했고, 업로드했는지, 공유했는지를 확인하고 이를 통해 업무 과정을 증명할 수 있습니다",
          },
          originality: {
            title: "업무 과정의 증명",
            body: "누가 문서를 열람했고, 업로드했는지, 공유했는지를 확인하고 이를 통해 업무 과정을 증명할 수 있습니다",
          },
        },
        share: {
          title: "Share",
          monitor: {
            title: "공유 자료, 쉽게 모니터링 하세요",
            body: "공유한 자료 열람 등과 같은 모든 기록에 대해서 간편하게 모니터링 할 수 있습니다",
          },
          control: {
            title: "문서의 완벽한 관리",
            body: "세분화된 권한 설정을 통해, 문서의 내부/외부 공유시 완벽한 관리를 할 수 있습니다",
          },
          intuitive: {
            title: "쉽고 직관적인 UI",
            body: "Basalt 추가적인 온보딩, 교육이 필요 없습니다. 쉽고, 직관적인 UI를 통해 바로 도입할 수 있습니다",
          },
        },
      },
      why_blockchain: {
        title: "왜 baSalt로 관리해야 할까요?",
        sub_title: {
          text1:
            "Basalt는 기업의 자산 관리 및 데이터의 무결성을 증빙하기 위해, 블록체인 기술을 사용합니다. ",
          text2: "블록체인의 ",
          text3:
            "투명성(transparent), 탈중앙성(decentralized), 추적 가능한(traceable) 성질",
          text4: "",
          text5: "",
          text6: "은",
          text7: "기업용 전자 기록을 관리하는데 최적의 기술입니다",
          text8: "",
        },
        no_corr: {
          title: "추가적인 공증이 필요 없습니다",
          body: "위변조가 불가능한 블록체인의 특성상, 전자기록에 대한 추가적인 공증이 필요하지 않습니다",
        },
        cost: {
          title: "효율적으로 관리할 수 있습니다",
          body: "중간 과정의 인증기관이 필요하지 않기 때문에 인증 절차를 간소화하고, 비용을 절감할 수 있습니다",
        },
        trasnparent: {
          title: "투명하고 정확합니다",
          body: "인증 과정의 모든 기록은 투명하고 변조되지 않습니다",
        },
        news1:
          "미국의 많은 주에서 블록체인 기술을 활용한 기록물에 대한 효력을 인정한 사례가 증가하고 있습니다",
        new2: "많은 국제 조직에서는 정보 공유에 블록체인 기술의 적용을 고려하고 있습니다",
      },
      sign_in_landing: {
        title: "이 모든 문제를 한 번에 해결하세요",
        place_holder: "지금 이메일을 입력해서 시작하세요",
        send: "무료체험시작",
      },
      solutions: {
        titles: {
          title1: "업무용 기밀 문서 관리,",
          title2: "어떻게 해야할지 막막하지 않으셨나요?",
        },
        sub_title: "바솔트로 한 번에 해결할 수 있습니다",
        fund: {
          title: "투자 유치를 앞둔\n스타트업인가요?",
          body: "투자 피치덱을 링크 공유 NDA첨부를 통해, 보호하고 열람 여부를 확인할 수 있습니다",
        },
        partnership: {
          title: "문서 관리가\n고민이신가요?",
          body: "기밀 문서, 계약 열람 시, 서명 요청을 통해 안전하게 공유하고, 이력을 관리할 수 있습니다",
        },
        idea: {
          title: "아이디어와 기술을\n보호하고 싶으신가요?",
          body: "완벽한 감사추적 기능 제공을 통해, 아이디어와 기술의 소유권 증명을 할 수 있습니다",
        },
        collabo: {
          title: "문서를 안전하게\n공유하고 싶으신가요?",
          body: "안전한 가상 데이트 공간을 통해, 안심하고 팀원들과 문서 공유를 진행할 수 있습니다",
        },
      },
      how: {
        title: "baSalt와 함께 스마트한 문서 관리를 시작해보세요!",
        sub_titles: {
          fst: "기업의 모든 데이터 및 자산 관리 증빙을 위해 블록체인 기술을 사용합니다",
          snd: "블록체인의 투명성, 탈중앙성, 추적 가능한 특성으로 기업의 소중한 자산을 안전하게 보호합니다",
        },

        protect: {
          title: "안전하고 투명한 문서 보안",
        },

        how1: "작업을 업로드하고, 인증 받으세요!",
        how2: "전체적인 업무 과정을 모니터링 하세요!",
        how3: "개별 링크 생성과, 맞춤 설정을 통해 안전하게 작업을 공유하세요",
        how4: "개별 리포트를 통해 누가 어떤 문서를 보고, 다운로드 했는지 확인하세요!",
      },
      sign_up: "지금 시작하세요!",
      news: {
        sub_titles: {
          fst: "블록체인 기술을 활용한 다양한",
          snd: "해외사례 및 트렌드를 살펴보세요.  ",
        },
        news_1:
          "미국의 많은 주에서 블록체인 기술을 활용한 기록물에 대한 효력을 인정한 사례가 증가하고 있습니다...",
        news_2:
          "많은 국제 조직에서는 정보 공유에 블록체인 기술의 적용을 고려하고 있습니다 ...",
        titles: {
          fst: "블록체인",
          snd: "으로 문서 관리,",
          thrd: "앞으로의 기업이라면",
          four: "당연한 선택입니다!",
        },

        read_more: "더 읽어보기",
      },
    },
  },
  en: {
    landing_main: {
      main_title: {
        fist: "Scale up!",
        snd: "Your Business Easily\nyet Securely!",
      },
      main_subtitle: {
        fist: "Manage all your files",
        snd: "with baSalt Docs",
      },
      verified_err: "Failed to send an email for verified. please retry",
      email_auth_err:
        "This email account cannot be used. Please contact to customer center.",
      snd_box: {
        title: "All your needs for file management\nare here in baSalt Docs.",
        contents: {
          first: {
            title: {
              first:
                "Do you want to know which investors are interested in you and your services?",
              second: "",
            },
            body: {
              first: "Check out their reactions to the files",
              second: "you shared with 'sharing-link'!",
            },
          },
          second: {
            title: {
              first: "How can files be safely shared",
              second: "with partner companies?",
            },
            body: "baSalt provide NDA options and electroic signatures system for sharing files safely",
          },
          third: {
            title: {
              first: "Do you want to give legal effect",
              second: "to confidential files that you upload?",
            },
            body: {
              first:
                "When you upload a file on baSalt Docs, it is stored securely in the blockchain immediately.",
              second:
                "Use stored records and blockchain certificates to verify the original and use it as legal evidence if necessary!",
            },
          },
          fourth: {
            title: {
              first: "Do you want to systematically manage",
              second: "all the history of document creation, deletion,",
              third: "sharing, viewing and downloading?",
            },
            body: {
              first:
                "Create a systematic document management workflow by checking the first",
              second:
                "creation, deletion, sharing, viewing, and downloading history of all documents created on baSalt Docs!",
            },
          },
          email_input: {
            title: "baSalt Docs can solve\nall these problems at once!",
            placeholder: "Start now by entering your email!",
          },
        },
      },
      thrd_box: {
        first: {
          title: "Share Any File\nWithout Any Stress!",
          description:
            "Stop to share files in attachment that is time-spending\nand risky.On baSalt Docs, you can share files using\n‘sharing-link' efficiently and securely.",
          titles: ["All work processes", "are recorded", "in the timeline"],
          descriptions: [
            "All work processes are automatically recorded",
            "in the workspace to intuitively check ",
            "and manage the timeline",
          ],
        },
        second: {
          title: "Simplify Your\nEsignature Workflow\nWithout Stress!",
          description:
            "Send documents that require signature to many\npeople at the same time. On baSalt Docs, you can allow\ndifferent signers to sign on the same document\nat the same time.",
          titles: ["Link creation", "makes it easy ", "and safe to share "],
          descriptions: [
            "You can easily enhance the security",
            "regarding external users’ file access",
            "through various sharing options",
          ],
        },
        third: {
          title: "Streamline Your \nTeam's Workflow\nWithout Stress!",
          description:
            "Don’t make any vaguer assumptions about\nyour team anymore. On baSalt Docs,\neach member's activities are automatically recorded.\nYou can save time to check others' work progresses.",
          titles: [
            "Through NDA settings,",
            "You can create",
            "legal effect easily",
          ],
          descriptions: [
            "You can easily secure legal effect",
            "with just a few clicks without experts",
          ],
        },
      },
      fourth_box: {
        titles: "With baSalt Docs, you can\nmanage files easily yet securely!",
        security: {
          title: "Double Encryption",
          descriptions: [
            "Double-encrypted at the same time as file uploads and keeps them safe in the blockchain",
            "",
          ],
        },
        timeline: {
          title: "Time-line",
          descriptions: [
            "All records of creation, deletion, sharing, viewing and downloading etc. can be checked by member/activity.",
            "",
          ],
        },

        link: {
          title: "Create sharing-links",
          descriptions: [
            "You can easily enhance the security regarding external users’ file access through various sharing options",
            "",
          ],
        },

        auth: {
          title: "Set Permissions",
          descriptions: [
            "You can control workspace members' accesses to files by setting permissions for each one",
            "",
          ],
        },
        nda: {
          title: "NDA",
          descriptions: [
            "Simplified NDA creation and Esignature workflow You can Add a security policy with NDA and ask Esignatures from other parties simply",
            "",
          ],
        },
        certification: {
          title: "Issue Blockchain Certificates",
          descriptions: [
            "You can download a 'blockchain certificate' which states the file you uploaded was safely stored in blockchain.",
            "",
          ],
        },
        report: {
          title: "Insight Reports",
          descriptions: [
            "You can download reports that include information about visitors who have accessed the shaing-link and the duration of stay by document page.",
            "",
          ],
        },
        ocr: {
          title: "OCR Search",
          descriptions: [
            "Search for files by OCR You can search for files of any format by OCR.",
            "",
          ],
        },
      },
      articles: {
        titles: "What effect & impact\ndoes blochcain has?",
        first: {
          titles: ["The trend and scope", "of US blockchain"],
          descriptions: [
            "In many states in the United States, there are increasing cases of acknowledging the validity of records using blockchain technology...",
            "",
          ],
        },
        second: {
          titles: ["Deloitte’s 2019 Global Blockchain Survey", ""],
          descriptions: [
            "Since the first blockchain technology began to be promoted more than a decade ago, leaders across the industry are often contemplating...",
            "",
          ],
        },
        third: {
          titles: ["The future of the blockchain", "as mentioned by the UN"],
          descriptions: [
            "Blockchain is one of the extended technologies where convergence and interaction intersect...",
            "",
          ],
        },
      },

      reviews: {
        harry: {
          title:
            "Remote work environments\ndoesn’t matter at all \nfor team collaboration",
          description: `"Actually, I introduced baSalt with half a doubt at first. But our teams and I realized\nin a few weeks that it is not only easy & fast for sharing files among team members,\nbut also safe because of the blockchain technology. Despite the physical distance between\nteam members in remote work environments, we could build an integrated workflow\nwith baSalt Docs."`,
          titles: [
            "Using baSalt,",
            "I can gain lots of insight of",
            "investment attraction",
          ],
          descriptions: [
            "“ baSalt is an effective tool for quickly and securely",
            "sharing investments, funds, knowledge base and data and documents",
            "baSalt's Insight Report is useful for understanding",
            "the interests and potential needs of partners and investors. ”",
          ],
        },
        terra: {
          title:
            "baSalt Docs is essential\nfor startup to build an integrated workflow\nfor startup",
          description: `"baSalt was the best solution for us in this situation. Features like timeline helped\nto manage internal personnel more efficiently. It is helpful as well that I can set the\nexpiration date and give permission for each file when sharing files outside.\nI think it's the best solution with no reason not to use."`,
          titles: [
            "I can check the process",
            "of our work immediately",
            "using Timeline in baSalt",
          ],
          descriptions: [
            "“ Using baSalt, I can see the timeline of",
            "when other teammate accessed the shared files,",
            "and I can easily make a management system",
            "of documents using baSalt",
          ],
        },
        umid: {
          title: "To interact with investors and VCs\nis not stressful anymore",
          description: `“baSalt Docs came through for us in the nick of time.\nWe were managing investment documents with baSalt Docs when we realized we were going\nto need Esignature capabilities to bring investors on board.\nbaSalt Docs has transformed the way we interact with investors and VCs who are looking at us.”`,
          titles: [
            "It's certified as a blockchain",
            "immediately after upload",
            "so I don't worry about notarization",
          ],
          descriptions: [
            "“ It can be applied not only to confidential documents,",
            "but also to ideas, documents, minutes that want to secure intellectual property rights,",
            "so it is more reliable and not difficult to manage",
            "than ordinary document management solutions ”",
          ],
        },
      },
      curr_report: {
        titles: [
          "Check out baSalt Docs'",
          ["<b>Real-Time Insight Report</b>", ""],
          "Right now!",
          "",
        ],
        titles2: [
          "Check out baSalt Docs'",
          "Real-Time Insight Report",
          "Right now!",
        ],
        description:
          "baSalt Docs provides insight reports on external sharing,\nand it is easy to check the history of external users for each link",
        descriptions: [
          "BaSalt provides insight reports ",
          "on external sharing,",
          "and it is easy to check the history",
          "of external users for each link",
        ],
      },
      header: {
        service: "Service",
        solution: "Solutions",
        pricing: "Pricing",
        blog: "Blog",
        contact: "Contact",
        signup: "Sign up",
        login: "Login",
      },

      footer: {
        service: "Service",
        solution: "Solutions",
        info: "Information",
        download: "자료 다운로드",
        customer_center: "Customer Center",
        service_1: "Features",
        service_2: "Pricing",
        solution_1: "Attracting Investment",
        solution_2: "Business Cooperation",
        solution_3: "Protecting Ideas",
        solution_4: "Streamlined Workflow",
        info_1: "Notice",
        info_2: "Partnership inquiry",
        info_3: "User Guide (Kor)",
        info_4: "User Guide (Eng)",
        download_1: "Service statement (Kor)",
        download_2: "Service statement (Eng)",
        customer_1: "FAQ",
        customer_2: "Contact us",
        terms: "Terms of Service",
        privacy: "Privacy Policy",
        refund: "Refund Policy",
        redwit_info: {
          ceo: "ReDWit, Inc  |   Business Registration Number 144-87-01498   |   CEO. Jiwon Kim",
          contact:
            "Email. basaltdocs@redwit.io   |   Tel. +82 42-861-5001   |   Fax. +82 42-861-5002",
          address:
            "E19 building (9F), 291, Daehak-ro, Yuseong-gu, Daejeon, Repulic of Korea   |  44, Eoeun-ro(2F), Yuseong-gu, Daejeon, Republic of Korea",
          rights: "Copyright ⓒ 2021 ReDWit Inc. All Rights Reserved",
        },
      },

      free_trial_footer: {
        fst_line: "Do you want to start managing documents",
        snd_line: "as safe and easy as the companies above?",
      },

      go_workspaces: "Go to my workspace",
      more_workspace: "See more workspaces",
      create_workspace: "Create a new workspace",
      my_workspaces: "My workspaces",
      start_button: "Start now",
      title: {
        titles: {
          title1: "Document Secret",
          title2: "Sharing Solution",
        },
        body: "baSalt is a blockchain-based document management software for you to create, capture, manage, and share confidential documents in a secure and transparent way",
      },
      main_copy: {
        you: "You",
        viewed_en: "viewed ",
        basalt: "baSalt",
        viewed_kr: "",
      },

      features: {
        title: "Do you want to manage documents safely?",
        sub_title:
          "baSalt most securely manages and shares your documents, and records the course of all operations",
        feature_upload: {
          titles: {
            fst_line: "Uploaded documents",
            fst_line2: "are kept safe",
            snd_line1: "by Blockchain!",
            snd_line2: "",
            thrd_line: "",
          },
          sub_title:
            "Upload your work and it’s intantly certified for you to share safely",
        },

        logs: {
          user: "User",
          view: {
            view_1: "viewed the",
            view_2: "Document",
            view_3: "",
          },
          download: {
            download_1: "downloaded a",
            download_2: "Document",
            download_3: "in baSalt",
          },
          link: {
            link_1: "created",
            link_2: "a link of Document",
            link_3: "",
          },
          nda: {
            nda_1: "attached",
            nda_2: "NDA",
            nda_3: "in link",
          },
          upload: {
            upload1: "uploaded a Document",
            upload2: "in baSalt",
            upload3: "",
          },
        },

        feature_link: {
          titles: {
            fst_line1: "Individual",
            fst_line2: "link creation",
            fst_line3: "",
            snd_line: "make it easy to share",
          },
          sub_title:
            "Various options allow you to securely share documents by increasing security for external shares",
        },

        feature_nda: {
          titles: {
            fst_line1: "NDA Attachments",
            fst_line2: "or any other legal protections",
            snd_line: "when sharing your work",
          },
          sub_title:
            "With just a few clicks, it's easy to get legal effect without the help of an expert",
        },

        feature_logs: {
          box_title: "Your Today baSalt timeline is,",
          title: "All logs of documents will be stored automatically!",
          sub_title:
            "Prove every process of your ideas and work with the complete audit trail",
        },

        feature_report: {
          titles: {
            fst_line: "All logs for shares are in",
            snd_line1: "Insight report",
            snd_line2: "",
          },
          sub_title: "It's easy to see external user's history for each link",

          box_title: "How you spent your time:",
          graph_title: "Average spent time per image",
          most_view: "The most viewed,",
          least_view: "The leaset viewed,",
          sec: "sec",
          th_image: "Image",
        },
      },
      why_blockchain: {
        title: "Why Blockchain?",
        sub_title: {
          text1:
            "Basalt leverages blockchain technology to securely record every process of your work",
          text2: "The",
          text3: "transparent, decentralized",
          text4: ", and",
          text5: "traceable",
          text6: " nature of blockchain establishes",
          text7: "secure and incorruptible trail of your assets",
          text8: "leading to numerous benefits",
        },
        no_corr: {
          title: "No need of corroboration",
          body: "Serves as the best evidence rule by being traceable, maintaining the integrity of the data and immutable to tampering",
        },
        cost: {
          title: "Cost and time efficient",
          body: "Saves cost and time by eliminating the need for middlemen in recording, tracking and verifying processes",
        },
        trasnparent: {
          title: "Transparent and accurate",
          body: "Eliminates human involvement in the recording and verification process resulting in a transparent and accurate record of information",
        },
        news1:
          "In US, a number of states and federal agencies have introduced legislation providing a framework for business and legal application of blockchain technology and enforceability of smart contracts",
        new2: "International organizations such as United Nations are in process of evaluating the potential use of blockchain technology to improve transparency, efficiency and trust in government information sharing",
      },
      sign_in_landing: {
        title: "Start all this management now!",
        place_holder: "Enter your email now to start free trial",
        send: "Free Trial Right Now",
      },
      solutions: {
        titles: {
          title1: "How to manage your documents",
          title2: "with baSalt",
        },
        sub_title:
          "You can manage your documents in various situations with baSalt!",
        fund: {
          title: "For fundraising",
          body: "Have complete control over your investor pitch deck using unique links and attached NDA",
        },
        partnership: {
          title: "For new partnerships",
          body: "Request to sign confidential documents and contracts in a reliable and fully traceable way",
        },
        idea: {
          title: "For your ideas and innovations",
          body: "Keep a complete audit trail to track your progress and prove ownership of your ideas",
        },
        collabo: {
          title: "For collaboration",
          body: "Create a safe and secure virtual data room for your team and your clients",
        },
      },
      how: {
        title: "Start managing documents smartly with baSalt",
        sub_titles: {
          fst: "Basalt leverages blockchain technology to securely record every process of your work",
          snd: "The transparent, decentralized, and traceable nature of blockchain establishes leading to numerous benefits",
        },

        protect: {
          title: "Protect your document",
        },

        how1: "Upload your work and get it certified in few simple steps",
        how2: "Monitor the complete history of your work",
        how3: "Create unique links with customized settings to share safely",
        how4: "Monitor how your assets are being accessed",
      },
      sign_up: "Get started today!",
      news: {
        titles: {
          fst: "",
          snd: "Why blockchain?",
          thrd: "",
          four: "",
        },
        read_more: "Read more",
        sub_titles: {
          fst: "Look at various overseas cases and trends using blockchain technology",
          snd: "",
        },
        news_1:
          "In US, a number of states and federal agencies have introduced legislation providing a framework for business and legal application of blockchain technology and enforceability of smart contracts",
        news_2:
          "International organizations such as United Nations are in process of evaluating the potential use of blockchain technology to improve transparency, efficiency and trust in government information sharing",
      },
      customer_case: {
        title: "Why did we introduce baSalt?",
        sub_title: "Reviews of real users with baSalt",
        ps_title:
          "(* The review was created with permission to post anonymously.)",
      },
      effect: {
        prove: {
          title: "Legal Effect",
          ownership: {
            title: "Ownership",
            body: "Ensure your ownership of your work by leaving digital fingerprint on blockchain",
          },
          prove_process: {
            title: "Proof of process",
            body: "Prove every process of your ideas and work with the complete audit trail",
          },
          originality: {
            title: "Originality",
            body: "Protect and prove the originality of your work with tamper-proof recordings",
          },
        },
        share: {
          title: "Efficient management",
          monitor: {
            title: "Monitor",
            body: "Track and monitor who opens and downloads your assets",
          },
          control: {
            title: "Complete control",
            body: "Have complete control over your assets with granular access control",
          },
          intuitive: {
            title: "Easy and intuitive",
            body: "Share your work safely in few simple steps! No onboarding trainig needed!",
          },
        },

        protect: {
          title: "Protect your document",
          secure: {
            title: "Secure",
            body: "Have peace of mind that everything is encrypted from files to storage",
          },
          certified: {
            title: "Instantly certified",
            body: "Upload your work and it’s intantly certified for you to share safely",
          },

          trans: {
            title: "Transparency in Certification",
            body: "All certification process are transparent and traceable",
          },
          legal: {
            title: "Legal made simple",
            body: "Attach NDA, eSignature or any other legal protections when sharing your work",
          },
        },
      },
    },
  },
};
