import { doAPIPostRequest, doAPIGetRequest } from "./api";

import {
  IIssueVBank,
  ICheckPayment,
  IIssueCard,
} from "@basalt-commons/api/request/payment";
import {
  OIssueVBank,
  validateOIssueVBank,
  OPaymentCheck,
  validateOPaymentCheck,
  validateOIssueCard,
  OIssueCard,
  OGetPendingPayment,
  validateOGetPendingPayment,
} from "@basalt-commons/api/response/payment";
import { InternalErrorKind, isErr } from "@redwit-commons/utils/exception2";
import { getLoggers } from "@redwit-commons/utils/log";
const { WARN, INFO } = getLoggers("service/payment");

/**
 * GOONO 서버에 가상계좌 발급을 요청
 * @param goonoToken string
 * @param args IIssueVBank
 * @returns Promise<OIssueVBank>
 */
const issueVBank = async (
  goonoToken: string,
  args: IIssueVBank
): Promise<OIssueVBank> => {
  const ret = await doAPIPostRequest("payment/vbank", goonoToken, args);
  return validateOIssueVBank(ret);
};

const issueCard = async (
  goonoToken: string,
  args: IIssueCard
): Promise<OIssueCard> => {
  const ret = await doAPIPostRequest("payment/card", goonoToken, args);
  return validateOIssueCard(ret);
};

const checkPayment = async (
  goonoToken: string,
  args: ICheckPayment
): Promise<OPaymentCheck> => {
  const ret = await doAPIGetRequest("payment/check", goonoToken, args);
  return validateOPaymentCheck(ret);
};

const getPendingPayment = async (
  goonoToken: string
): Promise<OGetPendingPayment | undefined> => {
  try {
    const ret = await doAPIGetRequest("payment/pending", goonoToken);
    return validateOGetPendingPayment(ret);
  } catch (err) {
    if (isErr(err)) {
      if (
        err.kind === InternalErrorKind.ResponseCode &&
        err.code >= 400 &&
        err.code < 500
      ) {
        INFO("NO Payment", err.code);
        return undefined;
      }
      WARN("진행중 결제를 가져오는 도중 에러 발생", err);
    }
    throw err;
  }
};

export default {
  issueVBank,
  issueCard,
  checkPayment,
  getPendingPayment,
};
