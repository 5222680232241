import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import clsx from "clsx";

type FlexProps = {
  container?: boolean;
  item?: boolean;
  flexFlow?: string;
  gap?: string;
  fullWidth?: boolean;
} & Omit<BoxProps, "">;

function Flex({
  container = false,
  item = false,
  flexFlow,
  gap,
  flexDirection,
  flexWrap,
  justifyContent,
  alignContent,
  alignItems,
  alignSelf,
  order,
  flexGrow,
  flexShrink,
  flexBasis,
  flex,
  children,
  className,
  fullWidth,
  onClick,
}: FlexProps) {
  const displayFlex = container && container === true ? `flex` : undefined;
  const setContainerValue = (value: any) => {
    if (container && container === true) {
      return value;
    }
  };
  const setItemValue = (value: any) => {
    if (item && item === true) {
      return value;
    }
  };

  return (
    <Box
      onClick={onClick}
      className={clsx(className)}
      display={`${displayFlex}`}
      flexDirection={setContainerValue(flexDirection)}
      flexWrap={setContainerValue(flexWrap)}
      justifyContent={setContainerValue(justifyContent)}
      alignContent={setContainerValue(alignContent)}
      alignItems={setContainerValue(alignItems)}
      alignSelf={setItemValue(alignSelf)}
      order={setItemValue(order)}
      flexGrow={setItemValue(flexGrow)}
      flexShrink={setItemValue(flexShrink)}
      flexBasis={setItemValue(flexBasis)}
      flex={setItemValue(flex)}
      style={{
        flexFlow: setContainerValue(flexFlow),
        gap: setContainerValue(gap),
        width: fullWidth ? "100%" : undefined,
      }}
    >
      {children}
    </Box>
  );
}

export default Flex;
