import { TaskObject, TaskObjectSchema } from "../../api/object/task";
import {
  GoonoArrayResponse,
  GoonoPendingTasks,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "../../api/response/common";
import T from "@redwit-commons/utils/typecheck";
import { ContractObject, ContractObjectSchema } from "../object/nda_contract";
import {
  WorkspaceWithNdaContract,
  WorkspaceWithNdaContractSchema,
} from "../object/workspace";

export enum CheckDownloadableErrorType {
  EXPIRED = "WorkspacePlan is expired",
  INDIVIDUAL = "Individual plan do not support download contract",
}

export const CheckDownloadableErrorTypeList = [
  CheckDownloadableErrorType.INDIVIDUAL,
  CheckDownloadableErrorType.EXPIRED,
];

export type OCreateNDAContract = GoonoResponse<ContractObject> &
  GoonoPendingTasks<{ contract_task: string }>;
export const OCreateNDAContractSchema = mkResponseSchema(
  ContractObjectSchema,
  T.object().addField("contract_task", T.string())
);
export const validateOCreateNDAContract = T.mkValidator<OCreateNDAContract>(
  OCreateNDAContractSchema
);

export type OGetNDAContractList = GoonoResponse<WorkspaceWithNdaContract>;
export const OGetNDAContractListSchema = mkResponseSchema(
  WorkspaceWithNdaContractSchema
);
export const validateOGetNDAContractList = T.mkValidator<OGetNDAContractList>(
  OGetNDAContractListSchema
);

export type OGetNDAContract = GoonoResponse<ContractObject>;
export const OGetNDAContractSchema = mkResponseSchema(ContractObjectSchema);
export const validateOGetNDAContract = T.mkValidator<OGetNDAContract>(
  OGetNDAContractSchema
);

export type OSendContract = GoonoResponse<string>;
export const OSendContractSchema = mkResponseSchema(T.string());
export const validateOSendContract =
  T.mkValidator<OSendContract>(OSendContractSchema);

export type OGetWorkspacesNDAContractList =
  GoonoArrayResponse<WorkspaceWithNdaContract>;
export const OGetWorkspacesNDAContractListSchema = mkArrayResultsSchema(
  WorkspaceWithNdaContractSchema
);
export const validateOGetWorkspacesNDAContractList =
  T.mkValidator<OGetWorkspacesNDAContractList>(
    OGetWorkspacesNDAContractListSchema
  );

export type OCheckDownloadableContract = GoonoResponse<{
  available: boolean;
  message?: CheckDownloadableErrorType;
}>;
export const OCheckDownloadableContractSchema = mkResponseSchema(
  T.object()
    .addField("available", T.boolean())
    .addField(
      "message",
      T.string().withEnum(CheckDownloadableErrorTypeList),
      false
    )
);
export const validateOCheckDownloadableContract =
  T.mkValidator<OCheckDownloadableContract>(OCheckDownloadableContractSchema);

export type OGetNdaContractAllPDF = GoonoResponse<TaskObject> &
  GoonoPendingTasks<{ pdf: string }>;
export const OGetNdaContractAllPDFSchema = mkResponseSchema(
  TaskObjectSchema,
  T.object().addField("pdf", T.string())
);
export const validateOGetNdaContractAllPDF =
  T.mkValidator<OGetNdaContractAllPDF>(OGetNdaContractAllPDFSchema);

export type OGetMyNdaContractAll = GoonoResponse<TaskObject> &
  GoonoPendingTasks<{ zip: string }>;
export const OGetMyNdaContractAllSchema = mkResponseSchema(
  TaskObjectSchema,
  T.object().addField("zip", T.string())
);
export const validateOGetMyNdaContractAll = T.mkValidator<OGetMyNdaContractAll>(
  OGetMyNdaContractAllSchema
);

export type OCheckSignedDocument = GoonoResponse<{
  need_sign: boolean;
}>;
export const OCheckSignedDocumentSchema = mkResponseSchema(
  T.object().addField("need_sign", T.boolean())
);
export const validateOCheckSignedDocument = T.mkValidator<OCheckSignedDocument>(
  OCheckSignedDocumentSchema
);
