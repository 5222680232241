import { ProjectRoleType, ProjectRoleTypeList } from "../../api/object/project";
import {
  ProjectAuthType,
  ProjectAuthTypeValues,
} from "../../api/object/user_project_map";
import {
  IShareProjectMembers,
  IShareProjectMembersSchema,
} from "../../api/request/project";
import T from "@redwit-commons/utils/typecheck";

export type ICreateBasaltProject = {
  name: string;
  project_type: ProjectRoleType;
} & IShareProjectMembers;

export type ISetDefaultNDA = {
  NdaId: string;
};

export interface IUpdateJoinedUserProject {
  authType: Exclude<ProjectAuthType, ProjectAuthType.OWNER>;
  userId: string;
}

export interface IDeleteJoinedUserProject {
  UserId: string;
}

export type IUpdateOwner = {
  userId: string;
};
export type IResendProjectShare = {
  logId: string;
  email: string;
};
export type IUpdateProjectAuthSharedEmail = {
  logId: string;
  email: string;
  authType: Exclude<ProjectAuthType, ProjectAuthType.OWNER>;
};
export type IDeleteProjectSharedEmail = IResendProjectShare;

export const ICreateBasaltProjectSchema = IShareProjectMembersSchema.clone()
  .addField("name", T.string())
  .addField("project_type", T.string().withEnum(ProjectRoleTypeList));

export const ISetDefaultNDASchema = T.object().addField("NdaId", T.string());

export const IUpdateJoinedUserProjectSchema = T.object()
  .addField("authType", T.string().withEnum(ProjectAuthTypeValues))
  .addField("userId", T.string());

export const IDeleteJoinedUserProjectSchema = T.object().addField(
  "UserId",
  T.string()
);

export const IUpdateOwnerSchema = T.object().addField("userId", T.string());

export const IResendProjectShareSchema = T.object()
  .addField("logId", T.string())
  .addField("email", T.string());

export const IUpdateProjectAuthSharedEmailSchema = T.object()
  .addField("logId", T.string())
  .addField("email", T.string())
  .addField("authType", T.string().withEnum(ProjectAuthTypeValues));

export const IDeleteProjectSharedEmailSchema =
  IResendProjectShareSchema.clone();

export const validateICreateBasaltProject = T.mkValidator<ICreateBasaltProject>(
  ICreateBasaltProjectSchema
);
export const validateISetDefaultNDA =
  T.mkValidator<ISetDefaultNDA>(ISetDefaultNDASchema);
export const validateIUpdateJoinedUserProject =
  T.mkValidator<IUpdateJoinedUserProject>(IUpdateJoinedUserProjectSchema);
export const validateIDeleteJoinedUserProject =
  T.mkValidator<IDeleteJoinedUserProject>(IDeleteJoinedUserProjectSchema);
export const validateIUpdateOwner =
  T.mkValidator<IUpdateOwner>(IUpdateOwnerSchema);
export const validateIResendProjectShare = T.mkValidator<IResendProjectShare>(
  IResendProjectShareSchema
);
export const validateIUpdateProjectAuthSharedEmail =
  T.mkValidator<IUpdateProjectAuthSharedEmail>(
    IUpdateProjectAuthSharedEmailSchema
  );
export const validateIDeleteProjectSharedEmail =
  T.mkValidator<IDeleteProjectSharedEmail>(IDeleteProjectSharedEmailSchema);
