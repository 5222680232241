import { AccessTime, withAccessTime } from "../../api/object/access_time";
import { UserObject, UserObjectSchema } from "../../api/object/user";
import { ProjectAuthType } from "../../api/object/user_project_map";
import T from "@redwit-commons/utils/typecheck";
import { WorkspaceRoleType } from "./user_workspace_map";

export enum TokenLogType {
  // basalt의 회원가입 시, 이메일 인증에 사용되는 토큰
  AUTH_EMAIL = "TokenLog::Auth_Email",
  // 비밀번호 변경 인증에 사용되는 토큰
  PW = "TokenLog::PW",
  // 프로젝트 초대 메일 발송에 사용되는 토큰
  INVITE_PROJECT = "TokenLog::Invite_Project",
  // 워크스페이스 초대 메일 발송에 사용되는 토큰
  INVITE_WORKSPACE = "TokenLog::Invite_Workspace",
  // 워크스페이스 관리자에게 노크 메일 발송에 사용되는 토큰
  KNOCK_WORKSPACE = "TokenLog::Knock_Workspace",
}

// 이메일 상태
export enum TokenStatusType {
  PENDING = "EmailStatus::Pending", // 최초 이메일 발송 상태
  SUCCESS = "EmailStatus::Success", // 이메일 확인 상태
  CANCEL = "EmailStatus::Cancel", // 발송한 이메일의 유효기간이 끝난 상태
}

export const TokenLogTypeList = [
  TokenLogType.AUTH_EMAIL,
  TokenLogType.PW,
  TokenLogType.INVITE_PROJECT,
  TokenLogType.INVITE_WORKSPACE,
  TokenLogType.KNOCK_WORKSPACE,
];

export const TokenStatusTypeList = [
  TokenStatusType.CANCEL,
  TokenStatusType.PENDING,
  TokenStatusType.SUCCESS,
];

export type TokenLogPureCore = {
  readonly id: string;
  readonly token: string;
  readonly type: TokenLogType;
  readonly status: TokenStatusType;
  readonly email: string;
  readonly authType?:
    | ProjectAuthType
    | Exclude<WorkspaceRoleType, WorkspaceRoleType.OWNER>;
  readonly UserId?: string;
  readonly ProjectId?: string;
  readonly WorkspaceId?: string;
};

export type TokenLogObject = TokenLogPureCore & AccessTime;
export type TokenLogWithUserObject = {
  readonly User?: UserObject;
} & TokenLogObject;

export const TokenLogObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("token", T.string())
  .addField("type", T.string().withEnum(TokenLogTypeList))
  .addField("status", T.string().withEnum(TokenStatusTypeList))
  .addField("email", T.string())
  .addField("authType", T.string(), false)
  .addField("UserId", T.string(), false)
  .addField("ProjectId", T.string(), false)
  .addField("WorkspaceId", T.string(), false);

export const TokenLogWithUserObjectSchema =
  TokenLogObjectSchema.clone().addField(
    "User",
    UserObjectSchema.clone(),
    false
  );

export const extractTokenLogObject =
  T.mkObjectExtractor<TokenLogObject>(TokenLogObjectSchema);
export const validateTokenLogObject =
  T.mkValidator<TokenLogObject>(TokenLogObjectSchema);
export const extractTokenLogWithUserObject =
  T.mkObjectExtractor<TokenLogWithUserObject>(TokenLogWithUserObjectSchema);
export const validateTokenLogWithUserObject =
  T.mkValidator<TokenLogWithUserObject>(TokenLogWithUserObjectSchema);
