import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { PayplePaymentObject } from "@basalt-commons/global-api/object/payments";
import {
  ODomesticPayPleAuthWOPartnerInfo,
  OGlobalPayPleAuth,
} from "@basalt-commons/global-api/response/payment";

export enum PaymentStateStatus {
  INIT = "PaymentState::INIT",
}

export enum PaymentActionKind {
  TRY_SET_DEFAULT_CARD = "PaymentAction::TRY_SET_DEFAULT_CARD",
  TRY_DELETE_CARD = "PaymentAction::TRY_DELETE_CARD",
  TRY_GET_WORKSPACE_PAYMENT = "PaymentAction::TRY_GET_WORKSPACE_PAYMENT",
  TRY_GET_PAYPLE_GLOBAL_AUTH = "PaymentAction::TRY_GET_PAYPLE_GLOBAL_AUTH",
  TRY_GET_PAYPLE_DOMESTIC_AUTH = "PaymentAction::TRY_GET_PAYPLE_DOMESTIC_AUTH",
}
export type PaymentError = never;

export type PaymentState = {
  readonly status: PaymentStateStatus.INIT;
  readonly cardPayments?: PayplePaymentObject[];
  readonly payple_global_auth_info?: OGlobalPayPleAuth;
  readonly payple_domestic_auth_info?: ODomesticPayPleAuthWOPartnerInfo;
};

export type PaymentAction =
  | {
      readonly kind: PaymentActionKind.TRY_SET_DEFAULT_CARD;
      readonly cardId: string;
    }
  | {
      readonly kind: PaymentActionKind.TRY_DELETE_CARD;
      readonly cardId: string;
    }
  | {
      readonly kind: PaymentActionKind.TRY_GET_WORKSPACE_PAYMENT;
    }
  | {
      readonly kind: PaymentActionKind.TRY_GET_PAYPLE_GLOBAL_AUTH;
    }
  | {
      readonly kind: PaymentActionKind.TRY_GET_PAYPLE_DOMESTIC_AUTH;
    };

const smid = "PAYMENT_STATE_MACHINE3";
export type PaymentStateMachineType = StateMachine3<
  PaymentStateStatus,
  PaymentState,
  PaymentActionKind,
  PaymentAction,
  PaymentError
>;
export const paymentStateMachine: PaymentStateMachineType = new StateMachine3<
  PaymentStateStatus,
  PaymentState,
  PaymentActionKind,
  PaymentAction,
  PaymentError
>(smid, { status: PaymentStateStatus.INIT }, [
  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.INIT,
    PaymentActionKind.TRY_SET_DEFAULT_CARD
  ),
  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.INIT,
    PaymentActionKind.TRY_DELETE_CARD
  ),
  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.INIT,
    PaymentActionKind.TRY_GET_WORKSPACE_PAYMENT
  ),
  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.INIT,
    PaymentActionKind.TRY_GET_PAYPLE_GLOBAL_AUTH
  ),
  transition(
    PaymentStateStatus.INIT,
    PaymentStateStatus.INIT,
    PaymentActionKind.TRY_GET_PAYPLE_DOMESTIC_AUTH
  ),
]);

export type DispatchPaymentAction = Dispatch<
  StateMachineAction<
    PaymentStateStatus,
    PaymentState,
    PaymentActionKind,
    PaymentAction,
    PaymentError
  >
>;
export default mkReducer<
  PaymentStateStatus,
  PaymentState,
  PaymentActionKind,
  PaymentAction,
  PaymentError
>(paymentStateMachine);

export const doPaymentAction = (
  dispatch: DispatchPaymentAction,

  nextAction: PaymentAction,
  onResolve: () => void = () => {},
  onReject: (err: PaymentError | InternalError) => void = () => {}
) => {
  dispatch(paymentStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doPaymentActionAsync = (
  dispatch: DispatchPaymentAction,
  nextAction: PaymentAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(paymentStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetPayment = (dispatch: DispatchPaymentAction) => {
  dispatch(paymentStateMachine.newResetAction());
};
