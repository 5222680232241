import T from "@redwit-commons/utils/typecheck";
import {
  ContentObject,
  ContentObjectWOStage,
  ContentObjectWOStageSchema,
  ContentObjectWStage,
  ContentObjectWStageSchema,
  ContentObjectSchema,
} from "../object/content";
import {
  GoonoResponse,
  mkResponseSchema,
  GoonoArrayResponse,
  mkArrayResultsSchema,
} from "./common";

export type OGetPublishContentTitle = GoonoArrayResponse<ContentObjectWOStage>;
export type OGetAllContentTitle = GoonoArrayResponse<ContentObjectWStage>;
export type OGetContentBody = GoonoResponse<ContentObject>;
export type OCreateContent = GoonoResponse<string>;
export type OUpdateContentView = GoonoResponse<string>;
export type OUpdateContent = GoonoResponse<ContentObject>;
export type ODeleteContent = GoonoResponse<string>;

export const OGetPublishContentTitleSchema = mkArrayResultsSchema(
  ContentObjectWOStageSchema
);
export const OGetAllContentTitleSchema = mkArrayResultsSchema(
  ContentObjectWStageSchema
);
export const OGetContentBodySchema = mkResponseSchema(ContentObjectSchema);
export const OCreateContentSchema = mkResponseSchema(T.string());
export const OUpdateContentViewSchema = mkResponseSchema(T.string());
export const OUpdateContentSchema = mkResponseSchema(ContentObjectSchema);
export const ODeleteContentSchema = mkResponseSchema(T.string());

export const validateOGetPublishContentTitle =
  T.mkValidator<OGetPublishContentTitle>(OGetPublishContentTitleSchema);
export const validateOGetAllContentTitle = T.mkValidator<OGetAllContentTitle>(
  OGetAllContentTitleSchema
);
export const validateOGetContentBody = T.mkValidator<OGetContentBody>(
  OGetContentBodySchema
);
export const validateOCreateContent =
  T.mkValidator<OCreateContent>(OCreateContentSchema);
export const validateOUpdateContentView = T.mkValidator<OUpdateContentView>(
  OUpdateContentViewSchema
);
export const validateOUpdateContent =
  T.mkValidator<OUpdateContent>(OUpdateContentSchema);
export const validateODeleteContent =
  T.mkValidator<ODeleteContent>(ODeleteContentSchema);
