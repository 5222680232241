import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";

/*
 * types
 */
export interface GDTCore {
  id: string;
  access_token: string;
  refresh_token: string;
  expires_in: string;
  UserId: string;
}
export type GDTObject = GDTCore & AccessTime;
// export type GDTDBObject = GDTObject & {User: UserDBObject};
/*
 * Schema
 */
export const GDTObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("access_token", T.string())
  .addField("refresh_token", T.string())
  .addField("expires_in", T.string())
  .addField("UserId", T.string());

/*
 * Validator & Extractor
 */
export const extractGDTObject = T.mkObjectExtractor<GDTObject>(GDTObjectSchema);
