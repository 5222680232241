import moment from "moment";

export function getFormat(
  time_zone: string | undefined,
  separator?: string,
  noTime?: boolean
): string {
  let timezone = time_zone === undefined ? moment.tz.guess(true) : time_zone;
  let ymdCountries = ["CN", "JP", "KR", "KP", "TW", "HU", "MN", "LT", "BT"];
  let output = "";
  let time = " HH:mm:ss";

  for (let i = 0; i < ymdCountries.length; i++) {
    if (moment.tz.zonesForCountry(ymdCountries[i]).includes(timezone)) {
      output = "MMM D, YYYY h:mm A";
      break;
    } else if (moment.tz.zonesForCountry("US").includes(timezone)) {
      output = "MM/DD/YYYY";
      break;
    }
  }

  if (!output) {
    output = "MMM D, YYYY h:mm A";
  }

  if (separator) {
    output = output.replaceAll("/", separator);
  }
  if (noTime !== true) {
    output += time;
  }
  return output;
}
