import Flex from "@src/components/pure/utils/Flex";
import clsx from "clsx";
import useStyle from "./styles/NDACardItem";
import SpoqaHansTypography from "@SpoqaHansTypography";
import React, { useState } from "react";
import NDACardItemMenu from "@src/screens/NDAScreen/NDACardItemMenu";
import { Grid } from "@material-ui/core";
import { ReactComponent as SignatureIcon } from "@ui/basalt/images/note/nav_draw.svg";
import { ReactComponent as ConnectedFileIcon } from "@ui/basalt/images/note/nav_addFile.svg";
import CardIItem, { CardItemProps } from "@src/components/pure/items/CardItem";
import { useHistory, useParams } from "react-router-dom";

export type NDAItemProps = {
  contract_count?: number;
  connected_file_count?: number;
  nda_id?: string;
  onDownloadAllContract?: (ndaId: string) => Promise<void>;
  onDeleteNDA?: () => void;
};

export type NDACardItemProps = NDAItemProps & CardItemProps;

export const NDACardItem: React.FC<NDACardItemProps> = ({ ...props }) => {
  const {
    windowWidth,
    nda_id,
    connected_file_count,
    contract_count,
    onDownloadAllContract,
    onDeleteNDA,
  } = props;
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const history = useHistory();
  const params: any = useParams();
  const classes = useStyle();

  const renderNDAMoreMenu = () => {
    if (!hover) return;
    if (!onDeleteNDA || !onDownloadAllContract || !setAnchorEl) return;
    return (
      <NDACardItemMenu
        anchorEl={anchorEl}
        setHover={(h) => setHover(h)}
        setAnchorEl={(el) => setAnchorEl(el)}
        onClickDelete={onDeleteNDA}
        onDownloadAllContract={() => onDownloadAllContract(nda_id as string)}
      />
    );
  };

  const HoverBtnListView = () => {
    const classWithHovering = clsx(classes.hover_btn_list, classes.hovering);
    const className =
      windowWidth < 1024
        ? classWithHovering
        : hover
        ? classWithHovering
        : classes.hover_btn_list;
    return (
      <Flex gap="8px" container className={className}>
        {renderNDAMoreMenu()}
      </Flex>
    );
  };

  const renderIconInfoObject = ({
    count,
    icon,
  }: {
    count: number;
    icon: React.ReactNode;
  }) => {
    return (
      <Grid
        item
        container
        justifyContent="flex-end"
        alignItems="center"
        className={classes.bottom_icon_info_wrap}
      >
        {icon}
        <SpoqaHansTypography
          fontWeight="medium"
          className={classes.bottom_icon_info_count}
        >
          {count > 99 ? "99+" : count}
        </SpoqaHansTypography>
      </Grid>
    );
  };

  const CardInfoWithIcon = () => {
    return (
      <Flex item container gap="5px">
        {renderIconInfoObject({
          icon: <SignatureIcon />,
          count: contract_count ? contract_count : 0,
        })}
        {renderIconInfoObject({
          icon: <ConnectedFileIcon />,
          count: connected_file_count ? connected_file_count : 0,
        })}
      </Flex>
    );
  };

  const cardClickHandler = () => {
    history.push(`/${params.workspace_name}/nda/${nda_id}`);
  };

  return (
    <CardIItem
      {...props}
      onCardClick={cardClickHandler}
      hoverbutton={HoverBtnListView()}
      cardInfoWithIcon={CardInfoWithIcon()}
      hover={hover}
      setHover={(state) => {
        setHover(state);
      }}
    />
  );
};

export default NDACardItem;
