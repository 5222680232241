import T from "@redwit-commons/utils/typecheck";
import {
  WorkspacePlanPureCoreSchema,
  WorkspacePlanPureObject,
} from "./workspace_plan";
import { CardObject, CardObjectSchema } from "./card";
import {
  WorkspaceDBObject,
  WorkspaceDBObjectSchema,
  WorkspaceObject,
  WorkspaceObjectSchema,
} from "./workspace";
import {
  PaypleDBObject,
  PaypleDBObjectSchema,
  PayplePaymentObject,
  PayplePaymentObjectSchema,
  refinePayplePayment,
} from "./payments";
import { extractUserObject } from "../../api/object/user";
import { validateUserObjectWithWorkspaceRoleType } from "./user";

export type WorkspaceWithPaymentInfoDBObject = {
  readonly WorkspacePlan: WorkspacePlanPureObject;
  readonly Cards: Array<CardObject>;
  readonly Payples: Array<PaypleDBObject>;
} & WorkspaceDBObject;

export type WorkspaceWithPaymentInfo = {
  readonly WorkspacePlan: WorkspacePlanPureObject;
  readonly Cards: Array<CardObject>;
  readonly Payples: Array<PayplePaymentObject>;
} & WorkspaceObject;

export const refineWorkspaceWithPaymentInfo = (
  rdbWorkspace: WorkspaceWithPaymentInfoDBObject
) => {
  const Users = rdbWorkspace.Users.map((user) => {
    const userObject = extractUserObject(user);
    const roleType = user.User_Workspace_Map.roleType;
    const userObjWorkspaceWithRole = validateUserObjectWithWorkspaceRoleType({
      ...userObject,
      roleType,
    });
    return userObjWorkspaceWithRole;
  });
  const Payples = rdbWorkspace.Payples.map((payment) => {
    return refinePayplePayment(payment);
  });

  const ret: WorkspaceWithPaymentInfo = {
    ...rdbWorkspace,
    Users,
    Payples,
  };

  return ret;
};

export const WorkspaceWithPaymentInfoDBObjectSchema =
  WorkspaceDBObjectSchema.clone()
    .addField("WorkspacePlan", WorkspacePlanPureCoreSchema.clone())
    .addField("Cards", T.array(CardObjectSchema.clone()))
    .addField("Payples", T.array(PaypleDBObjectSchema.clone()));

export const RefineWorkspaceWithPaymentInfoSchema =
  WorkspaceObjectSchema.clone()
    .addField("WorkspacePlan", WorkspacePlanPureCoreSchema.clone())
    .addField("Cards", T.array(CardObjectSchema.clone()))
    .addField("Payples", T.array(PayplePaymentObjectSchema.clone()));

export const extractWorkspaceWithPaymentInfoDBObject =
  T.mkObjectExtractor<WorkspaceWithPaymentInfoDBObject>(
    WorkspaceWithPaymentInfoDBObjectSchema
  );
export const validateWorkspaceWithPaymentInfoDBObject =
  T.mkValidator<WorkspaceWithPaymentInfoDBObject>(
    WorkspaceWithPaymentInfoDBObjectSchema
  );
