export default {
  ko: {
    pw_verify_screen: {
      title: "비밀번호 변경",
      place_holder: "이메일을 입력해주세요",
      verify_email: "이메일 인증하기",
      send_mail: "인증 메일이 발송되었습니다",
      fail_mail: "인증 메일 발송이 실패하였습니다. 다시 시도해주세요",
    },
  },
  en: {
    pw_verify_screen: {
      title: "Change the password",
      place_holder: "Enter your Email",
      verify_email: "Verify Email address",
      send_mail: "Verification Email has been sent!",
      fail_mail: "Failed to send the verification Email. Please try it again",
    },
  },
};
