import { createStyles, Theme } from "@material-ui/core";

const styles = (MuiTheme: Theme) =>
  createStyles({
    root: {
      flexDirection: "column",
      width: "100%",
      maxWidth: "calc(1556px + 44px * 2)",
      padding: "0px 44px 36px",
      margin: "auto",
      minHeight: "calc(100vh - 48px)",

      [MuiTheme.breakpoints.down(1024)]: {
        padding: "0px 16px",
      },
    },
  });

export default styles;
