export default {
  ko: {
    file: {
      list: {
        title: {
          name: "파일 이름",
          shared_link: "공유 링크",
          updatedAt: "업데이트",
          uploader: "업로더",
        },
      },

      item: {
        button: {
          share: "공유",
          report: "리포트",
        },
      },
      update_prefix: "",
      update_postfix: " 업데이트됨",
      create_prefix: "",
      create_postfix: "생성됨",
      tooltip_view: "클릭해서 파일을 확인하세요",
      tooltip_link: "총 링크 수",
      tooltip_report: "파일의 보고서를 확인할 수 있습니다",
      tooltip_link_share: "이 파일을 공유할 새 링크를 가져옵니다",
      verified: "Verified",
      blocks: "block",
      link: {
        list: {
          title: {
            status: "상태",
            name: "이름",
            share: "공유 링크",
            option: "링크 옵션",
          },
        },
        new_link: "새로운 링크",
        none: "링크를 찾을 수 없습니다",
        expired_link: "기간 만료",
      },
      tags: {
        new: "새로운 태그",
        none: "태그를 찾을 수 없습니다.",
        more: "태그 추가",
      },

      grid: {
        title: "파일",
        create_link: "링크 생성하기",
        view_file: "파일 보기",
        link_postfix: "개의 링크가 생성되어 있습니다.",
        one_link: "1개의 링크가 생성되어 있습니다.",
      },
      menu: {
        create_link: "공유 링크 생성",
        link_report: "링크 리포트",
        file_info: "파일 정보",
        link_email: "링크 이메일 발송",
        download: "파일 다운받기",
        delete: "파일삭제",
      },
    },
  },
  en: {
    file: {
      list: {
        title: {
          name: "File name",
          shared_link: " Link",
          updatedAt: "Updated",
          uploader: "Uploader",
        },
      },

      item: {
        button: {
          share: "Share",
          report: "Report",
        },
      },
      update_prefix: "Updated ",
      update_postfix: "",
      create_prefix: "Created",
      create_postfix: "",
      verified: "Verified",
      tooltip_view: "Click to view the file",
      tooltip_link: "Total links",
      tooltip_report: "You can check the report of file",
      tooltip_link_share: "Get a new link to share this file",
      blocks: "Blocks",
      link: {
        list: {
          title: {
            status: "Status",
            name: "Name",
            share: "Link",
            option: "Options",
          },
        },
        new_link: "New link",
        none: "No links were found",
        expired_link: "Expired link",
      },

      tags: {
        new: "New tags",
        none: "No tags were found.",
        more: "Add tags",
      },

      grid: {
        title: "File",
        create_link: "Create a link",
        view_file: "View file",
        link_postfix: " links have been created.",
        one_link: "1 link has been created.",
      },

      menu: {
        create_link: "Create a link",
        link_report: "Link report",
        file_info: "File Info.",
        link_email: "Send a link via email",
        download: "Download",
        delete: "Delete",
      },
    },
  },
};
