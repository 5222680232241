import { doAPIGetRequest, USE_BACKUP_SERVER, ENV } from "./api";
import { OVersion, validateOVersion } from "@basalt-commons/api/response/home";
import { getLoggers } from "@redwit-commons/utils/log";
import {
  InternalErrorKind,
  isErr,
  mkErr,
} from "@redwit-commons/utils/exception2";

const { WARN } = getLoggers("service");

/**
 * GOONO 서버에 현재 버전 정보를 받아오는 API
 * @returns Promise<OVersion>
 */
const getVersionInfo = async (): Promise<OVersion> => {
  const ret = await doAPIGetRequest("/");
  return validateOVersion(ret);
};

/**
 * Version info 를 기반으로 현재 connection 모드를 고르기도 함.
 * @returns Promise<OVersion>
 */
const checkConnection = async (): Promise<OVersion> => {
  USE_BACKUP_SERVER.backup_index = undefined;
  for (;;) {
    try {
      const ret = await doAPIGetRequest("/");
      return validateOVersion(ret);
    } catch (err) {
      if (isErr(err) && err.kind === InternalErrorKind.Network) {
        WARN("Soft error duing version check , try alternative servers.");
        if (USE_BACKUP_SERVER.backup_index === undefined) {
          WARN(`Prev was ${ENV.API_SERVER}`);
          USE_BACKUP_SERVER.backup_index = 0;
        } else {
          WARN(
            `Prev was ${ENV.BACKUP_SERVERS[USE_BACKUP_SERVER.backup_index]}`
          );
          USE_BACKUP_SERVER.backup_index += 1;
        }

        if (USE_BACKUP_SERVER.backup_index >= ENV.BACKUP_SERVERS.length) {
          USE_BACKUP_SERVER.backup_index = undefined;
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: "checkConnection",
            msg: "No more backup server is left",
          });
        }
        WARN(`Now using ${ENV.BACKUP_SERVERS[USE_BACKUP_SERVER.backup_index]}`);
      } else {
        throw mkErr({
          kind: InternalErrorKind.UncaughtException,
          exception: err,
        });
      }
    }
  }
};

export default {
  getVersionInfo,
  checkConnection,
};
