import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "./access_time";

export interface Translate {
  kr: string;
  en: string;
}
export const TranslateSchema = T.object()
  .addField("kr", T.string())
  .addField("en", T.string());
export const valdiateTranslate = T.mkValidator<Translate>(TranslateSchema);

export enum ContentStage {
  EDIT = "ContentStage::EDIT",
  PUBLISH = "ContentState::PUBLISH",
}

export interface ContentPublishHeader {
  readonly id: string;
  readonly thumbUrl?: string;
  readonly thumbCid: string;
  readonly thumbExtension: string;
  readonly title: Translate;
  readonly author: Translate;
  readonly view_count: number;
  readonly min_amount: number;
  readonly category: Translate;
  readonly recommend: boolean;
}

export interface ContentDBPublishHeader {
  readonly id: string;
  readonly thumbUrl?: string;
  readonly thumbCid: string;
  readonly thumbExtension: string;
  readonly title: string;
  readonly author: string;
  readonly view_count: number;
  readonly min_amount: number;
  readonly category: string;
  readonly recommend: boolean;
}

export type ContentDBEditHeader = {
  stage: ContentStage;
} & ContentDBPublishHeader;

export type ContentEditHeader = {
  stage: ContentStage;
} & ContentPublishHeader;

export type ContentObjectWOStage = ContentPublishHeader & AccessTime;
export type ContentObjectWStage = ContentEditHeader & ContentObjectWOStage;

export type ContentDBObjectWOStage = ContentDBPublishHeader & AccessTime;
export type ContentDBObjectWStage = ContentDBEditHeader &
  ContentDBObjectWOStage;

export interface ContentBody {
  readonly url: Translate;
}
export interface ContentDBBody {
  readonly url: string;
}

export type ContentDBObjectCore = ContentDBEditHeader & ContentDBBody;
export type ContentDBObject = ContentDBObjectWStage & ContentDBBody;

export type ContentObjectCore = ContentEditHeader & ContentBody;
export type ContentObject = ContentObjectWStage & ContentBody;

export const ContentDBObjectWOStageSchema = withAccessTime()
  .addField("id", T.string())
  .addField("author", T.string())
  .addField("title", T.string())
  .addField("min_amount", T.number())
  .addField("view_count", T.number())
  .addField("category", T.string())
  .addField("recommend", T.boolean())
  .addField("thumbUrl", T.string(), false)
  .addField("thumbCid", T.string())
  .addField("thumbExtension", T.string());

export const ContentObjectWOStageSchema = withAccessTime()
  .addField("id", T.string())
  .addField("author", TranslateSchema.clone())
  .addField("title", TranslateSchema.clone())
  .addField("min_amount", T.number())
  .addField("view_count", T.number())
  .addField("category", TranslateSchema.clone())
  .addField("recommend", T.boolean())
  .addField("thumbUrl", T.string(), false)
  .addField("thumbCid", T.string())
  .addField("thumbExtension", T.string());

export const ContentDBObjectWStageSchema =
  ContentDBObjectWOStageSchema.clone().addField(
    "stage",
    T.string().withEnum([ContentStage.EDIT, ContentStage.PUBLISH])
  );
export const ContentObjectWStageSchema =
  ContentObjectWOStageSchema.clone().addField(
    "stage",
    T.string().withEnum([ContentStage.EDIT, ContentStage.PUBLISH])
  );

export const ContentBodySchema = T.object().addField(
  "url",
  TranslateSchema.clone()
);

export const ContentObjectSchema = ContentObjectWStageSchema.clone().addField(
  "url",
  TranslateSchema.clone()
);

export const ContentDBBodySchema = T.object().addField("url", T.string());

export const ContentDBObjectSchema =
  ContentDBObjectWStageSchema.clone().addField("url", T.string());

export const extractContentObjectWOStage =
  T.mkObjectExtractor<ContentObjectWOStage>(ContentObjectWOStageSchema);
export const extractContentObjectWStage =
  T.mkObjectExtractor<ContentObjectWStage>(ContentObjectWStageSchema);
export const extractContentBodyStage =
  T.mkObjectExtractor<ContentBody>(ContentBodySchema);
export const extractContentObject =
  T.mkObjectExtractor<ContentObject>(ContentObjectSchema);

export const extractContentDBObjectWOStage =
  T.mkObjectExtractor<ContentDBObjectWOStage>(ContentDBObjectWOStageSchema);
export const extractContentDBObjectWStage =
  T.mkObjectExtractor<ContentDBObjectWStage>(ContentDBObjectWStageSchema);
export const extractContentDBBodyStage =
  T.mkObjectExtractor<ContentDBBody>(ContentDBBodySchema);
export const extractContentDBObject = T.mkObjectExtractor<ContentDBObject>(
  ContentDBObjectSchema
);
