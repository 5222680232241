import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import Services from "@basalt-react-commons/services";
import {
  NoteStateStatus,
  NoteState,
  NoteAction,
  NoteActionKind,
  NoteStateMachineType,
  noteStateMachine,
  NoteErrorType,
  doNoteAction,
} from "../../store/reducers/note";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import moment from "moment";
import { TokenStateStatus } from "../../store/reducers/token";
import { HistoryType, HistoryObject } from "@basalt-commons/api/object/history";
import pbytes from "pretty-bytes";
import {
  heicExtensionList,
  imageExtensionList,
  officeExtensionList,
  pdfExtensionList,
  validateHEICImageExtension,
  validateImageExtension,
  validateOfficeFileExtension,
  validatePDFFileExtension,
} from "@basalt-commons/api/request/note";
import { UserLogType } from "@basalt-commons/api/object/user_log";
import { v4 as uuid } from "uuid";
import { WorkspaceStateStatus } from "../../store/reducers/workspace";
import { TaskStatusType } from "@basalt-commons/api/object/task";
import { ProjectAuthType } from "@basalt-commons/api/object/user_project_map";
import { ProjectStateStatus } from "../../store/reducers/project";
import { ICreateDocument } from "@basalt-commons/global-api/request/document";
import { getProjectAuthLevel } from "@basalt-commons/global-api/object/user_project_map";
import { saveAs } from "file-saver";
import {
  DocumentObject,
  DocumentStatus,
} from "@basalt-commons/global-api/object/document";

const {
  GlobalNoteService,
  NoteService,
  TaskService,
  SearchService,
  GlobalProjectService,
  IPFSService,
  TimelineService,
  DocumentService,
} = Services;
const officeMimeTypeList = [
  "msword",
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
  "vnd.ms-excel",
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "vnd.ms-powerpoint",
  "vnd.openxmlformats-officedocument.presentationml.presentation",
];
const getOfficeExtension = (mimeType: string) => {
  switch (mimeType) {
    case "msword":
      return "doc";
    case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docx";
    case "vnd.ms-excel":
      return "xls";
    case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xlsx";
    case "vnd.ms-powerpoint":
      return "ppt";
    case "vnd.openxmlformats-officedocument.presentationml.presentation":
      return "pptx";
    default:
      throw mkErr({
        kind: InternalErrorKind.Abort,
        loc: "getOfficeExtension",
        msg: "invalid file type",
      });
  }
};
/**
 * HistoryList를 업데이트순으로 정리합니다
 * @param history HistoryObject[]
 */
//TODO : 같은시간에 업로드 되었을 경우 정렬이 정상 적용 되지 않는 이슈 있음
const alignHistoryWithUpdateTime = (
  history: HistoryObject[]
): HistoryObject[] => {
  const sort = history.sort((a, b) => {
    let aDate = moment(a.updatedAt);
    let bDate = moment(b.updatedAt);
    return aDate.isAfter(bDate) ? -1 : 1;
  });
  return sort;
};

/**
 *
 * @param extension
 */
const checkExtension = (extension: string) => {
  if (imageExtensionList.includes(extension)) {
    return validateImageExtension(extension);
  } else if (pdfExtensionList.includes(extension)) {
    return validatePDFFileExtension(extension);
  } else if (heicExtensionList.includes(extension)) {
    return validateHEICImageExtension(extension);
  } else if (officeExtensionList.includes(extension)) {
    return validateOfficeFileExtension(extension);
  } else {
    throw mkErr({
      kind: InternalErrorKind.Abort,
      loc: "checkExtension",
      msg: "invalid file type",
    });
  }
};

const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.note,
    file: state.file,
    token: state.token,
    project: state.project,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type NoteContainerProps = PropsFromRedux & {
  stateMachine: NoteStateMachineType;
};

class NoteContainer extends ReduxStateComponent3<NoteContainerProps> {
  static defaultProps = {
    stateMachine: noteStateMachine,
  };
  private NOTE_FETCH_SIZE = 30;
  constructor(props: NoteContainerProps) {
    super(props);
  }

  /**
   * byte 용량에 따라 변경된 값을 반환 ( byte, KB, MB )
   * @param fileSize byte 값
   * @returns 변경된 byte 값
   */
  fileSizeConvert(fileSize: number): string {
    return pbytes(fileSize);
  }

  protected async onAction(
    prevState: NoteState,
    action: NoteAction
  ): Promise<NoteState> {
    const { token, workspace } = this.props;

    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "NoteContainer",
        msg: "Login User only using Note Logic",
      });
    const userToken = token.state.token;
    const userId = token.state.id;
    const userName = token.state.name;
    const userProfileCid = token.state.profile_cid;
    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "NoteContainer",
        msg: "Only select workspaces using Note Logic",
      });
    const workspaceId = workspace.state.selectAuthWorkspace.id;

    switch (action.kind) {
      case NoteActionKind.TRY_GET_NOTES: {
        const { projectId, workspaceId } = action;

        const ret = await this.guardAwait(() =>
          GlobalNoteService.getNotes(userToken, workspaceId, projectId, {
            fetchSize: this.NOTE_FETCH_SIZE,
          })
        );
        const notes = ret.response.results;
        const retDoc = await this.guardAwait(() =>
          DocumentService.getDocuments(userToken, workspaceId, projectId)
        );
        const docs = retDoc.response.results;

        const extractExpiredDocs = docs.filter(
          (doc) =>
            doc.status === DocumentStatus.ACTIVE &&
            moment(doc.expires_in).isAfter(moment()) !== true
        );
        let updatedDocs: DocumentObject[] = [];
        if (extractExpiredDocs.length > 0) {
          const updateInActiveDocsPromise = extractExpiredDocs.map(
            async (doc) => {
              const ret = await this.guardAwait(() =>
                DocumentService.updateDocumentStatus(
                  userToken,
                  workspaceId,
                  projectId,
                  doc.id,
                  { status: DocumentStatus.INACTIVE }
                )
              );
              return ret.response;
            }
          );
          await this.guardAwait(() =>
            Promise.allSettled(updateInActiveDocsPromise)
          );

          const retDoc = await this.guardAwait(() =>
            DocumentService.getDocuments(userToken, workspaceId, projectId)
          );
          updatedDocs = retDoc.response.results;
        }

        // We get the projects belong to the current workspace
        const projects = await this.guardAwait(() =>
          GlobalProjectService.getProjects(userToken, workspaceId)
        );
        // Then find the one with id equal to projectId
        const project = projects.find((p) => p.id === projectId);

        if (project === undefined) {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: NoteActionKind.TRY_GET_NOTES,
            msg: `${NoteErrorType.PROJECT_NOT_IN_WORKSPACE}_projectId:${projectId}_workspaceId${workspaceId}`,
          });
        }

        // 최근 해시태그 정보 가져오기
        const historyInfo = await this.guardAwait(() =>
          SearchService.getSearchWord(userToken)
        );
        const recentHistory = alignHistoryWithUpdateTime(
          historyInfo.response.results.filter(
            (h) => h.type === HistoryType.HASHTAG
          )
        ).slice(0, 5);
        const recentHashtag = recentHistory.map((h) => h.value);
        const total = ret.response.metadata.total;

        // 이전 선택지가 있었다면 새로고침한 노트 목록중에서 없는 것 제외하고 최대한 유지해본다
        if (prevState.status === NoteStateStatus.SUCCESS_SELECT)
          return {
            status: NoteStateStatus.SUCCESS_SELECT,
            docs: updatedDocs.length > 0 ? updatedDocs : docs,
            selects: prevState.selects.filter((x) =>
              notes.some((n) => n.id === x.id)
            ),
            notes,
            project,
            recentHashtag,
            total,
          };
        else
          return {
            status: NoteStateStatus.SUCCESS,
            notes,
            project,
            recentHashtag,
            total,
            docs: updatedDocs.length > 0 ? updatedDocs : docs,
          };
      }
      case NoteActionKind.TRY_GET_MORE_NOTES: {
        if (prevState.status === NoteStateStatus.INIT)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_GET_MORE_NOTES,
            msg: "prevState is invalid",
          });
        const { project, notes } = prevState;
        const { workspaceId } = action;
        if (notes.length < 1) {
          return prevState;
        }
        const lastNotes = notes[notes.length - 1];
        const ret = await this.guardAwait(() =>
          GlobalNoteService.getNotes(userToken, workspaceId, project.id, {
            fetchSize: this.NOTE_FETCH_SIZE,
            beforeAt: lastNotes.createdAt,
          })
        );
        const allNotes = [...notes, ...ret.response.results];
        if (prevState.status === NoteStateStatus.SUCCESS_SELECT) {
          // all select 상태에서 더 받아오는 경우 all select note 업데이트 해주기
          if (prevState.allSelect === true) {
            const selected = allNotes;
            return {
              ...prevState,
              notes: allNotes,
              selects: selected,
              total: ret.response.metadata.total,
            };
          }
        }
        return {
          ...prevState,
          notes: allNotes,
          total: ret.response.metadata.total,
        };
      }
      case NoteActionKind.TRY_UPLOAD_NOTES: {
        const { files } = this.props.file.state;
        const { projectId, workspaceId, writtenAt } = action;

        // Check user auth. Only Write and above can perform this action
        const targetProject =
          this.props.project.state.status === ProjectStateStatus.SUCCESS
            ? this.props.project.state.projects.find(
                (pj) => pj.id === projectId
              )
            : undefined; // target project 확인하기
        const userAuth = targetProject?.Users.find(
          (u) => u.id === userId
        )?.authType;
        if (
          getProjectAuthLevel(userAuth) <
          getProjectAuthLevel(ProjectAuthType.WRITE)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: NoteActionKind.TRY_UPLOAD_NOTES,
            msg: "Only 'Write' and above can upload notes",
          });
        const noteGroupId = uuid();

        const uploadNotes = files.map(async (file: File) => {
          const mimeType = file.type.split("/")[1].toLowerCase();
          const extension = officeMimeTypeList.includes(mimeType)
            ? getOfficeExtension(mimeType)
            : checkExtension(mimeType);
          const cid = await this.guardAwait(() =>
            IPFSService.uploadFileIPFS(userToken, file, extension)
          );
          const note = await this.guardAwait(() =>
            GlobalNoteService.createNoteWithPreview(
              userToken,
              workspaceId,
              projectId,
              { cid, extension, writtenAt, file_name: file.name.normalize() }
            )
          );

          // background에서 task 확인 후  note action
          this.scheduleBackgroundTask(async (): Promise<boolean> => {
            // state 가 원하지 않는 방향으로 변하면 bgtask 취소
            if (
              prevState.status === NoteStateStatus.INIT ||
              prevState.project.id !== projectId
            ) {
              return false;
            }
            // task polling
            const ret = await TaskService.getTask(
              note.pending_tasks.newnote,
              userToken
            );
            if (ret.response.status !== TaskStatusType.INITIALIZED) {
              // notify cancelled or completed
              doNoteAction(this.props.dispatch, {
                kind: NoteActionKind.TRY_GET_NOTES,
                projectId,
                workspaceId,
              });
              if (
                ret.response.status === TaskStatusType.COMPLETED &&
                files.length > 1
              ) {
                await TimelineService.createTimeline(userToken, workspaceId, {
                  type: UserLogType.UPLOAD_CHUNKS_NOTES,
                  NoteId: note.response.id,
                  UserId: userId,
                  userName: userName,
                  profile_cid: userProfileCid,
                  ProjectId: projectId,
                  projectName: targetProject?.name,
                  WorkspaceId: workspaceId,
                  chunk_notes: files.length,
                  noteGroupId,
                  file_name: note.response.file_name,
                  blockNumber: note.response.Auth?.blockNum,
                });
              }
              await TimelineService.createTimeline(userToken, workspaceId, {
                type: UserLogType.UPLOAD_NOTE,
                NoteId: note.response.id,
                UserId: userId,
                userName: userName,
                profile_cid: userProfileCid,
                ProjectId: projectId,
                projectName: targetProject?.name,
                WorkspaceId: workspaceId,
                file_name: note.response.file_name,
                blockNumber: note.response.Auth?.blockNum,
              });
              return false;
            }
            // pending
            return true;
          });
        });
        await this.guardAwait(() => Promise.allSettled(uploadNotes));

        if (
          prevState.status !== NoteStateStatus.INIT &&
          prevState.project.id === projectId
        ) {
          const {
            response: { results, metadata },
          } = await this.guardAwait(() =>
            GlobalNoteService.getNotes(userToken, workspaceId, projectId)
          );
          return { ...prevState, notes: results, total: metadata.total };
        }
        return { ...prevState };
      }
      case NoteActionKind.TRY_SELECT_SEARCH: {
        const { selectNote, project, recentHashtag } = action;
        const ret = await this.guardAwait(() =>
          Promise.resolve(
            IPFSService.getFileSizeIPFS(userToken, selectNote.cid)
          )
        );
        let fileSize = "";
        if (ret !== undefined) fileSize = this.fileSizeConvert(parseInt(ret));
        return {
          status: NoteStateStatus.SUCCESS_SELECT,
          selects: [selectNote],
          fileSize,
          project,
          recentHashtag,
          notes: [],
          docs: [],
        };
      }
      /**
       * 노트들을 선택할 때 사용하는 액션
       */
      case NoteActionKind.TRY_SELECT_NOTE: {
        if (
          prevState.status !== NoteStateStatus.SUCCESS_SELECT &&
          prevState.status !== NoteStateStatus.SUCCESS
        )
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_SELECT_NOTE,
            msg: "Invalid prev state",
          });

        const { selects } = action;
        if (
          selects.length === 0 &&
          prevState.status === NoteStateStatus.SUCCESS_SELECT
        ) {
          return { ...prevState, selects: [] };
        }
        // 파일 사이즈 겟
        try {
          const ret = await this.guardAwait(() =>
            Promise.resolve(
              IPFSService.getFileSizeIPFS(userToken, selects[0].cid)
            )
          );
          let fileSize = "";
          if (ret !== undefined) fileSize = this.fileSizeConvert(parseInt(ret));
          return {
            ...prevState,
            status: NoteStateStatus.SUCCESS_SELECT,
            selects: selects,
            fileSize,
          };
        } catch (err) {
          console.log(err);
          return {
            ...prevState,
            status: NoteStateStatus.SUCCESS_SELECT,
            selects: selects,
          };
        }
      }
      /**
       * Select 된걸 전부 다 풀어버릴 때 사용하는 액션
       */
      case NoteActionKind.TRY_UNSELECT_NOTE: {
        if (prevState.status !== NoteStateStatus.SUCCESS_SELECT)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_UNSELECT_NOTE,
            msg: "Invalid prev state",
          });

        return { ...prevState, status: NoteStateStatus.SUCCESS };
      }
      case NoteActionKind.TRY_CREATE_LINK: {
        if (
          prevState.status !== NoteStateStatus.SUCCESS &&
          prevState.status !== NoteStateStatus.SUCCESS_SELECT
        ) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_CREATE_LINK,
            msg: "Invalid prev state",
          });
        }
        const { noteId, file_name } = action;
        const prev_notes =
          prevState.status === NoteStateStatus.SUCCESS_SELECT
            ? prevState.selects
            : prevState.notes;
        if (prev_notes.find((nt) => nt.id === noteId) === undefined) {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: NoteActionKind.TRY_CREATE_LINK,
            msg: "invalid note",
          });
        }
        const ret_document = await this.guardAwait(() =>
          DocumentService.createDocument(
            userToken,
            workspaceId,
            prevState.project.id,
            {
              ...action,
              title: action.link_title,
              NoteId: noteId,
              file_name,
            } as ICreateDocument
          )
        );
        this.scheduleBackgroundTask(async () => {
          await TimelineService.createTimeline(userToken, workspaceId, {
            type: UserLogType.CREATE_LINK,
            linkName: action.link_title,
            NoteId: noteId,
            UserId: userId,
            userName: userName,
            profile_cid: userProfileCid,
            ProjectId: prevState.project.id,
            projectName: prevState.project.name,
            WorkspaceId: workspaceId,
            file_name,
            DocumentId: ret_document.response.id,
          });
          return false;
        });
        return {
          ...prevState,
          create_link: ret_document.response.id,
          status: NoteStateStatus.SUCCESS,
        };
      }
      /**
       * 선택한 노트들을 다운로드 할 때 사용하는 액션
       */
      case NoteActionKind.TRY_DOWNLOAD_NOTE: {
        if (prevState.status !== NoteStateStatus.SUCCESS_SELECT) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_DOWNLOAD_NOTE,
            msg: "Invalid prev state",
          });
        }
        const { selects, project } = prevState;

        // TODO: remove undefined WorkspaceId (need migration)
        if (project.WorkspaceId === undefined) {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: NoteActionKind.TRY_DOWNLOAD_NOTE,
            msg: "Invalid WorkspaceId",
          });
        }
        if (selects.length < 1) {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_DOWNLOAD_NOTE,
            msg: "Invalid data",
          });
        }
        const workspaceID = project.WorkspaceId;
        const noteGroupId = uuid();
        this.scheduleBackgroundTask(async () => {
          const userLogPromises = selects.map(async (note) => {
            if (selects.length > 1) {
              await TimelineService.createTimeline(userToken, workspaceId, {
                type: UserLogType.DOWNLOAD_CHUNKS_FILES,
                NoteId: note.id,
                UserId: userId,
                userName: userName,
                profile_cid: userProfileCid,
                ProjectId: project.id,
                projectName: project.name,
                WorkspaceId: workspaceId,
                chunk_notes: selects.length,
                noteGroupId,
                file_name: note.file_name,
                blockNumber: note.Auth?.blockNum,
              });
            }

            await TimelineService.createTimeline(userToken, workspaceId, {
              type: UserLogType.DOWNLOAD_FILE,
              NoteId: note.id,
              UserId: userId,
              userName: userName,
              profile_cid: userProfileCid,
              ProjectId: project.id,
              projectName: project.name,
              WorkspaceId: workspaceId,
              file_name: note.file_name,
              blockNumber: note.Auth?.blockNum,
            });
          });
          await Promise.allSettled(userLogPromises);
          return false;
        });

        if (selects.length === 1) {
          const originFile = await this.guardAwait(() =>
            GlobalNoteService.getOriginNote(
              userToken,
              workspaceID,
              project.id,
              {
                noteId: selects[0].id,
              }
            )
          );
          let filePath = IPFSService.getIPFSUrl(
            originFile.response.originalCid
          );
          saveAs(filePath, selects[0].file_name);
          if (
            officeExtensionList.includes(originFile.response.originalExtension)
          ) {
            filePath = "";
          }
          return { ...prevState, filePath };
        }
        const originFileList = await this.guardAwait(() =>
          GlobalNoteService.getOriginNoteList(
            userToken,
            workspaceID,
            project.id,
            {
              noteIds: selects.map((note) => note.id),
            }
          )
        );

        // 시간이 걸리는 작업이므로 Task처리를 필요로 한다.
        const taskResult = await this.guardAwait(() =>
          TaskService.waitforTaskComplete(originFileList.response.id, userToken)
        );
        if (taskResult) {
          const zip = await this.guardAwait(() =>
            IPFSService.getIPFSZipUrl(
              userToken,
              originFileList.pending_tasks.zip,
              originFileList.response.UserId
            )
          );
          const filePath = IPFSService.getIPFSUrl(zip + ".zip");
          return { ...prevState, filePath };
        } else {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: NoteActionKind.TRY_DOWNLOAD_NOTE,
            msg: "zip get failed",
          });
        }
      }
      case NoteActionKind.TRY_REMOVE_NOTES: {
        if (prevState.status !== NoteStateStatus.SUCCESS_SELECT)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_REMOVE_NOTES,
            msg: "Invalid prev state",
          });

        const { project, selects } = prevState;
        const workspaceId = project.WorkspaceId;
        if (workspaceId === undefined) {
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: NoteActionKind.TRY_REMOVE_NOTES,
            msg: "Invalid WorkspaceId",
          });
        }
        // Check user auth. Only Admin and above can perform this action
        const userAuth = project.Users.find((u) => u.id === userId)?.authType;
        if (
          userAuth === undefined ||
          (userAuth !== ProjectAuthType.ADMIN &&
            userAuth !== ProjectAuthType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: NoteActionKind.TRY_REMOVE_NOTES,
            msg: "Only Admin and Owner can delete notes",
          });
        // 여러개 삭제 가능 한 로직
        const deletes = selects.map(async (note) => {
          await this.guardAwait(() =>
            GlobalNoteService.deleteNote(
              userToken,
              workspaceId,
              project.id,
              note.id
            )
          );
          this.scheduleBackgroundTask(async () => {
            const promiseLog = selects.map(async (note) => {
              await TimelineService.createTimeline(userToken, workspaceId, {
                type: UserLogType.DELETE_FILE,
                NoteId: note.id,
                UserId: userId,
                userName: userName,
                profile_cid: userProfileCid,
                ProjectId: project.id,
                projectName: project.name,
                WorkspaceId: workspaceId,
                file_name: note.file_name,
                blockNumber: note.Auth?.blockNum,
              });
            });
            await Promise.allSettled(promiseLog);
            return false;
          });
        });
        await this.guardAwait(() => Promise.all(deletes));

        // 삭제 후 노트 정보 가져오기
        const newNotesRes = await this.guardAwait(() =>
          GlobalNoteService.getNotes(userToken, workspaceId, project.id)
        );
        return {
          ...prevState,
          status: NoteStateStatus.SUCCESS,
          notes: newNotesRes.response.results,
          total: newNotesRes.response.metadata.total,
        };
      }
      case NoteActionKind.TRY_EDIT_HASHTAGS: {
        if (
          prevState.status !== NoteStateStatus.SUCCESS_SELECT &&
          prevState.status !== NoteStateStatus.SUCCESS
        )
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_EDIT_HASHTAGS,
            msg: "Invalid prev state",
          });

        const { note, tags, workspaceId } = action;

        const oldHashtags = note.Tags;
        const tagsToAdd = tags.filter(
          (t) => oldHashtags.find((h) => h.value === t) === undefined
        );
        const tagsToDelete = oldHashtags.filter(
          (h) => tags.includes(h.value) === false
        );

        // delete only tags that are not in new tags
        await this.guardAwait(() =>
          Promise.all(
            tagsToDelete.map(async (hashtag) => {
              await this.guardAwait(() =>
                NoteService.deleteNoteTag(userToken, note.id, {
                  tagId: hashtag.id,
                })
              );
            })
          )
        );

        // create only tags that are not already in the tags
        await this.guardAwait(() =>
          NoteService.createNoteTags(userToken, note.id, { tags: tagsToAdd })
        );

        // history update 해줍니다
        await this.guardAwait(() =>
          Promise.all(
            tagsToAdd.map(async (tag) => {
              await this.guardAwait(() =>
                SearchService.updateSearchWord(userToken, {
                  type: HistoryType.HASHTAG,
                  value: tag,
                })
              );
            })
          )
        );

        // 노트의 해시태그 변경된점 반영하기 위함 ( )  Hashtag of note to reflect changes
        const newNotesRes = await this.guardAwait(() =>
          GlobalNoteService.getNotes(userToken, workspaceId, note.ProjectId)
        );
        // 최근 해시태그 정보 가져오기   Get Recent Hashtag Information
        const historyInfo = await this.guardAwait(() =>
          SearchService.getSearchWord(userToken)
        );
        const recentHistory = alignHistoryWithUpdateTime(
          historyInfo.response.results.filter(
            (h) => h.type === HistoryType.HASHTAG
          )
        ).slice(0, 5);
        const recentHashtag = recentHistory.map((h) => h.value);

        if (prevState.status === NoteStateStatus.SUCCESS_SELECT)
          return {
            ...prevState,
            recentHashtag: recentHashtag,
            notes: newNotesRes.response.results,
            total: newNotesRes.response.metadata.total,
            // TODO: fix this
            selects: newNotesRes.response.results.filter((n) =>
              prevState.selects.find((s) => s.id === n.id)
            ),
          };
        else
          return {
            ...prevState,
            recentHashtag: recentHashtag,
            notes: newNotesRes.response.results,
            total: newNotesRes.response.metadata.total,
          };
      }
      case NoteActionKind.TRY_ALL_SELECT_NOTE: {
        if (
          prevState.status !== NoteStateStatus.SUCCESS_SELECT &&
          prevState.status !== NoteStateStatus.SUCCESS
        )
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_ALL_SELECT_NOTE,
            msg: "prevState is invalid",
          });
        const allNotes = prevState.notes;
        if (prevState.status === NoteStateStatus.SUCCESS)
          return {
            ...prevState,
            status: NoteStateStatus.SUCCESS_SELECT,
            selects: [...allNotes],
            allSelect: true,
          };
        if (prevState.allSelect === undefined || prevState.allSelect === false)
          return { ...prevState, selects: [...allNotes], allSelect: true };
        else return { ...prevState, selects: [], allSelect: false };
      }
      case NoteActionKind.TRY_RESET_NOTE: {
        return { status: NoteStateStatus.INIT };
      }
      case NoteActionKind.TRY_DOWNLOAD_CERTIFICATION: {
        if (prevState.status !== NoteStateStatus.SUCCESS_SELECT)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_DOWNLOAD_CERTIFICATION,
            msg: "Invalid prev state",
          });
        const { project, selects } = prevState;
        const { workspaceId, noteId } = action;
        const note = selects.find((n) => n.id === noteId);
        const notePDF = await this.guardAwait(() =>
          GlobalNoteService.getNoteCerti(userToken, workspaceId, project.id, {
            noteId,
          })
        );

        this.scheduleBackgroundTask(async () => {
          await TimelineService.createTimeline(userToken, workspaceId, {
            type: UserLogType.DOWNLOAD_BLOCK_CHAIN,
            NoteId: noteId,
            UserId: userId,
            userName: userName,
            profile_cid: userProfileCid,
            ProjectId: project.id,
            projectName: project.name,
            WorkspaceId: workspaceId,
            file_name: note?.file_name,
            blockNumber: note?.Auth?.blockNum,
          });
          return false;
        });

        // 시간이 걸리는 작업이므로 Task처리를 필요로 한다.
        const taskResult = await this.guardAwait(() =>
          TaskService.waitforTaskComplete(notePDF.response.id, userToken)
        );
        if (taskResult) {
          const pdf = await this.guardAwait(() =>
            IPFSService.getIPFSPdfUrl(
              userToken,
              notePDF.pending_tasks.pdf,
              notePDF.response.UserId
            )
          );
          const filePath = IPFSService.getIPFSUrl(pdf);
          return { ...prevState, filePath };
        } else {
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: NoteActionKind.TRY_DOWNLOAD_CERTIFICATION,
            msg: "pdf get failed",
          });
        }
      }
      default: {
        throw mkErr({
          kind: InternalErrorKind.Fatal,
          loc: "NoteActionKind::default",
          msg: "Unknown action",
          action,
        });
      }
    }
  }
}

export default connector(NoteContainer);
