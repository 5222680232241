import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";
import { ReduxStateComponent3 } from "@redwit-react-commons/template/ReduxStateComponent3";
import {
  StampAction,
  StampActionKind,
  StampState,
  stampStateMachine,
  StampStateMachineType,
  StampStateStatus,
} from "../../store/reducers/stamp";
import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import { TokenStateStatus } from "../../store/reducers/token";
import Services from "@basalt-react-commons/services";
import { WorkspaceStateStatus } from "../../store/reducers/workspace";
import { StampStatus } from "@basalt-commons/global-api/object/stamp";
import { WorkspaceRoleType } from "@basalt-commons/global-api/object/user_workspace_map";

const { StampService } = Services;
const mapStateToProps = (state: RootState) => {
  return {
    reduxState: state.stamp,
    token: state.token,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type StampContainerProps = PropsFromRedux & {
  stateMachine: StampStateMachineType;
};

class StampContainer extends ReduxStateComponent3<StampContainerProps> {
  static defaultProps = { stateMachine: stampStateMachine };
  constructor(props: StampContainerProps) {
    super(props);
  }

  protected async onAction(
    prevState: StampState,
    action: StampAction
  ): Promise<StampState> {
    const { token, workspace } = this.props;
    if (token.state.status !== TokenStateStatus.SUCCESS)
      throw mkErr({
        kind: InternalErrorKind.Fatal,
        loc: "LogContainer::onAction",
        msg: "not goono login",
      });

    if (workspace.state.status !== WorkspaceStateStatus.SUCCESS)
      return { ...prevState };
    const userToken = token.state.token;
    const workspaceId = workspace.state.selectAuthWorkspace.id;
    const userId = token.state.id;
    const userAuth = workspace.state.selectAuthWorkspace.Users.find(
      (u) => u.id === userId
    );

    switch (action.kind) {
      case StampActionKind.TRY_CREATE: {
        // check the status
        if (prevState.status !== StampStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: StampActionKind.TRY_CREATE,
            msg: "Invalid prevState status",
          });

        if (
          userAuth === undefined ||
          (userAuth.roleType !== WorkspaceRoleType.ADMIN &&
            userAuth.roleType !== WorkspaceRoleType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: StampActionKind.TRY_CREATE,
            msg: "Only Admin and Owner can perform this action",
          });
        const { cid, extension, name, description } = action;
        const currStamp = prevState.currStamp;
        if (currStamp !== undefined) {
          // 기존 stamp deactive 시키기
          await this.guardAwait(() =>
            StampService.updateStamp(userToken, workspaceId, currStamp.id, {
              status: StampStatus.DEACTIVE,
            })
          );
        }

        const stampRet = await this.guardAwait(() =>
          StampService.createStamp(userToken, workspaceId, {
            stamp_cid: cid,
            extension,
            description,
            name,
          })
        );
        const ret = await this.guardAwait(() =>
          StampService.getAllStamp(userToken, workspaceId)
        );
        const newCurr = ret.response.results.find(
          (stamp) =>
            stamp.status === StampStatus.ACTIVE &&
            stamp.id === stampRet.response.id
        );
        return {
          status: StampStateStatus.SUCCESS,
          currStamp: newCurr,
          stamps: ret.response.results,
        };
      }
      case StampActionKind.TRY_EDIT: {
        if (prevState.status !== StampStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: StampActionKind.TRY_EDIT,
            msg: "Invalid prevState status",
          });

        if (
          userAuth === undefined ||
          (userAuth.roleType !== WorkspaceRoleType.ADMIN &&
            userAuth.roleType !== WorkspaceRoleType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: StampActionKind.TRY_EDIT,
            msg: "Only Admin and Owner can perform this action",
          });

        const { name, description, stamp_cid, extension } = action;
        await this.guardAwait(() =>
          StampService.updateStamp(userToken, workspaceId, action.targetId, {
            name,
            description,
            stamp_cid,
            extension,
          })
        );
        const ret = await this.guardAwait(() =>
          StampService.getAllStamp(userToken, workspaceId)
        );
        const findCurr = ret.response.results.find(
          (stamp) => stamp.status === StampStatus.ACTIVE
        );
        return {
          status: StampStateStatus.SUCCESS,
          currStamp: findCurr,
          stamps: ret.response.results,
        };
      }
      case StampActionKind.TRY_GET: {
        if (
          userAuth === undefined ||
          userAuth.roleType === WorkspaceRoleType.GUEST
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: StampActionKind.TRY_GET,
            msg: "Guests cannot perform this action",
          });
        const ret = await this.guardAwait(() =>
          StampService.getAllStamp(userToken, workspaceId)
        );
        const findCurr = ret.response.results.find(
          (stamp) => stamp.status === StampStatus.ACTIVE
        );
        return {
          status: StampStateStatus.SUCCESS,
          stamps: ret.response.results,
          currStamp: findCurr,
        };
      }
      case StampActionKind.TRY_DELETE: {
        if (prevState.status !== StampStateStatus.SUCCESS)
          throw mkErr({
            kind: InternalErrorKind.Fatal,
            loc: StampActionKind.TRY_DELETE,
            msg: "Invalid prevState status",
          });

        if (
          userAuth === undefined ||
          (userAuth.roleType !== WorkspaceRoleType.ADMIN &&
            userAuth.roleType !== WorkspaceRoleType.OWNER)
        )
          throw mkErr({
            kind: InternalErrorKind.Abort,
            loc: StampActionKind.TRY_DELETE,
            msg: "Only Admin and Owner can perform this action",
          });

        //[TODO] I am deactivating the stamp before deleting. Not sure if necessary
        const currStamp = prevState.currStamp;
        if (currStamp !== undefined && currStamp.id === action.targetId) {
          // 기존 stamp deactive 시키기
          await this.guardAwait(() =>
            StampService.updateStamp(userToken, workspaceId, currStamp.id, {
              status: StampStatus.DEACTIVE,
            })
          );
        }

        await this.guardAwait(() =>
          StampService.deleteStamp(userToken, workspaceId, action.targetId)
        );
        const ret = await this.guardAwait(() =>
          StampService.getAllStamp(userToken, workspaceId)
        );
        const findCurr = ret.response.results.find(
          (stamp) => stamp.status === StampStatus.ACTIVE
        );
        return {
          status: StampStateStatus.SUCCESS,
          currStamp: findCurr,
          stamps: ret.response.results,
        };
      }
    }
  }
}

export default connector(StampContainer);
