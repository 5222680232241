export default {
  ko: {
    log_item: {
      upload: "Upload",
      verified: "Verified",
      create_link: "Link",
      download: "Download",
      block: "Block",
      view: "View",
      delete: "Delete",
      create: "Create",
      join: "Join",
      view_folder: {
        view_text_ko1: "님이",
        view_text_ko2: "에 들어갔습니다",
        view_text_en1: "",
        view_text_en2: "",
      },
      view_file: {
        view_text_ko1: "을 열람하였습니다",
        view_text_en1: "님이 ",
        view_text_en2: "",
      },
      file_verified: {
        verified_text_ko1: "님의 파일 ",
        verified_text_ko2: "이 블록체인 인증되었습니다",
      },
      upload_file: {
        uplaod_text_en1: "님이",
        uplaod_text_ko1: "에 파일을 업로드하였습니다",
      },
      upload_chunk: {
        upload_text_ko1: "님이",
        upload_text_ko3: "에 업로드하였습니다",
        upload_text_ko2: "개의 파일을",
        upload_text_en1: "",
        upload_text_en2: "",
        upload_text_en3: "",
      },
      download_file: {
        download_text_ko_1: "님이",
        download_text_en_1: "",
        download_text_en_2: "",
        download_text_ko_2: "의",
        download_text_ko_3: "블록체인 인증서",
        download_text_ko_4: "를 다운받았습니다",
        download_text_ko_5: "을 다운받았습니다",
      },
      download_chunck: {
        dowload_text_en: "님이",
        download_text_en2: "개의 ",
        download_text_en3: "파일을",
        download_text_ko: "에서 다운받았습니다",
      },
      make_link: {
        link_text_en1: "",
        link_text_ko1: "님이",
        link_text_ko2: "의 링크를 생성하였습니다",
      },

      delete_log: {
        delete_text_1: "님이",
        delete_text_2: "를",
        delete_text_3: "삭제하였습니다",
      },

      create_log: {
        create_text_1: "님이",
        create_text_workspace: "워크스페이스를",
        create_text_folder: "폴더를",
        create_text_2: "생성하였습니다",
      },

      join_log: {
        join_text_1: "님이",
        join_text_workspace: "에",
        join_text_folder: "폴더에",
        join_text_2: "",
        join_text_3: "로 들어왔습니다 ",
      },
    },
  },
  en: {
    log_item: {
      upload: "Upload",
      verified: "Verified",
      create_link: "Link",
      download: "Download",
      block: "Block",
      view: "View",
      delete: "Delete",
      create: "Create",
      join: "Join",
      view_folder: {
        view_text_ko1: "",
        view_text_ko2: "",
        view_text_en1: "entered to ",
        view_text_en2: " folder",
      },
      view_file: {
        view_text_ko1: "",
        view_text_en1: "viewed ",
        view_text_en2: "file",
      },
      file_verified: {
        verified_text_ko1: "님의 파일 ",
        verified_text_ko2: "이 블록체인 인증되었습니다",
      },
      upload_file: {
        uplaod_text_en1: "uploaded a file in",
        uplaod_text_ko1: "",
      },
      upload_chunk: {
        upload_text_ko1: "",
        upload_text_ko3: "",
        upload_text_ko2: "",
        upload_text_en1: "uploaded",
        upload_text_en2: " files",
        upload_text_en3: "in",
      },
      download_file: {
        download_text_ko_1: "downloaded",
        download_text_en_1: "the blockchain certificate",
        download_text_en_2: "of",
        download_text_ko_2: "",
        download_text_ko_3: "",
        download_text_ko_4: "",
        download_text_ko_5: "",
      },
      download_chunck: {
        dowload_text_en: "downloaded",
        download_text_en2: "files ",
        download_text_en3: "from",
        download_text_ko: "",
      },

      make_link: {
        link_text_en1: "created a link in",
        link_text_ko1: "",
        link_text_ko2: "",
      },

      delete_log: {
        delete_text_1: " deleted",
        delete_text_2: "",
        delete_text_3: "",
      },

      create_log: {
        create_text_1: "created the ",
        create_text_workspace: "workspace",
        create_text_folder: "folder",
        create_text_2: "",
      },

      join_log: {
        join_text_1: "joined the",
        join_text_workspace: "",
        join_text_folder: "folder",
        join_text_2: "as a ",
        join_text_3: "",
      },
    },
  },
};
