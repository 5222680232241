import {
  Button,
  Divider,
  Menu,
  WithStyles,
  withStyles,
  MenuItem,
} from "@material-ui/core";
import { theme } from "@theme";
import React from "react";
import SpoqaHansTypography from "@SpoqaHansTypography";
import RobotoTypography from "@RobotoTypography";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styles from "./styles/SidebarWorkspaceMenu";
import {
  doWorkspaceActionAsync,
  WorkspaceActionKind,
  WorkspaceErrorType,
  WorkspaceStateStatus,
} from "../store/reducers/workspace";
import { WorkspaceRoleType } from "@basalt-commons/global-api/object/user_workspace_map";
import { TokenStateStatus } from "../store/reducers/token";
import {
  WorkspaceWithPlan,
  WorkspaceWithTeamObject,
} from "@basalt-commons/global-api/object/workspace";
import {
  doSnackbarAction,
  SnackbarActionKind,
  SnackbarType,
} from "../store/reducers/snackbar";
import { ScreenURL } from "../routes/RouteList";
import { InternalErrorKind, isErr } from "@redwit-commons/utils/exception2";
import translate from "../utils/translate";
import copyToClipboard from "copy-to-clipboard";
import config from "../config";
import Flex from "@src/components/pure/utils/Flex";
import { ViewerSubHeaderHeightXL } from "@src/utils/templates/styles/BaseGrid";
import BasaltTypography from "@BasaltTypography";
import PersonIcon from "@material-ui/icons/Person";
import RandomWorkspaceIcon from "@src/components/pure/icons/RandomWorkspaceIcon";

const mapStateToProps = (state: RootState) => {
  return {
    token: state.token,
    workspace: state.workspace,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type SidebarProps = {
  currentWorkspace: WorkspaceWithTeamObject;
  anchorEl: null | HTMLElement;
  setAnchorEl: (value: null | HTMLElement) => void;
};
type SidebarWorkspaceMenuProps = PropsFromRedux &
  RouteComponentProps<Props> &
  SidebarProps &
  WithStyles<typeof styles>;

type SidebarWorkspaceMenuState = {};

class MainSidebar extends React.PureComponent<
  SidebarWorkspaceMenuProps,
  SidebarWorkspaceMenuState
> {
  constructor(props: SidebarWorkspaceMenuProps) {
    super(props);
    this.state = {};
  }

  getWorkspaceData() {
    const { workspace } = this.props;
    let workspace_curr: WorkspaceWithTeamObject | undefined,
      plan: WorkspaceWithPlan | undefined;
    if (workspace.state.status === WorkspaceStateStatus.SUCCESS) {
      workspace_curr = workspace.state.selectAuthWorkspace;
      plan = workspace.state.workspaceWithPlan;
    }
    const userId = this.getUserId();
    const userRole = workspace_curr?.Users.find(
      (user) => user.id === userId
    )?.roleType;
    return {
      workspace_curr,
      workspaces: workspace.state.workspaces,
      plan,
      userRole,
    };
  }

  getUserId() {
    const { token } = this.props;
    return token.state.status === TokenStateStatus.SUCCESS
      ? token.state.id
      : "invalid Id";
  }

  async onClickSignOutWorkspace() {
    try {
      await doWorkspaceActionAsync(this.props.dispatch, {
        kind: WorkspaceActionKind.TRY_SIGN_OUT_WORKSPACE,
      });
      this.props.history.replace(ScreenURL.SERVICE_MAIN);
      return;
    } catch (err) {
      if (isErr(err) && err.kind === InternalErrorKind.Abort) {
        if (err.msg === WorkspaceErrorType.WARN_OWNER_SIGN_OUT) {
          doSnackbarAction(this.props.dispatch, {
            kind: SnackbarActionKind.TRY_OPEN,
            type: SnackbarType.ALERT,
            msg: translate.snackbar.workspace.signout.fail.reject_owner,
          });
          return;
        }
      }
    }
    return;
  }

  getWorkspaceRoleName(role?: WorkspaceRoleType) {
    switch (role) {
      case WorkspaceRoleType.OWNER:
        return translate.main_sidebar.role_type.owner;
      case WorkspaceRoleType.ADMIN:
        return translate.main_sidebar.role_type.admin;
      case WorkspaceRoleType.MEMBER:
        return translate.main_sidebar.role_type.member;
      case WorkspaceRoleType.GUEST:
      default:
        return translate.main_sidebar.role_type.guest;
    }
  }

  renderWorkspaceSubMenuCard(workspace: WorkspaceWithTeamObject, idx: number) {
    const { classes } = this.props;
    if (!workspace) return <></>;

    return (
      <Button
        key={`WorkspaceCardItem-${idx}`}
        onClick={() => {
          this.props.history.push(`/${workspace.name}/main`);
        }}
        className={classes.workspace_list_button}
      >
        <div className={classes.workspaceSubMenuCardInnerDiv}>
          <RandomWorkspaceIcon
            key={workspace.id}
            workspace={workspace}
            index={idx}
          />
          <Flex className={classes.workspace_list_text_box}>
            <RobotoTypography noWrap className={classes.workspace_title}>
              {workspace.name}
            </RobotoTypography>
            <SpoqaHansTypography noWrap className={classes.workspace_url}>
              {config.endpoint.WEB_ENDPOINT.slice(13)}
              {workspace.name}/main
            </SpoqaHansTypography>
          </Flex>
        </div>
      </Button>
    );
  }

  renderWorkspaceInfo = () => {
    const { classes, currentWorkspace } = this.props;
    const { workspace_curr, userRole } = this.getWorkspaceData();
    return (
      <>
        <Flex id="workspace-menu" className={classes.top_container}>
          <Flex id="workspace-menu" className={classes.workspace_name_row}>
            <RandomWorkspaceIcon
              key={currentWorkspace.id}
              workspace={currentWorkspace}
              index={0}
              isIcon24={true}
            />
            <Flex className={classes.workspace_name_box}>
              <SpoqaHansTypography
                fontWeight="medium"
                noWrap
                className={classes.workspace_name}
              >
                {currentWorkspace.name}
              </SpoqaHansTypography>
            </Flex>
          </Flex>
          <Flex id="workspace-menu" className={classes.workspace_url_row}>
            <SpoqaHansTypography
              noWrap
              onClick={() => {
                window.open(
                  `${config.endpoint.WEB_ENDPOINT}${workspace_curr?.name}/main`
                );
                this.props.setAnchorEl(null);
              }}
              className={classes.workspace_url_text}
            >
              {config.endpoint.WEB_ENDPOINT.slice(13)}
              {workspace_curr?.name}/main
            </SpoqaHansTypography>
            <Button
              className={classes.copy_button}
              onClick={() => {
                copyToClipboard(
                  `${config.endpoint.WEB_ENDPOINT}${workspace_curr?.name}/main`
                );
                doSnackbarAction(this.props.dispatch, {
                  kind: SnackbarActionKind.TRY_OPEN,
                  msg: translate.snackbar.workspace.copy_url.success,
                  type: SnackbarType.CONFIRM,
                });
                this.props.setAnchorEl(null);
              }}
            >
              <BasaltTypography
                type="body3_medium"
                className={classes.copy_text}
              >
                COPY
              </BasaltTypography>
            </Button>
          </Flex>
          <Divider
            style={{ margin: "12px 0", backgroundColor: theme.gray_2 }}
          />
          <Flex
            id="workspace-menu"
            className={classes.workspace_type_and_member_row}
          >
            <SpoqaHansTypography
              fontWeight="regular"
              className={classes.workspace_type}
            >
              {workspace_curr?.allow_search === true
                ? translate.main_sidebar.public_workspace
                : translate.main_sidebar.private_workspace}
            </SpoqaHansTypography>
            <SpoqaHansTypography className={classes.line}>
              &nbsp;{"|"}&nbsp;
            </SpoqaHansTypography>
            <PersonIcon className={classes.avatar_icon} />
            <SpoqaHansTypography className={classes.member_number}>
              {workspace_curr?.Users?.length}
            </SpoqaHansTypography>
          </Flex>
          <Flex id="workspace-menu" className={classes.workspace_auth_row}>
            <SpoqaHansTypography
              fontWeight="regular"
              className={classes.my_auth_text}
            >
              {translate.main_sidebar.role}
              {" : "}
            </SpoqaHansTypography>
            <SpoqaHansTypography
              fontWeight="bold"
              className={classes.user_auth_text}
            >
              {this.getWorkspaceRoleName(userRole)}
            </SpoqaHansTypography>
          </Flex>
        </Flex>
        <Divider className={classes.menu_divider} />
      </>
    );
  };

  renderWorkspaceLogout = () => {
    const { classes } = this.props;
    return (
      <>
        <Button
          id="workspace-menu"
          className={classes.signout_workspace_button}
          onClick={async () => {
            await this.onClickSignOutWorkspace();
            this.props.setAnchorEl(null);
          }}
        >
          <SpoqaHansTypography
            className={classes.sign_out_text}
            fontWeight="regular"
          >
            {translate.main_sidebar.sign_out}
          </SpoqaHansTypography>
        </Button>
        <Divider className={classes.menu_divider} />
      </>
    );
  };

  renderWorkspaceList = () => {
    const { classes } = this.props;
    const { workspace_curr, workspaces } = this.getWorkspaceData();
    if (!workspace_curr) return <></>;
    const workspaceSubMenuCardElements = workspaces
      .filter((workspace) => {
        return workspace.id !== workspace_curr.id;
      })
      .map((workspace, idx) => {
        // 현재 선택된 워크스페이스가 0번째 컬러를 가져가기 때문에 1부터 시작해야 함
        return this.renderWorkspaceSubMenuCard(workspace, idx + 1);
      });

    return (
      <>
        <Flex className={classes.workspace_list_container}>
          {workspaceSubMenuCardElements}
        </Flex>
        <Divider className={classes.menu_divider} />
      </>
    );
  };

  renderCreateNewWorkspace = () => {
    const { classes } = this.props;
    return (
      <Button
        id="workspace-menu"
        className={classes.create_workspace_button}
        onClick={() => {
          this.props.history.push(ScreenURL.WORKSPACE_CREATE);
        }}
      >
        <div className={classes.create_workspace_row}>
          <div className={classes.add_new_workspace_icon}>
            <SpoqaHansTypography
              className={classes.add_new_workspace_icon_text}
            >
              +
            </SpoqaHansTypography>
          </div>
          <div className={classes.new_workspace_text_box}>
            <SpoqaHansTypography
              className={classes.new_workspace_text}
              fontWeight="regular"
            >
              {translate.main_sidebar.new_workspace}
            </SpoqaHansTypography>
          </div>
        </div>
      </Button>
    );
  };

  render() {
    const { workspace_curr } = this.getWorkspaceData();
    const { classes } = this.props;
    if (!workspace_curr) return <></>;
    return (
      <>
        <Menu
          id="workspace-menu"
          elevation={0}
          anchorEl={this.props.anchorEl}
          keepMounted
          open={Boolean(this.props.anchorEl)}
          onClose={() => {
            this.props.setAnchorEl(null);
          }}
          className={classes.root}
          PaperProps={{
            style: {
              maxWidth: 260,
              width: "100%",
              borderRadius: 8,
              border: `1px solid ${theme.gray_2}`,
              marginTop: ViewerSubHeaderHeightXL - 30,
              backgroundColor: "white",
              boxShadow: "0 6px 6px 4px rgba(33, 37, 41, 0.02)",
            },
          }}
        >
          <MenuItem
            id="workspace-menu"
            style={{ all: "inherit" }}
            disableRipple
          >
            {this.renderWorkspaceInfo()}
            {this.renderWorkspaceLogout()}
            {this.renderWorkspaceList()}
            {this.renderCreateNewWorkspace()}
          </MenuItem>
        </Menu>
      </>
    );
  }
}

export default connector(withStyles(styles)(withRouter(MainSidebar)));
