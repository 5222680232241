import { StampObject } from "@basalt-commons/global-api/object/stamp";
import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import { ProjectObject } from "@basalt-commons/api/object/project";
import { TextValidateResult } from "../../utils/input-validator";
import { NoteObject } from "@basalt-commons/api/object/note";
import { UserIdentity, UserProfile } from "@basalt-commons/api/object/user";
import { WorkspaceWithTeamObject } from "@basalt-commons/global-api/object/workspace";
import { WorkspaceRoleType } from "@basalt-commons/global-api/object/user_workspace_map";
import { NdaObject } from "@basalt-commons/global-api/object/nda";
import { CardTextInfo } from "../../components/pure/buttons/LandingCardButton";
import { VideoType } from "../../modals/SolutionVideoModal";
import { AccessTime } from "@basalt-commons/api/object/access_time";
import { UserObjectWithWorkspaceRoleType } from "@basalt-commons/global-api/object/user";
import { TeamWithUsersObject } from "@basalt-commons/global-api/object/team";
import { IShareWorkspace } from "@basalt-commons/global-api/request/workspace";
import { ContractObject } from "@basalt-commons/global-api/object/nda_contract";
import { DocumentObject } from "@basalt-commons/global-api/object/document";
import { DocumentWithUserObject } from "@basalt-commons/global-api/object/document";
import { HistoryObject } from "@basalt-commons/api/object/history";
import { CaptureType } from "./persist";
import { CardObjectWithDefault } from "@basalt-commons/global-api/object/card";

export enum ModalStateStatus {
  INIT = "Modal::INIT",
  OPENED = "Modal::OPENED",
}

export enum ModalActionKind {
  TRY_OPEN = "ModalAction::TRY_OPEN",
  TRY_CLOSE = "ModalAction::TRY_CLOSE",
  TRY_OPEN_CAPTURE = "ModalAction::TRY_OPEN_CAPTURE",
  TRY_CLOSE_RESET = "ModalAction::TRY_CLOSE_RESET",
}

// 여기서 modal type 추가
export enum ModalType {
  TERMS = "ModalType::TERMS",
  NORMAL_ADD_NOTE = "ModalType::NORMAL_ADD_NOTE",
  GDT_ADD_NOTE = "ModalType::GDT_ADD_NOTE",
  DELETE = "ModalType::DELETE",
  TEXT_INPUT = "ModalType::TEXT_INPUT",
  USER_SIGN = "ModalType::USER_SIGN",
  NOTE_DETAIL = "ModalType::NOTE_DETAIL",
  EDIT_TAG = "ModalType::EDIT_TAG",
  NEW_NOTE = "ModalType::NEW_NOTE",
  INVITE_WORKSPACE = "ModalType::INVITE_WORKSPACE",
  INVITE_PROJECT = "ModalType::INVITE_PROJECT",
  CREATE_EDIT_TEAM = "ModalType::CREATE_EDIT_TEAM",
  SELECT_NDA = "ModaType::SELECT_NDA",
  PROJECT_CHANGE_OWNER = "ModalType::PROJECT_CHANGE_OWNER",
  SOLUTION = "ModalType::SOLUTION",
  EMAIL_SEND = "ModalType::EMAIL_SEND",
  VIEW_HISTORY = "ModalType::VIEW_HISTORY",
  CONNECTED_FILES = "ModalType::CONNECTED_FILES",
  LINK_CREATE = "ModalType::LINK_CREATE",
  POLICY_INFO = "ModalType::POLICY_INFO",
  COMPANY_STAMP = "ModalType::COMPANY_STAMP",
  WELCOME = "ModalType::WELCOME",
  SET_DEFAULT_CARD = "ModalType::SET_DEFAULT_CARD",
  SEARCH = "ModalType::SEARCH",
  LINK_DONE = "ModalType::LINK_DONE",
}

export const allowDoubleOpenModal = [
  ModalType.DELETE,
  ModalType.TEXT_INPUT,
  ModalType.USER_SIGN,
  ModalType.EDIT_TAG,
  ModalType.INVITE_WORKSPACE,
  ModalType.SELECT_NDA,
  ModalType.COMPANY_STAMP,
];

export type ProjectInfoParams = ProjectObject & { uploadedAt?: string };

export type ModalError = never;

type ModalInfo =
  | {
      readonly type:
        | ModalType.NORMAL_ADD_NOTE
        | ModalType.GDT_ADD_NOTE
        | ModalType.NEW_NOTE
        | ModalType.NOTE_DETAIL
        | ModalType.WELCOME;
    }
  | {
      readonly type: ModalType.POLICY_INFO;
      readonly policy: "folder" | "workspace";
    }
  | {
      readonly type: ModalType.INVITE_PROJECT;
      readonly targetProject: ProjectObject;
      readonly workspace?: WorkspaceWithTeamObject;
      readonly onSubmit?: (() => void) | (() => Promise<void>);
    }
  | {
      readonly type: ModalType.DELETE;
      readonly confirmMsg?: string;
      readonly confirmBtnColor?: string;
      readonly title: string;
      readonly bodyMsg?: string;
      readonly bodySubMsg?: string;
      readonly onDelete: (() => void) | (() => Promise<void>);
      readonly onCancel?: (() => void) | (() => Promise<void>);
    }
  | {
      readonly type: ModalType.TEXT_INPUT;
      readonly multiline?: boolean;
      readonly confirmMsg?: string;
      readonly title?: string;
      readonly bodyMsg?: string;
      readonly placeholder?: string;
      readonly errorValidator?: (text: string) => TextValidateResult; // goono 공통 input validator return 값
      readonly onSubmit:
        | ((text: string) => void)
        | ((text: string) => Promise<void>);
    }
  | {
      readonly type: ModalType.USER_SIGN;
      readonly onSubmit:
        | ((url: string) => void)
        | ((url: string) => Promise<void>);
    }
  | {
      readonly type: ModalType.EDIT_TAG;
      readonly targetNote: NoteObject;
    }
  | {
      readonly type: ModalType.INVITE_WORKSPACE;
      readonly onSubmit: (members: IShareWorkspace[]) => void;
      readonly workspace: WorkspaceWithTeamObject;
    }
  | {
      readonly type: ModalType.CREATE_EDIT_TEAM;
      readonly onSubmit: (
        name: string,
        members: UserObjectWithWorkspaceRoleType[]
      ) => void;
      readonly workspace: WorkspaceWithTeamObject;
      readonly team?: TeamWithUsersObject;
      readonly users: (AccessTime &
        UserIdentity &
        UserProfile & {
          roleType: WorkspaceRoleType;
        })[];
    }
  | {
      readonly type: ModalType.SEARCH;
      readonly onSubmit: (() => void) | (() => Promise<void>);
      readonly workspace?: WorkspaceWithTeamObject;
      readonly targetProject?: ProjectObject;
      readonly workspcae_name?: string;
      readonly searchWord?: string;
      readonly searchHistories?: HistoryObject;
    }
  | {
      readonly type: ModalType.SELECT_NDA;
      readonly workspace_name: string;
      readonly onSubmit: (nda: NdaObject) => void;
      readonly projectName?: string;
      /**
       * @deprecated 곧 사용하지 않을 예정입니다.
       */
      readonly goToLink?: string;
    }
  | {
      readonly type: ModalType.TERMS;
      readonly viewType: "nda" | "term" | "privacy";
    }
  | {
      readonly type: ModalType.PROJECT_CHANGE_OWNER;
      readonly targetProject: ProjectObject;
      readonly currOwnerId?: string;
      readonly onSubmit: (newOwnerId: string) => void;
    }
  | {
      readonly type: ModalType.SOLUTION;
      readonly videoType: VideoType;
      readonly text: CardTextInfo;
    }
  | {
      readonly type: ModalType.EMAIL_SEND;
      readonly onSubmit: (email: string[]) => Promise<void>;
      readonly workspace?: WorkspaceWithTeamObject;
    }
  | {
      readonly type: ModalType.VIEW_HISTORY;
      readonly onDownload: (contract: ContractObject) => Promise<void>;
      readonly stampName?: string;
      readonly docContracts: {
        doc: DocumentWithUserObject | undefined;
        contract: ContractObject;
      }[];
      readonly timezone: string;
      readonly format: string;
    }
  | {
      readonly type: ModalType.CONNECTED_FILES;
      readonly docs: DocumentObject[];
      readonly docName: string;
      readonly contracts: ContractObject[];
    }
  | {
      readonly type: ModalType.LINK_CREATE;
      readonly targetNote: NoteObject;
    }
  | {
      readonly type: ModalType.COMPANY_STAMP;
      readonly onSubmit: (stamp: StampObject) => void;
    }
  | {
      readonly type: ModalType.LINK_DONE;
      readonly linkId: string;
    }
  | {
      readonly type: ModalType.SET_DEFAULT_CARD;
      readonly card: CardObjectWithDefault;
      readonly onConfirm: (cardId: string) => Promise<void>;
    };

export type ModalState =
  | {
      readonly status: ModalStateStatus.INIT;
    }
  | ({
      readonly status: ModalStateStatus.OPENED;
      readonly other?: ModalInfo;
    } & ModalInfo);

export type ModalAction =
  | ({
      readonly kind: ModalActionKind.TRY_OPEN;
    } & ModalInfo)
  | ({
      readonly kind: ModalActionKind.TRY_OPEN_CAPTURE;
      readonly capture?: CaptureType;
    } & ModalInfo)
  | {
      readonly kind: ModalActionKind.TRY_CLOSE;
    }
  | {
      readonly kind: ModalActionKind.TRY_CLOSE_RESET;
    };
const smid = "MODAL_STATE_MACHINE3";
export type ModalStateMachineType = StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>;
export const modalStateMachine: ModalStateMachineType = new StateMachine3<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(smid, { status: ModalStateStatus.INIT }, [
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.OPENED,
    ModalActionKind.TRY_OPEN
  ),
  transition(
    ModalStateStatus.OPENED,
    ModalStateStatus.OPENED,
    ModalActionKind.TRY_OPEN
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.OPENED,
    ModalActionKind.TRY_OPEN_CAPTURE
  ),
  transition(
    ModalStateStatus.OPENED,
    ModalStateStatus.OPENED,
    ModalActionKind.TRY_OPEN_CAPTURE
  ),
  transition(
    ModalStateStatus.OPENED,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE
  ),
  transition(
    ModalStateStatus.OPENED,
    ModalStateStatus.OPENED,
    ModalActionKind.TRY_CLOSE
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE
  ),
  transition(
    ModalStateStatus.OPENED,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE_RESET
  ),
  transition(
    ModalStateStatus.OPENED,
    ModalStateStatus.OPENED,
    ModalActionKind.TRY_CLOSE_RESET
  ),
  transition(
    ModalStateStatus.INIT,
    ModalStateStatus.INIT,
    ModalActionKind.TRY_CLOSE_RESET
  ),
]);

/* */
export type DispatchModalAction = Dispatch<
  StateMachineAction<
    ModalStateStatus,
    ModalState,
    ModalActionKind,
    ModalAction,
    ModalError
  >
>;
export default mkReducer<
  ModalStateStatus,
  ModalState,
  ModalActionKind,
  ModalAction,
  ModalError
>(modalStateMachine);
export const doModalAction = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction,
  onResolve: () => void = () => {},
  onReject: (err: ModalError | InternalError) => void = () => {}
) => {
  dispatch(modalStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doModalActionAsync = (
  dispatch: DispatchModalAction,
  nextAction: ModalAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(modalStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetModal = (dispatch: DispatchModalAction) => {
  dispatch(modalStateMachine.newResetAction());
};
