import T from "@redwit-commons/utils/typecheck";
import { UserLogObject, UserLogObjectSchema } from "../object/user_log";
import {
  GoonoArrayResponse,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "./common";

export type OCreateLog = GoonoResponse<string>;
export type OGetUserLogs = GoonoArrayResponse<UserLogObject>;
export type OGetNoteExtensions = GoonoArrayResponse<string>;

export const OCreateLogSchema = mkResponseSchema(T.string());
export const OGetUserLogsSchema = mkArrayResultsSchema(UserLogObjectSchema);
export const OGetNoteExtensionsSchema = mkArrayResultsSchema(T.string());

export const validateOCreateLog = T.mkValidator<OCreateLog>(OCreateLogSchema);
export const validateOGetUserLogs =
  T.mkValidator<OGetUserLogs>(OGetUserLogsSchema);
export const validateOGetNoteExtensions = T.mkValidator<OGetNoteExtensions>(
  OGetNoteExtensionsSchema
);
