import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import SpoqaHansTypography from "../../components/pure/typo/SpoqaHansTypography";
import { theme } from "@theme";
import DesktopImg from "@ui/basalt/images/note/img-desktop@3x.png";

const useStyles = makeStyles((MuiTheme) => ({
  mobile: {
    margin: "auto 0",
    paddingBottom: 68,
    [MuiTheme.breakpoints.up(1024)]: {
      display: "none",
    },
  },
  mobile_img: {
    marginBottom: 24,
    width: 168,

    "& img": {
      maxWidth: "100%",
    },
  },
  mobile_bold_text: {
    ...theme.font20,
    lineHeight: "28px",
    marginBottom: 8,
  },
  mobile_text: {
    ...theme.font16,
    color: theme.gray_7_text,
  },
}));

// type ScreenMobileViewProps = {
// };

const ScreenMobileView = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      className={classes.mobile}
    >
      <div className={classes.mobile_img}>
        <img src={DesktopImg} alt="pc에서 확인할 수 있습니다" />
      </div>
      <SpoqaHansTypography
        fontWeight="bold"
        className={classes.mobile_bold_text}
      >
        PC에서 확인할 수 있습니다!
      </SpoqaHansTypography>
      <SpoqaHansTypography className={classes.mobile_text}>
        현재 기기를 지원하지 않는 기능입니다.
      </SpoqaHansTypography>
      <SpoqaHansTypography className={classes.mobile_text}>
        PC에서 확인해보세요!
      </SpoqaHansTypography>
    </Grid>
  );
};

export default ScreenMobileView;
