import React from "react";
import { Button, Grid } from "@material-ui/core";
import { ReactComponent as ForbiddenIcon } from "@ui/basalt/images/error/404.svg";
import { ReactComponent as ErrorIcon } from "@ui/basalt/images/error/500.svg";
import SpoqaHansTypography from "../typo/SpoqaHansTypography";

type FailViewProps = {
  width?: number | string;
  height?: number | string;
  onPressPrev?: () => void;
  onPressHome?: () => void;
  errorCode?: number;
};

class FailView extends React.PureComponent<FailViewProps> {
  static defaultProps = {};
  constructor(props: FailViewProps) {
    super(props);
  }

  renderBody() {
    const { errorCode } = this.props;
    if (errorCode === 404) {
      return (
        <>
          <ForbiddenIcon />
          <SpoqaHansTypography
            fontWeight="bold"
            style={{
              fontSize: 24,
              letterSpacing: -1.4,
              color: "#212529",
              marginTop: 16,
              marginBottom: 8,
            }}
          >
            {"The requested page cannot be found"}
          </SpoqaHansTypography>
          <SpoqaHansTypography
            style={{ fontSize: 16, letterSpacing: -0.6, color: "#495057" }}
          >
            {
              "The page cannot be found since the address is changed or deleted. "
            }
          </SpoqaHansTypography>
          <SpoqaHansTypography
            style={{ fontSize: 16, letterSpacing: -0.6, color: "#495057" }}
          >
            {"Please check whether the entered address is correct."}
          </SpoqaHansTypography>
        </>
      );
    } else
      return (
        <>
          <ErrorIcon />
          <SpoqaHansTypography
            fontWeight="bold"
            style={{
              fontSize: 24,
              letterSpacing: -1.4,
              color: "#212529",
              marginTop: 16,
              marginBottom: 8,
            }}
          >
            {"You cannot access to page"}
          </SpoqaHansTypography>
          <SpoqaHansTypography
            style={{ fontSize: 16, letterSpacing: -0.6, color: "#495057" }}
          >
            {"The connection is unstable or inaccessible."}
          </SpoqaHansTypography>
          <SpoqaHansTypography
            style={{ fontSize: 16, letterSpacing: -0.6, color: "#495057" }}
          >
            {"Please check again."}
          </SpoqaHansTypography>
        </>
      );
  }

  render() {
    const { onPressHome, onPressPrev } = this.props;
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
        item
        style={{ width: this.props.width, height: this.props.height }}
      >
        {this.renderBody()}
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="row"
          style={{ marginTop: 40, marginBottom: 40 }}
        >
          {onPressPrev && (
            <Button
              onClick={() => {
                onPressPrev();
              }}
              style={{
                width: 130,
                height: 56,
                border: "1px solid #5c7cfa",
                borderRadius: 10,
                marginRight: 16,
              }}
            >
              <SpoqaHansTypography
                fontWeight="medium"
                style={{ color: "#5c7cfa", fontSize: 18, letterSpacing: -1 }}
              >
                {"Previous page"}
              </SpoqaHansTypography>
            </Button>
          )}
          {onPressHome && (
            <Button
              onClick={() => {
                onPressHome();
              }}
              style={{
                width: 130,
                height: 56,
                backgroundColor: "#5c7cfa",
                border: "1px solid #5c7cfa",
                borderRadius: 10,
                marginRight: 16,
              }}
            >
              <SpoqaHansTypography
                fontWeight="medium"
                style={{ color: "#ffffff", fontSize: 18, letterSpacing: -1 }}
              >
                {"Go Home"}
              </SpoqaHansTypography>
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default FailView;
