import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "../../api/object/access_time";
import { UserObject, UserObjectSchema } from "../../api/object/user";
import { NdaObject, NdaObjectSchema } from "./nda";

export enum StampStatus {
  ACTIVE = "Active",
  DEACTIVE = "Deactive",
}

export type StampPureObjectCore = {
  readonly id: string;
  readonly stamp_cid?: string;
  readonly name?: string;
  readonly description?: string;
  readonly status: StampStatus;
  readonly extension?: string;
  readonly WorkspaceId: string;
  readonly UserId: string;
};

export type StampObjectCore = {
  readonly User: UserObject;
} & StampPureObjectCore;

export type StampWithNDACore = {
  readonly Ndas: Array<NdaObject>;
} & StampObjectCore;

export type StampPureObject = StampPureObjectCore & AccessTime;
export type StampObject = StampObjectCore & AccessTime;
export type StampWithNDA = StampWithNDACore & AccessTime;

export const StampPureObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("stamp_cid", T.string(), false)
  .addField("name", T.string(), false)
  .addField("description", T.string(), false)
  .addField("status", T.string())
  .addField("extension", T.string(), false)
  .addField("WorkspaceId", T.string())
  .addField("UserId", T.string());

export const StampObjectSchema = StampPureObjectSchema.clone().addField(
  "User",
  UserObjectSchema.clone()
);

export const StampWithNDASchema = StampObjectSchema.clone().addField(
  "Ndas",
  T.array(NdaObjectSchema.clone())
);

export const extractStampPureObject = T.mkObjectExtractor<StampPureObject>(
  StampPureObjectSchema
);
export const extractStampObject =
  T.mkObjectExtractor<StampObject>(StampObjectSchema);
export const validateStampObject =
  T.mkValidator<StampObject>(StampObjectSchema);
export const extractStampWithNDA =
  T.mkObjectExtractor<StampWithNDA>(StampWithNDASchema);
export const validateStampWithNDA =
  T.mkValidator<StampWithNDA>(StampWithNDASchema);
