import { TaskObject, TaskObjectSchema } from "../../api/object/task";
import {
  GoonoArrayResponse,
  GoonoPendingTasks,
  GoonoResponse,
  mkArrayResultsSchema,
  mkResponseSchema,
} from "../../api/response/common";
import T from "@redwit-commons/utils/typecheck";
import {
  RuleObjectWithContract,
  RuleObjectWithContractSchema,
} from "../object/rule";
import {
  RuleContractPureObject,
  RuleContractPureObjectSchema,
} from "../object/rule_contract";
import {
  WorkspaceWithRule,
  WorkspaceWithRuleContract,
  WorkspaceWithRuleContractSchema,
  WorkspaceWithRuleSchema,
} from "../object/workspace";

export type OCreateRuleContract = GoonoResponse<RuleContractPureObject> &
  GoonoPendingTasks<{ contract_task: string }>;
export const OCreateRuleContractSchema = mkResponseSchema(
  RuleContractPureObjectSchema,
  T.object().addField("contract_task", T.string())
);
export const validateOCreateRuleContract = T.mkValidator<OCreateRuleContract>(
  OCreateRuleContractSchema
);

export type OGetRuleWithContract = GoonoResponse<RuleObjectWithContract>;
export const OGetRuleWithContractSchema = mkResponseSchema(
  RuleObjectWithContractSchema
);
export const validateOGetRuleWithContract = T.mkValidator<OGetRuleWithContract>(
  OGetRuleWithContractSchema
);

export type OGetWorkspacesRuleList = GoonoArrayResponse<WorkspaceWithRule>;
export const OGetWorkspacesRuleListSchema = mkArrayResultsSchema(
  WorkspaceWithRuleSchema
);
export const validateOGetWorkspacesRuleList =
  T.mkValidator<OGetWorkspacesRuleList>(OGetWorkspacesRuleListSchema);

export type OGetWorkspacesRuleContractList =
  GoonoArrayResponse<WorkspaceWithRuleContract>;
export const OGetWorkspacesRuleContractListSchema = mkArrayResultsSchema(
  WorkspaceWithRuleContractSchema
);
export const validateOGetWorkspacesRuleContractList =
  T.mkValidator<OGetWorkspacesRuleContractList>(
    OGetWorkspacesRuleContractListSchema
  );

export type OGetRuleContractList = GoonoArrayResponse<RuleObjectWithContract>;
export const OGetRuleContractListSchema = mkArrayResultsSchema(
  RuleObjectWithContractSchema
);
export const validateOGetRuleContractList = T.mkValidator<OGetRuleContractList>(
  OGetRuleContractListSchema
);

export type ONeedSignRequiredRule = GoonoResponse<{
  needSignRequiredRule: string[];
}>;
export const ONeedSignRequiredRuleSchema = mkResponseSchema(
  T.object().addField("needSignRequiredRule", T.array(T.string()))
);
export const validateONeedSignRequiredRule =
  T.mkValidator<ONeedSignRequiredRule>(ONeedSignRequiredRuleSchema);

export type OGetRuleContractAllPDF = GoonoResponse<TaskObject> &
  GoonoPendingTasks<{ pdf: string }>;
export const OGetRuleContractAllPDFSchema = mkResponseSchema(
  TaskObjectSchema,
  T.object().addField("pdf", T.string())
);
export const validateOGetRuleContractAllPDF =
  T.mkValidator<OGetRuleContractAllPDF>(OGetRuleContractAllPDFSchema);

export type OGetMyRuleContractAll = GoonoResponse<TaskObject> &
  GoonoPendingTasks<{ zip: string }>;
export const OGetMyRuleContractAllSchema = mkResponseSchema(
  TaskObjectSchema,
  T.object().addField("zip", T.string())
);
export const validateOGetMyRuleContractAll =
  T.mkValidator<OGetMyRuleContractAll>(OGetMyRuleContractAllSchema);
