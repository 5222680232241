import T from "@redwit-commons/utils/typecheck";

export interface WorkspaceCardMapObjectCore {
  readonly WorkspaceId: string;
  readonly CardId: string;
  readonly default_card: boolean;
}

export const WorkspaceCardMapObjectSchema = T.object()
  .addField("WorkspaceId", T.string())
  .addField("CardId", T.string())
  .addField("default_card", T.boolean());

export const extractWorkspaceCardMapObjet =
  T.mkObjectExtractor<WorkspaceCardMapObjectCore>(WorkspaceCardMapObjectSchema);
