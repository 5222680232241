import T from "@redwit-commons/utils/typecheck";
import { AccessTime, addAccessTime } from "./access_time";
import { ProjectAuthType, ProjectAuthTypeValues } from "./user_project_map";
import { LicenseRoleTypesValues, LicenseRoleTypes } from "./user_license_map";

export type SNSPlatform = "kakao" | "google" | "apple" | "test" | "email";

// 사용자 구별에 필요한 필수 정보들
export type UserIdentity = {
  readonly email: string;
  readonly id: string;
  readonly apple_sub?: string;
  readonly platform: SNSPlatform;
};

export const UserIdentitySchema = T.object()
  .addField("email", T.string())
  .addField("id", T.string())
  .addField(
    "platform",
    T.string().withEnum(["kakao", "google", "apple", "test", "email"])
  )
  .addField("apple_sub", T.string(), false);

// 사용자 기타 정보들
export type UserProfile = {
  /**
   * @deprecated
   * 이제 없어질 필드입니다.
   */
  profile_version?: "0.0.1";
  name: string;
  gender: "male" | "female" | "not-to-disclose";
  school: string;
  department: string;
  /**
   * @deprecated
   * 이제 없어질 필드입니다.
   */
  profile_img?: string | undefined;
  /**
   * @deprecated
   * 이제 없어질 필드입니다.
   */
  user_sign?: string | undefined;
  marketing_term: boolean | undefined;

  profile_cid: string;
  profile_extension: string;
  user_sign_cid: string | undefined;
  user_sign_extension: string | undefined;

  time_zone?: string | undefined;
};

export const addProfileFields = (from: T): T => {
  return from
    .addField("profile_version", T.string(), false)
    .addField(
      "gender",
      T.string().withEnum(["male", "female", "not-to-disclose"])
    )
    .addField("school", T.string())
    .addField("name", T.string())
    .addField("department", T.string())
    .addField("profile_img", T.string(), false)
    .addField("user_sign", T.string(), false)
    .addField("marketing_term", T.boolean(), false)
    .addField("profile_cid", T.string())
    .addField("profile_extension", T.string())
    .addField("user_sign_cid", T.string(), false)
    .addField("user_sign_extension", T.string(), false)
    .addField("time_zone", T.string(), false);
};

export const UserProfileSchema = addProfileFields(T.object());

// 프로파일 정보를 포함한 유저 정보 전부
export type UserCore = UserIdentity & UserProfile;
export const UserCoreSchema = addProfileFields(UserIdentitySchema.clone());

// 데이터베이스에서 가져오면 accesstime 도 적혀있음
export type UserObject = AccessTime & UserCore;
export const UserObjectSchema = addAccessTime(UserCoreSchema.clone());

export type UserObjectWithAuthType = UserObject & {
  authType: ProjectAuthType;
  pinned: string;
};
export type UserObjectWithLicenseRole = UserObject & { role: LicenseRoleTypes };

export const UserObjectWithAuthTypeSchema = UserObjectSchema.clone()
  .addField("authType", T.string().withEnum(ProjectAuthTypeValues))
  .addField("pinned", T.string());

export const UserObjectWithLicenseRoleSchema =
  UserObjectSchema.clone().addField(
    "role",
    T.string().withEnum(LicenseRoleTypesValues)
  );

export const validateUserCore = T.mkValidator<UserCore>(UserCoreSchema);
export const validateUserObject = T.mkValidator<UserObject>(UserObjectSchema);
export const validateUserObjectWithAuthType =
  T.mkValidator<UserObjectWithAuthType>(UserObjectWithAuthTypeSchema);
export const validateUserObjectWithLicenseRole =
  T.mkValidator<UserObjectWithLicenseRole>(UserObjectWithLicenseRoleSchema);

export const extractUserObject =
  T.mkObjectExtractor<UserObject>(UserObjectSchema);

export const validateUserProfile =
  T.mkValidator<UserProfile>(UserProfileSchema);
export const extractUserProfile =
  T.mkObjectExtractor<UserProfile>(UserProfileSchema);
