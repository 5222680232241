import { validate as validateEmail } from "email-validator";
import translate from "./translate";

export type TextValidateResult = {
  avaliable: boolean;
  errorMsg?: string;
  limitLetter?: boolean;
};
export enum EmailValidateErrorType {
  NOT_FORMAT = "NOT_FORMAT",
  NOT_ENTER = "NOT_ENTER",
}
export function emailValidator(text: string): TextValidateResult {
  if (text.length === 0)
    return {
      avaliable: false,
      errorMsg: translate.error.email_validator.not_enter,
    };
  const avaliable = validateEmail(text);
  if (avaliable) return { avaliable };
  return { avaliable, errorMsg: translate.error.email_validator.not_format };
}

/**
 * Check for valid password recognition
 * @override Include special characters
 * @param text
 * @return false if valid text, true if invalid
 */
export function checkValidatePw(text: string) {
  let tmp = text;
  if (/[A-Za-z]+/.test(text)) {
    tmp = tmp.replace(/[A-Za-z]+/g, "");
    if (/[!@#$%^&*()_+~"<>?{}\[\]]+/g.test(tmp)) {
      tmp = tmp.replace(/[!@#$%^&*()_+~"<>?{}\[\]]+/g, "");
      if (/\d+/.test(tmp)) {
        return tmp.replace(/\d+/g, "").length < 1;
      }
    }
  }
  return false;
}
/**
 * 특수문자 테스트
 * !@#$%^&*()_+~"<>?{}[] 를 제외하고는 모두 불가능합니다
 * @param text string
 */
export function validatePWSpecialChar(text: string) {
  return text.replace(/^[A-Za-z0-9!@#$%^&*()_+~"<>?{}\[\]]+/g, "").length < 1;
}
/**
 * Check password length
 * @param text
 * @return false if valid text, true if invalid
 */
export function checkLengthPW(text: string) {
  return text.length >= 8;
}

/**
 * Password Guide
  1. Must be at least 8 characters in length
  2. Must be a combination of English + numbers + special characters (must be a combination)
  3. Space not allowed
 * @param text string
 */
export function pwValidator(text: string): TextValidateResult {
  if (!checkLengthPW(text))
    return { avaliable: false, errorMsg: PWValidateErrorType.MORE_8_LETTER };
  if (!validatePWSpecialChar(text))
    return { avaliable: false, errorMsg: PWValidateErrorType.INVALID_SPECIAL };
  if (!checkValidatePw(text))
    return { avaliable: false, errorMsg: PWValidateErrorType.FORMAT };
  return { avaliable: true };
}

export enum PWValidateErrorType {
  MORE_8_LETTER = "MORE_8_LETTER",
  FORMAT = "FORMAT",
  INVALID_SPECIAL = "INVALID_SPECIAL",
}

/**
 * 특수문자 테스트
 * ~_-/ 를 제외하고는 모두 불가능합니다
 * @param text string
 * @retrun true : 가능한 문자 / false : 불가능한 문자
 */
export function validateSpecialChar(text?: string) {
  if (text === undefined) return true;
  return text.replace(/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\/~_-]+/g, "").length < 1;
}

/**
 * 특수문자 테스트 (자모음 가능)
 * ~_-/ 및 한글의 자음과 모음을 제외하고는 모두 불가능합니다
 * @param text string
 * @retrun true : 가능한 문자 / false : 불가능한 문자
 */
export function validateSpecialCharKor(text?: string) {
  if (text === undefined) return true;
  return (
    text.replace(/^[\u1100-\u11ff\u3130-\u318f가-힣a-zA-Z0-9\/~_-]+/g, "")
      .length < 1
  );
}

/**
 * 모든 특수문자 불가능합니다
 * @param text string
 */
export function validateAllSpecialChar(text?: string) {
  if (text === undefined) return true;
  return text.replace(/^[가-힣a-zA-Z0-9]+/g, "").length < 1;
}

/**
 * 모든 특수문자 불가능합니다
 * @param text string
 */
export function validateSpecialCharExceptSpace(text?: string) {
  if (text === undefined) return true;
  return text.replace(/^[가-힣a-zA-Z0-9\s]+/g, "").length < 1;
}
/**
 * 이모지 검사
 * 모든 이모지 불가능합니다
 */
export function validateEmojiChar(text: string) {
  return !/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g.test(
    text
  );
}
/**
 * 영문 소문자/대문자/한글 모두 포함함.
 * 자음, 모음만 있는 경우 제외
 * 숫자를 포함함.
 * @param text
 * @returns
 */
export function checkUserName(text: string) {
  if (/^[가-힣a-zA-Z0-9]+/g.test(text)) {
    return true;
  }
  return false;
}
/**
 * 영문 소문자/대문자/한글 모두 포함함.
 * 자음, 모음만 있는 경우 제외
 * 숫자를 포함함.
 * @param text
 * @returns
 */
export function checkInstitution(text: string) {
  if (/^[가-힣a-zA-Z0-9]+/g.test(text)) {
    return true;
  }
  return false;
}
/**
 * 회원 가입 입력 시 유효한 이름 인지 검사
 * @param text
 * @return 만약 유효한 텍스트 이면 false, 유효하지 않은 경우 true
 */
export function checkMixedName(text: string) {
  //한글 또는 영문 사용하기(혼용X)
  if (/^[가-힣]{2,4}|[a-zA-Z]{2,10}$/.test(text)) {
    if (/^[a-zA-Z]{2,10}|[가-힣]{2,4}$/.test(text)) {
      return false;
    }
  }
  return true;
}

export enum NameValidateErrorType {
  OVERFLOW_LETTER = "OVERFLOW_LETTER",
  NOT_FORMAT = "NOT_FORMAT",
  SPECIAL_CHAR = "SPECIAL_CHAR",
  EMOJI = "EMOJI",
}

/**
* 이름 입력 _ 가이드 
1. 한영 모두 가능하나  동시 입력 불가능 ex)박sunny 
2. 한글의 경우
글자로 완성되지 않은 자음, 모음만 기재된 경우/ 연속 사용을 제한함 
ex) ㄱ, ㄴ, ㅣ, ㅏ, ㅏㅏ,ㄴㄴ,ㄷㄹ,ㄱㅔ,ㄴ ㅏ 등
3, 영문의 경우 대소문자의 사용이 자유로움. 동시 사용가능 
ex) SUNNY, Sunny, SuNNy 
4. 공백 입력을 제한
5. 특수문자 사용 안됨 (특수문자 입력해도 인풋에 나타나지않음)
* @param text string
*/
export function nameValidator(text: string): TextValidateResult {
  if (text.length > 30)
    return {
      avaliable: false,
      errorMsg: NameValidateErrorType.OVERFLOW_LETTER,
    };
  if (!checkUserName(text))
    return { avaliable: false, errorMsg: NameValidateErrorType.NOT_FORMAT };
  if (!validateAllSpecialChar(text))
    return { avaliable: false, errorMsg: NameValidateErrorType.SPECIAL_CHAR };
  if (!validateEmojiChar(text))
    return { avaliable: false, errorMsg: NameValidateErrorType.EMOJI };
  return { avaliable: true };
}

export function checkNameError(error?: string) {
  if (error === undefined) return;
  switch (error) {
    case NameValidateErrorType.NOT_FORMAT:
      return translate.error.name_validator.not_format;
    case NameValidateErrorType.OVERFLOW_LETTER:
      return translate.error.overflow_error(30);
    case NameValidateErrorType.SPECIAL_CHAR:
      return translate.error.name_validator.special_char;
    case NameValidateErrorType.EMOJI:
      return translate.error.name_validator.emoji;
    default:
      return error;
  }
}

/**
* Institution 입력 _ 가이드 
1. 한영 모두 가능하나  동시 입력 불가능 ex)박sunny 
2. 한글의 경우
글자로 완성되지 않은 자음, 모음만 기재된 경우/ 연속 사용을 제한함 
ex) ㄱ, ㄴ, ㅣ, ㅏ, ㅏㅏ,ㄴㄴ,ㄷㄹ,ㄱㅔ,ㄴ ㅏ 등
3, 영문의 경우 대소문자의 사용이 자유로움. 동시 사용가능 
ex) SUNNY, Sunny, SuNNy 
4. 특수문자 사용 안됨 (특수문자 입력해도 인풋에 나타나지않음)
* @param text string
*/
export function institutionValidator(text: string): TextValidateResult {
  if (text.length > 50)
    return {
      avaliable: false,
      errorMsg: NameValidateErrorType.OVERFLOW_LETTER,
    };
  if (!checkInstitution(text))
    return { avaliable: false, errorMsg: NameValidateErrorType.NOT_FORMAT };
  if (!validateSpecialCharExceptSpace(text))
    return { avaliable: false, errorMsg: NameValidateErrorType.SPECIAL_CHAR };
  if (!validateEmojiChar(text))
    return { avaliable: false, errorMsg: NameValidateErrorType.EMOJI };
  return { avaliable: true };
}

export function checkInstitutionError(error?: string) {
  if (error === undefined) return;
  switch (error) {
    case NameValidateErrorType.NOT_FORMAT:
      return translate.error.inst_validator.not_format;
    case NameValidateErrorType.OVERFLOW_LETTER:
      return translate.error.overflow_error(50);
    case NameValidateErrorType.SPECIAL_CHAR:
      return translate.error.inst_validator.special_char;
    case NameValidateErrorType.EMOJI:
      return translate.error.inst_validator.emoji;
    default:
      return error;
  }
}

export function workspaceNameValidator(text: string): TextValidateResult {
  if (text.length < 1)
    return {
      avaliable: false,
      errorMsg: translate.error.workspace_name_validator.more_than_one,
    };
  if (text.length > 15)
    return {
      avaliable: false,
      errorMsg: translate.error.workspace_name_validator.more_15_letter,
    };
  if (validateEmojiChar(text) !== true) {
    return {
      avaliable: false,
      errorMsg: translate.error.workspace_name_validator.no_emoji,
    };
  }
  if (validateAllSpecialChar(text) === false)
    return {
      avaliable: false,
      errorMsg: translate.error.workspace_name_validator.no_special_char,
    };
  else return { avaliable: true };
}

export function projectNameValidator(text: string): TextValidateResult {
  if (text.length < 2)
    return {
      avaliable: false,
      errorMsg: translate.error.folder_name_validator.more_than_two,
    };
  if (validateEmojiChar(text) !== true) {
    return { avaliable: false, errorMsg: translate.error.emoji_error };
  }
  if (validateSpecialCharKor(text) === false)
    return {
      avaliable: false,
      errorMsg: translate.error.special_character_error,
    };
  if (text.length > 50)
    return {
      avaliable: false,
      errorMsg: translate.error.overflow_error(50),
      limitLetter: true,
    };
  else return { avaliable: true };
}

export function messageTextValidator(text: string): TextValidateResult {
  if (text.length === 150)
    return {
      avaliable: false,
      errorMsg: translate.error.overflow_error(150),
    };
  return textValidator(text.replace(/\s/g, ""));
}

export function commentTextValidator(text: string): TextValidateResult {
  if (text.length > 1000) {
    return {
      avaliable: false,
      errorMsg: translate.error.overflow_error(1000),
    };
  }
  return { avaliable: true };
}
export function ndaDescriptionValidator(text: string): TextValidateResult {
  if (text.length > 100)
    return {
      avaliable: false,
      errorMsg: translate.error.overflow_error(100),
      limitLetter: true,
    };
  return textValidator(text.replace(/\s/g, ""));
}
/**
 * Goono 통일된 text validator
 * @param text string
 */
export function textValidator(text: string): TextValidateResult {
  if (!validateEmojiChar(text))
    return { avaliable: false, errorMsg: translate.error.emoji_error };
  if (!validateSpecialChar(text))
    return {
      avaliable: false,
      errorMsg: translate.error.special_character_error,
    };
  return { avaliable: true };
}

export const makeWorkspaceURL = (name: string) => {
  return name.replaceAll(" ", "_");
};

export const getWorkspaceNameFromURL = (url: string) => {
  return url.replaceAll("_", " ");
};
