import React from "react";
import { TypographyProps, Typography } from "@material-ui/core";
import { theme } from "@theme";
import { BaseMobileGuide } from "../../../utils/templates/styles/BaseGrid";

type BasaltTypographyProps = {
  type:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "h6_regular"
    | "subtitle1"
    | "subtitle1_regular"
    | "subtitle2"
    | "subtitle2_medium"
    | "subtitle2_regular"
    | "body1"
    | "body1_medium"
    | "body1_regular"
    | "body2"
    | "body2_medium"
    | "body2_regular"
    | "body3"
    | "body3_medium"
    | "body3_regular"
    | "body4"
    | "body4_medium"
    | "body4_regular";
  style: React.CSSProperties;
  className: string | undefined;
  id: string | undefined;
  noWrap: boolean;
  handlelines: number;
  highlightColor?: string;
} & TypographyProps;

type BasaltTypographyState = {};

class BasaltTypography extends React.PureComponent<
  BasaltTypographyProps,
  BasaltTypographyState
> {
  static defaultProps = {
    type: "h5",
    style: {},
    noWrap: false,
    id: undefined,
    className: undefined,
    handlelines: 1,
  };
  constructor(props: BasaltTypographyProps) {
    super(props);
    this.state = {
      MobileMode: window.innerWidth <= BaseMobileGuide,
    };
  }

  getTypeStyle(): { fontSize?: number; fontFamily: { fontFamily: string } } {
    const { type } = this.props;
    switch (type) {
      case "h1":
        return {
          fontSize: 64,
          fontFamily: theme.spoqahans_bold,
        };
      case "h2":
        return {
          fontSize: 46,
          fontFamily: theme.spoqahans_bold,
        };
      case "h3":
        return {
          fontSize: 36,
          fontFamily: theme.spoqahans_bold,
        };
      case "h4":
        return {
          fontSize: 32,
          fontFamily: theme.spoqahans_bold,
        };
      case "h5":
        return {
          fontSize: 28,
          fontFamily: theme.spoqahans_bold,
        };
      case "h6":
        return {
          fontSize: 24,
          fontFamily: theme.spoqahans_bold,
        };
      case "h6_regular":
        return {
          fontSize: 24,
          fontFamily: theme.spoqahans_regular,
        };
      case "subtitle1":
        return {
          fontSize: 20,
          fontFamily: theme.spoqahans_bold,
        };
      case "subtitle1_regular":
        return {
          fontSize: 20,
          fontFamily: theme.spoqahans_regular,
        };
      case "subtitle2":
        return {
          fontSize: 18,
          fontFamily: theme.spoqahans_bold,
        };
      case "subtitle2_medium":
        return {
          fontSize: 18,
          fontFamily: theme.spoqahans_medium,
        };
      case "subtitle2_regular":
        return {
          fontSize: 18,
          fontFamily: theme.spoqahans_regular,
        };
      case "body1":
        return {
          fontSize: 16,
          fontFamily: theme.spoqahans_bold,
        };
      case "body1_medium":
        return {
          fontSize: 16,
          fontFamily: theme.spoqahans_medium,
        };
      case "body1_regular":
        return {
          fontSize: 16,
          fontFamily: theme.spoqahans_regular,
        };
      case "body2":
        return {
          fontSize: 14,
          fontFamily: theme.spoqahans_bold,
        };
      case "body2_medium":
        return {
          fontSize: 14,
          fontFamily: theme.spoqahans_medium,
        };
      case "body2_regular":
        return {
          fontSize: 14,
          fontFamily: theme.spoqahans_regular,
        };
      case "body3":
        return {
          fontSize: 12,
          fontFamily: theme.spoqahans_bold,
        };
      case "body3_medium":
        return {
          fontSize: 12,
          fontFamily: theme.spoqahans_medium,
        };
      case "body3_regular":
        return {
          fontSize: 12,
          fontFamily: theme.spoqahans_regular,
        };
      case "body4":
        return {
          fontSize: 10,
          fontFamily: theme.spoqahans_bold,
        };
      case "body4_medium":
        return {
          fontSize: 10,
          fontFamily: theme.spoqahans_medium,
        };
      case "body4_regular":
        return {
          fontSize: 10,
          fontFamily: theme.spoqahans_regular,
        };
      default:
        return {
          fontSize: undefined,
          fontFamily: theme.spoqahans_regular,
        };
    }
  }

  handlerLinesStyle() {
    const { handlelines } = this.props;
    if (handlelines > 1)
      return {
        maxWidth: "100%",
        display: "-webkit-box",
        WebkitLineClamp: handlelines,
        overflow: "hidden",
      };
    else return {};
  }

  render() {
    const defaultStyle = this.getTypeStyle();
    const { noWrap, id, style, className, handlelines } = this.props;
    /*
        whiteSpace: "pre-wrap", 
    WebkitBoxOrient: 'vertical',
        */
    return (
      <Typography
        {...this.props}
        noWrap={noWrap}
        id={id}
        style={{
          ...defaultStyle.fontFamily,
          ...this.handlerLinesStyle(),
          whiteSpace: handlelines > 1 ? "pre-wrap" : undefined,
          WebkitBoxOrient: handlelines > 1 ? "vertical" : undefined,
          fontSize: defaultStyle.fontSize,
          ...style,
        }}
        className={className}
      >
        {this.props.children}
      </Typography>
    );
  }
}

export default BasaltTypography;
