export default {
  ko: {
    project_create: {
      title: "새 폴더 생성",
      name: "폴더 이름",
      name_subheader: "폴더 이름을 50자 이내로 입력하세요",
      name_placeholder: "폴더 이름을 입력하세요",
      add_members: "멤버 추가",
      add_members_subheader: "폴더에 추가할 멤버를 입력하세요",
      add_members_placeholder: "멤버 이메일을 입력해주세요.",
      switch_to_public_project: "전체 공개 폴더로 전환",
      button_create: "만들기",
      members_title: "Members",
      teams_title: "Teams",
      direct_input: "직접 입력한 사용자",
      add_on_click: "클릭해서 추가하기",
      not_member: "외부 사용자",
    },
    drag_tip: "화면에 파일을 끌어다 놓으면 업로드 됩니다",
    drag_or: "또는",
    upload_tip: "또는 화면에 파일을 끌어다 놓으면 업로드 됩니다",
    upload_tip_xs: "파일을 자유롭게 업로드 해보세요!",
    upload_button: "파일 업로드",
    upload_none: "해당 폴더에 올라간 파일이 없습니다",
    add_files: "파일 업로드",
    save: "저장",
    all_files: "모든 파일",
    update_prefix: "",
    update_postfix: "업데이트됨",
    all_members: "모든 멤버를 보기",
    get_nda: "다른 NDA 가져오기",
    download_button: "다운로드",
    cancel: "취소",
    delete_button: "폴더삭제",
    delete_button_eng: "Delete",
    select_all: "모두 선택",
    files: "개",
    deleteFolder: "이 폴더를 삭제하시겠습니까?",
    nda: {
      change_nda: "NDA파일 바꾸기",
      choose_nda: "NDA 파일 선택하기",
      choose_subtitle: "폴더 기본 NDA 파일을 선택해주세요",
      search: "파일 검색하기",
      set_default: "기본 NDA파일 설정하기",
      update_nda: "NDA 파일 업데이트하기",
      no_set_nda: "설정된 NDA파일이 없습니다.",
    },
    bookmark: "폴더 즐겨찾기",
    invite: "다른 멤버들을 초대해보세요",
    link: {
      create: "링크 생성하기",
      new: "새로운 링크 생성",
      require_email: "이메일을 요구합니다",
      require_sign: "사인을 요구합니다",
      can_download: "PDF 다운 가능",
      can_print: "프린트 가능",
    },

    report_tip: "파일의 리포트를 확인할 수 있습니다",
    preview: "미리보기",
    settings: {
      files: "파일",
      verified_files: "이 블록체인에 인증되었습니다",
      creation_date: "생성날짜",
      title: "폴더 세팅",
      settings: "설정",
      info: "폴더 정보",
      folder_name: "폴더 이름",
      tooltip: "폴더 소유자만 설정을 편집할 수 있습니다",
      remove: "폴더 삭제",
      private_folder: "개인 폴더",
      shared_folder: "공유 폴더",
      public_folder: "공용 폴더",
      prefix: "",
      postfix: "의 설정",
      back: "돌아가기",
      folder_owner: "폴더 관리자",
    },
    members: {
      members: "Members",
      member: "Member",
      member_search: "멤버 검색",
      member_search_placeholder: "멤버 이름 또는 이메일을 검색하세요",
      account: "계정",
      authority: "권한",
      owner: "소유자",
      admin: "관리자",
      workspaceAdmin: "Admin",
      write: "쓰기",
      read: "읽기 전용",
      editor: "편집자",
      guest: "Guest",
      invite: "멤버 초대하기",
      make_public: "워크스페이스 전체 공개 폴더로 전환",
      is_public: "공개 폴더",
      send_email_again: "초대 메일 다시 발송",
      invitation: "초대 하기",
      add: "추가 하기",
      workspace_outside_user: "워크스페이스 외부 멤버",
      description: {
        admin:
          "폴더에 사람 초대, 파일 업로드, 공유 링크 생성 및 삭제, 파일 히스토리 다운로드를 할 수 있습니다.",
        write:
          "폴더에 파일 업로드, 파일 프리뷰 및 파일 히스토리, 파일 인증 정보를 확인할 수 있습니다.",
        read: "파일 프리뷰 및 파일 히스토리, 파일 인증 정보를 확인할 수 있습니다.",
      },
    },

    all_projects: {
      last_updated: "마지막 업데이트됨",
      folders: "폴더",
    },
  },

  en: {
    project_create: {
      title: "Create new folder",
      name: "Folder name",
      name_subheader: "Enter folder name within 50 characters",
      name_placeholder: "Enter folder name",
      add_members: "Add Members",
      add_members_subheader: "Enter members to add to the folder",
      add_members_placeholder: "Enter member's Email address",
      switch_to_public_project: "Switch to public folder",
      button_create: "Create",
      members_title: "Members",
      teams_title: "Teams",
      direct_input: "User Entered Directly",
      add_on_click: "Click and add",
      not_member: "User from Outside",
    },
    drag_tip: "If you drag and drop the file, it will be uploaded",
    drag_or: "or",
    upload_button: "Upload files",
    upload_none: "No Files Uploaded",
    add_files: "Upload files",
    save: "Save",
    all_files: "All files",
    update_prefix: "Updated",
    update_postfix: "",
    all_members: "See all members",
    get_nda: "Import another NDA",
    download_button: "Download",
    cancel: "Cancel",
    delete_button: "Delete",
    delete_button_eng: "Delete",
    select_all: "Select all",
    files: " files",
    deleteFolder: "Are you sure to delete this folder?",
    nda: {
      change_nda: "Change NDA file",
      choose_nda: "Select NDA file",
      choose_subtitle: "Please choose the default NDA file in the folder",
      search: "Search for files",
      set_default: "Set default NDA file",
      update_nda: "Update NDA",
      delete: "Are you sure to delete this folder?",
      no_set_nda: "No NDA files were set.",
    },
    bookmark: "Add to bookmark",
    invite: "Invite other members",
    upload_tip: "Or upload files by dropping them on the screen",
    upload_tip_xs: "Feel free to upload files!",
    link: {
      create: "Create link",
      new: "Create new link",
      require_email: "It requires Email address",
      require_sign: "It requires a signature",
      can_download: "Available to download PDF",
      can_print: "Print available",
    },
    report_tip: "You can check the reports for files",
    preview: "Preview",
    settings: {
      creation_date: "Date Created",
      files: "files",
      verified_files: " have been authenticated on the blockchain.",
      title: "Folder Settings",
      settings: "Setting",
      info: "Folder Information",
      folder_name: "Folder Name",
      tooltip: "Only folder owner can modify the settings",
      remove: "Delete",
      private_folder: "Private Folder",
      shared_folder: "Shared Folder",
      public_folder: "Public Folder",
      prefix: "Settings of ",
      postfix: "",
      back: "Return to Folders",
      folder_owner: "Folder Administrator",
    },
    members: {
      members: "Members",
      member: "Member",
      member_search: "Search for members",
      member_search_placeholder: "Search for member's name or Email",
      account: "Account",
      authority: "Authority",
      send_email_again: "Send the invitation again",
      owner: "Owner",
      admin: "Administrator",
      workspaceAdmin: "Administrator",
      write: "Write",
      read: "Read Only",
      editor: "Editor",
      guest: "Guest",
      invite: "Invite members",
      make_public: "Switch to public folder",
      is_public: "Public Folder",
      invitation: "Invite",
      add: "Add",
      workspace_outside_user: "Member from outside of workspace",
      description: {
        admin:
          "You can invite members, upload files, create/delete sharing-links, and download the file history report",
        write:
          "You can upload files, see file previews, and check file history and the authentication information.",
        read: "You can check file previews, file history, authentication information",
      },
    },

    all_projects: {
      last_updated: "Last updated",
      folders: "Folders",
    },
  },
};
