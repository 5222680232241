import { InternalErrorKind, mkErr } from "@redwit-commons/utils/exception2";
import config from "@src/config";
import { produce } from "immer";
import { Action, Dispatch } from "redux";

export enum LazyScriptActionType {
  DONE = "LazyScriptAction::DONE",
}

export type LazyScriptAction = {
  readonly type: LazyScriptActionType.DONE;
  readonly done: string;
};

export type LazyScriptState = {
  readonly done_list: string[];
};

const actionTypeGuard = (input: Action): input is LazyScriptAction => {
  if (typeof input.type === "string") {
    switch (input.type) {
      case LazyScriptActionType.DONE:
        return true;
      default:
        return false;
    }
  }
  return false;
};

const createDefault = (): LazyScriptState => {
  return {
    done_list: [],
  };
};

export default (state: LazyScriptState | undefined, action: Action) => {
  if (state === undefined) {
    return createDefault();
  }
  if (!actionTypeGuard(action)) {
    return state;
  }

  return produce(state, (draft) => {
    switch (action.type) {
      case LazyScriptActionType.DONE: {
        if (draft.done_list.includes(action.done)) return;
        draft.done_list = [...draft.done_list, action.done];
        break;
      }

      default: {
        throw mkErr({
          kind: InternalErrorKind.Fatal,
          loc: "LazyScriptAction",
          msg: "Unknown action",
        });
      }
    }
  });
};

export const thirdpartyScriptLoaderInfos = {
  jQuery: {
    src: "https://code.jquery.com/jquery-1.12.4.min.js",
    type: "jQuery",
    async: false,
    defer: false,
  },
  global_payple: {
    src: config.payple.GLOBAL_PAYPLE_SCRIPT,
    type: "global_payple",
    async: false,
    defer: false,
  },
  domestic_payple: {
    src: config.payple.DOMESTIC_PAYPLE_SCRIPT,
    type: "domestic_payple",
    async: false,
    defer: false,
  },
  googleAPI: {
    src: "https://apis.google.com/js/platform.js?onload=init",
    type: "googleAPI",
    async: true,
    defer: true,
  },
};

const load_started: string[] = [];

export const triggerLazyScriptLoad = (
  name: keyof typeof thirdpartyScriptLoaderInfos,
  dispatch: Dispatch
) => {
  if (load_started.includes(name)) return;

  load_started.push(name);

  const { src, async, defer } = thirdpartyScriptLoaderInfos[name];
  const scriptElem = document.createElement("script");

  scriptElem.src = src;
  async && (scriptElem.async = async);
  defer && (scriptElem.defer = defer);

  document.body.appendChild(scriptElem);

  scriptElem.onload = () => {
    dispatch({
      done: name,
      type: LazyScriptActionType.DONE,
    });
  };
};
