import T from "@redwit-commons/utils/typecheck";
import { SearchParams, searchParamsSchema } from "./common";

export type IGetNotes = SearchParams;

export const IGetNotesSchema = searchParamsSchema.clone();

export const validateIGetNotes = T.mkValidator<IGetNotes>(IGetNotesSchema);

/**
 * Sharp 에서 input 으로 받을 수 있는 이미지인지 확인한다.
 */
export type ImageExtension = "jpg" | "jpeg" | "png" | "svg" | "tiff" | "webp";
export const imageExtensionList = ["jpg", "jpeg", "png", "svg", "tiff", "webp"];
export const trivialImageExtensionList = ["jpg", "jpeg", "png"];
export const ImageExtensionSchema = T.string().withEnum(imageExtensionList);
export const validateImageExtension =
  T.mkValidator<ImageExtension>(ImageExtensionSchema);

/**
 * heic-convert 에서 처리 가능한 이미지 타입을 말한다
 */
export type HEICImageExtension = "heif" | "heic";
export const heicExtensionList = ["heif", "heic"];
export const HEICImageExtensionSchema = T.string().withEnum(heicExtensionList);
export const validateHEICImageExtension = T.mkValidator<HEICImageExtension>(
  HEICImageExtensionSchema
);

/**
 * Openoffice 에서 pdf로 변환 가능한 타입을 말한다.
 */
export type OfficeFileExtension =
  | "doc"
  | "docx"
  | "ppt"
  | "pptx"
  | "xls"
  | "xlsx";
export const officeExtensionList = [
  "doc",
  "docx",
  "ppt",
  "pptx",
  "xls",
  "xlsx",
];
export const OfficeFileExtensionSchema =
  T.string().withEnum(officeExtensionList);
export const validateOfficeFileExtension = T.mkValidator<OfficeFileExtension>(
  OfficeFileExtensionSchema
);

export type PDFFileExtension = "pdf";
export const pdfExtensionList = ["pdf"];
export const PDFFileExtensionSchema = T.string().withEnum(pdfExtensionList);
export const validatePDFFileExtension = T.mkValidator<PDFFileExtension>(
  PDFFileExtensionSchema
);

export type UnknownExtension = "unknown";
export const unknownExtensionList = ["unknown"];
export const UnknownExtensionSchema = T.string().withEnum(pdfExtensionList);
export const validateUnknownExtension = T.mkValidator<UnknownExtension>(
  UnknownExtensionSchema
);

type HWPFileExtension = "hwp";
const hwpExtensionList = ["hwp"];
const HWPFileExtensionSchema = T.string().withEnum(hwpExtensionList);
/**
 * @deprecated 아직 개발 안됨. 사용 금지.
 */
export const _validateHWPFileExtension = T.mkValidator<HWPFileExtension>(
  HWPFileExtensionSchema
);

export interface ICreateNote {
  /**
   * @deprecated 곧 URL 이 퇴출 될 예정입니다. CID를 사용해주세요
   */
  url?: string;
  cid: string;
  /**
   * 반드시 소문자로 넣어주세요 "pdf", "png" 처럼 '.' 빼고 주세요.
   *
   * 이 필드 변경 시 서버측에서 IPFS 업로드 가능 리스트에도 넣어주어야 합니다.
   */
  extension:
    | ImageExtension
    | HEICImageExtension
    | OfficeFileExtension
    | PDFFileExtension
    | UnknownExtension;
  tag?: Array<string>;
  /**
   * @deprecated PDF 인 경우 명시적으로 extension 을 주세요. 당분간은 병행해서
   * isFile 도 함께 세팅해주시면 좋겠습니다.
   */
  isFile?: boolean;
  writtenAt?: string;
  /**
   * 글로벌 서비스에 필요한 필드입니다.
   * file_name: 확장자 명까지 포함한 파일 이름입니다.
   */
  file_name?: string;
}

export const ICreateNoteSchema = T.object()
  .addField("url", T.string(), false)
  .addField("cid", T.string())
  .addField(
    "extension",
    T.string().withEnum([
      ...imageExtensionList,
      ...heicExtensionList,
      ...officeExtensionList,
      ...pdfExtensionList,
      ...unknownExtensionList,
    ])
  )
  .addField("tag", T.array(T.string()), false)
  .addField("isFile", T.boolean(), false)
  .addField("writtenAt", T.string(), false)
  .addField("file_name", T.string(), false);

export const validateICreateNote =
  T.mkValidator<ICreateNote>(ICreateNoteSchema);

export type IGetAuthNotes = SearchParams;

export const IGetAuthNotesSchema = searchParamsSchema.clone();

export const validateIGetAuthNotes =
  T.mkValidator<IGetAuthNotes>(IGetAuthNotesSchema);

export type ISearch = {
  q: string;
} & SearchParams;

export const ISearchSchema = searchParamsSchema
  .clone()
  .addField("q", T.string());

export const validateISearch = T.mkValidator<ISearch>(ISearchSchema);

export interface IGetNotePDF {
  noteIds: string[];
  fileName?: string;
}

export const IGetNotePDFSchema = T.object()
  .addField("noteIds", T.array(T.string()))
  .addField("fileName", T.string(), false);

export type IMergeNotes = IGetNotePDF;
export const IMergeNotesSchema = IGetNotePDFSchema;

export const validateIGetNotePDF =
  T.mkValidator<IGetNotePDF>(IGetNotePDFSchema);
export const validateIMergeNotes =
  T.mkValidator<IMergeNotes>(IMergeNotesSchema);

export interface ICreateNoteTags {
  tags: string[];
}
export interface IDeleteNoteTag {
  tagId: string;
}
export const ICreateNoteTagsSchema = T.object().addField(
  "tags",
  T.array(T.string())
);
export const IDeleteNoteTagSchema = T.object().addField("tagId", T.string());

export const validateICreateNoteTags = T.mkValidator<ICreateNoteTags>(
  ICreateNoteTagsSchema
);
export const validateIDeleteNoteTag =
  T.mkValidator<IDeleteNoteTag>(IDeleteNoteTagSchema);

/**
 * ShareNoteWithLink api has three optional conditions
 * expiredAt will decide how much time external user can read note.
 * ndaOpt will get an nda sign from external user who want to read note
 * emailOpt will get an email of external user
 */
export interface IShareNote {
  noteIds: string[];
  printOpt?: boolean;
  downloadOpt?: boolean;
  expiresIn?: string;
  ndaOpt?: boolean;
  emailOpt?: boolean;
}

export const IShareNoteSchema = T.object()
  .addField("noteIds", T.array(T.string()))
  .addField("email", T.boolean(), false)
  .addField("expiresIn", T.string(), false)
  .addField("nda", T.boolean(), false);

export const validateIShareNote = T.mkValidator<IShareNote>(IShareNoteSchema);

export interface IGetSharePDF {
  email?: string;
  nda?: string;
  nda_extension?: string;
  share_token: string;
}
export interface IDecodeShareNotePDF {
  share_token: string;
}

export const IGetSharePDFSchema = T.object()
  .addField("share_token", T.string())
  .addField("email", T.string(), false)
  .addField("nda", T.string(), false)
  .addField("nda_extension", T.string(), false);

export const validateIGetSharePDF =
  T.mkValidator<IGetSharePDF>(IGetSharePDFSchema);

export const IDecodeShareNotePDFSchema = T.object().addField(
  "share_token",
  T.string()
);
export const validateIDecodeShareNotePDF = T.mkValidator<IDecodeShareNotePDF>(
  IDecodeShareNotePDFSchema
);
