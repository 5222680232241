export default {
  ko: {
    pw_update: {
      placeholder: "새로운 비밀번호를 입력해주세요",
      placeholder_confirm: "한번 더 입력해주세요",
      button: "비밀번호 업데이트",
      title: "비밀번호 업데이트",
    },
  },

  en: {
    pw_update: {
      placeholder: "Enter new password",
      placeholder_confirm: "Confirm your password",
      button: "Update your password",
      title: "Password update",
    },
  },
};
