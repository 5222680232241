import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { ProjectObject } from "@basalt-commons/api/object/project";
import SpoqaHansTypography from "../../typo/SpoqaHansTypography";
import { theme } from "@theme";
import { BaseSideBarWidth } from "../../../../utils/templates/styles/BaseGrid";
// import { BaseHeaderHeight, BaseSideBarWidth } from "../../../../utils/templates/styles/BaseGrid";
import FolderRoundedIcon from "@material-ui/icons/FolderRounded";
import FolderSharedRoundedIcon from "@material-ui/icons/FolderSharedRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { ProjectAuthType } from "@basalt-commons/api/object/user_project_map";
import { getProjectAuthLevel } from "@basalt-commons/global-api/object/user_project_map";

export const SidebarFolderItemHeight = 32;

type SidebarFolderItemProps = {
  userId: string;
  folder: ProjectObject;
  selected: boolean;
  onClick: (folder: ProjectObject) => void;
  onEdit:
    | ((folder: ProjectObject) => void)
    | ((folder: ProjectObject) => Promise<void>);
};

type SidebarFolderItemState = {
  hover: boolean;
};

class SidebarFolderItem extends React.PureComponent<
  SidebarFolderItemProps,
  SidebarFolderItemState
> {
  static defaultProps = {
    userId: "invalid",
    onEdit: () => {},
    onClick: () => {},
    selected: false,
  };
  constructor(props: SidebarFolderItemProps) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  hasEditAuth() {
    const { userId, folder } = this.props;
    const user = folder.Users.find((u) => u.id === userId);
    if (user === undefined) return false;
    if (
      getProjectAuthLevel(user.authType) <
      getProjectAuthLevel(ProjectAuthType.ADMIN)
    )
      return false;
    return true;
  }

  getColor() {
    if (this.props.selected === true) return theme.indigo_5;
    else {
      if (this.state.hover) return theme.black;
      else return theme.gray_8;
    }
  }

  render() {
    const { folder } = this.props;
    const color = this.getColor();

    return (
      <Grid
        onClick={() => {
          this.props.onClick(folder);
        }}
        onMouseEnter={() => {
          this.setState({ hover: true });
        }}
        onMouseLeave={() => {
          this.setState({ hover: false });
        }}
        container
        item
        xs={12}
        alignItems="center"
        direction="row"
        style={{
          cursor: "pointer",
          width: "100%",
          height: SidebarFolderItemHeight,
          backgroundColor:
            this.state.hover === true ? theme.gray_2 : theme.gray_0,
          paddingLeft: 20,
        }}
      >
        {folder.Users.length > 1 ? (
          <FolderSharedRoundedIcon
            style={{ ...theme.font16, color, marginRight: 10 }}
          />
        ) : (
          <FolderRoundedIcon
            style={{ ...theme.font16, color, marginRight: 10 }}
          />
        )}
        <SpoqaHansTypography
          fontWeight="medium"
          style={{
            width: BaseSideBarWidth - 140 - 40,
            ...theme.font14,
            color,
            userSelect: "none",
          }}
          noWrap
        >
          {folder.name}
        </SpoqaHansTypography>
        {this.state.hover === true && this.hasEditAuth() === true && (
          <IconButton
            onClick={async (evt) => {
              evt.stopPropagation();
              await this.props.onEdit(folder);
            }}
            style={{ position: "absolute", width: 28, height: 28, right: 16 }}
          >
            <EditRoundedIcon style={{ color: theme.gray_5, fontSize: 20 }} />
          </IconButton>
        )}
      </Grid>
    );
  }
}

export default SidebarFolderItem;
