import { doAPIPostRequest, doAPIGetRequest, doAPIDeleteRequest } from "./api";
import { ProjectObject } from "@basalt-commons/api/object/project";
import {
  validateOGetProjects,
  validateOCreateProject,
  validateODeleteProject,
  validateOUpdateProject,
  validateOShareProject,
  validateOJoinProject,
  validateODeleteJoinedUser,
  validateOGetProject,
  validateOPinnedProject,
  validateOShareProjectLink,
  validateOUpdateJoinedUser,
  OCheckProjectShareToken,
  validateOCheckProjectShareToken,
} from "@basalt-commons/api/response/project";
import {
  ICreateProject,
  IUpdateProject,
  IShareProject,
  IJoinProject,
  IDeleteJoinedUser,
  IShareProjectMembers,
  IPinnedProject,
  IUpdateJoinedUser,
  ICheckProjectShareToken,
} from "@basalt-commons/api/request/project";

/**
 * 프로젝트 리스트를 받아오는 API Service
 * @param token string, 구노 토큰
 * @returns Promise<ProjectObject[]>
 */
const getProjects = async (token: string): Promise<ProjectObject[]> => {
  const obj = await doAPIGetRequest("projects", token);
  const ret = validateOGetProjects(obj);
  return ret.response.results;
};
/**
 * 프로젝트를 받아오는 API Service
 * @param token string, 구노 토큰
 * @returns Promise<ProjectObject>
 */
const getProject = async (
  token: string,
  projectID: string
): Promise<ProjectObject> => {
  const obj = await doAPIGetRequest(`project/${projectID}`, token);
  const ret = validateOGetProject(obj);
  return ret.response;
};
/**
 * 프로젝트 생성 API Service
 * @param token string, 구노 토큰
 * @param args ICreateProject
 * @returns Promise<ProjectObejct>
 */
const createProject = async (
  token: string,
  args: ICreateProject
): Promise<ProjectObject> => {
  const ret = validateOCreateProject(
    await doAPIPostRequest("projects", token, args)
  );
  return ret.response;
};
/**
 * 프로젝트 삭제 API Service
 * @param token string, 구노 토큰
 * @param projectID
 * @returns Promise<string>
 */
const deleteProject = async (
  token: string,
  projectID: string
): Promise<string> => {
  const ret = validateODeleteProject(
    await doAPIDeleteRequest(`projects/${projectID}`, token)
  );
  return ret.response;
};
/**
 * 프로젝트 수정 API Service
 * @param token string, 구노 토큰
 * @param projectId
 * @param args IUpdateProject
 * @returns Promise<ProjectObejct>
 */
const editProject = async (
  token: string,
  projectID: string,
  args: IUpdateProject
): Promise<ProjectObject> => {
  const ret = validateOUpdateProject(
    await doAPIPostRequest(`projects/${projectID}/update`, token, args)
  );
  return ret.response;
};

const shareProject = async (
  token: string,
  projectID: string,
  args: IShareProject
): Promise<string> => {
  const ret = validateOShareProject(
    await doAPIPostRequest(`projects/${projectID}/share`, token, args)
  );
  return ret.response.token;
};

const shareProjectLink = async (
  token: string,
  projectID: string,
  args: IShareProject
): Promise<string> => {
  const ret = validateOShareProjectLink(
    await doAPIPostRequest(`projects/${projectID}/share/link`, token, args)
  );
  return ret.response.link;
};

const shareProjectWithMemebers = async (
  token: string,
  projectID: string,
  args: IShareProjectMembers
) => {
  await doAPIPostRequest(`projects/${projectID}/share/members`, token, args);
  return;
};

const joinProject = async (
  token: string,
  args: IJoinProject
): Promise<ProjectObject> => {
  const ret = validateOJoinProject(
    await doAPIPostRequest(`projects/join`, token, args)
  );
  return ret.response;
};

const pinProject = async (
  token: string,
  projectID: string,
  args: IPinnedProject
): Promise<ProjectObject> => {
  const ret = validateOPinnedProject(
    await doAPIPostRequest(`project/${projectID}/pinned`, token, args)
  );
  return ret.response;
};

const deleteProjectAuth = async (token: string, args: IDeleteJoinedUser) => {
  const ret = validateODeleteJoinedUser(
    await doAPIDeleteRequest(`projects/joined`, token, args)
  );
  return ret.response;
};

const updateProjectAuth = async (token: string, args: IUpdateJoinedUser) => {
  const ret = validateOUpdateJoinedUser(
    await doAPIPostRequest(`projects/joined`, token, args)
  );
  return ret.response;
};

const checkShareToken = async (
  token: string,
  args: ICheckProjectShareToken
): Promise<OCheckProjectShareToken> => {
  const ret = validateOCheckProjectShareToken(
    await doAPIPostRequest(`/projects/check/share_token`, token, args)
  );
  return ret;
};

export default {
  getProjects,
  getProject,
  createProject,
  pinProject,
  deleteProject,
  editProject,
  shareProject,
  shareProjectWithMemebers,
  joinProject,
  deleteProjectAuth,
  updateProjectAuth,
  checkShareToken,
  shareProjectLink,
};
