import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "../../api/object/access_time";
import {
  DocumentObject,
  DocumentObjectSchema,
  DocumentWithUserObject,
  DocumentWithUserObjectSchema,
} from "./document";
import {
  ProjectPureObject,
  ProjectPureObjectSchema,
} from "../../api/object/project";
import { UserObject, UserObjectSchema } from "../../api/object/user";
import { ContractObject, ContractObjectSchema } from "./nda_contract";
import { addTXInfo, TXInfo } from "../../api/object/tx_info";

/**
 * x, y 좌표는 박스 기준으로 왼쪽 아래 꼭지점
 */
export type CustomInfo = {
  x: number;
  y: number;
  page: number;
};

export type CustomNDAInfo = {
  requestor: {
    name: CustomInfo;
    sign: CustomInfo;
    department?: CustomInfo;
    time: CustomInfo;
  };
  signer: {
    name: CustomInfo;
    sign: CustomInfo;
    department?: CustomInfo;
    time: CustomInfo;
  };
};

export const CustomInfoSchema = T.object()
  .addField("x", T.number())
  .addField("y", T.number())
  .addField("page", T.number());

export const CustomNDAInfoSchema = T.object()
  .addField(
    "requestor",
    T.object()
      .addField("name", CustomInfoSchema.clone())
      .addField("sign", CustomInfoSchema.clone())
      .addField("department", CustomInfoSchema.clone(), false)
      .addField("time", CustomInfoSchema.clone())
  )
  .addField(
    "signer",
    T.object()
      .addField("name", CustomInfoSchema.clone())
      .addField("sign", CustomInfoSchema.clone())
      .addField("department", CustomInfoSchema.clone(), false)
      .addField("time", CustomInfoSchema.clone())
  );

export const extractCustomNDAInfo =
  T.mkObjectExtractor<CustomNDAInfo>(CustomNDAInfoSchema);
export const validateCustomNDAInfo =
  T.mkValidator<CustomNDAInfo>(CustomNDAInfoSchema);

export type NdaPureObjectCore = {
  readonly id: string;
  readonly file_name: string;
  readonly description?: string;
  readonly cid: string;
  readonly extension: string;
  readonly thumbCid?: string;
  readonly thumbExtension?: string;
  readonly UserId: string;
  readonly WorkspaceId: string;
  readonly StampId?: string;
  readonly isCustom: boolean;
  readonly json?: string;
};

export type NdaDocumentMapObjectCore = {
  readonly NdaId: string;
  readonly DocumentId: string;
};

export type NdaObjectCore = {
  readonly Documents: Array<DocumentObject>;
  readonly Projects: Array<ProjectPureObject>;
  readonly User: UserObject;
} & NdaPureObject;

export type NdaPureObject = NdaPureObjectCore & AccessTime;
export type NdaObject = NdaObjectCore & AccessTime;
export type NdaObjectWithContract = {
  readonly Documents: Array<DocumentWithUserObject>;
  readonly NdaContracts: Array<ContractObject>;
} & NdaPureObject;

export const NdaEssentialSchema = T.object()
  .addField("id", T.string())
  .addField("file_name", T.string())
  .addField("description", T.string(), false)
  .addField("cid", T.string())
  .addField("extension", T.string())
  .addField("UserId", T.string())
  .addField("WorkspaceId", T.string())
  .addField("StampId", T.string(), false)
  // .addField( 'hash', T.string() )
  // .addField( 'hashAlg', T.string() )
  .addField("CipherId", T.string(), false); // not implemented on this branch

export const NdaEssentialWithTXSchema = addTXInfo(NdaEssentialSchema.clone());

export type NdaEssential = {
  readonly id: string;
  readonly file_name: string;
  readonly description?: string;
  readonly cid: string;
  readonly extension: string;
  readonly UserId: string;
  readonly WorkspaceId: string;
  readonly StampId?: string;
  // readonly hash: string,
  readonly hashAlg: string;
  readonly CipherId?: string; // not implemented on this branch
};

/**
 * 성공한 Nda essential 을 불러올 때 필요
 */
export type NdaEssentialWithTX = NdaEssential & TXInfo;

export const extractNdaEssentialWithTX =
  T.mkObjectExtractor<NdaEssentialWithTX>(NdaEssentialWithTXSchema);
const extractNdaEssentialHelper =
  T.mkObjectExtractor<NdaEssential>(NdaEssentialSchema);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const extractNdaEssential = (input: any): NdaEssential => {
  if (typeof input === "object") {
    if (input.hashAlg === undefined) {
      input.hashAlg = "sha256";
    }
  }
  return extractNdaEssentialHelper(input);
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const NdaPureObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("file_name", T.string())
  .addField("description", T.string(), false)
  .addField("cid", T.string())
  .addField("extension", T.string())
  .addField("thumbCid", T.string(), false)
  .addField("thumbExtension", T.string(), false)
  .addField("UserId", T.string())
  .addField("WorkspaceId", T.string())
  .addField("StampId", T.string(), false)
  .addField("isCustom", T.boolean())
  .addField("json", T.string(), false);

export const NdaObjectSchema = NdaPureObjectSchema.clone()
  .addField("Documents", T.array(DocumentObjectSchema.clone()))
  .addField("Projects", T.array(ProjectPureObjectSchema.clone()))
  .addField("User", UserObjectSchema.clone());

export const NdaObjectWithContractSchema = NdaPureObjectSchema.clone()
  .addField("Documents", T.array(DocumentWithUserObjectSchema.clone()))
  .addField("NdaContracts", T.array(ContractObjectSchema.clone()));

export const extractNdaPureObject =
  T.mkObjectExtractor<NdaPureObject>(NdaPureObjectSchema);
export const extractNdaObject = T.mkObjectExtractor<NdaObject>(NdaObjectSchema);
export const validateNdaObject = T.mkValidator<NdaObject>(NdaObjectSchema);
export const extractNdaObjectWithContract =
  T.mkObjectExtractor<NdaObjectWithContract>(NdaObjectWithContractSchema);
