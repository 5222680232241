import { doAPIPostRequest } from "./api";

import {
  IInitNotification,
  IPushNotification,
} from "@basalt-commons/api/request/notification";
import {
  validateOInitNotification,
  OInitNotification,
  OPushNotification,
  validateOPushNotification,
} from "@basalt-commons/api/response/notification";

/**
 * GOONO 서버에 푸시 알람을 받아오기 위한 등록 API Serivce
 * @param goonoToken string
 * @param args IPushNotification
 * @returns Promise<OInitNotification>
 */
const pushNotiRegister = async (
  goonoToken: string,
  args: IInitNotification
): Promise<OInitNotification> => {
  const ret = await doAPIPostRequest("notification", goonoToken, args);
  return validateOInitNotification(ret);
};

const postNoti = async (
  goonoToken: string,
  args: IPushNotification
): Promise<OPushNotification> => {
  const ret = await doAPIPostRequest(`admin/notification`, goonoToken, args);
  return validateOPushNotification(ret);
};

export default {
  pushNotiRegister,
  postNoti,
};
