import { createStyles } from "@material-ui/core";
import { theme } from "@theme";

const styles = createStyles({
  root: {
    width: 65,
    height: 65,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  iconGrid: {
    cursor: "pointer",
    width: 45,
    height: 45,
    borderRadius: 10,
    overflow: "hidden",
    "&:hover": {
      boxShadow: "0 1px 2px 4px #00000008",
      backgroundColor: "red",
    },
    boxShadow: "none",
  },
  image: { width: 45, height: 45, objectFit: "cover", borderRadius: 10 },
  title: { ...theme.font32, color: theme.white },
  tooltipText: { ...theme.font12, color: theme.white },

  root_small: {
    width: 36,
    height: 36,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  icon_grid_small: {
    cursor: "pointer",
    width: 28,
    height: 28,
    borderRadius: 8,
    overflow: "hidden",
    "&:hover": {
      boxShadow: "0 1px 2px 4px #00000008",
      backgroundColor: "red",
    },
    boxShadow: "none",
  },
  image_small: { width: 28, height: 28, objectFit: "cover", borderRadius: 10 },
  title_small: { ...theme.font18, color: theme.white, lineHeight: "1" },
  tooltipText_small: { ...theme.font10, color: theme.white },
});

export default styles;
