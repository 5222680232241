import { useLoginEffect } from "./use-login-effect";
import { useSignInEffect } from "./use-sign-in-effect";
import { useEmailVerificationEffect } from "./use-email-verification-effect";
import { useInquirySendEffect } from "./use-inquiry-send-effect";
import { useSpaceCreateEffect } from "./use-space-create-effect";
import { useSendInviteEffect } from "./use-send-invite-effect";
import { useCreatFolderEffect } from "./use-create-folder-effect";
import { useUploadFileEffect } from "./use-upload-file-effect";
import { useTryCreatLinkEffect } from "./use-try-create-link-effect";
import { useCreateLinkEffect } from "./use-create-link-effect";
import { useTryUploadNdaEffect } from "./use-try-upload-nda-effect";
import { useUploadNdaEffect } from "./use-upload-nda-effect";
import { useUploadSignatureEffect } from "./use-upload-signature-effect";
import { useSendLinkEffect } from "./use-send-link-effect";
import { useUploadSecurityEffect } from "./use-upload-security-effect";
import { useBeginCheckoutEffect } from "./use-begin-checkout-effect";
import { usePurchaseEffect } from "./use-purchase-effect";
import { useIdentifyEffect } from "./use-identity-effect";

export const marketingFlow = {
  useLoginEffect,
  useSignInEffect,
  useEmailVerificationEffect,
  useInquirySendEffect,
  useSpaceCreateEffect,
  useSendInviteEffect,
  useCreatFolderEffect,
  useUploadFileEffect,
  useTryCreatLinkEffect,
  useCreateLinkEffect,
  useTryUploadNdaEffect,
  useUploadNdaEffect,
  useUploadSignatureEffect,
  useSendLinkEffect,
  useUploadSecurityEffect,
  useBeginCheckoutEffect,
  usePurchaseEffect,
  useIdentifyEffect,
};
