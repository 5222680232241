import T from "@redwit-commons/utils/typecheck";
import { AccessTime, withAccessTime } from "../../api/object/access_time";
import { UserObject, UserObjectSchema } from "../../api/object/user";

export type TeamPureCore = {
  readonly id: string;
  readonly name: string;
  readonly WorkspaceId: string;
};
export type UserTeamMapPureCore = {
  readonly UserId: string;
  readonly TeamId: string;
};

export type TeamWithUsersObject = {
  readonly Users: Array<UserObject>;
} & TeamPureObject;

export type TeamPureObject = TeamPureCore & AccessTime;

export const TeamPureObjectSchema = withAccessTime()
  .addField("id", T.string())
  .addField("name", T.string())
  .addField("WorkspaceId", T.string());

export const TeamWithUsersObjectSchema = TeamPureObjectSchema.clone().addField(
  "Users",
  T.array(UserObjectSchema.clone())
);

export const extractTeamPureObject =
  T.mkObjectExtractor<TeamPureObject>(TeamPureObjectSchema);
export const extractTeamWithUsersObject =
  T.mkObjectExtractor<TeamWithUsersObject>(TeamWithUsersObjectSchema);
export const validateTeamWithUsersObject = T.mkValidator<TeamWithUsersObject>(
  TeamWithUsersObjectSchema
);
