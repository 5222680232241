import T from "@redwit-commons/utils/typecheck";
import { AccessTime, addAccessTime } from "./access_time";

export enum UserLogType {
  UPLOAD_NOTE = "UPLOAD_NOTE",
  UPLOAD_CHUNKS_NOTES = "UPLOAD_CHUNKS_NOTES",
  UPLOAD_GD_NOTE = "UPLOAD_GD_NOTE",
  LINK_GITHUB = "LINK_GITHUB",
  VISIT = "VISIT",
  ACCESS = "ACCESS",
  ACCESS_ADMIN = "ACCESS_ADMIN",
  ACCESS_USER = "ACCESS_USER",
  ACCESS_MANAGER = "ACCESS_MANAGER",
  DOWNLOAD_FILE = "DOWNLOAD_FILE",
  VIEW_CONTENT = "VIEW_CONTENT",
  VIEW_FOLDER = "VIEW_FOLDER",
  VIEW_FILE = "VIEW_FILE",
  DOWNLOAD_CHUNKS_FILES = "DOWNLOAD_CHUNKS_FILES",
  ONLINE = "ONLINE",
  VIEW_DOCUMENT = "VIEW_DOCUMENT",
  OFF_LINK = "OFF_LINK",
  CREATE_LINK = "CREATE_LINK",
  DELETE_FILE = "DELETE_FILE",
  DELETE_CHUNKS_NOTES = "DELETE_CHUNKS_NOTES",
  JOIN_FOLDER = "JOIN_FOLDER",
  JOIN_WORKSPACE = "JOIN_WORKSPACE",
  CREATE_WORKSPACE = "CREATE_WORKSPACE",
  CREATE_FOLDER = "CREATE_FOLDER",
  DELETE_FOLDER = "DELETE_FOLDER",
  DOWNLOAD_BLOCK_CHAIN = "DOWNLOAD_BLOCK_CHAIN",
  DOWNLOAD_DOCUMENT = "DOWNLOAD_DOCUMENT",
  FILE_VERIFIED = "FILE_VERIFIED",
}

export const OnlyNoteTimeLineTypeList = [
  UserLogType.UPLOAD_NOTE,
  UserLogType.UPLOAD_CHUNKS_NOTES, // TODO: remove
  UserLogType.DOWNLOAD_FILE,
  UserLogType.DOWNLOAD_BLOCK_CHAIN, // TODO: remove
  UserLogType.DOWNLOAD_CHUNKS_FILES, // TODO: remove
  UserLogType.VIEW_FILE,
  UserLogType.DELETE_FILE,
  UserLogType.DELETE_CHUNKS_NOTES, // TODO: remove
  UserLogType.OFF_LINK, // TODO: remove
  UserLogType.CREATE_LINK,
  UserLogType.DOWNLOAD_DOCUMENT,
  UserLogType.VIEW_DOCUMENT,
  UserLogType.FILE_VERIFIED, // TODO: remove
];

export const WorkspaceTimelineTypeList = [
  UserLogType.ONLINE, // TODO: remove
  UserLogType.JOIN_WORKSPACE,
  UserLogType.CREATE_WORKSPACE,
];

export const OnlyTimeLineTypeList = [
  ...OnlyNoteTimeLineTypeList,
  ...WorkspaceTimelineTypeList,
  UserLogType.VIEW_FOLDER,
  UserLogType.JOIN_FOLDER,
  UserLogType.CREATE_FOLDER,
  UserLogType.DELETE_FOLDER,
];

// indexes : [ type, createdAt ]
export const ActivityUserLogTypeMapList = [
  UserLogType.UPLOAD_NOTE,
  UserLogType.DOWNLOAD_FILE,
  UserLogType.VIEW_FILE,
  UserLogType.DELETE_FILE,
  UserLogType.CREATE_LINK,
  UserLogType.JOIN_FOLDER,
  UserLogType.CREATE_FOLDER,
  UserLogType.DELETE_FOLDER,
  UserLogType.JOIN_WORKSPACE,
  UserLogType.CREATE_WORKSPACE,
];

export type ActivityType =
  | "Upload"
  | "Share"
  | "download"
  | "Download"
  | "View"
  | "Create"
  | "Invite"
  | "Delete";

export enum ActivityKind {
  UPLOAD = "Upload",
  SHARE = "Share",
  DOWNLOAD = "Download",
  VIEW = "View",
  CREATE = "Create",
  INVITE = "Invite",
  DELETE = "Delete",
}

export const ActivityTypeList = [
  ActivityKind.UPLOAD,
  ActivityKind.SHARE,
  ActivityKind.DOWNLOAD,
  ActivityKind.VIEW,
  ActivityKind.CREATE,
  ActivityKind.INVITE,
  ActivityKind.DELETE,
];

export const getUserLogTypeFromActivityKind = (kind: ActivityKind) => {
  switch (kind) {
    case ActivityKind.UPLOAD: {
      return [UserLogType.UPLOAD_NOTE];
    }
    case ActivityKind.SHARE: {
      return [UserLogType.CREATE_LINK];
    }
    case ActivityKind.DOWNLOAD: {
      return [UserLogType.DOWNLOAD_FILE];
    }
    case ActivityKind.VIEW: {
      return [UserLogType.VIEW_FILE];
    }
    case ActivityKind.CREATE: {
      return [UserLogType.CREATE_FOLDER, UserLogType.CREATE_WORKSPACE];
    }
    case ActivityKind.INVITE: {
      return [UserLogType.JOIN_FOLDER, UserLogType.JOIN_WORKSPACE];
    }
    case ActivityKind.DELETE: {
      return [UserLogType.DELETE_FILE, UserLogType.DELETE_FOLDER];
    }
  }
};

export const UserLogTypeList = [
  UserLogType.UPLOAD_GD_NOTE,
  UserLogType.LINK_GITHUB,
  UserLogType.VISIT,
  UserLogType.ACCESS,
  UserLogType.ACCESS_USER,
  UserLogType.ACCESS_ADMIN,
  UserLogType.ACCESS_MANAGER,
  ...OnlyTimeLineTypeList,
];

export type UserLogObjectCore = {
  readonly type: UserLogType;
  readonly UserId?: string;
  readonly NoteId?: string;
  readonly ProjectId?: string;
  readonly LicenseId?: string;
  readonly ip_addr?: string;
  readonly location?: string;
  readonly query?: string;
  readonly body?: string;
  readonly url?: string;
  readonly uploadType?: "gallery" | "camera" | "file";
  readonly device?: string;
  readonly platformOS?: "ios" | "android" | "web";
  readonly app_version?: string;
  readonly chunk_notes?: number;
  readonly trigger_user?: string;
  readonly userName?: string;
  readonly profile_cid?: string;
  readonly error_object?: string;
  readonly error_location?: string;
  /** baSalt log */
  readonly WorkspaceId?: string;
  readonly workspaceName?: string;
  readonly file_name?: string;
  readonly blockNumber?: string;
  readonly noteGroupId?: string;
  readonly projectName?: string;
  readonly auth?: string;

  // pdf view loggin 을 위함
  readonly pageNum?: number;
  readonly startMoment?: string;
  readonly endMoment?: string;
  readonly viewEmail?: string;
  readonly DocumentId?: string;
  readonly linkName?: string;
  readonly region?: string;
  readonly sessionId?: string; // log for counting page staying: uuid
};

// id 는 자동 생성
export type UserLogObject = AccessTime &
  UserLogObjectCore & { readonly id: number };

export const UserLogObjectCoreSchema = T.object()
  .addField("UserId", T.string(), false)
  .addField("NoteId", T.string(), false)
  .addField("ProjectId", T.string(), false)
  .addField("LicenseId", T.string(), false)
  .addField("ip_addr", T.string(), false)
  .addField("location", T.string(), false)
  .addField("query", T.string(), false)
  .addField("body", T.string(), false)
  .addField("url", T.string(), false)
  .addField("device", T.string(), false)
  .addField("uploadType", T.string(), false)
  .addField("app_version", T.string(), false)
  .addField("platformOS", T.string(), false)
  .addField("chunk_notes", T.number(), false)
  .addField("trigger_user", T.string(), false)
  .addField("userName", T.string(), false)
  .addField("profile_cid", T.string(), false)
  .addField("error_object", T.string(), false)
  .addField("error_location", T.string(), false)
  .addField("WorkspaceId", T.string(), false)
  .addField("workspaceName", T.string(), false)
  .addField("file_name", T.string(), false)
  .addField("blockNumber", T.string(), false)
  .addField("noteGroupId", T.string(), false)
  .addField("projectName", T.string(), false)
  .addField("auth", T.string(), false)
  .addField("pageNum", T.number(), false)
  .addField("startMoment", T.string(), false)
  .addField("endMoment", T.string(), false)
  .addField("viewEmail", T.string(), false)
  .addField("DocumentId", T.string(), false)
  .addField("linkName", T.string(), false)
  .addField("region", T.string(), false)
  .addField("sessionId", T.string(), false)
  .addField("type", T.string().withEnum(UserLogTypeList));

export const UserLogObjectSchema = addAccessTime(
  UserLogObjectCoreSchema.clone().addField("id", T.string())
);

export const validateUserLogObjectCore = T.mkValidator<UserLogObjectCore>(
  UserLogObjectCoreSchema
);
export const extractUserLogObject =
  T.mkObjectExtractor<UserLogObject>(UserLogObjectSchema);
