import React from "react";
import Rootes from "./routes";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import ContainerProvider from "./components/container";
import { configLogLevel, DEBUG, INFO } from "@redwit-commons/utils/log";
import "./App.css";
import { changeEnv } from "@basalt-react-commons/services/api";

if (typeof process.env.REACT_APP_TEST_ENABLED === "string") {
  if (
    process.env.REACT_APP_TEST_ENABLED === "1" ||
    process.env.REACT_APP_TEST_ENABLED === "y" ||
    process.env.REACT_APP_TEST_ENABLED === "Y"
  ) {
    changeEnv("test");
  }
}
configLogLevel({
  ReduxComponent: DEBUG,
  ReduxComponent3: DEBUG,
  NavigationState: DEBUG,
  StateMachine2: DEBUG,
  StateMachine3: DEBUG,
  "service/user": INFO,
  service: INFO,
  FutureComponent: INFO,
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ContainerProvider>
          <Rootes />
        </ContainerProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
