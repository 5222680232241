export default {
  ko: {
    workspace_create: {
      welcome: "baSalt에 오신걸 환영합니다",
      create: {
        title: "워크스페이스를 생성해주세요",
        placeholder: "생성할 워크스페이스 이름을 작성해주세요",
        allow_search: {
          title: "워크스페이스를 공개하겠습니다",
          tooltip: "워크스페이스 공개 설정시, 다른 사용자가 검색할 수 있습니다",
        },
      },
      create_btn: "워크스페이스 생성하기",
      search_btn: "다른 워크스페이스에 참여하고 싶으신가요?",
    },
    workspace_invite: {
      title: "생성된 워크스페이스에 멤버를 초대해보세요",
      placeholder: "이메일을 입력하세요",
      send_btn: "초대메일 발송하기",
      skip_btn: "건너뛰기",
    },
    workspace_knock: {
      back_btn: "워크스페이스 검색 화면으로 가기",
      knock_btn: "워크스페이스 노크하기",
      bold_txt: "명의 멤버",
      reqular_txt: "가 이 워크스페이스에 참여중입니다",
    },
    workspace_search: {
      back_btn: "워크스페이스 생성 화면으로 돌아가기",
      title: "워크스페이스를 검색해보세요",
      placeholder: "워크스페이스 이름 검색하기",
    },
  },

  en: {
    workspace_create: {
      welcome: "Welcome to baSalt",
      create: {
        title: "Please create workspace",
        placeholder:
          "Please write down the name of the workspace you want to create",
        allow_search: {
          title: "Set workspace to public",
          tooltip:
            "When setting the workspace public, other users can search it",
        },
      },
      create_btn: "Create workspace",
      search_btn: "Do you want to participate in another workspace?",
    },
    workspace_invite: {
      title: "Invite a member to the created workspace",
      placeholder: "Enter the email",
      send_btn: "Send an invitation mail",
      skip_btn: "Skip",
    },
    workspace_knock: {
      back_btn: "Go to the workspace search screen",
      knock_btn: "Knock workspace",
      bold_txt: "",
      reqular_txt: "members are participating in this workspace",
    },
    workspace_search: {
      back_btn: "Go to the workspace create screen",
      title: "Try to search workspace",
      placeholder: "Search workspace name",
    },
  },
};
