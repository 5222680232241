import { doAPIDeleteRequest, doAPIGetRequest } from "./api";
import {
  validateOCheckAlarm,
  validateODeleteAlarm,
  validateOGetAlarmList,
} from "@basalt-commons/global-api/response/alarm";
import { AlarmObject } from "@basalt-commons/global-api/object/alarm";

const getAlarmList = async (
  token: string,
  workspaceId: string
): Promise<AlarmObject[]> => {
  const res = await doAPIGetRequest(`workspaces/${workspaceId}/alarm`, token);
  const ret = validateOGetAlarmList(res).response.results;
  return ret;
};

const checkAlarm = async (
  token: string,
  workspaceId: string,
  alarmId: string
): Promise<string> => {
  const res = await doAPIGetRequest(
    `workspaces/${workspaceId}/alarms/${alarmId}/check`,
    token
  );
  const ret = validateOCheckAlarm(res).response;
  return ret;
};

const deleteAlarm = async (
  token: string,
  workspaceId: string,
  alarmId: string
): Promise<string> => {
  const res = await doAPIDeleteRequest(
    `workspaces/${workspaceId}/alarms/${alarmId}`,
    token
  );
  const ret = validateODeleteAlarm(res).response;
  return ret;
};

export default {
  getAlarmList,
  checkAlarm,
  deleteAlarm,
};
