import React from "react";
import {
  WithStyles,
  withStyles,
  Snackbar,
  Grid,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import SpoqaHansTypography from "@SpoqaHansTypography";
import styles, {
  SnackBarIconSize,
  SnackBarPadding,
  SnackBarTextMargin,
} from "./styles/SnackBar";
import { SnackbarType } from "../../../store/reducers/snackbar";
import { ReactComponent as ConfirmIcon } from "@ui/basalt/images/snackbar/confirm.svg";
import { ReactComponent as WarningIcon } from "@ui/basalt/images/snackbar/warning.svg";
import { ReactComponent as AlertIcon } from "@ui/basalt/images/snackbar/alert.svg";

// 스낵바의 타입은 Alert / Warning / Confirm / Info / Loading이 있습니다
export enum GoonoSnackBarState {
  ALERT = "Alert",
  CONFIRM = "Confirm",
  INFO = "Info",
  LOADING = "Loading",
}

type GoonoSnackBarProps = {
  open: boolean;
  isBackdrop?: boolean;
  type?: SnackbarType | GoonoSnackBarState;
  msg: string;
  onClose: () => void;
  duration: number;
} & WithStyles<typeof styles>;

class GoonoSnackBar extends React.PureComponent<
  GoonoSnackBarProps,
  { gridWidth: number }
> {
  static defaultProps = {
    msg: "",
    onClose: () => {},
    duration: 6000,
  };
  private ref: React.RefObject<HTMLDivElement> = React.createRef();
  private _unmounted: boolean = false;
  constructor(props: GoonoSnackBarProps) {
    super(props);
    this.state = {
      gridWidth: window.innerWidth > 970 ? 700 : 400,
    };
  }

  onResize = () => {
    const width = this.ref.current
      ? this.ref.current.clientWidth
      : this.state.gridWidth;
    if (this.state.gridWidth !== width) this.setState({ gridWidth: width });
  };

  componentDidMount() {
    window.addEventListener("resize", this.onResize, true);
  }

  componentWillUnmount() {
    if (this._unmounted === true) return;
    this._unmounted = true;
    window.removeEventListener("resize", this.onResize, true);
  }

  renderAlert() {
    const { classes } = this.props;
    return (
      <Grid
        ref={this.ref}
        direction="row"
        alignItems="center"
        justifyContent="center"
        container
        item
        className={classes.render_alert_container}
      >
        <AlertIcon />
        <SpoqaHansTypography className={classes.render_alert_text} noWrap>
          {this.props.msg}
        </SpoqaHansTypography>
      </Grid>
    );
  }

  renderWarning() {
    const { classes } = this.props;
    return (
      <Grid
        ref={this.ref}
        direction="row"
        alignItems="center"
        justifyContent="center"
        container
        item
        className={classes.render_warning_container}
      >
        <WarningIcon />
        <SpoqaHansTypography className={classes.render_warning_text} noWrap>
          {this.props.msg}
        </SpoqaHansTypography>
      </Grid>
    );
  }

  renderConfirm() {
    const { classes } = this.props;
    return (
      <Grid
        ref={this.ref}
        direction="row"
        alignItems="center"
        justifyContent="center"
        container
        item
        className={classes.render_confirm_container}
      >
        <ConfirmIcon />
        <SpoqaHansTypography className={classes.render_confirm_text} noWrap>
          {this.props.msg}
        </SpoqaHansTypography>
      </Grid>
    );
  }

  renderInfo() {
    const { classes } = this.props;
    return (
      <Grid
        ref={this.ref}
        direction="row"
        alignItems="center"
        justifyContent="center"
        container
        item
        className={classes.render_info_container}
      >
        <SpoqaHansTypography
          className={classes.render_info_text}
          style={{ maxWidth: this.state.gridWidth - SnackBarPadding * 2 }}
          noWrap
        >
          {this.props.msg}
        </SpoqaHansTypography>
      </Grid>
    );
  }

  handleRef = (instance: HTMLDivElement | null) => {
    if (instance === null) return;
    this.setState({ gridWidth: instance.clientWidth });
  };

  renderLoading() {
    const { classes } = this.props;
    return (
      <Grid
        ref={this.ref}
        direction="row"
        alignItems="center"
        container
        item
        className={classes.render_loading_container}
      >
        <CircularProgress
          size={SnackBarIconSize}
          className={classes.render_loading_icon}
        />
        <SpoqaHansTypography
          className={classes.render_loading_text}
          style={{
            maxWidth:
              this.state.gridWidth -
              SnackBarIconSize -
              SnackBarTextMargin -
              SnackBarPadding * 2,
          }}
          noWrap
        >
          {this.props.msg}
        </SpoqaHansTypography>
      </Grid>
    );
  }

  renderDefault() {
    return <div />;
  }

  renderSnackBar() {
    const { type } = this.props;
    switch (type) {
      case SnackbarType.ALERT:
        return this.renderAlert();
      case SnackbarType.WARNING:
        return this.renderWarning();
      case SnackbarType.CONFIRM:
        return this.renderConfirm();
      case SnackbarType.INFO:
        return this.renderInfo();
      case SnackbarType.LOADING:
        return this.renderLoading();
      default:
        return this.renderDefault();
    }
  }

  onClose() {
    const { type } = this.props;
    switch (type) {
      case SnackbarType.INFO:
      case SnackbarType.LOADING:
        return;
      case SnackbarType.WARNING:
      case SnackbarType.ALERT:
      case SnackbarType.CONFIRM:
        return this.props.onClose();
    }
  }

  renderBackDrop() {
    const { classes } = this.props;
    return (
      <Backdrop
        style={{
          // TODO : 여기는 수정 필요. 특정 부분이 선명하게 보임.
          zIndex: 999,
          color: "#fff",
        }}
        open={this.props.open}
      >
        <Snackbar
          open={this.props.open}
          autoHideDuration={this.props.duration}
          onClose={() => {
            this.onClose();
          }}
          className={classes.snackbar}
        >
          {this.renderSnackBar()}
        </Snackbar>
      </Backdrop>
    );
  }

  render() {
    const { classes, isBackdrop } = this.props;
    if (isBackdrop !== undefined && isBackdrop === true)
      return this.renderBackDrop();
    return (
      <Snackbar
        open={this.props.open}
        autoHideDuration={this.props.duration}
        onClose={() => {
          this.onClose();
        }}
        className={classes.snackbar}
      >
        <div style={{ width: "100%" }}>{this.renderSnackBar()}</div>
      </Snackbar>
    );
  }
}

export default withStyles(styles)(GoonoSnackBar);
