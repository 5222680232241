export default {
  ko: {
    link_item: {
      options: "링크 옵션",
      view_options: "이 링크 옵션을 보기",
      created_en: "",
      created_ko: "생성됨",
      not_member: "워크스페이스 멤버가 아닙니다",
    },
  },

  en: {
    link_item: {
      options: "link option",
      view_options: "View this link options",
      created_en: "created",
      created_ko: "",
      not_member: "Not a workspace member",
    },
  },
};
