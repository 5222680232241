import T from "@redwit-commons/utils/typecheck";

/**
 * HLF transaction 수행 시 추가되는 정보
 */
export interface TXInfo {
  readonly txID: string;
  readonly txChannelID: string;
}

export const addTXInfo = (from: T): T => {
  return from.addField("txID", T.string()).addField("txChannelID", T.string());
};

export const extractTXInfo = T.mkObjectExtractor<TXInfo>(addTXInfo(T.object()));
