import T from "@redwit-commons/utils/typecheck";
import {
  ProjectEmojiInfo,
  ProjectResearchInfo,
  ProjectRoleType,
  ProjectRoleTypeList,
} from "../object/project";
import {
  ProjectAuthType,
  ProjectAuthTypeValues,
} from "../object/user_project_map";

export type ICreateProject = {
  name: string;
  /**
   * @deprecated 삭제될 필드입니다
   */
  project_type?: ProjectRoleType;
} & ProjectEmojiInfo;

export type IUpdateProject = {
  name: string;
  project_type?: ProjectRoleType;
} & ProjectResearchInfo &
  ProjectEmojiInfo;
export interface IShareProject {
  authType: Exclude<ProjectAuthType, ProjectAuthType.OWNER>;
  email?: string;
}
export interface IJoinProject {
  shareToken: string;
}
export interface IPinnedProject {
  pinned?: string;
}
export interface IDeleteJoinedUser {
  UserId: string;
  ProjectId: string;
}
export interface IShareProjectMembers {
  members: Array<{
    email: string;
    authType: Exclude<ProjectAuthType, ProjectAuthType.OWNER>;
  }>;
}

export interface IUpdateJoinedUser {
  authType: ProjectAuthType;
  userId: string;
  projectId: string;
}

export type ICheckProjectShareToken = {
  shareToken: string;
};

export const ICreateProjectSchema = T.object()
  .addField("name", T.string())
  .addField("project_type", T.string().withEnum(ProjectRoleTypeList), false)
  .addField("emoji_lbl", T.string(), false);

export const IUpdateProjectSchema = T.object()
  .addField("name", T.string())
  .addField("emoji_lbl", T.string(), false)
  .addField("researchName", T.string(), false)
  .addField("researchOwner", T.string(), false)
  .addField("researchNumber", T.string(), false)
  .addField("researchStart", T.string(), false)
  .addField("researchEnd", T.string(), false);

export const IShareProjectSchema = T.object()
  .addField("authType", T.string().withEnum(ProjectAuthTypeValues))
  .addField("email", T.string(), false);
export const IJoinProjectSchema = T.object().addField("shareToken", T.string());
export const IPinnedProjectSchema = T.object().addField(
  "pinned",
  T.string(),
  false
);
export const IDeleteJoinedUserSchema = T.object()
  .addField("UserId", T.string())
  .addField("ProjectId", T.string());
export const IShareProjectMembersSchema = T.object().addField(
  "members",
  T.array(
    T.object().addField(
      "email",
      T.string().addField(
        "authType",
        T.string().withEnum(ProjectAuthTypeValues)
      )
    )
  )
);
export const IUpdateJoinedUserSchema = T.object()
  .addField("authType", T.string().withEnum(ProjectAuthTypeValues))
  .addField("userId", T.string())
  .addField("projectId", T.string());

export const ICheckProjectShareTokenSchema = T.object().addField(
  "shareToken",
  T.string()
);

export const validateICreateProject =
  T.mkValidator<ICreateProject>(ICreateProjectSchema);
export const validateIUpdateProject =
  T.mkValidator<IUpdateProject>(IUpdateProjectSchema);
export const validateIShareProject =
  T.mkValidator<IShareProject>(IShareProjectSchema);
export const validateIJoinProject =
  T.mkValidator<IJoinProject>(IJoinProjectSchema);
export const validateIPinnedProject =
  T.mkValidator<IPinnedProject>(IPinnedProjectSchema);
export const validateIDeleteJoinedUser = T.mkValidator<IDeleteJoinedUser>(
  IDeleteJoinedUserSchema
);
export const validateIShareProjectMembers = T.mkValidator<IShareProjectMembers>(
  IShareProjectMembersSchema
);
export const validateIUpdateJoinedUser = T.mkValidator<IUpdateJoinedUser>(
  IUpdateJoinedUserSchema
);
export const validateICheckProjectShareToken =
  T.mkValidator<ICheckProjectShareToken>(ICheckProjectShareTokenSchema);
