import T from "@redwit-commons/utils/typecheck";
import { mkResponseSchema, GoonoResponse } from "../../api/response/common";
import {
  WorkspaceWithPlan,
  WorkspaceWithPlanSchema,
} from "../object/workspace";

/**
 * 500 에러: FAIL
 * 404 에러: NOT_FOUND
 * 나머지는 400 에러
 */
export enum DeleteCardErrorType {
  NOT_FOUND_CARD = "DeleteCardError::NOT_FOUND_CARD",
  // 빌링키 삭제 요청 실패
  FAIL_DELETE = "DeleteCardError::FAIL_DELETE",
}

export type OSetDefaultCard = GoonoResponse<WorkspaceWithPlan>;
export const OSetDefaultCardSchema = mkResponseSchema(WorkspaceWithPlanSchema);
export const validateOSetDefaultCard = T.mkValidator<OSetDefaultCard>(
  OSetDefaultCardSchema
);

export type ODeleteCard = GoonoResponse<string>;
export const ODeleteCardSchema = mkResponseSchema(T.string());
export const validateODeleteCard =
  T.mkValidator<ODeleteCard>(ODeleteCardSchema);
