import { Dispatch } from "redux";
import { InternalError } from "@redwit-commons/utils/exception2";
import {
  StateMachine3,
  transition,
  mkReducer,
  StateMachineAction,
} from "@redwit-react-commons/reducers/state3";
import {
  ProjectObject,
  ProjectResearchInfo,
  ProjectRoleType,
} from "@basalt-commons/api/object/project";
import { ProjectAuthType } from "@basalt-commons/api/object/user_project_map";
import { NdaObject } from "@basalt-commons/global-api/object/nda";
import { TokenLogWithUserObject } from "@basalt-commons/global-api/object/token_log";
import { IResendWorkspaceShare } from "@basalt-commons/global-api/request/workspace";

export enum ProjectStateStatus {
  INIT = "ProjectState::INIT",
  SUCCESS = "ProjectState::SUCCESS",
}

export enum ProjectActionKind {
  TRY_GET_PROJECTS = "ProjectAction::TRY_GET_PROJECTS",
  TRY_CREATE_PROJECT = "ProjectAction::TRY_CREATE_PROJECT",
  TRY_SHARE_PROJECT = "ProjectAction::TRY_SHARE_PROJECT",
  TRY_SHARE_PROJECT_EMAILS = "ProjectAction::TRY_SHARE_PROJECT_EMAILS",
  TRY_DELETE_PROJECT = "ProjectAction::TRY_DELETE_PROJECT",
  TRY_EDIT_PROJECT = "ProjectAction::TRY_EDIT_PROJECT",
  TRY_JOIN_PROJECT = "ProjectAction::TRY_JOIN_PROJECT",
  TRY_UDPATE_PROJECT_MEMBER_AUTH = "ProjectAction::TRY_UDPATE_PROJECT_MEMBER_AUTH",
  TRY_UDPATE_PROJECT_OWNER = "ProjectAction::TRY_UDPATE_PROJECT_OWNER",
  TRY_PIN_PROJECT = "ProjectAction::TRY_PIN_PROJECT",
  TRY_SET_NDA = "ProjectAction::TRY_SET_NDA",
  TRY_GET_PROJECT_SHARE_EMAIL_LIST = "ProjectAction::TRY_GET_PROJECT_SHARE_EMAIL_LIST",
  TRY_UPDATE_PROJECT_TYPE = "ProjectAction::TRY_UPDATE_PROJECT_TYPE",
  TRY_RESEND_PROJECT_SHARE_EMAIL = "ProjectAction::TRY_RESEND_PROJECT_SHARE_EMAIL",
  TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH = "ProjectAction::TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH",
}

export type ProjectError = never;

export enum ProjectErrorType {
  ONLY_OWNER = "ProjectErrorType::ONLY_OWNER",
  ONLY_ADMIN = "ProjectErrorType::ONLY_ADMIN",
  ONLY_ADMIN_OR_OWNER = "ProjectErrorType::ONLY_ADMIN_OR_OWNER",
  WORKSPACE_NOT_SELECTED = "ProjectErrorType::WORKSPACE_NOT_SELECTED",
}

export type ProjectState =
  | {
      readonly status: ProjectStateStatus.INIT;
    }
  | {
      readonly status: ProjectStateStatus.SUCCESS;
      readonly selectProject?: ProjectObject;
      readonly pinnedProjects: ProjectObject[];
      readonly projects: ProjectObject[];
      readonly shareEmailList?: TokenLogWithUserObject[];
    };

export type ProjectChangeAuthType = Exclude<
  ProjectAuthType,
  ProjectAuthType.OWNER | ProjectAuthType.RW
>;
export type IShareProjectType = {
  email: string;
  authType: ProjectChangeAuthType;
};
export type ProjectAction =
  | {
      readonly kind: ProjectActionKind.TRY_GET_PROJECTS;
      readonly id?: string; //프로젝트의 아이디
      readonly workspaceName: string;
    }
  | {
      readonly kind: ProjectActionKind.TRY_CREATE_PROJECT;
      readonly name: string;
      readonly project_type: ProjectRoleType;
      readonly externalUser: Array<{
        email: string;
        authType: ProjectChangeAuthType;
      }>;
      readonly workspaceMember: Array<{
        email: string;
        authType: ProjectChangeAuthType;
      }>;
    }
  | ({
      readonly kind: ProjectActionKind.TRY_EDIT_PROJECT;
      readonly id: string;
      readonly name: string;
    } & ProjectResearchInfo)
  | {
      readonly kind: ProjectActionKind.TRY_DELETE_PROJECT;
      readonly id: string;
    }
  | {
      readonly kind: ProjectActionKind.TRY_JOIN_PROJECT;
      readonly shareToken: string;
    }
  | {
      readonly kind: ProjectActionKind.TRY_UDPATE_PROJECT_MEMBER_AUTH;
      readonly id: string;
      readonly targets: { userId: string; authType?: ProjectChangeAuthType }[];
    }
  | {
      readonly kind: ProjectActionKind.TRY_UDPATE_PROJECT_OWNER;
      readonly projectId: string;
      readonly targetUserId: string;
    }
  | {
      readonly kind: ProjectActionKind.TRY_SHARE_PROJECT_EMAILS;
      readonly id: string;
      readonly members: IShareProjectType[];
    }
  | {
      readonly kind: ProjectActionKind.TRY_GET_PROJECT_SHARE_EMAIL_LIST;
      readonly id: string;
    }
  | {
      readonly kind: ProjectActionKind.TRY_PIN_PROJECT;
      readonly project: ProjectObject;
    }
  | {
      readonly kind: ProjectActionKind.TRY_SET_NDA;
      readonly id: string;
      readonly nda: NdaObject;
    }
  | {
      readonly kind: ProjectActionKind.TRY_UPDATE_PROJECT_TYPE;
      readonly id: string;
    }
  | {
      readonly kind: ProjectActionKind.TRY_RESEND_PROJECT_SHARE_EMAIL;
      readonly project_id: string;
      readonly args: IResendWorkspaceShare;
    }
  | {
      readonly kind: ProjectActionKind.TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH;
      readonly project_id: string;
      readonly args: Array<{
        logId: string;
        email: string;
        authType?: Exclude<ProjectAuthType, ProjectAuthType.OWNER>;
      }>;
    };

const smid = "PROJECT_STATE_MACHINE3";
export type ProjectStateMachineType = StateMachine3<
  ProjectStateStatus,
  ProjectState,
  ProjectActionKind,
  ProjectAction,
  ProjectError
>;
export const projectStateMachine: ProjectStateMachineType = new StateMachine3<
  ProjectStateStatus,
  ProjectState,
  ProjectActionKind,
  ProjectAction,
  ProjectError
>(smid, { status: ProjectStateStatus.INIT }, [
  transition(
    ProjectStateStatus.INIT,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_GET_PROJECTS
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_GET_PROJECTS
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_CREATE_PROJECT
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_EDIT_PROJECT
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_DELETE_PROJECT
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_SHARE_PROJECT_EMAILS
  ),
  transition(
    ProjectStateStatus.INIT,
    ProjectStateStatus.INIT,
    ProjectActionKind.TRY_JOIN_PROJECT
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.INIT,
    ProjectActionKind.TRY_JOIN_PROJECT
  ),

  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_UDPATE_PROJECT_MEMBER_AUTH
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_PIN_PROJECT
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_SET_NDA
  ),

  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_UDPATE_PROJECT_OWNER
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_GET_PROJECT_SHARE_EMAIL_LIST
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_UPDATE_PROJECT_TYPE
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_RESEND_PROJECT_SHARE_EMAIL
  ),
  transition(
    ProjectStateStatus.SUCCESS,
    ProjectStateStatus.SUCCESS,
    ProjectActionKind.TRY_UPDATE_PROJECT_SHARE_EMAIL_AUTH
  ),
]);

export type DispatchProjectAction = Dispatch<
  StateMachineAction<
    ProjectStateStatus,
    ProjectState,
    ProjectActionKind,
    ProjectAction,
    ProjectError
  >
>;
export default mkReducer<
  ProjectStateStatus,
  ProjectState,
  ProjectActionKind,
  ProjectAction,
  ProjectError
>(projectStateMachine);

export const doProjectAction = (
  dispatch: DispatchProjectAction,

  nextAction: ProjectAction,
  onResolve: () => void = () => {},
  onReject: (err: ProjectError | InternalError) => void = () => {}
) => {
  dispatch(projectStateMachine.newTryAction(nextAction, onResolve, onReject));
};
export const doProjectActionAsync = (
  dispatch: DispatchProjectAction,
  nextAction: ProjectAction
) => {
  return new Promise<void>((resolve, reject) => {
    dispatch(projectStateMachine.newTryAction(nextAction, resolve, reject));
  });
};
export const resetProject = (dispatch: DispatchProjectAction) => {
  dispatch(projectStateMachine.newResetAction());
};
