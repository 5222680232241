import T from "@redwit-commons/utils/typecheck";

export interface SearchParams {
  beforeAt?: string;
  afterAt?: string;
  fetchSize?: number;
  reverse?: boolean;
}

export const addSearchParams = (from: T) => {
  return from
    .addField("beforeAt", T.string(), false)
    .addField("afterAt", T.string(), false)
    .addField("fetchSize", T.integer(), false)
    .addField("reverse", T.boolean(), false);
};

export const searchParamsSchema = addSearchParams(T.object());

export const extractSearchParams =
  T.mkObjectExtractor<SearchParams>(searchParamsSchema);
