import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../store/reducers";
import GoonoSnackBar from "../components/pure/modals/SnackBar";
import {
  doSnackbarAction,
  SnackbarStateStatus,
  SnackbarActionKind,
} from "../store/reducers/snackbar";

const mapStateToProps = (state: RootState) => {
  return {
    snackbar: state.snackbar,
  };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = {};
type SnackbarsProps = PropsFromRedux & Props;
type SnackbarsState = {};
class Snackbars extends React.PureComponent<SnackbarsProps, SnackbarsState> {
  constructor(props: SnackbarsProps) {
    super(props);
  }

  render() {
    const { snackbar } = this.props;
    return (
      <GoonoSnackBar
        open={snackbar.state.status === SnackbarStateStatus.OPENED}
        type={
          snackbar.state.status === SnackbarStateStatus.OPENED
            ? snackbar.state.type
            : undefined
        }
        duration={
          snackbar.state.status === SnackbarStateStatus.OPENED
            ? snackbar.state.duration
            : undefined
        }
        msg={
          snackbar.state.status === SnackbarStateStatus.OPENED
            ? snackbar.state.msg
            : undefined
        }
        isBackdrop={
          snackbar.state.status === SnackbarStateStatus.OPENED
            ? snackbar.state.isBackdrop
            : undefined
        }
        onClose={() => {
          doSnackbarAction(this.props.dispatch, {
            kind: SnackbarActionKind.TRY_CLOSE,
          });
        }}
      />
    );
  }
}

export default connector(Snackbars);
