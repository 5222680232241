export default {
  ko: {
    landing_solution: {
      banner_text: "\n문서 관리,\n한 큐에 끝내세요",
      banner_changing_text: [
        "투자 유치 시",
        "기업 간의 협력에서",
        "아이디어 및 기술 보호에서",
        "팀 내부적 문서 관리에서",
      ],
      banner_button: "지금 시작하기",
      person_title: "Harry, CSO of start-up",
      investment_person_review_headers:
        "바솔트를 이용하면서 <highlight>투자 유치<highlight>에 많은 <highlight>인사이트를<highlight> 얻을 수 있었죠!",
      cooperation_person_review_headers:
        "문서 보안 계약서와 파일 <highlight>공유<highlight>를 한 번에 진행할 수 있어서 편리합니다",
      idea_person_review_headers:
        "업로드 한 파일에 대한 블록체인 <highlight>인증서<highlight>로 <highlight>특허 가출원<highlight>까지 할 수 있다는게 놀라워요!",
      file_person_review_headers:
        "필수적으로 기록해야 하는 로그를 baSalt가 <highlight>자동<highlight>으로 기록해주니 일을 2번할 필요가 없어요!",
      investment_person_review_text:
        "“ 바솔트는 투자, 기금, 기술 자료 및 데이터와 문서를 빠르고 안전하게 공유하는 효과적인 도구입니다. 바솔트의 인사이트 리포트를 이용하면 파트너나 투자사의 관심과 잠재적인 니즈를 이해하는데 특히 유용합니다 ”",

      start_text: "더 많은 기능이 궁금하다면?",
      start_button: "지금 시작하기",
      card_section_header:
        "문서 관리에 대한 어떤 고민이든, baSalt가 해결합니다",
      cards: [
        {
          title: "드래그 앤 드랍 업로드하고,3초 블록체인 인증까지",
          highlight: "드래그 앤 드랍",
          text: "간편한 업로드 방식과 쉽고 블록체인으로 빠르고 안전하게 인증받을 수 있습니다.",
        },
        {
          title: "팀원 별 권한 설정을 통해, 세분화 된 문서 접근 관리",
          highlight: "권한 설정",
          text: "팀원 별로 세분화된 권한 설정을 통해 문서 보안을 강화할 수 있습니다.",
        },
        {
          title: "태그, OCR을 활용한 손쉬 운 문서 검색",
          highlight: ["태그", "OCR"],
          text: "문서에 대한 OCR을 제공하며, 태그를 이용하여 문서 검색 및 관리할 수 있습니다.",
        },
        {
          title: "NDA 및 옵션 설정을 통한 외부 공유 링크 생성",
          highlight: "옵션 설정",
          text: "다운로드, 프린트, NDA 비밀유지협약 등과 같은 다양한 공유 옵션을 설정하여 외부 사용자에게 더 안전하게 공유할 수 있습니다.",
        },
        {
          title: "링크에 대한 방문자 정보 및 인사이트 리포트 제공",
          highlight: "인사이트 리포트",
          text: "방문자 수, 페이지별 체류 시간에 대한 통계, 방문자 정보 등과 같은 공유 링크에 대한 다양한 리포트를 제공합니다.",
        },
        {
          title: "문서 열람/삭제/다운로드/공유 기록을 자동으로 수집",
          highlight: "자동으로 수집",
          text: "워크스페이스 내의 팀원들의 문서에 대한 활동 기록을 자동으로 수집하고, 이를 PDF로 추출할 수 있습니다.",
        },

        {
          title: "워크스페이스 코멘트를 이용한 협업 관리 증진",
          highlight: "코멘트",
          text: "파일 내부에서 팀원끼리 코멘트를 주고 받으면서 협업을 증진시킬 수 있습니다.",
        },

        {
          title: "외부 서비스 연동을 통한, 더 편리한 문서 업로드",
          highlight: "외부 서비스 연동",
          text: "외부 서비스 연동으로 더 쉽게 파일 업로드를 수행할 수 있도록 업데이트 될 예정입니다.",
        },

        {
          title: "워크스페이스 관리자를 위한 월간 리포트 제공",
          highlight: "워크스페이스 관리자",
          text: "워크스페이스 관리자에게 월간 리포트 제공 서비스를 업데이트 할 예정입니다.",
        },
      ],

      video_view: [
        {
          tab: "투자 유치 시",
          tab_xs: "투자 시",
          title:
            "IR 덱, 서비스 소개서 등과 같은 문서를 공유하고,\n어느 부분이 가장 많은 관심을 가졌는지 한 눈에 파악하세요!",
          text: "업로드한 파일에 대해 다운로드, 프린트, NDA 첨부 등과 같은 뷰어 옵션을 선택하여 공유 링크를 생성할 수 있습니다.\n파일을 열람하는 사용자에 대한 인사이트 및 정보에 대한 그래프를 실시간으로 확인할 수 있습니다.",
        },

        {
          tab: "기업 간의 협력에서",
          tab_xs: "협업 시",
          title:
            "NDA 첨부를 통해, 외부의 문서 공유 시 보안을\n한 층 더 강화하세요",
          text: "NDA 첨부를 통하여 문서 열람에 대한 보안 정책을 추가할 수 있고, 공유받은 사용자로부터 전자 서명을 받을 수 있습니다.\n기업 비밀, 협약 내용 등과 같이 외부 기업과의 문서 공유에 대해 보호를 하세요!",
        },

        {
          tab: "아이디어 및 기술 보호",
          tab_xs: "기술 보호",
          title:
            "블록체인 인증으로 파일을 보관하여\n아이디어, 기술과 업무의 과정을 손쉽게 증명할 수 있습니다!",
          text: "업로드한 파일은 즉시 블록체인에 저장되기 때문에, 해당 파일의 업로드 시점, 파일의 원본 진위 여부 등과 같은\n과정에 대한 법적 증명을 바로 할 수 있습니다.",
        },

        {
          tab: "팀 내부적 문서 관리",
          tab_xs: "문서 관리",
          title:
            "문서의 생성, 삭제, 열람, 공유 모든 내역을\n자동으로 기록하고, 관리하세요!",
          text: "워크스페이스 내부의 문서에 대한 열람, 다운로드, 삭제, 공유 등과 같은 활동들을 실시간으로 기록하고,\n이를 직관적인 형태의 타임라인으로 제공합니다. 제공된 타임라인은 날짜별, 팀원별, 활동 별로 필터링하여 확인할 수 있습니다.",
        },
      ],
    },
  },
  en: {
    landing_solution: {
      banner_text: "\nGet Started\nbaSalt Docs\nRight Now!",
      banner_changing_text: [
        "For attracting\ninvestment",
        "For flexible\ncollaborative works",
        "For protecting\nconfidential data ",
        "For streamlined\ninternal workflow",
      ],
      banner_button: "Start now",
      person_title: "Harry, CSO of start-up",
      investment_person_review_headers:
        "Remote work environments doesn’t matter at all for team collaboration",
      cooperation_person_review_headers:
        "It is very convenient since it enables us to proceed security contract and file haring simultaneously",
      idea_person_review_headers:
        "It is amazing that we can apply a provisional patentwith the block chain verification for the uploaded files!",
      file_person_review_headers:
        "The necessary logs do not have to done twice since baSalt automatically takes care of them",
      investment_person_review_text: `"Actually, I introduced baSalt with half a doubt at first. But our teams and I realized in a few weeks that it is not only easy & fast for sharing files among team members, but also safe because of the blockchain technology. Despite the physical distance between team members in remote work environments, we could build an integrated workflow with baSalt Docs.”`,
      start_text: "Wanna Get More?",
      start_button: "Free Trial Right Now",
      card_section_header:
        "baSalt Docs has everything you need for file management",
      cards: [
        {
          title:
            "Upload with drag and drop and store in the blockchain within 3 seconds!",
          highlight: "drag and drop",
          text: "You can easily upload files and immediately get secure blockchain authentication.",
        },
        {
          title:
            "Control your members' accesses to files by setting permissions for each one!",
          highlight: "Control",
          text: "File security can be enhanced by enabling granular permission settings for each member.",
        },
        {
          title: "Search easily for files of any format by OCR and tags!",
          highlight: ["tags", "OCR"],
          text: "Any file in any format can be searched using OCR and tags.",
        },
        {
          title:
            "Create a sharing-link with setting various link accessible options!",
          highlight: "options",
          text: "You can share files more securely by setting various link accessible options like download, print, attach NDA, and more.",
        },
        {
          title:
            "Use an report which records all activities of who have accessed sharing-links!",
          highlight: "sharing-links",
          text: "You can download and utilize reports that contain information including the number of link visitors and duration of stay by page.",
        },
        {
          title: "Check simply all records for all files on baSalt Docs!",
          highlight: "all records",
          text: "You can check all records of creation, deletion, sharing, viewing and downloading etc. for files by member/activity.",
        },

        {
          title: "Increase collaborative productivity with comment features!",
          highlight: "collaborative",
          text: "Workspace members can exchange comments on specific files and enhance collaborative productivity.",
        },

        {
          title: "Integrate with the tools you already use!",
          highlight: "Integrate",
          text: "You can integrate with the familiar tools and build and integrated workflow.",
        },

        {
          title: "Plan future works systematically with monthly reports!",
          highlight: "monthly reports!",
          text: "Workspace administrators can use monthly reports for planning future works.",
        },
      ],

      video_view: [
        {
          tab: "For attracting investment",
          tab_xs: "For Invest",
          title:
            "Share data with investors including IR Deck and Service Introduction,\nand see at a glance what they are most interested in!",
          text: "About uploaded files, you can create sharing-links by setting options like\ndownload, print, attach NDA, and more.\nYou can view real-time insights and information about users who browse the links in graph form. ",
        },

        {
          tab: "For flexible collaborative works",
          tab_xs: "Cooperation",
          title:
            "Create an NDA with one click and attach it\nwhen share a file with others to further enhance file security!",
          text: "You can set up the sharing-link to be accessible only when the other party signs the NDA.\nUse it to share confidential documents externally to enhance file security!",
        },

        {
          tab: "For protecting confidential data and technology",
          tab_xs: "Protect idea",
          title:
            "Protect your valuable ideas and skills with double encryption\nand blockchain storing of uploaded files!",
          text: "Once you upload a file, it is stored in the blockchain with double encryption,\nso you can block file leakage.\nIn addition, you can prove the uploaded time and the authenticity of the original file\nwith the blockchain certificate.",
        },

        {
          tab: "For streamlined internal workflow",
          tab_xs: "Manage files",
          title:
            "Check all details of creation, deletion, sharing, viewing\nand downloading of files at a glance and get insights!",
          text: "All activities such as viewing, downloading, deleting, and sharing files inside the workspace\nare recorded in real time.\nYou can see this as an intuitive report and filter by date/member/activity.",
        },
      ],
    },
  },
};
