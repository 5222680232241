import {
  mkResponseSchema,
  GoonoResponse,
  GoonoPendingTasks,
  GoonoArrayResponse,
  mkArrayResultsSchema,
} from "./common";
import { UserObject, UserObjectSchema } from "../object/user";
import T from "@redwit-commons/utils/typecheck";
import {
  LicenseObject,
  LicenseObjectSchema,
  LicensePureObjectWDescriptionSchema,
  LicensePureObjectWDescription,
} from "../object/license";
import {
  LicenseRoleTypes,
  LicenseRoleTypesValues,
} from "../object/user_license_map";

export type OLogin = GoonoResponse<{
  needRegister: boolean;
  token: string;
  errorMessage?: string;
  platform?: string;
}>;

export type OLoginWithEmail = GoonoResponse<{
  needRegister: boolean; // no error, no user -> true, if error -> false
  token: string; // given only when email verified & valid password, else empty string
  errorMessage?: string;
}>;

export enum emailLoginErrorType {
  INVALID_PASSWORD = "invalid_password",
  NOT_VERIFIED = "not_verified",
  SEND_MAIL_AGAIN = "send_mail_again",
  NOT_REGISTERED = "not_registered",
}

export enum snsLoginErrorType {
  INCORRECT_PLATFORM = "incorrect platform",
}

export enum checkRegisterErrorType {
  ALREADY_REGISTER = "User Already Exists",
  TOKEN_EXPIRED = "Email token is expired",
  NEED_VERIFY = "Email verify need",
  ALREADY_VERIFIED = "Email verified but not registered",
}

export const OLoginSchema = mkResponseSchema(
  T.object()
    .addField("needRegister", T.boolean())
    .addField("token", T.string())
    .addField("errorMessage", T.string(), false)
    .addField("platform", T.string(), false)
);

export const OLoginWithEmailSchema = mkResponseSchema(
  T.object()
    .addField("needRegister", T.boolean())
    .addField("token", T.string())
    .addField("errorMessage", T.string(), false)
);

export type OCheckRegistered = GoonoResponse<{
  available: boolean;
  message?: string;
  platform?: string;
  url?: string;
}>;

export const OCheckRegisteredSchema = mkResponseSchema(
  T.object()
    .addField("available", T.boolean())
    .addField("message", T.string(), false)
    .addField("platform", T.string(), false)
    .addField("url", T.string(), false)
);

export const validateOCheckRegistered = T.mkValidator<OCheckRegistered>(
  OCheckRegisteredSchema
);

export const validateOLogin = T.mkValidator<OLogin>(OLoginSchema);

export const validateOLoginWithEmail = T.mkValidator<OLoginWithEmail>(
  OLoginWithEmailSchema
);

export type OEmailVerify = GoonoResponse<{ result: string }>;

export const OEmailVerifySchema = mkResponseSchema(
  T.object().addField("result", T.string())
);

export type ORegister = GoonoResponse<{ token: string }> &
  GoonoPendingTasks<{
    hlf_create_user: string;
    hlf_create_project: string;
    hlf_share_project?: string;
  }>;

export type ORegisterWithEmail = GoonoResponse<{ token: string }> &
  GoonoPendingTasks<{
    hlf_create_user?: string;
    hlf_create_project?: string;
    hlf_share_project?: string;
    email_verification: string;
  }>;
// OGetAllLicense 라이센스 없을 경우 empty array return
export type OGetAllLicense = GoonoArrayResponse<LicenseObject>;
export const OGetAllLicenseSchema = mkArrayResultsSchema(LicenseObjectSchema);
export const validateOGetAllLicense =
  T.mkValidator<OGetAllLicense>(OGetAllLicenseSchema);
// OGetLicense 라이센스 없을 경우 throw 발생
export type OGetLicense = GoonoResponse<
  LicensePureObjectWDescription & { role: LicenseRoleTypes; occupied: number }
>;
export const OGetLicenseSchema = mkResponseSchema(
  LicensePureObjectWDescriptionSchema.clone()
    .addField("role", T.string().withEnum(LicenseRoleTypesValues))
    .addField("occupied", T.number())
);
export const validateOGetLicense =
  T.mkValidator<OGetLicense>(OGetLicenseSchema);
// export type ORegister = GoonoResponse<{
//   token: string,  // goono_token
// }>
export const ORegisterSchema = mkResponseSchema(
  T.object().addField("token", T.string()),
  T.object()
    .addField("hlf_create_user", T.string())
    .addField("hlf_create_project", T.string())
    .addField("hlf_share_project", T.string(), false)
);

export const ORegisterWithEmailSchema = mkResponseSchema(
  T.object().addField("token", T.string()),
  T.object()
    .addField("hlf_create_user", T.string(), false)
    .addField("hlf_create_project", T.string(), false)
    .addField("hlf_share_project", T.string(), false)
    .addField("email_verification", T.string())
);

export type OProfile = GoonoResponse<UserObject>;

export const OProfileSchema = mkResponseSchema(UserObjectSchema);

export const validateORegister = T.mkValidator<ORegister>(ORegisterSchema);

export const validateORegisterWithEmail = T.mkValidator<ORegisterWithEmail>(
  ORegisterWithEmailSchema
);

export const validateOProfile = T.mkValidator<OProfile>(OProfileSchema);

export const validateOProfileUpdate = T.mkValidator<OProfile>(OProfileSchema);

export type OProfileUpdate = GoonoResponse<UserObject>;

export const OProfileUpdateSchema = mkResponseSchema(UserObjectSchema);

export type ODeleteAccount = GoonoResponse<UserObject>;
export const ODeleteAccountSchema = mkResponseSchema(UserObjectSchema);

export type OInviteLicenseCode = GoonoResponse<{ code: string }>;
export const OInviteLicenseCodeSchema = mkResponseSchema(
  T.object().addField("code", T.string())
);
export const validateOInviteLicenseCode = T.mkValidator<OInviteLicenseCode>(
  OInviteLicenseCodeSchema
);

export type OJoinLicenseCode = GoonoResponse<{ isJoined: boolean }>;
export const OJoinLicenseCodeSchema = mkResponseSchema(
  T.object().addField("isJoined", T.boolean())
);
export const validateOJoinLicenseCode = T.mkValidator<OJoinLicenseCode>(
  OJoinLicenseCodeSchema
);

export type OAuthenticationPW = GoonoResponse<{ url?: string }> &
  GoonoPendingTasks<{
    email_verification: string;
  }>;

export const OAuthenticationPWSchema = mkResponseSchema(
  T.object().addField("url", T.string(), false),
  T.object().addField("email_verification", T.string())
);
export const validateOAuthenticationPW = T.mkValidator<OAuthenticationPW>(
  OAuthenticationPWSchema
);

export type OUpdatePW = GoonoResponse<{
  updated: boolean;
  errMSG?: string;
}>;
export const OUpdatePWSchema = mkResponseSchema(
  T.object()
    .addField("updated", T.boolean())
    .addField("errMSG", T.string(), false)
);
export const validateOUpdatePW = T.mkValidator<OUpdatePW>(OUpdatePWSchema);
