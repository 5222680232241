import { doAPIPostRequest, doAPIGetRequest } from "./api";
import {
  ILogin,
  IRegister,
  IProfileUpdate,
  ILoginWithEmail,
  IRegisterWithEmail,
  ICheckRegistered,
  IInviteLicenseCode,
  IJoinLicenseCode,
  IAuthenticationPW,
  IUpdatePW,
} from "@basalt-commons/api/request/user";
import {
  OLogin,
  validateOLogin,
  ORegister,
  validateORegister,
  OProfile,
  validateOProfile,
  validateOProfileUpdate,
  ORegisterWithEmail,
  validateOLoginWithEmail,
  OLoginWithEmail,
  validateORegisterWithEmail,
  validateOCheckRegistered,
  OCheckRegistered,
  validateOGetLicense,
  validateOInviteLicenseCode,
  validateOJoinLicenseCode,
  validateOAuthenticationPW,
  validateOUpdatePW,
  OUpdatePW,
  OAuthenticationPW,
} from "@basalt-commons/api/response/user";
import { LicensePureObjectWDescription } from "@basalt-commons/api/object/license";
import { LicenseRoleTypes } from "@basalt-commons/api/object/user_license_map";
import { getLoggers } from "@redwit-commons/utils/log";
import { InternalErrorKind, isErr } from "@redwit-commons/utils/exception2";
import { ISendAuthMail } from "@basalt-commons/global-api/request/user";
import {
  OBasaltLogin,
  OGlobalRegister,
  OSendAuthMail,
  validateOBasaltLogin,
  validateOGlobalRegister,
  validateOSendAuthMail,
} from "@basalt-commons/global-api/response/user";

const { WARN, INFO } = getLoggers("service/user");

export enum goonoLoginType {
  SNS = "ILoginType::SNS",
  EMAIL = "ILoginType::EMAIL",
}

export type goonoLoginResult = {
  needRegister: boolean;
  token: string;
  errorMessage?: string;
  platform?: string;
};

export type GoonoLoginArgs =
  | {
      type: goonoLoginType.EMAIL;
      args: ILoginWithEmail;
    }
  | {
      type: goonoLoginType.SNS;
      args: ILogin;
    };

const goonoEmailCheckRegister = async (
  args: ICheckRegistered
): Promise<OCheckRegistered> => {
  const ret = await doAPIPostRequest("user/register/check", undefined, args);
  return validateOCheckRegistered(ret);
};

/**
 * Goono 서버 로그인 시 SNS를 이용
 * @param args ILogin
 * @returns Promise<OLogin>
 */
const goonoLoginWithSNS = async (args: ILogin): Promise<OLogin> => {
  const ret = await doAPIPostRequest("user/login", undefined, args);
  return validateOLogin(ret);
};

/**
 * Goono 서버 로그인 시 Email을 이용
 * @param args ILoginWithEmail
 * @returns Promise<OLoginWithEmail>
 */
const goonoLoginWithEmail = async (
  args: ILoginWithEmail
): Promise<OLoginWithEmail> => {
  const ret = await doAPIPostRequest("user/login/email", undefined, args);
  return validateOLoginWithEmail(ret);
};

/**
 * Goono 서버 로그인
 * @param args GoonoLoginArgs
 * @returns Promise<goonoLoginResult>
 */
const goonoLogin = async (args: GoonoLoginArgs): Promise<goonoLoginResult> => {
  switch (args.type) {
    case goonoLoginType.EMAIL: {
      /* Email Login */
      const ret = await goonoLoginWithEmail(args.args);
      return ret.response;
    }
    default: {
      const ret = await goonoLoginWithSNS(args.args);
      return ret.response;
    }
  }
};

export type GoonoRegisterArgs =
  | {
      type: goonoLoginType.EMAIL;
      args: IRegisterWithEmail;
    }
  | {
      type: goonoLoginType.SNS;
      args: IRegister;
    };

export type goonoRegisterResult = {
  token: string;
};

const goonoRegisterWithSNS = async (args: IRegister): Promise<ORegister> => {
  const ret = await doAPIPostRequest("user/register", undefined, args);
  return validateORegister(ret);
};

const goonoReigsterWithEmail = async (
  args: IRegisterWithEmail
): Promise<ORegisterWithEmail> => {
  const ret = await doAPIPostRequest("user/register/email", undefined, args);
  return validateORegisterWithEmail(ret);
};

/**
 * Goono 서버 회원가입
 * @param args IRegsiter
 * @returns Promise<goonoRegisterResult>
 */
const goonoRegister = async (
  args: GoonoRegisterArgs
): Promise<goonoRegisterResult> => {
  switch (args.type) {
    case goonoLoginType.EMAIL: {
      const ret = await goonoReigsterWithEmail(args.args);
      return ret.response;
    }
    default: {
      const ret = await goonoRegisterWithSNS(args.args);
      return ret.response;
    }
  }
};

/**
 * Goono 서버 프로필 정보
 * @param goonoToken
 * @returns Promise<OProfile>
 */
const goonoProfile = async (goonoToken: string): Promise<OProfile> => {
  const ret = await doAPIGetRequest("user/profile", goonoToken);
  return validateOProfile(ret);
};

/**
 * Goono 서버 회원 정보 수정
 * @param goonoToken string, 구노 토큰
 * @param args IProfileUpdate
 * @returns Promise<OProfileUpdate>
 */
const goonoProfileUpdate = async (
  goonoToken: string,
  args: IProfileUpdate
): Promise<OProfile> => {
  const ret = await doAPIPostRequest("user/profile/update", goonoToken, args);
  // Note: Type Validation Here
  return validateOProfileUpdate(ret);
};

type LicensePlan = LicensePureObjectWDescription & {
  role: LicenseRoleTypes;
  occupied: number;
};

const goonoLicense = async (
  goonoToken: string
): Promise<LicensePlan | undefined> => {
  try {
    const ret = await doAPIGetRequest("user/license", goonoToken, undefined);
    return validateOGetLicense(ret).response;
  } catch (err) {
    if (isErr(err)) {
      if (
        err.kind === InternalErrorKind.ResponseCode &&
        err.code >= 400 &&
        err.code < 500
      ) {
        INFO("GUEST user", err.code);
        return undefined;
      }
      WARN("라이센스 가져오는 도중 에러 발생", err);
    }
    throw err;
  }
};
/**
 * 라이센스 정보에 초대코드가 없는경우, 초대 코드 생성을 요청하는 함수입니다.
 * @param token
 * @param licenseID
 * @param args IInviteLicenseCode
 */
const goonoInviteLicense = async (
  token: string,
  licenseID: string,
  args: IInviteLicenseCode
): Promise<string> => {
  const ret = validateOInviteLicenseCode(
    await doAPIPostRequest(`user/${licenseID}/share`, token, args)
  );
  return ret.response.code;
};
/**
 * 라이센스 초대 참여
 * @param token
 * @param args IJoinLicenseCode
 */
const goonoJoinLicense = async (
  token: string,
  args: IJoinLicenseCode
): Promise<boolean> => {
  try {
    const ret = validateOJoinLicenseCode(
      await doAPIPostRequest(`user/join/license`, token, args)
    );
    return ret.response.isJoined;
  } catch (err) {
    if (isErr(err)) {
      if (err.kind === InternalErrorKind.ResponseCode && err.code === 600) {
        INFO("1인 1라이센스 정책 위반", err.code);
        return false;
      }
      WARN("라이센스 참여 도중 에러 발생", err);
    }
    throw err;
  }
};
/**
 * 비밀번호 변경 요청에 대한 인증 메일 발송 함수입니다.
 * @param args IAuthenticationPW
 */
const goonoAuthenticationPW = async (
  args: IAuthenticationPW
): Promise<OAuthenticationPW> => {
  const ret = validateOAuthenticationPW(
    await doAPIPostRequest("user/authentication/password", undefined, args)
  );
  return ret;
};
/**
 * 비밀번호 업데이트 API
 * @param args IUpdatePW
 */
const goonoUpdatePW = async (args: IUpdatePW): Promise<OUpdatePW> => {
  const ret = validateOUpdatePW(
    await doAPIPostRequest("user/update/password", undefined, args)
  );
  return ret;
};

/**
 * A function that checks if register is available.
 * @param args ICheckRegistered
 * @deprecated 삭제될 API 입니다
 */
const globalEmailCheckRegister = async (
  args: ICheckRegistered
): Promise<OCheckRegistered> => {
  const ret = await doAPIPostRequest(
    "global/user/register/check",
    undefined,
    args
  );
  return validateOCheckRegistered(ret);
};
/**
 * A function that requests sending authentication mail.
 * @param args ISendAuthMail
 * @deprecated 삭제될 API 입니다
 */
const sendAuthEmail = async (args: ISendAuthMail): Promise<OSendAuthMail> => {
  const ret = await doAPIPostRequest(
    "global/user/send/auth_mail",
    undefined,
    args
  );
  return validateOSendAuthMail(ret);
};
/**
 * A Function to request user registration
 * @param args IRegisterWithEmail
 * @deprecated 삭제될 API 입니다
 */
const globalReigsterWithEmail = async (
  args: IRegisterWithEmail
): Promise<OGlobalRegister> => {
  const ret = await doAPIPostRequest(
    "global/user/register/email",
    undefined,
    args
  );
  return validateOGlobalRegister(ret);
};
/**
 * baSalt 비밀번호 변경 요청 API
 * @param args
 */
const globalAuthenticationPW = async (
  args: IAuthenticationPW
): Promise<OAuthenticationPW> => {
  const ret = await doAPIPostRequest(
    "global/user/authentication/password",
    undefined,
    args
  );
  return validateOAuthenticationPW(ret);
};
/**
 * baSalt 비밀번호 변경 API
 * @param args
 */
const globalUpdatePW = async (args: IUpdatePW): Promise<OUpdatePW> => {
  const ret = validateOUpdatePW(
    await doAPIPostRequest("global/user/update/password", undefined, args)
  );
  return ret;
};
/**
 * baSalt 가입 확인 V2
 * @param args
 */
const basaltEmailCheckRegister = async (
  args: ICheckRegistered
): Promise<OCheckRegistered> => {
  const ret = validateOCheckRegistered(
    await doAPIPostRequest("basalt/user/register/check", undefined, args)
  );
  return ret;
};
/**
 * baSalt 이메일 가입 V2
 * @param args
 */
const basaltEmailRegister = async (
  args: IRegisterWithEmail
): Promise<ORegisterWithEmail> => {
  const ret = validateORegisterWithEmail(
    await doAPIPostRequest("basalt/user/register/email", undefined, args)
  );
  return ret;
};
/**
 * baSalt SNS 가입
 * @param args
 */
const basaltSNSRegister = async (args: IRegister): Promise<ORegister> => {
  const ret = validateORegister(
    await doAPIPostRequest("basalt/user/register/sns", undefined, args)
  );
  return ret;
};
/**
 * baSalt 서버 로그인 시 SNS를 이용
 * @param args ILogin
 * @returns Promise<OBasaltLogin>
 */
const basaltLoginWithSNS = async (args: ILogin): Promise<OBasaltLogin> => {
  const ret = await doAPIPostRequest("basalt/user/login/sns", undefined, args);
  return validateOBasaltLogin(ret);
};

/**
 * baSalt 서버 로그인 시 Email을 이용
 * @param args ILoginWithEmail
 * @returns Promise<OBasaltLogin>
 */
const basaltLoginWithEmail = async (
  args: ILoginWithEmail
): Promise<OBasaltLogin> => {
  const ret = await doAPIPostRequest(
    "basalt/user/login/email",
    undefined,
    args
  );
  return validateOBasaltLogin(ret);
};

/**
 * Goono 서버 로그인
 * @param args GoonoLoginArgs
 * @returns Promise<OBasaltLogin>
 */
const basaltLogin = async (args: GoonoLoginArgs): Promise<string> => {
  switch (args.type) {
    case goonoLoginType.EMAIL: {
      /* Email Login */
      const ret = await basaltLoginWithEmail(args.args);
      return ret.response.token;
    }
    default: {
      const ret = await basaltLoginWithSNS(args.args);
      return ret.response.token;
    }
  }
};

export default {
  goonoEmailCheckRegister,
  goonoLogin,
  goonoRegister,
  goonoProfile,
  goonoProfileUpdate,
  goonoLicense,
  goonoInviteLicense,
  goonoJoinLicense,
  goonoAuthenticationPW,
  goonoUpdatePW,
  /** Global Service API */
  globalEmailCheckRegister,
  sendAuthEmail,
  globalReigsterWithEmail,
  globalAuthenticationPW,
  globalUpdatePW,
  /** basalt register API V2 */
  basaltEmailCheckRegister,
  basaltEmailRegister,
  basaltSNSRegister,
  basaltLogin,
};
