import {
  makeStyles,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
} from "@material-ui/core";
import SpoqaHansTypography from "@SpoqaHansTypography";
import { theme } from "@theme";
import React, { useState } from "react";
import SidebarDropDownMenuView from "../components/pure/menus/SidebarDropDownMenu";
import SidebarDrawerTitle from "../components/pure/lists/items/SidebarDrawerTitle";
import translate from "../utils/translate";
import clsx from "clsx";
import { ActivityType, FilterActivityKind } from "../utils/data/LogActionType";
import { getIPFSUrl } from "@basalt-react-commons/services/ipfs";

const useSidebarRadioGroupViewStyles = makeStyles(() => ({
  radio_menu_item_layout: {
    marginLeft: 20,
  },
  radio_menu_item_text: {
    ...theme.font14,
    color: theme.gray_7_text,
  },
  radio_root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  radio_icon: {
    border: `0.1px solid ${theme.gray_2}`,
    borderRadius: "50%",
    width: 20,
    height: 20,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  },
  radio_icon_checked: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    backgroundColor: "transparent",
    "&:before": {
      display: "block",
      marginTop: 3,
      marginLeft: 3,
      width: 14,
      height: 14,
      borderRadius: "50%",
      backgroundColor: theme.indigo_5,
      content: '""',
    },
  },
}));

type SidebarRadioGroupViewProps = {
  group_name: string;
  value: string | undefined;
  items: Array<{ value: string; label?: string; labelElement?: JSX.Element }>;
  handleChange: (value: string | undefined) => void;
};

const SidebarRadioGroupView = ({
  group_name,
  value,
  items,
  handleChange,
}: SidebarRadioGroupViewProps) => {
  const classes = useSidebarRadioGroupViewStyles();
  const defaultItem = { value: "All", label: "All" };

  const handleRadioChange = (value: string) => {
    handleChange(value === defaultItem.value ? undefined : value);
  };

  const defaultLabelElement = (label: string) => {
    return (
      <SpoqaHansTypography className={classes.radio_menu_item_text}>
        {label}
      </SpoqaHansTypography>
    );
  };

  const radioMenuItem = ({
    index,
    value,
    label,
    labelElement = defaultLabelElement(label ?? value),
  }: {
    index?: number;
    value: string;
    label?: string;
    labelElement?: JSX.Element;
  }) => {
    return (
      <div key={index} className={classes.radio_menu_item_layout}>
        <FormControlLabel
          value={value}
          control={
            <Radio
              color="default"
              icon={<span className={clsx(classes.radio_icon)} />}
              checkedIcon={
                <span
                  className={clsx(
                    classes.radio_icon,
                    classes.radio_icon_checked
                  )}
                />
              }
              className={classes.radio_root}
            />
          }
          label={labelElement}
        />
      </div>
    );
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label={group_name}
        name={group_name}
        value={value ?? defaultItem.value}
        onChange={(evt) => handleRadioChange(evt.target.value)}
      >
        {radioMenuItem({ value: defaultItem.value })}
        {items.map((item, index) => radioMenuItem({ index: index, ...item }))}
      </RadioGroup>
    </FormControl>
  );
};

const useSidebarRadioMemberGroupViewStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
  },
  item_text: {
    ...theme.font14,
    color: theme.gray_7_text,
    maxWidth: 118,
  },
  profile: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    objectFit: "contain",
    marginRight: 6,
  },
}));

const SidebarRadioMemberGroupView = ({
  members,
  ...rest
}: {
  members: Array<MemberType>;
} & Omit<SidebarRadioGroupViewProps, "items">) => {
  const classes = useSidebarRadioMemberGroupViewStyles();

  const memberLabelElement = (member: MemberType) => {
    return (
      <div className={classes.root}>
        <Avatar
          alt="profile"
          src={getIPFSUrl(member.profile_cid)}
          className={classes.profile}
        />
        <SpoqaHansTypography noWrap className={classes.item_text}>
          {member.name}
        </SpoqaHansTypography>
      </div>
    );
  };

  const makeItem = (member: MemberType) => {
    return {
      label: member.name,
      value: member.id,
      labelElement: memberLabelElement(member),
    };
  };

  return (
    <SidebarRadioGroupView {...rest} items={members.map((m) => makeItem(m))} />
  );
};

const useStyles = makeStyles(() => ({
  root: { overflowY: "auto" },
  reset_button: {
    width: 70,
    height: 22,
    padding: "2px 8px",
    borderRadius: 8,
    backgroundColor: theme.gray_2,
    position: "absolute",
    right: 16,
    border: `1px solid ${theme.gray_4}`,
  },
  reset_button_text: {
    color: theme.indigo_6,
    ...theme.font12,
  },
  reset_button_disabled_text: {
    color: theme.gray_4,
  },
}));

type MemberType = {
  id: string;
  name: string;
  profile_cid: string;
};

type ActivityDrawerInnerComponentProps = {
  members: Array<MemberType>;
  activity_type?: ActivityType;
  userId?: string;
  setActivity: (activity_type?: any, userId?: string) => void;
};

const ActivityDrawerInnerComponent = ({
  members,
  activity_type,
  userId,
  setActivity,
}: ActivityDrawerInnerComponentProps) => {
  const classes = useStyles();
  const [activityFilter, setActivityFilter] = useState<string | undefined>(
    activity_type
  );
  const [memberFilter, setMemberFilter] = useState<string | undefined>(userId);

  const handleActivityChange = (value: string | undefined) => {
    setActivityFilter(value);
    setActivity(value, memberFilter);
  };

  const handleMemberChange = (value: string | undefined) => {
    setMemberFilter(value);
    setActivity(activityFilter, value);
  };

  const activityItems = Object.values(FilterActivityKind).map((a) => {
    return { value: a };
  });

  const renderDrawerTitle = () => {
    const resetButtonDisabled =
      activityFilter === undefined && memberFilter === undefined;
    const resetFilter = () => {
      setActivityFilter(undefined);
      setMemberFilter(undefined);
      setActivity();
    };
    return (
      <SidebarDrawerTitle
        title={translate.main_sidebar.activities.title}
        rightIcon={
          <Button
            onClick={(evt) => {
              evt.stopPropagation();
              resetFilter();
            }}
            disabled={resetButtonDisabled}
            className={classes.reset_button}
          >
            <SpoqaHansTypography
              fontWeight="medium"
              className={clsx(
                classes.reset_button_text,
                resetButtonDisabled && classes.reset_button_disabled_text
              )}
            >
              Reset all
            </SpoqaHansTypography>
          </Button>
        }
      />
    );
  };

  return (
    <div className={classes.root}>
      {renderDrawerTitle()}
      <SidebarDropDownMenuView
        menu_name={translate.main_sidebar.activities.activity.menu_name}
        listComponent={
          <SidebarRadioGroupView
            group_name={"activities"}
            value={activityFilter}
            items={activityItems}
            handleChange={handleActivityChange}
          />
        }
      />
      <SidebarDropDownMenuView
        menu_name={translate.main_sidebar.activities.members.menu_name}
        listComponent={
          <SidebarRadioMemberGroupView
            group_name={"members"}
            value={memberFilter}
            handleChange={handleMemberChange}
            members={members}
          />
        }
      />
    </div>
  );
};

export default ActivityDrawerInnerComponent;
