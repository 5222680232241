export default {
  ko: {
    main_sidebar: {
      folder_drawer: {
        title: "모든 폴더",
        favorite: "Favorite",
        public: "Public",
        private: "Private",
        shared: "Shared",
      },
      security_drawer: {
        title: "보안 서약 관리",
        manage_nda: "NDA 관리",
        corp_sign: "회사 서명",
        all_documents: "모든 서명 문서",
      },
      workspace_setting: {
        title: "워크스페이스 설정",
        settings: "기본 설정",
        members: "멤버 및 팀",
        payments: "결제",
      },
      activities: {
        title: "모든 활동",
        button_reset: "Reset all",
        activity: {
          menu_name: "Activity",
        },
        members: {
          menu_name: "Members",
        },
      },
      new_workspace: "새 워크스페이스 만들기",
      public_workspace: "공개 워크스페이스",
      private_workspace: "비공개 워크스페이스",
      members: "멤버",
      members_postfix: "명",
      role: "내 권한",
      role_postfix: "",
      role_type: {
        owner: "Owner",
        guest: "Guest",
        admin: "Admin",
        member: "Member",
        description: {
          admin:
            "워크스페이스 내의 룰 설정 및 팀 개설, NDA 업로드, 회사 서명 등록 및 다른 사람을 초대할 수 있습니다.",
          member:
            "워크스페이스 내의 NDA 업로드가 가능하고, 워크스페이스에 다른 사람을 초대할 수 있습니다.",
          guest: "워크스페이스 내의 공유받은 폴더를 확인할 수 있습니다.",
        },
        more: "권한 정책 더 알아보기",
      },
      invite: "멤버 초대하기",
      nda_management: "NDA 관리",
      ws_settings: "워크스페이스 세팅",
      nda: "NDA",
      settings: "설정",
      sign_out: "워크스페이스에서 로그아웃하기",
      tooltip_pin: "최대 5개의 폴더를 즐겨찾기 할 수 있습니다",
      favorite: "Favorite",
      public: "Public",
      private: "Private",
      shared: "Shared",
      add_folder: "폴더 추가",
    },
  },

  en: {
    main_sidebar: {
      folder_drawer: {
        title: "All folders",
        favorite: "Favorite",
        public: "Public",
        private: "Private",
        shared: "Shared",
      },
      security_drawer: {
        title: "보안 서약 관리",
        manage_nda: "NDA 관리",
        corp_sign: "회사 서명",
        all_documents: "모든 서명 문서",
      },
      workspace_setting: {
        title: "워크스페이스 설정",
        settings: "기본 설정",
        members: "멤버 및 팀",
        payments: "결제",
      },
      activities: {
        title: "모든 활동",
        button_reset: "Reset all",
        activity: {
          menu_name: "Activity",
        },
        members: {
          menu_name: "Members",
        },
      },
      new_workspace: "Create a new workspace",
      public_workspace: "Public workspace",
      private_workspace: "Private workspace",
      members: "Members",
      members_postfix: "",
      role: "Your role is",
      role_postfix: "",
      role_type: {
        owner: "Owner",
        guest: "Guest",
        admin: "Admin",
        member: "Member",
        description: {
          admin:
            "Admin can set up workspace rules, create teams, upload NDAs, register company stamps and invite members in workspace",
          member: "Member can use NDAs and invite members in workspace",
          guest: "Guest can check the shared folder in workspace",
        },
        more: "Learn more about policy",
      },
      invite: "Invite members",
      nda_management: "NDAs",
      nda: "NDAs",
      ws_settings: "Workspace Settings",
      settings: "Settings",
      sign_out: "Sign out of workspace",
      tooltip_pin: "You can bookmark up to five folders",
      favorite: "Favorite",
      public: "Public",
      private: "Private",
      shared: "Shared",
      add_folder: "Add a folder",
    },
  },
};
