import React from "react";
import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { theme } from "@theme";
import LoadingWrap from "./LoadingWrap";
import clsx from "clsx";

type CircularLoadingWrapProps = {
  loading: boolean;
  loadingComponent?: JSX.Element;
  children?: any;
  wrapClass?: string;
  progressClass?: string;
  isOnBackground?: boolean;
};

const useStyle = makeStyles((MuiTheme) => ({
  root: {
    width: "100%",
    marginTop: 52,
    height: 480,
    [MuiTheme.breakpoints.down(theme.breakSmall)]: {
      height: 160,
    },
  },
  rootBackground: {
    backgroundColor: `${theme.gray_0}`,
    borderRadius: 10,
    border: `2px dashed ${theme.gray_3}`,
  },
  progress: {
    color: theme.indigo_6,
  },
}));

export function CircularLoadingWrap({
  loading,
  loadingComponent,
  children,
  wrapClass,
  progressClass,
  isOnBackground = true,
}: CircularLoadingWrapProps) {
  const memoLoading = React.useMemo(() => loading, [loading]);
  const classes = useStyle();

  const CircularLoadingView = React.useMemo(() => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={clsx(
          classes.root,
          wrapClass,
          isOnBackground && classes.rootBackground
        )}
      >
        <CircularProgress className={clsx(classes.progress, progressClass)} />
      </Grid>
    );
  }, [loading]);

  return (
    <LoadingWrap
      loading={memoLoading}
      loadingComponent={
        loadingComponent ? loadingComponent : CircularLoadingView
      }
    >
      {children}
    </LoadingWrap>
  );
}

export default React.memo(CircularLoadingWrap);
